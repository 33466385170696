<template>
  <div class="funnelBody">
    <updated-funnel-body-head
      :class="['funnelBodyGrid', { funnelBodyGrid_sourceHide: sourceHide }]"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      @hideSource="hideSource"
      @getPipelineFull="getPipelineFull"
      @getPipelineShort="getPipelineShort"
    />

    <updated-funnel-body-item
      v-for="lead in leadsFiltered"
      :key="lead.id"
      :class="['funnelBodyGrid', { funnelBodyGrid_sourceHide: sourceHide }]"
      :lead="lead"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      @showSource="showSource"
    />

    <funnel-body-unpaid
      :unpaidLeads="unpaidLeads"
      v-if="unpaidLeads && !hasSubscription"
    />
  </div>
</template>

<script>
import UpdatedFunnelBodyHead from "@/components/Funnel/UpdatedFunnelBodyHead.vue";
import UpdatedFunnelBodyItem from "@/components/Funnel/UpdatedFunnelBodyItem.vue";
import FunnelBodyUnpaid from "@/components/Funnel/FunnelBodyUnpaid.vue";
import { pathOr } from "ramda";

export default {
  name: "UpdatedFunnelBody",
  components: {
    UpdatedFunnelBodyHead,
    UpdatedFunnelBodyItem,
    FunnelBodyUnpaid,
  },
  props: {
    leads: {
      type: Array,
      required: true,
    },
    hasSubscription: {
      type: [Boolean, Number],
      required: false,
    },
    total: {
      type: Number,
      required: false,
    },
    company: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sourceHide: false,
      pipelineIsFull: false,
    };
  },
  computed: {
    webinarWidth() {
      const webinarArr = [];
      for (let i = this.leads.length; i--;) {
        webinarArr.push(
          pathOr([], ["bot", "messages", "groups"], this.leads[i]).length
        );
      }
      webinarArr.sort();
      return webinarArr[webinarArr.length - 1];
    },
    unpaidLeads() {
      return this.total - 5 > 0 ? this.total - 5 : 0;
    },
    leadsFiltered() {
      if (this.hasSubscription) {
        return this.leads;
      }
      return this.leads.slice(0, 5);
    },
  },
  methods: {
    hideSource() {
      this.sourceHide = true;
    },
    showSource() {
      this.sourceHide = false;
    },
    getPipelineFull() {
      this.pipelineIsFull = true;
    },
    getPipelineShort() {
      this.pipelineIsFull = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnelBodyGrid {
  grid-gap: 8px 4px;
  grid-template-areas: 'avatar name source' 'actions actions actions' 'tags tags tags';
  grid-template-columns: 20px calc(100vw - 200px) 155px;

  @media (min-width: phone_s) {
    grid-template-columns: 20px 175px 155px;
  }

  @media (min-width: tablet_xs) {
    grid-template-columns: 20px 120px 155px;
  }

  @media (min-width: desktop) {
    grid-template-columns: 24px 161px 170px 480px 83px;
    grid-gap: 23px 12px;
    grid-template-areas: unset;

    &_sourceHide {
      grid-template-columns: 24px 161px 12px 480px 83px;
    }
  }

  @media (min-width: desktopLarge) {
    grid-template-columns: 24px 161px 170px 595px 83px;

    &_sourceHide {
      grid-template-columns: 24px 161px 12px 595px 83px;
    }
  }
}

.funnelBody {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;

  @media (min-width: tablet_xs) {
    column-gap: 15px;
  }

  @media (min-width: desktop) {
    display: block;
    margin-top: 22px;
  }
}
</style>
