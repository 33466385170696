var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fieldset',{staticClass:"contactsFilter__fieldset"},[_c('legend',{staticClass:"contactsFilter__legend"},[_c('Tr',[_vm._v("Прогресс")])],1),_c('div',{staticClass:"contactsFilter__fieldsetBox"},_vm._l((_vm.progresses),function(progressItem){return _c('label',{key:progressItem.param,staticClass:"contactsFilter__label"},[_c('contacts-progress',{staticClass:"contactsFilter__progress",attrs:{"param":progressItem.param}}),_c('input',{staticClass:"contactsFilter__inputCheckbox",attrs:{"name":"progress","type":"radio"},domProps:{"checked":_vm.isFilterChecked('progress', progressItem.param),"value":progressItem.param},on:{"change":_vm.change}}),_c('span',{class:[
          'contactsFilter__fakeCheckbox',
          {
            contactsFilter__fakeCheckbox_checked: _vm.isFilterChecked(
              'progress',
              progressItem.param
            ),
          } ]}),_c('span',{staticClass:"contactsFilter__labelText"},[_vm._v(" "+_vm._s(progressItem.verbose)+" ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }