import axiosConfig from "@/utils/axiosConfigFunnel";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    lead_id: "",
    form: {
      product_name: "",
      product_id: "",
      amount: "",
    },
    createdUrl: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    linkCreated(state) {
      return state.created;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
    errors(state) {
      return state.errors;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_LEAD_ID(state, value) {
      state.lead_id = value;
    },
    CHANGE_NAME(state, value) {
      state.form.product_name = value;
    },
    CHANGE_PRODUCT_ID(state, value) {
      state.form.product_id = value;
    },
    CHANGE_AMOUNT(state, value) {
      state.form.amount = value;
    },
    LINK_IS_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED_URL(state, data) {
      state.createdUrl = data.checkout_url;
    },
    CLEAR_STORE(state) {
      state.lead_id = "";
      state.form = {
        product_name: "",
        product_id: "",
        amount: "",
      };
      state.createdUrl = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async createLink({ commit, state }) {
      const data = {
        ...state.form,
      };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/leads/${state.lead_id}/order/prodamus`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 201) {
          commit("SET_CREATED_URL", response.data.data);
          commit("LINK_IS_CREATED", true);
          commit("SET_PENDING", false);
          return response;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        console.info(e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
