<template>
  <div>
    <BaseBreadcrumbs
      :breadcrumbs="breadcrumbs"
      class="breadcrumbsContactsProfile"
    />
    <h1 class="title funnel__createLinkTitle"><Tr>Создать ссылку</Tr></h1>
    <funnel-create-link-success
      :updatedFunnel="true"
      :createdUrl="createdUrl"
      :createdName="createdName"
      @newLink="newLink"
      @beforeDestroy="beforeDestroy"
      v-if="linkCreated"
    />
    <funnel-create-link
      :updatedFunnel="true"
      :createLinkPropsObject="createLinkPropsObject"
      @create="create"
      @changeName="changeName"
      @changeProduct="changeProduct"
      @changeFunnel="changeFunnel"
      @changeStarter="changeStarter"
      v-else
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseBreadcrumbs from "@/components/common/BaseBreadcrumbs.vue";
import FunnelCreateLink from "@/components/Funnel/FunnelCreateLink.vue";
import FunnelCreateLinkSuccess from "@/components/Funnel/FunnelCreateLinkSuccess.vue";

export default {
  name: "UpdatedFunnelCreateLinkContainer",
  components: {
    BaseBreadcrumbs,
    FunnelCreateLink,
    FunnelCreateLinkSuccess,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Воронка", link: "/funnel" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      linkCreated: "updatedFunnelCreateLink/linkCreated",
      pending: "updatedFunnelCreateLink/pending",
      products: "updatedFunnelCreateLink/products",
      funnels: "updatedFunnelCreateLink/funnels",
      starters: "updatedFunnelCreateLink/starters",
      funnelId: "updatedFunnelCreateLink/funnelId",
      starterId: "updatedFunnelCreateLink/starterId",
      productId: "updatedFunnelCreateLink/productId",
      name: "updatedFunnelCreateLink/name",
      errors: "updatedFunnelCreateLink/errors",
      createdUrl: "updatedFunnelCreateLink/createdUrl",
      createdName: "updatedFunnelCreateLink/createdName",
    }),
    createLinkPropsObject() {
      return {
        linkCreated: this.linkCreated,
        name: this.name,
        pending: this.pending,
        products: this.products,
        funnels: this.funnels,
        starters: this.starters,
        funnelId: this.funnelId,
        starterId: this.starterId,
        productId: this.productId,
        errors: this.errors,
      };
    },
  },
  methods: {
    create() {
      this.$store.dispatch("updatedFunnelCreateLink/createLink");
    },

    changeName(value) {
      this.$store.commit("updatedFunnelCreateLink/CHANGE_NAME", value);
    },

    changeProduct(value) {
      this.$store.commit("updatedFunnelCreateLink/CHANGE_PRODUCT", value);
    },

    changeFunnel(value) {
      this.$store.commit("updatedFunnelCreateLink/CHANGE_FUNNEL", value);
    },

    changeStarter(value) {
      this.$store.commit("updatedFunnelCreateLink/CHANGE_STARTER", value);
    },

    newLink() {
      this.$store.commit("updatedFunnelCreateLink/CLEAR_STORE");
    },
  },
  beforeDestroy() {
    this.$store.commit("updatedFunnelCreateLink/CLEAR_STORE");
  },
  mounted() {
    Promise.all([this.$store.dispatch("updatedFunnelCreateLink/fetch")])
      .then(() => {
        this.$store.commit("updatedFunnelCreateLink/CHANGE_PRODUCT", this.products[0].id);
      })
      .then(() => {
        this.$store.commit("updatedFunnelCreateLink/CHANGE_FUNNEL", this.funnels[0].id);
      })
      .then(() => {
        if (this.starters.length > 0) {
          this.$store.commit("updatedFunnelCreateLink/CHANGE_STARTER", this.starters[0].id);
        }
      })
      .catch(e => {
        console.log(e);
      });
  },
};
</script>

<style lang="stylus" scoped></style>
