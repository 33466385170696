import axios from "axios";
import { pathOr } from "ramda";
import router from "@/router";

const axiosConfig = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_CHATBOT_URL,
  Accept: "application/json",
});

export default {
  namespaced: true,

  state: () => ({
    data: {},
    isNew: true,
    errors: {},
    pending: false,
  }),

  getters: {
    name(state) {
      return pathOr("", ["data", "name"], state);
    },
    lang(state) {
      return pathOr(undefined, ["data", "lang"], state);
    },
    duration(state) {
      return pathOr(0, ["data", "duration"], state);
    },
    isPublish(state) {
      return pathOr(undefined, ["data", "status"], state) === "publish";
    },
    pending(state) {
      return state.pending;
    },
    isNew(state) {
      return state.isNew;
    },
    videoUrl(state) {
      if (state.data && state.data.vimeo_id) {
        return `https://player.vimeo.com/video/${state.data.vimeo_id}`;
      }
      return "https://player.vimeo.com/video/0";
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.data.name = value;
    },
    CHANGE_LANG(state, value) {
      state.data.lang = value;
    },
    CHANGE_STATUS(state, value) {
      state.data.status = value ? "publish" : "draft";
    },
    SET_NEW_FLAG(state, value) {
      state.isNew = value;
    },
    LINK_PRESENTATION(state, value) {
      state.data.presentation_id = value;
    },
  },
  actions: {
    initNew({ commit }) {
      commit("SET_NEW_FLAG", true);
      const newWebinar = {
        name: "",
        lang: null,
        status: "draft",
        psychotype: "red",
        vimeo_id: 0,
        duration: 1800,
      };
      commit("SET_DATA", newWebinar);
    },

    async fetch({ state, commit, rootGetters }, webinarId) {
      if (state.pending) {
        return;
      }
      commit("SET_NEW_FLAG", false);
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/videos/${webinarId}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async create({ state, commit, rootGetters, dispatch }) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.post(
          `/videos`,
          state.data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          const videoId = response.data.response.id;

          Promise.all([
            dispatch("presentation/createPresentation", state.data.name, {
              root: true,
            }),
          ]).then(presentation => {
            Promise.all([
              dispatch("update", { presentation_id: presentation[0].id }),
            ]).then(resp => {
              if (resp[0].status === 200) {
                router.push(
                  `/${router.currentRoute.params.lang}/leadership/autowebinars/${videoId}/webinar`
                );
                commit("SET_DATA", response.data.response);
                commit("SET_PENDING", false);
              }
            });
          });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async update({ commit, state, rootGetters }, outerData?) {
      try {
        commit("SET_PENDING", true);

        const webinarId = state.data.id;
        const data = {
          ...state.data,
          ...outerData,
        };

        const response = await axios.put(
          `/videos/${webinarId}`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async delete({ state, commit, rootGetters }) {
      try {
        commit("SET_PENDING", true);

        const webinarId = state.data.id;

        const response = await axios.delete(
          `/videos/${webinarId}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          router.push(
            `/${router.currentRoute.params.lang}/leadership/autowebinars`
          );
          // commit("SET_DATA", response.data.response);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
