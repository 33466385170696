<script>
export default {
  name: "BaseCheckboxesGroup",
  props: {
    title: String,
    list: Array,
    checkedList: Array,
    errors: Array,
  },
  methods: {
    isChecked(name) {
      if (this.checkedList) {
        return this.checkedList.includes(name);
      }
      return false;
    },
  },
};
</script>

<template>
  <fieldset>
    <legend>
      <Tr>{{ title }}</Tr>
      <label :key="item.title" v-for="item in list">
        <Tr>{{ item.title }}</Tr>
        <input
          :checked="isChecked(item.value)"
          :id="item.value"
          @change="$emit('handle-checkboxes', item.value)"
          type="checkbox"
        />
        <span class="checkbox" />
      </label>
      <div class="errors">
        <div :key="error" class="errors__item" v-for="error in errors">
          <Tr>{{ error }}</Tr>
        </div>
      </div>
    </legend>
  </fieldset>
</template>

<style lang="stylus" scoped>
legend {
  font(#0F1642, 12px, 400, 15px);
  letter-spacing: -0.1px;
}

label {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 25px;
  font_regular();
}

input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.checkbox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
  border-radius: 2px;
}

input:checked + .checkbox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Crect width='8' height='8' rx='2 ' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 70%;
  background-position-x: 2px;
  background-position-y: 2px;
  background-repeat: no-repeat;
}

.errors {
  margin-top: 10px;

  &__item {
    color: danger_text_color;
  }
}
</style>
