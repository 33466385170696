import moment from "moment";
import momentTz from "moment-timezone";

export function formatDateTz(date, lang, timezone = "Europe/Moscow") {
  if (date) {
    const time = momentTz.tz(date, timezone).format("D MMM, HH:mm");

    return moment(time)
      .utc()
      .locale(lang)
      .format("D MMM, HH:mm");
  }
}
