<template>
  <div>
    <fieldset class="contactsFilter__fieldset">
      <legend class="contactsFilter__legend"><Tr>Подогретость</Tr></legend>
      <div class="contactsFilter__fieldsetBox">
        <label
          :key="therm.param"
          class="contactsFilter__label"
          v-for="therm in contactHeatArray"
        >
          <input
            class="contactsFilter__inputCheckbox"
            name="heat"
            type="radio"
            :checked="isFilterChecked('heat', therm.param)"
            :value="therm.param"
            @change="change"
          />
          <span
            :class="[
              'contactsFilter__fakeCheckbox',
              {
                contactsFilter__fakeCheckbox_checked: isFilterChecked(
                  'heat',
                  therm.param
                ),
              },
            ]"
          />
          <span
            v-if="therm.param === 'unmarked'"
            class="contactsFilter__labelTxt"
          >
            {{ therm.verbose }}
          </span>
          <base-icon-svg
            v-else
            class="contactsFilter__img"
            width="120"
            height="20"
            viewbox="0 0 120 20"
            v-tooltip="therm.verbose"
          >
            <ContactsHeatCold v-if="therm.param === 'cold'" />
            <ContactsHeatWarm v-else-if="therm.param === 'warm'" />
            <ContactsHeatHot v-else-if="therm.param === 'hot'" />
          </base-icon-svg>
        </label>
      </div>
    </fieldset>

    <fieldset class="contactsFilter__fieldset">
      <legend class="contactsFilter__legend"><Tr>Топ</Tr></legend>
      <div class="contactsFilter__fieldsetBox">
        <label
          :key="top.param"
          class="contactsFilter__label"
          v-for="top in contactTopArray"
        >
          <input
            class="contactsFilter__inputCheckbox"
            name="top"
            type="radio"
            :checked="isFilterChecked('top', top.param)"
            :value="top.param"
            @change="change"
          />
          <span
            :class="[
              'contactsFilter__fakeCheckbox',
              {
                contactsFilter__fakeCheckbox_checked: isFilterChecked(
                  'top',
                  top.param
                ),
              },
            ]"
          />
          <span
            :class="[
              { contactsFilter__tops: top.param !== 'unmarked' },
              `contactsFilter__${top.param}`,
            ]"
            class="contactsFilter__labelTxt"
          >
            <template v-if="top.param === '100'">
              <Tr>ТОП 100</Tr>
            </template>

            <template v-else-if="top.param === 'unmarked'">
              {{ top.verbose }}
            </template>

            <template v-else>
              <Tr>ТОП 20</Tr>
            </template>
          </span>
        </label>
      </div>
    </fieldset>
  </div>
</template>

<script>
import ContactsHeatCold from '../SvgIcons/contacts/ContactsHeatCold.vue';
import ContactsHeatWarm from '../SvgIcons/contacts/ContactsHeatWarm.vue';
import ContactsHeatHot from '../SvgIcons/contacts/ContactsHeatHot.vue';
import { mapGetters } from 'vuex';

export default {
  name: "ContactsFilterBodyWarmTops",
  components: {
    ContactsHeatCold,
    ContactsHeatWarm,
    ContactsHeatHot,
  },
  data() {
    return {
      checkboxNameTherm: "contactsFilterTherm",
      checkboxNameTops: "contactsFilterTops",
    };
  },

  props: {
    isFilterChecked: {
      type: Function,
    },
  },

  computed: {
    ...mapGetters({
      contactHeatArray: "contacts/contactHeatArray",
      contactTopArray: "contacts/contactTopArray",
    }),
  },

  methods: {
    change(e) {
      this.$emit("change", {
        name: e.target.name,
        value: e.target.value ? e.target.value : "",
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
fieldsetWidth = 180px;

.contactsFilter__fieldset {
  position: relative;
  max-width: fieldsetWidth;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.contactsFilter__fieldset + .contactsFilter__fieldset {
  margin-top: 24px;
}

.contactsFilter__legend {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.contactsFilter__fieldsetBox {
  display: inline-grid;
  grid-row-gap: 10px;

  @media (min-width: desktop) {
    grid-row-gap: 6px;
  }
}

.contactsFilter__label {
  position: relative;
  padding-left: 23px;
  width: fieldsetWidth;
  height: 16px;
  cursor: pointer;
}

.contactsFilter__inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.contactsFilter__fakeCheckbox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
  border-radius: 50%;
}

.contactsFilter__fakeCheckbox_checked {
  background-color: main_text_color;
  box-shadow: inset 0px 0px 0px 2px #fff;
}

.contactsFilter__labelTxt {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  vertical-align: text-top;
}

.contactsFilter__tops {
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  letter-spacing: 0.29px;
}

.contactsFilter__100 {
  border-bottom: 4px solid #ffe043;
}

.contactsFilter__20_and_100 {
  border-bottom: 4px solid #19c564;
}

.contactsFilter__img {
  width: 63px;
  height: 10px;
}
</style>
