<template>
  <div class="news">
    <div class="loaderBox">
      <div class="loaderIcon" v-show="pending">
        <loader />
      </div>
      <div class="newsBox">
        <div
          class="newsItem"
          v-for="(event, index) in events"
          :key="`${event.id}_${index}`"
        >
          <template v-if="emptyLastName(event)">
            <div class="newsItemTitle">
              {{ formatDateTz(event.created_at, lang, userTimezone) }}
            </div>
            <div class="newsItemBody">
              <Tr>Фамилия удалена</Tr>
            </div>
          </template>

          <template v-else>
            <div class="newsItemTitle">
              {{ formatDateTz(event.created_at, lang, userTimezone) }}
            </div>
            <div class="newsItemBody">
              <template
                v-if="event.type === 'note' || event.type === 'progress'"
              >
                <span
                  class="newsItemText"
                  v-if="event.message_by_type"
                  v-html="event.message_by_type"
                />
              </template>
              <template v-else>
                {{ event.type_verbose }}
                <template v-if="event.topic">
                  <span class="newsItemBoldText" v-if="event.type === 'heat'">
                    {{ getVerbose(contactHeatArray, event.topic) }}
                  </span>
                  <span
                    class="newsItemBoldText"
                    v-else-if="
                      event.type === 'top' && event.topic === '20_and_100'
                    "
                  >
                    "<Tr>Топ 20</Tr>"
                  </span>
                  <span
                    class="newsItemBoldText"
                    v-else-if="event.type === 'top' && event.topic === '100'"
                  >
                    "<Tr>Топ 100</Tr>"
                  </span>
                  <span class="newsItemBoldText" v-else>
                    "{{ event.topic }}"
                  </span>
                </template>
                <span
                  class="newsItemBoldText"
                  v-else-if="
                    !event.topic && ['heat', 'top'].includes(event.type)
                  "
                >
                  "<Tr>Не отмечено</Tr>"
                </span>
              </template>
            </div>
          </template>
        </div>
      </div>

      <fetch-more @fetch-more="fetchMore" v-if="hasNextPage" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";
import { formatDateTz } from "@/utils/formatDateTz";

import Loader from "@/components/Loader.vue";
import FetchMore from "@/components/FetchMore.vue";

export default {
  name: "ContactsProfileEvents",

  components: {
    Loader,
    FetchMore,
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      events: "contactsProfileEvents/events",
      pending: "contactsProfileEvents/pending",
      errors: "contactsProfileEvents/errors",
      lang: "currentLang",
      userTimezone: "user/userTimezone",
      hasNextPage: "contactsProfileEvents/hasNextPage",
      currentPage: "contactsProfileEvents/currentPage",
      contactHeatArray: "contacts/contactHeatArray",
    }),
  },

  methods: {
    formatDateTz,

    fetchMore() {
      this.$store.dispatch("contactsProfileEvents/fetchMore", {
        page: this.currentPage + 1,
        contact: this.$route.params.profileId,
      });
    },

    getVerbose(array, param) {
      const findedEl = array.find(el => el.param === param);
      if (findedEl) return `"${findedEl.verbose}"`;
    },

    emptyLastName(event) {
      return event.type === 'last_name' && event.topic === ''
    }
  },

  mounted() {
    this.$store.dispatch("contactsProfileEvents/fetch", {
      page: 1,
      contact: this.$route.params.profileId,
    });
  },

  watch: {
    errors(val) {
      runNotice("error", val);
    },
  },

  beforeDestroy() {
    this.$store.commit("contactsProfileEvents/CLEAR_EVENTS")
  },
};
</script>

<style lang="stylus" scoped>
.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.newsItem {
  margin-bottom: 16px;
  overflow: hidden;
  cell_fade();
}

.newsItemTitle {
  margin-bottom: 2px;
  letter-spacing: -0.1px;
  font(main_text_color, 12px, 700, 14px);
}

.newsItemBody {
  font_regular();
  letter-spacing: -0.1px;
}

.newsItemBoldText {
  display: inline-flex;
  font-weight: 700;
}
</style>
