<template>
  <div class="underLoader">
    <div class="loaderBox">
      <div class="loaderIcon" v-if="pending">
        <loader />
      </div>

      <contacts-menu
        :isFilterChecked="isFilterChecked"
        :isMenuShown="isMenuShown"
        :filtersIsActive="filtersIsActive"
        :tags="tags"
        :filters="filters"
        :tagsFilterValue="tagsFilterValue"
        :tagsFilterCounter="tagsFilterCounter"
        :pickedAllTags="pickedAllTags"
        @change-filter="changeFilter"
        @clearFilters="clearFilters"
        @pickAllTags="pickAllTags"
        @handleFilter="handleFilter"
        @changeTagsFilterValue="changeTagsFilterValue"
        @changeTagsFilterCounter="changeTagsFilterCounter"
      />

      <no-content v-if="!pending && isNoContent" :noContent="noContent" />

      <contacts-body
        v-if="contacts.length > 0 || filtersFilled || filtersIsActive"
        :contacts="contacts"
        :tags="tags"
        :progresses="progresses"
        :contactHeatArray="contactHeatArray"
        :contactTopArray="contactTopArray"
        @delTag="delTag"
        @addTag="addTag"
      />

      <div class="pagination" v-show="amountPages > 1">
        <button
          type="button"
          v-for="n in amountPages"
          :key="n"
          @click="fetchPage(n)"
          class="pagination__button"
          :class="{ pagination__button_active: n === currentPage }"
        >
          {{ n }}
        </button>
      </div>

      <!-- <fetch-more @fetch-more="fetchMore" v-if="hasNextPage" /> -->
    </div>
  </div>
</template>

<script>
import { runNotice } from "@/utils/notifications";
import { mapGetters, mapState } from "vuex";

import NoContent from "@/components/NoContent.vue";
import ContactsMenu from "@/components/Contacts/ContactsMenu.vue";
import ContactsBody from "@/components/Contacts/ContactsBody.vue";
import Loader from "@/components/Loader.vue";
// import FetchMore from "@/components/FetchMore.vue";

export default {
  name: "ContactsContainer",

  components: {
    NoContent,
    ContactsMenu,
    ContactsBody,
    Loader,
    // FetchMore,
  },

  data() {
    return {
      filtersIsActive: false,
      dataPendingMailDuplicates: false,
      dataPendingPhoneDuplicates: false,
      dataMailDuplicates: [],
      dataPhoneDuplicates: [],
    };
  },

  computed: {
    ...mapGetters({
      contacts: "contacts/contacts",
      progresses: "leadTasks/contactProgresses",
      contactHeatArray: "contacts/contactHeatArray",
      contactTopArray: "contacts/contactTopArray",
      pending: "contacts/pending",
      pendingContactProgresses: "leadTasks/pendingContactProgresses",
      // hasNextPage: "contacts/hasNextPage",
      currentPage: "contacts/currentPage",
      amountPages: "contacts/amountPages",
      isFilterChecked: "contacts/isFilterChecked",
      filters: "contacts/filters",
      tagsFilterValue: "contacts/tagsFilterValue",
      tagsFilterCounter: "contacts/tagsFilterCounter",
      pickedAllTags: "contacts/pickedAllTags",
    }),
    ...mapState(
      {
        tags: state => state.contactsTags.tags,
        deletedContactsCounter: state => state.contacts.deletedContactsCounter,
      }
    ),

    noContent() {
      return {
        noFunnels: true,
        text: "Пусто. У вас еще нет ни одного контакта",
        maxWidth: 0,
      };
    },

    filtersFilled() {
      return Object.keys(this.filters).length > 0 && this.contacts.length === 0;
    },

    isNoContent() {
      return (
        Object.keys(this.filters).length === 0 && this.contacts.length === 0
      );
    },

    isMenuShown() {
      return this.contacts.length > 0 || this.filtersFilled || this.filtersIsActive;
    },
  },

  methods: {
    fetchMore() {
      this.$store.dispatch("contacts/fetchMore", this.currentPage + 1);
    },

    fetchPage(n) {
      this.$store.dispatch("contacts/fetchPage", n);
    },

    changeFilter(payload) {
      this.$store
        .dispatch("contacts/changeFilter", payload)
        .then(() => {
          this.$store.dispatch("contacts/fetch");
        })
        .catch(e => {
          console.log(e);
        });
    },

    clearFilters() {
      this.$store.commit("contacts/CLEAR_FILTERS");
      this.$store.dispatch("contacts/fetch");
    },

    handleFilter() {
      this.filtersIsActive = !this.filtersIsActive;
    },

    addTag(payload) {
      this.$store.dispatch('contactsTags/addTag', payload)
        .then(response => {
          if (response && response.status === 201) {
            this.$store.dispatch("contacts/fetch", this.currentPage);

            this.$modal.hide(this.modalName);

            runNotice('success', this.$t('Тег добавлен'))
          } else {
            runNotice('error', this.$t('Тег не добавлен'))
          }
        })
        .catch(e => { console.log(e); });
    },

    delTag(payload) {
      this.$store.dispatch('contactsTags/fetchIdTag', payload)
        .then(response => {
          if (response && response.status === 200) {
            this.$store.dispatch("contactsTags/delTagFromContact", response.data.results[0].id)
              .then(response => {
                if (response && response.status === 204) {
                  runNotice('success', this.$t('Тег удален'));
                  this.$store.dispatch("contacts/fetch", this.currentPage);
                } else {
                  runNotice('error', this.$t('Тег не удален'));
                }
              })
              .catch(e => { console.log(e); });
          } else {
            runNotice('error', this.$t('Тег не удален'));
          }
        })
        .catch(e => { console.log(e); });
    },

    changeTagsFilterValue(value) {
      this.$store.commit("contacts/SET_TAGS_FILTER_VALUE", value);
    },

    changeTagsFilterCounter(value) {
      this.$store.commit("contacts/SET_TAGS_FILTER_COUNTER", value);
    },

    pickAllTags(payload) {
      this.$store
        .dispatch("contacts/pickAllTags", payload)
        .then(() => {
          this.$store.dispatch("contacts/fetch");
        })
        .catch(e => {
          console.log(e);
        });
    },
  },

  mounted() {
    this.$store.dispatch("contacts/fetch")
      .then(() => {
        this.$store.dispatch("leadTasks/fetchContactProgresses");
        this.$store.dispatch("contacts/fetchContactHeat");
        this.$store.dispatch("contacts/fetchContactTop");
        this.$store.dispatch("contactsTags/fetch");
      })
      .catch(e => { console.log(e); });
  },

  watch: {
    deletedContactsCounter() {
      this.$store.dispatch("contacts/fetch");
    },
  },

  destroyed() {
    this.$store.commit("contacts/CLEAR_DELETED_CONTACTS_COUNTER");
  },
};
</script>

<style lang="stylus" scoped>
.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.pagination {
  margin-top: 20px;
  margin-bottom: 10px;

  .pagination__button {
    padding: 2px 8px;
    font_bold();
    color: main_text_color;
    background-color: unset;
    border: none;
    border-bottom: 2px solid transparent;

    &:hover {
      opacity: 0.6;
    }

    &_active {
      color: orange;
      border-bottom: 2px solid orange;
    }
  }
}
</style>
