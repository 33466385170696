<template>
  <main class="main">
    <h1 class="title">
      <Tr>Задачи</Tr>
    </h1>
    <notification v-if="!hasSubscription">
      <template slot="text">Раздел доступен только по подписке</template>
    </notification>
    <tasks-container>
      <router-view></router-view>
    </tasks-container>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import TasksContainer from "@/containers/Tasks/TasksContainer.vue";
import Notification from "@/components/Notification.vue";

export default {
  name: "Tasks",
  components: {
    TasksContainer,
    Notification,
  },

  computed: {
    ...mapGetters({
      contacts: "leadTasks/contacts",
      hasNextPage: "leadTasks/hasNextPage",
      currentPage: "leadTasks/currentPage",
      pending: "leadTasks/pending",
      hasSubscription: "user/hasSubscription",
      callStatus: "caller/callStatus",
    }),
  },

  mounted() {
    if (!this.hasSubscription) {
      const base = this.$store.getters["user/mainSiteLink"];
      const href = base + '/front/tasks';
      window.location = href;
    }
  },
};
</script>

<style lang="stylus" scoped>
.main {
  @media (min-width: 768px) {
    width: auto;
  }
}
</style>
