<template>
  <base-icon-svg width="20" height="24" viewbox="0 0 20 24" v-if="actionItem">
    <order
      v-if="actionItem.status === 'approved'"
      v-tooltip="
        `${$t('Заказ успешно совершен')} ${
          actionItem.desc ? actionItem.desc : ''
        }`
      "
    />
    <order
      v-if="actionItem.status === 'success'"
      v-tooltip="
        `${$t('Заказ успешно совершен')} ${
          actionItem.desc ? actionItem.desc : ''
        }`
      "
    />
    <order
      class="orderInProcess"
      v-if="actionItem.status === 'processing'"
      v-tooltip="
        `${$t('Заказ в процессе обработки')} ${
          actionItem.desc ? actionItem.desc : ''
        }`
      "
    />
    <order
      class="orderInProcess"
      v-if="actionItem.status === 'created'"
      v-tooltip="
        `${$t('Заказ создан')} ${actionItem.desc ? actionItem.desc : ''}`
      "
    />
    <order-failed
      class="orderInProcess"
      v-if="actionItem.status === 'expired'"
      v-tooltip="
        `${$t('Время жизни заказа истекло')} ${
          actionItem.desc ? actionItem.desc : ''
        }`
      "
    />
    <order-failed
      class="orderInProcess"
      v-if="actionItem.status === 'declined'"
      v-tooltip="
        `${$t('Заказ отклонен')} ${actionItem.desc ? actionItem.desc : ''}`
      "
    />
    <order-failed
      class="orderInProcess"
      v-if="actionItem.status === 'reversed'"
      v-tooltip="
        `${$t('Заказ отменен')} ${actionItem.desc ? actionItem.desc : ''}`
      "
    />
  </base-icon-svg>
</template>

<script>
import Order from "@/components/SvgIcons/funnel/Order.vue";
import OrderFailed from "@/components/SvgIcons/funnel/OrderFailed.vue";

export default {
  name: "UpdatedFunnelBodyItemOrders",

  components: {
    Order,
    OrderFailed,
  },

  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.orderInProcess {
  opacity: 0.6;
}
</style>
