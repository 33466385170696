<template>
  <main class="main">
    <h1 class="title">
      <Tr>Настройки лидера</Tr>
    </h1>
    <LeadershipSettings :leadershipSettings="leadershipSettings" />
  </main>
</template>

<script>
import LeadershipSettings from "@/components/Leadership/LeadershipSettings.vue";
import { mapGetters } from "vuex";

export default {
  name: "Leadership",
  components: {
    LeadershipSettings,
  },
  data() {
    return {
      leadershipSettings: [
        {
          title: "Свои бизнес-квесты",
          link: "/leadership/courses",
          text: "Создавайте бизнес-квесты для своих нужд.",
        },
        {
          title: "Программы обучения",
          link: "/leadership/programs",
          text: "Создавайте программы обучения для своих нужд.",
        },
        // {
        //   title: "Автовебинары",
        //   link: "/leadership/autowebinars",
        //   text: "Создавайте вебинары для своих целей.",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      showLeadership: "user/showLeadership",
    }),
    mainUrl() {
      return process.env.MAIN_URL;
    },
    outHref() {
      const base = this.$store.getters["user/mainSiteLink"];
      return base + "/dashboard";
    },
  },
  beforeMount() {
    if (!this.showLeadership) {
      window.location.replace(this.outHref);
    }
  },
};
</script>

<style lang="stylus" scoped></style>
