import { pathOr } from "ramda";
import Vue from "vue";
import { setToken } from "../../utils/cookies/setToken";
import setFavicon from "@/utils/setFavicon";
import setTitle from "@/utils/setTitle";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
    isAuth: false,
  }),
  getters: {
    pending(state) {
      return state.pending;
    },
    isDev(state) {
      const dev = pathOr(undefined, ["data", "stats", "IS_DEV"], state);
      return !!dev;
    },
    id(state) {
      const id = pathOr(undefined, ["data", "id"], state);
      return !!id;
    },
    userId(state) {
      const id = pathOr(undefined, ["data", "id"], state);
      return id;
    },
    avatar(state) {
      const avatar = pathOr("", ["data", "get_avatar_url"], state);
      if (avatar.includes("data:")) {
        return avatar;
      }
      return `${process.env.VUE_APP_MAIN_URL}${avatar}`;
    },
    hasSubscription(state) {
      return pathOr(null, ["data", "active_subscription"], state);
    },
    name(state) {
      return pathOr("", ["data", "name"], state);
    },
    level(state) {
      return pathOr("", ["data", "level"], state);
    },
    mentor(state) {
      return pathOr({}, ["data", "mentor_object"], state);
    },
    mentorAvatar(state) {
      const avatar = pathOr(
        "",
        ["data", "mentor_object", "get_avatar_url"],
        state
      );
      if (avatar.includes("data:")) {
        return avatar;
      }
      return `${process.env.VUE_APP_MAIN_URL}${avatar}`;
    },
    mentorIsRegistered(state) {
      return pathOr({}, ["data", "mentor_is_registered"], state);
    },
    brilliants(state) {
      return pathOr(0, ["data", "brilliant"], state);
    },
    gold(state) {
      return pathOr(0, ["data", "gold"], state);
    },

    usersCompanyLogo(state) {
      return pathOr("", ["data", "company_object", "logo_url"], state);
    },
    usersCompanySlug(state) {
      return pathOr("", ["data", "company_object", "slug"], state);
    },
    userCompanyName(state) {
      return pathOr("", ["data", "company_object", "name"], state);
    },
    techWorks(state) {
      return pathOr("", ["data", "company_object", "technical_works"], state);
    },
    stats(state) {
      return pathOr({}, ["data", "stats"], state);
    },
    bonuses(state) {
      const { data } = state;
      if (
        data &&
        data.company_object &&
        data.company_object.slug &&
        data.company_object.slug === "bitbon"
      ) {
        return pathOr(0, ["data", "stats", "user_bonuses_amount"], state);
      }
      return false;
    },
    chatBotToken(state) {
      return pathOr("", ["data", "chat_bot_api_token"], state);
    },
    mainSiteLink(state, getters) {
      const slug = getters.usersCompanySlug.toUpperCase();
      const companyUrl = process.env[`VUE_APP_MAIN_${slug}_URL`];
      return companyUrl;
    },
    userRefLink(state) {
      return pathOr(undefined, ["data", "user_ref_link"], state);
    },

    hasFunnel(state) {
      return pathOr("", ["data", "company_object", "has_funnel"], state);
    },
    showBnLink(state) {
      return pathOr("", ["data", "company_object", "show_bn_link"], state);
    },
    showRewards(state) {
      return pathOr("", ["data", "company_object", "show_rewards"], state);
    },
    showDashboard(state) {
      return pathOr("", ["data", "company_object", "show_dashboard"], state);
    },
    showChat(state) {
      return pathOr("", ["data", "company_object", "show_chat"], state);
    },
    showRef(state) {
      return pathOr("", ["data", "company_object", "show_ref"], state);
    },
    showTeam(state) {
      return pathOr("", ["data", "company_object", "show_team"], state);
    },
    showMentoring(state) {
      return pathOr(false, ["data", "company_object", "show_mentoring"], state);
    },
    showLeadership(state) {
      return pathOr(false, ["data", "leadership_package_active"], state);
    },
    showLeads(state) {
      return pathOr(false, ["data", "telephony_leads_access"], state);
    },
    showUpdatedLeads(state) {
      return pathOr(false, ["data", "can_accept_leads"], state);
    },
    showAssignment(state) {
      return pathOr(false, ["data", "can_assignment_leads"], state);
    },
    subscription(state) {
      if (state.data && state.data.active_subscription) {
        if (state.data && state.data.company && state.data.company.is_bitbon) {
          return "year";
        } else {
          return "payed";
        }
      } else {
        return "free";
      }
    },
    userStatus(state) {
      return pathOr("", ["data", "rank_display"], state);
    },
    referalUrl(state) {
      return pathOr(undefined, ["data", "referral_url"], state);
    },
    contestPart(state) {
      return pathOr(false, ["data", "contest_object"], state);
    },
    contestSlug(state) {
      return pathOr(false, ["data", "contest_object", "slug"], state);
    },
    contestImage(state) {
      return pathOr(
        undefined,
        ["data", "contest_object", "small_image"],
        state
      );
    },
    contestTitle(state) {
      return pathOr("", ["data", "contest_object", "name"], state);
    },
    contestPosition(state) {
      return pathOr(0, ["data", "contest_user_object", "position"], state);
    },
    contestPrevPosition(state) {
      return pathOr(
        0,
        ["data", "contest_user_object", "previous_position"],
        state
      );
    },
    userTimezone(state) {
      return pathOr("", ["data", "tz"], state);
    },

    bnName(state) {
      return pathOr("", ["data", "company_object", "bn_name"], state);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },

    SET_TITLE_AND_FAVICON(state, data) {
      const favicon = pathOr(false, ["company_object", "favicon"], data);
      const html_title = pathOr(false, ["company_object", "html_title"], data);
      const slug = pathOr("tasq", ["company_object", "slug"], data);

      setFavicon(slug, favicon);
      setTitle(html_title);
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_AUTH(state) {
      state.isAuth = true;
    },

    SET_TIMEZONE(state, value) {
      state.data.tz = value;
    },
  },
  actions: {
    async urlAuth({ commit }, token) {
      setToken(token);
      commit("SET_AUTH");
    },

    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get("/users/me/");

        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("SET_DATA", response.data);
          commit("SET_TITLE_AND_FAVICON", response.data);
          return response;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async setTimezone({ commit }, value) {
      try {
        const response = await Vue.axios.patch("/users/update_tz/", {
          tz: value,
        });

        if (response.status === 200) {
          commit("SET_TIMEZONE", value);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
