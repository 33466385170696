<template>
  <a :href="outHref" class="footer__link" target="_blank">
    <Tr>{{ link.link }}</Tr>
  </a>
</template>

<script>
export default {
  name: "FooterLink",
  props: { link: Object },
  computed: {
    outHref() {
      const base = this.$store.getters["user/mainSiteLink"];
      return base + this.link.href;
    },
  },
};
</script>

<style lang="stylus" scoped>
.footer__link {
  display: inline-block;
  width: fit-content;
  color: #3489f8;
  font-size: 8px;
  line-height: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  text-decoration: underline;
  cursor: pointer;
}

.footer__link:hover {
  text-decoration: none;
}
</style>
