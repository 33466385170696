<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <div>
      <div class="title__wrap">
        <h1 class="title titleProfileQuests">
          <Tr>Программы обучения</Tr>
        </h1>
        <base-link
          v-if="hasPrograms"
          to="/leadership/programs/new/description"
          class="createQuest"
        >
          <Tr>Создать программу</Tr>
        </base-link>
      </div>
      <div class="underLoader">
        <programs-container />
      </div>
    </div>
  </main>
</template>

<script>
import ProgramsContainer from "@/containers/Leadership/ProgramsContainer.vue";
import { mapGetters } from "vuex";

export default {
  name: "Programs",
  components: {
    ProgramsContainer,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Настройки лидера", link: "/leadership" }],
    };
  },
  computed: {
    ...mapGetters({
      programs: "leadershipPrograms/programs",
    }),
    hasPrograms() {
      if (this.programs && this.programs.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.titleProfileQuests {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-direction: column;

  @media (min-width: desktop) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.createQuest {
  align-self: flex-start;
  margin-bottom: 13px;
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();

  @media (min-width: desktop) {
    align-self: center;
    margin-bottom: 5px;
    margin-left: 50px;
  }
}
</style>
