<template>
  <fieldset class="dropdowns__fieldset">
    <div class="dropdowns__top">
      <legend class="dropdowns__legend">
        <Tr>{{ dropdownsGroup.title }}</Tr>
      </legend>
      <button class="dropdowns__buttonAll" @click="checkboxesCheck">
        <Tr>Все</Tr>
      </button>
    </div>

    <button class="dropdowns__select" type="button" @click="toggle">
      <span class="dropdowns__selectText">{{ value }}</span>
      <span class="dropdowns__selectCounter" v-if="counter > 0">
        {{ counter }}
      </span>
    </button>
    <div
      class="dropdowns__container"
      v-show="
        dropdown && dropdownsGroup.array && dropdownsGroup.array.length > 0
      "
      v-click-outside="hide"
    >
      <div class="dropdowns__wrap">
        <div
          class="dropdowns__labelWrap"
          v-for="dropdownsGroupItem in dropdownsGroup.array"
          :key="dropdownsGroupItem.value"
        >
          <label
            :class="[
              'dropdowns__labelItem',
              dropdownsGroup.labelClass,
              { dropdowns__labelItem_contactTags: contactTags },
            ]"
          >
            {{ dropdownsGroupItem.name }}
            <input
              class="dropdowns__inputCheckbox"
              :name="dropdownsGroup.name"
              type="checkbox"
              :value="dropdownsGroupItem.id ? dropdownsGroupItem.id : 'without'"
              :checked="
                isFilterChecked(dropdownsGroup.name, dropdownsGroupItem.id)
              "
              @change="change($event, dropdownsGroupItem.id)"
            />
            <span class="dropdowns__checkBox" />
            <span
              class="dropdowns__contactTags"
              v-if="contactTags"
              :style="{ backgroundColor: `#${dropdownsGroupItem.color}` }"
            />
          </label>
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "UpdatedFunnelFilterDropdownsGroup",
  data() {
    return {
      dropdown: false,
    };
  },
  props: {
    dropdownsGroup: Object,
    isFilterChecked: Function,
    pickedAll: Boolean,
    filterValue: [String, Number],
    filterCounter: [String, Number],
    contactTags: Boolean,
  },
  computed: {
    value: {
      get() {
        if (this.filterValue) {
          if (this.filterValue === "all") {
            return this.$t("Все");
          } else if (this.filterValue === "without") {
            return this.$t("Без менеджера");
          } else {
            return this.dropdownsGroup.array.find(el => el.id == this.filterValue).name;
          }
        } else if (this.pickedAll && !this.filterValue) {
          return this.$t("Все");
        } else {
          return "";
        }
      },
      set(value) {
        this.$emit("change-filter-value", value);
      },
    },
    counter: {
      get() {
        return this.filterCounter;
      },
      set(value) {
        this.$emit("change-filter-counter", value);
      },
    },
  },
  methods: {
    checkboxesCheck(el) {
      this.$emit("changeAll", this.dropdownsGroup.array.map((el) => el.id));
      !this.pickedAll ? this.value = "all" : this.value = "";
      this.counter = "";
      this.dropdown = false;
    },

    toggle() {
      this.dropdown = !this.dropdown;
    },

    hide() {
      this.dropdown = false;
    },

    change(el, value) {
      this.$emit("change", {
        name: this.dropdownsGroup.name,
        value,
      });
      const checkedBoxes = document.querySelectorAll(
        `.dropdowns__inputCheckbox[name = ${this.dropdownsGroup.name}]:checked`
      );
      const checkedBoxesCount = checkedBoxes.length;

      if (el.target.checked) {
        this.value = el.target.value;
        this.counter = checkedBoxesCount - 1;
      } else {
        if (checkedBoxesCount > 0) {
          this.value = checkedBoxes[0].value;
          this.counter = checkedBoxesCount - 1;
        }
        if (checkedBoxesCount === 0) {
          this.value = "";
          this.counter = "";
        }
      }
      this.dropdown = false;
    },
  },
  mounted() {
    this.popupItem = this.$el;
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus" scoped>
.dropdowns__fieldset {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.dropdowns__buttonAll {
  letter-spacing: 0.4px;
  line-height: 17px;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dotted secondary_text_color;
  color: secondary_text_color;
}

.dropdowns__legend {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.dropdowns__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.dropdowns__select {
  position: relative;
  padding: 7px 8px;
  width: 200px;
  height: 34px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #E6E7EB;
  border-radius: 2px;
  text-align: left;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='6' viewBox='0 0 8 6'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M4 6L-.243 1.757 1.172.343 4 3.172 6.828.343l1.415 1.414z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 93%;
  background-position-y: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;

  &Text {
    width: 91%;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }

  &Counter {
    position: absolute;
    top: 8px;
    right: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: grey_text_color;
    font-size: 12px;
    font-weight: 700;
    color: main_text_color;

    &::before {
      position: absolute;
      content: '+';
      left: -7px;
      font-weight: 700;
      background-color: #fff;
      box-shadow: -4px 0px 3px 3px #fff;
    }

    &::after {
      position: absolute;
      content: '';
      right: 0;
      width: 0;
      height: 100%;
      box-shadow: 2px 0px 3px 3px #fff;
    }
  }
}

.dropdowns__container {
  position: absolute;
  z-index: 4;
  padding-bottom: 4px;
  width: 272px;
  background-color: #fff;
  box-shadow: 0px 8px 10px rgba(15 22 66 0.4);
  border-radius: 2px;

  &::after {
    position: absolute;
    content: '';
    bottom: 4px;
    width: 100%;
    height: 8px;
    background: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%);
  }
}

.dropdowns__wrap {
  max-height: 276px;
  overflow-y: auto;
  scrollbar-color: grey_text_color white;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey_text_color;
    border-radius: 4px;
  }
}

.dropdowns__labelWrap {
  position: relative;
  padding: 6px 8px;

  &:hover {
    background-color: grey_text_color;

    & .dropdowns__checkBox {
      border: 2px solid #fff;
    }
  }
}

.dropdowns__labelItem {
  position: relative;
  display: block;
  padding-left: 24px;
  min-height: 18px;
  font(main_text_color, 14px, 400, 19px);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;

  &_contactTags {
    padding-left: 34px;
  }

  &_psychotype {
    padding-left: 44px;

    &::before {
      position: absolute;
      content: '';
      top: 5px;
      left: 28px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &_messenger {
    padding-left: 44px;
  }
}

.dropdowns__labelWrap:nth-of-type(1) .dropdowns__labelItem_psychotype::before {
  background-color: danger_text_color;
}

.dropdowns__labelWrap:nth-of-type(2) .dropdowns__labelItem_psychotype::before {
  background-color: secondary_text_color;
}

.dropdowns__labelWrap:nth-of-type(3) .dropdowns__labelItem_psychotype::before {
  background-color: #FFE043;
}

.dropdowns__labelWrap:nth-of-type(4) .dropdowns__labelItem_psychotype::before {
  background-color: #19C564;
}

.dropdowns__checkBox {
  position: absolute;
  content: '';
  top: 2px;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid grey_text_color;
  border-radius: 2px;
}

.dropdowns__inputCheckbox:checked + .dropdowns__checkBox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Crect width='8' height='8' rx='2 ' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 70%;
  background-position-x: 2px;
  background-position-y: 2px;
  background-repeat: no-repeat;
}

.dropdowns__inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.dropdowns__contactTags {
  position: absolute;
  content: '';
  top: 6px;
  left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 2px;
}

@media (min-width: desktop) {
  .dropdowns__fieldset {
    margin-right: 0px;
    margin-bottom: 0px;
  }
}
</style>
