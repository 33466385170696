<template>
  <div class="contactsBody">
    <TeamHead :showRank="showRank" :company="company" />
    <!-- TABLE BODY -->
    <div class="teamTree__tableBody">
      <TeamTreeBodyItem
        v-for="teamItem in team"
        :teamItem="teamItem"
        :level="1"
        :key="teamItem.id"
        :showRank="showRank"
        :company="company"
      />
    </div>
    <div class="loader" v-if="pending"></div>
    <!-- END OF TABLE BODY -->
  </div>
</template>

<script>
import TeamHead from "@/components/Team/TeamHead.vue";
import TeamTreeBodyItem from "@/components/Team/TeamTreeBodyItem.vue";

export default {
  name: "TeamTreeBody",
  props: {
    team: {
      required: true,
      type: Array,
    },
    pending: {
      required: false,
      type: Boolean,
    },
    showRank: {
      required: false,
      type: Boolean,
    },
    company: {
      required: true,
      type: String,
    },
  },
  data() {
    return {};
  },
  components: {
    TeamHead,
    TeamTreeBodyItem,
  },
};
</script>

<style lang="stylus" scoped>
.contactsBody {
  position: relative;
}

.teamTree__tableBody {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.loader {
  position: absolute;
  background: #fff;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}

@media (min-width: desktop) {
  .teamTree__tableBody {
    display: block;
    margin-top: 0;
  }
}
</style>
