import Vue from "vue";
import { path, pathOr } from "ramda";
import router from "@/router";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    sort: "",
    error: {},
    pending: {},
  }),
  getters: {
    pending(state) {
      return state.pending;
    },
    calls(state) {
      return pathOr([], ["data", "results"], state);
    },
    currentPage(state) {
      return pathOr(undefined, ["data", "current_page_number"], state);
    },
    amountPages(state) {
      return pathOr(undefined, ["data", "num_pages"], state);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_SORT(state, sort) {
      if (state.sort === sort) {
        state.sort = `-${sort}`;
      } else {
        state.sort = sort;
      }
    },
  },
  actions: {
    changeSort({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_SORT", payload);
        resolve();
      });
    },

    updateListAfterCall({ dispatch }, { leadId }) {
      if (router.currentRoute.name === "Leads") {
        dispatch("fetch", { pageNum: 1 });
      } else if (router.currentRoute.name === "LeadCalls") {
        dispatch("fetch", { pageNum: 1, leadId });
      }
    },

    async fetch({ commit, state }, { pageNum, leadId }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/telephony/calls/`, {
          params: {
            external_lead_id: leadId,
            ...(state.sort ? { o: state.sort } : []),
            page: pageNum,
          },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
