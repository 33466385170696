import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    reminders: [],
    reminder: {
      name: "",
      description: "",
      started_at: null,
    },
    meeting: {
      type: null,
      topic: "",
      started_at: null,
    },
    errors: {},
    pending: false,
    meetingPending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    meetingPending(state) {
      return state.meetingPending;
    },

    reminders(state) {
      return state.reminders;
    },

    createdReminder(state) {
      return state.reminder;
    },

    startedAt(state) {
      return state.reminder.started_at;
    },

    errors(state) {
      return state.errors.errors;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.reminders = [...data.results];
    },

    SET_MORE(state, data) {
      state.data = data;
      state.reminders = [...state.reminders, ...data.results];
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data.data;
    },

    SET_REMINDER_NAME(state, value) {
      state.reminder.name = value;
    },

    SET_REMINDER_DESCRIPTION(state, value) {
      state.reminder.description = value;
    },

    SET_REMINDER_STARTED_AT(state, value) {
      state.reminder.started_at = value;
    },

    CLEAR_REMINDER(state) {
      state.reminder = {
        name: "",
        description: "",
        started_at: null,
      };
    },
  },

  actions: {
    async fetch({ commit }, { page, contact, filter }) {
      try {
        commit("SET_PENDING", true);

        let response;

        if (filter === "all") {
          response = await Vue.axios.get(`/user_tasks/`, {
            params: { page, contact, type: "Напоминание про контакт" },
          });
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/`, {
            params: { page, contact, type: "Напоминание про контакт" },
          });
        }

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchMore({ commit }, { page, contact, filter }) {
      try {
        commit("SET_PENDING", true);

        let response;

        if (filter === "all") {
          response = await Vue.axios.get(`/user_tasks/`, {
            params: { page, contact, type: "Напоминание про контакт" },
          });
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/`, {
            params: { page, contact, type: "Напоминание про контакт" },
          });
        }

        if (response.status === 200) {
          commit("SET_MORE", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createReminder({ commit, state }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/user_tasks/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response);
      }
    },
  },
};
