<template>
  <modal
    name="telephony-modal"
    height="auto"
    width="100%"
    :max-width="440"
    :adaptive="true"
    :clickToClose="false"
  >
    <div class="telephonyModal">
      <button
        class="telephonyModal__minimizeButton"
        type="button"
        @click="toggleMinimizedMode"
      >
        <Tr>Свернуть</Tr>
      </button>

      <!-- BODY -->
      <div class="telephonyModal__body">
        <!-- avatar -->
        <div class="telephonyModal__avatar">
          <img
            alt=""
            class="telephonyModal__avatarImg"
            :src="userAvatar"
            v-if="userAvatar"
          />
          <div v-else class="telephonyModal__avatarImg empty">
            {{ userName | getInitialsFilter }}
          </div>
        </div>

        <!-- name -->
        <span class="telephonyModal__name">
          {{ userName }}
        </span>

        <!-- status -->
        <div class="telephonyModal__statusBox">
          <span class="telephonyModal__status" v-if="callStatus === 'pending'">
            {{ timer | timerFilter }}
          </span>
          <span class="telephonyModal__status" v-else>
            {{ statuses[callStatus] }}
          </span>
        </div>

        <!-- links -->
        <div class="telephonyModal__linksBox">
          <!-- <base-link
            v-for="link in links"
            :key="link.text"
            :to="`/leads/${userId}/${link.href}/`"
            class="telephonyModal__link"
            @click.native="toggleMinimizedMode"
          >
            <Tr>{{ link.text }}</Tr>
          </base-link> -->
          <base-link
            :to="`/leads/${userId}/`"
            class="telephonyModal__link"
            @click.native="toggleMinimizedMode"
          >
            {{ userName }}
          </base-link>
        </div>

        <!-- hangUp -->
        <button type="button" class="telephonyModal__hangUp" @click="endCall">
          <base-icon-svg width="24" height="10" viewbox="0 0 24 10">
            <telephony-hang-up />
          </base-icon-svg>
          <Tr>Положить трубку</Tr>
        </button>
      </div>
      <!-- BODY -->

      <!-- FOOTER -->
      <div class="telephonyModal__footer" v-if="isChrome">
        <div class="telephonyModal__selectBox">
          <label class="telephonyModal__label" for="input">
            <Tr>Микрофон</Tr>
          </label>
          <div class="telephonyModal__selectWrap">
            <select
              id="input"
              name="input"
              class="telephonyModal__select"
              @change="changeDevice($event.target.value, 'input')"
            >
              <option
                :value="option.value"
                v-for="option in inputDevices"
                :key="option.label + 'input' + option.value"
                :selected="option.value === activeInput"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
        <div class="telephonyModal__selectBox">
          <label class="telephonyModal__label" for="output">
            <Tr>Звук</Tr>
          </label>
          <div class="telephonyModal__selectWrap">
            <select
              id="output"
              name="output"
              class="telephonyModal__select"
              @change="changeDevice($event.target.value, 'output')"
            >
              <option
                :value="option.value"
                v-for="option in outputDevices"
                :key="option.label + 'output' + option.value"
                :selected="option.value === activeOutput"
              >
                {{ option.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- FOOTER -->
    </div>
  </modal>
</template>

<script>
import TelephonyHangUp from "@/components/Telephony/TelephonyHangUpIcon.vue";
import { mapGetters } from "vuex";
import { pathEq } from "ramda";

export default {
  name: "TelephonyModal",
  components: { TelephonyHangUp },
  data() {
    return {
      links: [
        { href: "info", text: "Инфо" },
        { href: "notes", text: "Заметки" },
        { href: "products", text: "Товары" },
        { href: "tasks", text: "Задачи" },
      ],
      statuses: {
        no_call: "-",
        disconnected: this.$t("Соединение разорвано"),
        rejected: this.$t("Отклонено"),
      },
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      userName: "caller/userName",
      userAvatar: "caller/userAvatar",
      userId: "caller/userId",
      inputDevices: "caller/inputDevices",
      outputDevices: "caller/outputDevices",
      activeInput: "caller/activeInput",
      activeOutput: "caller/activeOutput",
      timer: "caller/timer",
      callStatus: "caller/callStatus",
      userBrowser: "userBrowser",
    }),
    isChrome() {
      const isIt = pathEq(["name"], "chrome")(this.userBrowser);
      return isIt;
    },
  },
  methods: {
    endCall() {
      this.$store.dispatch("caller/endCall");
      this.$modal.hide("telephony-modal");
    },
    changeDevice(value, type) {
      this.$store.dispatch("caller/changeAudioDevice", { value, type });
    },
    toggleMinimizedMode() {
      this.$store.commit("caller/TOGGLE_MIN_MODE", true);
      this.$modal.hide("telephony-modal");
    },
  },
  filters: {
    timerFilter(totalSeconds) {
      function pad(val) {
        const valString = val + "";
        if (valString.length < 2) {
          return "0" + valString;
        } else {
          return valString;
        }
      }

      return `${pad(parseInt(totalSeconds / 60))}:${pad(totalSeconds % 60)}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.telephonyModal {
  &__minimizeButton {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 7px 12px;
    border-radius: 4px;
    border: 1px solid orange;
    background-color: unset;
    font_regular();

    @media (min-width: 375px) {
      top: 16px;
      right: 16px;
    }

    @media (min-width: desktop) {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 16px 24px;
    border-bottom: 2px solid grey_text_color;
  }

  &__avatar {
    margin-bottom: 12px;
  }

  &__avatarImg {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;

    @media (min-width: 375px) {
      width: 120px;
      height: 120px;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: yellow;
      font_subtitle();

      @media (min-width: 375px) {
        font(main_text_color, 34px, 700, 41px);
      }
    }
  }

  &__name {
    display: block;
    margin-bottom: 8px;
    font_subtitle();
  }

  &__statusBox {
    margin-bottom: 17px;
  }

  &__status {
    font_regular();
  }

  &__linksBox {
    display: grid;
    grid-gap: 10px;
    margin-bottom: 24px;

    @media (min-width: phone_xl) {
      display: block;
    }
  }

  &__link {
    padding: 7px 12px 8px;
    border-radius: 4px;
    background-color: #e6e7eb;
    font_regular();
    text-decoration: none;
    text-align: center;

    @media (min-width: phone_xl) {
      text-align: left;
    }

    @media (min-width: desktop) {
      &:hover {
        opacity: 0.6;
      }
    }

    &:not(:last-of-type) {
      margin-right: 4px;
    }
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    font_small();
  }

  &__selectBox {
    margin-bottom: 10px;

    @media (min-width: desktop) {
      margin-bottom: 0;
    }
  }

  &__selectWrap {
    position: relative;
    width: 198px;
    cell_fade();

    &::after {
      top: 2px;
      right: 24px;
      height: calc(100% - 4px);
    }
  }

  &__select {
    padding: 0 8px;
    width: 198px;
    height: 32px;
    border-radius: 2px;
    border: 1px solid grey_text_color;
    font_regular();
  }

  &__hangUp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px 12px 12px 4px;
    border-radius: 4px;
    background-image: linear-gradient(180deg, #f84d5b 0%, #e81526 100%);
    border: none;
    font(#fff, 14px, 400, 20px);

    &.callAvaiilable {
      background-image: linear-gradient(180deg, #19C564 0%, #5ED692 100%);
    }

    @media (min-width: desktop) {
      &:hover {
        opacity: 0.6;
      }
    }

    & svg {
      width: 32px;
      height: 13px;
    }

    & span {
      margin-left: 4px;
    }
  }

  &__footer {
    padding: 12px 16px 16px;

    @media (min-width: desktop) {
      display: flex;
      justify-content: space-between;
    }
  }

  @media (min-width: desktop) {
  }
}
</style>
