<template>
  <div class="leadsTelephonyAttention">
    <div class="leadsTelephonyAttention__text">
      <Tr>Для звонков лидам рекомендуем использовать</Tr>
      <a
        href="https://www.google.com/intl/en/chrome/"
        class="leadsTelephonyAttention__link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Tr>браузер Chrome.</Tr>
      </a>
      <Tr>В других браузерах возможна нестабильная работа.</Tr>
    </div>
    <button
      class="leadsTelephonyAttention__button"
      type="button"
      @click="$emit('set-cookie')"
    >
      <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
        <close-button />
      </base-icon-svg>
    </button>
  </div>
</template>

<script>
import CloseButton from "@/components/SvgIcons/CloseButton.vue";

export default {
  name: "LeadsTelephonyAttention",
  components: {
    CloseButton,
  },
};
</script>

<style lang="stylus" scoped>
.leadsTelephonyAttention {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 8px 12px;
  border: 1px solid #ff9f43;
  border-radius: 4px;

  &__text {
    font_regular();
  }

  &__link {
    color: secondary_text_color;
  }

  &__button {
    display: flex;
    margin-left: 10px;
    align-items: center;
    padding: 0;
    height: 18px;
    background-color: #fff;
    border: none;
  }
}
</style>
