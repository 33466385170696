<template>
  <div v-if="actionItem">
    <span class="tableItem__time">
      {{ dateFormatted(actionItem.seen_at) }}
    </span>
    <div class="tableItem__box">
      <div class="tableItem__title">
        <base-icon-svg
          width="16"
          height="16"
          viewbox="0 0 16 16"
          class="tableItem__icon"
        >
          <notification-messenger
            :class="[
              'tableItem__iconInner',
              {
                tableItem__iconInner_contact: false,
              },
            ]"
          />
        </base-icon-svg>
        <template v-if="true">
          <Tr>Автоматическое сообщение</Tr>
        </template>
        <template v-else-if="false">
          <Tr>Ручное сообщение.</Tr>
        </template>
      </div>
      <div
        :class="['tableItem__text', { tableItem__text_full: messageTextFull }]"
      >
        <!-- <p class="tableItem__textInner" v-html="message" /> -->
        <p
          class="tableItem__textInner"
          :id="`text_${actionItem.id}`"
          v-html="actionItem.text"
          v-if="actionItem.text"
        />
        <template v-if="actionItem.attachments">
          <span
            class="tableItem__textInner"
            v-for="attachment in actionItem.attachments"
            :key="`${attachment.type}_${attachment.url}`"
          >
            <Tr>Прикреплен файл</Tr>({{ attachment.type }})
          </span>
        </template>
      </div>
      <button
        :class="['showMoreBtn', { showMoreBtn_opened: messageTextFull }]"
        type="button"
        @click="showMore"
        v-if="!!chatbotTextiSLong"
      >
        <span />
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import NotificationMessenger from '@/components/SvgIcons/funnel/NotificationMessenger.vue';

export default {
  name: "UpdatedFunnelModalLeadChatbot",
  components: {
    NotificationMessenger,
  },
  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messageTextFull: false,
      chatbotTextiSLong: false,
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },
  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
    showMore() {
      this.messageTextFull = !this.messageTextFull;
    },
  },
  mounted() {
    if (this.actionItem.id) {
      if (document.querySelector(`#text_${this.actionItem.id}`)) {
        this.chatbotTextiSLong = document.querySelector(`#text_${this.actionItem.id}`).offsetHeight > 18;
      }
    }
  }
};
</script>

<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__icon {
    margin-right: 7px;
  }

  &__iconInner {
    color: #3489f8;

    &_contact {
      color: main_text_color;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
      opacity: 0.6;
    }
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 23px;
    overflow: hidden;
    // max-height: 18px;
    height: 18px;

    // transition: 0.4s;
    &_full {
      // max-height: fit-content;
      height: auto;
    }
  }

  &__textInner {
    word-break: break-word;
    white-space: break-spaces;
  }
}

.showMoreBtn {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: 24px;
  height: 12px;
  background-color: grey_text_color;
  border: 1px solid #bfb9b9;
  border-radius: 5px;
  cursor: pointer;

  span {
    position: relative;
    top: -1px;
    display: block;
    width: 4px;
    height: 4px;
    border-right: 1px solid #4c4b4b;
    border-bottom: 1px solid #4c4b4b;
    transform: rotate(45deg);
    // transition: 0.4s;
  }

  &_opened {
    span {
      top: 1px;
      transform: rotate(225deg);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}
</style>
