import Program from "@/models/program";
import { pathOr } from "ramda";
import Vue from "vue";

const transformResponseData = ({ results, ...rest }) => ({
  ...rest,
  results: results.map(course => new Program(course)),
});

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
  }),

  getters: {
    programs(state) {
      return state.data && state.data.results ? state.data.results : [];
    },
    pending(state) {
      return state.pending;
    },
    theNextPage(state) {
      return pathOr(0, ["data", "next_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    // async fetch({ commit }) {
    //   try {
    //     commit("SET_PENDING", true);
    //     const response = await Vue.axios.get("/leadership_quest_courses/");

    //     if (response.status === 200) {
    //       commit("SET_DATA", transformResponseData(response.data));
    //       commit("SET_PENDING", false);
    //     } else {
    //       throw response.data.message;
    //     }
    //   } catch (e) {
    //     commit("SET_PENDING", false);
    //   }
    // },

    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get("/leadership_quest_courses/");

        if (response.status === 200) {
          commit("SET_DATA", transformResponseData(response.data));
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
