import i18n from "@/plugins/i18n";
import router from "@/router";
import { runNotice } from "@/utils/notifications";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    errors: {},
    pending: false,
  }),

  getters: {
    tasksList(state) {
      return state.data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    UPDATE_LIST(state, list) {
      state.data = list;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    DELETE_TASK(state, lessonId) {
      state.data.results = state.data.results.filter(
        ({ id }) => id !== lessonId
      );
    },
  },

  actions: {
    setData({ commit }, data) {
      commit("SET_DATA", data);
    },

    updateTaskPositionMirroring({ commit }, list) {
      commit("UPDATE_LIST", list);
    },

    async updateTaskPosition(
      { dispatch },
      { lessonId, old_position, new_position }
    ) {
      try {
        const response = await Vue.axios.patch(
          `/leadership_quest_tasks/${lessonId}/move_target/`,
          { new_position: new_position, old_position: old_position }
        );
        if (response.status === 200) {
          runNotice("success", i18n.t("Список заданий обновлен"));
          const { lessonId } = router.currentRoute.params;
          dispatch("leadershipLesson/fetch", { lessonId }, { root: true });
        }
      } catch (e) {
        console.info(e);
      }
    },

    async deleteTask({ commit, dispatch }, { task }) {
      const { target_type, id } = task;

      const taskTypes = {
        poll: () =>
          dispatch("leadershipTaskPoll/deletePoll", id, { root: true }),
        action: () =>
          dispatch("leadershipTaskAction/deleteAction", id, {
            root: true,
          }),
        upload: () =>
          dispatch("leadershipTaskUpload/deleteUpload", id, {
            root: true,
          }),
        video: () =>
          dispatch("leadershipTaskVideo/deleteVideo", id, { root: true }),
      };

      Promise.all([taskTypes[target_type]()]).then(() => {
        const { lessonId } = router.currentRoute.params;
        dispatch("leadershipLesson/fetch", { lessonId }, { root: true });
      });
    },
  },
};
