<template>
  <new-lesson-container />
</template>

<script>
import NewLessonContainer from "@/containers/Leadership/NewLessonContainer.vue";

export default {
  name: "NewLesson",
  components: {
    NewLessonContainer,
  },
};
</script>
