<template>
  <div>
    <span class="tableItem__time">
      {{ dateFormatted(actionItem.started_at) }}
    </span>
    <div class="tableItem__box">
      <div class="tableItem__title">
        <base-icon-svg
          width="18"
          height="16"
          viewbox="0 0 18 16"
          class="tableItem__icon"
        >
          <webinar-modal />
        </base-icon-svg>
        <span>
          <Tr>Вебинар.</Tr>
          {{ actionItem.url }}
        </span>
      </div>
      <div class="tableItem__text">
        <template
          v-if="
            actionItem.type !== 'record' && actionItem.progress !== undefined
          "
        >
          <span class="tooltip-time"> <Tr>Просмотрено:</Tr></span>
          <span class="tooltip-progress">{{ actionItem.progress }}%</span>
        </template>
        <div
          class="webinarKeypoints"
          v-for="keypoint in actionItem.important"
          :key="keypoint.second"
        >
          <span
            :class="[
              'webinarKeypoints__icon',
              { webinarKeypoints__icon_visited: keypoint.visited },
            ]"
          />
          <span
            :class="[
              'webinarKeypoints__text',
              { webinarKeypoints__text_visited: keypoint.visited },
            ]"
          >
            <Tr>Ключевая точка в</Tr>{{ keypoint.time }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import WebinarModal from '@/components/SvgIcons/funnel/modal/WebinarModal.vue';

export default {
  name: "UpdatedFunnelModalLeadWebinar",
  components: {
    WebinarModal,
  },
  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },
  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__time {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 7px;
  }

  &__title {
    display: flex;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      align-items: center;
    }

    &>span {
      opacity: 0.6;
    }
  }

  &__text {
    margin-left: 25px;
  }
}

.updatedFunnelBodyItem__notificationIcon {
  color: #3489f8;

  &_contact {
    color: main_text_color;
  }
}

.tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 7px;
}

.webinarKeypoints {
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  &__icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: grey_text_color;

    &_visited {
      background-color: green_text_color;
    }
  }

  &__text {
    font_small();
    opacity: 0.6;

    &_visited {
      opacity: 1;
    }
  }
}
</style>
