<template>
  <div class="breadcrumbs">
    <base-link
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="breadcrumb.title + index"
      :to="breadcrumb.link"
      class="breadcrumbs__link"
    >
      <Tr>{{ breadcrumb.title }}</Tr>
    </base-link>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BaseBreadCrumbs",
  props: {
    breadcrumbs: Array,
  },
});
</script>

<style lang="stylus" scoped>
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 8px;
}

.breadcrumbs__link {
  position: relative;
  display: inline-block;
  font(#3489F8, 10px, 400, 12px);

  @media (min-width: 374px) {
    font-size: 12px;
    line-height: 15px;
  }

  &:first-of-type {
    &:before {
      display: none;
    }
  }
}

.breadcrumbs__link::before {
  position: absolute;
  content: '';
  // top: -2px;
  left: -24px;
  display: block;
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg opacity='0.3'%3E%3Cpath d='M0,0H16V16H0Z' fill='none'/%3E%3Cpath d='M12.115,8.741,8.539,5.165l.943-.943,5.185,5.185L9.481,14.593l-.943-.943,3.576-3.576H4V8.741Z' transform='translate(-1.333 -1.407)' fill='%230f1642'/%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}

.breadcrumbs__link + .breadcrumbs__link {
  margin-left: 32px;
  margin-bottom: 8px;

  @media (min-width: tablet_large) {
    display: inline-block;
    margin-bottom: 0px;
    min-width: 16px;
    min-height: 16px;
  }
}
</style>
