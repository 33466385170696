<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewbox"
    :fill="iconColor"
  >
    <slot />
  </svg>
</template>

<script>
export default {
  name: "BaseIconSvg",
  props: {
    iconName: {
      type: String,
      default: "box",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    viewbox: {
      type: String,
      default: "0 0 18 18",
    },
    iconColor: {
      type: String,
      default: "#0f1642",
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
}
</style>
