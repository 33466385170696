<template>
  <modal
    name="leads-create-link-without-chatbot"
    :width="640"
    height="auto"
    :min-height="200"
    :shiftY="0.2"
    :adaptive="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="leadsCreateLinkModal">
      <!-- HEADER -->
      <div class="leadsCreateLinkModal__header">
        <div class="leadsCreateLinkModal__headerName">
          <Tr>Создать ссылку</Tr>
        </div>
        <button
          class="leadsCreateLinkModal__headerButton"
          type="button"
          @click="hideLeadsCreateLink"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="loader" v-if="pending">
        <loader />
      </div>
      <div :class="{ layout: pending }">
        <leads-create-link-success-without-chatbot v-if="linkCreated" />
        <leads-create-link-without-chatbot :lead="lead" v-else />
      </div>
      <!-- BODY -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import LeadsCreateLinkWithoutChatbot from "@/components/Leads/LeadsCreateLinkWithoutChatbot.vue";
import LeadsCreateLinkSuccessWithoutChatbot from "@/components/Leads/LeadsCreateLinkSuccessWithoutChatbot.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "LeadsModalCreateLinkWithoutChatbot",
  components: {
    CloseButton,
    LeadsCreateLinkWithoutChatbot,
    LeadsCreateLinkSuccessWithoutChatbot,
    Loader,
  },
  data() {
    return {
      lead: {}
    }
  },
  computed: {
    ...mapGetters({
      linkCreated: "funnelCreateLinkWithoutChatbot/linkCreated",
      pending: "funnelCreateLinkWithoutChatbot/pending",
      products: "funnelCreateLinkWithoutChatbot/products",
      videos: "funnelCreateLinkWithoutChatbot/videos",
    }),
  },
  methods: {
    beforeOpen(e) {
      this.lead = e.params.lead;
      Promise.all([this.$store.dispatch("funnelCreateLinkWithoutChatbot/fetch")])
        .then(() => {
          this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_PRODUCT", this.products[0].id);
        })
        .then(() => {
          this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_VIDEO", this.videos[0].id);
        })
        .catch(e => {
          console.log(e);
        });
    },
    beforeClose() {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CLEAR_STORE");
    },
    hideLeadsCreateLink() {
      this.$modal.hide("leads-create-link-without-chatbot");
      this.$store.commit("funnelCreateLinkWithoutChatbot/CLEAR_STORE");
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLinkModal {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  height: 100%;

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    padding-bottom: 7px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    right: 6px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }
}

.loader {
  position: absolute;
}

.layout {
  opacity: 0.6;
}
</style>
