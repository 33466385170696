<template>
  <div class="underLoader">
    <div class="loader__box">
      <no-content
        v-if="!pending && leadsList.length !== undefined && isNoContent"
        :noContent="noContentEmpty"
      />

      <updated-assignment-menu
        v-if="leadsList.length > 0 || filtersFilled || filtersIsActive"
        :pickedLeads="pickedLeads"
        :pickedLeadsWithManagers="pickedLeadsWithManagers"
        :pickedLeadsDoesntHaveManagers="pickedLeadsDoesntHaveManagers"
        :pending="pending"
        @openAssignManagerModal="openAssignManagerModal"
        @openRemoveManagerModal="openRemoveManagerModal"
        :funnels="funnels"
        :tags="tags"
        :orders="orders"
        :managersFilter="true"
        :managers="managers"
        :filters="filters"
        :isFilterChecked="isFilterChecked"
        :pickedAllFunnels="pickedAllFunnels"
        :pickedAllTags="pickedAllTags"
        :pickedAllOrders="pickedAllOrders"
        :pickedAllManagers="pickedAllManagers"
        :funnelsFilterValue="funnelsFilterValue"
        :funnelsFilterCounter="funnelsFilterCounter"
        :tagsFilterValue="tagsFilterValue"
        :tagsFilterCounter="tagsFilterCounter"
        :ordersFilterValue="ordersFilterValue"
        :ordersFilterCounter="ordersFilterCounter"
        :managersFilterValue="managersFilterValue"
        :managersFilterCounter="managersFilterCounter"
        :search="search"
        :filtersIsActive="filtersIsActive"
        @changeFilter="changeFilter"
        @pickAllFunnels="pickAllFunnels"
        @pickAllTags="pickAllTags"
        @pickAllOrders="pickAllOrders"
        @pickAllManagers="pickAllManagers"
        @clearFilters="clearFilters"
        @changeFunnelsFilterValue="changeFunnelsFilterValue"
        @changeFunnelsFilterCounter="changeFunnelsFilterCounter"
        @changeTagsFilterValue="changeTagsFilterValue"
        @changeTagsFilterCounter="changeTagsFilterCounter"
        @changeOrdersFilterValue="changeOrdersFilterValue"
        @changeOrdersFilterCounter="changeOrdersFilterCounter"
        @changeManagersFilterValue="changeManagersFilterValue"
        @changeManagersFilterCounter="changeManagersFilterCounter"
        @change-search="changeSearch"
        @handleFilter="handleFilter"
      />

      <updated-assignment-body
        v-if="leadsList.length > 0 || filtersFilled || filtersIsActive"
        :leads="leadsList"
        :total="total"
        :hasSubscription="hasSubscription"
        :company="company"
        :isPickedLeadsChecked="isPickedLeadsChecked"
        @pick-lead="pickLead"
        @pick-all-leads="pickAllLeads"
      />
      <button
        class="showmoreButton"
        type="button"
        @click="fetchMore"
        v-if="hasNextPage && hasSubscription"
      >
        <Tr>Показать больше</Tr>
      </button>
      <div class="loader__layout" v-if="pending">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NoContent from "@/components/NoContent.vue";
import UpdatedAssignmentMenu from "@/components/Assignment/UpdatedAssignmentMenu.vue";
import UpdatedAssignmentBody from "@/components/Assignment/UpdatedAssignmentBody.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "UpdatedAssignmentContainer",
  components: {
    NoContent,
    UpdatedAssignmentMenu,
    UpdatedAssignmentBody,
    Loader,
  },
  data() {
    return {
      filtersIsActive: false,
    };
  },
  computed: {
    ...mapGetters({
      leadsList: "updatedAssignment/leadsList",
      pending: "updatedAssignment/pending",
      managers: "assignmentManagers/managers",
      chatbotManagers: "updatedAssignment/chatbotManagers",
      pickedLeads: "updatedAssignment/pickedLeads",
      pickedLeadsWithManagers: "updatedAssignment/pickedLeadsWithManagers",
      pickedLeadsDoesntHaveManagers:
        "updatedAssignment/pickedLeadsDoesntHaveManagers",
      isPickedLeadsChecked: "updatedAssignment/isPickedLeadsChecked",
      hasNextPage: "updatedAssignment/hasNextPage",
      currentPage: "updatedAssignment/currentPage",
      hasSubscription: "user/hasSubscription",
      total: "updatedAssignment/total",
      referalUrl: "user/referalUrl",
      company: "user/usersCompanySlug",

      filters: "updatedAssignment/filters",
      funnels: "updatedFilterFunnels/funnels",
      tags: "updatedTags/tags",
      orders: "updatedOrderStatuses/orders",
      isFilterChecked: "updatedAssignment/isFilterChecked",
      pickedAllFunnels: "updatedAssignment/pickedAllFunnels",
      pickedAllTags: "updatedAssignment/pickedAllTags",
      pickedAllOrders: "updatedAssignment/pickedAllOrders",
      pickedAllManagers: "updatedAssignment/pickedAllManagers",
      funnelsFilterValue: "updatedAssignment/funnelsFilterValue",
      funnelsFilterCounter: "updatedAssignment/funnelsFilterCounter",
      tagsFilterValue: "updatedAssignment/tagsFilterValue",
      tagsFilterCounter: "updatedAssignment/tagsFilterCounter",
      ordersFilterValue: "updatedAssignment/ordersFilterValue",
      ordersFilterCounter: "updatedAssignment/ordersFilterCounter",
      managersFilterValue: "updatedAssignment/managersFilterValue",
      managersFilterCounter: "updatedAssignment/managersFilterCounter",
      search: "updatedAssignment/search",
    }),
    noContentStart() {
      return { noFunnels: true };
    },
    noContentEmpty() {
      return {
        noFunnels: true,
        text: "Пусто. У вас еще нет ни одного лида",
        maxWidth: 0,
      };
    },
    outHref() {
      const base = this.$store.getters["user/mainSiteLink"];
      return base + "/profile/edit/?m=r";
    },
    filtersFilled() {
      return (
        (Object.keys(this.filters).length > 1 && this.leadsList.length === 0) ||
        (this.filters.q.length > 0 && this.leadsList.length === 0)
      );
    },
    isNoContent() {
      return (
        Object.keys(this.filters).length === 1 &&
        this.filters.q.length === 0 &&
        this.leadsList.length === 0
      );
    },
  },
  methods: {
    fetchMore() {
      this.$store.dispatch("updatedAssignment/fetch", {
        page: this.currentPage + 1,
      });
    },
    refreshLeads() {
      this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
    },
    reload() {
      window.location.reload();
    },
    openAssignManagerModal() {
      this.$modal.show("updated-assign-manager");
      this.$store.commit(
        "updatedAssignment/CHANGE_MANAGER",
        this.managers[0].id
      );
    },
    openRemoveManagerModal() {
      this.$modal.show("updated-remove-manager");
      this.$store.commit(
        "updatedAssignment/CHANGE_MANAGER",
        this.chatbotManagers[0]
      );
    },
    pickLead(value) {
      this.$store.commit("updatedAssignment/SET_PICKED_LEADS", value);
    },
    pickAllLeads() {
      this.$store.commit(
        "updatedAssignment/SET_PICKED_ALL_LEADS",
        this.leadsList
      );
    },

    changeFilter(payload) {
      this.$store
        .dispatch("updatedAssignment/changeFilter", payload)
        .then(() => {
          this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllFunnels(payload) {
      this.$store
        .dispatch("updatedAssignment/pickAllFunnels", payload)
        .then(() => {
          this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllTags(payload) {
      this.$store
        .dispatch("updatedAssignment/pickAllTags", payload)
        .then(() => {
          this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllOrders(payload) {
      this.$store
        .dispatch("updatedAssignment/pickAllOrders", payload)
        .then(() => {
          this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllManagers(payload) {
      this.$store
        .dispatch("updatedAssignment/pickAllManagers", payload)
        .then(() => {
          this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    clearFilters() {
      this.$store.commit("updatedAssignment/CLEAR_FILTERS");
      this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
    },
    changeFunnelsFilterValue(value) {
      this.$store.commit("updatedAssignment/SET_FUNNELS_FILTER_VALUE", value);
    },
    changeFunnelsFilterCounter(value) {
      this.$store.commit("updatedAssignment/SET_FUNNELS_FILTER_COUNTER", value);
    },
    changeTagsFilterValue(value) {
      this.$store.commit("updatedAssignment/SET_TAGS_FILTER_VALUE", value);
    },
    changeTagsFilterCounter(value) {
      this.$store.commit("updatedAssignment/SET_TAGS_FILTER_COUNTER", value);
    },
    changeOrdersFilterValue(value) {
      this.$store.commit("updatedAssignment/SET_ORDERS_FILTER_VALUE", value);
    },
    changeOrdersFilterCounter(value) {
      this.$store.commit("updatedAssignment/SET_ORDERS_FILTER_COUNTER", value);
    },
    changeManagersFilterValue(value) {
      this.$store.commit("updatedAssignment/SET_MANAGERS_FILTER_VALUE", value);
    },
    changeManagersFilterCounter(value) {
      this.$store.commit(
        "updatedAssignment/SET_MANAGERS_FILTER_COUNTER",
        value
      );
    },
    changeSearch(value) {
      this.$store.dispatch("updatedAssignment/changeSearch", { search: value });
    },

    handleFilter() {
      this.filtersIsActive = !this.filtersIsActive;
    },
  },
  mounted() {
    if (this.company === "bitbon" && !this.referalUrl) {
      window.location.replace(this.outHref);
    }
    this.$store.dispatch("updatedAssignment/fetch", { page: 1 })
      .then(response => {
        if (response && response.status === 200) {
          this.$store.dispatch("assignmentManagers/fetch");
          this.$store.dispatch("updatedFilterFunnels/fetch");
          this.$store.dispatch("updatedTags/fetch");
          this.$store.dispatch("updatedOrderStatuses/fetch");
        }
      })
      .catch(e => {
        console.log(e);
      });
    this.$store.dispatch("updatedFunnelLeads/fetchLeadsContacts");
  },
};
</script>

<style lang="stylus" scoped>
.funnel__startAndEmpty {
  display: grid;
  place-items: center;

  &Img {
    display: block;
    margin-bottom: 20px;
    width: 128px;
    height: 128px;
  }

  &Text {
    display: block;
    text-align: center;
    color: #0F1642;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: -0.1px;

    &Wrap {
      width: calc(100% - 18px);
      max-width: 460px;
    }
  }

  &Button {
    position: relative;
    display: block;
    margin-top: 20px;
    width: max-content;
    background-color: unset;
    border: none;
    border-radius: 4px;
    color: #0F1642;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &_activate {
      padding: 11px 12px 11px 40px;
      background: transparent linear-gradient(180deg, #FDBA7A 0%, orange 100%);
      color: #fff;
      text-decoration: none;

      &::before {
        position: absolute;
        content: '';
        top: 4px;
        left: 4px;
        width: 32px;
        height: 32px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H32V32H0Z'/%3E%3Cpath class='b' d='M15.333,28.667A13.333,13.333,0,1,1,28.667,15.333,13.333,13.333,0,0,1,15.333,28.667ZM11.187,16.893l.017-.009,1.16,3.827c.149.415.355.489.6.455a.922.922,0,0,0,.547-.325L15.1,19.309l3.4,2.517c.621.343,1.068.165,1.223-.576l2.209-10.429c.244-.971-.183-1.36-.936-1.051L8.017,14.784c-.885.355-.88.851-.16,1.071l3.329,1.04Z' transform='translate(0.667 0.667)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_createLink {
      padding: 7px 8px 5px 28px;
      border: 1px dashed main_text_color;

      &::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();
}

.loader {
  &__box {
    position: relative;
  }

  &__layout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
  }
}

.funnel__startAndEmptyReload {
  margin-top: 20px;
  color: #0F1642;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
  text-align: center;

  &Button {
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: secondary_text_color;
  }
}

@media (min-width: tablet_large) {
  .funnel__startAndEmptyTextWrap {
    width: 460px;
  }
}
</style>
