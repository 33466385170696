<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <h1 class="title titleAutowebinar">
      <Tr>Продаем ещё более лучше</Tr>
    </h1>

    <div class="autowebinar__wrap">
      <div class="autowebinar__header">
        <ul class="tabList">
          <li class="tabItem">
            <button class="tabButton_active tabButton">Описание</button>
          </li>
          <li class="tabItem">
            <button class="tabButton">Вебинар</button>
          </li>
        </ul>
      </div>

      <div class="autowebinar__body"></div>
    </div>

    <!-- ПОПАП -->
    <div class="autowebinar__popupWrap">
      <div class="autowebinar__popup">
        <!-- попап хедер -->
        <div class="autowebinar__popupHeader">
          <h2 class="autowebinar__popupHeaderTitle">
            <Tr>Список зрителей</Tr>
          </h2>
          <div class="autowebinar__popupHeaderBox">
            <div class="autowebinar__popupHeaderCount">
              <span class="autowebinar__popupHeaderCount_bold">122</span>
              <Tr>/500</Tr>
            </div>
            <button class="autowebinar__popupHeaderButton" type="button">
              <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
                <close-button />
              </base-icon-svg>
            </button>
          </div>
        </div>
        <!-- конец попап хедер -->

        <!-- попап боди -->
        <div class="autowebinar__popupBody">
          <div class="autowebinar__popupAudienceContainer">
            <ul class="autowebinar__popupBodyList">
              <li class="autowebinar__popupBodyItem">
                <div class="autowebinar__popupBodyItemUser">
                  <img
                    src="@/assets/images/profile_avatar.jpg"
                    alt="аватар зрителя"
                    class="autowebinar__popupBodyItemAvatar"
                  />
                  <span class="autowebinar__popupBodyItemName"
                    >Василий Петров</span
                  >
                </div>
                <div class="autowebinar__popupBodyItemControls">
                  <base-link
                    class="autowebinar__popupBodyItemControlsButton"
                    to="/leadership/autowebinars/:webinarId/webinar/edit-audience"
                  >
                    <base-icon-svg>
                      <editing-edit />
                    </base-icon-svg>
                  </base-link>
                  <button
                    type="button"
                    class="autowebinar__popupBodyItemControlsButton"
                  >
                    <base-icon-svg>
                      <editing-delete />
                    </base-icon-svg>
                  </button>
                </div>
              </li>
            </ul>
          </div>
          <div class="autowebinar__popupBodyCreate">
            <fieldset class="autowebinar__popupBodyFieldset">
              <div class="autowebinar__popupBodyFieldsetBox">
                <label for="createAmount" class="autowebinar__popupBodyLabel">
                  <Tr>Количество</Tr>
                </label>
                <input
                  type="number"
                  id="createAmount"
                  class="autowebinar__popupBodyInput autowebinar__popupBodyInput_audienceList"
                  value="240"
                />
              </div>
              <div class="autowebinar__popupBodyFieldsetBox">
                <label for="createGender" class="autowebinar__popupBodyLabel">
                  <Tr>Пол</Tr>
                </label>
                <select
                  id="createGender"
                  class="autowebinar__popupBodySelect autowebinar__popupBodySelect_audienceList"
                >
                  <option value="both">
                    <Tr>Оба</Tr>
                  </option>
                  <option value="male">
                    <Tr>Мужской</Tr>
                  </option>
                  <option value="female">
                    <Tr>Женский</Tr>
                  </option>
                </select>
              </div>
              <button type="button" class="autowebinar__popupBodyCreateButton">
                <Tr>Сгенерировать</Tr>
              </button>
            </fieldset>
            <base-link
              class="autowebinar__popupBodyCreateButton"
              to="/leadership/autowebinars/:webinarId/webinar/create-audience"
            >
              <Tr>Создать вручную</Tr>
            </base-link>
          </div>
        </div>
        <!-- конец попап боди -->

        <!-- попап футер -->
        <div class="autowebinar__popupFooter">
          <button
            class="autowebinar__popupFooterButton autowebinar__popupFooterButton_submit"
            type="button"
          >
            <Tr>Сохранить</Tr>
          </button>
        </div>
        <!-- конец попап футер -->
      </div>
    </div>
    <!-- КОНЕЦ ПОПАПА -->
  </main>
</template>

<script>
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import EditingEdit from "@/components/SvgIcons/EditingEdit.vue";

export default {
  name: "Autowebinars",
  components: {
    EditingDelete,
    CloseButton,
    EditingEdit,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        { title: "Автовебинары", link: "/leadership/autowebinars" },
      ],
      NAME_MAX_LENGTH: 64,
      noContent: { noContacts: true, text: "Загрузите слайды и видео" },
    };
  },
};
</script>

<style lang="stylus" scoped>
.titleAutowebinar {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-wrap: wrap;
}

.tabList {
  margin-right: -16px;
  margin-left: -16px;
  border-bottom: 4px solid grey_text_color;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  padding: 4px 16px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);
}

.tabButton_active {
  position: relative;
}

.tabButton_active::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff9f43;
}

.autowebinar__wrap {
  padding: 8px 16px 20px;
  min-width: 692px;
  width fit-content
  border-top: 3px solid transparent;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;
}

.autowebinar__body {
  position: relative;
  padding-top: 16px;
}

.autowebinar__popup {
  position: relative;
  top: 159px;
  right: 0;
  left: -1px;
  margin auto
  padding 16px 16px 20px 16px
  width 640px
  background-color white
  box-shadow: 0px 3px 10px #0F164214;
  border-radius: 8px;
  box-sizing border-box
  &Wrap {
    position: fixed;
    top: 1px;
    z-index: 10;
    left: -8px;
    width: 100vw;
    height: 100vh;
    background-color: #0F164250;
  }
  &Header {
    display flex
    justify-content space-between
    padding-bottom 12px
    &Title {
      font(main_text_color, 20px, 700, 24px);
    }
    &Count {
      display inline-flex
      align-items: flex-end;
      margin-right 16px
      & span {
        font_regular();
      }
      & .autowebinar__popupHeaderCount_bold {
        display: inline-block;
        margin-bottom: 1px;
        font-size 15px
        font-weight 700
      }
    }
    &Button {
      padding 0
      width 24px
      height 24px
      background-color unset
      border unset
    }
  }
  &AudienceContainer {
    position relative
    &::before {
      position absolute
      content ''
      top 0px
      left 0
      width calc(100% - 8px)
      height 12px
      background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      pointer-events none
    }
    &::after {
      position absolute
      content ''
      z-index 1
      bottom 0px
      left 0
      width calc(100% - 8px)
      height 12px
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
      pointer-events none
    }
  }
  &Body {
    padding 0px 0px 16px
    &List {
      height 276px
      overflow-y scroll
      scrollbar-color: grey_text_color white;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #fff;
      }
      &::-webkit-scrollbar-thumb {
        background-color: grey_text_color;
        border-radius: 4px;
      }
    }
    &Item {
      display flex
      justify-content space-between
      margin-right: 2px;
      margin-left 12px
      padding-right: 4px;
      background-color grey_text_color
      border-radius 0 2px 2px 0
      cursor pointer
      &:not(:last-child) {
        margin-bottom 4px
      }
      &User {
        margin-left -12px
      }
      &Avatar {
        margin-right 8px
        width 24px
        height 24px
        border-radius 50%
      }
      &Name {
        font_regular();
      }
      &Controls {
        display flex
        align-items center
        &Button {
          display inline-flex
          justify-content center
          align-items center
          padding unset
          width 16px
          height 16px
          background-color unset
          border none
          &:first-child {
            margin-right 12px
          }
        }
      }
    }
    &Create {
      display flex
      justify-content space-between
      align-items: flex-end;
      padding-top 16px
      &Button {
        padding 7px 7px 7px 8px
        height 32px
        background-color grey_text_color
        border none
        border-radius 4px
        font(main_text_color, 14px, 400, 18px);

      }
    }
    &Fieldset {
      display: grid;
      grid-template-columns: 80px 80px 128px;
      grid-column-gap: 8px;
      align-items: end;
    }
    &Label {
      display block
      margin-bottom 3px
      font(main_text_color, 12px, 400, 16px);
    }
    &Input,
    &Select {
      height: 32px;
      border-radius: 2px;
      border: 1px solid grey_text_color;
      font(main_text_color, 14px, 400, 18px);
      background-color: white;
      &_audienceList {
        width: 80px;
      }
    }
    &Input {
      padding 7px 7px 7px 8px
    }

    &Select {
      padding 3px
    }
  }

  &Footer {
    position relative
    border-top 2px solid grey_text_color
    margin 0 -16px
    padding 20px 16px 0px
    &Button {
      padding 11px 12px
      border-radius: 4px;
      border: unset;
      font_regular();
      &_submit {
      background: transparent linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
      color: #fff;
      }
    }
  }
}
</style>
