import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { find, propEq } from "ramda";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    form: {
      product_id: null,
      landing_id: null,
      funnel_id: null,
      name: "",
      description: "",
    },
    createdUrl: "",
    createdName: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    name(state) {
      return state.form.name;
    },
    description(state) {
      return state.form.description;
    },
    linkCreated(state) {
      return state.created;
    },
    products(state) {
      return state.data;
    },
    funnels(state) {
      if (state.form.product_id) {
        const product: any = find(propEq("id", Number(state.form.product_id)))(
          state.data
        );
        return product ? product.funnels : [];
      }
      return [];
    },
    landings(state) {
      if (state.form.product_id) {
        const product: any = find(propEq("id", Number(state.form.product_id)))(
          state.data
        );
        return product ? product.landings : [];
      }
      return [];
    },
    productId(state) {
      return state.form.product_id;
    },
    landingId(state) {
      return state.form.landing_id;
    },
    funnelId(state) {
      return state.form.funnel_id;
    },
    errors(state) {
      return state.errors;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
    createdName(state) {
      return state.createdName;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.form.name = value;
    },
    CHANGE_LANDING(state, value) {
      state.form.landing_id = Number(value);
    },
    CHANGE_FUNNEL(state, value) {
      state.form.funnel_id = Number(value);
    },
    CHANGE_PRODUCT(state, value) {
      state.form.product_id = Number(value);
      state.form.landing_id = null;
      state.form.funnel_id = null;
    },
    CHANGE_DESCRIPTION(state, description) {
      state.form.description = description;
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED(state, data) {
      state.createdName = data.name;
      state.createdUrl = data.url;
    },
    CLEAR_STORE(state) {
      state.form = {
        product_id: null,
        landing_id: null,
        funnel_id: null,
        name: "",
        description: "",
      };
      state.createdUrl = "";
      state.createdName = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async fetch({ commit, state }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(`/products`, axiosConfig(tasqToken));
        if (response.status === 200) {
          commit("SET_DATA", response.data.response);

          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async createLink({ commit, state }) {
      const data = { ...state.form };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/links`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 200) {
          commit("SET_CREATED", response.data.response);
          commit("LINK_CREATED", true);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
