<template>
  <div>
    <div key="video-hidden-box">
      <leadership-video-link :link="url" @change="handleVideoChange" />
      <div class="errors" v-if="errors">
        <div class="errors__item" v-if="errors.url_ru && errors.url_ru.length">
          RU:{{ " " }}
          <span :key="item" v-for="item in errors.url_ru">{{ item }}</span>
        </div>
        <div class="errors__item" v-if="errors.url_en && errors.url_en.length">
          EN:{{ " " }}
          <span :key="item" v-for="item in errors.url_en">{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeadershipVideoLink from "@/components/Leadership/LeadershipVideoLink.vue";
import { mapGetters } from "vuex";

export default {
  name: "TaskVideo",
  components: {
    LeadershipVideoLink,
  },
  props: {},
  computed: {
    ...mapGetters({
      url: "leadershipTaskVideo/url",
      lang: "leadershipTask/activeLang",
      errors: "leadershipTask/errors",
    }),
  },
  methods: {
    handleVideoChange(value) {
      this.$store.commit("leadershipTaskVideo/CHANGE_URL", {
        value,
        lang: this.lang,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.errors {
  margin-top: 5px;

  &__item {
    color: danger_text_color;
    font-size: 12px;

    span {
      &:not(:last-of-type) {
        &:after {
          content: ', ';
        }
      }
    }
  }
}
</style>
