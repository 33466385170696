<template>
  <button class="buttonDashed" @click="clickHandler">
    <Tr>{{ text }}</Tr>
    <template v-if="count > 0">
      <Tr>{{ "для" }}</Tr>
      <span class="count">
        {{ $tc("лидов", count, { n: count }) }}
      </span>
    </template>
  </button>
</template>

<script>
export default {
  name: "AssignmentButtonDashed",
  props: {
    text: String,
    count: Number
  },
  methods: {
    clickHandler() {
      this.$emit("button-click");
    },
  },
};
</script>

<style lang="stylus" scoped>
.buttonDashed {
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
}

.count {
  font-weight: 700;
}
</style>
