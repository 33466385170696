<template>
  <div class="underLoader">
    <div class="loaderBox">
      <div class="loaderIcon" v-if="pending">
        <loader />
      </div>

      <no-content v-if="!pending && isNoContent" :noContent="noContent" />

      <div class="mergeContactsBox">
        <contacts-groups-item
          v-for="mailGroup in dataMailDuplicates"
          :key="mailGroup.email"
          :group="mailGroup"
          @chooseGroup="chooseGroup"
        />

        <contacts-groups-item
          mergeType="phone"
          v-for="phoneGroup in dataPhoneDuplicates"
          :key="phoneGroup.phone"
          :group="phoneGroup"
          @chooseGroup="chooseGroup"
        />
      </div>

      <form-submit
        v-if="false"
        @click="openManualMergeModal"
        text="Ручное объединение"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

import NoContent from "@/components/NoContent.vue";
import Loader from "@/components/Loader.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import ContactsGroupsItem from "@/components/MergeContacts/ContactsGroupsItem.vue";

export default {
  name: "ContactsGroupsContainer",

  components: {
    NoContent,
    Loader,
    FormSubmit,
    ContactsGroupsItem,
  },

  data() {
    return {
      dataPendingMailDuplicates: false,
      dataPendingPhoneDuplicates: false,
      dataMailDuplicates: [],
      dataPhoneDuplicates: [],

      noContent: {
        text: "Пусто. У вас нет контактов для объединения",
        maxWidth: 0,
      },
    };
  },

  computed: {
    ...mapState(
      {
        pendingMailDuplicates: state => state.contactsUnion.pendingMailDuplicates,
        pendingPhoneDuplicates: state => state.contactsUnion.pendingPhoneDuplicates,
        mailDuplicates: state => state.contactsUnion.mailDuplicates,
        phoneDuplicates: state => state.contactsUnion.phoneDuplicates,
      }
    ),

    ...mapGetters({
      currentLang: "currentLang",
    }),

    pending() {
      return this.dataPendingMailDuplicates || this.dataPendingPhoneDuplicates;
    },

    isNoContent() {
      return this.dataPendingMailDuplicates === true ||
        this.dataPendingPhoneDuplicates === true ||
        (this.dataPhoneDuplicates.length === 0 && this.dataMailDuplicates.length === 0);
    },
  },

  methods: {
    openManualMergeModal() {
      alert("openManualMergeModal");
    },

    chooseGroup(contacts) {
      const contactsIds = contacts.map(el => el.id);

      this.$store.commit("contactsUnion/SET_CHOSEN_CONTACTS_GROUP_IDS", contactsIds);

      this.$store.commit("contactsUnion/SET_CHOSEN_CONTACTS_GROUP", contacts);

      this.$router.push({ path: `/${this.currentLang}/merge-contacts` });
    },
  },

  watch: {
    pendingMailDuplicates(value) {
      this.dataPendingMailDuplicates = value;
    },
    pendingPhoneDuplicates(value) {
      this.dataPendingPhoneDuplicates = value;
    },
    mailDuplicates(value) {
      this.dataMailDuplicates = [...value];
    },
    phoneDuplicates(value) {
      this.dataPhoneDuplicates = [...value];
    },
  },

  mounted() {
    this.$store.commit("contactsUnion/SET_CLEAR_CHOSEN_CONTACTS_GROUP");
    this.$store.commit("contactsUnion/SET_CLEAR_CHOSEN_CONTACTS_GROUP_IDS");
    this.$store.commit("contactsUnion/SET_CLEAR_CREATED_CONTACT");
    this.$store.dispatch("contactsUnion/fetchMailDuplicates");
    this.$store.dispatch("contactsUnion/fetchPhoneDuplicates");
  },
};
</script>

<style lang="stylus" scoped>
.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.mergeContactsBox {
  display: grid;
  grid-row-gap: 16px;
}
</style>
