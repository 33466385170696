<template>
  <TasksLayout :tasks="tasks" :activeTaskTab="activeTaskTab" />
</template>

<script>
import { mapGetters } from "vuex";
import TasksLayout from "@/components/Tasks/TasksLayout.vue";

export default {
  name: "TasksDone",
  components: {
    TasksLayout,
  },
  computed: {
    ...mapGetters({
      tasks: "leadTasks/tasks",
    }),

    activeTaskTab() {
      const pathNamearr = this.$route.path.split('/');
      return pathNamearr[pathNamearr.length - 1];
    },
  },
  mounted() {
    this.$store.dispatch("leadTasks/fetch", { page: 1, filter: this.activeTaskTab });
  },
};
</script>
