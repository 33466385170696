<template>
  <div class="filterBody">
    <div class="filterBody__box filterBody__dropdowns">
      <updated-leads-filter-double-dropdowns-group
        :title="'Дата'"
        @change="changeFilter"
        :start_created_at="filters.start_created_at"
        :finish_created_at="filters.finish_created_at"
        class="filterBody__lastAction"
        :date="true"
      />

      <div class="filterBody__checkboxes-item">
        <updated-funnel-filter-dropdowns-group
          :dropdownsGroup="funnelsCheckboxGroup"
          :isFilterChecked="isFilterChecked"
          :pickedAll="pickedAllFunnels"
          :filterValue="funnelsFilterValue"
          :filterCounter="funnelsFilterCounter"
          @change="changeFilter"
          @changeAll="pickAllFunnels"
          @change-filter-value="changeFunnelsFilterValue"
          @change-filter-counter="changeFunnelsFilterCounter"
        />
      </div>

      <div class="filterBody__checkboxes-item">
        <updated-funnel-filter-dropdowns-group
          :dropdownsGroup="tagsCheckboxGroup"
          :isFilterChecked="isFilterChecked"
          :pickedAll="pickedAllTags"
          :filterValue="tagsFilterValue"
          :filterCounter="tagsFilterCounter"
          @change="changeFilter"
          @changeAll="pickAllTags"
          @change-filter-value="changeTagsFilterValue"
          @change-filter-counter="changeTagsFilterCounter"
        />
      </div>

      <div class="filterBody__checkboxes-item">
        <updated-funnel-filter-dropdowns-group
          :dropdownsGroup="ordersCheckboxGroup"
          :isFilterChecked="isFilterChecked"
          :pickedAll="pickedAllOrders"
          :filterValue="ordersFilterValue"
          :filterCounter="ordersFilterCounter"
          @change="changeFilter"
          @changeAll="pickAllOrders"
          @change-filter-value="changeOrdersFilterValue"
          @change-filter-counter="changeOrdersFilterCounter"
        />
      </div>

      <div class="filterBody__checkboxes-item" v-if="managersFilter">
        <updated-funnel-filter-dropdowns-group
          :dropdownsGroup="managersCheckboxGroup"
          :isFilterChecked="isFilterChecked"
          :pickedAll="pickedAllManagers"
          :filterValue="managersFilterValue"
          :filterCounter="managersFilterCounter"
          @change="changeFilter"
          @changeAll="pickAllManagers"
          @change-filter-value="changeManagersFilterValue"
          @change-filter-counter="changeManagersFilterCounter"
        />
      </div>

      <div class="filterBody__checkboxes-item" v-if="statusesFilter">
        <updated-funnel-filter-dropdowns-group
          :dropdownsGroup="statusesCheckboxGroup"
          :isFilterChecked="isFilterChecked"
          :pickedAll="pickedAllStatuses"
          :filterValue="statusesFilterValue"
          :filterCounter="statusesFilterCounter"
          @change="changeFilter"
          @changeAll="pickAllStatuses"
          @change-filter-value="changeStatusesFilterValue"
          @change-filter-counter="changeStatusesFilterCounter"
        />
      </div>

      <updated-leads-filter-double-dropdowns-group
        :title="'Прогресс вебинара'"
        @change="changeFilter"
        :webinar_progress_from="filters.webinar_progress_from"
        :webinar_progress_to="filters.webinar_progress_to"
        class="filterBody__lastAction"
      />

      <updated-funnel-filter-checkboxes-group
        :checkboxGroup="webinarsCheckboxGroup"
        :isFilterChecked="isFilterChecked"
        @change="changeFilter"
        :killAllBtn="true"
        :isRadio="true"
      />
    </div>
    <clear-filters class="filterBody__clearFilters" @click="clearFilters" />
  </div>
</template>

<script>
import UpdatedFunnelFilterDropdownsGroup from "./UpdatedFunnelFilterDropdownsGroup.vue";
import UpdatedLeadsFilterDoubleDropdownsGroup from "@/components/Leads/UpdatedLeadsFilterDoubleDropdownsGroup.vue";
import UpdatedFunnelFilterCheckboxesGroup from "@/components/Funnel/UpdatedFunnelFilterCheckboxesGroup.vue";
import ClearFilters from "@/components/ClearFilters.vue";

export default {
  name: "UpdatedFunnelFilterBody",
  components: {
    UpdatedFunnelFilterDropdownsGroup,
    UpdatedLeadsFilterDoubleDropdownsGroup,
    UpdatedFunnelFilterCheckboxesGroup,
    ClearFilters,
  },
  props: {
    funnels: {
      type: Object,
    },
    tags: {
      type: Object,
    },
    orders: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    isFilterChecked: {
      type: Function,
    },
    pickedAllFunnels: {
      type: Boolean,
    },
    pickedAllTags: {
      type: Boolean,
    },
    pickedAllOrders: {
      type: Boolean,
    },
    pickedAllManagers: {
      type: Boolean,
    },
    pickedAllStatuses: {
      type: Boolean,
    },
    funnelsFilterValue: {
      type: [String, Number],
    },
    funnelsFilterCounter: {
      type: Number,
    },
    tagsFilterValue: {
      type: [String, Number],
    },
    tagsFilterCounter: {
      type: Number,
    },
    ordersFilterValue: {
      type: [String, Number],
    },
    ordersFilterCounter: {
      type: Number,
    },
    managersFilterValue: {
      type: [String, Number],
    },
    managersFilterCounter: {
      type: Number,
    },
    statusesFilterValue: {
      type: [String, Number],
    },
    statusesFilterCounter: {
      type: Number,
    },
    managersFilter: Boolean,
    managers: {
      type: Array,
    },
    statusesFilter: Boolean,
    statuses: {
      type: Array,
    },
  },
  computed: {
    funnelsCheckboxGroup() {
      return {
        title: "Воронка",
        name: "funnel_id",
        array: this.funnels.data,
      };
    },
    tagsCheckboxGroup() {
      return {
        title: "Тег",
        name: "tag_id",
        array: this.tags.data,
      };
    },
    ordersCheckboxGroup() {
      return {
        title: "Статус заказа",
        name: "order_status",
        array: this.orders.data,
      };
    },
    managersWithNullManager() {
      return [{ id: null, name: this.$t('Без менеджера') }, ...this.managers];
    },
    managersCheckboxGroup() {
      return {
        title: "Менеджер",
        name: "manager_id",
        array: this.managersWithNullManager,
      };
    },
    statusesCheckboxGroup() {
      return {
        title: "Статус",
        name: "status_id",
        array: this.statuses,
      };
    },
    webinarsCheckboxGroup() {
      return {
        title: "Посещение вебинара",
        name: "webinar_viewed",
        array: [{ id: 1, name: "Посетил" }, { id: 0, name: "Не посетил" }],
      };
    },
  },
  methods: {
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    pickAllFunnels(payload) {
      this.$emit("pickAllFunnels", payload);
    },
    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },
    pickAllOrders(payload) {
      this.$emit("pickAllOrders", payload);
    },
    pickAllManagers(payload) {
      this.$emit("pickAllManagers", payload);
    },
    pickAllStatuses(payload) {
      this.$emit("pickAllStatuses", payload);
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    changeFunnelsFilterValue(value) {
      this.$emit("changeFunnelsFilterValue", value);
    },
    changeFunnelsFilterCounter(value) {
      this.$emit("changeFunnelsFilterCounter", value);
    },
    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },
    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
    changeOrdersFilterValue(value) {
      this.$emit("changeOrdersFilterValue", value);
    },
    changeOrdersFilterCounter(value) {
      this.$emit("changeOrdersFilterCounter", value);
    },
    changeManagersFilterValue(value) {
      this.$emit("changeManagersFilterValue", value);
    },
    changeManagersFilterCounter(value) {
      this.$emit("changeManagersFilterCounter", value);
    },
    changeStatusesFilterValue(value) {
      this.$emit("changeStatusesFilterValue", value);
    },
    changeStatusesFilterCounter(value) {
      this.$emit("changeStatusesFilterCounter", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterBody {
  position: relative;
  display: grid;
  grid-row-gap: 16px;
  padding: 15px 20px 20px 20px;
  margin-top: 7px;
  margin-bottom: 20px;
  width: fit-content;
  border: 1px solid grey_text_color;
  border-radius: 4px;

  &__clearFilters {
    margin-bottom: 15px;
  }

  @media (min-width: desktop) {
    margin-top: 20px;

    &__clearFilters {
      position: absolute;
      right: 20px;
      bottom: 20px;
      margin-bottom: 0;
    }
  }
}

.filterBody__box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.filterBody__checkboxes-item {
  margin-right: 20px;
  width: 200px;
}

@media (min-width: desktop) {
  .filterBody__box {
    display: grid;
    grid-template-columns: repeat(4, 220px);
    grid-column-gap: 16px;
    margin-bottom: 20px;
    width: fit-content;
  }

  .filterBody__checkboxes-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.filterBody__lastAction {
  margin-bottom: 20px;
}
</style>
