<template>
  <span class="footer__copyright">TASQ © {{ year }}</span>
</template>

<script>
export default {
  name: "FooterCopyright",
  computed: {
    year() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
</script>

<style lang="stylus" scoped>
.footer__copyright {
  display: inline-block;
  margin-top: 7px;
  color: #0f1642;
  // font-size: 8px;
  // line-height: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
}
</style>
