import numeral from "numeral";

// const slideToPixels = ({ slide, nextSlide, videoDuration, width }) => {
//   const start = numeral(slide.seconds);
//   const end = nextSlide ? numeral(nextSlide.seconds) : numeral(videoDuration);
//   const coef = numeral(videoDuration)
//     .divide(width)
//     .format("0.00");

//   const newSlide = {
//     ...slide,
//     start: start.divide(coef).format("0"),
//     end: end.divide(coef).format("0"),
//   };

//   return newSlide;
// };
const slideToPixels = ({ slide, nextSlide, videoDuration }) => ({
  ...slide,
  start: slide.seconds,
  end: nextSlide ? nextSlide.seconds : videoDuration,
});

export { slideToPixels };
