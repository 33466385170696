<template>
  <div class="loaderLayout">
    <loader class="loaderLayout__loader" />
  </div>
</template>
<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "LayoutLoader",
  components: {
    Loader,
  },
};
</script>

<style lang="stylus" scoped>
.loaderLayout {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
}
</style>