// import supportedLangs from "@/utils/supportedLangs";
// import { pathOr } from "ramda";

import MentorCheck from "@/models/mentorCheck";
import router from "@/router";
import pickGeneral from "@/utils/pickGeneral";
import postfixParser from "@/utils/postfixParser";
import { omit, pathOr, pick } from "ramda";
import Vue from "vue";
import { runNotice } from "@/utils/notifications";
import i18n from "@/plugins/i18n";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),
  getters: {
    title(state, getters, rootState) {
      const lang = rootState.leadershipTask.activeLang;
      return pathOr("", ["data", "locales", lang, "content"], state);
    },
    isValid() {
      return false;
    },
    pending(state) {
      return state.pending;
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },

    CHANGE_DESCRIPTION(state, { value, lang }) {
      state.data.locales[lang].content = value;
    },
  },
  actions: {
    initNew({ commit }) {
      commit("SET_DATA", new MentorCheck());
    },
    init({ commit }, data) {
      commit("SET_DATA", new MentorCheck(data));
    },
    changeDescription({ commit, rootState }, value) {
      commit("CHANGE_DESCRIPTION", {
        value,
        lang: rootState.leadershipTask.activeLang,
      });
    },

    async fetch({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(
          `/leadership_quest_task_actions/${id}/`
        );
        if (response.status === 200) {
          commit("SET_DATA", new MentorCheck(response.data));
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
        console.info(e);
      }
    },

    saveAction({ state, dispatch }, data) {
      const { id } = state.data;
      const generalData = pickGeneral(data);

      if (id) {
        dispatch("patchAction", generalData);
      } else {
        dispatch("postAction", generalData);
      }
    },

    async postAction({ state, commit, rootState, dispatch }, generalData) {
      try {
        dispatch("leadershipTask/dropErrors", undefined, { root: true });
        commit("SET_PENDING", true);

        const commonData = postfixParser.toPrefix(omit(["type"], generalData));
        const actionData = postfixParser.toPrefix(
          pick(["locales", "position"], state.data)
        );

        const data = {
          ...actionData,
          ...commonData,
          quest_task: rootState.leadershipLesson.data.id,
        };
        const response = await Vue.axios.post(
          "/leadership_quest_task_actions/",
          data
        );
        if (response.status === 201) {
          commit("SET_PENDING", false);
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
          const { courseId, lessonId, lang } = router.currentRoute.params;
          router.push(
            `/${lang}/leadership/courses/${courseId}/lessons/${lessonId}/works?type=video`
          );
        }
      } catch (e) {
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
        commit("SET_PENDING", false);
      }
    },

    async patchAction({ state, commit, rootState, dispatch }, generalData) {
      try {
        dispatch("leadershipTask/dropErrors", undefined, { root: true });
        commit("SET_PENDING", true);

        const commonData = postfixParser.toPrefix(omit(["type"], generalData));
        const picked: any = pick(["locales"], state.data);
        const withouTitle = postfixParser.deleteFromLocales(
          picked.locales,
          "title"
        );
        const actionData = postfixParser.toPrefix(withouTitle);

        const data = {
          ...commonData,
          ...actionData,
        };

        const response = await Vue.axios.patch(
          `/leadership_quest_task_actions/${state.data.id}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          runNotice("success", i18n.t("Сохранено"));
          dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        commit("SET_PENDING", false);
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
      }
    },

    async deleteAction({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(
          `/leadership_quest_task_actions/${id}/`
        );
        if (response.status === 204) {
          commit("SET_PENDING", false);
          return true;
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
