<template>
  <main class="main">
    <updated-funnel-links-list-container />
  </main>
</template>

<script>
import UpdatedFunnelLinksListContainer from "@/containers/Funnel/UpdatedFunnelLinksListContainer.vue";

export default {
  name: "UpdatedFunnelLinksList",
  components: {
    UpdatedFunnelLinksListContainer,
  },
};
</script>
