<template>
  <base-input-text
    v-bind="{ ...$props }"
    @handle-input="handleInput"
    :error="error"
    :type="type"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: "ContactsProfileInfoInput",

  props: {
    title: String,

    value: String,

    labelId: String,

    inputWidth: {
      type: Number,
      required: false,
    },

    type: String,

    placeholder: String,

    labelBold: Boolean,
  },

  data() {
    return {
      error: false,
    };
  },

  methods: {
    handleInput(value) {
      this.$emit("handle-input", { value: value, param: this.labelId });
      if (this.labelId === 'first_name' && value.length === 0) {
        this.error = true;
      } else {
        this.error = false;
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
