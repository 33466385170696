<template>
  <modal-layout
    :name="modalName"
    @before-open="beforeOpen"
    @before-close="closeModal"
    modalHeight="400px"
  >
    <template #title
      >{{ lead.id }}
      {{ lead.trigger && lead.trigger.name ? lead.trigger.name : "" }}</template
    >
    <template #body>
      <div class="callToLead__header">
        <!-- <base-button /> -->
      </div>
      <div class="callToLead__body">
        <div class="callToLead__title">
          <template v-if="trigger && trigger.source === 'form'">
            <Tr>Заявка с формы лендинга</Tr
            ><span>{{ landingUrl(trigger) }}</span>
          </template>
          <template v-if="trigger && trigger && trigger.phone">
            <updated-leads-call-to-lead-modal-phone
              :trigger="trigger"
              @openTelephonyModal="
                openTelephonyModal({ lead, phone: trigger.phone })
              "
            />
          </template>
        </div>
        <template v-if="lead.triggers && lead.triggers.length > 0">
          <div
            class="callToLead__title"
            v-for="(trigger, index) in lead.triggers"
            :key="trigger.id"
          >
            <template v-if="!phoneRepeated(trigger, index)">
              <template v-if="trigger.source === 'form'">
                <template
                  v-if="landingUrl(trigger).includes('oton.education/online')"
                >
                  <Tr>Заявка с формы лендинга</Tr>
                  <span>{{ landingUrl(trigger) }}</span>
                </template>
                <template v-else>
                  <Tr>Повторная заявка с формы лендинга</Tr>
                  <span>{{ landingUrl(trigger) }}</span>
                </template>
              </template>
              <template v-if="trigger.phone">
                <updated-leads-call-to-lead-modal-phone
                  :trigger="trigger"
                  @openTelephonyModal="
                    openTelephonyModal({ lead, phone: trigger.phone })
                  "
                />
              </template>
            </template>
          </div>
        </template>
      </div>
    </template>
  </modal-layout>
</template>

<script>
import { runNotice } from "@/utils/notifications";

import ModalLayout from "@/components/ModalLayout.vue";
import UpdatedLeadsCallToLeadModalPhone from "@/components/Leads/UpdatedLeadsCallToLeadModalPhone.vue";

export default {
  name: "UpdatedLeadsCallToLeadModal",

  components: {
    ModalLayout,
    UpdatedLeadsCallToLeadModalPhone,
  },

  data() {
    return {
      modalName: "call-to-lead",
      lead: {},
    };
  },

  computed: {
    trigger() {
      if (Object.keys(this.lead).length > 0) {
        return this.lead.trigger;
      }
      return null
    },

  },

  methods: {
    closeModal() {
      console.info('closeModal');
    },

    beforeOpen(event) {
      this.lead = event.params.lead;
    },

    landingUrl(trigger) {
      if (trigger.page) {
        let arr = trigger.page.split("?");
        arr = arr.slice(0, 1);
        return arr.join("");
      }
      return "";
    },

    phoneRepeated(trigger, index) {
      // const phonesEqualWithPlus = trigger.phone && trigger.phone.startsWith("+")
      //   ? trigger.phone.slice(1) === this.lead.trigger.phone
      //   : false;

      const phoneIsTriggerPhone = trigger.phone === this.lead.trigger.phone;

      const equalTriggersPhones = this.lead.triggers.slice(0, index).map(triggersItem => triggersItem.phone).includes(trigger.phone);

      const triggersPhonesEqual =
        index !== 0
          ? equalTriggersPhones ||
          phoneIsTriggerPhone
          : phoneIsTriggerPhone;

      // const triggersPhonesWithPlusEqual =
      //   index !== 0
      //     ? trigger.phone && trigger.phone.startsWith("+")
      //       ? trigger.phone.slice(1) === this.lead.triggers[index - 1].phone
      //       : false
      //     : false;

      return triggersPhonesEqual;
    },

    openTelephonyModal(data) {
      const startCall = () => {
        Promise.all([this.$store.dispatch("caller/initAudioDevices")])
          .then(() => {
            this.$store.dispatch("caller/startCall", data);
            this.$modal.hide(this.modalName);
            this.$modal.show("telephony-modal");
          })
          .catch(e => {
            console.log(e);
          });
      };
      const noAccess = () =>
        runNotice(
          "error",
          this.$t("Включите доступ к микрофону в настройках браузера")
        );

      if (this.isFirefox) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function () {
            startCall();
          })
          .catch(function (err) {
            console.log("No mic for you!");
          });
        return;
      }

      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "microphone" })
          .then(result => {
            const permission = result.state;

            if (permission === "granted") {
              startCall();
            } else {
              navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function () {
                  startCall();
                })
                .catch(function (err) {
                  console.log("No mic access");
                  noAccess();
                });
            }
          })
          .catch(error => {
            console.log("Got error :", error);
            runNotice(
              "error",
              this.$t("Включите доступ к микрофону в настройках браузера")
            );
          });
      } else {
        startCall();
      }

      return;
    },
  },
};
</script>

<style lang="stylus" scoped>
.callToLead__body {
  // padding-top: 16px;
  font_regular();
}

.callToLead__title {
  &>span {
    margin-right: 5px;
    opacity: 0.6;
  }
}
</style>
