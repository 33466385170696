const setFavicon = (slug: string, url?: string | false) => {
  if (url) {
    const isChrome = navigator.userAgent.indexOf("Chrome") != -1;
    const isSafari = navigator.userAgent.indexOf("Safari") != -1;

    const companyUrl = process.env[`VUE_APP_MAIN_${slug.toUpperCase()}_URL`];
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = `${companyUrl}${url}`;
    if (isSafari && !isChrome) {
      link.type = "image/x-icon";
    }
    document.head.appendChild(link);
  }
};

export default setFavicon;
