<template>
  <modal-layout :pending="pending" :name="modalName" @before-close="closeModal">
    <template #title>Создать напоминание</template>
    <template #body>
      <div class="inputBox">
        <base-input-text
          class="contactReminderModal__name"
          title="Название"
          labelId="reminder_name"
          @handle-input="handleInput"
          :maxlength="MAX_TEXT_LENGTH"
        />
        <div class="inputCounterBox">
          <span class="inputCounter">
            <span
              :class="{
                inputCounter_red: valueLength === MAX_TEXT_LENGTH,
              }"
              >{{ valueLength }}</span
            >
            /{{ MAX_TEXT_LENGTH }}
          </span>
        </div>
      </div>
      <textarea-maxlength
        class="contactReminderModal__textarea"
        label="Описание"
        labelId="description"
        :height="100"
        :withCounter="true"
        @handleTextarea="handleTextarea"
      />
      <div class="contactReminderModal__datePickerBox datePickerBox">
        <label class="label">
          <Tr>Крайний срок закрытия задачи</Tr>
        </label>
        <date-picker
          type="datetime"
          format="DD MMM YYYY, HH:mm"
          :lang="
            ['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale
          "
          v-model="startedAt"
          :default-value="new Date().setHours(10, 0, 0, 0)"
          :disabled-date="notBeforeToday"
        />
      </div>
      <form-submit @click="createReminder" :disabled="createBtnDisabled" />
    </template>
  </modal-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

import ModalLayout from "@/components/ModalLayout.vue";
import DatePicker from "vue2-datepicker";
import TextareaMaxlength from "@/components/TextareaMaxlength.vue";
import FormSubmit from "@/components/FormSubmit.vue";

export default {
  name: "ContactsProfileModalCreateReminder",

  components: {
    ModalLayout,
    TextareaMaxlength,
    DatePicker,
    FormSubmit,
  },

  data() {
    return {
      modalName: "create-reminder",
      valueLength: 0,
      MAX_TEXT_LENGTH: 250,
    };
  },

  computed: {
    ...mapGetters({
      pending: "contactsProfileReminders/pending",
      errors: "contactsProfileReminders/errors",
      createdReminder: "contactsProfileReminders/createdReminder",
      isDev: "user/isDev",
      contact: "contactsProfile/contact",
    }),

    startedAt: {
      get() {
        return this.$store.getters["contactsProfileReminders/startedAt"];
      },

      set(value) {
        this.$store.commit(
          "contactsProfileReminders/SET_REMINDER_STARTED_AT",
          value
        );
      },
    },

    createBtnDisabled() {
      if (this.createdReminder.name.length === 0) return true;
      else if (this.createdReminder.description.length === 0) return true;
      else if (this.createdReminder.started_at === null) return true;
      return false;
    },

    activeTaskTab() {
      const pathNamearr = this.$route.path.split("/");
      return pathNamearr[pathNamearr.length - 1];
    },
  },

  methods: {
    handleInput(value) {
      this.$store.commit("contactsProfileReminders/SET_REMINDER_NAME", value);
      this.valueLength = value.length;
    },

    handleTextarea(value) {
      this.$store.commit(
        "contactsProfileReminders/SET_REMINDER_DESCRIPTION",
        value
      );
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    closeModal() {
      this.$store.commit("contactsProfileReminders/CLEAR_REMINDER");
      this.valueLength = 0;
    },

    createReminder() {
      const date = new Date(this.createdReminder.started_at).toISOString();

      let contentType;
      this.isDev ? (contentType = 8) : (contentType = 7);

      const data = {
        object_id: this.contact.id,
        contact: this.contact.id,
        content_type: contentType,
        title: this.createdReminder.name,
        description: this.createdReminder.description,
        ended_at: date,
        type: "Напоминание про контакт",
      };

      this.$store.dispatch("contactsProfileReminders/createReminder", data)
        .then(response => {
          if (response) {
            if (response.status === 201) {
              runNotice("success", this.$t("Напоминание создано"));
              this.$modal.hide(this.modalName);
              this.$store.dispatch("contactsProfileReminders/fetch", {
                page: 1,
                contact: this.contact.id,
                filter: this.activeTaskTab,
              });
            } else {
              runNotice("error", this.$t("Не удается создать напоминание"));
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    },
  },

  watch: {
    errors(val) {
      Object.values(val).forEach(el => runNotice("error", el));
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactReminderModal__name, .contactReminderModal__datePickerBox, .contactReminderModal__textarea {
  margin-bottom: 16px;
}

.datePickerBox {
  display: block;
}

.label {
  display: block;
  margin-bottom: 4px;
  font(main_text_color, 12px, 400, 16px);
}

.inputBox {
  position: relative;
  width: fit-content;
}

.inputCounterBox {
  position: absolute;
  top: 0;
  right: 0;
}

.inputCounter {
  font_small();
  opacity: 0.6;

  & span {
    font-weight: 700;
  }

  &_red {
    color: #e81526;
  }
}
</style>
