<template>
  <leadership-layout :title="title" :breadcrumbs="breadcrumbs" :tabs="tabs">
    <leadership-program
      v-if="program"
      :course="program"
      :errors="errors ? errors.errors : {}"
      :lang="lang"
      :supportedLangsTitled="supportedLangsTitled"
      :courseName="name"
      :courseDescription="description"
      :langTabs="langTabs"
      :isPublished="isPublished"
      :isNew="isNew"
      :cost="cost"
      :paymentType="paymentType"
      @change-active-lang="handleChangeLang"
      @change-languages="handleChangeLanguages"
      @change-name="handleChangeName"
      @change-description="handleChangeDescription"
      @save-course="save"
      @publish-course="publish"
      @change-price="changePrice"
      @change-payment-type="changePaymentType"
    />
  </leadership-layout>
</template>

<script>
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipProgram from "@/components/Leadership/LeadershipProgram.vue";
import { mapGetters } from "vuex";

export default {
  name: "ProgramContainer",
  components: {
    LeadershipLayout,
    LeadershipProgram,
  },
  props: {
    isNew: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    breadcrumbs() {
      return [
        { title: "Настройки лидера", link: "/leadership" },
        {
          title: "Программы обучения",
          link: "/leadership/programs",
        },
      ];
    },
    tabs() {
      const { programId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/programs/${
            !this.isNew ? programId + "/description" : "new/description"
          }`,
          exact: true,
        },
        ...(!this.isNew
          ? [
              {
                title: "Квесты",
                link: `/leadership/programs/${programId}/courses`,
                exact: true,
              },
            ]
          : []),
      ];
    },
    title() {
      if (this.isNew) {
        return this.$t("Новая программа");
      }
      return this.name;
    },
    ...mapGetters({
      program: "leadershipProgram/program",
      pending: "leadershipProgram/pending",
      lang: "leadershipProgram/lang",
      errors: "leadershipProgram/errors",
      supportedLangsTitled: "supportedLangsTitled",
      name: "leadershipProgram/name",
      description: "leadershipProgram/description",
      langTabs: "leadershipProgram/langTabs",
      cost: "leadershipProgram/cost",
      isPublished: "leadershipProgram/isPublished",
      paymentType: "leadershipProgram/paymentType",
    }),
  },
  methods: {
    handleChangeLang(lang) {
      this.$store.commit("leadershipProgram/TOGGLE_LANG", lang);
    },
    handleChangeLanguages(lang) {
      this.$store.commit("leadershipProgram/TOGGLE_PROGRAM_LANG", lang);
    },
    handleChangeName(value) {
      this.$store.commit("leadershipProgram/CHANGE_NAME", value);
    },
    handleChangeDescription(value) {
      this.$store.commit("leadershipProgram/CHANGE_DESCRIPTION", value);
    },
    save() {
      if (this.isNew) {
        this.$store.dispatch("leadershipProgram/create");
      } else {
        this.$store.dispatch("leadershipProgram/update");
      }
    },
    changePrice(value) {
      this.$store.commit("leadershipProgram/CHANGE_COST", value);
    },
    changePaymentType(value) {
      this.$store.commit("leadershipProgram/TOGGLE_COST", value);
    },
    publish() {
      this.$store.dispatch("leadershipProgram/publish");
    },
  },
  mounted() {
    if (this.isNew) {
      this.$store.dispatch("leadershipProgram/init");
    } else {
      const { programId } = this.$route.params;
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }
  },
};
</script>

<style lang="stylus" scoped></style>
