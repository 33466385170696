<template>
  <main class="main">
    <BaseBreadcrumbs
      :breadcrumbs="breadcrumbs"
      class="leadsProfileBreadcrumbs"
    />
    <div class="loaderBox">
      <div class="loaderIcon" v-if="pending">
        <loader />
      </div>
      <updated-lead-profile
        v-if="Object.keys(lead).length > 0"
        :tabs="tabs"
        :activeTab="activeTab"
        :leadsPage="true"
        :lead="lead"
        :calls="calls"
      />
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";

import BaseBreadcrumbs from "@/components/common/BaseBreadcrumbs.vue";
import UpdatedLeadProfile from "@/components/Leads/UpdatedLeadProfile.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "UpdatedLeadContainer",
  components: {
    BaseBreadcrumbs,
    UpdatedLeadProfile,
    Loader,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Лиды", link: "/leads" }],
      activeTab: "events",
    };
  },
  computed: {
    ...mapGetters({
      lead: "updatedLead/lead",
      pending: "updatedLead/pending",
      calls: "leadsCalls/calls",
      callsPending: "leadsCalls/pending",
    }),
    tabs() {
      if (this.lead.funnel && this.lead.funnel.is_restarted) {
        return [
          {
            title: "События",
            name: "events",
          },
          {
            title: "Инфо",
            name: "info",
          },
          {
            title: "Заметки",
            name: "notes",
          },
          {
            title: "Ссылка на оплату",
            name: "paymentLink",
          },
          {
            title: "Ссылка на вебинар",
            name: "webinarLink",
          },
          {
            title: "Перезапуск воронки",
            name: "restartFunnel",
          },
          {
            title: "История звонков",
            name: "callsHistory",
          },
        ];
      }
      return [
        {
          title: "События",
          name: "events",
        },
        {
          title: "Инфо",
          name: "info",
        },
        {
          title: "Заметки",
          name: "notes",
        },
        {
          title: "Ссылка на оплату",
          name: "paymentLink",
        },
        {
          title: "Ссылка на вебинар",
          name: "webinarLink",
        },
        {
          title: "История звонков",
          name: "callsHistory",
        },
      ];
    },
  },
  mounted() {
    const { leadId } = this.$route.params;
    this.$store.dispatch("updatedLead/fetch", leadId);
    this.$store.dispatch("updatedLeadsStatuses/fetch");
    this.$store.dispatch("updatedFilterFunnels/fetch");
    this.$store.dispatch("updatedLeadsCreateLinkToWebinar/fetchProducts");
    this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
    this.$store.dispatch("updatedFunnelLeads/fetchLeadsContacts");
    this.$store.dispatch("leadsCalls/fetch", { pageNum: 1, leadId });
  },
};
</script>

<style lang="stylus" scoped>
.leadsProfileBreadcrumbs {
  margin-bottom: 15px;
}

.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
  z-index: 100;
}
</style>
