<template>
  <router-link
    class="link"
    :to="toWithPrefix"
    v-bind="{ ...$props, ...$attrs }"
  >
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "BaseLink",
  props: {
    to: {
      type: [String, Object],
      required: true,
    },
    out: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    toWithPrefix() {
      if (typeof this.to === "string") {
        if (this.$route.params.lang) {
          return `/${this.$route.params.lang}${this.to}`;
        }
        return `/${this.$i18n.locale}${this.to}`;
      }
      const obj = {
        ...this.to,
        path: `/${this.to.path}`,
      };
      return obj;
    },
  },
};
</script>

<style lang="stylus">
.link {
  text-decoration: none;
  outline: none !important;

  * {
    text-decoration: none;
    outline: none;
  }
}
</style>