import { pick } from "ramda";

const getAwards = (data?) =>
  data
    ? pick(
        [
          "approve_reward_brilliant",
          "approve_reward_expire_brilliant",
          "reward_expire_gold",
          "reward_gold",
        ],
        data
      )
    : {
        approve_reward_brilliant: 0,
        approve_reward_expire_brilliant: 0,
        reward_expire_gold: 0,
        reward_gold: 0,
      };

export default getAwards;
