import axiosConfig from "@/utils/axiosConfigFunnel";
import Cookies from "js-cookie";
import axios from "axios";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    lead(state) {
      return state.data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data.data;
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    UPDATE_LEAD_STATUS(state, status) {
      state.data.status = status;
    },
  },

  actions: {
    async fetch({ commit, state }, leadId) {
      if (state.pending) {
        return;
      }

      try {
        commit("SET_PENDING", true);
        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/leads/${leadId}`,
          axiosConfig(tasqToken)
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async updateLeadStatus({ commit }, status) {
      commit("UPDATE_LEAD_STATUS", status);
    },
  },
};
