<template>
  <div class="autowebinar__uploadButtonWrap">
    <div class="newVideo" v-if="newVideo">
      <form
        id="videoUploadForm"
        method="POST"
        :action="uploadLink"
        enctype="multipart/form-data"
      >
        <div class="fileMask">
          <input
            type="file"
            name="file_data"
            @change="openFileModal"
            ref="videoUpload"
          />
          <button class="autowebinar__uploadButton">
            <Tr>Выбрать файл</Tr>
          </button>
        </div>
        <span class="cancel" @click="handleNewVideo">
          <Tr>Отмена</Tr>
        </span>
      </form>
      <div class="autowebinar__descriptionText">
        <Tr>
          Видео с комментариями спикера. Продолжительность должна быть от 5
          минут до 1 часа.
        </Tr>
      </div>
    </div>
    <div class="hasVideo" v-else>
      <button class="autowebinar__uploadButton" @click="handleNewVideo">
        <base-icon-svg
          class="autowebinar__uploadSvg"
          width="19"
          height="16"
          viewbox="0 0 19 16"
        >
          <upload-video />
        </base-icon-svg>
        <Tr>Загрузить другое видео</Tr>
      </button>

      <div class="autowebinar__descriptionText">
        <!-- <Tr>
          Видео с комментариями спикера. Продолжительность должна быть от 5
          минут до 1 часа.
        </Tr> -->
        <iframe
          id="uploadedVideo"
          class="videoFrame"
          :src="videoUrl"
          frameborder="0"
        />
      </div>
    </div>
    <modal name="addVideo" :height="360">
      <add-video-modal
        :name="videoName"
        :description="videoDescription"
        @change-name="changeVideoName"
        @change-description="changeVideoDescription"
        @save="saveVideoData"
      />
    </modal>
    <modal
      name="videoLoading"
      :clickToClose="false"
      :adaptive="true"
      :maxWidth="450"
    >
      <video-loading-modal />
    </modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UploadVideo from "@/components/Leadership/AutowebinarIcons/UploadVideo.vue";
import AddVideoModal from "@/components/Autowebinars/AddVideoModal.vue";
import VideoLoadingModal from "@/components/Autowebinars/VideoLoadingModal.vue";

export default {
  name: "AutowebinarsUploadVideo",
  components: {
    UploadVideo,
    AddVideoModal,
    VideoLoadingModal,
  },
  data() {
    return {
      newVideo: false,
    };
  },
  props: {
    videoUrl: String,
  },
  computed: {
    ...mapGetters({
      uploadLink: "autowebinarVideoUpload/uploadLink",
      videoName: "autowebinarVideoUpload/videoName",
      videoDescription: "autowebinarVideoUpload/videoDescription",
      videoLoader: "autowebinarVideoUpload/videoLoader",
    }),
  },
  methods: {
    handleNewVideo() {
      this.newVideo = !this.newVideo;

      if (
        !this.newVideo &&
        this.$refs.videoUpload &&
        this.$refs.videoUpload.files[0]
      ) {
        this.$refs.videoUpload.value = "";
      }
    },
    handleFileUpload() {
      if (this.$refs.videoUpload && this.$refs.videoUpload.files[0]) {
        const file = this.$refs.videoUpload.files[0];

        this.$store.dispatch("autowebinarVideoUpload/create", file);
      }
    },
    changeVideoName(value) {
      this.$store.commit("autowebinarVideoUpload/CHANGE_VIDEO_NAME", value);
    },
    changeVideoDescription(value) {
      this.$store.commit(
        "autowebinarVideoUpload/CHANGE_VIDEO_DESCRIPTION",
        value
      );
    },
    openFileModal() {
      if (this.$refs.videoUpload && this.$refs.videoUpload.files[0]) {
        this.$store.commit(
          "autowebinarVideoUpload/CHANGE_VIDEO_NAME",
          this.$refs.videoUpload.files[0].name
        );
        this.$store.commit(
          "autowebinarVideoUpload/CHANGE_VIDEO_DESCRIPTION",
          ""
        );
        this.$modal.show("addVideo");
      }
    },
    saveVideoData() {
      Promise.all([
        this.$store.dispatch("autowebinarVideoUpload/authOtonUploader"),
      ]).then((resp) => {
        if (resp[0].status === 200) {
          const data = {
            name: this.videoName,
            redirect_url: window.location.href,
            description: this.videoName,
          };

          Promise.all([
            this.$store.dispatch("autowebinarVideoUpload/createVideo", {
              data,
              token: resp[0].data.data.token,
            }),
          ]).then((resp) => {
            if (resp[0].status === 200) {
              console.log(resp[0].data.data);
              const upload_link = resp[0].data.data.upload_link;

              this.$store.commit("SET_UPLOAD_DATA", resp[0].data.data);

              Promise.all([
                this.$store.dispatch(
                  "autowebinarVideoUpload/updateVideoInChatBot",
                  {
                    vimeo_id: resp[0].data.data.id,
                  }
                ),
              ]).then((resp) => {
                if (resp[0].status === 200) {
                  console.log(123123);
                  this.$modal.hide("addVideo");
                  this.$modal.show("videoLoading");
                }
              });

              document.forms["videoUploadForm"].action = upload_link;
              document.forms["videoUploadForm"].submit();
            }
          });
        }
      });
    },
  },
  mounted() {
    // this.$modal.show("videoLoading");
    // this.$modal.show("addVideo");
  },
};
</script>

<style lang="stylus" scoped>
.fileMask {
  position: relative;
  display: inline-block;

  &:hover {
    opacity: 0.8;
  }

  button {
    position: relative;
    z-index: 5;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 10;
    cursor: pointer;
  }
}

.autowebinar__descriptionText {
  margin-top: 10px;
}

.cancel {
  color: #3489f8;
  margin-left: 10px;
  cursor: pointer;
}
</style>
