import createLocales from "@/utils/createLocales";
import supportedLangs from "@/utils/supportedLangs";
import Universal from ".";
import Answer from "./answer";

class Question extends Universal {
  constructor(data?) {
    if (data) {
      super(data);
    } else {
      super({
        position: 1,
        locales: {
          ...createLocales(["body"], supportedLangs(), ""),
        },
        answers: [new Answer()],
      });
    }
  }
}

export default Question;
