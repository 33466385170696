






















import TeamMenuFilterSearchInput from "@/components/Team/TeamMenuFilterSearchInput.vue";

export default {
  name: "TeamMenuFilterSearch",
  data() {
    return {};
  },
  components: {
    TeamMenuFilterSearchInput,
  },
};
