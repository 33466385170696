<template>
  <div class="contactsProfileInfoAddInput">
    <label class="contactsProfileInfoAddInput__title">
      <Tr>{{ title }}</Tr>
    </label>

    <div
      class="contactsProfileInfoAddInput__inputWrap"
      v-for="arrayItem in contactWayArray"
      :key="arrayItem.id"
    >
      <contacts-profile-info-add-input-inner
        class="contactsProfileInfoAddInput__input"
        :ref="`input`"
        :contactWay="contactWay"
        :item="arrayItem"
        :type="type"
        :placeholder="placeholder"
        @input="changeInput"
      />
      <button
        class="contactsProfileInfoAddInput__deleteButton"
        @click="delInput(arrayItem.id, contactWay)"
      />
    </div>
    <button
      @click="addInput"
      :disabled="disabledAddBtn"
      :class="[
        'contactsProfileInfoAddInput__addButton',
        { contactsProfileInfoAddInput__addButton_disabled: disabledAddBtn },
      ]"
      type="button"
    >
      <Tr>Добавить</Tr>
    </button>
  </div>
</template>

<script>
import ContactsProfileInfoAddInputInner from "@/components/Contacts/ContactsProfileInfoAddInputInner.vue"

export default {
  name: "ContactsProfileInfoAddInput",

  components: {
    ContactsProfileInfoAddInputInner,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    contactWayArray: {
      type: Array,
    },

    contactWay: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },

    placeholder: String,
  },

  computed: {
    disabledAddBtn() {
      if (this.contactWayArray && this.contactWayArray.length > 0) {
        return this.contactWayArray.length > 4 || this.contactWayArray[this.contactWayArray.length - 1].value === '' || this.contactWayArray[this.contactWayArray.length - 1].link === '';
      }
      return false
    },
  },

  methods: {
    addInput() {
      this.$emit('add-contact-way-in-array', this.contactWay);
    },

    delInput(id, name) {
      this.$emit('del-contact-way-in-array', { id, name });
      this.$store.commit(`contactsProfile/CLEAR_ERRORS_${name.toUpperCase()}`);
    },

    changeInput(payload) {
      this.$emit("change-contact-way-value", payload);
    },
  },

  mounted() {
    if (this.contactWayArray && this.contactWayArray.length === 0) {
      this.$emit("add-new-empty-contact-way-value", this.contactWay);
    }
  },

  updated() {
    if (this.contactWayArray.length === 0) {
      this.$emit("add-new-empty-contact-way-value", this.contactWay);
    }
  },
};
</script>

<style lang="stylus" scoped>
.contactsProfileInfoAddInput__title {
  display: block;
  font_small();
}

.contactsProfileInfoAddInput__inputWrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 4px;
}

.contactsProfileInfoAddInput__inputWrap+.contactsProfileInfoAddInput__inputWrap {
  margin-bottom: 8px;
}

.contactsProfileInfoAddInput__input {
  display: block;
  margin-top: 4px;
  padding: 5px 4px 6px 7px;
  width: 100%;
  max-width: 240px;
  border: 1px solid #e6e7eb;
  font_regular();

  @media (min-width: tablet) {
    width: 240px;
    max-width: unset;
  }
}

.contactsProfileInfoAddInput__deleteButton {
  display: block;
  margin-bottom: 6px;
  margin-left: 12px;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M0,0H20V20H0Z' fill='none'/%3E%3Cpath d='M14,5.2h4V6.8H16.4V17.2a.8.8,0,0,1-.8.8H4.4a.8.8,0,0,1-.8-.8V6.8H2V5.2H6V2.8A.8.8,0,0,1,6.8,2h6.4a.8.8,0,0,1,.8.8Zm.8,1.6H5.2v9.6h9.6Zm-3.669,4.8,1.414,1.414-1.131,1.131L10,12.731,8.586,14.146,7.454,13.014,8.869,11.6,7.454,10.186,8.586,9.054,10,10.469l1.414-1.414,1.131,1.131ZM7.6,3.6V5.2h4.8V3.6Z' transform='translate(0 0)' fill='%230f1642'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}

.contactsProfileInfoAddInput__deleteButton, .contactsProfileInfoAddInput__addButton {
  &:hover {
    opacity: 0.6;
  }
}

.contactsProfileInfoAddInput__addButton {
  margin-top: 4px;
  padding: 0;
  background-color: unset;
  border: none;
  border-bottom: 1px dotted #3489f8;
  font(secondary_text_color, 12px, 400, 15px);

  &_disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.contactsProfileInfoAddInput__btn {
  &:disabled {
    opacity: 0.4;
  }
}
</style>
