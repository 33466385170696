<template>
  <div>
    <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
      <funnel-landing
        v-if="trigger.source === 'form'"
        v-tooltip="`${$t('Лендинг')} ${landingUrl}`"
      />
      <funnel-vk v-if="trigger.source === 'vk_form'" v-tooltip="$t('Вк')" />
      <funnel-fb v-if="trigger.source === 'fb_form'" v-tooltip="Facebook" />
      <funnel-telegram
        v-if="trigger.source === 'chatbot'"
        v-tooltip="'Telegram'"
      />
      <funnel-fb v-if="false" v-tooltip="'Facebook'" />
    </base-icon-svg>
  </div>
</template>

<script>
import FunnelLanding from "@/components/SvgIcons/funnel/FunnelLanding.vue";
import FunnelVk from "@/components/SvgIcons/funnel/FunnelVk.vue";
import FunnelFb from "@/components/SvgIcons/funnel/FunnelFb.vue";
import FunnelTelegram from "@/components/SvgIcons/funnel/FunnelTelegram.vue";

export default {
  name: "UpdatedFunnelBodyItemTrigger",

  components: {
    FunnelLanding,
    FunnelVk,
    FunnelFb,
    FunnelTelegram,
  },

  props: {
    trigger: {
      type: Object,
    },
  },

  computed: {
    landingUrl() {
      if (this.trigger && this.trigger.page) {
        let arr = this.trigger.page.split("/");
        arr = arr.slice(0, 3)
        return arr.join("/");
      }
      return "";
    },
  },
};
</script>

<style lang="stylus" scoped></style>
