import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { path, pathOr } from "ramda";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    userChatBotId(state) {
      return pathOr(undefined, ["data", "id"], state);
    },
    chatBotActivationLink(state) {
      return pathOr(undefined, ["data", "profile", "link_bot_helper"], state);
    },
    chatId(state) {
      return path(["data", "profile", "telegram_chat_id"], state);
    },
    hasActiveBot(state) {
      const chatId = pathOr(
        false,
        ["data", "profile", "telegram_chat_id"],
        state
      );
      return chatId ? true : false;
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async fetch({ commit, rootGetters, state }) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/profile`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
