<template>
  <div>
    <leadership-layout
      :title="title"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
    >
      <leadership-course
        :course="course"
        :errors="errors"
        :lang="lang"
        :supportedLangsTitled="supportedLangsTitled"
        :courseName="name"
        :courseDescription="description"
        :langTabs="langTabs"
        :isPublished="isPublished"
        :cost="cost"
        :programs="programs"
        :choosedProgram="choosedProgram"
        @change-active-lang="handleChangeLang"
        @change-languages="handleChangeLanguages"
        @change-name="handleChangeName"
        @change-description="handleChangeDescription"
        @save-course="saveCourse"
        @publish-course="publishCourse"
        @unpublish-course="unpublishCourse"
        @change-price="changePrice"
        @change-payment-type="changePaymentType"
        @change-program="changeProgram"
      />
    </leadership-layout>
  </div>
</template>

<script>
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipCourse from "@/components/Leadership/LeadershipCourse.vue";
import { mapGetters } from "vuex";

export default {
  name: "CourseContainer",
  components: {
    LeadershipLayout,
    LeadershipCourse,
  },
  data() {
    return {
      // activeTab: "description",
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { title: "Настройки лидера", link: "/leadership" },
        ...(this.programId
          ? [
              {
                title: "Программы обучения",
                link: "/leadership/programs/",
              },
              {
                title: this.programName,
                link: `/leadership/programs/${this.programId}/description`,
              },
              {
                title: "Квесты",
                link: `/leadership/programs/${this.programId}/courses`,
              },
            ]
          : [
              {
                title: "Свои бизнес-квесты",
                link: "/leadership/courses/",
              },
            ]),
      ];
    },
    tabs() {
      const { courseId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/courses/${courseId}/description${
            this.programId ? `?programId=${this.programId}` : ""
          }`,
        },
        {
          title: "Уроки",
          link: `/leadership/courses/${courseId}/lessons${
            this.programId ? `?programId=${this.programId}` : ""
          }`,
        },
      ];
    },
    programId() {
      const {
        query: { programId },
      } = this.$route;
      if (programId) {
        return programId;
      }
      return undefined;
    },
    ...mapGetters({
      course: "leadershipCourse/course",
      pending: "leadershipCourse/pending",
      lang: "leadershipCourse/lang",
      errors: "leadershipCourse/errors",
      name: "leadershipCourse/name",
      supportedLangsTitled: "supportedLangsTitled",
      description: "leadershipCourse/description",
      langTabs: "leadershipCourse/langTabs",
      title: "leadershipCourse/title",
      isPublished: "leadershipCourse/isPublished",
      cost: "leadershipCourse/cost",
      programs: "leadershipPrograms/programs",
      choosedProgram: "leadershipCourse/choosedProgram",
      programName: "leadershipProgram/name",
    }),
  },
  mounted() {
    const {
      params: { courseId },
      query: { programId },
    } = this.$route;

    this.$store.dispatch("leadershipCourse/fetch", { courseId });
    this.$store.dispatch("leadershipPrograms/fetch");

    if (programId) {
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }
  },
  methods: {
    handleChangeLang(lang) {
      this.$store.commit("leadershipCourse/TOGGLE_LANG", lang);
    },
    handleChangeLanguages(lang) {
      this.$store.commit("leadershipCourse/TOGGLE_COURSE_LANG", lang);
    },
    handleChangeName(value) {
      this.$store.commit("leadershipCourse/CHANGE_NAME", value);
    },
    handleChangeDescription(value) {
      this.$store.commit("leadershipCourse/CHANGE_DESCRIPTION", value);
    },
    saveCourse() {
      this.$store.dispatch("leadershipCourse/updateCourse");
    },
    publishCourse() {
      this.$store.dispatch("leadershipCourse/publishCourse");
    },
    unpublishCourse() {
      this.$store.dispatch("leadershipCourse/unpublishCourse");
    },
    changePrice(value) {
      this.$store.commit("leadershipCourse/CHANGE_COST", value);
    },
    changePaymentType(value) {
      this.$store.commit("leadershipCourse/TOGGLE_COST", value);
    },
    changeProgram(value) {
      this.$store.commit("leadershipCourse/CHANGE_PROGRAM", value);
    },
  },
};
</script>
