<template>
  <div class="contactsBody">
    <contacts-body-head class="contactsGrid" />

    <contacts-body-item
      class="contactsGrid"
      v-for="(contact, index) in contacts"
      :key="`${contact.id}_${index}`"
      :contact="contact"
      :progresses="progresses"
      :contactHeatArray="contactHeatArray"
      :contactTopArray="contactTopArray"
      :tags="tags"
      @delTag="delTag"
      @addTag="addTag"
    />
  </div>
</template>

<script>
import ContactsBodyHead from "@/components/Contacts/ContactsBodyHead.vue";
import ContactsBodyItem from "@/components/Contacts/ContactsBodyItem.vue";

export default {
  name: "ContactsBody",
  components: {
    ContactsBodyHead,
    ContactsBodyItem,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
    progresses: {
      type: Array,
      required: true,
    },
    contactHeatArray: {
      type: Array,
      required: true,
    },
    contactTopArray: {
      type: Array,
      required: true,
    },
  },

  methods: {
    delTag(payload) {
      this.$emit('delTag', payload);
    },

    addTag(payload) {
      this.$emit('addTag', payload);
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsBody {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;

  @media (min-width: desktop) {
    display: grid;
    row-gap: unset;
    column-gap: unset;
    grid-row-gap: 8px;
  }
}

.contactsGrid {
  grid-template-columns: 24px auto;
  grid-column-gap: 12px;
  align-items: center;
  font_regular();

  @media (min-width: desktop) {
    grid-template-columns: 24px 150px 20px 105px 110px 130px 100px auto;
  }
}
</style>
