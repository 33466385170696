<template>
  <li class="menu__item" @click="asideMenuHandler">
    <base-link
      :exact="exact"
      :to="menulink.href"
      :class="[
        'menu__link',
        { menu__link_active: isTasks(menulink) || isContacts(menulink) },
      ]"
      :out="menulink.href !== '/leadership'"
      v-if="menulink.inner"
    >
      <menu-link :link="menulink" :counts="counter" />
    </base-link>
    <a
      :href="outHref"
      class="menu__link"
      v-else
      @click.prevent="goOutside(outHref)"
    >
      <menu-link :link="menulink" :counts="counter" />
    </a>
  </li>
</template>

<script>
import MenuLink from "@/components/Sidebar/MenuLink.vue";
import { sidebarHandler } from "@/components/common/SidebarHandler.js";
import { mapGetters } from "vuex";

export default {
  name: "SidebarMenuLink",
  components: {
    MenuLink,
  },
  data() {
    return {
      leads: "navbar_total_telephony_leads_count",
      contacts: "menu_contacts_count",
      funnel: "menu_funnel_lead_count",
      assignment: "navbar_total_telephony_leads_assigment_count",
    };
  },
  props: { menulink: Object, exact: Boolean },
  computed: {
    ...mapGetters({
      callStatus: "caller/callStatus",
    }),
    mainUrl() {
      return process.env.MAIN_URL;
    },
    outHref() {
      const base = this.$store.getters["user/mainSiteLink"];
      return base + this.menulink.href;
    },
    counter() {
      const stats = this.$store.getters["user/stats"];
      if (this[this.menulink.name]) {
        return stats[this[this.menulink.name]];
      }
      return undefined;
    },
  },
  methods: {
    asideMenuHandler() {
      sidebarHandler.$emit("aside-menu-handler");
    },
    goOutside(href) {
      if (this.callStatus === "pending") {
        this.$modal.show("dialog", {
          title: this.$t("Внимание!"),
          text: this.$t(
            "Вы переходите на другой раздел сайта. Ваш звонок прервется."
          ),
          buttons: [
            {
              title: this.$t("Нет, я остаюсь"),
              handler: () => {
                this.$modal.hide("dialog");
              },
            },
            {
              title: this.$t("Все-равно перейти"),
              handler: () => {
                window.location = href;
                this.$modal.hide("dialog");
              },
            },
          ],
        });
      } else {
        window.location = href;
      }
    },
    isTasks(menulink) {
      return menulink.href === '/tasks' && this.$route.path.includes('tasks');
    },
    isContacts(menulink) {
      return menulink.href === '/contacts' && this.$route.path.includes('contacts');
    },
  },
};
</script>

<style lang="stylus" scoped>
.menu__link_colorized {
  background-color: pink !important;
}

.menu__link {
  position: relative;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #0f1642;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  display: flex;
  align-items: center;
  padding: 4px 8px 4px 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.router-link-active, &_active {
    background-color: #0f1642;
  }
}

.menu__link {
  &.router-link-active .menu__txt span, &_active .menu__txt span {
    color: #fff;
  }
}

.menu__link {
  &.router-link-active svg, &_active svg {
    fill: #fff;
  }
}

.menu__link {
  &.router-link-active .icon, &_active .icon {
    fill: #fff;
  }
}

.menu__link:hover {
  background-color: rgba(15, 22, 66, 0.3);
}

.menu__link_active:hover {
  background-color: rgba(15, 22, 66, 0.8);
}

.menu__link svg {
  vertical-align: middle;
  fill: #0f1642;
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.menu__txt {
  display: flex;
  align-items: center;
}

.menu__txt span {
  vertical-align: bottom;
}

.menu__link_active {
  background-color: #0f1642;
  color: #fff;
}

.menu__link_active svg {
  fill: #fff;
}

.menu__link_active span {
  color: #0f1642;
}

.menu__txt span {
  font-size: 16px;
  line-height: 22px;
  margin-left: 8px;
}

.menu__count {
  font-size: 12px;
  line-height: 16px;
  border-radius: 2px;
}

.menu__count_active {
  padding: 0 3px;
}

.menu__count_positive {
  background-color: #e6e7eb;
}

.menu__count_negative {
  background-color: #ff9f43;
  font-weight: 700;
}
</style>
