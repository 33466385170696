import createLocales from "@/utils/createLocales";
import getDate from "@/utils/getDate";
import supportedLangs from "@/utils/supportedLangs";
import { omit } from "ramda";
import Universal from ".";

class Course extends Universal {
  constructor(data?) {
    if (data) {
      super({
        ...data,
        payment_type: data.payment_type ? data.payment_type : "free",
      });
    } else {
      super({
        id: 0,
        locales: {
          ...createLocales(["name", "short_description"], supportedLangs(), ""),
        },
        published_at: "",
        created_at: "",
        updated_at: "",
        languages: supportedLangs(),
        payment_type: "free",
        quest_course: null,
        cost: 0,
      });
    }
  }

  getCreateModel() {
    const data = omit(["id", "published_at", "created_at", "updated_at"], {
      ...this.transformLocales(),
      started_at: getDate(),
    });
    return data;
  }

  getUpdateModel() {
    const data = omit(
      ["id", "published_at", "created_at", "updated_at", "started_at"],
      {
        ...this.transformLocales(),
      }
    );
    return data;
  }
}

export default Course;
