import { render, staticRenderFns } from "./ContactsProfileRemindersAll.vue?vue&type=template&id=e1f82e7c&scoped=true&"
import script from "./ContactsProfileRemindersAll.vue?vue&type=script&lang=js&"
export * from "./ContactsProfileRemindersAll.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1f82e7c",
  null
  
)

export default component.exports