<template>
  <div class="leadsRestartFunnelModal">
    <!-- BODY -->
    <div class="leadsRestartFunnel">
      <div class="leadsRestartFunnelSuccess" v-if="linkCreated">
        <div class="leadsRestartFunnelSuccess__title">
          <Tr>Воронка перевыпущена с новой датой</Tr>
        </div>
      </div>

      <div class="leadsRestartFunnel__box" v-if="restartedWithdate">
        <div class="">
          <!-- webinar start -->
          <label class="leadsRestartFunnel__label">
            <Tr>Начало вебинара</Tr>
          </label>
          <div class="datePickerBox">
            <date-picker
              type="datetime"
              format="DD MMM YYYY, HH:mm"
              :lang="
                ['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale
              "
              v-model="startedAt"
              :default-value="new Date().setHours(10, 0, 0, 0)"
              :disabled-date="notBeforeToday"
            />
          </div>
          <div class="error">
            <div
              class="error__item"
              v-for="error in errors.webinar_started_at"
              :key="error"
            >
              {{ error }}
            </div>
          </div>
          <!-- webinar start -->
        </div>
      </div>

      <button
        class="leadsRestartFunnel__submit"
        @click="create"
        :disabled="!restartedWithdate"
      >
        <Tr>Создать</Tr>
      </button>
    </div>
    <!-- BODY -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DatePicker from "vue2-datepicker";

export default {
  name: "UpdatedLeadsRestartFunnel",
  components: {
    DatePicker,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      linkCreated: "updatedLeadsRestartFunnel/linkCreated",
      errors: "updatedLeadsRestartFunnel/errors",
      funnels: "updatedFilterFunnels/funnels",
    }),
    startedAt: {
      get() {
        return this.$store.getters["updatedLeadsRestartFunnel/startedAt"];
      },

      set(value) {
        this.$store.commit(
          "updatedLeadsRestartFunnel/CHANGE_STARTED_AT",
          value
        );
      },
    },
    criteria() {
      const funnel = this.funnels.data.find(el => el.id === this.lead.funnel.id);
      if (funnel && funnel.criteria) {
        return funnel.criteria;
      } else {
        return [];
      }
    },
    restartedWithdate() {
      if (this.criteria.length > 0) {
        const funnlesWithCriteria = this.criteria.map(el => Object.prototype.hasOwnProperty.call(el, "key") && el.key === "webinar_started_at");
        return funnlesWithCriteria.includes(true);
      }
      return false;
    },
  },

  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    create() {
      this.$store.commit("updatedLeadsRestartFunnel/SET_ERRORS", {});

      if (this.restartedWithdate) {
        this.$store.commit("updatedLeadsRestartFunnel/SET_CRITERIA", this.criteria);
      }

      this.$store.dispatch(
        "updatedLeadsRestartFunnel/restartFunnel",
        {
          leadId: this.lead.id,
          funnelId: this.lead.funnel.id
        }
      );
    },
  },

  beforeDestroy() {
    this.$store.commit("updatedLeadsRestartFunnel/CLEAR_STORE");
  },
};
</script>

<style lang="stylus" scoped>
.leadsRestartFunnelModal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-size: 10px;
  line-height: 14px;

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }
}

.loader {
  position: absolute;
}

.layout {
  opacity: 0.6;
}

.leadsRestartFunnel {
  padding: 0 0 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;

  &__box {
    margin: 0 -12px 20px;
    padding: 0 12px;
    border-bottom: 2px solid grey_text_color;

    @media (min-width: desktop) {
      margin: 0 -16px 20px;
      padding: 0 16px;
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font_small();
  }

  &__submit {
    padding: 11px 12px;
    width: fit-content;
    font_regular();
    color: #fff;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    border: none;
    border-radius: 4px;

    &:hover {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
    }
  }
}

@media (min-width: desktop) {
  .leadsRestartFunnel {
    padding: 0;
  }
}

.error {
  margin-bottom: 12px;

  &__item {
    color: danger_text_color;
    font-size: 14px;
  }
}

.datePickerBox {
  display: block;
  margin-bottom: 16px;
}

.leadsRestartFunnelSuccess {
  margin: -10px -10px 10px;
  padding: 7px 16px;
  background-color: green_text_color;

  @media (min-width: desktop) {
    margin: -16px -16px 16px;
  }

  &__title {
    font_bold();
    color: #fff;
  }
}
</style>
