// import createLocales from "@/utils/createLocales";
// import supportedLangs from "@/utils/supportedLangs";
import getAwards from "@/utils/getAwards";
import { omit } from "ramda";
import Universal from ".";
import Answer from "./answer";
import Question from "./question";

const transformTestQuestions = questions => {
  return questions
    .sort((a, b) => a.position - b.position)
    .map(question =>
      omit(["quest_task_poll_answers_objects"], {
        ...new Question(question),
        answers: question.quest_task_poll_answers_objects
          .sort((a, b) => a.position - b.position)
          .map(answer => new Answer(answer)),
      })
    );
};
class Test extends Universal {
  [x: string]: any;

  constructor(data?) {
    if (data) {
      const transformed = {
        id: data.id,
        locales: data.locales,
        position: data.position,
        questions: transformTestQuestions(
          data.quest_task_poll_questions_objects
        ),
        ...getAwards(data),
      };
      super(transformed);
    } else {
      super({
        id: 0,
        questions: [new Question()],
        ...getAwards(),
      });
    }
  }

  getCreateModel() {
    const model = this.questions.map(question => ({
      ...omit(["answers"], this.transformLocales(question)),
      quest_task_poll_answers_objects: question.answers.map(answer => ({
        ...this.transformLocales(answer),
      })),
    }));

    return model;
  }
}

export default Test;
