export const psychotype = {
  title: "Психотип",
  name: "psychotype",
  array: [
    {
      text: "Красный",
      value: "red",
    },
    {
      text: "Синий",
      value: "blue",
    },
    {
      text: "Желтый",
      value: "yellow",
    },
    {
      text: "Зеленый",
      value: "green",
    },
  ],
};

// export const messenger = {
//   title: "Мессенджер",
//   name: "messenger",
//   array: [
//     {
//       text: "Telegram",
//       value: "telegram",
//     },
//     {
//       text: "Viber",
//       value: "viber",
//     },
//   ],
// };

export const chatBot = {
  title: "Чат-бот",
  name: "bot",
  array: [
    {
      text: "Не начал",
      value: "nostart",
    },
    {
      text: "Нажал /start",
      value: "start",
    },
    {
      text: "Выявлен психотип",
      value: "psychotype",
    },
  ],
};

export const chatBotWithoutPsycho = {
  title: "Чат-бот",
  name: "bot",
  array: [
    {
      text: "Не начал",
      value: "nostart",
    },
    {
      text: "Нажал /start",
      value: "start",
    },
  ],
};

export default { psychotype, chatBot, chatBotWithoutPsycho };
