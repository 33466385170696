<script>
export default {
  name: "LeadershipTextarea",
  props: {
    title: String,
    value: String,
    maxLength: Number,
  },
  computed: {
    valueLength() {
      if (!this.value) {
        return 0;
      }
      return this.value.length;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("handle-description", e.target.value);
    },
  },
};
</script>

<template>
  <fieldset class="fieldset">
    <label class="label">
      {{ $t(title) }}
      <textarea
        :maxlength="maxLength"
        :value="value"
        @input="handleInput"
        class="textarea"
      />
      <div class="span__wrap">
        <span class="span">
          <span :class="{ span_red: valueLength > maxLength }">{{
            valueLength
          }}</span>
          /{{ maxLength }}
        </span>
      </div>
    </label>
  </fieldset>
</template>

<style lang="stylus" scoped>
.fieldset {
  padding-bottom: 2px;
  // width: 640px;
}

.label {
  position: relative;
  display: block;
  font_small();
}

.textarea {
  display: block;
  margin-top: 4px;
  padding: 7px 7px;
  width: 100%;
  height: 128px;
  border: 1px solid #e6e7eb;
  font_regular();
  line-height: 20px;
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;
}

.textarea::-webkit-scrollbar {
  width: 12px;
}

.textarea::-webkit-scrollbar-track {
  background: #fff;
}

.textarea::-webkit-scrollbar-thumb {
  background-color: #e6e7eb;
  border: 2px solid #fff;
  border-radius: 6px;
}

.span__wrap {
  position: absolute;
  top: 0;
  right: 0;
}

.span {
  font_small();
  opacity: 0.6;

  & span {
    font-weight 700
  }

  &_red {
    color: #e81526;
  }
}
</style>
