<template>
  <new-autowebinar-container />
</template>

<script>
import NewAutowebinarContainer from "@/containers/Autowebinars/NewAutowebinarContainer.vue";

export default {
  name: "AutowebinarDescription",
  components: {
    NewAutowebinarContainer,
  },
};
</script>

<style lang="stylus" scoped></style>
