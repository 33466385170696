import { getUrlLang } from "./cookies/getLang";

const axiosConfig = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...(getUrlLang() ? { "accept-language": getUrlLang() } : {}),
    },
    baseURL: process.env.VUE_APP_FUNNEL_URL,
    Accept: "application/json",
  };
};

export default axiosConfig;
