<template>
  <main class="main">
    <h1 class="title">
      <Tr>Контакты</Tr>
    </h1>
    <contacts-container />
  </main>
</template>

<script>
import ContactsContainer from "@/containers/Contacts/ContactsContainer.vue";

export default {
  name: "Contacts",
  components: {
    ContactsContainer,
  },
};
</script>

<style lang="stylus" scoped>
.main {
  @media (min-width: 768px) {
    width: auto;
  }
}
</style>
