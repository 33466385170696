<template>
  <main class="main">
    <updated-funnel-create-link-container />
  </main>
</template>

<script>
import UpdatedFunnelCreateLinkContainer from "@/containers/Funnel/UpdatedFunnelCreateLinkContainer.vue";

export default {
  name: "UpdatedFunnelCreateLink",

  components: {
    UpdatedFunnelCreateLinkContainer,
  },
};
</script>

<style lang="stylus" scoped></style>
