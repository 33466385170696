<template>
  <header class="header">
    <div class="logoInfo__wrap">
      <header-logo />
      <div class="headerInformation__container">
        <header-info
          :expired="true"
          :param1="'На неделю'"
          :param2="'Просрочено'"
          :title="'Задачи'"
          :value1="stats.navbar_week_tasks"
          :value2="stats.navbar_task_overdue_count"
          name="tasks"
        />
        <header-info
          v-if="showTeam"
          :expired="false"
          :param1="'В первой линии'"
          :param2="'Всего'"
          :title="'Команда'"
          :value1="headerInfoValue1"
          :value2="headerInfoValue2"
          name="team"
        />
        <header-info-ref v-if="userRef && showRef" :userRef="userRef" />
        <!-- <header-info-drawing
          v-if="usersCompanySlug == 'oton'"
          :image="contestImage"
          :takePart="contestPart"
          :title="contestTitle"
          :position="contestPosition"
          :prevPosition="contestPrevPosition"
          :slug="contestSlug"
        /> -->
      </div>
    </div>
    <header-menu-button />
    <header-account-info :accountInfoOpen="accountInfoOpen" />
  </header>
</template>

<script>
import "../assets/styles/vars.styl";
import { mapGetters } from "vuex";
import HeaderAccountInfo from "@/components/Header/HeaderAccountInfo.vue";
import HeaderLogo from "@/components/Header/HeaderLogo.vue";
import HeaderInfo from "@/components/Header/HeaderInfo.vue";
import HeaderInfoRef from "@/components/Header/HeaderInfoRef.vue";
// import HeaderInfoDrawing from "@/components/Header/HeaderInfoDrawing.vue";
import HeaderMenuButton from "@/components/Header/HeaderMenuButton.vue";
import { sidebarHandler } from "@/components/common/SidebarHandler.js";

export default {
  name: "TheHeader",
  components: {
    HeaderAccountInfo,
    HeaderLogo,
    HeaderInfo,
    HeaderInfoRef,
    // HeaderInfoDrawing,
    HeaderMenuButton,
  },
  data() {
    sidebarHandler.$on("aside-menu-handler", this.menuOpen);
    return {
      accountInfoOpen: false,
    };
  },
  methods: {
    menuOpen() {
      this.accountInfoOpen = !this.accountInfoOpen;
    },
  },
  computed: {
    ...mapGetters({
      stats: "user/stats",
      userRef: "user/userRefLink",
      showRef: "user/showRef",
      showTeam: "user/showTeam",
      contestPart: "user/contestPart",
      contestImage: "user/contestImage",
      contestTitle: "user/contestTitle",
      contestPosition: "user/contestPosition",
      contestPrevPosition: "user/contestPrevPosition",
      usersCompanySlug: "user/usersCompanySlug",
      contestSlug: "user/contestSlug",
    }),
    headerInfoValue1() {
      return `${this.stats.navbar_team_first_line} / ${this.stats.navbar_team_first_line_paid_count}`;
    },
    headerInfoValue2() {
      return `${this.stats.navbar_team_count} / ${this.stats.navbar_team_paid_count} `;
    },
  },
};
</script>

<style lang="stylus" scoped>
.header {
  position: relative;
  z-index: 102;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #0f1642;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 8px 20px 0 #0f164252;
  padding: 0px 8px 0px 11px;
  height: 40px;
  min-height: unset;
  max-height: unset;

  @media (min-width: desktop) {
    padding: 5px 20px;
    height: auto;
    min-height: 65px;
    max-height: 65px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.header::before {
  position: absolute;
  content: '';
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff00 100%) 0%;
  opacity: 0.2;
}

.logoInfo__wrap {
  @media (min-width: desktop) {
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: 117px;
    align-items: center;
  }
}

.headerInformation__container {
  display: none;

  @media (min-width: desktop) {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 39px;
  }
}
</style>
