<template>
  <div>
    <loader v-if="pending" />
    <div v-else-if="!hasWebinars">
      <no-content :noContent="noContent" />
      <div class="buttonBox">
        <base-link to="/leadership/autowebinars/new" class="createAutowebinar">
          <Tr>Создать автовебинар</Tr>
        </base-link>
      </div>
    </div>
    <div class="autowebinars__wrap" v-else>
      <leadership-autowebinar-list-item
        :key="item.id"
        :quest="item"
        v-for="item in webinars"
      />
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";
import { mapGetters } from "vuex";
import LeadershipAutowebinarListItem from "@/components/Leadership/LeadershipAutowebinarListItem.vue";
// import LeadershipQuestsList from "@/components/Leadership/LeadershipQuestsList.vue";

export default {
  name: "AutowebinarsContainer",
  components: {
    Loader,
    NoContent,
    LeadershipAutowebinarListItem,
    // LeadershipQuestsList,
  },
  data() {
    return {
      noContent: {
        text: "Пусто. У вас еще нет ни одного автовебинара",
      },
    };
  },
  computed: {
    hasWebinars() {
      if (this.webinars && this.webinars.length) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      webinars: "autowebinars/webinars",
      pending: "autowebinars/pending",
    }),
  },
  mounted() {
    this.$store.dispatch("autowebinars/fetch");
  },
};
</script>

<style lang="stylus" scoped>
.autowebinars__wrap {
  display: grid;
  grid-template-columns: repeat(2, 456px);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding-top: 1px;
  /* display: none; */
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.createAutowebinar {
  align-self: center;
  margin-top: 30px;
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
}
</style>
