// import Universal from "@/models";
import ListLesson from "@/models/listLesson";
import i18n from "@/plugins/i18n";
import { runNotice } from "@/utils/notifications";
import { pathOr } from "ramda";
import Vue from "vue";

const transformResponseData = ({ results, ...rest }) => ({
  ...rest,
  results: results.map(lesson => new ListLesson(lesson)),
});

const courseIsPublished = rootState => {
  if (
    rootState &&
    rootState.leadershipCourse &&
    rootState.leadershipCourse.data &&
    rootState.leadershipCourse.data.published_at
  ) {
    return true;
  }
  return false;
};

export default {
  namespaced: true,

  state: () => ({
    data: {
      results: [],
    },
    pending: false,
  }),

  getters: {
    lessons(state) {
      return pathOr([], ["data", "results"], state);
    },
    pending(state) {
      return state.pending;
    },
    courseIsPublished(state, getters, rootState) {
      if (
        rootState &&
        rootState.leadershipCourse &&
        rootState.leadershipCourse.data &&
        rootState.leadershipCourse.data.published_at
      ) {
        return true;
      }
      return false;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    UPDATE_LIST(state, list) {
      state.data.results = list;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    DELETE_LESSON(state, lessonId) {
      state.data.results = state.data.results.filter(
        ({ id }) => id !== lessonId
      );
    },
    CLEAR_STORE(state) {
      state.data.results = [];
      state.pending = false;
    },
  },

  actions: {
    async fetch({ commit }, { courseId }) {
      try {
        commit("SET_PENDING", true);
        const params = {
          quest: courseId,
        };
        const response = await Vue.axios.get(
          `/leadership_quest_tasks/?page_size=100`,
          {
            params,
          }
        );
        if (response.status === 200) {
          const data = transformResponseData(response.data);
          commit("SET_DATA", data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    updateLessonPositionMirroring({ commit, getters }, list) {
      commit("UPDATE_LIST", list);
    },

    async updateLessonPosition(
      { commit, rootState, getters },
      { lessonId, position }
    ) {
      try {
        const response = await Vue.axios.patch(
          `/leadership_quest_tasks/${lessonId}/move/`,
          { new_position: position }
        );
        if (response.status === 200) {
          runNotice("success", i18n.t("Список уроков обновлен"));
        }
      } catch (e) {
        console.info(e);
      }
    },

    async deleteLesson({ commit, rootState, getters }, lessonId) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(
          `/leadership_quest_tasks/${lessonId}/`
        );
        if (response.status === 204) {
          commit("SET_PENDING", false);
          runNotice("success", i18n.t("Список уроков обновлен"));
          commit("DELETE_LESSON", lessonId);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        // notifications("error", "This is error message");
      }
    },
  },
};
