import { getToken } from "@/utils/cookies/getToken";
import Vue from "vue";
import axios from "axios";
import { pathOr } from "ramda";

const axiosConfig = (token: string) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  baseURL: process.env.VUE_APP_CHATBOT_URL,
  Accept: "application/json",
});

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    webinars(state) {
      return pathOr([], ["data", "response"], state);
    },
    pending(state) {
      return state.pending;
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },
  actions: {
    async fetch({ commit, rootGetters }) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          "/videos",
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
