import { pathOr } from "ramda";
import Vue from "vue";
import moment from "moment";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    events: [],
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    events(state) {
      return pathOr([], ["events"], state);
    },

    errors(state) {
      return state.errors;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.events = [...data.results];
    },

    SET_MORE_DATA(state, data) {
      state.data = data;
      state.events = [...state.events, ...data.results];
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    CLEAR_EVENTS(state) {
      state.events = [];
    },
  },

  actions: {
    async fetch({ commit }, { page, contact }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_events/`, {
          params: { page, contact },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async fetchMore({ commit }, { page, contact }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_events/`, {
          params: { page, contact },
        });

        if (response.status === 200) {
          commit("SET_MORE_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async postEvent({ commit }, { contact, topic }) {
      try {
        commit("SET_PENDING", true);

        const now = Date.now();
        const nowToLocalIso = moment(now).toISOString();

        const data = {
          contact: contact,
          topic: topic,
          created_at: nowToLocalIso,
        };

        const response = await Vue.axios.post(`/contact_events/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
