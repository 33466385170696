<template>
  <div>
    <div class="label">
      <Tr>{{ heading }}</Tr>
    </div>
    <vue-editor
      class="content-editor"
      v-model="dataModel"
      :editorOptions="editorOptions"
    />
  </div>
</template>

<script>
import { VueEditor, Quill } from "vue2-editor";
import Emoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";

Quill.register(
  {
    "formats/emoji": Emoji.EmojiBlot,
    "modules/short_name_emoji": Emoji.ShortNameEmoji,
    "modules/toolbar_emoji": Emoji.ToolbarEmoji,
    "modules/textarea_emoji": Emoji.TextAreaEmoji,
  },
  true
);

const editorOptions = {
  modules: {
    toolbar: {
      container: [
        // [{ size: ["small", false, "large"] }],
        ["bold", "italic", "underline", "strike"],
        // ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }],
        // [{ indent: "-1" }, { indent: "+1" }],
        // [{ direction: "rtl" }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        // [{ font: [] }],
        // [{ align: [] }],
        // ["clean"],
        ["link"],
        ["emoji"],
      ],
      handlers: {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        emoji: function() {},
      },
    },
    short_name_emoji: true,
    toolbar_emoji: true,
    textarea_emoji: true,
  },
};

export default {
  name: "TextEditor",
  components: { VueEditor },
  props: {
    content: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      required: true,
    },
  },
  computed: {
    editorOptions: () => editorOptions,
    dataModel: {
      get() {
        return this.content;
      },
      set(value) {
        this.$emit("change-content", value);
      },
    },
  },
};
</script>

<style lang="stylus">
@import '~vue2-editor/dist/vue2-editor.css';

.label {
  position: relative;
  display: block;
  font_small();
  margin-bottom: 5px;
}

.ql-editor {
  max-height: 300px;
}

* {
  font-family: 'Montserrat', sans-serif !important;
}

strong {
  font_bold();
}

em {
  font-style: italic;
}

.textarea-emoji-control {
  display: none;
}
</style>
