import supportedLangs from "@/utils/supportedLangs";
import Vue from "vue";
import axios from "axios";
import Vuex from "vuex";
import leadershipCourses from "./modules/leadershipCourses";
import leadershipCourse from "./modules/leadershipCourse";
import leadershipNewCourse from "./modules/leadershipNewCourse";
import leadershipLessons from "./modules/leadershipLessons";
import leadershipLesson from "./modules/leadershipLesson";
import leadershipTask from "./modules/leadershipTask";
import leadershipTaskPoll from "./modules/leadershipTaskPoll";
import leadershipTaskAction from "./modules/leadershipTaskAction";
import user from "./modules/user";
import fileUpload from "./modules/fileUpload";
import autowebinars from "./modules/autowebinars";
import autowebinar from "./modules/autowebinar";
import presentation from "./modules/presentation";
import videoEditor from "./modules/videoEditor";
import chats from "./modules/chats";
import funnels from "./modules/funnels";
import funnel from "./modules/funnel";
import funnelGreeting from "./modules/funnelGreeting";
import funnelPsychotypes from "./modules/funnelPsychotypes";
import funnelBots from "./modules/funnelBots";
import funnelAuto from "./modules/funnelAuto";
import leadershipTaskUpload from "./modules/leadershipTaskUpload";
import leadershipTaskVideo from "./modules/leadershipTaskVideo";
import funnelVariables from "./modules/funnelVariables";
import funnelLeads from "./modules/funnelLeads";
import funnelLinksList from "./modules/funnelLinksList";
import funnelCreateLink from "./modules/funnelCreateLink";
import funnelCreateLinkWithoutChatbot from "./modules/funnelCreateLinkWithoutChatbot";
import team from "./modules/team";
import products from "./modules/products";
import chatBotProfile from "./modules/chatBotProfile";
import autowebinarVideoUpload from "./modules/autowebinarVideoUpload";
import leadershipProgram from "./modules/leadershipProgram";
import leadershipPrograms from "./modules/leadershipPrograms";
import leadershipProgramCourses from "./modules/leadershipProgramCourses";
import leads from "./modules/leads";
import leadershipTasks from "./modules/leadershipTasks";
import lead from "./modules/lead";
import leadNotes from "./modules/leadNotes";
import leadsTaskCreate from "./modules/leadsTaskCreate";
import leadTasks from "./modules/leadTasks";
import leadTask from "./modules/leadTask";
import leadsProducts from "./modules/leadsProducts";
import leadsStatuses from "./modules/leadsStatuses";
import leadsCalls from "./modules/leadsCalls";
import caller from "./modules/caller";
import leadsCreateLink from "./modules/leadsCreateLink";
import { detect } from "detect-browser";
import assignment from "./modules/assignment";
import assignmentStatuses from "./modules/assignmentStatuses";
import assignmentManagers from "./modules/assignmentManagers";
import assignmentSources from "./modules/assignmentSources";
import updatedFunnelLeads from "./modules/updatedFunnelLeads";
import updatedAssignment from "./modules/updatedAssignment";
import updatedLeads from "./modules/updatedLeads";
import updatedLead from "./modules/updatedLead";
import updatedLeadChangeStatus from "./modules/updatedLeadChangeStatus";
import updatedLeadsStatuses from "./modules/updatedLeadsStatuses";
import updatedLeadNotes from "./modules/updatedLeadNotes";
import updatedLeadsCreateOrder from "./modules/updatedLeadsCreateOrder";
import updatedFunnelCreateLink from "./modules/updatedFunnelCreateLink";
import updatedFunnelLinksList from "./modules/updatedFunnelLinksList";
import updatedLeadsCreateLinkToWebinar from "./modules/updatedLeadsCreateLinkToWebinar";
import updatedOrderStatuses from "./modules/updatedOrderStatuses";
import updatedFilterFunnels from "./modules/updatedFilterFunnels";
import updatedTags from "./modules/updatedTags";
import updatedLeadsRestartFunnel from "./modules/updatedLeadsRestartFunnel";
import contacts from "./modules/contacts";
import contactsProfile from "./modules/contactsProfile";
import contactsProfileEvents from "./modules/contactsProfileEvents";
import contactsProfileNotes from "./modules/contactsProfileNotes";
import contactsProfileReminders from "./modules/contactsProfileReminders";
import contactsProfileMeetings from "./modules/contactsProfileMeetings";
import contactsTags from "./modules/contactsTags";
import contactsProfileNew from "./modules/contactsProfileNew";
import contactsUnion from "./modules/contactsUnion";

import sharedMutations from "vuex-shared-mutations";

import { getLang } from "@/utils/cookies/getLang";

Vue.use(Vuex);

const langsList = {
  ru: "Русский",
  en: "Английский",
  tr: "Турецкий",
  de: "Немецкий",
  es: "Испанский",
};

const axiosConfig = () => ({
  baseURL: process.env.VUE_APP_URL,
});

export default new Vuex.Store({
  state: {
    supportedLangs: supportedLangs(),
    siteLang: getLang() || "ru",
    userBrowser: detect() || {},
  },
  getters: {
    supportedLangs(state) {
      return state.supportedLangs;
    },
    supportedLangsTitled(state) {
      return state.supportedLangs.map(lang => ({
        value: lang,
        title: langsList[lang],
      }));
    },
    currentLang(state) {
      return state.siteLang;
    },
    userBrowser(state) {
      return state.userBrowser;
    },
  },
  mutations: {
    CHANGE_LANG(state, lang) {
      state.siteLang = lang;
    },
  },

  plugins: [
    sharedMutations({
      predicate: [
        "updatedLeads/UPDATE_LEAD_STATUS",
        "contacts/SET_DELETED_CONTACTS_COUNTER",
      ],
    }),
  ],

  modules: {
    leadershipCourses,
    leadershipNewCourse,
    leadershipCourse,
    leadershipLessons,
    leadershipLesson,
    leadershipTask,
    leadershipTaskPoll,
    leadershipTaskAction,
    user,
    fileUpload,
    autowebinars,
    autowebinar,
    presentation,
    videoEditor,
    chats,
    funnels,
    funnel,
    funnelGreeting,
    funnelPsychotypes,
    funnelBots,
    funnelAuto,
    leadershipTaskUpload,
    funnelVariables,
    funnelLeads,
    funnelLinksList,
    funnelCreateLink,
    funnelCreateLinkWithoutChatbot,
    team,
    products,
    chatBotProfile,
    autowebinarVideoUpload,
    leadershipPrograms,
    leadershipProgram,
    leadershipProgramCourses,
    leads,
    leadershipTasks,
    leadershipTaskVideo,
    lead,
    leadNotes,
    leadsTaskCreate,
    leadTasks,
    leadTask,
    leadsProducts,
    leadsStatuses,
    leadsCalls,
    caller,
    leadsCreateLink,
    assignment,
    assignmentStatuses,
    assignmentManagers,
    assignmentSources,
    updatedFunnelLeads,
    updatedAssignment,
    updatedLeads,
    updatedLead,
    updatedLeadChangeStatus,
    updatedLeadsStatuses,
    updatedLeadNotes,
    updatedLeadsCreateOrder,
    updatedFunnelCreateLink,
    updatedFunnelLinksList,
    updatedLeadsCreateLinkToWebinar,
    updatedOrderStatuses,
    updatedFilterFunnels,
    updatedTags,
    updatedLeadsRestartFunnel,
    contacts,
    contactsProfile,
    contactsProfileEvents,
    contactsProfileNotes,
    contactsProfileReminders,
    contactsProfileMeetings,
    contactsTags,
    contactsProfileNew,
    contactsUnion,
  },
});
