<template>
  <div class="leadsCreateLinkModal">
    <div class="loader" v-if="pending">
      <loader />
    </div>

    <!-- BODY -->
    <div :class="{ layout: pending }">
      <updated-leads-create-order-success v-if="linkCreated" />
      <updated-leads-create-order :lead="lead" v-else />
    </div>
    <!-- BODY -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UpdatedLeadsCreateOrder from "@/components/Leads/UpdatedLeadsCreateOrder.vue";
import UpdatedLeadsCreateOrderSuccess from "@/components/Leads/UpdatedLeadsCreateOrderSuccess.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "UpdatedLeadsCreateOrderBox",
  components: {
    UpdatedLeadsCreateOrder,
    UpdatedLeadsCreateOrderSuccess,
    Loader,
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      linkCreated: "updatedLeadsCreateOrder/linkCreated",
      pending: "updatedLeadsCreateOrder/pending",
    }),
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLinkModal {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-size: 10px;
  line-height: 14px;

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }
}

.loader {
  position: absolute;
}

.layout {
  opacity: 0.6;
}
</style>
