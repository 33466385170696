<template>
  <div class="funnel__linkListTbodyItem">
    <div class="funnel__linkListTbodyCell funnel__linkListTheadCellDate">
      {{ createdAt }}
    </div>
    <div class="funnel__linkListTbodyCell funnel__linkListTheadCellName">
      {{ name }}
    </div>
    <div
      v-if="!updatedFunnel"
      class="funnel__linkListTbodyCell funnel__linkListTheadCellType"
    >
      <Tr v-if="type === 'vebinar'">Без чат-бота</Tr>
      <Tr v-else-if="type === 'funnel'">С чат-ботом</Tr>
    </div>
    <div class="funnel__linkListTbodyCell funnel__linkListTheadCellLink">
      {{ url }}
    </div>
    <div class="funnel__linkListTbodyCell funnel__linkListTheadCellCopy">
      <button
        class="funnel__linkCreatedBodyCopyLink"
        type="button"
        v-clipboard="copy"
        :key="url"
      >
        <svg>
          <use xlink:href="@/assets/svg/sprite_funnel.svg#copy" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { runNotice } from "@/utils/notifications";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "FunnelLinkListItem",
  data() {
    return {};
  },
  props: {
    link: {
      type: Object,
      required: true,
    },
    updatedFunnel: Boolean,
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      managers: "assignmentManagers/managers",
    }),

    createdAt() {
      return this.updatedFunnel ? moment(this.link.created_at)
        .utcOffset(6)
        .locale(this.currentLang).format("DD.MM.YYYY HH:mm") : moment(this.link.created_at).format("DD.MM.YYYY HH:mm");
    },
    name() {
      return this.link.name;
    },
    type() {
      return this.link.type;
    },
    url() {
      return this.updatedFunnel ? this.link.redirect_url : this.link.url;
    },
  },
  methods: {
    copy() {
      runNotice("success", this.$t("Ссылка скопирована"));
      return this.updatedFunnel ? this.link.redirect_url : this.link.url;
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnel__linkListTbodyItem {
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  width: 293px;
}

.funnel__linkListTbodyCell {
  padding: 3px 0;
  text-align: left;
  font_regular();
  word-break: break-all;

  &_bordered span {
    display: inline-block;
    border-bottom: 2px dotted #0F164260;
  }
}

.funnel__linkListTheadCellDate {
  margin-right: 4px;
  width: 110px;
  width: 280px;
}

.funnel__linkListTheadCellName {
  order: -1;
  width: 280px;
}

.funnel__linkListTheadCellType {
  width: 140px;
}

.funnel__linkListTheadCellFollows {
  position: relative;
  padding-left: 155px;
  width: fit-content;
}

.funnel__linkListTheadCellLink {
  margin-right: 8px;
  width: 265px;
  width: fit-content;
}

.funnel__linkListTheadCellCopy {
  width: 24px;
}

.funnel__linkListTbodyCellMobile {
  position: absolute;
  left: 0;
  font-size: 12px;
}

.funnel__linkCreatedBodyCopyLink {
  display: block;
  padding: 0;
  width: 18px;
  height: 18px;
  background-color: unset;
  border: none;
  vertical-align: bottom;

  & svg {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 425px) {
  .funnel__linkListTbodyItem {
    margin-right: 20px;
  }
}

@media (min-width: desktop) {
  .funnel__linkListTbodyItem {
    display: flex;
    margin-right: 0;
    margin-bottom: 0;
    width: fit-content;

    &:hover {
      background-color: grey_text_color;

      & .funnel__linkCreatedBodyCopyLink {
        display: block;
      }
    }
  }

  .funnel__linkListTbodyCell {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .funnel__linkListTheadCellDate {
    margin-right: 8px;
    width: 145px;
  }

  .funnel__linkListTheadCellName {
    order: unset;
    width: 250px;
    word-break: unset;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .funnel__linkListTheadCellFollows {
    padding-left: 0;
    width: 192px;
  }

  .funnel__linkListTheadCellLink {
    margin-right: 8px;
    width: 240px;
  }

  .funnel__linkListTheadCellCopy {
    width: 19px;
    height: auto;
  }

  .funnel__linkListTbodyCellMobile {
    display: none;
  }

  .funnel__linkCreatedBodyCopyLink {
    display: none;
    width: 14px;
    height: 16px;
  }
}
</style>
