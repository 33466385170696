import axios from "axios";
import { getUrlLang } from "./cookies/getLang";
import { getToken } from "./cookies/getToken";
// import { getUrlLang } from "./cookies/getLang";
// import { getToken } from "./cookies/getToken";
// import { errorMiddlewareNotice } from "./notifications";

const instance = axios.create({
  baseURL: process.env.baseUrl,
  headers: {
    Authorization: getToken(),
    ...(getUrlLang() ? { "accept-language": getUrlLang() } : {}),
  },
});

instance.interceptors.request.use(config => {
  const token = getToken();
  config.headers.Authorization = token;
  config.headers["accept-language"] = getUrlLang();
  return config;
});
instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default instance;
