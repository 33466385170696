import createLocales from "@/utils/createLocales";
import supportedLangs from "@/utils/supportedLangs";
import Universal from ".";

class Task extends Universal {
  constructor(data?) {
    if (data) {
      super(data);
    } else {
      super({
        id: undefined,
        type: "video", // poll | action | upload | video
        locales: {
          ...createLocales(["title"], supportedLangs(), ""),
        },
        reward_gold: 0,
        reward_expire_gold: 0,
        approve_reward_brilliant: 0,
        approve_reward_expire_brilliant: 0,
      });
    }
  }
}

export default Task;
