import i18n from "@/plugins/i18n";
import router from "@/router";
import axiosConfig from "@/utils/axiosConfigChatBot";
import { errorMiddlewareNotice, runNotice } from "@/utils/notifications";
import axios from "axios";
import { pathOr } from "ramda";

const funnelCreateValidationCheck = ({ name, product_id }) => {
  let valid = true;
  if (!name.trim()) {
    runNotice("error", i18n.t("Заполните название воронки"));
    valid = false;
  }
  if (!product_id) {
    runNotice("error", i18n.t("Выберите продукт"));
    valid = false;
  }
  return valid;
};

export default {
  namespaced: true,
  state: () => ({
    data: {},
    variables: {},
    errors: {},
    pending: false,
  }),

  getters: {
    name(state) {
      return pathOr("", ["data", "name"], state);
    },
    lang(state) {
      return state.data.lang;
    },
    status(state) {
      return state.data.status;
    },
    pending(state) {
      return state.pending;
    },
    variables(state) {
      return state.variables;
    },
    productId(state) {
      return (
        pathOr(null, ["data", "product", "id"], state) || state.data.product_id
      );
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, data) {
      state.data.name = data;
    },
    CHANGE_LANG(state, data) {
      state.data.lang = data;
    },
    CHANGE_STATUS(state, data) {
      state.data.status = data;
    },
    SET_VARIABLES(state, data) {
      state.variables = data;
    },
    CHANGE_PRODUCT(state, id) {
      state.data.product_id = id;
    },
  },

  actions: {
    initNew({ commit, rootGetters }) {
      commit("SET_DATA", {
        name: "",
        lang: rootGetters.currentLang,
        status: "draft",
        product_id: null,
      });
    },
    async fetch({ commit, rootGetters }, id) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", {});

        const response = await axios.get(
          `/funnels/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async create({ commit, state, rootGetters }) {
      try {
        if (!funnelCreateValidationCheck(state.data)) {
          return;
        }

        commit("SET_PENDING", true);

        const data = {
          name: state.data.name.trim(),
          lang: state.data.lang,
          product_id: state.data.product_id,
          // status: state.data.status,
        };

        if (!data.name) {
          runNotice("error", i18n.t("Название не может быть пустым"));
          commit("SET_PENDING", false);
          return;
        }

        const response = await axios.post(
          "/funnels",
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        errorMiddlewareNotice(e.response.data);
      }
    },

    async patch({ commit, state, rootGetters }) {
      try {
        commit("SET_PENDING", true);

        const { funnelId } = router.currentRoute.params;

        const data = {
          name: state.data.name,
          lang: state.data.lang,
          status: state.data.status,
        };

        const response = await axios.put(
          `/funnels/${funnelId}`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        errorMiddlewareNotice(e.response.data);
      }
    },

    async publishFunnel({ commit, state, rootGetters }) {
      try {
        commit("SET_PENDING", true);

        const { funnelId } = router.currentRoute.params;

        const response = await axios.post(
          `/funnels/${funnelId}/publish`,
          {},
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        errorMiddlewareNotice(e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
