import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { find, propEq } from "ramda";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    form: {
      type: "vebinar",
      product_id: "",
      video_id: "",
      started_at: "",
      name: "",
      description: "",
      lead: {
        first_name: "",
        last_name: "",
        phone: "",
        usid: "",
      },
    },
    createdUrl: "",
    createdName: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    name(state) {
      return state.form.name;
    },
    description(state) {
      return state.form.description;
    },
    leadFirstName(state) {
      return state.form.lead.first_name;
    },
    leadLastName(state) {
      return state.form.lead.last_name;
    },
    leadPhone(state) {
      return state.form.lead.phone;
    },
    linkCreated(state) {
      return state.created;
    },
    products(state) {
      if (state.data.length > 0) {
        const products = [...state.data];
        return products.filter(product => product.videos.length > 0);
      }
    },
    videos(state) {
      if (state.form.product_id) {
        const product: any = find(propEq("id", Number(state.form.product_id)))(
          state.data
        );
        return product ? product.videos : [];
      }
      return [];
    },
    videoId(state) {
      return state.form.video_id;
    },
    startedAt(state) {
      return state.form.started_at;
    },
    errors(state) {
      return state.errors;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
    createdName(state) {
      return state.createdName;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.form.name = value;
    },
    CHANGE_DESCRIPTION(state, description) {
      state.form.description = description;
    },
    CHANGE_VIDEO(state, value) {
      state.form.video_id = value;
    },
    CHANGE_STARTED_AT(state, value) {
      state.form.started_at = value;
    },
    CHANGE_PRODUCT(state, value) {
      state.form.product_id = value;
      state.form.video_id = "";
    },
    CHANGE_LEAD_FIRST_NAME(state, value) {
      state.form.lead.first_name = value;
    },
    CHANGE_LEAD_LAST_NAME(state, value) {
      state.form.lead.last_name = value;
    },
    CHANGE_LEAD_PHONE(state, value) {
      state.form.lead.phone = value;
    },
    CHANGE_LEAD_USID(state, value) {
      state.form.lead.usid = value;
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED(state, data) {
      state.createdName = data.name;
      state.createdUrl = data.url;
    },
    CLEAR_STORE(state) {
      state.form = {
        type: "vebinar",
        product_id: "",
        video_id: "",
        started_at: "",
        name: "",
        description: "",
        lead: {
          first_name: "",
          last_name: "",
          usid: "",
          phone: "",
        },
      };
      state.createdUrl = "";
      state.createdName = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async fetch({ commit, state }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(`/products`, axiosConfig(tasqToken));
        if (response.status === 200) {
          commit("SET_DATA", response.data.response);

          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async createLink({ commit, state }) {
      const data = {
        ...state.form,
        started_at: moment(state.form.started_at).format("DD.MM.YYYY HH:mm:ss"),
      };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/links`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 200) {
          commit("SET_CREATED", response.data.response);
          commit("LINK_CREATED", true);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
