import createLocales from "@/utils/createLocales";
import postfixParser from "@/utils/postfixParser";
import supportedLangs from "@/utils/supportedLangs";
import moment from "moment";
import { omit } from "ramda";
import Universal from ".";
import Course from "./course";

const transformVideoObject = (apiVideoModel?) => {
  if (apiVideoModel && apiVideoModel.length) {
    return {
      enabled: true,
      items: [
        ...apiVideoModel.map(model => postfixParser.parse(model)),
        ...(apiVideoModel.length == 1
          ? [
              {
                locales: {
                  ...createLocales(["url"], supportedLangs(), ""),
                },
                reward_gold: 0,
                reward_expire_gold: 0,
              },
            ]
          : []),
      ],
    };
  }
  return {
    enabled: false,
    items: [
      {
        locales: {
          ...createLocales(["url"], supportedLangs(), ""),
        },
        reward_gold: 0,
        reward_expire_gold: 0,
      },
      {
        locales: {
          ...createLocales(["url"], supportedLangs(), ""),
        },
        reward_gold: 0,
        reward_expire_gold: 0,
      },
    ],
  };
};
export const transformDocFiles = files => {
  if (files) {
    return files.map(file => ({
      id: file.id,
      language: file.language,
      name: file.file_object.original_filename,
      url: file.file_object.file,
    }));
  }
  return [];
};

export const transformDeadline = (deadline?) => ({
  checked: !!deadline,
  value: deadline,
});

export const transformDeadlineForUpdate = (deadline?) =>
  deadline.checked ? deadline.value : null;

export const transformLessonDelay = (data?) => {
  if (data) {
    if (data.will_be_available_at) {
      // const unixTime = moment(data.will_be_available_at)
      //   .utcOffset(180)
      //   .unix();

      return {
        delayType: "by_date", // "none" | "delay" | "by_date"
        delay_days: "",
        delay_time: "",
        will_be_available_at: moment(data.will_be_available_at)
          .subtract("hours", 3)
          .format("YYYY-MM-DD HH:mm:ss"),
      };
    } else if (data.delay_days || data.delay_time) {
      return {
        delayType: "delay", // "none" | "delay" | "by_date"
        delay_days: data.delay_days ? data.delay_days : "",
        delay_time: data.delay_time ? data.delay_time : "",
        will_be_available_at: "",
      };
    } else {
      return {
        delayType: "none", // "none" | "delay" | "by_date"
        delay_days: "",
        delay_time: "",
        will_be_available_at: "",
      };
    }
  }

  return {
    delayType: "none", // "none" | "delay" | "by_date"
    delay_days: "",
    delay_time: "",
    will_be_available_at: "",
  };
};

export const transformDelayForUpdate = data => {
  const {
    lessonDelay: { delayType, delay_days, delay_time, will_be_available_at },
  } = data;
  if (delayType === "delay") {
    data.delay_days = delay_days ? delay_days : "";
    data.delay_time = delay_time ? delay_time : "";
    data.will_be_available_at = null;
  } else if (delayType === "by_date") {
    data.delay_days = null;
    data.delay_time = null;
    data.will_be_available_at = will_be_available_at
      ? moment(will_be_available_at)
          .utc()
          .utcOffset(-180)
          .format()
      : "";
  } else {
    data.delay_days = null;
    data.delay_time = null;
    data.will_be_available_at = null;
  }

  return data;
};

class Lesson extends Universal {
  constructor(data?) {
    if (data) {
      const withParsedVideo = {
        ...data,
        video: transformVideoObject(data.quest_task_videos_objects),
        linkedFiles: transformDocFiles(data.quest_task_doc_files_objects),
        quest_object: new Course(data.quest_object),
        lessonDelay: transformLessonDelay(data),
        deadline: transformDeadline(data.time_to_complete),
      };
      super(withParsedVideo);
    } else {
      super({
        id: 0,
        can_skip: false,
        quest_object: new Course(),
        course: 0,
        locales: {
          ...createLocales(["name", "content"], supportedLangs(), ""),
        },
        position: 0,
        linkedFiles: [],
        video: transformVideoObject(),
        // video: {
        //   enabled: false,

        //   locales: {
        //     ...createLocales(["url"], supportedLangs(), ""),
        //   },
        //   reward_gold: 0,
        //   reward_expire_gold: 0,
        // },
        lessonDelay: transformLessonDelay(),
        deadline: transformDeadline(),
      });
    }
  }

  getCreateModel() {
    let fullModel = this.transformLocales();
    const video = fullModel.video.enabled
      ? fullModel.video.items
          .map(item => this.transformLocales(item))
          .filter(
            item =>
              item.url_ru ||
              item.url_en ||
              item.url_tr ||
              item.url_de ||
              item.url_es
          )
      : [];
    const time_to_complete = transformDeadlineForUpdate(fullModel.deadline);

    fullModel = transformDelayForUpdate(fullModel);
    const data = omit(
      [
        "id",
        "video",
        "position",
        "quest_object",
        "linkedFiles",
        "quest_task_doc_files_objects",
        "lessonDelay",
        "deadline",
      ],
      {
        ...fullModel,
        time_to_complete,
        quest_task_videos_objects: video,
      }
    );

    return data;
  }
}

export default Lesson;
