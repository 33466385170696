<template>
  <div class="price">
    <div class="name">
      <Tr>Доступ</Tr>
    </div>
    <div
      class="priceRadioButton"
      v-for="option in paymentOptions"
      :key="option.value"
    >
      <label class="priceRadioButton__label" :for="option.value">
        <input
          class="priceRadioButton__input"
          type="radio"
          name="prayment_type"
          :id="option.value"
          :value="option.value"
          v-model="paymentTypeComputed"
        />
        <span class="priceRadioButton__tuning" />
        <Tr>{{ option.label }}</Tr>
      </label>
    </div>

    <div class="box" v-if="paymentType === 'paid'">
      <input
        type="number"
        class="price__input"
        step="1"
        :key="'input-quest-price'"
        :min="0"
        v-model="price"
      />
      <div class="currency">€</div>
    </div>
    <div :key="error" class="errors__item" v-for="error in errors">
      <Tr>{{ error }}</Tr>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeadershipCoursePrice",
  components: {},
  props: {
    cost: {
      type: [Number, String],
      requred: true,
    },
    paymentType: String,
    errors: Array,
    isProgram: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    paymentOptions() {
      return [
        {
          label: "Бесплатный",
          value: "free",
        },
        ...(!this.isProgram
          ? [
            {
              label: "Входит в подписку",
              value: "subscription",
            },
            {
              label: "Платный",
              value: "paid",
            },
          ]
          : []),

        {
          label: "Закрытый доступ",
          value: "private",
        },
      ];
    },
    price: {
      get() {
        return this.cost;
      },
      set(value) {
        this.$emit("change-price", value);
      },
    },
    paymentTypeComputed: {
      get() {
        return this.paymentType;
      },
      set(value) {
        this.$emit("change-payment-type", value);
      },
    },
  },
  methods: {
    changeCheckbox() {
      this.$emit("toggle-cost");
    },
  },
};
</script>

<style lang="stylus" scoped>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.price {
  margin-bottom: 15px;
  margin-top: 20px;

  &__input {
    width: 100%;
    margin-top: 10px;
    padding: 5px 20px 6px 7px;
    width: 100%;
    border: 1px solid #e6e7eb;
    color: #0f1642;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.priceRadioButton {
  margin-top: 8px;

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;

    &:checked + .priceRadioButton__tuning {
      background-color: #0F1642;
      box-shadow: inset 0px 0px 0px 2px #fff;
    }
  }

  &__tuning {
    display: inline-block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid #e6e7eb;
    border-radius: 50%;
  }

  &__label {
    display: flex;
    align-items: center;
    color: #0f1642;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.name {
  font-size: 12px;
}

.box {
  max-width: 100px;
  position: relative;
  margin-bottom: 5px;
}

.currency {
  position: absolute;
  height: calc(100% - 10px);
  top: 10px;
  right: 5px;
  display: flex;
  align-items: center;
  font-weight: bold;
}

.errors {
  margin-top: 10px;

  &__item {
    color: danger_text_color;
  }
}
</style>
