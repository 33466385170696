<template>
  <button class="showmoreButton" type="button" @click="fetchMore">
    <Tr>Показать больше</Tr>
  </button>
</template>

<script>
export default {
  name: "FetchMore",
  methods: {
    fetchMore() {
      this.$emit("fetch-more");
    },
  },
};
</script>

<style lang="stylus" scoped>
.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();

  @media (min-width: tablet_large) {
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
