import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import router from "@/router";
import { pathOr } from "ramda";
import { errorMiddlewareNotice, runNotice } from "@/utils/notifications";
import i18n from "@/plugins/i18n";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
    errors: {
      telegram: "",
      viber: "",
    },
    types: {
      telegram: "company",
      viber: "company",
    },
    tokens: {
      telegram: "",
      viber: "",
    },
    creatingSuccess: {
      telegram: undefined,
      viber: undefined,
    },
    userBots: [],
    linkedBots: {
      telegram: undefined,
      viber: undefined,
    },
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    types(state) {
      return state.types;
    },
    tokens(state) {
      return state.tokens;
    },
    errors(state) {
      return state.errors;
    },
    creatingSuccess(state) {
      return state.creatingSuccess;
    },
    telegramLinked(state) {
      return pathOr(false, ["bots", "telegram", "id"], state.data);
    },
    viberLinked(state) {
      return pathOr(false, ["bots", "viber", "id"], state.data);
    },
    name(state) {
      return state.data.name;
    },
    userBots: state => messenger => {
      return state.userBots.filter(bot => bot.messenger === messenger);
    },
    linkedBot: state => messenger => {
      return state.linkedBots[messenger];
    },
    botsAreLinked(state) {
      return (
        pathOr(false, ["data", "bots", "telegram", "type"], state) &&
        pathOr(false, ["data", "bots", "viber", "type"], state)
      );
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;

      const { bots } = data;
      if (bots) {
        state.linkedBots.telegram =
          bots.telegram && bots.telegram.type ? bots.telegram.id : undefined;
        state.linkedBots.viber = bots.viber ? bots.viber.id : undefined;
      } else {
        state.linkedBots.telegram = "telegram";
        state.linkedBots.viber = "viber";
      }
    },
    SET_USERBOTS(state, data) {
      state.userBots = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_TYPE(state, { messenger, type }) {
      state.types[messenger] = type;
    },
    CHANGE_TOKEN(state, { messenger, value }) {
      state.tokens[messenger] = value;
    },
    SET_ERRORS(state, { message, messenger }) {
      state.errors[messenger] = message;
    },
    DROP_ERRORS(state, { messenger, value }) {
      state.errors[messenger] = value;
    },
    SET_SUCCESS(state, { messenger, login }) {
      state.creatingSuccess[messenger] = login;
    },

    CHANGE_BOT(state, { messenger, id }) {
      state.linkedBots[messenger] = id;
    },

    CLEAR_TOKEN(state, messenger) {
      state.tokens[messenger] = "";
    },
  },

  actions: {
    async fetch({ commit, rootGetters }, id) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", {});

        const response = await axios.get(
          `/funnels/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchUserBots({ commit, rootGetters }) {
      try {
        commit("SET_PENDING", true);
        const response = await axios.get(
          `/bots/`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status === 200) {
          commit("SET_USERBOTS", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createBot({ commit, rootGetters, state, dispatch }, { messenger }) {
      try {
        commit("DROP_ERRORS", messenger);
        commit("SET_PENDING", true);

        const data = {
          // type: state.types[messenger],
          type: "funnel",
          messenger: messenger,
          token: state.tokens[messenger],
        };

        const response = await axios.post(
          `/bots/`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          const responseData = response.data.response;
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          commit("CLEAR_TOKEN", messenger);
          dispatch("linkBot", {
            id: responseData.id,
            type: state.types[messenger],
            login: responseData.login,
            messenger,
          });
          dispatch("fetchUserBots");
          runNotice("success", i18n.t("Бот успешно добавлен"));
        } else {
          throw response.data.message;
        }
      } catch (e) {
        errorMiddlewareNotice(
          e.response.data.errors ? e.response.data : [e.response.data.message]
        );
        commit("SET_ERRORS", { message: e.response, messenger });
        commit("SET_PENDING", false);
      }
    },

    async linkBot({ commit, rootGetters, dispatch }, { id, messenger }) {
      try {
        commit("SET_PENDING", true);

        const data = {
          id,
          messenger,
          type: id === null ? "company" : "personal",
        };
        const { funnelId } = router.currentRoute.params;

        const response = await axios.put(
          `/funnels/${funnelId}/bots`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status == 200) {
          commit("SET_PENDING", false);
          dispatch("fetch", funnelId);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        errorMiddlewareNotice(e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
