import router from "@/router";
import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import qs from "qs";
import { adjust, assocPath, mapObjIndexed, sort } from "ramda";

const getQuestions = data => {
  if (data && data.elements) {
    let filtered = data.elements.filter(
      el => el.position && el.position.block && el.position.block === "webinar"
    );
    filtered = sort((a: any, b: any) => {
      return a.position.index - b.position.index;
    }, filtered);

    return filtered || [];
  }
  return [];
};

const getTimers = data => {
  if (data && data.elements) {
    let filtered = data.elements.filter(el => el.type === "timer");
    filtered = sort((a: any, b: any) => {
      return a.target - b.target;
    }, filtered);

    return filtered || [];
  }
  return [];
};

export default {
  namespaced: true,

  state: () => ({
    data: {},
    questions: [],
    errors: {},
    pending: false,
  }),

  getters: {
    name(state) {
      return state.data.name;
    },
    pending(state) {
      return state.pending;
    },
    questions(state) {
      return state.questions;
    },
    timers(state) {
      if (state.data.elements) {
        return getTimers(state.data);
      }
      return [];
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.questions = getQuestions(data);
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_MESSAGE(state, { message, type, value, index }) {
      state.questions = adjust(
        index,
        (question: any) =>
          assocPath(["parameters", "text", type], value, question),
        state.questions
      );
    },
    SET_MESSAGE(state, message) {
      state.questions = state.questions.map(mes =>
        mes.id === message.id ? message : mes
      );
    },
  },

  actions: {
    async fetch({ commit, rootGetters }, id) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", {});

        const response = await axios.get(
          `/funnels/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async onBlur({ dispatch }, data) {
      dispatch("putMessage", data);
    },

    async putMessage({ commit, rootGetters }, message) {
      const { id } = message;

      try {
        // commit("SET_PENDING", true);

        const data = qs.stringify(message);
        const { funnelId } = router.currentRoute.params;

        const response = await axios.put(
          `/funnels/${funnelId}/elements/${id}`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status == 200) {
          commit("SET_PENDING", false);
          commit("SET_MESSAGE", response.data.response);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async restoreMessage(
      { commit, rootGetters, dispatch },
      { message, questionType }
    ) {
      try {
        const data = qs.stringify(message.position);

        const response = await axios.get(
          `/funnels/default/elements?${data}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          // return response.data.response;
          dispatch("putMessage", {
            ...message,
            parameters: {
              ...message.parameters,
              text: {
                ...mapObjIndexed((val, key) => {
                  if (key === questionType) {
                    return val;
                  }
                  return val === null || val === ""
                    ? response.data.response.all
                    : val;
                }, message.parameters.text),
                [questionType]:
                  response.data.response[questionType] === null
                    ? response.data.response.all
                    : response.data.response[questionType],
              },
            },
          });
          // const { funnelId } = router.currentRoute.params;
          // dispatch("fetch", funnelId);
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
