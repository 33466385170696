import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import qs from "qs";
import { pathOr } from "ramda";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
    sort: {
      created_at: "desc",
    },
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    linksList(state) {
      return pathOr(0, ["data", "data"], state);
    },
    hasMorePages(state) {
      const current = pathOr(0, ["data", "pagination", "current_page"], state);
      const last = pathOr(0, ["data", "pagination", "last_page"], state);
      return current < last;
    },
    currentPage(state) {
      return pathOr(0, ["data", "pagination", "current_page"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = {
        data:
          data.pagination.current_page === 1
            ? data.data
            : state.data.data.concat(data.data),
        pagination: data.pagination,
      };
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    CHANGE_SORT(state) {
      state.sort.created_at = state.sort.created_at === "asc" ? "desc" : "asc";
    },
  },

  actions: {
    changeSort({ commit, dispatch }, page) {
      commit("CHANGE_SORT");
      dispatch("fetch", page);
    },
    async fetch({ commit, rootGetters, state }, page?) {
      try {
        commit("SET_PENDING", true);

        const params = qs.stringify({
          sort: state.sort,
          ...(page ? { page } : []),
        });

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/links?${params}`,
          axiosConfig(tasqToken)
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
