import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    phones_errors: {},
    emails_errors: {},
    links_errors: {},
    pending: false,
    actions: {
      isChangedNameMessengers: false,
      create: [],
      change: [],
      del: [],
    },
    status: [],
    responses: false,
    tags: [],
    tagsFilterValue: "",
    tagsFilterCounter: null,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    contact(state) {
      return state.data;
    },

    errors(state) {
      return state.errors;
    },

    phones_errors(state) {
      return state.phones_errors;
    },

    emails_errors(state) {
      return state.emails_errors;
    },

    links_errors(state) {
      return state.links_errors;
    },

    responses(state) {
      return state.responses;
    },

    isFilterChecked: state => value => {
      if (state.tags.length > 0) {
        return state.tags.includes(value) || state.data.tags.includes(value);
      }
      return false;
    },

    tagsFilterValue(state) {
      return state.tagsFilterValue;
    },

    tagsFilterCounter(state) {
      return state.tagsFilterCounter;
    },

    contactTags(state) {
      return state.tags;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    SET_ERRORS_CONTACT_PHONES(state, data) {
      state.phones_errors = data;
    },

    SET_ERRORS_CONTACT_EMAILS(state, data) {
      state.emails_errors = data;
    },

    SET_ERRORS_CONTACT_LINKS(state, data) {
      state.links_errors = data;
    },

    CHANGE_NAME_MESSENGERS(state, { param, value }) {
      state.data[param] = value;
      state.actions.isChangedNameMessengers = true;
    },

    RESET_NAME_MESSENGERS(state) {
      state.actions.isChangedNameMessengers = false;
    },
    RESET_ACTIONS_CREATE(state) {
      state.actions.create = [];
    },
    RESET_ACTIONS_CHANGE(state) {
      state.actions.change = [];
    },
    RESET_ACTIONS_DEL(state) {
      state.actions.del = [];
    },

    CHANGE_RESPONSE(state) {
      state.responses = true;
    },

    ADD_CONTACT_WAY_IN_ARRAY(state, contactWay) {
      if (contactWay === "links_objects") {
        state.data[contactWay].push({
          id: state.data[contactWay].find(
            el =>
              el.id ===
              state.data[contactWay][state.data[contactWay].length - 1].id + 100
          )
            ? Number(
                state.data[contactWay][state.data[contactWay].length - 1].id
              ) * 2
            : state.data[contactWay][state.data[contactWay].length - 1].id +
              100,
          link: "",
        });

        state.actions.create.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          link: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          link: "",
          profileId: state.data.id,
          isCreated: true,
        });
      } else {
        state.data[contactWay].push({
          id: state.data[contactWay].find(
            el =>
              el.id ===
              state.data[contactWay][state.data[contactWay].length - 1].id + 100
          )
            ? Number(
                state.data[contactWay][state.data[contactWay].length - 1].id
              ) * 2
            : state.data[contactWay][state.data[contactWay].length - 1].id +
              100,
          value: "",
        });

        state.actions.create.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          value: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          value: "",
          profileId: state.data.id,
          isCreated: true,
        });
      }
    },

    ADD_NEW_EMPTY_CONTACT_WAY_VALUE(state, name) {
      if (name === "links_objects") {
        state.data[name].push({
          id: 1,
          link: "",
        });

        state.actions.create.push({
          id: 1,
          name,
          link: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: 1,
          name,
          link: "",
          profileId: state.data.id,
          isCreated: true,
        });
      } else {
        state.data[name].push({
          id: 1,
          value: "",
        });

        state.actions.create.push({
          id: 1,
          name,
          value: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: 1,
          name,
          value: "",
          profileId: state.data.id,
          isCreated: true,
        });
      }
    },

    CHANGE_CONTACT_WAY_IN_ARRAY_VALUE(state, { name, value, id }) {
      const findedDataEl = state.data[name].find(el => el.id === Number(id));
      if (findedDataEl) {
        if (Object.prototype.hasOwnProperty.call(findedDataEl, "value")) {
          findedDataEl.value = value;
        } else if (Object.prototype.hasOwnProperty.call(findedDataEl, "link")) {
          findedDataEl.link = value;
        }
      }

      const findedActionsEl = state.actions.change.find(
        el => el.id === Number(id) && el.name === name
      );
      if (findedActionsEl) {
        if (name === "links_objects") {
          findedActionsEl.value = value;
        } else {
          findedActionsEl.link = value;
        }
        return;
      }
      if (name === "links_objects") {
        state.actions.change.push({
          id,
          name,
          link: value,
          profileId: state.data.id,
        });
      } else {
        state.actions.change.push({
          id,
          name,
          value,
          profileId: state.data.id,
        });
      }
    },

    DEL_CONTACT_WAY_IN_ARRAY(state, { id, name }) {
      // del from ui
      const findedEl = state.data[name].find(el => el.id === id);
      const findedElIndex = state.data[name].indexOf(findedEl);
      state.data[name].splice(findedElIndex, 1);

      // del from created
      const createdEl = state.actions.create.find(
        createdEl => createdEl.id === id
      );
      if (createdEl) {
        const createdElIndex = state.actions.create.indexOf(createdEl);
        state.actions.create.splice(createdElIndex, 1);
        state.actions.change.splice(createdElIndex, 1);
        return;
      }

      state.actions.del.push({ id: id, name: name });
    },

    CLEAR_ACTIONS(state) {
      state.actions = {
        isChangedNameMessengers: false,
        create: [],
        change: [],
        del: [],
      };
    },

    CLEAR_RESPONSE(state) {
      state.responses = false;
    },

    CLEAR_ERRORS(state) {
      state.errors = {};
    },

    CLEAR_ERRORS_PHONES_OBJECTS(state) {
      state.phones_errors = {};
    },

    CLEAR_ERRORS_EMAILS_OBJECTS(state) {
      state.emails_errors = {};
    },

    CLEAR_ERRORS_LINKS_OBJECTS(state) {
      state.links_errors = {};
    },

    CHANGE_STATUS(state, { name, param }) {
      state.data[name] = param;
      if (state.status.includes(name)) {
        return;
      }
      state.status.push(name);
    },

    SET_TAGS_CLEAR(state) {
      state.tags = [];
      state.tagsFilterValue = "";
      state.tagsFilterCounter = null;
    },

    SET_TAGS(state, value) {
      if (state.tags.includes(value)) {
        state.tags = state.tags.filter(el => el !== value);
      } else {
        state.tags = [...state.tags, value];
      }
    },

    SET_TAGS_FILTER_VALUE(state, value) {
      state.tagsFilterValue = value;
    },

    SET_TAGS_FILTER_COUNTER(state, value) {
      state.tagsFilterCounter = Number(value);
    },
  },

  actions: {
    async fetch({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contacts/${id}/`);

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("CLEAR_ACTIONS");
          commit("CLEAR_RESPONSE");
          commit("SET_TAGS_CLEAR");
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchContactEmails({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_emails/`);

        if (response.status === 200) {
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchContactPhones({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_phones/`);

        if (response.status === 200) {
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchContactLinks({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_links/`);

        if (response.status === 200) {
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async changeContact({ commit, state, dispatch }) {
      try {
        commit("SET_PENDING", true);

        const responseData: any = {
          first_name: state.data.first_name,
          last_name: state.data.last_name,
          telegram: state.data.telegram,
          whatsapp: state.data.whatsapp,
          country: state.data.country,
          city: state.data.city,
        };

        const setEmptyResponse = contactObjects => {
          if (state.data[contactObjects].length > 0) {
            responseData[contactObjects] = [...state.data[contactObjects]];
          }

          if (
            contactObjects === "links_objects" &&
            state.data[contactObjects].length === 1 &&
            !state.data[contactObjects][0].link
          ) {
            responseData[contactObjects] = [];
          } else if (
            contactObjects !== "links_objects" &&
            state.data[contactObjects].length === 1 &&
            !state.data[contactObjects][0].value
          ) {
            responseData[contactObjects] = [];
          }
        };

        setEmptyResponse("phones_objects");
        setEmptyResponse("emails_objects");
        setEmptyResponse("links_objects");

        // CHANGE CONTACT TAGS
        if (state.tags.length > 0) {
          const promisesOnDelete: any[] = [];

          await Promise.all(
            state.tags.map(el => {
              if (state.data.tags.includes(el)) {
                return dispatch(
                  "contactsTags/fetchIdTag",
                  {
                    tagId: el,
                    contactId: state.data.id,
                  },
                  { root: true }
                )
                  .then(response => {
                    if (response && response.status === 200) {
                      promisesOnDelete.push(
                        dispatch(
                          "contactsTags/delTagFromContact",
                          response.data.results[0].id,
                          { root: true }
                        )
                          .then(() => {
                            commit("SET_TAGS_CLEAR");
                          })
                          .catch(e => {
                            console.log(e);
                          })
                      );
                    }
                  })
                  .catch(e => {
                    console.log(e);
                  });
              } else {
                return dispatch(
                  "contactsTags/addTag",
                  {
                    tagId: el,
                    contactId: state.data.id,
                  },
                  { root: true }
                )
                  .then(() => {
                    commit("SET_TAGS_CLEAR");
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }
            })
          );

          await Promise.all(promisesOnDelete);
        }
        // CHANGE CONTACT TAGS

        const response = await Vue.axios.patch(
          `/contacts/${state.data.id}/`,
          responseData
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("SET_DATA", response.data);
          commit("CLEAR_ERRORS");
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },

    async changeStatus({ commit, state }) {
      try {
        commit("SET_PENDING", true);

        const data = {};

        state.status.forEach(el => {
          return (data[el] = state.data[el]);
        });

        const response = await Vue.axios.patch(
          `/contacts/${state.data.id}/`,
          data
        );

        if (String(response.status).startsWith("20")) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },

    async deleteContact({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(`/contacts/${id}/`);

        if (response.status === 204) {
          commit("SET_PENDING", false);
          return response;
        } else {
          console.log(response);
          throw response.data.message;
        }
      } catch (e) {
        console.log(e);
        commit("SET_PENDING", false);
      }
    },
  },
};
