<template>
  <draggable
    @change="changePosition"
    class="draggable__list draggable__list-group"
    handle=".draggable__buttonMove"
    tag="ul"
    v-model="listComputed"
  >
    <slot></slot>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  props: {
    list: {
      type: Array,
    },
  },
  computed: {
    listComputed: {
      get() {
        return this.list;
      },
      set(value) {
        this.$emit("change-position-mirror", value);
      },
    },
  },
  methods: {
    changePosition({ moved }) {
      this.$emit("change-position", {
        elementId: moved.element.id,
        old_position: moved.oldIndex + 1,
        new_position: moved.newIndex + 1,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.draggable__list {
  counter-reset: list;
  display: grid;
  grid-row-gap: 4px;
}
</style>
