<template>
  <div class="loaderBox">
    <div class="loaderIcon" v-show="pending">
      <loader />
    </div>

    <contacts-profile-notes-feed
      @create-note="create"
      @update-note="update"
      @delete-note="del"
      :notes="notes"
      :placeholder="this.$t(placeholder)"
    />

    <fetch-more @fetch-more="fetchMore" v-if="hasNextPage" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Loader from "@/components/Loader.vue";
import ContactsProfileNotesFeed from "@/components/Contacts/ContactsProfileNotesFeed.vue";
import FetchMore from "@/components/FetchMore.vue";

export default {
  name: "ContactsProfileNotes",

  components: {
    Loader,
    ContactsProfileNotesFeed,
    FetchMore,
  },

  data() {
    return {
      placeholder: "Заметки - это одна из самых важных",
    };
  },

  computed: {
    ...mapGetters({
      notes: "contactsProfileNotes/notes",
      pending: "contactsProfileNotes/pending",
      hasNextPage: "contactsProfileNotes/hasNextPage",
      currentPage: "contactsProfileNotes/currentPage",
    }),

    profileId() {
      return this.$route.params.profileId;
    },
  },

  methods: {
    create(data) {
      this.$store.dispatch("contactsProfileNotes/createNote", { ...data, contact: this.profileId });
    },

    update(data) {
      this.$store.dispatch("contactsProfileNotes/updateNote", { ...data, contact: this.profileId });
    },

    del(id) {
      this.$store.dispatch("contactsProfileNotes/deleteNote", { id, contact: this.profileId });
    },

    fetchMore() {
      this.$store.dispatch("contactsProfileNotes/fetchMore", {
        page: this.currentPage + 1,
        contact: this.profileId,
      });
    }
  },

  mounted() {
    this.$store.dispatch("contactsProfileNotes/fetch",
      { page: 1, contact: this.profileId, });
  },
};
</script>

<style lang="stylus" scoped>
.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}
</style>
