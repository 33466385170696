<template>
  <div class="tagsPopup">
    <div class="tagsPopupInnerWrap">
      <div class="tagsPopupInner" v-if="tags.length > 0">
        <!-- <input class="tagsPopupInput" type="text" @input="searchTag" /> -->
        <span
          class="tagsPopupItem"
          v-for="tag in tags"
          :key="tag.id"
          :style="{
            backgroundColor: `#${tag.color}`,
            color: `#${tag.text_color}`,
          }"
          @dblclick="dblclick"
          @click="addTag(tag.id, contact.id)"
        >
          {{ tag.name }}
        </span>
      </div>
    </div>
    <div
      :class="[
        'tagsPopupFooter',
        { tagsPopupFooter_bordered: tags.length > 0 },
      ]"
    >
      <button
        class="tagsPopupButton"
        type="button"
        @dblclick="dblclick"
        @click="createTag"
      >
        <Tr>Новый тег</Tr>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactsBodyItemTagsPopup",

  props: {
    contact: {
      type: Object,
      required: true,
    },

    tags: {
      type: Array,
      required: true,
    },
  },

  methods: {
    createTag() {
      this.$modal.show('create-contact-tag', { contact: this.contact });
    },

    addTag(tagId, contactId) {
      this.$emit('addTag', { tagId, contactId });
    },

    dblclick(event) {
      console.info('dblclick');
      event.preventDefault();
    },

    searchTag() {
      console.info('searchTag');
    }
  },
};
</script>

<style lang="stylus" scoped>
.tagsPopup {
  padding: 0 2px;
  width: 140px;
  height: fit-content;
  background-color: #fff;
}

.tagsPopupInnerWrap {
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e7eb;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}

.tagsPopupInner {
  display: grid;
  grid-row-gap: 4px;
  padding: 3px 0 4px;

  @media (min-width: desktop) {
    grid-row-gap: 2px;
  }
}

.tagsPopupInput {
  padding: 2px;
  width: 100%;
  border: 1px solid grey_text_color;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
}

.tagsPopupItem {
  position: relative;
  padding: 2px 3px 4px;
  border-radius: 2px;
  width: fit-content;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

.tagsPopupFooter {
  position: relative;
  display: flex;
  padding: 4px 0 8px;

  &_bordered {
    border-top: 2px solid grey_text_color;

    &::before {
      position: absolute;
      content: '';
      top: -6px;
      right: 0;
      width: 100%;
      height: 4px;
      background-image: linear-gradient(180deg, #FFFFFF00 0%, #fff 100%);
    }
  }
}

.tagsPopupButton {
  padding: 0;
  border: none;
  background-color: unset;
  font-size: 12px;
  line-height: 14px;
  color: secondary_text_color;
  border-bottom: 2px dotted secondary_text_color;
}
</style>
