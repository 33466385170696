<template>
  <main class="main">
    <leadership-layout
      :title="webinarName"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
      :webinarWidth="webinarWidth"
    >
      <div class="autowebinar__body" ref="bodyTrack">
        <autowebinars-upload-block :videoUrl="videoUrl" />
        <!-- <div class="scale">
          <scale-slider @change-scale="changeScale" :value="scale" />
        </div>
        <div class="scrollable">
          <autowebinars-slides
            v-if="slidesList && slidesList.length && videoDuration"
          />
          <autowebinars-messages />
          <no-content
            :noContent="noContent"
            class="autowebinar__noContent"
            v-if="isEmptyContent"
          />
        </div> -->
      </div>
    </leadership-layout>
  </main>
</template>

<script>
// import NoContent from "@/components/NoContent.vue";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import AutowebinarsUploadBlock from "@/components/Autowebinars/AutowebinarsUploadBlock.vue";
// import AutowebinarsSlides from "@/components/Autowebinars/AutowebinarsSlides.vue";
import { mapGetters } from "vuex";
// import ScaleSlider from "@/components/ScaleSlider.vue";
// import AutowebinarsMessages from "@/components/Autowebinars/AutowebinarsMessages.vue";

export default {
  name: "AutowebinarWebinarContainer",
  components: {
    AutowebinarsUploadBlock,
    // NoContent,
    LeadershipLayout,
    // AutowebinarsSlides,
    // ScaleSlider,
    // AutowebinarsMessages,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        { title: "Автовебинары", link: "/leadership/autowebinars" },
      ],
      NAME_MAX_LENGTH: 64,
      noContent: { noContacts: true, text: "Загрузите слайды и видео" },
      webinarWidth: true,
    };
  },
  computed: {
    ...mapGetters({
      webinarName: "autowebinar/name",
      videoDuration: "autowebinar/duration",
      videoUrl: "autowebinar/videoUrl",
      slidesList: "presentation/slides",
      scale: "presentation/scale",
    }),
    pending() {
      return (
        this.$store.getters["autowebinar/pending"] ||
        this.$store.getters["presentation/pending"]
      );
    },
    isEmptyContent() {
      return !this.slidesList.length;
    },
    tabs() {
      const id = this.$route.params.webinarId;

      return [
        {
          title: "Описание",
          link: `/leadership/autowebinars/${id}/description`,
          exact: true,
        },
        {
          title: "Вебинар",
          link: `/leadership/autowebinars/${id}/webinar`,
          exact: true,
        },
      ];
    },
    scale: {
      get() {
        return this.$store.getters["presentation/scale"];
      },
      set(value) {
        this.$store.commit("presentation/CHANGE_SCALE", value);
      },
    },
  },
  methods: {
    changeScale(value) {
      this.$store.commit("presentation/CHANGE_SCALE", value);
    },
  },
  mounted() {
    const videoId = this.$route.params.webinarId;

    const trackWidth = this.$refs.bodyTrack.offsetWidth;

    Promise.all([this.$store.dispatch("autowebinar/fetch", videoId)]).then(
      (response) => {
        this.$store.dispatch("videoEditor/init", {
          width: 400,
          id: response[0].data.response.vimeo_id,
          duration: response[0].data.response.duration,
        });
        const { id } = response[0].data.response.presentation;
        const chat = response[0].data.response.chat;

        this.$store.dispatch("presentation/fetch", { id, trackWidth });
        if (chat && chat.id) {
          this.$store.dispatch("chats/fetch", { id: chat.id });
          this.$store.dispatch("chats/fetchChatMembers", { id: videoId });
        }
      }
    );
  },
};
</script>

<style lang="stylus" scoped>
.autowebinar__noContent {
  margin-top: 64px;
  margin-bottom: 58px;
  width: 187px;
}

.scrollable {
  padding-top: 20px;
  overflow-x: scroll;
  white-space: nowrap;
  // height: 400px;
}

.scale {
  display: flex;
  justify-content: flex-end;
}
</style>
