<template>
  <div class="contactsProfileRemindersLayout">
    <div class="tasksWrap">
      <tasks-item
        v-for="(task, index) in reminders"
        :key="`${task.id}_${index}`"
        :task="task"
        :contactFromContacts="contact"
        :activeTaskTab="activeTaskTab"
      />
    </div>
    <button
      class="showmoreButton"
      type="button"
      @click="fetchMore"
      v-if="hasNextPage"
    >
      <Tr>Показать больше</Tr>
    </button>
  </div>
</template>

<script>
import TasksItem from "@/components/Tasks/TasksItem.vue";

export default {
  name: "ContactsProfileRemindersLayout",

  components: {
    TasksItem,
  },

  props: {
    reminders: {
      type: Array,
      required: true,
    },

    hasNextPage: Boolean,

    contact: {
      type: Object,
      required: true,
    },

    activeTaskTab: {
      type: String,
      required: true,
    },
  },

  methods: {
    fetchMore() {
      this.$emit("fetch-more");
    },
  },
};
</script>

<style lang="stylus" scoped>
.tasksWrap {
  display: grid;
  grid-row-gap: 20px;
}

.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();

  @media (min-width: tablet_large) {
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
