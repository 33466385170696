import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    allData: {},
    tasksTabs: [
      { value: "actual", name: "Актуальные" },
      { value: "expired", name: "Просроченные" },
      { value: "done", name: "Сделанные" },
      { value: "all", name: "Все(Задачи)" },
    ],
    tasks: [],
    allTasks: [],
    contacts: {},
    contactProgresses: [],
    errors: {},
    pendingTasks: false,
    pendingAllTasks: false,
    pendingContacts: false,
    pendingContactProgresses: false,
  }),

  getters: {
    pending(state) {
      return (
        state.pendingTasks ||
        state.pendingAllTasks ||
        state.pendingContacts ||
        state.pendingContactProgresses
      );
    },
    pendingContactProgresses(state) {
      return state.pendingContactProgresses;
    },
    tasksTabs(state) {
      return state.tasksTabs;
    },
    tasks(state) {
      return pathOr([], ["tasks"], state);
    },
    allTasks(state) {
      return pathOr([], ["allTasks"], state);
    },
    contacts(state) {
      return pathOr([], ["contacts", "results"], state);
    },
    contactProgresses(state) {
      return state.contactProgresses;
    },
    hasNextPage(state) {
      return (
        pathOr(1, ["data", "current_page_number"], state) <
        pathOr(1, ["data", "num_pages"], state)
      );
    },
    currentPage(state) {
      return pathOr(1, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.tasks = [...data.results];
    },
    SET_ALl_TASKS(state, data) {
      state.allData = data;
      state.allTasks = [...data.results];
    },
    SET_CONTACTS(state, data) {
      state.contacts = data;
    },
    SET_CONTACT_PROGRESSES(state, data) {
      state.contactProgresses = data;
    },
    SET_MORE_TASKS(state, data) {
      state.data = data;
      state.tasks = [...state.tasks, ...data.results];
    },
    SET_PENDING_TASKS(state, status) {
      state.pendingTasks = status;
    },
    SET_PENDING_ALL_TASKS(state, status) {
      state.pendingAllTasks = status;
    },
    SET_PENDING_CONTACTS(state, status) {
      state.pendingContacts = status;
    },
    SET_PENDING_CONTACT_PROGRESSES(state, status) {
      state.pendingContactProgresses = status;
    },
  },

  actions: {
    async fetchContacts({ commit }) {
      try {
        commit("SET_PENDING_CONTACTS", true);

        const response = await Vue.axios.get(`/contacts/`, {
          params: { page_size: 10000 },
        });

        if (response.status === 200) {
          commit("SET_CONTACTS", response.data);
          commit("SET_PENDING_CONTACTS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_CONTACTS", false);
      }
    },

    async fetchContactProgresses({ commit }) {
      try {
        commit("SET_PENDING_CONTACT_PROGRESSES", true);

        const response = await Vue.axios.get(`/contacts_progress/`);

        if (response.status === 200) {
          commit("SET_CONTACT_PROGRESSES", response.data);
          commit("SET_PENDING_CONTACT_PROGRESSES", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_CONTACT_PROGRESSES", false);
      }
    },

    async fetch({ commit }, { page, filter }) {
      try {
        commit("SET_PENDING_TASKS", true);

        let response;

        if (filter === "all") {
          response = await Vue.axios.get(
            `/user_tasks/?o=-created_at&page=${page}`
          );
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/?page=${page}`);
        }

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING_TASKS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_TASKS", false);
      }
    },

    async fetchAll({ state, commit, dispatch }, { page }) {
      Promise.all([
        dispatch("fetchContacts"),
        dispatch("fetchContactProgresses"),
      ]).then(async () => {
        if (state.pending) {
          return;
        }
        try {
          commit("SET_PENDING_ALL_TASKS", true);

          const response = await Vue.axios.get(
            `/user_tasks/?o=-created_at&page=${page}`
          );

          if (response.status === 200) {
            commit("SET_ALl_TASKS", response.data);
            commit("SET_PENDING_ALL_TASKS", false);
          } else {
            throw response.data.message;
          }
        } catch (e) {
          commit("SET_PENDING_ALL_TASKS", false);
        }
      });
    },

    async fetchOnlyAllTasks({ commit }, { page }) {
      try {
        commit("SET_PENDING_TASKS", true);

        const response = await Vue.axios.get(
          `/user_tasks/?o=-created_at&page=${page}`
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING_TASKS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_TASKS", false);
      }
    },

    async fetchMore({ state, commit }, { page, filter }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING_TASKS", true);

        let response;
        if (filter === "all") {
          response = await Vue.axios.get(
            `/user_tasks/?o=-created_at&page=${page}`
          );
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/?page=${page}`);
        }

        if (response.status === 200) {
          commit("SET_MORE_TASKS", response.data);
          commit("SET_PENDING_TASKS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_TASKS", false);
      }
    },
  },
};
