<template>
  <fieldset
    class="fieldset"
    :style="{ width: inputWidth ? `${inputWidth}px` : '' }"
  >
    <label class="label">
      {{ $t(title) }}
      <input
        :maxlength="maxLength"
        :value="value"
        @input="handleInput"
        class="input"
        type="text"
      />
      <div class="span__wrap" :class="{ withoutCounter: inputWithoutCounter }">
        <span class="span">
          <span :class="{ span_red: valueLength > maxLength }">
            {{ valueLength }}
          </span>
          /{{ maxLength }}</span
        >
      </div>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "LeadershipInputText",
  props: {
    title: String,
    value: String,
    maxLength: Number,
    inputWithoutCounter: Boolean,
    inputWidth: {
      type: Number,
      required: false,
    },
  },
  computed: {
    valueLength() {
      if (!this.value) {
        return 0;
      }
      const val = this.value;
      return val.length;
    },
  },
  methods: {
    handleInput(e) {
      this.$emit("handle-name", e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.fieldset {
  margin-bottom: 12px;
  width: 100%;
  max-width: 400px;

  @media (min-width: tablet_large) {
    width: 400px;
    max-width: unset;
  }
}

.label {
  position: relative;
  display: block;
  font_small();
}

.input {
  display: block;
  margin-top: 4px;
  padding: 5px 4px 6px 7px;
  width: 100%;
  border: 1px solid #e6e7eb;
  font_regular();
}

.span__wrap {
  position: absolute;
  top: 0;
  right: 0;
}

.withoutCounter {
  display: none;
}

.span {
  font_small();
  opacity: 0.6;

  & span {
    font-weight: 700;
  }

  &_red {
    color: #e81526;
  }
}
</style>
