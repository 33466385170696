import { render, staticRenderFns } from "./ContactsProfileLayout.vue?vue&type=template&id=12e228bc&scoped=true&"
import script from "./ContactsProfileLayout.vue?vue&type=script&lang=js&"
export * from "./ContactsProfileLayout.vue?vue&type=script&lang=js&"
import style0 from "./ContactsProfileLayout.vue?vue&type=style&index=0&id=12e228bc&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12e228bc",
  null
  
)

export default component.exports