<template>
  <v-popover offset="0" trigger="hover" placement="top" v-if="actionItem">
    <!-- This will be the popover target (for the events and position) -->
    <span
      class="
        updatedFunnelBodyItem__notificationTrigger
        updatedFunnelBodyItem__cellWebinar
        tooltip-target
        b3
      "
    >
      <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
        <webinar />
      </base-icon-svg>
    </span>

    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="webinarPopover">
        <p class="tooltip-title">
          {{ actionItem.url }}
        </p>
        <p class="tooltip-title">
          {{ actionItem.name }}
        </p>
        <p class="tooltip-title">
          {{ dateFormatted(actionItem.started_at) }}
        </p>
        <template
          v-if="
            actionItem.type !== 'record' && actionItem.progress !== undefined
          "
        >
          <span class="tooltip-time">
            <Tr>Просмотрено:</Tr>
          </span>
          <span class="tooltip-progress">{{ actionItem.progress }}%</span>
        </template>

        <div
          class="webinarKeypoints"
          v-for="keypoint in actionItem.important"
          :key="keypoint.second"
        >
          <div class="tooltip-text">
            <span
              :class="[
                'webinarKeypoints__icon',
                { webinarKeypoints__icon_visited: keypoint.visited },
              ]"
            />
            <span
              :class="[
                'webinarKeypoints__text',
                { webinarKeypoints__text_visited: keypoint.visited },
              ]"
            >
              <Tr>Ключевая точка в</Tr>{{ keypoint.time }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import Webinar from "@/components/SvgIcons/funnel/Webinar.vue";

export default {
  name: "UpdatedFunnelBodyItemWebinar",

  components: {
    Webinar,
  },

  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },

  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.updatedFunnelBodyItem__notificationTrigger {
  padding: 0;
  background: none;
  border: none;
}

.webinarPopover {
  color: main_text_color;
  font_mobile();
  text-align: left;
}

.tooltip-title, .tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
  opacity: 0.6;
}

.webinarKeypoints {
  &__icon {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: grey_text_color;

    &_visited {
      background-color: green_text_color;
    }
  }

  &__text {
    opacity: 0.6;

    &_visited {
      opacity: 1;
    }
  }
}
</style>
