<template>
  <contacts-profile-layout
    :tabs="tabs"
    :activeTab="$route.name"
    :contact="contact"
    :contacts="contacts"
    :contactsPending="pendingContacts"
    @delContact="delContact"
  >
    <router-view></router-view>
  </contacts-profile-layout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { runNotice } from "@/utils/notifications";

import ContactsProfileLayout from "@/components/Contacts/ContactsProfileLayout.vue";

export default {
  name: "ContatcsProfile",
  components: {
    ContactsProfileLayout,
  },
  data() {
    return {
      tabs: [
        {
          name: "ContactsProfileInfo",
          title: "Инфо",
          link: `/contacts/${this.$route.params.profileId}/info`,
        },
        {
          name: "ContactsProfileStatus",
          title: "Статус",
          link: `/contacts/${this.$route.params.profileId}/status`,
        },
        {
          name: "ContactsProfileNotes",
          title: "Заметки",
          link: `/contacts/${this.$route.params.profileId}/notes`,
        },
        {
          name: "ContactsProfileReminders",
          title: "Напоминания",
          link: `/contacts/${this.$route.params.profileId}/reminders`,
        },
        {
          name: "ContactsProfileMeetings",
          title: "Встречи",
          link: `/contacts/${this.$route.params.profileId}/meetings`,
        },
        {
          name: "ContactsProfileEvents",
          title: "События",
          link: `/contacts/${this.$route.params.profileId}/events`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      contact: "contactsProfile/contact",
      currentLang: "currentLang",
      contacts: "leadTasks/contacts",
    }),
    ...mapState('leadTasks', ['pendingContacts'])
  },

  methods: {
    delContact() {
      this.$modal.show("dialog", {
        title: this.$t("Внимание!"),
        text: this.$t("Вы уверены?"),
        buttons: [
          {
            title: this.$t("Отмена"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Удалить"),
            handler: () => {
              this.$store.dispatch("contactsProfile/deleteContact", this.contact.id)
                .then(response => {
                  if (response && response.status === 204) {
                    this.$router.push({ path: `/${this.currentLang}/contacts/` });
                    this.$store.commit("contacts/SET_DELETED_CONTACTS_COUNTER");
                    this.$store.dispatch("user/fetch");
                    runNotice("success", this.$t("Контакт удален"));
                  } else {
                    runNotice("error", this.$t("Что-то пошло не так"));
                  }
                })
                .catch(e => { console.log(e) });
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },

    changeTagsFilterValue(value) {
      this.$store.commit("contacts/SET_TAGS_FILTER_VALUE", value);
    },

    changeTagsFilterCounter(value) {
      this.$store.commit("contacts/SET_TAGS_FILTER_COUNTER", value);
    },
  },

  mounted() {
    this.$store.dispatch("leadTasks/fetchContacts")
      .then(() => {
        this.$store.dispatch("contactsProfile/fetch", this.$route.params.profileId)
          .then(() => {
            this.$store.dispatch("leadTasks/fetchContactProgresses");
            this.$store.dispatch("contacts/fetchContactHeat");
            this.$store.dispatch("contacts/fetchContactTop");
            this.$store.dispatch("contactsTags/fetch");
          })
          .catch(e => console.log(e));
      })
      .catch(e => console.log(e));
  },
};
</script>

<style lang="stylus" scoped></style>
