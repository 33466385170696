<template>
  <div>
    <span class="tableItem__time">
      {{ dateFormatted(actionItem.created_at) }}
    </span>
    <div class="tableItem__box">
      <div class="tableItem__title">
        <base-icon-svg
          width="20"
          height="24"
          viewbox="0 0 20 24"
          class="tableItem__icon"
        >
          <order v-if="actionItem.status === 'approved'" />
          <order v-if="actionItem.status === 'success'" />
          <order
            class="orderInProcess"
            v-if="actionItem.status === 'processing'"
          />
          <order
            class="orderInProcess"
            v-if="actionItem.status === 'created'"
          />
          <order-failed v-if="actionItem.status === 'expired'" />
          <order-failed v-if="actionItem.status === 'declined'" />
          <order-failed v-if="actionItem.status === 'reversed'" />
        </base-icon-svg>
        <span>
          <Tr v-if="actionItem.status === 'approved'"
            >Заказ успешно совершен</Tr
          >
          <Tr v-if="actionItem.status === 'success'">Заказ успешно совершен</Tr>
          <Tr v-if="actionItem.status === 'processing'"
            >Заказ в процессе обработки</Tr
          >
          <Tr v-if="actionItem.status === 'created'">Заказ создан</Tr>
          <Tr v-if="actionItem.status === 'expired'"
            >Время жизни заказа истекло</Tr
          >
          <Tr v-if="actionItem.status === 'declined'">Заказ отклонен</Tr>
          <Tr v-if="actionItem.status === 'reversed'">Заказ отменен</Tr>
          <!-- {{ webinar.url }} -->
        </span>
      </div>
      <div class="tableItem__text" v-if="actionItem.desc">
        {{ actionItem.desc }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Order from '@/components/SvgIcons/funnel/modal/Order.vue';
import OrderFailed from '@/components/SvgIcons/funnel/modal/OrderFailed.vue';

export default {
  name: "UpdatedFunnelModalLeadOrder",
  components: {
    Order,
    OrderFailed,
  },
  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },
  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__time {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: relative;
    left: -2px;
    margin-right: 3px;
    transform: scale(1.3);
  }

  &__title {
    position: relative;
    top: -4px;
    display: flex;

    @media (min-width: tablet) {
      align-items: center;
    }

    &>span {
      margin-right: 5px;
      opacity: 0.6;
    }
  }

  &__text {
    margin-left: 25px;
  }
}

.updatedFunnelBodyItem__notificationIcon {
  color: #3489f8;

  &_contact {
    color: main_text_color;
  }
}

.tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 7px;
}

.webinarKeypoints {
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  &__icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: grey_text_color;

    &_visited {
      background-color: green_text_color;
    }
  }

  &__text {
    font_small();
    opacity: 0.6;

    &_visited {
      opacity: 1;
    }
  }
}

.orderInProcess {
  opacity: 0.6;
}
</style>
