<template>
  <fieldset class="contactsFilter__fieldset">
    <legend class="contactsFilter__legend"><Tr>Прогресс</Tr></legend>
    <div class="contactsFilter__fieldsetBox">
      <label
        class="contactsFilter__label"
        v-for="progressItem in progresses"
        :key="progressItem.param"
      >
        <contacts-progress
          class="contactsFilter__progress"
          :param="progressItem.param"
        />
        <input
          class="contactsFilter__inputCheckbox"
          name="progress"
          type="radio"
          :checked="isFilterChecked('progress', progressItem.param)"
          :value="progressItem.param"
          @change="change"
        />
        <span
          :class="[
            'contactsFilter__fakeCheckbox',
            {
              contactsFilter__fakeCheckbox_checked: isFilterChecked(
                'progress',
                progressItem.param
              ),
            },
          ]"
        />
        <span class="contactsFilter__labelText">
          {{ progressItem.verbose }}
        </span>
      </label>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from 'vuex';

import ContactsProgress from "@/components/Contacts/ContactsProgress";

export default {
  name: "ContactsFilterBodyProgress",
  components: {
    ContactsProgress,
  },
  data() {
    return {
      checkboxName: "contactsFilterProgress",
    };
  },

  computed: {
    ...mapGetters({
      progresses: "leadTasks/contactProgresses",
    }),
  },

  props: {
    isFilterChecked: {
      type: Function,
    },
  },

  methods: {
    change(e) {
      this.$emit("change", {
        name: e.target.name,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsFilter__fieldset {
  position: relative;
  width: 100%;
  max-width: 290px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.contactsFilter__legend {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.contactsFilter__fieldsetBox {
  display: inline-grid;
  grid-row-gap: 10px;
  width: 100%;
}

.contactsFilter__label {
  position: relative;
  padding-bottom: 15px;
  padding-left: 23px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 31px;
  cursor: pointer;
}

.contactsFilter__fakeCheckbox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
  border-radius: 50%;
}

.contactsFilter__fakeCheckbox_checked {
  background-color: main_text_color;
  box-shadow: inset 0px 0px 0px 2px #fff;
}

.contactsFilter__inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.contactsFilter__progress {
  margin-right: 8px;
}

.contactsFilter__labelText {
  position: absolute;
  bottom: 0;
  left: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

@media (min-width: tablet_large) {
  .contactsFilter__fieldset {
    width: auto;
    max-width: 500px;
  }

  .contactsFilter__fieldsetBox {
    grid-row-gap: 6px;
    width: auto;
  }

  .contactsFilter__label {
    padding-bottom: 0;
    width: fit-content;
    height: 16px;
  }

  .contactsFilter__labelText {
    position: static;
    font-size: 14px;
    line-height: 16px;
  }
}
</style>
