<template>
  <div class="updatedFunnelBodyItem">
    <slot name="checkbox" />

    <!-- AVATAR -->
    <div
      :class="[
        'updatedFunnelBodyItem__cell',
        'updatedFunnelBodyItem__cellAvatar',
        'updatedFunnelBodyItem__cellAvatar_empty',
        { updatedFunnelBodyItem__cellAvatar_assignment: assignment },
      ]"
      :style="randomColor"
      @click="openModal"
    >
      {{ lead.trigger.name | getInitialsFilter }}
    </div>
    <!-- AVATAR -->

    <!-- NAME -->
    <div
      class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellName"
      @click="openModal"
    >
      <span v-if="lead.trigger.name || lead.id" v-tooltip="popupName">
        {{ lead.id }} {{ lead.trigger.name }}
      </span>
      <span v-else>-</span>
    </div>
    <!-- NAME -->

    <slot name="manager" />
    <slot name="status" />

    <!-- SOURCE -->
    <updated-funnel-body-item-source
      class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellSource"
      :sourceHide="sourceHide"
      :lead="lead"
      @show-source="$emit('showSource')"
      @show-lead="openModal"
    />
    <!-- SOURCE -->

    <slot name="managerIcon" />

    <!-- ACTIONS -->
    <div class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellActions">
      <!-- TRIGGER -->
      <updated-funnel-body-item-trigger
        class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellTrigger"
        v-if="lead.trigger"
        :trigger="lead.trigger"
      />
      <!-- TRIGGER -->

      <div
        class="updatedFunnelBodyItem__pipelineWrap"
        :id="`pipeline_${lead.id}`"
      >
        <div v-show="pipelineIsFull">
          <button
            v-show="pipelinePos > 0"
            @mouseover="pipelineScroll('prev')"
            @mouseleave="pipelineScroll('stopPrev')"
            class="
              updatedFunnelBodyItem__pipelineButton
              updatedFunnelBodyItem__pipelineButton_prev
            "
          />
          <div class="updatedFunnelBodyItem__pipelineInner" @click="openModal">
            <div
              class="updatedFunnelBodyItem__pipeline"
              :style="`transform: translateX(-${pipelinePos}px)`"
            >
              <div
                v-for="pipelineItem in lead.pipeline"
                :key="`${pipelineItem.array}+${pipelineItem.value}`"
                :class="[
                  'updatedFunnelBodyItem__cell',
                  {
                    updatedFunnelBodyItem__cell_chatbot:
                      pipelineItem.array === 'chatbot',
                  },
                ]"
              >
                <component
                  :is="actionComponent(pipelineItem.array)"
                  :actionItem="findEl(pipelineItem.value, pipelineItem.array)"
                />
              </div>
            </div>
          </div>
          <button
            v-show="
              pipelineInnerWidth > pipelineWidth &&
              pipelinePos + pipelineWidth < pipelineInnerWidth &&
              pipelineIsFull
            "
            @mouseover="pipelineScroll('next')"
            @mouseleave="pipelineScroll('stopNext')"
            class="
              updatedFunnelBodyItem__pipelineButton
              updatedFunnelBodyItem__pipelineButton_next
            "
          />
        </div>
        <div
          class="horizontal-wrap"
          v-show="!pipelineIsFull"
          @mousedown.left="onMouseDown"
        >
          <vue-horizontal
            class="horizontal"
            :button="false"
            ref="horizontal"
            snap="none"
            @scroll="onScroll"
          >
            <updated-funnel-pipeline-short
              class="
                updatedFunnelBodyItem__pipeline
                updatedFunnelBodyItem__pipeline_short
              "
              :lead="lead"
            />
          </vue-horizontal>
        </div>
      </div>
    </div>
    <!-- ACTIONS -->

    <div
      class="updatedFunnelBodyItem__cellTagsWrap"
      @mousedown.left="onTagsMouseDown"
    >
      <vue-horizontal
        class="horizontal"
        :button="false"
        ref="tagsHorizontal"
        snap="none"
        @scroll="onScroll"
      >
        <div class="updatedFunnelBodyItem__cellTags">
          <div
            class="updatedFunnelBodyItem__cell"
            v-for="tag in lead.tags"
            :key="tag.id"
          >
            <div
              class="
                updatedFunnelBodyItem__cellTag
                updatedFunnelBodyItem__cellTag_desktop
              "
              v-tooltip="{ trigger: 'hover', content: tagName(tag.id) }"
            >
              {{ shortcutTagName(tagName(tag.id)) }}
            </div>
            <div
              class="
                updatedFunnelBodyItem__cellTag
                updatedFunnelBodyItem__cellTag_mobile
              "
              v-tooltip="{ trigger: 'click', content: tagName(tag.id) }"
            >
              {{ shortcutTagName(tagName(tag.id)) }}
            </div>
          </div>
        </div>
      </vue-horizontal>
    </div>

    <slot name="calls" />
    <slot name="createLinks" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import getInitials from "@/utils/getInitials";

import UpdatedFunnelBodyItemSource from "@/components/Funnel/UpdatedFunnelBodyItemSource.vue";
import UpdatedFunnelBodyItemTrigger from "@/components/Funnel/UpdatedFunnelBodyItemTrigger.vue";
import UpdatedFunnelBodyItemNotification from "@/components/Funnel/UpdatedFunnelBodyItemNotification.vue";
import UpdatedFunnelBodyItemTriggers from "@/components/Funnel/UpdatedFunnelBodyItemTriggers.vue";
import UpdatedFunnelBodyItemOrders from "@/components/Funnel/UpdatedFunnelBodyItemOrders.vue";
import UpdatedFunnelBodyItemWebinar from "@/components/Funnel/UpdatedFunnelBodyItemWebinar.vue";
import UpdatedFunnelBodyItemChatbot from "@/components/Funnel/UpdatedFunnelBodyItemChatbot.vue";
import UpdatedFunnelPipelineShort from "@/components/Funnel/UpdatedFunnelPipelineShort.vue";

import VueHorizontal from "vue-horizontal";

export default {
  name: "UpdatedFunnelBodyItem",

  components: {
    UpdatedFunnelBodyItemSource,
    UpdatedFunnelBodyItemTrigger,
    UpdatedFunnelBodyItemNotification,
    UpdatedFunnelBodyItemTriggers,
    UpdatedFunnelBodyItemOrders,
    UpdatedFunnelBodyItemWebinar,
    UpdatedFunnelBodyItemChatbot,
    UpdatedFunnelPipelineShort,
    VueHorizontal,
  },

  props: {
    lead: {
      type: Object,
      required: true,
    },
    sourceHide: {
      type: Boolean,
    },
    pipelineIsFull: Boolean,
    assignment: Boolean,
  },

  data() {
    return {
      pipelineSize: 23,
      pipelinePos: 0,
      scrollSpeed: 150,
      pipelineWidth: null,
      left: 0,
      originX: 0,
      originLeft: 0,
      tagsLeft: 0,
      tagsOriginX: 0,
      tagsOriginLeft: 0,
      prevInterval: null,
      nextInterval: null,
    };
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      tags: "updatedTags/tagsArray",
    }),

    randomColor() {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }
      const str = `background: linear-gradient(#fff -125%, rgb(${getRandomInt(
        150,
        240
      )}, ${getRandomInt(150, 240)}, ${getRandomInt(150, 240)}))`;
      return str;
    },

    popupName() {
      return {
        content: this.lead.trigger.name,
        classes: ["funnel-tooltip"],
      };
    },

    pipelineInnerWidth() {
      return this.lead.pipeline.length * 23;
    },
  },

  methods: {
    openModal() {
      if (this.$route.name === "UpdatedLeads") {
        const routeUrl = this.$router.resolve({
          path: `leads/${this.lead.id}`,
        });
        window.open(routeUrl.href, "_blank");
      } else {
        this.$modal.show("updated-funnel-lead", { lead: this.lead });
      }
    },

    shortcutTagName(tagName) {
      return getInitials(tagName, 3);
    },

    actionComponent(arrayName) {
      const obj = {
        notifications: "UpdatedFunnelBodyItemNotification",
        triggers: "UpdatedFunnelBodyItemTriggers",
        orders: "UpdatedFunnelBodyItemOrders",
        webinars: "UpdatedFunnelBodyItemWebinar",
        chatbot: "UpdatedFunnelBodyItemChatbot",
      };
      return obj[arrayName];
    },

    findEl(id, array) {
      return this.lead[array].find(el => el.id === id);
    },

    pipelineScroll(dest) {
      if (dest === "prev") {
        this.pipelinePos -= this.pipelineSize;
        if (!this.prevInterval) {
          this.prevInterval = setInterval(() => {
            this.pipelinePos -= this.pipelineSize;
          }, this.scrollSpeed);
        }
      } else if (dest === "next") {
        this.pipelinePos += this.pipelineSize;
        if (!this.nextInterval) {
          this.nextInterval = setInterval(() => {
            this.pipelinePos += this.pipelineSize;
          }, this.scrollSpeed);
        }
      } else if (dest === "stopPrev") {
        clearInterval(this.prevInterval);
        this.prevInterval = null;
      } else if (dest === "stopNext") {
        clearInterval(this.nextInterval);
        this.nextInterval = null;
      }
    },

    onScroll({ left }) {
      this.left = left;
    },
    onMouseDown(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;
      this.$refs.horizontal.scrollToLeft(left, "auto");
    },

    // ------------------------

    onTagsScroll({ left }) {
      this.tagsLeft = left;
    },
    onTagsMouseDown(e) {
      this.tagsOriginX = e.pageX;
      this.tagsOriginLeft = this.tagsLeft;

      window.addEventListener("mouseup", this.onTagsMouseUp);
      window.addEventListener("mousemove", this.onTagsMouseMove);
    },
    onTagsMouseUp() {
      window.removeEventListener("mouseup", this.onTagsMouseUp);
      window.removeEventListener("mousemove", this.onTagsMouseMove);
    },
    onTagsMouseMove(e) {
      const offset = e.pageX - this.tagsOriginX;
      const left = this.tagsOriginLeft - offset;
      this.$refs.tagsHorizontal.scrollToLeft(left, "auto");
    },

    tagName(id) {
      return this.tags.find(el => el.id === id).name;
    },
  },

  mounted() {
    if (this.lead) {
      this.pipelineWidth = document.querySelector(`#pipeline_${this.lead.id}`).offsetWidth;
    }
  },

  destroyed() {
    this.onMouseUp();
  },
};
</script>

<style lang="stylus" scoped>
scrollAnimationSpeed = 0.6s;

.updatedFunnelBodyItem {
  position: relative;
  display: grid;
  grid-row-gap: 4px;
  margin-bottom: 18px;
  cursor: pointer;

  @media (min-width: desktop) {
    &:hover {
      background-color: grey_text_color;

      .updatedFunnelBodyItem__cellName, .updatedFunnelBodyItem__cellManager, .leadsTableItem__cellStatus, .updatedFunnelBodyItem__cellTagsWrap {
        &::after {
          background-image: linear-gradient(90deg, #FFFFFF00 0%, grey_text_color 100%);
        }
      }

      .checkbox__fakeCheckBox {
        border-color: #fff;
      }

      .leadsTableItem__linksBtn {
        display: flex;
      }

      .horizontal-wrap {
        &::after {
          background-image: linear-gradient(90deg, #FFFFFF00 0%, grey_text_color 100%);
        }
      }
    }
  }
}

.updatedFunnelBodyItem__cell {
  align-items: center;
  height: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: main_text_color;

  @media (min-width: desktop) {
    height: 24px;
    font-size: 14px;
    line-height: 18px;
  }
}

.updatedFunnelBodyItem__cellAvatar {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  overflow: hidden;
  grid-area: avatar;

  &_empty {
    display: flex;
    align-items: center;
    justify-content: center;
    font(main_text_color, 10px, 700, 14px);
  }

  &_assignment {
    @media (min-width: desktop) {
      margin: 0 0 0 -22px;
    }
  }

  @media (min-width: desktop) {
    grid-area: unset;
    width: 24px;
    height: 24px;
  }
}

.updatedFunnelBodyItem__cellName {
  position: relative;
  grid-area: name;
  display: flex;
  height: 20px;
  font-weight: 700;
  cell_fade();

  & span {
    overflow: hidden;
    white-space: nowrap;

    & span {
      display: inline;
    }
  }

  @media (min-width: desktop) {
    grid-area: unset;
    height: 24px;
  }
}

.updatedFunnelBodyItem__cellSource {
  grid-area: source;

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.updatedFunnelBodyItem__cellTrigger {
  height: 24px;
}

.updatedFunnelBodyItem__cellActions {
  display: grid;
  grid-area: actions;
  grid-template-columns: 20px auto;
  grid-gap: 3px;
  align-items: unset;
  height: auto;

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.updatedFunnelBodyItem__pipelineWrap {
  position: relative;
  overflow: hidden;
}

.updatedFunnelBodyItem__pipeline {
  transition: scrollAnimationSpeed;
}

.updatedFunnelBodyItem__pipelineButton {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0;
  background-color: #fff;
  border: none;
  width: 24px;
  height: 24px;
  border: 1px solid #fff;
  cell_fade();

  &:hover {
    border: 1px solid #C948FF;
  }

  &::before {
    position: relative;
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    border-right: 2px solid main_text_color;
    border-bottom: 2px solid main_text_color;
  }

  &::after {
    top: -1px;
    height: 24px;
  }

  &_prev {
    cursor: w-resize;
    left: 0;

    &::before {
      left: 2px;
      transform: rotate(135deg);
    }

    &::after {
      right: -9px;
      width: 8px;
      background-image: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF00 100%);
    }
  }

  &_next {
    cursor: e-resize;
    right: 0;

    &::before {
      right: 2px;
      transform: rotate(-45deg);
    }

    &::after {
      right: unset;
      left: -9px;
      width: 8px;
    }
  }
}

.updatedFunnelBodyItem__pipeline {
  display: grid;
  grid-template-columns: repeat(auto-fill, 20px);
  grid-auto-flow: column;
  grid-gap: 3px;

  &_short {
    grid-template-columns: unset;
    width: fit-content;
  }

  .updatedFunnelBodyItem__cell {
    height: 24px;
  }
}

.updatedFunnelBodyItemBox {
  position: relative;
  height: 20px;
  overflow: hidden;

  @media (min-width: desktop) {
    height: 24px;
  }
}

.updatedFunnelBodyItem__cell_chatbot {
  &:not(:first-of-type) {
    top: 0;
    left: 0;
  }
}

.updatedFunnelBodyItem__cellTagsWrap {
  position: relative;
  grid-area: tags;
  cell_fade();

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.updatedFunnelBodyItem__cellTags {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  width: fit-content;
}

.updatedFunnelBodyItem__cellTag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  min-width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: main_text_color;
  font_mobile();
  color: #fff;
  text-transform: uppercase;

  &_desktop {
    display: none;

    @media (min-width: desktop) {
      display: flex;
    }
  }

  &_mobile {
    @media (min-width: desktop) {
      display: none;
    }
  }

  @media (min-width: desktop) {
    height: 24px;
  }
}

@media (min-width: 425px) {
  .updatedFunnelBodyItem {
    margin-right: 30px;
  }
}

@media (min-width: desktop) {
  .updatedFunnelBodyItem {
    margin-right: 0;
    margin-bottom: 8px;
  }

  .updatedFunnelBodyItem__cellName {
    font-weight: 400;

    & span {
      height: auto;
      line-height: 18px;
    }
  }
}

.horizontal {
  user-select: none;
}

.horizontal-wrap {
  cell_fade();
}
</style>
