import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { pathOr } from "ramda";
import staticFilters from "@/utils/storesUtils/leadsFilters";
import qs from "qs";
import Vue from "vue";

const transformLinksResponse = (links: any) => {
  return links.map(link => ({
    ...link,
    value: link.id,
    // name: link.utm_campaign,
  }));
};
const transformFiltersResponse = (products: any) => {
  const result = {
    landings: [],
    funnels: [],
    products: [],
  };

  const productsMapped = products.map(product => {
    const landings = product.landings.map(landing => ({
      ...landing,
      name: `${landing.name} (${product.name})`,
      value: landing.id,
    }));
    const funnels = product.funnels.map(funnel => ({
      ...funnel,
      name: `${funnel.name} (${product.name})`,
      value: funnel.id,
    }));
    result.landings = result.landings.concat(landings);
    result.funnels = result.funnels.concat(funnels);

    return { ...product, value: product.id };
  });
  result.products = productsMapped;
  return result;
};

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
    pendingLeads: false,
    filters: {},
    staticFilters: staticFilters,
    dynamicFilters: {},
    filtersIsActive: false,
    isPickedAllLinks: false,
    isPickedAllProducts: false,
    isPickedAllFunnels: false,
    isPickedAllLandings: false,
    linksFilterValue: "",
    linksFilterCounter: "",
    productsFilterValue: "",
    productsFilterCounter: "",
    funnelsFilterValue: "",
    funnelsFilterCounter: "",
    landingsFilterValue: "",
    landingsFilterCounter: "",
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    pendingLeads(state) {
      return state.pendingLeads;
    },
    leadsList(state) {
      return pathOr([], ["data", "data"], state);
    },
    linksList(state) {
      return pathOr([], ["dynamicFilters", "links"], state);
    },
    currentPage(state) {
      return pathOr(0, ["data", "pagination", "current_page"], state);
    },
    hasNextPage(state) {
      const currentPage = pathOr(
        0,
        ["data", "pagination", "current_page"],
        state
      );
      const lastPage = pathOr(0, ["data", "pagination", "last_page"], state);
      return currentPage < lastPage;
    },
    staticFilters(state) {
      return state.staticFilters;
    },
    dynamicFilters(state) {
      return state.dynamicFilters;
    },
    filters(state) {
      return state.filters;
    },
    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },
    filtersIsActive(state) {
      return state.filtersIsActive;
    },
    total(state) {
      return pathOr(0, ["data", "pagination", "total"], state);
    },
    pickedAllLinks(state) {
      return state.isPickedAllLinks;
    },
    pickedAllProducts(state) {
      return state.isPickedAllProducts;
    },
    pickedAllFunnels(state) {
      return state.isPickedAllFunnels;
    },
    pickedAllLandings(state) {
      return state.isPickedAllLandings;
    },
    linksFilterValue(state) {
      return state.linksFilterValue;
    },
    linksFilterCounter(state) {
      return state.linksFilterCounter;
    },
    productsFilterValue(state) {
      return state.productsFilterValue;
    },
    productsFilterCounter(state) {
      return state.productsFilterCounter;
    },
    funnelsFilterValue(state) {
      return state.funnelsFilterValue;
    },
    funnelsFilterCounter(state) {
      return state.funnelsFilterCounter;
    },
    landingsFilterValue(state) {
      return state.landingsFilterValue;
    },
    landingsFilterCounter(state) {
      return state.landingsFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = {
        data:
          data.pagination.current_page === 1
            ? data.data
            : state.data.data.concat(data.data),
        pagination: data.pagination,
      };
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_PENDING_LEADS(state, status) {
      state.pendingLeads = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    CHANGE_FILTER(state, { name, value }) {
      if (value instanceof Array) {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }
      if (state.filters[name]) {
        if (state.filters[name].includes(value)) {
          state.filters = {
            ...state.filters,
            [name]: state.filters[name].filter(el => el !== value),
          };
        } else {
          if (name === "bot") {
            state.filters = {
              ...state.filters,
              [name]: [value],
            };
          } else {
            state.filters = {
              ...state.filters,
              [name]: [...state.filters[name], value],
            };
          }
        }
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },
    SET_PICKED_ALL_LINKS(state, value) {
      state.isPickedAllLinks = !state.isPickedAllLinks;

      state.isPickedAllLinks
        ? (state.filters.link_id = [...value])
        : (state.filters.link_id = []);
    },
    SET_PICKED_ALL_PRODUCTS(state, value) {
      state.isPickedAllProducts = !state.isPickedAllProducts;

      state.isPickedAllProducts
        ? (state.filters.product_id = [...value])
        : (state.filters.product_id = []);
    },
    SET_PICKED_ALL_FUNNELS(state, value) {
      state.isPickedAllFunnels = !state.isPickedAllFunnels;

      state.isPickedAllFunnels
        ? (state.filters.funnel_id = [...value])
        : (state.filters.funnel_id = []);
    },
    SET_PICKED_ALL_LANDINGS(state, value) {
      state.isPickedAllLandings = !state.isPickedAllLandings;

      state.isPickedAllLandings
        ? (state.filters.landing_id = [...value])
        : (state.filters.landing_id = []);
    },
    SET_DYNAMIC_FILTERS(state, products) {
      state.dynamicFilters = {
        ...state.dynamicFilters,
        ...transformFiltersResponse(products),
      };
    },
    SET_LINKS(state, links) {
      state.dynamicFilters = {
        ...state.dynamicFilters,
        links: state.dynamicFilters.links
          ? state.dynamicFilters.links.concat(transformLinksResponse(links))
          : transformLinksResponse(links),
      };
    },
    TOGGLE_FILTERS(state) {
      state.filtersIsActive = !state.filtersIsActive;
    },
    CLEAR_FILTERS(state) {
      state.filters = {};
    },
    SET_LINKS_FILTER_VALUE(state, value) {
      state.linksFilterValue = value;
    },
    SET_LINKS_FILTER_COUNTER(state, value) {
      state.linksFilterCounter = value;
    },
    SET_PRODUCTS_FILTER_VALUE(state, value) {
      state.productsFilterValue = value;
    },
    SET_PRODUCTS_FILTER_COUNTER(state, value) {
      state.productsFilterCounter = value;
    },
    SET_FUNNELS_FILTER_VALUE(state, value) {
      state.funnelsFilterValue = value;
    },
    SET_FUNNELS_FILTER_COUNTER(state, value) {
      state.funnelsFilterCounter = value;
    },
    SET_LANDINGS_FILTER_VALUE(state, value) {
      state.landingsFilterValue = value;
    },
    SET_LANDINGS_FILTER_COUNTER(state, value) {
      state.landingsFilterCounter = value;
    },
  },

  actions: {
    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },

    toggleFilter({ commit }) {
      return new Promise((resolve: any) => {
        commit("TOGGLE_FILTERS");
        resolve();
      });
    },
    pickAllLinks({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_LINKS", payload);
        resolve();
      });
    },
    pickAllProducts({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_PRODUCTS", payload);
        resolve();
      });
    },
    pickAllFunnels({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_FUNNELS", payload);
        resolve();
      });
    },
    pickAllLandings({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_LANDINGS", payload);
        resolve();
      });
    },
    async fetchFilters({ commit, rootGetters, state }) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/products`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DYNAMIC_FILTERS", response.data.response);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async fetchLinks({ commit, rootGetters, state, dispatch }, page?) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/links/?page=${page ? page : 1}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_LINKS", response.data.response.data);

          commit("SET_PENDING", false);

          if (
            response.data.response.pagination.current_page <
            response.data.response.pagination.last_page
          ) {
            dispatch(
              "fetchLinks",
              response.data.response.pagination.current_page + 1
            );
          }
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async fetch({ commit, rootGetters, state, dispatch }, { page }) {
      const filters = qs.stringify(
        { ...state.filters, ...(page ? { page } : []) },
        { arrayFormat: "comma" }
      );
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING_LEADS", true);

        const response = await axios.get(
          `/leads?${filters}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING_LEADS", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING_LEADS", false);
      }
    },
  },
};
