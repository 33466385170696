<template>
  <div class="leadsTable">
    <updated-funnel-body-head
      :class="[
        'leadsTableBox',
        'leadsTableBoxHead',
        { leadsTableBox_sourceHide: sourceHide },
      ]"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      @hideSource="hideSource"
      @getPipelineFull="getPipelineFull"
      @getPipelineShort="getPipelineShort"
    >
      <template slot="status">
        <div class="leadsTableHead__cell leadsTableHead__cellStatus">
          <Tr class="leadsTableHead__cellText">Статус</Tr>
        </div>
      </template>
      <template slot="managerIcon">
        <div class="leadsTableHead__cell leadsTableHead__cellManager" />
      </template>
      <template slot="createLinks"><div /></template>
    </updated-funnel-body-head>

    <updated-funnel-body-item
      v-for="lead in leads"
      :key="lead.id"
      :class="[
        'leadsTableBox',
        'leadsTableBox_item',
        { leadsTableBox_sourceHide: sourceHide },
      ]"
      :lead="lead"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      @showSource="showSource"
      @click="openModal(lead)"
    >
      <template slot="status">
        <div
          class="leadsTableItem__cell leadsTableItem__cellStatus"
          v-tooltip="status(lead)"
        >
          <span class="updatedFunnelBodyItem__cell_mobile">
            <Tr>Статус</Tr>:
          </span>
          <span>{{ status(lead) }}</span>
        </div>
      </template>
      <template slot="managerIcon">
        <div class="leadsTableItem__cell leadsTableItem__cellManager">
          <v-popover
            offset="0"
            trigger="hover"
            placement="top"
            v-if="hasManager(lead)"
          >
            <span
              :class="[
                'managerIcon',
                'managerIcon_desktop',
                { managerIcon_active: hasManager(lead) },
              ]"
            >
              <base-icon-svg width="24" height="24" viewbox="0 0 24 24">
                <manager-icon />
              </base-icon-svg>
            </span>
            <template slot="popover" v-if="hasManager(lead)">
              <div class="notificationPopover">
                <div class="tooltip-title">
                  <Tr>Менеджер</Tr>
                </div>
                <span class="tooltip-text">
                  {{ managerName(lead) }}
                </span>
              </div>
            </template>
          </v-popover>
          <span
            v-else
            :class="[
              'managerIcon',
              'managerIcon_desktop',
              { managerIcon_active: hasManager(lead) },
            ]"
          >
            <base-icon-svg width="24" height="24" viewbox="0 0 24 24">
              <manager-icon />
            </base-icon-svg>
          </span>
          <v-popover
            offset="0"
            trigger="click"
            placement="top"
            v-if="hasManager(lead)"
          >
            <span
              :class="[
                'managerIcon',
                'managerIcon_mobile',
                { managerIcon_active: hasManager(lead) },
              ]"
            >
              <base-icon-svg width="24" height="24" viewbox="0 0 24 24">
                <manager-icon />
              </base-icon-svg>
            </span>
            <template slot="popover">
              <div class="notificationPopover">
                <div class="tooltip-title">
                  <Tr>Менеджер</Tr>
                </div>
                <span class="tooltip-text">
                  {{ managerName(lead) }}
                </span>
              </div>
            </template>
          </v-popover>
          <span
            v-else
            :class="[
              'managerIcon',
              'managerIcon_mobile',
              { managerIcon_active: hasManager(lead) },
            ]"
          >
            <base-icon-svg width="24" height="24" viewbox="0 0 24 24">
              <manager-icon />
            </base-icon-svg>
          </span>
        </div>
      </template>

      <!-- CALLS -->
      <template slot="calls">
        <div>
          <button
            class="
              updatedFunnelBodyItem__cell updatedFunnelBodyItem__callButton
            "
            @click="openCallToLeadModal(lead)"
            v-if="isUserOP && phoneStartsWithPlus(lead)"
          >
            <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
              <leads-phone-icon />
            </base-icon-svg>
          </button>
        </div>
      </template>
      <!-- CALLS -->

      <template slot="createLinks">
        <button
          class="
            updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellActionsButton
          "
          type="button"
          @click.prevent="openCreateTaskModalByLead(lead)"
          v-tooltip="{
            content: $t('Создать задачу'),
            classes: 'leadsCreateTaskTooltip',
          }"
        >
          <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
            <leads-create-task-icon />
          </base-icon-svg>
          <div class="updatedFunnelBodyItem__cellActionsText">
            <Tr>Создать задачу</Tr>
          </div>
        </button>
      </template>
    </updated-funnel-body-item>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

import UpdatedFunnelBodyHead from "@/components/Funnel/UpdatedFunnelBodyHead.vue";
import UpdatedFunnelBodyItem from "@/components/Funnel/UpdatedFunnelBodyItem.vue";
import LeadsCreateTaskIcon from "@/components/SvgIcons/LeadsCreateTaskIcon.vue";
import ManagerIcon from "@/components/SvgIcons/funnel/ManagerIcon.vue";
import LeadsPhoneIcon from "@/components/SvgIcons/LeadsPhoneIcon.vue";

export default {
  name: "UpdatedLeadsBody",
  components: {
    UpdatedFunnelBodyHead,
    UpdatedFunnelBodyItem,
    LeadsCreateTaskIcon,
    ManagerIcon,
    LeadsPhoneIcon,
  },

  data() {
    return {
      sourceHide: false,
      pipelineIsFull: false,
    };
  },
  props: {
    leads: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      managers: "assignmentManagers/managers",
      statuses: "updatedLeadsStatuses/statuses",
      isUserOP: "user/showUpdatedLeads",
      isDev: "user/isDev",
    }),
  },
  methods: {
    hasManager(lead) {
      const usersAreManagers = lead.users.filter(user => user.type === "manager");
      if (usersAreManagers.length > 0) {
        const managerIds = usersAreManagers.map(el => el.user_id);
        const managersListIds = this.managers.map(manager => manager.id);
        return managerIds.filter(managerId => managersListIds.includes(Number(managerId))).length > 0;
      }
      return false;
    },

    managerName(lead) {
      const usersAreManagers = lead.users.filter(user => user.type === "manager");
      if (usersAreManagers.length > 0) {
        const managerIds = usersAreManagers.map(el => el.user_id);
        const managersListIds = this.managers.map(manager => manager.id);
        ;
        const name = managerIds.filter(managerId => managersListIds.includes(Number(managerId))).map(el => this.managers.find(manager => manager.id == el).name)
        if (name.length > 0) return name[0]
      }
    },

    hideSource() {
      this.sourceHide = true;
    },

    showSource() {
      this.sourceHide = false;
    },

    sortByName() {
      console.log("sortByName");
    },

    sortByInteraction() {
      console.log("sortByInteraction");
    },

    sortByAdding() {
      console.log("sortByAdding");
    },

    changeSort(sortType) {
      this.$emit("change-sort", sortType);
    },

    getPipelineFull() {
      this.pipelineIsFull = true;
    },

    getPipelineShort() {
      this.pipelineIsFull = false;
    },

    status(lead) {
      return lead.status ? this.statuses.find(el => el.id === lead.status.id).name : "";
    },

    openModal(lead) {
      const routeUrl = this.$router.resolve({
        path: `leads/${lead.id}`,
      });
      window.open(routeUrl.href, "_blank");
    },

    openCreateTaskModalByLead(lead) {
      this.$modal.show("create-task", { lead });
    },

    openTelephonyModal(data) {
      const startCall = () => {
        Promise.all([this.$store.dispatch("caller/initAudioDevices")])
          .then(() => {
            this.$store.dispatch("caller/startCall", data);
            this.$modal.hide(this.modalName);
            this.$modal.show("telephony-modal");
          })
          .catch(e => {
            console.log(e);
          });
      };
      const noAccess = () =>
        runNotice(
          "error",
          this.$t("Включите доступ к микрофону в настройках браузера")
        );

      if (this.isFirefox) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function () {
            startCall();
          })
          .catch(function (err) {
            console.log("No mic for you!");
          });
        return;
      }

      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "microphone" })
          .then(result => {
            const permission = result.state;

            if (permission === "granted") {
              startCall();
            } else {
              navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function () {
                  startCall();
                })
                .catch(function (err) {
                  console.log("No mic access");
                  noAccess();
                });
            }
          })
          .catch(error => {
            console.log("Got error :", error);
            runNotice(
              "error",
              this.$t("Включите доступ к микрофону в настройках браузера")
            );
          });
      } else {
        startCall();
      }

      return;
    },

    phoneRepeated(triggerPhone, triggers) {
      if (triggers.length === 0) {
        return false;
      } else {
        const booleanArray = triggers.map((trigger, index) => {
          // const phonesEqual = trigger.phone === triggerPhone;

          // const phonesEqualWithPlus = trigger.phone && trigger.phone.startsWith("+")
          //   ? trigger.phone.slice(1) === triggerPhone
          //   : false;

          if (trigger.phone) {
            const triggersPhonesEqual =
              index === 0
                ? trigger.phone === triggerPhone
                : trigger.phone === triggers[index - 1].phone;

            // const triggersPhonesWithPlusEqual =
            //   index !== 0
            //     ? trigger.phone && trigger.phone.startsWith("+")
            //       ? trigger.phone.slice(1) === triggers[index - 1].phone
            //       : false
            //     : false;

            return triggersPhonesEqual;
          }
        });

        return booleanArray.includes(false);
      }
    },

    openCallToLeadModal(lead) {
      if (!this.phoneRepeated(lead.trigger.phone, lead.triggers)) {
        this.openTelephonyModal(({ lead, phone: lead.trigger.phone }));
        return;
      }
      this.$modal.show("call-to-lead", { lead });
    },

    phoneStartsWithPlus(lead) {
      return lead?.trigger?.phone ? lead.trigger.phone.startsWith("+") : false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsTable {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
  grid-gap: 11px;
  margin-top: 6px;

  @media (min-width: phone) {
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
  }

  @media (min-width: 1150px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 20px;
  }

  @media (min-width: desktop) {
    display: block;
    margin-top: 17px;
  }
}

.leadsTableBox {
  display: grid;
  grid-gap: 8px 4px;
  grid-template-areas: 'avatar name manager source' 'status status status status' 'actions actions actions actions' 'tags tags tags tags' 'createTask createTask call call';
  grid-template-columns: 20px calc(100vw - 230px) 24px 155px;

  @media (min-width: phone_s) {
    grid-template-columns: 20px 145px 24px 155px;
  }

  @media (min-width: tablet_xs) {
    grid-template-columns: 20px 90px 24px 155px;
  }

  @media (min-width: desktop) {
    grid-template-columns: 24px 130px 90px 170px 24px 293px 83px 16px 20px;
    grid-gap: 23px 6px;
    grid-template-areas: unset;

    &_sourceHide {
      grid-template-columns: 24px 130px 90px 12px 24px 293px 83px 16px 20px;
    }
  }

  @media (min-width: 1240px) {
    grid-template-columns: 24px 130px 90px 170px 24px 313px 83px 16px 20px;

    &_sourceHide {
      grid-template-columns: 24px 130px 90px 12px 24px 313px 83px 16px 20px;
    }
  }

  @media (min-width: desktopLarge) {
    grid-template-columns: 24px 135px 90px 170px 24px 392px 83px 16px 20px;
    grid-gap: 23px 12px;

    &_sourceHide {
      grid-template-columns: 24px 135px 90px 12px 24px 392px 83px 16px 20px;
    }
  }

  @media (min-width: desktopLeads) {
    grid-template-columns: 24px 135px 90px 170px 24px 362px 83px 16px 130px;

    &_sourceHide {
      grid-template-columns: 24px 135px 90px 12px 24px 362px 83px 16px 130px;
    }
  }

  @media (min-width: 1440px) {
    grid-template-columns: 24px 135px 90px 170px 24px 382px 83px 16px 130px;

    &_sourceHide {
      grid-template-columns: 24px 135px 90px 12px 24px 382px 83px 16px 130px;
    }
  }

  @media (min-width: 1500px) {
    grid-template-columns: 24px 135px 90px 170px 24px 442px 83px 16px 130px;

    &_sourceHide {
      grid-template-columns: 24px 135px 90px 12px 24px 442px 83px 16px 130px;
    }
  }

  @media (min-width: desktopXl) {
    grid-template-columns: 24px 135px 90px 170px 24px 468px 83px 16px 130px;

    &_sourceHide {
      grid-template-columns: 24px 135px 90px 12px 24px 468px 83px 16px 130px;
    }
  }
}

.leadsTableBoxHead {
  display: none;

  @media (min-width: desktop) {
    display: grid;
  }
}

.leadsTableHead {
  &__cell {
    height: 22px;
  }

  &__cellText {
    font(main_text_color, 14px, 700, 18px);
    text-align: left;
  }
}

.leadsTableHead__cellStatus, .leadsTableItem__cellStatus {
  grid-area: status;

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.leadsTableItem__cell {
  position: relative;
  align-items: center;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  font(main_text_color, 12px, 400, 14px);

  @media (min-width: desktop) {
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
  }
}

.leadsTableItem__cellStatus {
  @media (min-width: desktop) {
    position: relative;
    display: flex;
    align-items: center;
    cell_fade();
    overflow: hidden;
  }
}

.leadsTableItem__cellManager {
  display: flex;
  align-items: center;
  color: danger_text_color;
  height: 20px;
  line-height: unset;

  @media (min-width: desktop) {
    height: 24px;
  }
}

.managerIcon {
  position: relative;
  top: 1px;
  color: #A5A8B8;

  &_desktop {
    display: none;

    @media (min-width: desktop) {
      display: flex;
    }
  }

  &_mobile {
    @media (min-width: desktop) {
      display: none;
    }
  }

  &_active {
    color: main_text_color;
  }
}

.updatedFunnelBodyItem__cell_mobile {
  display: inline-flex;
  margin-right: 7px;
  color: #0f1642;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.4;

  @media (min-width: desktop) {
    display: none;
  }
}

.updatedFunnelBodyItem__cellActionsButton {
  grid-area: createTask;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  text-decoration: none;
  background: none;
  font(main_text_color, 12px, 400, 14px);

  @media (min-width: desktop) {
    grid-area: unset;
    display: none;
    font-size: 12px;
    line-height: 14px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.updatedFunnelBodyItem__callButton {
  grid-area: call;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background: unset;

  @media (min-width: desktop) {
    grid-area: unset;
    display: none;

    &:hover {
      opacity: 0.6;
    }
  }
}

.updatedFunnelBodyItem__cellActionsText {
  @media (min-width: desktop) {
    display: none;
  }

  @media (min-width: desktopLeads) {
    display: block;
  }
}

.updatedFunnelBodyItem {
  &:hover {
    @media (min-width: desktop) {
      .updatedFunnelBodyItem__cellActionsButton, .updatedFunnelBodyItem__callButton {
        display: flex;
      }
    }
  }
}

.notificationPopover {
  color: main_text_color;
  font_mobile();
  text-align: left;
}

.tooltip-title {
  font-weight: 600;
}
</style>
