<template>
  <fieldset class="checkboxes__fieldset">
    <legend class="checkboxes__legend">
      <Tr>{{ checkboxGroup.title }}</Tr>
    </legend>
    <button
      class="checkboxes__buttonAll"
      @click="checkboxesCheck"
      v-if="buttonAll && !killAllBtn"
    >
      <Tr>Все</Tr>
    </button>

    <div
      class="checkboxes__labelWrap"
      v-for="checkboxGroupItem in checkboxGroup.array"
      :key="checkboxGroupItem.id"
    >
      <label class="checkboxes__labelItem">
        <Tr>{{ checkboxGroupItem.name }}</Tr>
        <input
          class="checkboxes__inputCheckbox"
          :name="checkboxGroup.name"
          type="checkbox"
          :checked="isFilterChecked(checkboxGroup.name, checkboxGroupItem.id)"
          @change="change($event, checkboxGroupItem.id)"
        />
        <span
          class="checkboxes__checkBox"
          :class="{
            checkboxes__checkBox_radioButton: isRadio,
          }"
        />
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "UpdatedFunnelFilterCheckboxesGroup",
  data() {
    return {
      buttonAll: true,
    };
  },
  props: {
    isRadio: Boolean,
    checkboxGroup: Object,
    isFilterChecked: Function,
    killAllBtn: Boolean,
  },
  methods: {
    checkboxesCheck() {
      this.$emit("change", {
        name: this.checkboxGroup.name,
        value: this.checkboxGroup.array.map((el) => el.value),
      });
      this.buttonAll = false;
    },
    change(el, value) {
      this.$emit("change", {
        name: this.checkboxGroup.name,
        value,
      });
      if (!el.target.checked) {
        this.buttonAll = true;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.checkboxes__fieldset {
  position: relative;
  display: inline-block;
  width: 200px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;

  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.checkboxes__buttonAll {
  position: absolute;
  top: -30px;
  right: 2px;
  letter-spacing: 0.4px;
  line-height: 17px;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dotted secondary_text_color;
  color: secondary_text_color;
}

.checkboxes__legend {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.checkboxes__labelWrap+.checkboxes__labelWrap {
  margin-top: 6px;
}

.checkboxes__labelWrap {
  position: relative;
}

.checkboxes__labelItem {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 24px;
  font(main_text_color, 14px, 400, 19px);
  cursor: pointer;
}

.checkboxes__checkBox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid grey_text_color;
  border-radius: 2px;

  &_radioButton {
    border-radius: 50%;
  }
}

.checkboxes__inputCheckbox:checked + .checkboxes__checkBox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Crect width='8' height='8' rx='2 ' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 70%;
  background-position-x: 2px;
  background-position-y: 2px;
  background-repeat: no-repeat;
}

.checkboxes__inputCheckbox:checked + .checkboxes__checkBox_radioButton {
  background-image: none;
  background-color: main_text_color;
  box-shadow: inset 0px 0px 0px 2px #fff;
}

.checkboxes__inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

@media (min-width: desktop) {
}
</style>
