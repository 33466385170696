import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import i18n from "../plugins/i18n";
import DefaultLayout from "@/layouts/default.vue";
import TechWorksPlaceholder from "@/layouts/techWorks.vue";
import supportedLangs from "@/utils/supportedLangs";
import innerRoutes from "./routes";

Vue.use(VueRouter);

const languages = supportedLangs();

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "root",
    beforeEnter(to, from, next) {
      if (to.params.lang) {
        return next();
      }
      return next({ path: `/${i18n.locale}` });
    },
  },
  {
    path: "/tech-works",
    name: "techWorks",
    component: TechWorksPlaceholder,
    beforeEnter(to, from, next) {
      if (to.params.lang) {
        return next();
      }
      // return next({ path: `/${i18n.locale}` });
      return next({ path: `/${i18n.locale}/tech-works` });
    },
  },
  {
    path: "/:lang/tech-works",
    name: "langTechWorks",
    component: TechWorksPlaceholder,
    beforeEnter(to, from, next) {
      const lang = to.params.lang;

      if (languages.includes(lang)) {
        i18n.locale = lang;
        return next();
      }
      return next({ path: i18n.locale });
    },
  },
  {
    path: "/:lang",
    name: "locale",
    component: DefaultLayout,
    beforeEnter(to, from, next) {
      const lang = to.params.lang;

      if (languages.includes(lang)) {
        i18n.locale = lang;
        return next();
      }
      return next({ path: i18n.locale });
    },

    children: innerRoutes,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
