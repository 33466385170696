<template>
  <modal
    name="updated-assign-manager"
    :width="640"
    height="auto"
    :maxHeight="428"
    :shiftY="0.2"
    :adaptive="true"
  >
    <div class="pickManager">
      <!-- HEADER -->
      <div class="pickManager__header">
        <div class="pickManager__headerName">
          <Tr>Назначить менеджера</Tr>
        </div>
        <button
          class="pickManager__headerButton"
          type="button"
          @click="hidePickManager"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="pickManager__body">
        <div class="pickManager__leads leads">
          <div class="pickManager__title">
            <Tr>Лиды</Tr>
          </div>
          <div :class="['leads__wrap', { leads__wrap_pb: leads.length > 5 }]">
            <div class="leads__item lead" v-for="lead in leads" :key="lead.id">
              <div class="lead__avatar">
                <div class="lead__avatarImg empty" :style="randomColor">
                  {{ name(lead) | getInitialsFilter }}
                </div>
              </div>
              <span class="lead__name">{{ name(lead) }}</span>
            </div>
          </div>
          <div class="pickManager__border" v-if="leads.length > 5" />
        </div>
        <div class="pickManager__manager">
          <div class="pickManager__title">
            <Tr>Менеджер</Tr>
          </div>
          <select
            class="pickManager__select"
            name="pickManagerSelect"
            @change="changeManager"
            required
          >
            <!-- <option disabled selected /> -->
            <option
              v-for="(manager, index) in managers"
              :value="manager.id"
              :key="`${index}-${manager.id}`"
            >
              {{ manager.name }}
            </option>
          </select>
        </div>
      </div>
      <!-- BODY -->

      <!-- FOOTER -->
      <div class="pickManager__footer">
        <button
          :class="['pickManager__button']"
          type="button"
          @click="pickManager"
        >
          <Tr>Назначить</Tr>
        </button>
      </div>
      <!-- FOOTER -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import { runNotice } from "@/utils/notifications";

export default {
  name: "UpdatedAssignmentModalAssignManager",
  components: {
    CloseButton,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      leads: "updatedAssignment/pickedLeads",
      managers: "assignmentManagers/managers",
      // errors: "assignment/errors",
    }),
    randomColor() {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }
      const str = `background: linear-gradient(#fff -125%, rgb(${getRandomInt(
        150,
        240
      )}, ${getRandomInt(150, 240)}, ${getRandomInt(150, 240)}))`;
      return str;
    },
  },
  // watch: {
  //   errors(value) {
  //     Object.values(this.errors).forEach(i => {
  //       i.forEach(k => {
  //         runNotice("error", k);
  //       })
  //     })
  //   }
  // },
  methods: {
    hidePickManager() {
      this.$modal.hide("updated-assign-manager");
    },
    pickManager() {
      Promise.all([this.$store.dispatch("updatedAssignment/pickManager")])
        .then(() => {
          setTimeout(() => {
            this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
            runNotice("success", this.$t("Менеджер назначен"));
          }, 500);
        })
        .catch(() => {
          setTimeout(() => {
            this.$store.dispatch("updatedAssignment/fetch", { page: 1 });
            runNotice("success", this.$t("Ошибка"));
          }, 500);
        });
      this.$modal.hide("updated-assign-manager");
    },
    changeManager(e) {
      this.$store.commit("updatedAssignment/CHANGE_MANAGER", e.target.value);
    },
    name(lead) {
      if (lead.trigger) {
        return lead.trigger.name;
      }
      return "";
    },
  },
};
</script>

<style lang="stylus" scoped>
.pickManager {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  height: 100%;

  @media (min-width: tablet) {
    padding: 16px 16px 20px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    padding-bottom: 7px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    right: 6px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__title {
    margin-bottom: 4px;
    font_small();
  }

  &__border {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: grey_text_color;

    &::before {
      position: absolute;
      top: -8px;
      content: '';
      display: block;
      width: 100%;
      height: 8px;
      background-image: linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%);
    }
  }

  &__select {
    display: block;
    padding: 5px 8px;
    width: 100%;
    max-width: 400px;
    border-radius: 2px;
    border: 1px solid grey_text_color;
    font_regular();
  }

  &__footer {
    margin: 0 -16px;
    padding: 20px 16px 0;
    border-top: 2px solid grey_text_color;
  }

  &__button {
    display: block;
    padding: 11px 12px;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    border: none;
    border-radius: 4px;
    font(#fff, 14px, 400, 18px);

    &_disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.leads {
  margin-bottom: 18px;

  &__wrap {
    margin-top: 8px;
    max-height: 182px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ebe6e7 #fff;

    &_pb {
      padding-bottom: 8px;
    }

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6e7eb;
      border: 2px solid #fff;
      border-radius: 6px;
    }
  }
}

.lead {
  display: flex;
  align-items: center;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &__avatar {
    margin-right: 8px;
    border-radius: 50%;
  }

  &__avatarImg {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;

    @media (min-width: desktop) {
      width: 24px;
      height: 24px;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: orange;
      font(main_text_color, 10px, 700, 14px);
    }
  }

  &__name {
    word-break: break-word;
    text-overflow: ellipsis;
    font_regular();
  }
}
</style>
