import Course from "@/models/course";
import i18n from "@/plugins/i18n";
import { getLang } from "@/utils/cookies/getLang";
import { errorMiddlewareNotice, runNotice } from "@/utils/notifications";
import supportedLangs from "@/utils/supportedLangs";
import { append, path, pathOr } from "ramda";
import Vue from "vue";

const transformResponseData = data => new Course(data);

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
    activeLang: getLang(),
  }),

  getters: {
    course(state) {
      return state.data;
    },
    title(state, getters, rootState) {
      return pathOr("", ["data", "locales", rootState.siteLang, "name"], state);
    },
    pending(state) {
      return state.pending;
    },
    lang(state) {
      return state.activeLang;
    },
    errors(state) {
      return state.errors;
    },
    name(state) {
      return pathOr("", ["data", "locales", state.activeLang, "name"], state);
    },
    description(state) {
      return pathOr(
        "",
        ["data", "locales", state.activeLang, "short_description"],
        state
      );
    },
    isLangValid: state => lang => {
      if (!state.data.locales && !lang) {
        return false;
      }
      return (
        path(["data", "locales", lang, "name"], state) &&
        path(["data", "locales", lang, "short_description"], state)
      );
    },
    isPriceValid(state) {
      if (state.data.cost.isFree) {
        return true;
      } else if (state.data.cost.cost) {
        return true;
      }

      return false;
    },
    langTabs(state, getters) {
      return supportedLangs().map(lang => ({
        title: lang,
        isValid: getters.isLangValid(lang),
      }));
    },
    isPublished(state) {
      if (state.data && state.data.published_at) {
        return true;
      }
      return false;
    },
    cost(state) {
      return pathOr({}, ["data", "cost"], state);
    },
    choosedProgram(state) {
      return pathOr(null, ["data", "quest_course"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    TOGGLE_COURSE_LANG(state, lang) {
      if (state.data.languages.indexOf(lang) === -1) {
        state.data.languages = append(lang, state.data.languages);
      } else {
        state.data.languages = state.data.languages.filter(
          item => item !== lang
        );
      }
    },
    CHANGE_NAME(state, value) {
      state.data.locales[state.activeLang].name = value;
    },
    CHANGE_DESCRIPTION(state, value) {
      state.data.locales[state.activeLang].short_description = value;
    },
    PUBLISH_COURSE(state, publishedAt) {
      state.data.published_at = publishedAt;
    },
    TOGGLE_COST(state, value) {
      state.data.payment_type = value;
    },
    CHANGE_COST(state, value) {
      state.data.cost = value;
    },
    CHANGE_PROGRAM(state, value) {
      state.data.quest_course = value;
    },
  },

  actions: {
    async fetch({ commit, state }, { courseId }) {
      if (courseId == state.data.id) {
        // return;
      }
      try {
        commit("SET_ERRORS", {});
        commit("SET_PENDING", true);
        commit("CLEAR_DATA");
        const response = await Vue.axios.get(`/leadership_quests/${courseId}/`);
        if (response.status === 200) {
          commit("SET_DATA", transformResponseData(response.data));
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async updateCourse({ commit, state, rootState, getters }) {
      commit("SET_PENDING", true);
      commit("SET_ERRORS", {});
      try {
        const data = state.data.getUpdateModel();
        const response = await Vue.axios.patch(
          `/leadership_quests/${state.data.id}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("CLEAR_DATA");
          setTimeout(
            () => commit("SET_DATA", transformResponseData(response.data)),
            100
          );

          runNotice("success", i18n.t("Квест успешно сохранен"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async publishCourse({ commit, state }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_ERRORS", {});

        const data = { published_at: new Date() };
        const response = await Vue.axios.patch(
          `/leadership_quests/${state.data.id}/publish/`
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("PUBLISH_COURSE", response.data.published_at);
          commit("SET_DATA", transformResponseData(response.data.quest));
          runNotice("success", i18n.t("Квест успешно опубликован"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
        errorMiddlewareNotice(e.response.data);
      }
    },

    async unpublishCourse({ commit, state }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_ERRORS", {});

        const data = { published_at: null };
        const response = await Vue.axios.patch(
          `/leadership_quests/${state.data.id}/unpublish/`
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("PUBLISH_COURSE", response.data.published_at);
          runNotice("success", i18n.t("Квест успешно снят с публикации"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
