<template>
  <div class="questions__container">
    <h2 class="questions__title">
      <Tr>Вопросы теста</Tr>
    </h2>

    <draggable
      class="fieldset__container"
      v-model="questionsList"
      v-if="questionsList && questionsList.length"
      handle=".moveButton_question"
    >
      <test-question
        :activeLang="activeLang"
        :index="quesitionIndex"
        :key="'question-' + quesitionIndex"
        :question="question"
        @add-answer="addAnswer"
        @change-answer="changeAnswer"
        @change-answer-explanation="changeAnswerExplanation"
        @change-answer-right="changeAnswerRight"
        @delete-answer="deleteAnswer"
        @delete-question="deleteQuestion"
        @handle-change="changeQuestion"
        @update-answer-list="updateAnswersList"
        v-for="(question, quesitionIndex) in questionsList"
      />
    </draggable>
    <button @click="addQuestion" class="addQuestions">
      <Tr>Добавить вопрос</Tr>
    </button>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import TestQuestion from "@/components/Leadership/TestQuestion.vue";

export default {
  name: "TaskTest",
  components: {
    draggable,
    TestQuestion,
  },
  computed: {
    ...mapGetters({
      activeLang: "leadershipTask/activeLang",
    }),
    questionsList: {
      get() {
        return this.$store.getters["leadershipTaskPoll/questions"];
      },
      set(value) {
        this.$store.commit("leadershipTaskPoll/UPDATE_QUESTIONS_LIST", value);
      },
    },
  },
  methods: {
    addAnswer(payload) {
      this.$store.commit("leadershipTaskPoll/ADD_ANSWER", payload);
    },
    addQuestion() {
      this.$store.commit("leadershipTaskPoll/ADD_QUESTION");
    },
    changeAnswer(payload) {
      this.$store.commit("leadershipTaskPoll/CHANGE_ANSWER_BODY", payload);
    },
    changeAnswerExplanation(payload) {
      this.$store.commit("leadershipTaskPoll/CHANGE_ANSWER_EXPLANATION", payload);
    },
    changeAnswerRight(payload) {
      this.$store.commit("leadershipTaskPoll/CHANGE_ANSWER_RIGHT", payload);
    },
    deleteAnswer(value) {
      this.$store.commit("leadershipTaskPoll/DELETE_ANSWER", value);
    },
    deleteQuestion(value) {
      this.$store.commit("leadershipTaskPoll/DELETE_QUESTION", value);
    },
    changeQuestion(payload) {
      this.$store.commit("leadershipTaskPoll/CHANGE_QUESTION_BODY", payload);
    },
    updateAnswersList(payload) {
      this.$store.commit("leadershipTaskPoll/UPDATE_ANSWERS_LIST", payload);
    },
  },
};
</script>

<style lang="stylus" scoped>
.questions__title {
  margin-bottom: 14px;
  font_regular();
  font-weight: 700;
}

.fieldset {
  position: relative;

  &__container {
    display: grid;
    grid-row-gap: 20px;
  }

  &_question {
    padding: 4px 48px 0px 16px;
    background-color: grey_text_color;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &_answer {
    padding: 8px 48px 16px 44px;
    border: 1px solid grey_text_color;
  }

  &_addAnswer {
    padding: 16px;
    border: 1px solid grey_text_color;
  }
}

.addQuestions {
  margin-top: 20px;
  padding: 6px 7px;
  border: 1px dashed main_text_color;
  width: max-content;
  border-radius: 4px;
  background: none;
  font_regular();
}
</style>
