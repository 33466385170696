import router from "@/router";
import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import qs from "qs";
import { assocPath, find, path, pathEq, pathOr } from "ramda";

const getGreeting = data => {
  if (data && data.elements) {
    const filtered = data.elements.filter(
      el => el.position && el.position.block && el.position.block === "greeting"
    );
    return filtered[0] || {};
  }
  return {};
};

const getQuestions = data => {
  if (data && data.elements) {
    const filtered = data.elements.filter(
      el =>
        el.position && el.position.block && el.position.block === "questions"
    );
    return filtered || [];
  }
  return [];
};

export default {
  namespaced: true,
  state: () => ({
    data: {},
    greeting: {},
    questions: [],
    errors: {},
    pending: false,
  }),

  getters: {
    name(state) {
      return state.data.name;
    },
    pending(state) {
      return state.pending;
    },
    status(state) {
      return state.data.status;
    },
    greetingText(state) {
      return pathOr("", ["greeting", "parameters", "text", "all"], state);
    },
    question(state) {
      return index => {
        const el = find((el: any) => {
          if (el.position.index == index) {
            return true;
          }
          return false;
        })(state.questions);

        return path(["parameters", "text", "all"], el);
      };
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.greeting = getGreeting(data);
      state.questions = getQuestions(data);
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, data) {
      state.data.name = data;
    },
    CHANGE_LANG(state, data) {
      state.data.lang = data;
    },
    CHANGE_STATUS(state, data) {
      state.data.status = data;
    },
    SET_GREETING_TEXT(state, data) {
      state.greeting = assocPath(
        ["parameters", "text", "all"],
        data,
        state.greeting
      );
    },
    SET_QUESTION_TEXT(state, { index, value }) {
      state.questions = state.questions.map(el => {
        if (el.position.index == index) {
          return assocPath(["parameters", "text", "all"], value, el);
        }
        return el;
      });
    },
  },

  actions: {
    initNew({ commit }) {
      commit("SET_DATA", {
        name: "",
        lang: "en",
        status: "draft",
      });
    },
    async fetch({ commit, rootGetters }, id) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", {});

        const response = await axios.get(
          `/funnels/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    putGreeting({ dispatch, state }, { type, index }) {
      if (type === "greeting") {
        dispatch("putMessage", state.greeting);
      } else {
        dispatch(
          "putMessage",
          find((el: any) => {
            if (el.position.index == index) {
              return true;
            }
            return false;
          })(state.questions)
        );
      }
    },

    async putMessage({ commit, rootGetters }, message) {
      const { id } = message;

      try {
        const data = qs.stringify(message);
        const { funnelId } = router.currentRoute.params;

        const response = await axios.put(
          `/funnels/${funnelId}/elements/${id}`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status == 200) {
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async restoreQuestion({ commit, rootGetters }, payload) {
      try {
        const data = qs.stringify(payload);

        const response = await axios.get(
          `/funnels/default/elements?${data}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          return response.data.response;
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
