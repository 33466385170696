import router from "@/router";
import { runNotice } from "@/utils/notifications";
import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    notes: [],
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    notes(state) {
      return state.notes;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.notes = [...data.results];
    },

    SET_MORE_NOTES(state, data) {
      state.data = data;
      state.notes = [...state.notes, ...data.results];
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ commit }, { page, contact }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_notes/`, {
          params: { page, contact },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchMore({ commit }, { page, contact }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/contact_notes/`, {
          params: { page, contact },
        });

        if (response.status === 200) {
          commit("SET_MORE_NOTES", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createNote({ commit, state, dispatch }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/contact_notes/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          dispatch("fetch", { page: 1, contact: data.contact });
          return true;
        } else {
          commit("SET_PENDING", false);
          throw response.data.message;
        }
      } catch (e) {
        e.response.data.text.forEach(text => runNotice("error", text));
        commit("SET_PENDING", false);
      }
    },

    async updateNote({ commit, state, dispatch }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.patch(
          `/contact_notes/${data.id}/`,
          data
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          dispatch("fetch", { page: 1, contact: data.contact });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async deleteNote({ commit, state, dispatch }, { id, contact }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(`/contact_notes/${id}/`);

        if (response.status === 204) {
          commit("SET_PENDING", false);
          console.info("del");
          dispatch("fetch", { page: 1, contact });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
