<template>
  <!-- TABLE HEAD -->
  <div class="teamTree__tableHead">
    <div class="teamTree__tableHeadCell teamTree__tableCellName">
      <span class="teamTree__tableHeadText"><Tr>Имя</Tr></span>
    </div>
    <div class="teamTree__tableHeadCell teamTree__tableCellPhone">
      <span class="teamTree__tableHeadText"><Tr>Телефон</Tr></span>
    </div>
    <div class="teamTree__tableHeadCell teamTree__tableCellMessenger">
      <span class="teamTree__tableHeadText"><Tr>Связь</Tr></span>
    </div>
    <div
      class="teamTree__tableHeadCell teamTree__tableCellRank"
      v-if="showRank"
    >
      <span class="teamTree__tableHeadText"><Tr>Ранг</Tr></span>
    </div>
    <div class="teamTree__tableHeadCell teamTree__tableCellMLMPult">
      <span class="teamTree__tableHeadText">
        <Tr>Подписка</Tr>
      </span>
    </div>
    <div class="teamTree__tableHeadCell teamTree__tableCellContacts">
      <span class="teamTree__tableHeadText"><Tr>Контакты</Tr></span>
    </div>
    <div class="teamTree__tableHeadCell teamTree__tableCellTeam">
      <span class="teamTree__tableHeadText"><Tr>Команда</Tr></span>
    </div>
    <div
      class="teamTree__tableHeadCell teamTree__tableCellGold teamTree__tableHeadImg"
      v-if="!hideAwards"
    >
      <span class="teamTree__tableHeadText">
        <img
          class="team__currency team__currency_gold"
          src="@/assets/images/gold@2x.png"
          v-tooltip.top-center="tooltips.gold"
        />
      </span>
    </div>
    <div
      class="teamTree__tableHeadCell teamTree__tableCellDiamond teamTree__tableHeadImg"
      v-if="!hideAwards"
    >
      <span class="teamTree__tableHeadText">
        <img
          class="team__currency team__currency_diamond"
          src="@/assets/images/diamond@2x.png"
          v-tooltip.top-center="tooltips.diamond"
        />
      </span>
    </div>
  </div>
  <!-- END OF TABLE HEAD -->
</template>
<script>
export default {
  name: "TeamHead",
  props: {
    showRank: {
      required: false,
      type: Boolean,
    },
    company: {
      required: true,
      type: String,
    },
  },
  data() {
    return {};
  },
  components: {},
  computed: {
    tooltips() {
      return {
        gold: this.$t("Золото"),
        diamond: this.$t("Бриллианты"),
      };
    },
    hideAwards() {
      return this.company === "oton";
    },
  },
};
</script>
<style lang="stylus" scoped>
.teamTree__tableHead {
  display: none;
  grid-grid-auto-flow: column;
  grid-auto-flow: column;
  margin-bottom: 12px;
  width: fit-content;

  &Text {
    font_bold();

    &_bordered {
      border-bottom: 2px dotted #0f164270;
    }
  }

  .teamTree__tableCellName {
    margin-left: 60px;
  }
}

.teamTree__table {
  &Cell {
    &Name {
      width: 190px;
    }

    &Phone {
      width: 144px;
    }

    &Messenger {
      width: 96px;
    }

    &Rank {
      width: 140px;
    }

    &MLMPult {
      width: 114px;
    }

    &Contacts {
      width: 97px;
    }

    &Team {
      width: 93px;
    }

    &Gold, &Diamond {
      width: 47px;
    }
  }
}

.team__currency {
  position: relative;

  &_gold {
    bottom: 2px;
    height: 20px;
  }

  &_diamond {
    bottom: 4px;
    height: 16px;
  }
}

@media (min-width: 1320px) {
  .teamTree__tableHead {
    display: grid;
  }
}
</style>
