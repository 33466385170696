<template>
  <modal
    name="status-check"
    @before-open="beforeOpen"
    :width="640"
    height="auto"
    :adaptive="true"
    :shiftY="0.3"
  >
    <div class="lead-status-check">
      <div class="lead-status-check__title">
        <Tr>Введите обязательный комментарий для статуса 'Отказ'</Tr>
      </div>
      <textarea
        name="lead-status-check"
        id="lead-status-check"
        cols="30"
        rows="5"
        v-model="rejection_reason"
        class="lead-status-check__textarea"
      />
      <div class="lead-status-check__buttons-group">
        <FormSubmit
          class="lead-status-check__button lead-status-check__button_submit"
          @click="submit"
          text="Сохранить"
        />
        <FormSubmit
          class="lead-status-check__button lead-status-check__button_cancel"
          @click="cancel"
          text="Отмена"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
// import { path, pathEq } from "ramda";
import { runNotice } from "@/utils/notifications";
import FormSubmit from "@/components/FormSubmit";

export default {
  name: "LeadsModalStatusCheck",
  components: {
    FormSubmit,
  },
  data() {
    return {
      lead: {},
      rejection_reason: "",
      newStatus: null,
      currentStatus: null,
    };
  },
  computed: {
    ...mapGetters({}),
    valueLength() {
      if (!this.description) {
        return 0;
      }
      return this.description.length;
    },
  },
  methods: {
    beforeOpen(event) {
      this.newStatus = event.params.newStatus;
      this.currentStatus = event.params.currentStatus;
      this.rejection_reason = "";
    },

    cancel() {
      this.$modal.hide("status-check");
    },

    submit() {
      if (this.rejection_reason.trim()) {
        const { leadId } = this.$route.params;

        Promise.all([
          this.$store.dispatch("lead/changeStatus", {
            leadId,
            status: this.newStatus,
            rejection_reason: this.rejection_reason,
          }),
        ]).then(response => {
          this.$modal.hide("status-check");
          if (response && response[0] && response[0] === 200) {
            runNotice("success", this.$t("Статус изменен"));
          } else {
            runNotice("error", this.$t("Ошибка"));
          }
        });
      } else {
        runNotice("error", this.$t("Комментарий не может быть пустым"));
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.lead-status-check {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  align-items: flex-start;

  &__title {
    margin-bottom: 20px;
    font(main_text_color, 16px, 700, 18px);

    @media (min-width: tablet) {
      font(main_text_color, 18px, 700, 22px);
    }
  }

  &__textarea {
    display: block;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    resize: none;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ebe6e7 #fff;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6e7eb;
      border: 2px solid #fff;
      border-radius: 6px;
    }
  }

  &__buttons-group {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 20px;
  }

  &__button {
    margin-top: 0;

    &_submit {
      background-color: orange;
      color: #fff;
    }
  }
}
</style>
