var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"funnelBodyHead"},[_vm._t("checkbox"),_c('div',{staticClass:"funnelBodyHead__cell"}),_c('div',{staticClass:"funnelBodyHead__cell"},[_c('Tr',{staticClass:"funnelBodyHead__cellText"},[_vm._v("Имя")])],1),_vm._t("manager"),_vm._t("status"),_c('div',{staticClass:"funnelBodyHead__cell funnelBodyHead__cellSource"},[_c('Tr',{class:[
        'funnelBodyHead__cellText',
        { hide: _vm.sourceHide },
        { show: !_vm.sourceHide } ]},[_vm._v("Дата")]),_c('button',{class:[
        'funnelBodyHead__cellButton',
        { hide: _vm.sourceHide },
        { show: !_vm.sourceHide } ],attrs:{"type":"button"},on:{"click":_vm.hideSource}},[_c('Tr',[_vm._v("Свернуть")])],1)],1),_vm._t("managerIcon"),_c('div',{staticClass:"funnelBodyHead__cell funnelBodyHead__cellActions"},[_c('button',{class:[
        'funnelBodyHead__cellButton',
        { funnelBodyHead__cellButton_active: _vm.pipelineIsFull } ],attrs:{"type":"button"},on:{"click":_vm.getPipelineFull}},[_c('Tr',[_vm._v("Подробно")])],1),_c('button',{class:[
        'funnelBodyHead__cellButton',
        { funnelBodyHead__cellButton_active: !_vm.pipelineIsFull } ],attrs:{"type":"button"},on:{"click":_vm.getPipelineShort}},[_c('Tr',[_vm._v("Кратко")])],1)]),_c('div',{staticClass:"funnelBodyHead__cell funnelBodyHead__cellTags"},[_c('Tr',{staticClass:"funnelBodyHead__cellText"},[_vm._v("Теги")])],1),_vm._t("calls"),_vm._t("createLinks")],2)}
var staticRenderFns = []

export { render, staticRenderFns }