export default function(array) {
  const newArray: any = [];

  for (let index = 0; index < array.length; index++) {
    const el = array[index];

    if (index === 0) {
      newArray.push({
        ...el,
        start: 0,
        end: Number(el.end) - Number(el.start),
      });
    } else {
      newArray.push({
        ...el,
        start: Number(newArray[index - 1].end),
        end:
          Number(newArray[index - 1].end) + (Number(el.end) - Number(el.start)),
      });
    }
  }
  return newArray;
}
