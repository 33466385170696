<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <div>
      <div class="title__wrap">
        <h1 class="title titleProfileQuests">
          <Tr>Свои бизнес-квесты</Tr>
        </h1>
        <base-link
          v-if="hasCourses"
          to="/leadership/courses/new"
          class="createQuest"
        >
          <Tr>Создать квест</Tr>
        </base-link>
      </div>
      <div class="underLoader">
        <courses-container />
      </div>
    </div>
  </main>
</template>

<script>
import CoursesContainer from "@/containers/Leadership/CoursesContainer.vue";
import { mapGetters } from "vuex";

export default {
  name: "Courses",
  components: {
    CoursesContainer,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Настройки лидера", link: "/leadership" }],
    };
  },
  computed: {
    ...mapGetters({
      courses: "leadershipCourses/courses",
    }),
    hasCourses() {
      if (this.courses && this.courses.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.titleProfileQuests {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-direction: column;
  @media (min-width: desktop) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.createQuest {
  align-self: flex-start;
  margin-bottom: 13px;
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  @media (min-width: desktop) {
    align-self: center;
    margin-bottom: 5px;
    margin-left: 50px;
  }
}
</style>
