<template>
  <lessons-container />
</template>

<script>
import LessonsContainer from "@/containers/Leadership/LessonsContainer.vue";

export default {
  name: "Lessons",
  components: {
    LessonsContainer,
  },
};
</script>
