<template>
  <div>
    <leadership-selectbox
      :value="value"
      :options="options"
      label="Входит в программу обучения"
      @change-select="changeTaskType"
    />
  </div>
</template>

<script>
import LeadershipSelectbox from "@/components/Leadership/LeadershipSelectbox.vue";

export default {
  name: "LeadershipProgramSelector",
  components: {
    LeadershipSelectbox,
  },
  props: {
    programs: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
    },
  },
  computed: {
    options() {
      const mapped = this.programs.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
      return [{ label: "-", value: null }].concat(mapped);
    },
  },
  methods: {
    changeTaskType(value) {
      if (value === "-") {
        this.$emit("change-program", null);
      } else {
        this.$emit("change-program", value);
      }
    },
  },
};
</script>

<style>
</style>
