<template>
  <div>
    <div
      v-for="(pipelineItem, index) in pipelineShort"
      :key="`${pipelineItem.name}_${index}`"
      class="pipelineShortItem"
    >
      <!-- NOTIFICATION -->
      <span
        class="pipelineShortItem__inner pipelineShortItem__inner_notification"
        v-if="pipelineItem.name.includes('notifications-email')"
      >
        <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
          <notification-email
            :class="[
              'pipelineShortItem__notificationIcon',
              {
                pipelineShortItem__notificationIcon_contact:
                  pipelineItem.name.includes('contact'),
              },
            ]"
          />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner pipelineShortItem__inner_notification"
        v-if="pipelineItem.name.includes('notifications-sms')"
      >
        <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
          <notification-sms
            :class="[
              'pipelineShortItem__notificationIcon',
              {
                pipelineShortItem__notificationIcon_contact:
                  pipelineItem.name.includes('contact'),
              },
            ]"
          />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner pipelineShortItem__inner_notification"
        v-if="pipelineItem.name.includes('notifications-autocall')"
      >
        <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
          <notification-call
            :class="[
              'pipelineShortItem__notificationIcon',
              {
                pipelineShortItem__notificationIcon_contact:
                  pipelineItem.name.includes('contact'),
              },
            ]"
          />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <!-- NOTIFICATION -->

      <!-- WEBINAR -->
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'webinars'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <webinar />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <!-- WEBINAR -->

      <!-- ORDER -->
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-approved'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-processing'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order class="orderInProcess" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-created'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order class="orderInProcess" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-expired'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order-failed class="orderInProcess" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-declined'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order-failed class="orderInProcess" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>

      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'orders-reversed'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <order-failed class="orderInProcess" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <!-- ORDER -->

      <!-- TRIGGERS -->
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'triggers-chatbot'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <funnel-telegram />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'triggers-form'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <funnel-landing />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'triggers-vk'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <funnel-vk />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <span
        class="pipelineShortItem__inner"
        v-if="pipelineItem.name === 'triggers-fb'"
      >
        <base-icon-svg width="20" height="24" viewbox="0 0 20 24">
          <funnel-fb />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <!-- TRIGGERS -->

      <!-- CHATBOT -->
      <span
        class="pipelineShortItem__inner pipelineShortItem__inner_notification"
        v-if="pipelineItem.name === 'chatbot'"
      >
        <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
          <notification-messenger class="pipelineShortItem__notificationIcon" />
        </base-icon-svg>
        <span
          class="pipelineShortItem__text"
          v-if="pipelineItem.objArray.length > 1"
        >
          +{{ pipelineItem.objArray.length - 1 }}
        </span>
      </span>
      <!-- CHATBOT -->
    </div>
  </div>
</template>

<script>
import NotificationEmail from "@/components/SvgIcons/funnel/NotificationEmail.vue";
import NotificationSms from "@/components/SvgIcons/funnel/NotificationSms.vue";
import NotificationCall from "@/components/SvgIcons/funnel/NotificationCall.vue";
import Webinar from "@/components/SvgIcons/funnel/Webinar.vue";
import Order from "@/components/SvgIcons/funnel/Order.vue";
import OrderFailed from "@/components/SvgIcons/funnel/OrderFailed.vue";
import NotificationMessenger from "@/components/SvgIcons/funnel/NotificationMessenger.vue";
import FunnelLanding from "@/components/SvgIcons/funnel/FunnelLanding.vue";
import FunnelVk from "@/components/SvgIcons/funnel/FunnelVk.vue";
import FunnelFb from "@/components/SvgIcons/funnel/FunnelFb.vue";
import FunnelTelegram from "@/components/SvgIcons/funnel/FunnelTelegram.vue";

export default {
  name: "UpdatedFunnelPipelineShort",

  components: {
    NotificationEmail,
    NotificationSms,
    NotificationCall,
    Webinar,
    Order,
    OrderFailed,
    NotificationMessenger,
    FunnelLanding,
    FunnelVk,
    FunnelFb,
    FunnelTelegram,
  },

  props: {
    lead: {
      type: Object,
      required: true,
    },
  },

  computed: {
    pipelineShort() {
      let pipelineShortArray = [];

      const fillPipelineShortArray = (el, index) => {
        if (index === 0) {
          pipelineShortArray = [
            ...pipelineShortArray,
            { name: el.array, objArray: [el] },
          ];
        }

        else if (index > 0 && pipelineShortArray[pipelineShortArray.length - 1].name === el.array) {
          pipelineShortArray[pipelineShortArray.length - 1].objArray.push(el);
        }

        else if (pipelineShortArray[pipelineShortArray.length - 1].name !== el.array) {
          pipelineShortArray = [
            ...pipelineShortArray,
            { name: el.array, objArray: [el] },
          ];
        }
      };

      const leadPipeline = [];

      this.lead.pipeline.forEach(el => {
        const leadPipelineObj = { ...el };
        leadPipeline.push(leadPipelineObj);
      });

      leadPipeline.forEach((el, index) => {
        if (el.array === "notifications") {
          el.array += "-" + this.lead.notifications.find(notific => notific.id === el.value).channel + "-" + this.lead.notifications.find(notific => notific.id === el.value).type;
        }
        if (el.array === "orders") {
          el.array += "-" + this.lead.orders.find(order => order.id === el.value).status;
        }
        if (el.array === "triggers") {
          el.array += "-" + this.lead.triggers.find(trigger => trigger.id === el.value).source;
        }

        fillPipelineShortArray(el, index);
      });

      return pipelineShortArray;
    },
  },
};
</script>

<style lang="stylus" scoped>
.pipelineShortItem {
  display: flex;
  align-items: center;
  width: fit-content;
  height: 24px;
  background-color: grey_text_color;
  border-radius: 2px;

  &__inner {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 3px;
    align-items: center;
    width: fit-content;

    &_notification {
      padding: 0 2px;
    }
  }

  &__text {
    padding-right: 2px;
    font_small();
    letter-spacing: 0;
  }

  &__notificationIcon {
    color: #3489f8;

    &_contact {
      color: main_text_color;
    }
  }
}

.orderInProcess {
  opacity: 0.6;
}
</style>
