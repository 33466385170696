import Course from "@/models/course";
import i18n from "@/plugins/i18n";
import router from "@/router";
import { runNotice } from "@/utils/notifications";
import { find, pathOr, propEq } from "ramda";
import Vue from "vue";

const transformResponseData = ({ results, ...rest }) => ({
  ...rest,
  results: results.map(course => new Course(course)),
});

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
  }),

  getters: {
    programs(state) {
      return state.data && state.data.results ? state.data.results : [];
    },
    pending(state) {
      return state.pending;
    },
    courses(state) {
      return pathOr(0, ["data", "results"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    UPDATE_LIST(state, list) {
      state.data.results = list;
    },
    DELETE_COURSE(state, courseId) {
      state.data.results = state.data.results.filter(
        ({ id }) => id !== courseId
      );
    },
    CLEAR_STORE(state) {
      state.data.results = [];
      state.pending = false;
    },
  },
  actions: {
    updateLessonPositionMirroring({ commit, getters }, list) {
      commit("UPDATE_LIST", list);
    },

    async updateLessonPosition(
      { commit, rootState, getters },
      { courseId, position }
    ) {
      try {
        const response = await Vue.axios.patch(
          `/leadership_quests/${courseId}/move/`,
          { new_position: position }
        );
        if (response.status === 200) {
          runNotice("success", i18n.t("Список уроков обновлен"));
        }
      } catch (e) {
        console.info(e);
      }
    },

    async deleteLesson({ commit, dispatch, state }, courseId) {
      commit("SET_PENDING", true);
      commit("SET_ERRORS", {});

      let course: any = find(propEq("id", courseId))(state.data.results);
      if (!course) {
        runNotice("error", i18n.t("Произошла какая-то ошибка"));
        return;
      }
      course = { ...course.getUpdateModel(), quest_course: null };

      try {
        const data = course;
        const response = await Vue.axios.patch(
          `/leadership_quests/${courseId}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          // commit("DELETE_COURSE", courseId);
          const { programId } = router.currentRoute.params;
          dispatch("fetch", { programId });
          runNotice("success", i18n.t("Список квестов обновлен"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async fetch({ commit }, { programId }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get(
          `/leadership_quests/?page_size=100&quest_course=${programId}`
        );

        if (response.status === 200) {
          commit("SET_DATA", transformResponseData(response.data));
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
