<template>
  <input
    @input="changeInput"
    @keypress="getInputValid"
    :id="item.id"
    :type="type"
    :name="contactWay"
    :value="value"
    :placeholder="placeholder"
  />
</template>

<script>
export default {
  name: "ContactsProfileInfoAddInputInner",

  props: {
    item: {
      type: Object,
      required: true,
    },

    contactWay: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },

    placeholder: String,
  },

  data() {
    return {
      validKeys: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+"],
      copyPasteKeys: ["a", "z", "x", "c", "v"],
    };
  },

  computed: {
    value() {
      if (this.item.value) {
        return this.item.value;
      } else if (this.item.link) {
        return this.item.link;
      }
      return "";
    },
  },

  methods: {
    changeInput(e) {
      const value = e.target.value;
      this.$emit("input", { name: this.contactWay, id: Number(e.target.id), value });
    },

    getInputValid(e) {
      if (this.contactWay === "phones_objects") {
        if (!this.validKeys.includes(e.key) && !(this.copyPasteKeys.includes(e.key) && (e.ctrlKey || e.metaKey))) {
          e.preventDefault();
        }

        const inputValueArray = this.value.split('');

        if (e.key === "+" && inputValueArray.filter(el => el === "+").length > 0) {
          e.preventDefault();
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped></style>
