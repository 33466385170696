<template>
  <fieldset class="goodQuest__fieldset">
    <div :class="{ label_box_alright: false }" class="label_box">
      <div class="goodQuest__icon" />
      <label>
        <Tr>Код видео или ссылка</Tr> (Youtube <Tr>или</Tr> Vimeo)
        <input :value="link" @input="handleInput" type="text" />
      </label>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LeadershipVideoLink",
  components: {},
  props: {
    link: String,
    name: String,
  },
  computed: {
    ...mapGetters({
      lang: "leadershipTask/activeLang",
    }),
  },

  methods: {
    handleInput(e) {
      this.$emit(`change`, e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.label_box {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  grid-template-columns: min-content;
  margin-top: 12px;
  width: 276px;

  @media (min-width: 709px) {
    width: 604px;
  }
}

.label_box label {
  font_small();
  letter-spacing: -0.17px;
}

.label_box select {
  display: block;
  margin-top: 4px;
  padding: 4px 4px;
  padding-left: 22px;
  width: 160px;
  border: 2px solid #ebe6e7;
  border-radius: 2px;
  font_bold();
}

.label_box input {
  display: block;
  margin-top: 4px;
  padding: 5px 6px;
  width: 280px;
  border: 2px solid #ebe6e7;
  border-radius: 2px;
  font_regular();

  @media (min-width: 709px) {
    width: 400px;
  }
}

.goodQuest {
  position: relative;

  &__icon {
    position: absolute;
    bottom: 3px;
    left: 410px;
  }
}

.goodQuest__fieldsetTask {
  padding-bottom: 0px;
}

.goodQuest__fieldsetTask textarea {
  padding-top: 5px;
  height: 128px;
}

.goodQuest__fieldsetTask .goodQuest__labelText {
  letter-spacing: -0.1px;
}
</style>
