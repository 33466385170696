var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actionItem)?_c('div',[_c('span',{staticClass:"tableItem__time"},[_vm._v(" "+_vm._s(_vm.dateFormatted(_vm.actionItem.created_at))+" ")]),_c('div',{staticClass:"tableItem__box"},[_c('div',{staticClass:"tableItem__title"},[_c('base-icon-svg',{staticClass:"tableItem__icon",attrs:{"width":"16","height":"16","viewbox":"0 0 16 16"}},[(_vm.actionItem.channel === 'email')?[(_vm.actionItem.statistics && _vm.actionItem.statistics.open_count > 0)?_c('notification-email-opened',{class:[
              'tableItem__iconInner',
              {
                tableItem__iconInner_contact: _vm.actionItem.type === 'contact',
              } ]}):_c('notification-email',{class:[
              'tableItem__iconInner',
              {
                tableItem__iconInner_contact: _vm.actionItem.type === 'contact',
              } ]})]:_vm._e(),(_vm.actionItem.channel === 'sms')?_c('notification-sms',{class:[
            'tableItem__iconInner',
            {
              tableItem__iconInner_contact: _vm.actionItem.type === 'contact',
            } ]}):_vm._e(),(_vm.actionItem.channel === 'autocall')?_c('notification-call',{class:[
            'tableItem__iconInner',
            {
              tableItem__iconInner_contact: _vm.actionItem.type === 'contact',
            } ]}):_vm._e()],2),(_vm.actionItem.type === 'flow' && _vm.actionItem.channel !== 'autocall')?[_c('Tr',[_vm._v("Автоматическое сообщение")])]:_vm._e(),(_vm.actionItem.type === 'flow' && _vm.actionItem.channel === 'autocall')?[_c('Tr',[_vm._v("Автозвонок")])]:_vm._e(),(_vm.actionItem.type === 'contact')?[_c('Tr',[_vm._v("Ручное сообщение.")])]:_vm._e()],2),_c('div',{staticClass:"tableItem__text"},[(_vm.actionItem.parameters.subject)?[_vm._v(" "+_vm._s(_vm.actionItem.parameters.subject)+" ")]:_vm._e(),(_vm.actionItem.parameters.message)?[_vm._v(" "+_vm._s(_vm.actionItem.parameters.message)+" ")]:_vm._e(),(_vm.actionItem.parameters.number)?_c('div',[_vm._v(" "+_vm._s(_vm.actionItem.parameters.number)+" ")]):_vm._e(),(_vm.actionItem.statistics)?[(_vm.actionItem.statistics.status === 'completed')?[_c('Tr',[_vm._v("Успешный звонок")])]:_vm._e(),(_vm.actionItem.statistics.duration)?[_c('div',[_c('Tr',[_vm._v("Продолжительность:")]),_vm._v(" "+_vm._s(_vm.time)+" ")],1)]:_vm._e()]:_vm._e(),(
          _vm.actionItem.channel === 'autocall' &&
          _vm.actionItem.statistics &&
          _vm.actionItem.statistics.status &&
          _vm.actionItem.statistics.status !== 'completed'
        )?[_vm._v(" "+_vm._s(_vm.actionItem.statistics.status)+" ")]:_vm._e()],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }