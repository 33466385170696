<template>
  <main class="main">
    <base-breadcrumbs
      :breadcrumbs="breadcrumbs"
      class="contactsProfileBreadcrumbs"
    />
    <h1 class="title titleIsHided">
      <Tr>Объединение</Tr>
    </h1>
    <merge-contacts-container />
  </main>
</template>

<script>
import MergeContactsContainer from "@/containers/MergeContacts/MergeContactsContainer.vue";

export default {
  name: "MergeContacts",
  components: {
    MergeContactsContainer,
  },

  data() {
    return {
      breadcrumbs: [{ title: "Контакты", link: "/contacts" }, { title: "Объединение", link: "/contacts-groups" }],
    };
  },
};
</script>

<style lang="stylus" scoped>
.main {
  @media (min-width: 768px) {
    width: auto;
  }
}

.titleIsHided {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
</style>
