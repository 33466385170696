var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fieldset',{staticClass:"contactsFilter__fieldset"},[_c('legend',{staticClass:"contactsFilter__legend"},[_c('Tr',[_vm._v("Подогретость")])],1),_c('div',{staticClass:"contactsFilter__fieldsetBox"},_vm._l((_vm.contactHeatArray),function(therm){return _c('label',{key:therm.param,staticClass:"contactsFilter__label"},[_c('input',{staticClass:"contactsFilter__inputCheckbox",attrs:{"name":"heat","type":"radio"},domProps:{"checked":_vm.isFilterChecked('heat', therm.param),"value":therm.param},on:{"change":_vm.change}}),_c('span',{class:[
            'contactsFilter__fakeCheckbox',
            {
              contactsFilter__fakeCheckbox_checked: _vm.isFilterChecked(
                'heat',
                therm.param
              ),
            } ]}),(therm.param === 'unmarked')?_c('span',{staticClass:"contactsFilter__labelTxt"},[_vm._v(" "+_vm._s(therm.verbose)+" ")]):_c('base-icon-svg',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(therm.verbose),expression:"therm.verbose"}],staticClass:"contactsFilter__img",attrs:{"width":"120","height":"20","viewbox":"0 0 120 20"}},[(therm.param === 'cold')?_c('ContactsHeatCold'):(therm.param === 'warm')?_c('ContactsHeatWarm'):(therm.param === 'hot')?_c('ContactsHeatHot'):_vm._e()],1)],1)}),0)]),_c('fieldset',{staticClass:"contactsFilter__fieldset"},[_c('legend',{staticClass:"contactsFilter__legend"},[_c('Tr',[_vm._v("Топ")])],1),_c('div',{staticClass:"contactsFilter__fieldsetBox"},_vm._l((_vm.contactTopArray),function(top){return _c('label',{key:top.param,staticClass:"contactsFilter__label"},[_c('input',{staticClass:"contactsFilter__inputCheckbox",attrs:{"name":"top","type":"radio"},domProps:{"checked":_vm.isFilterChecked('top', top.param),"value":top.param},on:{"change":_vm.change}}),_c('span',{class:[
            'contactsFilter__fakeCheckbox',
            {
              contactsFilter__fakeCheckbox_checked: _vm.isFilterChecked(
                'top',
                top.param
              ),
            } ]}),_c('span',{staticClass:"contactsFilter__labelTxt",class:[
            { contactsFilter__tops: top.param !== 'unmarked' },
            ("contactsFilter__" + (top.param)) ]},[(top.param === '100')?[_c('Tr',[_vm._v("ТОП 100")])]:(top.param === 'unmarked')?[_vm._v(" "+_vm._s(top.verbose)+" ")]:[_c('Tr',[_vm._v("ТОП 20")])]],2)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }