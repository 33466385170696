var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'headerInformation_refLink',
    { headerInformation_refLink_mobile: _vm.mobile } ]},[_c('h3',{class:[
      'headerInformation__title',
      { headerInformation__title_mobile: _vm.mobile } ]},[_c('Tr',[_vm._v("Реф. ссылка")])],1),_c('span',{class:[
      'headerInformation__txt',
      { headerInformation__txt_mobile: _vm.mobile } ]},[_vm._v(_vm._s(_vm.userRef))]),_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.copy),expression:"copy"}],class:[
      'headerInformation__button',
      { headerInformation__button_mobile: _vm.mobile } ]},[_c('Tr',[_vm._v("Скопировать")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }