import Vue from "vue";
import { Device } from "@twilio/voice-sdk";
import { runNotice } from "@/utils/notifications";
import { isEmpty, pathOr } from "ramda";

async function getConnectedDevices(type) {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(device => device.kind === type);
}

let intervalInst;

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,

    device: {},
    call: {},

    inputDevices: [],
    outputDevices: [],

    activeInputValue: "default",
    activeOutputValue: "default",

    user: {},

    callStatus: "no_call", // no_call || pending || disconnected || rejected
    minimizedMode: false,

    timer: 0,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    callStatus(state) {
      return state.callStatus;
    },
    userName(state) {
      return pathOr("", ["user", "trigger", "name"], state);
    },
    userAvatar(state) {
      return pathOr("", ["user", "get_avatar_url"], state);
    },
    userId(state) {
      return pathOr(0, ["user", "id"], state);
    },
    inputDevices(state) {
      return state.inputDevices;
    },
    outputDevices(state) {
      return state.outputDevices;
    },
    activeInput(state) {
      return state.activeInputValue;
    },
    activeOutput(state) {
      return state.activeOutputValue;
    },
    minimizedMode(state) {
      return state.minimizedMode;
    },
    timer(state) {
      return state.timer;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    INIT_DEVICE(state, token) {
      state.device = new Device(token);
      state.device.on("error", function(error) {
        runNotice("error", error.message);
        console.log("device error: " + error.message);
      });
    },

    CHANGE_CALL_STATUS(state, status) {
      state.callStatus = status;
    },

    DISCONNECT(state) {
      if (!isEmpty(state.device)) {
        state.device.disconnectAll();
      }
      state.user = {};
    },

    UPDATE_AUDIO_DEVICES_LIST(state, { list, type }) {
      state[type] = list.map(({ label, deviceId }) => ({
        label,
        value: deviceId,
      }));
    },

    CHANGE_AUDIO_DEVICE(state, { type, value }) {
      if (type === "input") {
        state.device.audio.ringtoneDevices.set(value);
        state.activeInputValue = value;
      }
      if (type === "output") {
        state.device.audio.speakerDevices.set(value);
        state.activeOutputValue = value;
      }
    },
    TOGGLE_MIN_MODE(state, isMin) {
      state.minimizedMode = isMin;
    },
    CHANGE_TIMER(state) {
      state.timer = ++state.timer;
    },
    STOP_TIMER(state) {
      state.timer = 0;
    },
  },

  actions: {
    initDevice({ dispatch, commit }) {
      Promise.all([dispatch("fetchToken")]).then(
        responses => {
          if (responses && responses[0]) {
            commit("INIT_DEVICE", responses[0].token);
            dispatch("initAudioDevices");
          }
        },
        reject => {
          console.log(reject);
        }
      );
    },

    changeAudioDevice({ commit }, data) {
      commit("CHANGE_AUDIO_DEVICE", data);
    },

    async initAudioDevices({ state, commit }) {
      const inputDevices = await getConnectedDevices("audioinput");
      const outputDevices = await getConnectedDevices("audiooutput");

      commit("UPDATE_AUDIO_DEVICES_LIST", {
        list: inputDevices,
        type: "inputDevices",
      });
      commit("UPDATE_AUDIO_DEVICES_LIST", {
        list: outputDevices,
        type: "outputDevices",
      });

      navigator.mediaDevices.addEventListener("devicechange", async event => {
        const newInputDevices = await getConnectedDevices("audioinput");
        const newOutputDevices = await getConnectedDevices("audiooutput");

        commit("UPDATE_AUDIO_DEVICES_LIST", {
          list: newInputDevices,
          type: "inputDevices",
        });
        commit("UPDATE_AUDIO_DEVICES_LIST", {
          list: newOutputDevices,
          type: "outputDevices",
        });
      });

      return true;
    },

    async startCall({ state, commit, dispatch, rootGetters }, { lead, phone }) {
      if (state.callStatus === "pending") {
        return;
      }
      commit("SET_USER", lead);

      const userId = rootGetters["user/userId"];

      dispatch("connect", {
        params: {
          phoneNumber: phone,
          userId: userId,
          leadId: lead?.id,
          leadName: lead?.trigger?.name,
        },
        debug: true,
      });
    },

    async endCall({ state, commit }) {
      commit("DISCONNECT");
    },

    async connect({ state, commit, dispatch }, params) {
      try {
        // state.call = await state.device.connect(params);
        const call = await state.device.connect(params);

        call.addListener("ringing", () => {
          commit("CHANGE_CALL_STATUS", "pending");
          intervalInst = setInterval(() => {
            commit("CHANGE_TIMER");
          }, 1000);
        });

        call.addListener("rejected", () => {
          commit("CHANGE_CALL_STATUS", "rejected");
        });

        call.addListener("disconnect", () => {
          clearInterval(intervalInst);
          // dispatch("leads/updateLead", { leadId: state.user.id }, { root: true });
          // dispatch(
          //   "leadsCalls/updateListAfterCall",
          //   { leadId: state.user.id },
          //   { root: true }
          // );
          commit("CHANGE_CALL_STATUS", "disconnected");
          commit("TOGGLE_MIN_MODE", false);
          commit("STOP_TIMER");
          Vue.prototype.$modal.hide("telephony-modal");
        });
      } catch (e) {
        console.log(e);
      }
    },

    async fetchToken({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/telephony/leads/token/`);

        if (response.status === 200) {
          commit("SET_PENDING", false);
          return response.data;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
