<template>
  <div class="accountInfo" :class="{ accountInfo_active: accountInfoOpen }">
    <bonuses
      v-if="showBnLink && showRewards"
      :gold="gold"
      :diamonds="brilliants"
      :positionIsHeader="true"
    />
    <button @click="show" class="accountInfo__button">
      <img class="accountInfo__avatar" :src="avatar" />
      <img
        class="subscriptionIcon"
        src="@/assets/svg/flower.svg"
        alt=""
        v-if="hasSubscription"
      />
    </button>
    <sub-menu
      v-show="submenuShow"
      :company="usersCompanySlug"
      v-click-outside="hide"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import SubMenu from "@/components/Sidebar/SubMenu.vue";
import ClickOutside from "vue-click-outside";
import Bonuses from "@/components/Bonuses.vue";

export default {
  name: "HeaderAccountInfo",
  components: {
    SubMenu,
    Bonuses,
  },
  data() {
    return {
      submenuShow: false,
    };
  },
  props: { accountInfoOpen: Boolean },
  computed: {
    ...mapGetters({
      avatar: "user/avatar",
      brilliants: "user/brilliants",
      gold: "user/gold",
      bonuses: "user/bonuses",
      showBnLink: "user/showBnLink",
      hasSubscription: "user/hasSubscription",
      usersCompanySlug: "user/usersCompanySlug",
      showRewards: "user/showRewards",
    }),
  },
  methods: {
    show() {
      this.submenuShow = !this.submenuShow;
    },
    hide() {
      this.submenuShow = false;
    },
  },
  mounted() {
    this.popupItem = this.$el;
  },

  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus" scoped>
.accountInfo {
  position: absolute;
  z-index: 1;
  right: 8px;
  bottom: -46px;
  padding: 8px 0px;
  display: none;
  align-items: center;
  padding: 0;

  &_active {
    display: flex;
  }

  @media (min-width: desktop) {
    position: static;
    display: flex;
    margin: -2px -8px -2px 0;
  }
}

.accountInfo__diamonds img {
  width: 16px;
  height: 13px;
  margin-right: -5px;
}

.accountInfo__money img {
  width: 12px;
  height: 16px;
  display: inline-block;
  margin-right: -2px;
}

.accountInfo__bonus img {
  width: 14px;
  height: 14px;
  margin-right: -4px;
}

.accountInfo__points {
  display: inline-block;
  margin-right: 8px;
  text-align: right;

  @media (min-width: desktop) {
    display: block;
    margin-right: 0px;
  }
}

.accountInfo__points + .accountInfo__points {
  margin-left: 9px;
  margin-top: 4px;
}

.accountInfo__points span {
  margin-right: 4px;
  color: #0f1642;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;

  @media (min-width: desktop) {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
  }
}

.accountInfo__button {
  position: relative;
  margin-left: 8px;
  padding: 0;
  background: none;
  border: none;

  @media (min-width: desktop) {
    margin-left: 13px;
  }
}

.accountInfo__avatar {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 2px solid transparent;
  box-sizing: content-box;

  @media (min-width: desktop) {
    width: 40px;
    height: 40px;
  }
}

.accountInfo__avatar:hover {
  border: 2px solid #ff9f43;
}

.accountInfo__avatar--active {
  border: 2px solid #ff9f43;
}

.subscriptionIcon {
  position: absolute;
  bottom: -2px;
  right: -2px;
  border: 3px solid main_text_color;
  border-radius: 50%;
}
</style>
