<template>
  <div class="tasks">
    <tasks-menu
      v-if="allTasks.length && allTasks.length > 0 && hasSubscription"
      class="tasks__menu"
      :tabs="tasksTabs"
      section="tasks"
    />
    <slot />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import TasksMenu from "@/components/Tasks/TasksMenu.vue";

export default {
  name: "TasksContainer",
  components: {
    TasksMenu,
  },
  computed: {
    ...mapGetters({
      allTasks: "leadTasks/allTasks",
      tasksTabs: "leadTasks/tasksTabs",
      hasSubscription: "user/hasSubscription",
    }),
  },
  mounted() {
    this.$store.dispatch("leadTasks/fetchAll", { page: 1 });
  },
};
</script>

<style lang="stylus" scoped>
.tasks {
  &__menu {
    margin-bottom: 24px;
  }
}
</style>
