import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";
import VModal from "vue-js-modal";
import Toasted from "vue-toasted";
import globalComponents from "./plugins/globalComponents";
import VueAxios from "vue-axios";
import instance from "./utils/axiosInstance";
import setAxiosDefaults from "./plugins/setAxiosDefaults";
import VueDraggableResizable from "vue-draggable-resizable";
import Clipboard from "v-clipboard";
import VTooltip from "v-tooltip";
import "./assets/styles/vars.styl";
import Vue2Editor from "vue2-editor";
import VueMask from "v-mask";
import getInitials from "@/utils/getInitials";
import getPhone from "@/utils/getPhone";

Vue.config.productionTip = false;

Vue.use(Vue2Editor);
Vue.use(Clipboard);
Vue.use(VueAxios, instance);
Vue.use(VModal, { dialog: true });
Vue.use(Toasted);
Vue.use(VTooltip);
Vue.use(VueMask);

globalComponents();
setAxiosDefaults();

Vue.component("vue-draggable-resizable", VueDraggableResizable);

Vue.filter("getInitialsFilter", getInitials);
Vue.filter("getPhoneFilter", getPhone);

// maxlen-directive for input limit
const handlers = new Map();

function updateHandler(el, binding) {
  el.removeEventListener("input", handlers.get(el));

  const handler = () => {
    const value = el.value.replace(/\D/g, "").slice(0, binding.value);
    if (value !== el.value) {
      el.value = value;
      el.dispatchEvent(new Event("input"));
    }
  };
  handler();
  handlers.set(el, handler);

  el.addEventListener("input", handler);
}

Vue.directive("maxlen", {
  bind: updateHandler,
  update: updateHandler,
  unbind(el) {
    el.removeEventListener("input", handlers.get(el));
    handlers.delete(el);
  },
});
// maxlen-directive for input limit

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app");
