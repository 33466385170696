<template>
  <div class="tasksMenu">
    <ul class="tasksMenuInner">
      <li v-for="tab in tabs" :key="tab.value" class="tasksItem">
        <base-link :to="`/${section}/${tab.value}`" class="tasksItemLink">
          {{ $t(tab.name) }}
        </base-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TasksMenu",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.tasksMenu {
  overflow-x: auto;
  max-width: calc(100vw - 16px);
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  @media (min-width: tablet) {
    overflow-x: visible;
    max-width: unset;
  }

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e7eb;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}

.tasksMenuInner {
  display: flex;
  align-items: center;
}

.tasksItem {
  display: flex;
  font(main_text_color, 14px, 400, 20px);
  transition: all 0.2s;

  &:first-of-type .tasksItemLink {
    border-radius: 4px 0 0 4px;
  }

  &:last-of-type .tasksItemLink {
    border-radius: 0 4px 4px 0;
  }
}

.tasksItemLink {
  padding: 3px 11px;
  background-color: grey_text_color;

  &:hover {
    background-color: orange;
    color: #fff;
    cursor: pointer;
  }
}

.router-link-active {
  background-color: orange;
  color: #fff;
}
</style>
