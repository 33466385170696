<template>
  <div class="funnelBodyUnpaid">
    <div class="funnelBodyUnpaid__message">
      <div class="funnelBodyUnpaid__messageInner">
        <Tr>И ещё</Tr>
        <span
          class="funnelBodyUnpaid__messageProp funnelBodyUnpaid__messageBold"
          >{{ unpaidLeads }}</span
        >
        <span class="funnelBodyUnpaid__messageBold">{{ declension }}</span>
        .
      </div>
      <span class="funnelBodyUnpaid__messageMl">
        <Tr>Чтобы увидеть всех лидов</Tr>
      </span>
      ,
      <a
        class="funnelBodyUnpaid__messageLink funnelBodyUnpaid__messageMl"
        :href="outHref"
      >
        <Tr>оплатите тариф</Tr>
      </a>
      .
    </div>

    <div class="funnelBodyUnpaid__layout">
      <div
        class="funnelBodyUnpaid__layoutItem"
        v-for="(n, index) in unpaidLeads"
        :key="index"
      >
        <div
          class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemAvatar"
        >
          <div class="funnelBodyUnpaid__layoutItemBox" />
        </div>
        <div class="funnelBodyUnpaid__layoutItemInner">
          <div
            class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemName"
          >
            <div class="funnelBodyUnpaid__layoutItemBox" />
          </div>
          <div
            class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemLink"
          >
            <div class="funnelBodyUnpaid__layoutItemBox" />
          </div>
          <div
            class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemLanding"
          >
            <div class="funnelBodyUnpaid__layoutItemBox" />
          </div>
          <div
            class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemChatbot"
          >
            <div class="funnelBodyUnpaid__layoutItemBox" />
          </div>
          <div
            class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemAutowebinar"
          >
            <div class="funnelBodyUnpaid__layoutItemBox" />
          </div>
        </div>
        <div
          class="funnelBodyUnpaid__layoutItemCell funnelBodyUnpaid__layoutItemLogin"
        >
          <div class="funnelBodyUnpaid__layoutItemBox" />
          <div class="funnelBodyUnpaid__layoutItemBox" />
          <div class="funnelBodyUnpaid__layoutItemBox" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import declension from "@/utils/declension";

export default {
  name: "FunnelBodyUnpaid",
  data() {
    return {};
  },
  props: {
    unpaidLeads: {
      type: Number,
    },
  },
  computed: {
    declension() {
      return declension("лид", "лидов", "лида", this.unpaidLeads);
    },
    outHref() {
      const base = this.$store.getters["user/mainSiteLink"];
      return base + "/profile/tariffs/";
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnelBodyUnpaid {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.funnelBodyUnpaid__message {
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  width: fit-content;
  border-radius: 2px;
  background-color: grey_text_color;
}

.funnelBodyUnpaid__messageInner {
  margin-left: 5px;
  width: 100%;
}

.funnelBodyUnpaid__messageProp {
  margin: 0 5px;
}

.funnelBodyUnpaid__messageBold {
  font-weight: 700;
}

.funnelBodyUnpaid__messageMl {
  margin-left: 5px;
}

.funnelBodyUnpaid__messageLink {
  color: #007bff;
  text-decoration: underline;
}

.funnelBodyUnpaid__layout {
  display: flex;
  flex-wrap: wrap;
}

.funnelBodyUnpaid__layoutItem {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 8px;
  align-items: self-start;
  margin-top: 28px;
  margin-right: 30px;
  width: fit-content;

  &:last-of-type {
    margin-bottom: 30px;
  }

  &Avatar {
    width: 24px;

    & .funnelBodyUnpaid__layoutItemBox {
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  &Name, &Link {
    width: 260px;
  }

  &Name .funnelBodyUnpaid__layoutItemBox, &Link .funnelBodyUnpaid__layoutItemBox {
    width: 131px;
    height: 8px;
  }

  &Landing, &Chatbot, &Autowebinar {
    display: flex;
    justify-content: flex-end;
    width: 131px;
  }

  &Login {
    position: absolute;
    left: 147px;
    bottom: -26px;
    display: flex;
    justify-content: space-between;
    width: 66px;

    & .funnelBodyUnpaid__layoutItemBox {
      border-radius: 50%;
    }
  }

  &Landing .funnelBodyUnpaid__layoutItemBox, &Chatbot .funnelBodyUnpaid__layoutItemBox, &Autowebinar .funnelBodyUnpaid__layoutItemBox, &Login .funnelBodyUnpaid__layoutItemBox {
    width: 16px;
    height: 16px;
  }
}

.funnelBodyUnpaid__layoutItemCell {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
  min-height: 24px;
}

.funnelBodyUnpaid__layoutItemBox {
  background-color: grey_text_color;
}

@media (min-width: desktop) {
  .funnelBodyUnpaid__messageInner {
    margin-left: 0;
    width: auto;
  }

  .funnelBodyUnpaid__layoutItem {
    grid-auto-flow: column;
    align-items: center;
    margin-top: 8px;
    margin-right: 0;

    &:last-of-type {
      margin-bottom: 0;
    }

    &Name {
      width: 161px;
    }

    &Link {
      width: 177px;
    }

    &Landing {
      width: 83px;
    }

    &Chatbot {
      width: 56px;
    }

    &Autowebinar {
      width: 186px;
    }

    &Landing, &Chatbot, &Autowebinar {
      justify-content: flex-start;
    }
  }

  .funnelBodyUnpaid__layoutItemInner {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
  }

  .funnelBodyUnpaid__layoutItemCell {
    margin-bottom: 0;
    min-height: unset;
  }

  .funnelBodyUnpaid__layoutItemLogin {
    position: static;
    justify-content: space-between;
  }
}
</style>
