import { pathOr } from "ramda";
import Vue from "vue";
import axiosConfig from "@/utils/axiosConfigFunnel";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    tasqParametres: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    changedStatus(state) {
      return state.data;
    },
    errors(state) {
      return state.errors;
    },
    status(state) {
      return pathOr(0, ["data", "status"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_TASQ_PARAMETRES(state, data) {
      state.tasqParametres = data;
    },
  },

  actions: {
    async fetchSignature({ commit }, status) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(
          `/telephony/leads/v2/signed/?status_id=${status}`
        );

        if (response.status === 200) {
          commit("SET_TASQ_PARAMETRES", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async changeStatus(
      { commit, dispatch, rootGetters, state },
      { leadId, status }
    ) {
      Promise.all([dispatch("fetchSignature", status)]).then(async () => {
        const tasqParametres = { ...state.tasqParametres };
        const filters = tasqParametres.params;

        if (state.pending) {
          return;
        }

        try {
          commit("SET_PENDING", true);

          const tasqToken = Cookies.get("auth");

          const response = await Vue.axios.post(
            `/leads/${leadId}/status/?${filters}`,
            { status_id: status },
            axiosConfig(tasqToken)
          );

          if (response.status === 200 || response.status === 201) {
            commit("SET_PENDING", false);
            commit("SET_DATA", response.data.data.status);

            return "response";
          } else {
            commit("SET_ERRORS", { error: "error" });
            // throw response.data.message;
            throw response;
          }
        } catch (e) {
          commit("SET_ERRORS", { error: "error" });

          commit("SET_PENDING", false);
        }
      });
    },
  },
};
