<template>
  <div>
    <base-breadcrumbs
      :breadcrumbs="breadcrumbs"
      class="breadcrumbsContactsProfile"
    />
    <h1 class="title funnel__linkListTitle">
      <Tr>Список ссылок</Tr>
    </h1>
    <div class="createLinkButtonsGroup">
      <base-link
        class="createLinkButton createLinkButton_createLink"
        to="/funnel/create-link"
      >
        <Tr>Создать ссылку</Tr>
      </base-link>
      <base-link
        v-if="!isTasq && false"
        class="createLinkButton createLinkButton_createLink"
        to="/funnel/create-link-without-chatbot"
      >
        <Tr>Ссылка на вебинар</Tr>
      </base-link>
    </div>

    <div class="funnel__linkListContainer">
      <funnel-link-list-head :updatedFunnel="true" />

      <div class="funnel__linkListTbody">
        <funnel-link-list-item
          :updatedFunnel="true"
          :link="item"
          v-for="item in linksList"
          :key="item.id"
        />
      </div>
      <div class="loader__layout" v-if="pending">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseBreadcrumbs from "@/components/common/BaseBreadcrumbs.vue";
import FunnelLinkListHead from "@/components/Funnel/FunnelLinkListHead.vue";
import FunnelLinkListItem from "@/components/Funnel/FunnelLinkListItem.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "UpdatedFunnelLinksListContainer",
  components: {
    BaseBreadcrumbs,
    FunnelLinkListItem,
    FunnelLinkListHead,
    Loader,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Воронка", link: "/funnel" }],
      funnel: {
        landing: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      linksList: "updatedFunnelLinksList/linksList",
      currentPage: "updatedFunnelLinksList/currentPage",
      hasMorePages: "updatedFunnelLinksList/hasMorePages",
      pending: "updatedFunnelLinksList/pending",
      company: "user/usersCompanySlug",
    }),
    isTasq() {
      return /^(tasq)?$/.test(this.company);
    },
  },
  mounted() {
    this.$store.dispatch("updatedFunnelLinksList/fetch");
  },
  methods: {
    showMore() {
      this.$store.dispatch("updatedFunnelLinksList/fetch", this.currentPage + 1);
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnel__linkListContainer {
  position: relative;
  width: fit-content;
}

.funnel__linkListTitle {
  margin-bottom: 16px;
}

.funnel__linkListTheadCell {
  text-align: left;

  &:first-child {
    padding-left: 4px;
  }

  &:nth-child(1) {
    min-width: 140px;
  }

  &:nth-child(2) {
    min-width: 147px;
  }

  &:nth-child(3) {
    min-width: 297px;
  }

  &:nth-child(4) {
    min-width: 52px;
  }
}

.funnel__linkListTheadCellText {
  font_bold();

  &_bordered {
    display: inline-block;
    border-bottom: 2px dotted #0F164260;
  }
}

.funnel__linkListTbodyRow {
  &_active {
    background-color: grey_text_color;
  }
}

.funnel__linkListTbodyCell {
  padding: 3px 0;

  &:first-child {
    padding-left: 4px;
  }
}

.funnel__linkListTbodyCellText {
  display: inline-block;
  font_regular();
}

.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();
}

.loader__layout {
  background: #fff;
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.createLink {
  &Button {
    position: relative;
    margin-bottom: 10px;
    background-color: unset;
    border: none;
    border-radius: 4px;
    font_regular();
    text-decoration: none;

    &_createLink {
      display: block;
      width: fit-content;
      margin-right: 10px;
      padding: 7px 8px 7px 28px;
      border: 1px dashed main_text_color;

      &::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.createLinkButtonsGroup {
  margin-bottom: 20px;
}

@media (min-width: desktop) {
  .createLink {
    &Button {
      &_createLink {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
}
</style>
