<template>
  <aside class="aside" :class="{ aside_active: asideOpen }">
    <button type="button" class="aside__arrowBtn" @click="menuClose">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <g>
          <g>
            <path
              fill="#0f1642"
              d="M3.828 7H16v2H3.828l5.364 5.364-1.414 1.414L0 8 7.778.222l1.414 1.414z"
            />
          </g>
        </g>
      </svg>
    </button>
    <sidebar-menu />
    <!-- <sub-menu /> -->
    <sidebar-menu-mentor v-if="usersCompanySlug !== 'tasq'" />
  </aside>
</template>

<script>
import SidebarMenu from "@/components/Sidebar/SidebarMenu.vue";
// import SubMenu from "@/components/Sidebar/SubMenu.vue";
import SidebarMenuMentor from "@/components/Sidebar/SidebarMenuMentor.vue";
import { sidebarHandler } from "@/components/common/SidebarHandler.js";
import { mapGetters } from "vuex";

export default {
  name: "TheSidebar",
  data() {
    return {
      color: false,
    };
  },
  props: {
    asideOpen: Boolean,
  },
  components: {
    SidebarMenu,
    // SubMenu,
    SidebarMenuMentor,
  },
  computed: {
    ...mapGetters({
      usersCompanySlug: "user/usersCompanySlug",
    }),
  },
  methods: {
    menuClose() {
      sidebarHandler.$emit("aside-menu-handler");
    },
  },
};
</script>

<style lang="stylus" scoped>
.aside {
  position: absolute;
  z-index: 100;
  top: 0;
  right: 0;
  display: none;
  flex-direction: column;
  padding-top: 92px;
  padding-bottom: 20px;
  width: 240px;
  min-height: 100vh;
  background-color: #fff;
  box-shadow: 0 0 24px rgba(15, 22, 66, 0.08);

  &_active {
    display: flex;
  }

  @media (min-width: desktop) {
    bottom: 0;
    right: unset;
    display: block;
    padding-top: 84px;
    padding-left: 16px;
    min-height: unset;

    &_active {
      right: unset;
    }
  }

  &__arrowBtn {
    position: absolute;
    top: 50px;
    left: 4px;
    display: block;
    padding: 20px;
    width: 16px;
    height: 16px;
    background-color: unset;
    border: none;

    @media (min-width: desktop) {
      display: none;
    }

    & svg {
      position: relative;
      top: -8px;
      left: -8px;
    }
  }
}
</style>
