<template>
  <v-popover
    offset="0"
    trigger="hover"
    placement="top"
    class="updatedFunnelBodyItem__cellNotification"
    v-if="actionItem"
  >
    <!-- This will be the popover target (for the events and position) -->
    <span class="updatedFunnelBodyItem__notificationTrigger tooltip-target b3">
      <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
        <template v-if="actionItem.channel === 'email'">
          <notification-email-opened
            v-if="actionItem.statistics && actionItem.statistics.open_count > 0"
            :class="[
              'updatedFunnelBodyItem__notificationIcon',
              {
                updatedFunnelBodyItem__notificationIcon_contact:
                  actionItem.type === 'contact',
              },
            ]"
          />
          <notification-email
            v-else
            :class="[
              'updatedFunnelBodyItem__notificationIcon',
              {
                updatedFunnelBodyItem__notificationIcon_contact:
                  actionItem.type === 'contact',
              },
            ]"
          />
        </template>
        <notification-sms
          v-if="actionItem.channel === 'sms'"
          :class="[
            'updatedFunnelBodyItem__notificationIcon',
            {
              updatedFunnelBodyItem__notificationIcon_contact:
                actionItem.type === 'contact',
            },
          ]"
        />
        <notification-call
          v-if="actionItem.channel === 'autocall'"
          :class="[
            'updatedFunnelBodyItem__notificationIcon',
            {
              updatedFunnelBodyItem__notificationIcon_contact:
                actionItem.type === 'contact',
            },
          ]"
        />
      </base-icon-svg>
    </span>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="notificationPopover">
        <p class="tooltip-title" v-if="actionItem.parameters.subject">
          {{ actionItem.parameters.subject }}
        </p>
        <span class="tooltip-time">
          {{ dateFormatted(actionItem.created_at) }}
        </span>
        <div
          class="tooltip-text"
          v-if="
            actionItem.statistics &&
            actionItem.statistics.status &&
            actionItem.statistics.status === 'completed'
          "
        >
          <Tr>Успешный звонок</Tr>
        </div>
        <!-- <div
          class="tooltip-text"
          v-if="actionItem.channel === 'autocall' && !actionItem.statistics"
        >
          <Tr>Недозвон</Tr>
        </div> -->
        <template
          v-else-if="
            actionItem.statistics &&
            actionItem.statistics.status &&
            actionItem.statistics.status !== 'completed'
          "
        >
          <div
            class="tooltip-text"
            v-if="
              actionItem.statistics &&
              actionItem.statistics.open_count &&
              actionItem.statistics.open_count > 0
            "
          >
            Opened
          </div>
          <div class="tooltip-text" v-else>
            {{ actionItem.statistics.status }}
          </div>
        </template>
        <div
          class="tooltip-text"
          v-if="actionItem.statistics && actionItem.statistics.duration"
        >
          <Tr>Продолжительность:</Tr>
          {{ time }}
        </div>
        <p
          class="tooltip-text"
          v-html="actionItem.parameters.message"
          v-if="actionItem.parameters.message"
        />
        <p class="tooltip-text" v-if="actionItem.parameters.number">
          {{ actionItem.parameters.number }}
        </p>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import NotificationEmail from "@/components/SvgIcons/funnel/NotificationEmail.vue";
import NotificationEmailOpened from "@/components/SvgIcons/funnel/NotificationEmailOpened.vue";
import NotificationSms from "@/components/SvgIcons/funnel/NotificationSms.vue";
import NotificationCall from "@/components/SvgIcons/funnel/NotificationCall.vue";

export default {
  name: "UpdatedFunnelBodyItemNotification",

  components: {
    NotificationEmail,
    NotificationEmailOpened,
    NotificationSms,
    NotificationCall,
  },

  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
    time() {
      const secToMin = 60;
      if (Number(this.actionItem.statistics.duration) > secToMin) {
        const sec = Number(this.actionItem.statistics.duration) % secToMin;
        const min = (Number(this.actionItem.statistics.duration) - sec) / secToMin;
        return `${min} ${this.$t('мин')} ${sec} ${this.$t('сек')}`
      }
      return `${this.actionItem.statistics.duration} ${this.$t('сек')}`;
    },
  },

  methods: {
    dateFormatted(time) {
      if (time) {
        return moment(time)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  }
};
</script>

<style lang="stylus" scoped>
.updatedFunnelBodyItem__cellNotification {
  display: flex;
  align-items: center;
  height: 24px;
  background-color: grey_text_color;

  .updatedFunnelBodyItem__notificationTrigger {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 24px;
  }
}

.updatedFunnelBodyItem__notificationTrigger {
  padding: 0;
  background: none;
  border: none;
}

.updatedFunnelBodyItem__notificationIcon {
  color: #3489f8;

  &_contact {
    color: main_text_color;
  }
}

.notificationPopover {
  color: main_text_color;
  font_mobile();
  text-align: left;
}

.tooltip-title, .tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
  opacity: 0.6;
}
</style>
