<template>
  <div
    class="baseInputText"
    :style="{ width: inputWidth ? `${inputWidth}px` : '' }"
  >
    <label
      :class="[
        'baseInputText__label',
        'baseInputTextLabel',
        { baseInputTextLabel_bold: labelBold },
      ]"
      :for="labelId"
    >
      <Tr>{{ title }}</Tr>
    </label>
    <input
      :id="labelId"
      v-model="inputValue"
      @keypress="getInputValid"
      :class="['baseInputTextInput', { error: error }]"
      :type="type"
      :placeholder="placeholder"
      :maxlength="250"
    />
  </div>
</template>

<script>
export default {
  name: "BaseInputText",
  props: {
    title: String,

    value: String,

    labelId: String,

    inputWidth: {
      type: Number,
      required: false,
    },

    type: {
      type: String,
      default: "text",
    },

    error: Boolean,

    placeholder: String,

    maxlength: String,

    labelBold: Boolean,
  },

  data() {
    return {
      validKeys: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+"],
      copyPasteKeys: ["a", "z", "x", "c", "v"],
    };
  },

  computed: {
    inputValue: {
      get() {
        if (this.value) {
          return this.value;
        }
        return "";
      },
      set(value) {
        this.$emit("handle-input", value);
      },
    },
  },

  methods: {
    getInputValid(e) {
      if (this.labelId === "whatsapp") {
        if (!this.validKeys.includes(e.key) && !(this.copyPasteKeys.includes(e.key) && (e.ctrlKey || e.metaKey))) {
          e.preventDefault();
        }

        const inputValueArray = this.inputValue.split('');

        if (e.key === "+" && inputValueArray.filter(el => el === "+").length > 0) {
          e.preventDefault();
        }
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.baseInputText {
  width: 100%;
  max-width: 400px;

  @media (min-width: tablet_large) {
    width: 400px;
    max-width: unset;
  }

  &__label {
    margin-bottom: 4px;
  }
}

.baseInputTextLabel {
  display: block;
  font_small();

  &_bold {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
  }
}

.baseInputTextInput {
  display: block;
  padding: 5px 4px 6px 7px;
  width: 100%;
  border: 1px solid #e6e7eb;
  font_regular();
}

.error {
  box-shadow: inset 0px 0px 2px 2px #e81526;
}
</style>
