<template>
  <contacts-profile-reminders-layout
    :reminders="meetings"
    :hasNextPage="hasNextPage"
    :contact="contact"
    :activeTaskTab="activeTaskTab"
    @fetch-more="fetchMore"
  />
</template>

<script>
import { mapGetters } from "vuex";

import ContactsProfileRemindersLayout from "@/components/Contacts/ContactsProfileRemindersLayout.vue";

export default {
  name: "ContactsProfileRemindersActual",
  data() {
    return {};
  },
  components: {
    ContactsProfileRemindersLayout,
  },
  computed: {
    ...mapGetters({
      meetings: "contactsProfileMeetings/meetings",
      hasNextPage: "contactsProfileMeetings/hasNextPage",
      currentPage: "contactsProfileMeetings/currentPage",
      contact: "contactsProfile/contact",
    }),
    activeTaskTab() {
      const pathNamearr = this.$route.path.split("/");
      return pathNamearr[pathNamearr.length - 1];
    },
  },

  methods: {
    fetchMore() {
      this.$store.dispatch("contactsProfileMeetings/fetchMore", {
        page: this.currentPage + 1,
        contact: this.contact.id,
        filter: this.activeTaskTab,
      });
    },
  },

  mounted() {
    Promise.resolve(
      this.$store.dispatch(
        "contactsProfile/fetch",
        this.$route.params.profileId
      )
    )
      .then(() => {
        this.$store.dispatch("contactsProfileMeetings/fetch", {
          page: 1,
          contact: this.contact.id,
          filter: this.activeTaskTab,
        });
      })
      .catch(e => {
        console.log(e);
      });
  },
};
</script>

<style lang="stylus" scoped></style>
