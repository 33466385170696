import axiosConfig from "@/utils/axiosConfigFunnel";
import axios from "axios";
import { pathOr } from "ramda";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    linksList(state) {
      return pathOr([], ["data"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data.data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
  },

  actions: {
    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/funnel-links/`,
          axiosConfig(tasqToken)
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
