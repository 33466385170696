import axiosConfig from "@/utils/axiosConfigFunnel";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";

export default {
  namespaced: true,

  state: () => ({
    webinar_started_at: "",
    criteria: [],
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    linkCreated(state) {
      return state.created;
    },
    startedAt(state) {
      return state.webinar_started_at;
    },
    errors(state) {
      return state.errors;
    },
  },

  mutations: {
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_CRITERIA(state, value) {
      state.criteria = [...value];
    },
    CHANGE_STARTED_AT(state, value) {
      state.webinar_started_at = value;
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    CLEAR_STORE(state) {
      state.webinar_started_at = "";
      state.criteria = [];
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async restartFunnel({ commit, state }, { leadId, funnelId }) {
      let data = {};
      state.criteria.forEach(el => {
        const elValue = () => {
          if (el.type === "datetime") {
            return moment(state.webinar_started_at).format(
              "YYYY-MM-DD HH:mm:ss"
            );
          } else {
            return "";
          }
        };
        data = { ...data, [el.key]: elValue() };
      });

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/leads/${leadId}/funnel/${funnelId}`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 201 || response.status === 200) {
          commit("LINK_CREATED", true);
          commit("CHANGE_STARTED_AT", "");
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
