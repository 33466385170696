<template>
  <div :class="['search', { search_show: updatedFunnel }]">
    <button
      type="button"
      @click="submitByBtn"
      :class="['searchSubmit', { searchSubmit_active: inputSearch.length > 0 }]"
    />

    <input
      class="searchInput"
      :placeholder="$t(title)"
      ref="input"
      :value="inputSearch"
      @input="input"
      @keyup.enter="submit"
    />
    <button @click="clear" class="searchClear" v-if="inputSearch.length > 0" />
  </div>
</template>

<script>
export default {
  name: "UpdatedFunnelMenuFilterSearchInput",
  props: {
    updatedFunnel: Boolean,
    search: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      inputSearch: "",
    };
  },
  methods: {
    clear() {
      this.inputSearch = "";
      this.$emit("change-search", "");
      this.$refs.input.focus();
    },
    input(e) {
      const value = e.target.value;
      if (value.length === 0) {
        this.submitByBtn();
      }
      this.inputSearch = value;
    },
    emitFunction(value) {
      let emitValue = value;
      emitValue = emitValue.replace("+", "");
      emitValue = emitValue.trim();
      this.$emit('change-search', emitValue);
    },
    submit(e) {
      const value = e.target.value;
      this.emitFunction(value);
    },
    submitByBtn() {
      const value = this.$refs.input.value;
      this.emitFunction(value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.search {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  opacity: 0;
  pointer-events: none;

  &_show {
    opacity: 1;
    pointer-events: all;
  }
}

.searchSubmit {
  position: absolute;
  z-index: 2;
  content: '';
  bottom: 7px;
  left: 0px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  background-color: unset;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M14.874,13.739l3.44,3.439-1.136,1.136-3.439-3.44a7.229,7.229,0,1,1,1.136-1.136Zm-1.611-.6a5.619,5.619,0,1,0-.12.12l.12-.12Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: auto;
  pointer-events: none;

  &_active {
    cursor: pointer;
    pointer-events: all;
    border-radius: 2px 0 0 2px;
    box-shadow: inset 0 0 2px 1px rgba(15, 22, 66, 0.5), 0 0 3px 2px rgba(15, 22, 66, 0.5);

    &:hover {
      opacity: 0.6;
    }
  }
}

.searchInput {
  position: relative;
  margin-bottom: 0px;
  padding: 0;
  padding-right: 10px;
  padding-left: 25px;
  width: calc(100vw - 100px);
  max-width: 340px;
  height: 32px;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  color: #0F1642;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;

  @media (min-width: phone_s) {
    font-size: 14px;
    line-height: 18px;
  }
}

.searchInput:focus {
  border-bottom: 4px solid grey_text_color;
}

.searchInput:focus .searchClear {
  display: block;
}

.searchClear {
  position: absolute;
  top: 0;
  bottom: 4px;
  right: 0px;
  margin: auto;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M10.939,9.761l4.125-4.125,1.178,1.178-4.125,4.125,4.125,4.125-1.178,1.178-4.125-4.125L6.814,16.243,5.636,15.064l4.125-4.125L5.636,6.814,6.814,5.636Z' transform='translate(-0.939 -0.939)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
