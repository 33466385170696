import router from "@/router";
import { runNotice } from "@/utils/notifications";
import { pathOr } from "ramda";
import Vue from "vue";
import qs from "qs";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    tasqParametres: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    notes(state) {
      return pathOr([], ["data", "data"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_TASQ_PARAMETRES(state, data) {
      state.tasqParametres = data;
    },
  },

  actions: {
    async fetchParametres({ commit }, { data }) {
      try {
        commit("SET_PENDING", true);

        let response;

        if (data) {
          const encodeData = encodeURIComponent(data.text);
          response = await Vue.axios.get(
            `/telephony/leads/v2/signed/?text=${encodeData}`
          );
        } else {
          response = await Vue.axios.get(`/telephony/leads/v2/signed/`);
        }

        if (response.status === 200) {
          commit("SET_TASQ_PARAMETRES", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async fetch({ commit }, { leadId }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/leads/${leadId}/notes`, {
          baseURL: process.env.VUE_APP_FUNNEL_URL,
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createNote({ commit, state, dispatch }, data) {
      Promise.all([dispatch("fetchParametres", { data })]).then(async () => {
        if (state.pending) {
          return;
        }
        try {
          commit("SET_PENDING", true);

          const tasqParametres = { ...state.tasqParametres };
          const filters = tasqParametres.params;

          const filtersObj = qs.parse(filters);

          const response = await Vue.axios.post(
            `/leads/${data.leadId}/notes/`,
            // `/leads/${data.leadId}/notes/`,
            {
              // text: data.text,
              ...filtersObj,
            },
            { baseURL: process.env.VUE_APP_FUNNEL_URL }
          );

          if (response.status === 201) {
            commit("SET_PENDING", false);
            dispatch("fetch", { leadId: data.leadId });
            return true;
          } else {
            commit("SET_PENDING", false);
            throw response.data.message;
          }
        } catch (e) {
          e.response.data.text.forEach(text => runNotice("error", text));
          commit("SET_PENDING", false);
        }
      });
    },

    async updateNote({ commit, state, dispatch }, data) {
      Promise.all([dispatch("fetchParametres", { data })]).then(async () => {
        if (state.pending) {
          return;
        }
        try {
          commit("SET_PENDING", true);

          const tasqParametres = { ...state.tasqParametres };
          const filters = tasqParametres.params;

          const filtersObj = qs.parse(filters);

          const response = await Vue.axios.put(
            `/leads/${data.leadId}/notes/${data.id}/`,
            // { ...filtersObj },
            {},
            { baseURL: process.env.VUE_APP_FUNNEL_URL }
          );
          dispatch("fetch", { leadId: data.leadId });
        } catch (e) {
          if (e.response.status === 403) {
            runNotice("error", e.response.data.message);
          } else if (e.response.status === 422) {
            e.response.data.errors.text.forEach(text =>
              runNotice("error", text)
            );
          } else {
            runNotice("error", "Server error");
          }
        }
        commit("SET_PENDING", false);
      });
    },

    async deleteNote({ commit, state, dispatch }, { id, leadId, data }) {
      Promise.all([dispatch("fetchParametres", { data })]).then(async () => {
        if (state.pending) {
          return;
        }
        try {
          commit("SET_PENDING", true);

          const tasqParametres = { ...state.tasqParametres };
          const filters = tasqParametres.params;

          const response = await Vue.axios.delete(
            `/leads/${leadId}/notes/${id}/?${filters}`,
            { baseURL: process.env.VUE_APP_FUNNEL_URL }
          );

          if (response.status === 200) {
            commit("SET_PENDING", false);
            dispatch("fetch", { leadId: leadId });
            return true;
          } else {
            commit("SET_PENDING", false);
            throw response.data.message;
          }
        } catch (e) {
          e.response.data.text.forEach(text => runNotice("error", text));
          commit("SET_PENDING", false);
        }
      });
    },
  },
};
