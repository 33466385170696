<template>
  <div class="notes">
    <div class="notes__title" v-if="mode === 'new'" :key="'new-note-title'">
      <Tr>Новая заметка</Tr>
    </div>
    <div class="notes__title" v-else :key="'old-note-title'">
      <Tr>Заметка</Tr>
      <span>{{ formatDate(editingNote.created_at) }}</span>
    </div>
    <div class="notes__form">
      <textarea
        class="notes__textarea"
        v-model="newNote.text"
        :maxLength="MAX_TEXT_LENGTH"
        v-if="mode === 'new'"
      />
      <textarea
        class="notes__textarea"
        v-model="editingNote.text"
        :maxLength="MAX_TEXT_LENGTH"
        v-else
      />
      <div class="notes__btnsBox">
        <FormSubmit class="notes__saveBtn" @click="save" :disabled="!hasText" />
        <button
          class="notes__form_delButton"
          type="submit"
          @click="handleCancel"
          v-if="mode !== 'new'"
        >
          <Tr>Отменить</Tr>
        </button>
      </div>
    </div>
    <template v-if="pendingNotes">
      <loader />
    </template>
    <template v-else>
      <div class="notes__notes">
        <div
          class="notes__notesItem"
          v-for="note in reversedNotes"
          :key="`note-${note.id}`"
        >
          <h3 class="notes__notesItemTitle">
            {{ formatDate(note.created_at) }}
          </h3>
          <p class="notes__notesItemBody">
            {{ note.text }}
          </p>
          <button
            class="notes__notesItemButton notes__notesItemButton_edit"
            @click="handleEdit(note)"
          />
          <button
            class="notes__notesItemButton notes__notesItemButton_del"
            @click="deleteNote(note.id, lead.id)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import FormSubmit from "@/components/FormSubmit.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "UpdatedLeadsModalLeadNotes",
  components: {
    FormSubmit,
    Loader,
  },
  props: {
    notes: { type: Array, required: false },
    lead: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mode: "new",
      newNote: {
        text: "",
      },
      editingNote: {
        text: "",
        lead: 0,
        updated_at: "",
      },
      MAX_TEXT_LENGTH: 2000,
    };
  },
  computed: {
    ...mapGetters({
      lang: "currentLang",
      pendingNotes: "updatedLeadNotes/pending",
    }),
    hasText() {
      if (this.mode === "new") {
        return this.newNote.text.trim() !== "";
      } else {
        return this.editingNote.text.trim() !== "";
      }
    },
    reversedNotes() {
      const notes = [...this.notes];
      return notes.reverse();
    },
  },
  methods: {
    switchMode(mode) {
      this.mode = mode;
    },
    setEdit(note) {
      this.editingNote = { ...note };
    },
    handleEdit(note) {
      this.switchMode("edit");
      this.setEdit(note);
    },
    clearModels() {
      this.newNote = { text: "" };
      this.editingNote = {
        text: "",
        lead: 0,
        created_at: "",
      };
    },
    handleCancel() {
      this.switchMode("new");
      this.clearModels();
    },
    createNote() {
      this.$emit("create-note", this.newNote);
      this.newNote = { text: "" };
    },

    updateNote() {
      this.$emit("update-note", this.editingNote);
      this.switchMode("new");
      this.editingNote = {
        text: "",
        lead: 0,
        created_at: "",
      };
    },

    deleteNote(id, leadId) {
      this.$modal.show("dialog", {
        title: this.$t("Вы уверены?"),
        buttons: [
          {
            title: this.$t("Нет"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Да"),
            handler: () => {
              this.$emit("delete-note", { id, leadId });
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    save() {
      if (this.mode === "new") {
        this.createNote();
      } else {
        this.updateNote();
      }
    },
    formatDate(value) {
      return moment(value)
        .utcOffset(6)
        .locale(this.lang)
        .format("D MMMM HH:mm");
    },
  },
  mounted() {
    this.$store.dispatch("updatedLeadNotes/fetch", { leadId: this.lead.id });
  },
};
</script>

<style lang="stylus" scoped>
.notes__title {
  margin-bottom: 4px;
  font(main_text_color, 12px, 400, 15px);
}

.notes__textarea {
  display: block;
  padding: 8px 8px 0;
  width: 100%;
  height: 140px;
  border-radius: 2px;
  border: none;
  font_regular();
  resize: none;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;
}

.notes__textarea::-webkit-scrollbar {
  width: 12px;
}

.notes__textarea::-webkit-scrollbar-track {
  background: #fff;
}

.notes__textarea::-webkit-scrollbar-thumb {
  background-color: #e6e7eb;
  border: 2px solid #fff;
  border-radius: 6px;
}

.notes__form {
  position: relative;
  margin-bottom: 24px;
  height: 200px;
  border: 1px solid #e6e7eb;
}

.notes__form.form__button, .notes__form.notes__form_delButton {
  position: absolute;
}

.notes__form .notes__form_delButton {
  background-color: unset;
  border: none;
  border-bottom: 1px dotted #3489f8;
  padding: 0;
  font(#3489F8, 14px, 400, 18px);
  bottom: 19px;
}

.notes__form .form__button {
  margin-top: 0;
}

.notes__btnsBox {
  position: absolute;
  bottom: 8px;
  left: 8px;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  align-items: center;
}

.notes__notes {
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e7eb;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}

.notes__notesItem {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 24px;
  }
}

.notes__notesItemTitle {
  margin-bottom: 3px;
  font_bold();
}

.notes__notesItemBody {
  font(main_text_color, 14px, 400, 20px);
  letter-spacing: -0.1px;
  white-space: pre-line;
  word-break: break-word;
}

.notes__notesItemButton {
  position: absolute;
  padding: 0;
  width: 20px;
  height: 20px;
  top: 0;
  border: none;
  background: none;
  background-repeat: no-repeat;
  background-size: contain;
}

.notes__notesItemButton_edit {
  right: 32px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='14.976' viewBox='0 0 15 14.976'%3E%3Cpath d='M4.667,16.338H5.845l7.762-7.762L12.428,7.4,4.667,15.16ZM18,18H3V14.469l11.2-11.2a.833.833,0,0,1,1.178,0l2.358,2.357a.833.833,0,0,1,0,1.178L8.2,16.338H18ZM13.607,6.22,14.785,7.4,15.963,6.22,14.785,5.042,13.607,6.22Z' transform='translate(-3 -3.029)' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 80%;
  background-position: center;
}

.notes__notesItemButton_del {
  right: 0px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath d='M0,0H20V20H0Z' fill='none'/%3E%3Cpath d='M14.5,5.333h4.167V7H17V17.833a.833.833,0,0,1-.833.833H4.5a.833.833,0,0,1-.833-.833V7H2V5.333H6.167v-2.5A.833.833,0,0,1,7,2h6.667a.833.833,0,0,1,.833.833ZM15.333,7h-10V17h10Zm-3.822,5,1.473,1.473-1.178,1.178-1.473-1.473L8.86,14.652,7.682,13.473,9.155,12,7.682,10.527,8.86,9.348l1.473,1.473,1.473-1.473,1.178,1.178ZM7.833,3.667V5.333h5V3.667Z' transform='translate(-0.333 -0.333)' fill='%230f1642'/%3E%3C/svg%3E");
}

button:hover {
  @media (min-width: tablet) {
    &:hover {
      opacity: 0.6;
    }
  }
}

@media (min-width: desktop) {
  .notes__notesItemBody {
    width: 567px;
  }
}
</style>
