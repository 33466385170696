<template>
  <div
    :class="[
      'headerInformation_refLink',
      { headerInformation_refLink_mobile: mobile },
    ]"
  >
    <h3
      :class="[
        'headerInformation__title',
        { headerInformation__title_mobile: mobile },
      ]"
    >
      <Tr>Реф. ссылка</Tr>
    </h3>
    <span
      :class="[
        'headerInformation__txt',
        { headerInformation__txt_mobile: mobile },
      ]"
      >{{ userRef }}</span
    >
    <button
      :class="[
        'headerInformation__button',
        { headerInformation__button_mobile: mobile },
      ]"
      v-clipboard="copy"
    >
      <Tr>Скопировать</Tr>
    </button>
  </div>
</template>

<script>
import { runNotice } from "@/utils/notifications";

export default {
  name: "HeaderInfoRef",
  props: {
    userRef: {
      type: String,
      required: true,
    },
    mobile: Boolean,
  },
  methods: {
    copy() {
      runNotice("success", this.$t("Ссылка скопирована"));
      return this.userRef;
    },
  },
};
</script>

<style lang="stylus" scoped>
.headerInformation__title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.headerInformation__txt {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  opacity: 0.6;
  margin-right: 28px;
}

.headerInformation_refLink {
  &_mobile {
    margin-bottom: 21px;
  }
}

.headerInformation_refLink .headerInformation__txt {
  display: inline-block;
  margin-right: 0;
  overflow: hidden;
}

.headerInformation_refLink .headerInformation__button {
  margin-right: 0;
}

.headerInformation__button {
  display: block;
  padding: 0;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  opacity: 0.6;
  text-decoration: underline;
}

.headerInformation__button:hover {
  text-decoration: none;
}

.headerInformation__title, .headerInformation__txt, .headerInformation__button {
  &_mobile {
    color: main_text_color;
  }
}
</style>
