<template>
  <div class="addonFiles">
    <h2 class="addonTitle">
      <Tr>Дополнительные материалы</Tr>
    </h2>
    <form class="wrap">
      <div :key="'file_' + file.id" class="file" v-for="file in files">
        <a :href="file.link" class="link" download="download">
          {{ file.name }}
        </a>
        <button class="buttonDelete" @click.prevent="deleteFile(file.id)" />
      </div>
    </form>

    <button class="addFile">
      <Tr>Добавить файл</Tr>
      <label class="addFile__label">
        <input
          class="addFile__input"
          @change="handleFileUpload"
          ref="newFile"
          type="file"
        />
      </label>
    </button>
  </div>
</template>

<script>
export default {
  name: "FileUploader",
  components: {},
  props: {
    files: Array,
    activeLang: String,
  },
  computed: {
    newFiles() {
      return this.$store.state.leadershipFilesUploadStore.data;
    },
  },
  methods: {
    handleFileUpload() {
      const file = this.$refs.newFile.files[0];

      this.$store.dispatch("fileUpload/upload", file);
      this.$refs.newFile.value = null;
    },
    deleteFile(id) {
      this.$modal.show("dialog", {
        title: this.$t("Вы уверены?"),
        text: this.$t("После удаления восстановить файл будет нельзя"),
        buttons: [
          {
            title: this.$t("Нет"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Да"),
            handler: () => {
              this.$store.dispatch("leadershipLesson/deleteFile", id);
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.addonFiles {
  margin-top: 24px;
  margin-bottom: 17px;
}

.addonTitle {
  font_bold();
  margin-bottom: 4px;
}

.wrap {
  display: grid;
  grid-row-gap: 8px;
  margin-top: 8px;
  width: 268px;

  @media (min-width: 374px) {
    width: 300px;
  }
}

.file {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 10px;
  justify-content: space-between;
}

.link {
  font_regular();
  color: #3489f8;
  max-width: 300px;
  overflow: hidden;
}

.buttonDelete {
  display: block;
  padding: 0;
  width: 20px;
  height: 20px;
  background: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M11.5 2.333V4h-5V2.333zm.152 6.86l-1.179-1.178L9 9.488 7.527 8.015 6.348 9.193l1.474 1.474-1.474 1.473 1.179 1.178L9 11.845l1.473 1.473 1.179-1.178-1.474-1.473zM14 15.667H4v-10h10zM13.167 1.5a.833.833 0 0 0-.834-.833H5.667a.833.833 0 0 0-.834.833V4H.667v1.667h1.666V16.5c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833V5.667h1.666V4h-4.166z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 90%;
  border: none;
}

.addFile {
  position: relative;
  background: none;
  font-size: 14px;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  padding: 5px 7px;
  margin-top: 18px;

  &__label {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
  }

  &__input {
    visibility: hidden;
  }
}
</style>
