<template>
  <article :key="`${quest.id}_${quest.title}`" class="quests__item">
    <base-link :to="link" class="quests__itemLink">
      <div class="quests__itemHeader">
        <Tr class="quest__itemType">Автовебинар</Tr>
        <h2 class="quests__itemTitle">{{ quest.name }}</h2>
      </div>
      <div class="quests__itemBody">
        <Tr class="quests__itemBody_text">Количество слайдов</Tr>
        <Tr class="quests__itemBody_text">Статус</Tr>
        <span class="quests__itemBody_text_bold">{{ slidesCount }}</span>
        <Tr
          :class="statusClass"
          class="quests__itemBody_text_bold"
          v-if="isPublished"
        >
          Опубликован
        </Tr>
        <Tr :class="statusClass" class="quests__itemBody_text_bold" v-else>
          Черновик
        </Tr>
      </div>
      <!-- <button class="tempDelete" @click="deleteCourse">delete</button> -->
    </base-link>
  </article>
</template>

<script>
import { pathOr } from "ramda";

export default {
  name: "LeadershipAutowebinarListItem",
  props: {
    quest: { type: Object, required: true },
  },
  computed: {
    link() {
      return `/leadership/autowebinars/${this.quest.id}/description`;
    },
    isPublished() {
      return this.quest.status === "publish";
    },
    statusClass() {
      return this.quest.status === "publish"
        ? "quests__itemBody_text_bold_green"
        : "quests__itemBody_text_bold_red";
    },
    slidesCount() {
      return pathOr(0, ["quest", "parts", "length"], this);
    },
  },
  methods: {
    deleteCourse() {
      this.$store.dispatch(
        "leadershipCourses/deleteCourse",
        this.$props.quest.id
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.tempDelete {
  position: absolute;
  z-index: 2000;
}

.quests__item {
  position: relative;
  padding: 13px 16px 19px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0f164214;
}

.quests__itemLink {
  text-decoration: none;
}

.quests__itemHeader {
  margin-right: -16px;
  margin-left: -16px;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 12px;
  border-bottom: 2px solid #e6e7eb;
}

.quest__itemType {
  font_small();
}

.quests__itemTitle {
  margin-top: 5px;
  font_subtitle();
}

.quests__itemBody {
  padding-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 2px;
}

.quests__itemBody_text {
  font_small();
}

.quests__itemBody_text_bold {
  font_bold();
}

.quests__itemBody_text_bold_green {
  color: #19c564;
}

.quests__itemBody_text_bold_red {
  color: #e81526;
}
</style>
