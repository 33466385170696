<template>
  <div class="funnel__linkListThead">
    <div
      class="
        funnel__linkListTheadCell
        funnel__linkListTheadCellDate
        funnel__linkListTheadCell_bordered
      "
      @click="changeSort"
    >
      <Tr>Дата создания</Tr>
    </div>
    <div class="funnel__linkListTheadCell funnel__linkListTheadCellName">
      <Tr>Название</Tr>
    </div>
    <div
      v-if="!updatedFunnel"
      class="funnel__linkListTheadCell funnel__linkListTheadCellType"
    >
      <Tr>Тип</Tr>
    </div>
    <div class="funnel__linkListTheadCell funnel__linkListTheadCellLink">
      <Tr>Ссылка</Tr>
    </div>
    <div class="funnel__linkListTheadCell funnel__linkListTheadCellCopy" />
  </div>
</template>

<script>
export default {
  name: "FunnelLinkListHead",
  props: {
    updatedFunnel: Boolean,
  },
  methods: {
    changeSort() {
      if (this.updatedFunnel) {
        this.$store.dispatch("funnelLinksList/changeSort");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnel__linkListThead {
  display: none;
  grid-template-columns: repeat(5, min-content);
  margin-bottom: 3px;
  width: fit-content;
}

.funnel__linkListTheadCell {
  text-align: left;
  font_bold();

  &_bordered span {
    display: inline-block;
    border-bottom: 2px dotted #0F164260;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 8px;
  }
}

.funnel__linkListTheadCellDate {
  width: 145px;
}

.funnel__linkListTheadCellName {
  width: 250px;
}

.funnel__linkListTheadCellType {
  width: 140px;
}

.funnel__linkListTheadCellFollows {
  width: 192px;
}

.funnel__linkListTheadCellLink {
  width: 240px;
}

.funnel__linkListTheadCellCopy {
  width: 19px;
}

.funnel__linkListTbodyRow {
  &_active {
    background-color: grey_text_color;
  }
}

.funnel__linkListTbodyCell {
  padding: 3px 0;

  &:first-child {
    padding-left: 4px;
  }
}

.funnel__linkListTbodyCellText {
  display: inline-block;
  font_regular();
}

@media (min-width: desktop) {
  .funnel__linkListThead {
    display: grid;
  }
}
</style>
