<template>
  <div class="languages">
    <button
      :class="{
        languages__button_active: isChecked(lang.title, value),
        languages__button_notAvailable: !lang.isValid,
      }"
      :key="lang.title"
      @click="$emit('handle-change-tab', lang.title)"
      class="languages__button"
      v-for="lang in langs"
    >
      {{ lang.title }}
    </button>
  </div>
</template>

<script>
export default {
  name: "LangTabs",
  props: {
    langs: Array,
    value: String,
  },
  methods: {
    isChecked(name, current) {
      return name === current;
    },
  },
};
</script>

<style lang="stylus" scoped>
.languages {
  margin-bottom: 16px;
}

.languages__button {
  padding: 9px 11px 4px 11px;
  background: unset;
  background-color: #e6e7eb;
  border: unset;
  border-bottom: 4px solid #ff9f43;
  font(#0f1642, 10px, 700, 13px);
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
}

.languages__button:hover {
  background-color: #ff9f43;
  color: #fff;
}

.languages__button_active {
  background-color: #ff9f43;
  color: #fff;
}

.languages__button_notAvailable {
  border-bottom: 4px solid #e81526;
  color: #e81526;
}
</style>
