import { render, staticRenderFns } from "./UpdatedLeadsCreateOrderBox.vue?vue&type=template&id=795b9305&scoped=true&"
import script from "./UpdatedLeadsCreateOrderBox.vue?vue&type=script&lang=js&"
export * from "./UpdatedLeadsCreateOrderBox.vue?vue&type=script&lang=js&"
import style0 from "./UpdatedLeadsCreateOrderBox.vue?vue&type=style&index=0&id=795b9305&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "795b9305",
  null
  
)

export default component.exports