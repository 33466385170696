import Vue from "vue";
import moment from "moment";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    contactProgress: null,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_CONTACT_PROGRESS(state, value) {
      state.contactProgress = value;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async getTaskDone({ commit, state }, { id, result }) {
      if (state.pending) {
        return;
      }

      const now = Date.now();
      const nowToLocalIso = moment(now).toISOString();

      try {
        commit("SET_PENDING", true);

        let response;

        if (result) {
          response = await Vue.axios.patch(`/user_tasks/${id}/`, {
            done_at: nowToLocalIso,
            result: result,
            contact_progress: state.contactProgress,
          });
        } else {
          response = await Vue.axios.patch(`/user_tasks/${id}/`, {
            done_at: nowToLocalIso,
          });
        }

        if (response.status.toString().startsWith("20")) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async changeContactProgress({ commit, state }, { id, progress }) {
      // if (state.pending) {
      //   return;
      // }

      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.patch(`/contacts/${id}/`, {
          progress: state.contactProgress,
        });

        if (response.status.toString().startsWith("20")) {
          commit("SET_CONTACT_PROGRESS", progress);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
