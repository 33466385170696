<template>
  <div class="mergeContactsTableItem mergeContactsTableItem_created">
    <!-- NAME -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellName
        mergeContactsTableItemCellFade
      "
    >
      <div
        class="
          mergeContactsTableItemCellInner
          mergeContactsTableItemCellInner_created
        "
        v-if="name"
      >
        <base-tooltip :tooltipContent="name">
          {{ name }}
        </base-tooltip>
      </div>
    </div>
    <!-- NAME -->

    <!-- PHONE -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellPhone
        mergeContactsTableItemCellFade
      "
    >
      <template v-if="contact.phones_objects">
        <div
          v-for="phone in contact.phones_objects"
          :key="phone.id"
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <base-tooltip :tooltipContent="phone.value">
            {{ phone.value }}
          </base-tooltip>
        </div>
      </template>
    </div>
    <!-- PHONE -->

    <!-- EMAILS -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellEmail
        mergeContactsTableItemCellFade
      "
    >
      <template v-if="contact.emails_objects">
        <div
          v-for="email in contact.emails_objects"
          :key="email.id"
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <base-tooltip :tooltipContent="email.value">
            {{ email.value }}
          </base-tooltip>
        </div>
      </template>
    </div>
    <!-- EMAILS -->

    <!-- LINKS -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellLink
        mergeContactsTableItemCellFade
      "
    >
      <template v-if="contact.links_objects">
        <div
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
          v-for="link in contact.links_objects"
          :key="link.id"
        >
          <base-tooltip :tooltipContent="link.link">
            {{ link.link }}
          </base-tooltip>
        </div>
      </template>
    </div>
    <!-- LINKS -->

    <!-- COUNTRY -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellCountry
        mergeContactsTableItemCellFade
      "
    >
      <div
        class="
          mergeContactsTableItemCellInner
          mergeContactsTableItemCellInner_created
        "
        v-if="contact.country"
      >
        <base-tooltip :tooltipContent="contact.country">
          {{ contact.country }}
        </base-tooltip>
      </div>
    </div>
    <!-- COUNTRY -->

    <!-- CITY -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellCity
        mergeContactsTableItemCellFade
      "
    >
      <div
        class="
          mergeContactsTableItemCellInner
          mergeContactsTableItemCellInner_created
        "
        v-if="contact.city"
      >
        <base-tooltip :tooltipContent="contact.city">
          {{ contact.city }}
        </base-tooltip>
      </div>
    </div>
    <!-- CITY -->

    <!-- MESSENGERS -->
    <div
      class="
        mergeContactsTableItemCell
        mergeContactsTableItemCellMessengers
        mergeContactsTableItemCellFade
      "
    >
      <div
        class="
          mergeContactsTableItemCellInner
          mergeContactsTableItemCellInner_created
        "
        v-if="contact.telegram"
      >
        <base-icon-svg
          class="mergeContactsTableItemCellIcon"
          width="20"
          height="20"
          viewbox="0 0 20 20"
        >
          <telegram-icon />
        </base-icon-svg>
        <base-tooltip :tooltipContent="contact.telegram">
          {{ contact.telegram }}
        </base-tooltip>
      </div>
      <div
        class="
          mergeContactsTableItemCellInner
          mergeContactsTableItemCellInner_created
        "
        v-if="contact.whatsapp"
      >
        <base-icon-svg
          class="mergeContactsTableItemCellIcon"
          width="20"
          height="20"
          viewbox="0 0 20 20"
        >
          <whatsapp-icon />
        </base-icon-svg>
        <base-tooltip :tooltipContent="contact.whatsapp">
          {{ contact.whatsapp }}
        </base-tooltip>
      </div>
    </div>
    <!-- MESSENGERS -->

    <!-- PROGRESS -->
    <div class="mergeContactsTableItemCell mergeContactsTableItemCellProgress">
      <template
        v-if="Object.prototype.hasOwnProperty.call(contact, 'progress')"
      >
        <Tr class="contactsBodyItemMobileText">Прогресс</Tr>
        <div
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <base-tooltip
            class="progressBox"
            v-if="contact.progress > 0 || contact.progress === 0"
            :tooltipContent="progressTooltip(contact.progress)"
          >
            <span
              v-for="progressItem in 10"
              :key="progressItem"
              :class="[
                ' progressItem',
                {
                  progressItem_active: progressItem - 1 <= contact.progress,
                },
              ]"
            />
          </base-tooltip>
        </div>
      </template>
    </div>
    <!-- PROGRESS -->

    <!-- HEAT -->
    <div class="mergeContactsTableItemCell mergeContactsTableItemCellHeat">
      <template v-if="Object.prototype.hasOwnProperty.call(contact, 'heat')">
        <Tr class="contactsBodyItemMobileText">Подогретость</Tr>
        <div
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <div class="contactsBodyItemHeat">
            <base-tooltip
              class="contactsBodyItemHeatBox"
              v-if="contactHeat"
              :tooltipContent="heat"
            >
              <base-icon-svg
                class="contactsBodyItemHeatImg"
                width="120"
                height="20"
                viewbox="0 0 120 20"
              >
                <ContactsHeatCold v-if="contactHeat === 'cold'" />
                <ContactsHeatWarm v-else-if="contactHeat === 'warm'" />
                <ContactsHeatHot v-else-if="contactHeat === 'hot'" />
              </base-icon-svg>
            </base-tooltip>
            <span class="withoutValue" v-else />
          </div>
        </div>
      </template>
    </div>
    <!-- HEAT -->

    <!-- TOP -->
    <div class="mergeContactsTableItemCell mergeContactsTableItemCellTop">
      <template v-if="Object.prototype.hasOwnProperty.call(contact, 'top')">
        <Tr class="contactsBodyItemMobileText">Топ</Tr>
        <div
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <div class="contactsBodyItemTop">
            <Tr
              v-if="contactTop === '100'"
              class="contactsBodyItemTopInner contactsBodyItemTopInner_100"
              >ТОП 100</Tr
            >
            <Tr
              v-else-if="contactTop === '20_and_100'"
              class="contactsBodyItemTopInner contactsBodyItemTopInner_20"
              >ТОП 20</Tr
            >
            <span class="withoutValue" v-else />
          </div>
        </div>
      </template>
    </div>
    <!-- TOP -->

    <!-- TAGS -->
    <div class="mergeContactsTableItemCell mergeContactsTableItemCellTags">
      <template
        v-if="
          Object.prototype.hasOwnProperty.call(contact, 'tag_objects') &&
          contact.tag_objects.length > 0
        "
      >
        <Tr class="contactsBodyItemMobileText">Теги</Tr>
        <div
          class="
            mergeContactsTableItemCellInner
            mergeContactsTableItemCellInner_created
          "
        >
          <template v-if="contact.tag_objects.length > 0">
            <div class="tagsPopup">
              <div class="tagsPopupBox">
                <span
                  class="tagsPopupItem"
                  v-for="tag in contact.tag_objects"
                  :key="tag.id"
                  :style="{
                    backgroundColor: `#${tag.color}`,
                    color: `#${tag.text_color}`,
                  }"
                  >{{ tag.name }}</span
                >
              </div>
            </div>
          </template>
          <span class="withoutValue" v-else />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TelegramIcon from "@/components/SvgIcons/TelegramIcon.vue";
import WhatsappIcon from "@/components/SvgIcons/WhatsappIcon.vue";
import ContactsHeatCold from "@/components/SvgIcons/contacts/ContactsHeatCold.vue";
import ContactsHeatWarm from "@/components/SvgIcons/contacts/ContactsHeatWarm.vue";
import ContactsHeatHot from "@/components/SvgIcons/contacts/ContactsHeatHot.vue";

export default {
  name: "MergeContactsTableItemCreated",

  components: {
    TelegramIcon,
    WhatsappIcon,
    ContactsHeatCold,
    ContactsHeatWarm,
    ContactsHeatHot,
  },

  props: {
    contact: {
      type: Object,
      required: true,
    },

    contactHeatArray: {
      type: Array,
      required: true,
    },

    progresses: {
      type: Array,
      required: true,
    },
  },

  computed: {
    name() {
      if (this.contact.first_name || this.contact.last_name) {
        return `${this.contact.first_name ? this.contact.first_name : ""} ${this.contact.last_name ? this.contact.last_name : ""}`;
      }
      return ``;
    },

    contactHeat() {
      return this.contact.heat;
    },

    heat() {
      const heatEl = this.contactHeatArray.find(
        el => el.param === this.contact.heat
      );
      return heatEl ? heatEl.verbose : "";
    },

    contactTop() {
      return this.contact.top;
    },

    tagsListString() {
      return this.contact.tag_objects.map(el => el.name).join(", ");
    }
  },

  methods: {
    pickOption(event, value) {
      this.$emit('pickOption', event.target.name, value, this.contact);
    },

    progressTooltip(progress) {
      const progressEl = this.progresses.find(el => el.param === progress);
      return progressEl ? progressEl.verbose : "";
    },
  },
};
</script>

<style lang="stylus" scoped>
.mergeContactsTableItem {
  display: grid;
  grid-row-gap: 4px;
  margin-bottom: 10px;
  width: 100%;

  &_created {
    .contactsBodyItemMobileText {
      display: block;

      @media (min-width: tablet_large) {
        display: none;
      }
    }

    .mergeContactsTableItemCellProgress {
      padding-left: 120px;

      @media (min-width: tablet_large) {
        padding-left: 0px;
      }
    }

    .mergeContactsTableItemCellHeat {
      padding-left: 120px;

      @media (min-width: tablet_large) {
        padding-left: 0px;
      }
    }

    .mergeContactsTableItemCellTop {
      padding-left: 120px;

      @media (min-width: tablet_large) {
        padding-left: 0px;
      }
    }

    .mergeContactsTableItemCellTags {
      display: flex;
      align-items: center;
      padding-left: 120px;
      overflow: visible;

      .contactsBodyItemMobileText {
        bottom: 2px;
      }

      @media (min-width: tablet_large) {
        padding-left: 0px;
      }
    }

    .mergeContactsTableItemCell {
      min-height: unset;

      @media (min-width: tablet_large) {
        min-height: 20px;
      }
    }

    .tagsPopup {
      display: flex;
      flex-direction: column;

      @media (min-width: tablet_large) {
        display: block;
      }
    }
  }
}

.mergeContactsTableItemCell {
  position: relative;
  display: grid;
  grid-row-gap: 2px;
  min-height: 24px;
  overflow: hidden;
}

.mergeContactsTableItemCellName {
  font-weight: 700;

  @media (min-width: tablet_large) {
    font-weight: 400;
  }
}

.mergeContactsTableItemCellFade {
  display: block;
  cell_fade();
}

.mergeContactsTableItemCellInner {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  max-width: 100%;
  grid-column-gap: 2px;
  align-items: center;
  padding: 2px 2px 2px 22px;
  white-space: nowrap;

  &_created {
    padding-left: 0;
  }
}

.progressBox {
  position: relative;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  grid-column-gap: 2px;
}

.progressItem {
  display: inline-block;
  width: 8px;
  height: 14px;
  background-color: grey_text_color;

  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &_active {
    &:nth-child(1) {
      background-color: #E91927;
    }

    &:nth-child(2) {
      background-color: #EE4A2E;
    }

    &:nth-child(3) {
      background-color: #F37934;
    }

    &:nth-child(4) {
      background-color: #F8A33A;
    }

    &:nth-child(5) {
      background-color: #FFDB42;
    }

    &:nth-child(6) {
      background-color: #DADC48;
    }

    &:nth-child(7) {
      background-color: #A9D650;
    }

    &:nth-child(8) {
      background-color: #83D255;
    }

    &:nth-child(9) {
      background-color: #4FCB5C;
    }

    &:nth-child(10) {
      background-color: #1BC563;
    }
  }
}

.contactsBodyItemMobileText {
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  font-weight: 400;
  opacity: 0.6;
}

.contactsBodyItemProgress, .link, .contactsBodyItemHeat {
  display: flex;
  align-items: center;
}

.contactsBodyItemHeatBox {
  position: relative;
  z-index: 1;
}

.contactsBodyItemHeatBox, .contactsBodyItemTop {
  display: flex;
}

.contactsBodyItemHeatImg {
  display: inline-block;
  margin-top: 1px;
  margin-left: 1px;
  width: 64px;
  height: 12px;
}

.contactsBodyItemTopInner {
  font(main_text_colot, 10px, 700, 13px);
  letter-spacing: 0.29px;
  vertical-align: top;

  &_100 {
    border-bottom: 4px solid #ffe043;
  }

  &_20 {
    border-bottom: 4px solid #19c564;
  }
}

.contactsBodyItemTagsWrap {
  max-width: 130px;

  @media (min-width: phone_s) {
    max-width: 170px;
  }

  @media (min-width: tablet) {
    max-width: 130px;
  }

  @media (min-width: desktopLarge) {
    max-width: 200px;
  }

  @media (min-width: desktopLeads) {
    max-width: 250px;
  }
}

.contactsBodyItemTags {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;

  @media (min-width: desktop) {
    grid-column-gap: 2px;
  }
}

.contactsBodyItemTagsItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 3px;
  border-radius: 2px;
  background-color: lightgrey;

  span {
    display: inline-block;
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    max-width: 60px;
    text-overflow: ellipsis;

    @media (min-width: phone_s) {
      max-width: 80px;
    }

    @media (min-width: tablet) {
      max-width: 60px;
    }

    @media (min-width: desktopLarge) {
      max-width: 90px;
    }
  }
}

.mergeContactsTableItemCellIcon {
  width: 20px;
  height: 20px;
}

.tagsPopup {
  padding-bottom: 8px;
  margin-bottom: -8px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e7eb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: main_text_color;
    border: 2px solid #fff;
    border-radius: 6px;
  }

  @media (min-width: tablet) {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.tagsPopupBox {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 2px;
}

.tagsPopupItem {
  position: relative;
  display: inline-block;
  padding: 2px 3px 4px;
  width: fit-content;
  max-width: 120px;
  border-radius: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
}

.withoutValue {
  position: relative;
  display: inline-block;
  width: 14px;
  height: 20px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    background-color: main_text_color;
    opacity: 0.6;
  }
}
</style>
