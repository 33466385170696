<template>
  <modal-layout
    :modalWidth="480"
    :pending="false"
    :name="modalName"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <template #title v-if="hasTag">Изменить тег</template>
    <template #title v-else>Добавить тег</template>
    <template #body>
      <label class="createTag__name label">
        {{ $t("Название") }}
        <input
          :maxlength="maxLength"
          :value="name"
          @input="handleInput"
          class="input"
          type="text"
        />
        <div class="span__wrap">
          <span class="span">
            <span :class="{ span_red: valueLength === maxLength }">
              {{ valueLength }}
            </span>
            /{{ maxLength }}</span
          >
        </div>
      </label>
      <contacts-profile-create-tag-color
        :colors="tagsColorObjects"
        :color="tagColor"
        @change-color="changeColor"
      />
      <div class="tagsPopupFooter">
        <form-submit
          v-if="hasTag"
          @click="editTag(tag)"
          :disabled="createBtnDisabled"
          :orange="true"
          text="Сохранить"
        />
        <form-submit
          v-else
          @click="createTag"
          :disabled="createBtnDisabled"
          :orange="true"
          text="Добавить"
        />
      </div>
    </template>
  </modal-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { runNotice } from "@/utils/notifications";

import ModalLayout from "@/components/ModalLayout.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import ContactsProfileCreateTagColor from "@/components/Contacts/ContactsProfileCreateTagColor.vue";

export default {
  name: "ContactsProfileModalCreateTag",

  components: {
    ModalLayout,
    FormSubmit,
    ContactsProfileCreateTagColor,
  },

  data() {
    return {
      modalName: "create-contact-tag",
      maxLength: 32,
      contact: {},
      tag: {},
    };
  },

  computed: {
    ...mapGetters({
      createdTag: "contactsTags/createdTag",
      currentPage: "contacts/currentPage",
    }),
    ...mapState(
      'contactsTags', ['tagsColorObjects']
    ),

    hasTag() {
      return Object.keys(this.tag).length > 0;
    },

    name() {
      return this.createdTag.name ? this.createdTag.name : "";
    },

    tagColor() {
      return this.tag.color ? this.tag.color : "";
    },

    valueLength() {
      if (this.name.length === 0) {
        return 0;
      }
      return this.name.length;
    },

    createBtnDisabled() {
      if (this.createdTag.name.length === 0) return true;
      else if (this.createdTag.color.length === 0) return true;
      return false;
    },
  },

  methods: {
    handleInput(e) {
      this.$store.commit("contactsTags/SET_TAG_NAME", e.target.value);
    },

    changeColor(value) {
      this.$store.commit("contactsTags/SET_TAG_COLOR", value);
    },

    beforeClose() {
      this.$store.commit("contactsTags/CLEAR_TAG");
      this.tag = {};
    },

    beforeOpen(event) {
      this.contact = event.params.contact;
      if (event.params.tag) {
        this.tag = event.params.tag;
        this.$store.commit("contactsTags/SET_TAG_NAME", event.params.tag.name);
        this.$store.commit("contactsTags/SET_TAG_COLOR", event.params.tag.color);
      }
    },

    createTag() {
      this.$store.dispatch("contactsTags/createTag")
        .then(response => {
          if (response && response.status === 201) {
            if (this.contact && this.contact.id) {
              this.$store.dispatch("contactsTags/addTag", { tagId: response.data.id, contactId: this.contact.id })
                .then(response => {
                  if (response && response.status === 201) {
                    this.$store.dispatch("contacts/fetch", this.currentPage);
                    this.$store.dispatch("contactsTags/fetch");
                  }
                })
                .catch(e => {
                  console.log(e);
                });
            } else {
              this.$store.dispatch("contactsTags/fetch");
            }
            runNotice("success", this.$t("Тег создан"));
          } else {
            this.$store.dispatch("contacts/fetch", this.currentPage);
            runNotice("error", this.$t("Тег не создан"));
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.beforeClose();
          this.$modal.hide('create-contact-tag');
        });
    },

    editTag(tag) {
      this.$store.dispatch("contactsTags/editTag", tag).
        then(response => {
          if (response && response.status === 200) {
            this.$store.dispatch("contacts/fetch", this.currentPage);
            this.$store.dispatch("contactsTags/fetch");
            runNotice("success", this.$t("Тег изменен"));
          } else {
            this.$store.dispatch("contacts/fetch", this.currentPage);
            runNotice("error", this.$t("Тег не изменен"));
          }
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.beforeClose();
          this.$modal.hide('create-contact-tag');
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.createTag__name {
  margin-bottom: 12px;
}

.label {
  position: relative;
  display: block;
  width: 100%;
  max-width: 400px;
  font_small();
}

.input {
  display: block;
  margin-top: 4px;
  padding: 5px 4px 6px 7px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #e6e7eb;
  color: main_text_color;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.span__wrap {
  position: absolute;
  top: 0;
  right: 0;
}

.withoutCounter {
  display: none;
}

.span {
  display: flex;
  font_small();
  opacity: 0.6;

  & span {
    font-weight: 700;
  }

  &_red {
    color: #e81526;
  }
}

.tagsPopupFooter {
  display: flex;
  margin-bottom: -10px;
  border-top: 2px solid grey_text_color;
}
</style>
