<template>
  <footer class="footer">
    <div class="footer__linksWrap">
      <FooterLink
        :key="parametre.id"
        :link="parametre"
        v-for="parametre in parametres"
      />
    </div>
    <FooterCopyright />
  </footer>
</template>

<script>
import FooterLink from "@/components/Footer/FooterLink.vue";
import FooterCopyright from "@/components/Footer/FooterCopyright.vue";

export default {
  name: "TheFooter",
  components: {
    FooterLink,
    FooterCopyright,
  },
  data() {
    return {
      parametres: [
        {
          id: 0,
          link: "Политика конфиденциальности",
          href: "/privacy",
        },
        {
          id: 1,
          link: "Условия использования",
          href: "/terms",
        },
        // {
        //   id: 2,
        //   link: "Политика в отношении Cookie",
        //   href: "/",
        // },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.footer {
  margin-top: 9px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 7px 8px;

  @media (min-width: desktop) {
    flex-direction: unset;
    align-items: flex-end;
    margin-top: 10px;
    margin-left: 240px;
    padding: 8px 16px;
  }

  &__linksWrap {
    // display: grid;
    // grid-row-gap: 4px;
    display: inline-grid;
    grid-template-rows: repeat(2, min-content);
    grid-row-gap: 3px;

    @media (min-width: desktop) {
      grid-auto-flow: column;
      grid-template-rows: unset;
      grid-row-gap: unset;
      grid-column-gap: 15px;
    }
  }
}
</style>
