import { render, staticRenderFns } from "./UpdatedFunnelBodyItemNotification.vue?vue&type=template&id=21353d43&scoped=true&"
import script from "./UpdatedFunnelBodyItemNotification.vue?vue&type=script&lang=js&"
export * from "./UpdatedFunnelBodyItemNotification.vue?vue&type=script&lang=js&"
import style0 from "./UpdatedFunnelBodyItemNotification.vue?vue&type=style&index=0&id=21353d43&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21353d43",
  null
  
)

export default component.exports