import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    pending: false,
    errors: {},
  }),

  getters: {},

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ commit, rootGetters }) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/variables/`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async create({ commit, rootGetters }, data) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.post(
          `/variables/`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          return response.data;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
