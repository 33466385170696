<template>
  <modal
    name="funnel-keypoints"
    @before-open="beforeOpen"
    :width="640"
    height="auto"
    :adaptive="true"
  >
    <div class="funnelKeypoints">
      <!-- HEADER -->
      <div class="funnelKeypoints__header">
        <div class="funnelKeypoints__headerBox">
          <div class="funnelKeypoints__headerName">
            {{ leadName }}
          </div>
          <span class="funnelKeypoints__title">
            <Tr>Автовебинар</Tr>
            {{ leadUrl }}
          </span>
        </div>
        <button
          class="funnelKeypoints__headerButton"
          type="button"
          @click="hideCreateTask"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="funnelKeypoints__body">
        <ul class="funnelKeypoints__list">
          <li
            v-for="point in keyPoints"
            :key="`${point.second}_${point.time}`"
            :class="[
              'funnelKeypoints__item',
              { funnelKeypoints__item_visited: point.visited },
            ]"
          >
            <Tr>Ключевая точка в</Tr>
            {{ point.time }}
          </li>
        </ul>
      </div>
      <!-- BODY -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import { pathOr } from "ramda";

export default {
  name: "FunnelModalKeypoints",
  components: {
    CloseButton,
  },
  data() {
    return {
      lead: {},
    };
  },
  computed: {
    ...mapGetters({
      leads: "lead/lead",
    }),
    leadName() {
      return pathOr("", ["bot", "lead", "name"], this.lead);
    },
    leadUrl() {
      return pathOr("", ["vebinar", "url"], this.lead);
    },
    keyPoints() {
      return pathOr([], ["vebinar", "important"], this.lead);
    },
  },
  methods: {
    beforeOpen(event) {
      this.lead = event.params.lead;
    },
    hideCreateTask() {
      this.$modal.hide("funnel-keypoints");
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnelKeypoints {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    padding-bottom: 7px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerBox {
    width: 92%;
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__title {
    font-weight: 400;

    @media (min-width: tablet) {
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    right: 6px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;
    scrollbar-width: thin;
    scrollbar-color: grey_text_color #fff;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey_text_color;
      border: 2px solid #fff;
      border-radius: 6px;
    }

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 9px;
    padding-left: 24px;
    font(rgba(15, 22, 66, 0.4), 12px, 400, 16px);

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: grey_text_color;
    }

    &_visited {
      color: main_text_color;

      &::before {
        background-color: green_text_color;
      }
    }
  }
}
</style>
