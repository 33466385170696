<template>
  <div class="goodQuest__description">
    <form @submit.prevent class="goodQuest__form">
      <base-checkboxes-group
        :title="'Доступно на языках'"
        :list="supportedLangsTitled"
        :errors="errors ? errors.languages : []"
        :checkedList="course.languages"
        @handle-checkboxes="toggleLang"
      />
      <leadership-course-price
        :cost="course.cost"
        :paymentType="course.payment_type"
        :errors="errors ? errors.cost : []"
        @change-price="changePrice"
        @change-payment-type="changePaymentType"
      />
      <leadership-program-selector
        :programs="programs"
        :value="choosedProgram"
        @change-program="changeProgram"
      />
      <lang-tabs
        :langs="langTabs"
        :value="lang"
        @handle-change-tab="handleChangeLang"
        class="leadershipLangButtons__quest"
      />
      <leadership-input
        :maxLength="NAME_MAX_LENGTH"
        :value="courseName"
        @handle-name="handleChangeName"
        title="Название квеста"
      />
      <leadership-textarea
        :maxLength="DESCRIPTION_MAX_LENGTH"
        :value="courseDescription"
        @handle-description="handleChangeDescription"
        title="Описание"
      />
    </form>
    <div class="buttons">
      <form-submit @click="save" buttonName="save" />
      <form-submit
        @click="publish"
        buttonName="publish"
        class="publish"
        :text="'Опубликовать'"
        :key="'publish'"
        v-if="!isNew && !isPublished"
      />
      <form-submit
        @click="unpublish"
        class="unpublish"
        :text="'Снять с публикации'"
        :key="'unpublish'"
        v-if="!isNew && isPublished"
      />
    </div>
    <!-- <published-block v-else /> -->
  </div>
</template>

<script>
import LeadershipInput from "@/components/Leadership/LeadershipInput.vue";
import LeadershipTextarea from "@/components/Leadership/LeadershipTextarea.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import LangTabs from "@/components/LangTabs.vue";
import LeadershipCoursePrice from "@/components/Leadership/LeadershipCoursePrice.vue";
import LeadershipProgramSelector from "@/components/Leadership/LeadershipProgramSelector.vue";
// import PublishedBlock from "@/components/PublishedBlock.vue";

export default {
  name: "LeadershipCourse",
  components: {
    LeadershipInput,
    LeadershipTextarea,
    FormSubmit,
    LangTabs,
    LeadershipCoursePrice,
    LeadershipProgramSelector,
    // PublishedBlock,
  },
  data() {
    return {
      NAME_MAX_LENGTH: 150,
      DESCRIPTION_MAX_LENGTH: 2000,
    };
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    supportedLangsTitled: {
      type: Array,
    },
    langTabs: {
      type: Array,
      required: true,
    },
    errors: Object,
    courseName: String,
    courseDescription: String,
    isPublished: Boolean,
    isNew: {
      type: Boolean,
      required: false,
    },
    programs: {
      type: Array,
      required: true,
    },
    choosedProgram: {
      required: true,
    },
  },
  methods: {
    toggleLang(lang) {
      this.$emit("change-languages", lang);
    },
    handleChangeName(value) {
      this.$emit("change-name", value);
    },
    handleChangeDescription(value) {
      this.$emit("change-description", value);
    },
    handleChangeLang(value) {
      this.$emit("change-active-lang", value);
    },
    save() {
      this.$emit("save-course");
    },
    publish() {
      this.$emit("publish-course");
    },
    changePrice(value) {
      this.$emit("change-price", value);
    },
    unpublish() {
      this.$modal.show("dialog", {
        title: this.$t("Внимание!"),
        text: this.$t("Вы уверены?"),
        buttons: [
          {
            title: this.$t("Отмена"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Снять с публикации"),
            handler: () => {
              this.$emit("unpublish-course");
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    changePaymentType(value) {
      this.$emit("change-payment-type", value);
    },
    changeProgram(value) {
      this.$emit("change-program", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadershipLangButtons__quest {
  margin-top: 24px;
}

.publish {
  margin-left: 16px;
  color: #19c564;
}

.unpublish {
  margin-left: 16px;
  color: #e81526;
}
</style>
