<template>
  <div class="filter">
    <div class="filterSearch">
      <FunnelMenuFilterSearchButton
        :class="{ filterSearch__filter_active: filtersIsActive }"
        :isActive="filtersIsActive"
        @button-click="handleFilter"
      />
      <updated-funnel-menu-filter-search-input
        :updatedFunnel="true"
        :search="search"
        @change-search="changeSearch"
        :title="placeholder"
      />
    </div>
    <updated-funnel-filter-body
      v-show="filtersIsActive"
      :funnels="funnels"
      :tags="tags"
      :orders="orders"
      :filters="filters"
      :isFilterChecked="isFilterChecked"
      :pickedAllFunnels="pickedAllFunnels"
      :pickedAllTags="pickedAllTags"
      :pickedAllOrders="pickedAllOrders"
      :pickedAllManagers="pickedAllManagers"
      :pickedAllStatuses="pickedAllStatuses"
      :funnelsFilterValue="funnelsFilterValue"
      :funnelsFilterCounter="funnelsFilterCounter"
      :tagsFilterValue="tagsFilterValue"
      :tagsFilterCounter="tagsFilterCounter"
      :ordersFilterValue="ordersFilterValue"
      :ordersFilterCounter="ordersFilterCounter"
      :managersFilterValue="managersFilterValue"
      :managersFilterCounter="managersFilterCounter"
      :statusesFilterValue="statusesFilterValue"
      :statusesFilterCounter="statusesFilterCounter"
      :managersFilter="managersFilter"
      :statusesFilter="statusesFilter"
      :managers="managers"
      :statuses="statuses"
      @changeFilter="changeFilter"
      @pickAllFunnels="pickAllFunnels"
      @pickAllTags="pickAllTags"
      @pickAllOrders="pickAllOrders"
      @pickAllManagers="pickAllManagers"
      @pickAllStatuses="pickAllStatuses"
      @clearFilters="clearFilters"
      @changeFunnelsFilterValue="changeFunnelsFilterValue"
      @changeFunnelsFilterCounter="changeFunnelsFilterCounter"
      @changeTagsFilterValue="changeTagsFilterValue"
      @changeTagsFilterCounter="changeTagsFilterCounter"
      @changeOrdersFilterValue="changeOrdersFilterValue"
      @changeOrdersFilterCounter="changeOrdersFilterCounter"
      @changeManagersFilterValue="changeManagersFilterValue"
      @changeManagersFilterCounter="changeManagersFilterCounter"
      @changeStatusesFilterValue="changeStatusesFilterValue"
      @changeStatusesFilterCounter="changeStatusesFilterCounter"
    />
  </div>
</template>

<script>
import FunnelMenuFilterSearchButton from "@/components/Funnel/FunnelMenuFilterSearchButton.vue";
import UpdatedFunnelMenuFilterSearchInput from "@/components/Funnel/UpdatedFunnelMenuFilterSearchInput.vue";
import UpdatedFunnelFilterBody from "@/components/Funnel/UpdatedFunnelFilterBody.vue";

export default {
  name: "UpdatedFunnelMenuFilterSearch",
  components: {
    FunnelMenuFilterSearchButton,
    UpdatedFunnelMenuFilterSearchInput,
    UpdatedFunnelFilterBody,
  },
  props: {
    funnels: {
      type: Object,
    },
    tags: {
      type: Object,
    },
    orders: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    isFilterChecked: {
      type: Function,
    },
    pickedAllFunnels: {
      type: Boolean,
    },
    pickedAllTags: {
      type: Boolean,
    },
    pickedAllOrders: {
      type: Boolean,
    },
    pickedAllManagers: {
      type: Boolean,
    },
    pickedAllStatuses: {
      type: Boolean,
    },
    funnelsFilterValue: {
      type: [String, Number],
    },
    funnelsFilterCounter: {
      type: Number,
    },
    tagsFilterValue: {
      type: [String, Number],
    },
    tagsFilterCounter: {
      type: Number,
    },
    ordersFilterValue: {
      type: [String, Number],
    },
    ordersFilterCounter: {
      type: Number,
    },
    managersFilterValue: {
      type: [String, Number],
    },
    managersFilterCounter: {
      type: Number,
    },
    managersFilter: Boolean,
    managers: {
      type: Array,
    },
    statusesFilterValue: {
      type: [String, Number],
    },
    statusesFilterCounter: {
      type: Number,
    },
    statusesFilter: Boolean,
    statuses: {
      type: Array,
    },
    search: {
      type: String,
      required: true,
    },
    filtersIsActive: Boolean,
  },
  computed: {
    placeholder() {
      return "ID, имя, email или телефон";
    },
  },
  methods: {
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    pickAllFunnels(payload) {
      this.$emit("pickAllFunnels", payload);
    },
    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },
    pickAllOrders(payload) {
      this.$emit("pickAllOrders", payload);
    },
    pickAllManagers(payload) {
      this.$emit("pickAllManagers", payload);
    },
    pickAllStatuses(payload) {
      this.$emit("pickAllStatuses", payload);
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    changeFunnelsFilterValue(value) {
      this.$emit("changeFunnelsFilterValue", value);
    },
    changeFunnelsFilterCounter(value) {
      this.$emit("changeFunnelsFilterCounter", value);
    },
    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },
    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
    changeOrdersFilterValue(value) {
      this.$emit("changeOrdersFilterValue", value);
    },
    changeOrdersFilterCounter(value) {
      this.$emit("changeOrdersFilterCounter", value);
    },
    changeManagersFilterValue(value) {
      this.$emit("changeManagersFilterValue", value);
    },
    changeManagersFilterCounter(value) {
      this.$emit("changeManagersFilterCounter", value);
    },
    changeStatusesFilterValue(value) {
      this.$emit("changeStatusesFilterValue", value);
    },
    changeStatusesFilterCounter(value) {
      this.$emit("changeStatusesFilterCounter", value);
    },
    changeSearch(value) {
      this.$emit("change-search", value);
    },
    handleFilter() {
      this.$emit("handleFilter");
    },
  },
};
</script>

<style lang="stylus" scoped>
.filter {
  order: 1;
}

.filterSearch {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-column-gap: 8px;
}

.filterSearch__filter_active {
  border-bottom: 4px solid grey_text_color;
}

@media (min-width: desktop) {
  .filter {
    order: unset;
  }

  .filterSearch {
    grid-template-columns: 87px 340px;
    grid-column-gap: 15px;
  }
}
</style>
