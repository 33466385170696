<template>
  <div class="leadsCreateLink">
    <div class="leadsCreateLink__box">
      <!-- name -->
      <label for="leadsCreateOrder__product" class="leadsCreateLink__label">
        <Tr>Продукт</Tr>
      </label>
      <input
        id="leadsCreateOrder__product"
        class="leadsCreateLink__input"
        type="text"
        v-model="name"
        required
      />
      <!-- name -->

      <!-- product id -->
      <label for="leadsCreateOrder__productId" class="leadsCreateLink__label">
        <Tr>ID продукта</Tr>
      </label>
      <input
        id="leadsCreateOrder__productId"
        class="leadsCreateLink__input"
        type="number"
        v-model="productId"
        required
      />
      <!-- product id -->

      <!-- product -->
      <label for="leadsCreateOrder__amount" class="leadsCreateLink__label">
        <Tr>Цена (мин 50)</Tr>
      </label>
      <input
        id="leadsCreateOrder__amount"
        class="leadsCreateLink__input"
        type="number"
        v-model="amount"
        required
      />
      <!-- product -->
    </div>

    <button class="leadsCreateLink__submit" @click="create">
      <Tr>Создать</Tr>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

export default {
  name: "UpdatedLeadsCreateOrder",
  data() {
    return {
      name: "",
      productId: null,
      amount: null,
    };
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      linkCreated: "updatedLeadsCreateOrder/linkCreated",
      errors: "updatedLeadsCreateOrder/errors",
    }),
    nameErrors() {
      if (this.errors) {
        return this.errors.name ? this.errors.name : this.errors.utm_content;
      }
      return [];
    },
  },
  methods: {
    create() {
      this.$store.commit(
        "updatedLeadsCreateOrder/CHANGE_LEAD_ID",
        this.lead.id
      );
      this.$store.commit("updatedLeadsCreateOrder/CHANGE_NAME", this.name);
      this.$store.commit(
        "updatedLeadsCreateOrder/CHANGE_PRODUCT_ID",
        this.productId
      );
      this.$store.commit("updatedLeadsCreateOrder/CHANGE_AMOUNT", this.amount);
      this.$store.dispatch("updatedLeadsCreateOrder/createLink");
      // Promise.all([]).then(response => {
      //   if (!response) {
      //     runNotice("success", this.$t("Статус изменен"));
      //   }
      // });
    },
  },
  watch: {
    errors(value) {
      let errorItems = [...Object.values(value)];
      errorItems = errorItems.reverse();
      errorItems.map(el => runNotice("error", el));
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLink {
  padding: 20px 0;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;

  &__box {
    margin 0 -12px 20px
    padding 0 12px
    border-bottom 2px solid grey_text_color
    @media (min-width: desktop) {
      margin 0 -16px 20px
      padding 0 16px
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font_small();
  }

  &__input {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();
    &:last-of-type {
      margin-bottom 16px
    }
  }

  &__submit {
    padding: 11px 12px;
    width: fit-content;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #fff;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    border: none;
    border-radius: 4px;
    &:hover {
      opacity 0.6
    }
  }
}

@media (min-width: desktop) {
  .leadsCreateLink {
    padding: 0;

    &__input {
      width: 400px;
    }
  }
}

.error {
  &__item {
    color: danger_text_color;
    font-size: 14px;
  }
}

.datePickerBox {
  display: block;
  margin-bottom: 12px;
}

.inputWrap__error {
  position: relative;
  top: -5px;
}

.hiddenInput {
  display: block;
}
</style>
