<template>
  <button class="clearFilters" @click="click">
    <Tr>Сбросить фильтры</Tr>
  </button>
</template>

<script>

export default {
  name: "ClearFilters",
  methods: {
    click() {
      this.$emit("click");
    }
  }
}
</script>

<style lang="stylus" scoped>
.clearFilters {
  letter-spacing: 0.4px;
  font-size: 14px;
  line-height: 17px;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dotted secondary_text_color;
  color: secondary_text_color;
  width: fit-content;

  &:hover {
    opacity: 0.6;
  }
}
</style>
