<template>
  <main class="main" v-if="showAssignment">
    <h1 class="title">
      <Tr>Распределение</Tr>
    </h1>
    <updated-assignment-container />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import UpdatedAssignmentContainer from "@/containers/Assignment/UpdatedAssignmentContainer.vue";

export default {
  name: "UpdatedAssignment",
  components: {
    UpdatedAssignmentContainer,
  },
  computed: {
    ...mapGetters({
      showAssignment: "user/showAssignment",
    }),
  },
  mounted() {
    if (!this.showAssignment) {
      this.$router.replace({ name: "UpdatedFunnel" });
    }
  },
  beforeDestroy() {
    this.$modal.hide("updated-funnel-lead");
    this.$modal.hide("updated-assign-manager");
    this.$modal.hide("updated-remove-manager");
    this.$store.commit("updatedAssignment/CLEAR_STORE");
  },
};
</script>

<style lang="stylus" scoped>
.main {
  width: calc(100vw - 16px);

  @media (min-width: 768px) {
    width: auto;
  }
}
</style>
