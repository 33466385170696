<template>
  <div class="calls">
    <div class="calls__wrap" v-if="hasCalls">
      <span class="calls__headCell">
        <Tr>Телефон</Tr>
      </span>
      <span class="calls__headCell">
        <Tr>Дата и время</Tr>
      </span>
      <span class="calls__headCell">
        <Tr>Продолжительность</Tr>
      </span>
      <template v-for="call in calls">
        <span class="calls__cell" :key="`call-phone-${call.id}`">
          {{ call.phone_number }}
        </span>
        <span class="calls__cell" :key="`call-item-${call.id}`">
          {{ updatedAtDateFormatted(call.created_at) }}
        </span>
        <span class="calls__cell" :key="`call-history-item-${call.id}`">
          {{ formatSeconds(call.duration_seconds) }}
        </span>
      </template>
      <div class="pagination" v-if="amountPages > 1">
        <button
          v-for="n in amountPages"
          :key="n"
          @click="fetchPage(n)"
          class="pagination__button"
          :class="{ pagination__button_active: n === currentPage }"
        >
          {{ n }}
        </button>
      </div>
    </div>
    <div class="calls__noCallsWrap" v-else>
      <no-content :noContent="noContent" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import NoContent from "@/components/NoContent.vue";

export default {
  name: "LeadsProfileCalls",
  components: {
    NoContent
  },
  props: {
    calls: {
      type: Array,
    },
  },
  data() {
    return {
      noContent: {
        text: "Пусто. Пока нет ни одного звонка",
      },
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      currentPage: "leadsCalls/currentPage",
      amountPages: "leadsCalls/amountPages",
    }),
    hasCalls() {
      return !!this.calls.length;
    },
  },
  methods: {
    changeSort(sortType) {
      this.$emit("change-sort", sortType);
    },
    updatedAtDateFormatted(someDate) {
      if (someDate) {
        return moment(someDate)
          .utcOffset(0)
          .locale(this.currentLang)
          .format("DD.MM.YYYY, HH:mm");
      }
    },
    formatSeconds(seconds) {
      return moment(seconds * 1000).format("m:ss");
    },
    fetchPage(n) {
      const { leadId } = this.$route.params;
      this.$store.dispatch("leadsCalls/fetch", { pageNum: n, leadId: leadId });
    },
  },
};
</script>

<style lang="stylus" scoped>
.pagination {
  .pagination__button {
    padding: 2px 8px;
    font_bold();
    color: main_text_color;
    background-color: unset;
    border: none;
    border-bottom: 2px solid transparent;

    &_active {
      color: orange;
      border-bottom: 2px solid orange;
    }
  }
}

.calls {
  max-width: calc(100vw - 36px);

  &__wrap {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: 14px 23px;
    padding-bottom: 20px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #ebe6e7 #fff;

    &::-webkit-scrollbar {
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6e7eb;
      border: 2px solid #fff;
      border-radius: 6px;
    }
  }

  &__headCell {
    padding: 0;
    width: fit-content;
    border: none;
    background-color: unset;
    font_small();
    font-weight: 700;

    @media (min-width: tablet) {
      font_bold();
    }
  }

  &__cell {
    font_regular();
  }

  &__noCallsWrap {
    display: flex;
    place-items: center;
    margin-bottom: 50px;
  }
}
</style>
