import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { filter } from "ramda";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    products(state) {
      return state.data.filter(item => item.can_create_funnel);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", []);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get("/products", axiosConfig(tasqToken));

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
