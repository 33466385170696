<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <h1 class="title titleAutowebinar">
      <Tr>Продаем ещё более лучше</Tr>
    </h1>

    <div class="autowebinar__wrap">
      <div class="autowebinar__header">
        <ul class="tabList">
          <li class="tabItem">
            <button class="tabButton_active tabButton">Описание</button>
          </li>
          <li class="tabItem">
            <button class="tabButton">Вебинар</button>
          </li>
        </ul>
      </div>

      <div class="autowebinar__body"></div>
    </div>

    <!-- ПОПАП -->
    <div class="autowebinar__popupWrap">
      <div class="autowebinar__popup">
        <!-- попап хедер -->
        <div class="autowebinar__popupHeader">
          <h2 class="autowebinar__popupHeaderTitle">
            <Tr>Редактировать зрителя</Tr>
          </h2>
          <div class="autowebinar__popupHeaderBox">
            <button class="autowebinar__popupHeaderButton" type="button">
              <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
                <close-button />
              </base-icon-svg>
            </button>
          </div>
        </div>
        <!-- конец попап хедер -->

        <!-- попап боди -->
        <div class="autowebinar__popupBody">
          <div class="autowebinar__popupBodyAvatarWrap">
            <div class="autowebinar__popupBodyAvatar">
              <img
                src="@/assets/images/profile_avatar@2x.jpg"
                alt="аватар зрителя"
                class="autowebinar__popupBodyAvatarImg"
              />
            </div>
            <div class="autowebinar__popupBodyAvatarEditWrap">
              <button
                type="button"
                class="autowebinar__popupBodyAvatarEditButton"
              >
                <base-icon-svg>
                  <editing-edit />
                </base-icon-svg>
              </button>
              <button
                type="button"
                class="autowebinar__popupBodyAvatarEditButton"
              >
                <base-icon-svg>
                  <editing-delete />
                </base-icon-svg>
              </button>
            </div>
          </div>
          <div class="autowebinar__popupBodyName">
            <label for="createAudiencename" class="autowebinar__popupBodyLabel">
              <Tr>Имя</Tr>
            </label>
            <input
              type="text"
              name=""
              id="createAudiencename"
              class="autowebinar__popupBodyInput"
              value="Василий"
            />
          </div>
        </div>
        <!-- конец попап боди -->

        <!-- попап футер -->
        <div class="autowebinar__popupFooter">
          <button
            class="autowebinar__popupFooterButton autowebinar__popupFooterButton_submit"
            type="button"
          >
            <Tr>Сохранить</Tr>
          </button>
        </div>
        <!-- конец попап футер -->
      </div>
    </div>
    <!-- КОНЕЦ ПОПАПА -->
  </main>
</template>

<script>
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";
import EditingEdit from "@/components/SvgIcons/EditingEdit.vue";

export default {
  name: "Autowebinars",
  components: {
    CloseButton,
    EditingDelete,
    EditingEdit,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        { title: "Автовебинары", link: "/leadership/autowebinars" },
      ],
      NAME_MAX_LENGTH: 64,
      noContent: { noContacts: true, text: "Загрузите слайды и видео" },
    };
  },
};
</script>

<style lang="stylus" scoped>
.titleAutowebinar {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-wrap: wrap;
}

.tabList {
  margin-right: -16px;
  margin-left: -16px;
  border-bottom: 4px solid grey_text_color;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  padding: 4px 16px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);
}

.tabButton_active {
  position: relative;
}

.tabButton_active::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff9f43;
}

.autowebinar__wrap {
  padding: 8px 16px 20px;
  min-width: 692px;
  width fit-content
  border-top: 3px solid transparent;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;
}

.autowebinar__body {
  position: relative;
  padding-top: 16px;
}

.autowebinar__popup {
  position: relative;
  top: 159px;
  right: 0;
  left: -1px;
  margin auto
  padding 16px 16px 20px 16px
  width 640px
  background-color white
  box-shadow: 0px 3px 10px #0F164214;
  border-radius: 8px;
  box-sizing border-box
  &Wrap {
    position: fixed;
    top: 1px;
    z-index: 10;
    left: -8px;
    width: 100vw;
    height: 100vh;
    background-color: #0F164250;
  }
  &Header {
    display flex
    justify-content space-between
    padding-bottom 12px
    &Title {
      font(main_text_color, 20px, 700, 24px);
    }
    &Button {
      padding 0
      width 24px
      height 24px
      background-color unset
      border unset
    }
  }
  &Body {
    padding 0px 0px 16px
    &Avatar {
      width 120px
      height 120px
      border-radius 50%
      &Wrap {
        display grid
        grid-template-columns 120px 16px
        grid-column-gap 22px
        align-items center
        margin-top: 4px;
        margin-bottom 20px
        margin-left: 4px;
      }
      &Img {
        width 100%
        height 100%
      }
      &Edit {
        &Button {
          display flex
          justify-content center
          align-items center
          padding unset
          width 16px
          height 16px
          background-color unset
          border none
          &:first-child {
            margin-bottom 11px
          }
        }
      }
    }
    &Name {
      margin-left 4px
    }
    &Label {
      display block
      margin-bottom 3px
      font(main_text_color, 12px, 400, 16px);
    }
    &Input {
      padding 7px 7px 7px 8px
      width: 240px;
      height: 32px;
      border-radius: 2px;
      border: 1px solid grey_text_color;
      font(main_text_color, 14px, 400, 18px);
    }
  }

  &Footer {
    position relative
    border-top 2px solid grey_text_color
    margin 0 -16px
    padding 20px 16px 0px
    &Button {
      padding 11px 12px
      border-radius: 4px;
      border: unset;
      font_regular();
      &_submit {
      background: transparent linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
      color: #fff;
      }
    }
  }
}
</style>
