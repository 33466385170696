<template>
  <div class="filter__wrap">
    <div class="filterSearch">
      <button
        @click="handleFilter"
        class="filterSearch__filter"
        :class="{ filterSearch__filter_active: filtersIsActive }"
      >
        <svg>
          <use
            xlink:href="@/assets/svg/sprite_filter_menu.svg#filter--active"
            v-if="filtersIsActive"
          />
          <use xlink:href="@/assets/svg/sprite_filter_menu.svg#filter" v-else />
        </svg>
        {{ $t("Фильтр") }}
      </button>
      <ContactsMenuFilterSearchInput @search="changeFilter" />
    </div>

    <ContactsFilterBody
      v-show="filtersIsActive"
      :isFilterChecked="isFilterChecked"
      :tags="tags"
      :filters="filters"
      :pickedAllTags="pickedAllTags"
      :tagsFilterValue="tagsFilterValue"
      :tagsFilterCounter="tagsFilterCounter"
      @changeFilter="changeFilter"
      @pickAllTags="pickAllTags"
      @clearFilters="clearFilters"
      @changeTagsFilterValue="changeTagsFilterValue"
      @changeTagsFilterCounter="changeTagsFilterCounter"
    />
  </div>
</template>

<script>
import ContactsMenuFilterSearchInput from "@/components/Contacts/ContactsMenuFilterSearchInput.vue";
import ContactsFilterBody from "@/components/Contacts/ContactsFilterBody.vue";

export default {
  name: "ContactsMenuFilterSearch",

  components: {
    ContactsMenuFilterSearchInput,
    ContactsFilterBody,
  },

  props: {
    isFilterChecked: {
      type: Function,
    },

    filtersIsActive: Boolean,

    tags: {
      type: Array,
      required: true,
    },

    filters: {
      type: Object,
    },

    tagsFilterValue: {
      type: [String, Number],
    },

    tagsFilterCounter: {
      type: [String, Number],
    },

    pickedAllTags: {
      type: Boolean,
    },
  },

  methods: {
    changeFilter(payload) {
      this.$emit('change-filter', payload)
    },

    clearFilters() {
      this.$emit('clearFilters')
    },

    handleFilter() {
      this.$emit("handleFilter");
    },

    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },

    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },

    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterSearch {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  width: fit-content;
}

.filterSearch__filter {
  position: relative;
  padding: 0 8px 0 25px;
  width: fit-content;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  font_regular();

  &:hover {
    opacity: 0.6;
  }
}

.filterSearch__filter svg {
  position: absolute;
  bottom: 5px;
  left: 0px;
  width: 16px;
  height: 16px;
}

.filterSearch__filter_active {
  border-bottom: 4px solid grey_text_color;
}

.filterSearch__filter_active svg {
  width: 16px;
  height: 19px;
}
</style>
