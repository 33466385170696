<template>
  <modal-layout
    :pending="pending || meetingsTypesPending"
    :name="modalName"
    @before-close="closeModal"
  >
    <template #title>Создать встречу</template>
    <template #body>
      <!-- SELECT -->
      <div class="contactMeetingModal__meetingsTypes meetingsTypesSelect">
        <label class="meetingsTypesSelect__label" for="meetingType">
          <Tr>Тип встречи</Tr>
        </label>
        <select
          class="meetingsTypesSelect__selectItem"
          id="meetingType"
          @change="changeMeetingType"
        >
          <option selected disabled />
          <option
            v-for="type in meetingsTypes"
            :value="type.param"
            :key="type.param"
          >
            {{ type.verbose }}
          </option>
        </select>
      </div>
      <!-- SELECT -->

      <div class="inputBox">
        <base-input-text
          class="contactMeetingModal__name"
          title="Тема встречи"
          labelId="meeting_topic"
          @handle-input="handleInput"
          :maxlength="MAX_TEXT_LENGTH"
        />
        <div class="inputCounterBox">
          <span class="inputCounter">
            <span
              :class="{
                inputCounter_red: valueLength === MAX_TEXT_LENGTH,
              }"
              >{{ valueLength }}</span
            >
            /{{ MAX_TEXT_LENGTH }}
          </span>
        </div>
      </div>
      <div class="contactMeetingModal__datePickerBox datePickerBox">
        <label class="label">
          <Tr>Дата и время</Tr>
        </label>
        <date-picker
          type="datetime"
          format="DD MMM YYYY, HH:mm"
          :lang="
            ['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale
          "
          v-model="startedAt"
          :default-value="new Date().setHours(10, 0, 0, 0)"
          :disabled-date="notBeforeToday"
        />
      </div>
      <form-submit @click="createMeeting" :disabled="createBtnDisabled" />
    </template>
  </modal-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

import ModalLayout from "@/components/ModalLayout.vue";
import DatePicker from "vue2-datepicker";
import FormSubmit from "@/components/FormSubmit.vue";

export default {
  name: "ContactsProfileModalCreateMeeting",

  components: {
    ModalLayout,
    DatePicker,
    FormSubmit,
  },

  data() {
    return {
      modalName: "create-meeting",
      valueLength: 0,
      MAX_TEXT_LENGTH: 250,
    };
  },

  computed: {
    ...mapGetters({
      pending: "leadsCreateLink/pending",
      errors: "contactsProfileMeetings/errors",
      createdMeeting: "contactsProfileMeetings/createdMeeting",
      isDev: "user/isDev",
      contact: "contactsProfile/contact",
      meetingsTypes: "contactsProfileMeetings/meetingsTypes",
      meetingsTypesPending: "contactsProfileMeetings/meetingsTypesPending",
    }),

    startedAt: {
      get() {
        return this.$store.getters["contactsProfileMeetings/startedAt"];
      },

      set(value) {
        this.$store.commit(
          "contactsProfileMeetings/SET_MEETING_STARTED_AT",
          value
        );
      },
    },

    createBtnDisabled() {
      if (this.createdMeeting.type === null) return true;
      else if (this.createdMeeting.topic.length === 0) return true;
      else if (this.createdMeeting.started_at === null) return true;
      return false;
    },

    activeTaskTab() {
      const pathNamearr = this.$route.path.split("/");
      return pathNamearr[pathNamearr.length - 1];
    },
  },

  methods: {
    changeMeetingType(e) {
      this.$store.commit(
        "contactsProfileMeetings/SET_MEETING_TYPE",
        e.target.value
      );
    },

    handleInput(value) {
      this.$store.commit("contactsProfileMeetings/SET_MEETING_TOPIC", value);
      this.valueLength = value.length;
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    closeModal() {
      this.$store.commit("contactsProfileMeetings/CLEAR_MEETING");
      this.valueLength = 0;
    },

    createMeeting() {
      const date = new Date(this.createdMeeting.started_at).toISOString();

      const contactMeetsData = {
        type: this.createdMeeting.type,
        title: this.createdMeeting.topic,
        started_at: date,
        contact: this.contact.id,
      };

      Promise.resolve(
        this.$store.dispatch(
          "contactsProfileMeetings/createMeetingType",
          contactMeetsData
        )
      )
        .then(response => {
          if (response && response.status === 201) {
            const data = {
              object_id: this.contact.id,
              contact: this.contact.id,
              content_type: 41,
              title: this.createdMeeting.topic,
              ended_at: date,
              type: "Встреча с контактом",
            };
            Promise.resolve(
              this.$store.dispatch("contactsProfileMeetings/createMeeting", {
                ...data,
                object_id: response.data.id,
              })
            )
              .then(response => {
                if (response && response.status === 201) {
                  runNotice("success", this.$t("Встреча создана"));
                  this.$modal.hide(this.modalName);
                  this.$store.dispatch("contactsProfileMeetings/fetch", {
                    page: 1,
                    contact: this.contact.id,
                    filter: this.activeTaskTab,
                  });
                }
              })
              .catch(e => {
                console.log(e);
              });
          }
        })
        .catch(e => {
          runNotice("error", this.$t("Не удается создать встречу"));
          console.log(e);
        });
    },
  },

  watch: {
    errors(val) {
      Object.values(val).forEach(el => runNotice("error", el));
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactMeetingModal__name, .contactMeetingModal__datePickerBox, .contactMeetingModal__textarea, .contactMeetingModal__meetingsTypes {
  margin-bottom: 16px;
}

.datePickerBox {
  display: block;
}

.meetingsTypesSelect {
  &__label {
    display: block;
    margin-bottom: 4px;
    font(main_text_color, 12px, 400, 16px);
  }

  &__selectItem {
    display: block;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    &:disabled {
      background-color: #FAFAFA;
    }
  }
}

.label {
  display: block;
  margin-bottom: 4px;
  font(main_text_color, 12px, 400, 16px);
}

.inputBox {
  position: relative;
  width: fit-content;
}

.inputCounterBox {
  position: absolute;
  top: 0;
  right: 0;
}

.inputCounter {
  font_small();
  opacity: 0.6;

  & span {
    font-weight: 700;
  }

  &_red {
    color: #e81526;
  }
}
</style>
