<template>
  <div class="tagWrap">
    <!-- deleted tag -->
    <template v-if="contactTag.deleted">
      <div class="deletedTag">
        <Tr class="deletedTagText">Тег удален.</Tr>
        <button type="button" class="deletedTagBtn" @click="restoreTag">
          <Tr>Восстановить</Tr>
        </button>
      </div>
    </template>
    <!-- deleted tag -->

    <!-- tag -->
    <template v-else>
      <span
        class="tagsPopupItem"
        :style="{
          backgroundColor: `#${contactTag.color}`,
          color: `#${contactTag.text_color}`,
        }"
      >
        {{ contactTag.name }}
      </span>
      <div class="editWrap">
        <button type="button" class="tagBtn tagBtn_edit" @click="editTag">
          <base-icon-svg width="20" height="20" viewbox="0 0 20 20">
            <editing-edit />
          </base-icon-svg>
        </button>
        <button type="button" class="tagBtn tagBtn_del" @click="delTag">
          <base-icon-svg width="20" height="20" viewbox="0 0 20 20">
            <editing-delete />
          </base-icon-svg>
        </button>
      </div>
    </template>
    <!-- tag -->
  </div>
</template>

<script>
import { runNotice } from "@/utils/notifications";
import { mapGetters } from "vuex";

import EditingEdit from "@/components/SvgIcons/EditingEdit.vue";
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";

export default {
  name: "ContactsModalTagsItem",

  components: {
    EditingEdit,
    EditingDelete,
  },

  props: {
    tag: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      contactTag: {},
      tagIsDeleted: false,
    };
  },

  computed: {
    ...mapGetters({
      currentPage: "contacts/currentPage",
    }),
  },

  methods: {
    setContactTag() {
      this.contactTag = { ...this.tag };
    },

    dblclick(event) {
      event.preventDefault();
    },

    editTag() {
      this.$emit("editTag", this.contactTag);;
    },

    delTag() {
      this.$store.commit('contactsTags/SET_DELETED', this.contactTag)
      this.setContactTag();
      this.$emit("setContactTags");
      this.tagIsDeleted = true;

      setTimeout(() => {
        if (this.tagIsDeleted) {
          this.$store.dispatch('contactsTags/delTagFromTags', this.contactTag)
            .then(response => {
              if (response && response.status === 204) {
                Promise.resolve(this.$store.commit("contacts/CLEAR_FILTERS"))
                  .then(() => {
                    this.$store.dispatch("contacts/fetch", this.currentPage);
                  })
                  .catch(e => { console.log(e) });
                this.$store.dispatch("contactsTags/fetch")
                  .then(() => {
                    this.$emit("setContactTags");
                  })
                  .catch(e => { console.log(e) });
                runNotice('success', this.$t('Тег удален'))
              } else {
                runNotice('error', this.$t('Тег не удален'))
              }
            })
            .then(() => {
              this.tagIsDeleted = false;
            })
            .catch(e => { console.log(e); });
        }
      }, 4800)
    },

    restoreTag() {
      this.tagIsDeleted = false;
      this.$store.commit('contactsTags/SET_RESTORED', this.contactTag)
      this.setContactTag();
      this.$emit("setContactTags");
    },
  },

  beforeDestroy() {
    if (this.tagIsDeleted) {
      this.tagIsDeleted = false;
      this.$store.dispatch('contactsTags/delTagFromTags', this.contactTag)
        .then(response => {
          if (response && response.status === 204) {
            Promise.resolve(this.$store.commit("contacts/CLEAR_FILTERS"))
              .then(() => {
                this.$store.dispatch("contacts/fetch", this.currentPage)
              })
              .catch(e => { console.log(e) });
            this.$store.dispatch("contactsTags/fetch")
              .then(() => {
                this.$emit("setContactTags");
              })
              .catch(e => { console.log(e) });
            runNotice('success', this.$t('Тег удален'))
          } else {
            runNotice('error', this.$t('Тег не удален'))
          }
        })
        .catch(e => { console.log(e); });
    }
  },

  mounted() {
    this.setContactTag();
  },
};
</script>

<style lang="stylus" scoped>
.tagBtn {
  display: flex;
  align-items: center;
  padding: 0;
  background-color: transparent;
  border: none;

  @media (min-width: desktop) {
    opacity: 0;
    pointer-events: none;
  }
}

.tagWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  max-width: 280px;
  border-radius: 2px;

  @media (min-width: desktop) {
    max-width: 400px;
  }

  &:hover {
    @media (min-width: desktop) {
      background-color: grey_text_color;

      .tagBtn {
        opacity: 1;
        pointer-events: all;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.tagsPopupItem {
  position: relative;
  padding: 2px 3px 4px;
  border: none;
  border-radius: 2px;
  width: fit-content;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;

  @media (min-width: desktop) {
    max-width: 320px;
  }
}

.editWrap {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
}

.deletedTag {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 5px;
  align-items: center;
  min-height: 20px;
}

.deletedTagText {
  fontMainColor(12px, 700, 14px);
}

.deletedTagBtn {
  display: inline;
  padding: 0;
  border: none;
  border-bottom: 1px dotted secondary_text_color;
  background-color: unset;
  font-size: 12px;
  line-height: 14px;
  background-image: linear-gradient(
    to left,
    transparent 0%,
    transparent 50%,
    #e8152777 50%,
    #e8152777 100%
  );
  background-size: 200% auto;
  background-position: -100% center;
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
  // -webkit-box-decoration-break: clone;
  color: secondary_text_color;
  animation: shine 5s linear;

  @keyframes shine {
    to {
      background-position: 0% center;
    }
  }
}
</style>
