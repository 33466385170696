<template>
  <div class="bonuses">
    <div
      class="bonuses__item bonusesItem diamonds"
      v-if="diamonds || positionIsHeader"
    >
      <span
        :class="[
          'bonusesItem__counter',
          'bonusesCounter',
          { bonusesCounter_dark: darkNums },
        ]"
      >
        {{ diamonds }}
      </span>
      <div class="bonusesIconBox">
        <img
          class="
            bonusesItem__icon bonusesItem__icon_diamonds
            bonusesIcon
            diamondsIcon
          "
          alt="бриллианты"
          src="@/assets/images/diamond@2x.png"
        />
      </div>
      <div
        class="
          bonusesItem__tooltip
          bonusesItemTooltip bonusesItemTooltip_mobile
        "
        ref="bonusesItemTooltipDiamonds"
        v-tooltip="{ content: diamondsTootip, trigger: 'click' }"
        @click.stop="console(diamondsTootip)"
      />
      <div
        class="
          bonusesItem__tooltip
          bonusesItemTooltip bonusesItemTooltip_desktop
        "
        v-tooltip="{ content: diamondsTootip, trigger: 'hover' }"
      />
    </div>

    <div class="bonuses__item bonusesItem gold" v-if="gold || positionIsHeader">
      <span
        :class="[
          'bonusesItem__counter',
          'bonusesCounter',
          { bonusesCounter_dark: darkNums },
        ]"
      >
        {{ gold }}
      </span>
      <div class="bonusesIconBox">
        <img
          class="bonusesItem__icon bonusesItem__icon_gold bonusesIcon goldIcon"
          alt="золото"
          src="@/assets/images/gold@2x.png"
        />
      </div>

      <div
        class="
          bonusesItem__tooltip
          bonusesItemTooltip bonusesItemTooltip_mobile
        "
        ref="bonusesItemTooltipGold"
        v-tooltip="{ content: goldTootip, trigger: 'click' }"
        @click.stop="console(goldTootip)"
      />
      <div
        class="
          bonusesItem__tooltip
          bonusesItemTooltip bonusesItemTooltip_desktop
        "
        v-tooltip="{ content: goldTootip, trigger: 'hover' }"
      />
    </div>

    <div class="bonuses__item bonusesItem bonus" v-if="false">
      <span
        :class="[
          'bonusesItem__counter',
          'bonusesCounter',
          { bonusesCounter_dark: darkNums },
        ]"
      >
        {{ bonuses }}
      </span>
      <div class="bonusesIconBox">
        <img
          class="
            bonusesItem__icon bonusesItem__icon_bonus
            bonusesIcon
            bonusIcon
          "
          alt="бонусы"
          src="@/assets/svg/ico_bonuses.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Bonuses",

  props: {
    diamonds: {
      type: Number,
      default: 0,
    },
    gold: {
      type: Number,
      default: 0,
    },
    bonuses: {
      type: Number,
      default: 0,
    },
    darkNums: {
      type: Boolean,
      default: false,
    },
    positionIsHeader: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      bnName: "user/bnName",
    }),
    diamondsTootip() {
      if (this.positionIsHeader) return this.$t('Бриллианты начисляются наставникам');

      return this.$t('Награда в бриллиантах');
    },
    goldTootip() {
      if (this.positionIsHeader) return this.$t('Золотые монеты начисляются за своевременное выполнение заданий в разделе {training}.', { training: this.bnName });

      return this.$t('Награда в золоте');
    },
  },

  methods: {
    console(info) {
      console.info(info)
    },
  },
};
</script>

<style lang="stylus" scoped>
.bonuses {
  display: grid;
  grid-row-gap: 4px;
}

.bonusesItem {
  position: relative;
  z-index: 1;
  display: grid;
  grid-column-gap: 4px;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  cursor: help;
}

.bonusesCounter {
  font(main_text_color, 10px, 700, 12px);

  @media (min-width: tablet_large) {
    color: #fff;
    font-size: 12px;
    line-height: 16px;

    &_dark {
      color: main_text_color;
    }
  }
}

.bonusesIconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
}

.bonusIcon {
  display: block;
  width: 16px;
}

.diamondsIcon {
  width: 16px;
  height: 13px;
}

.goldIcon {
  width: 12px;
  height: 16px;
  display: inline-block;
}

.bonusIcon {
  width: 14px;
  height: 14px;
}

.bonusesItemTooltip {
  position: absolute;
  z-index: 2;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;

  &_desktop {
    display: none;

    @media (min-width: tablet) {
      display: block;
    }
  }

  &_mobile {
    @media (min-width: tablet) {
      display: none;
    }
  }
}
</style>
