<template>
  <fieldset class="createTagColor">
    <legend class="createTagColor__legend contactsTagColorLegend">
      {{ $t("Цвет") }}
    </legend>
    <div class="contactsTagColorInputWrap">
      <label
        class="contactsTagColorInputBox"
        v-for="colorItem in colors"
        :key="colorItem.color"
      >
        <input
          class="contactsTagColorInput"
          name="contactsTagColor"
          type="radio"
          :value="colorItem.color"
          :checked="color === colorItem.color"
          @change="changeColor"
        />
        <span
          class="contactsTagColorCheckbox"
          :style="`backgroundColor: #${colorItem.color}`"
        />
      </label>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "ContactsProfileCreateTagColor",

  props: {
    colors: {
      type: Array,
      required: true,
    },

    color: {
      type: String,
    },
  },

  methods: {
    changeColor(e) {
      this.$emit('change-color', e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsTagColorInputWrap {
  display: grid;
  grid-template-columns: repeat(7, 20px);
  grid-gap: 2px;
}

.createTagColor {
  margin-bottom: 20px;

  &__legend {
    margin-bottom: 4px;
  }
}

.contactsTagColorLegend {
  display: block;
  font_small();
}

.contactsTagColorInputBox {
  position: relative;
  padding-bottom: 20px;
  padding-left: 20px;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.contactsTagColorInput {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;

  &:checked+.contactsTagColorCheckbox {
    border: 1px solid #000;
    box-shadow: 0px 0px 1px 1px main_text_color;
  }
}

.contactsTagColorCheckbox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 20px;
  height: 20px;
  border-radius: 2px;
}
</style>
