<template>
  <div class="contactsBodyItem">
    <!-- avatar -->
    <div @click="openContact" class="contactsBodyItemButton">
      <avatar :avatarName="name" :contacts="true" />
    </div>
    <!-- avatar -->

    <!-- name -->
    <div
      @click="openContact"
      class="contactsBodyItemCell contactsBodyItemButton"
      v-tooltip="name"
    >
      <Tr class="contactsBodyItemMobileText">Имя</Tr>
      {{ name }}
    </div>
    <!-- name -->

    <div
      class="contactsBodyItemCell contactsBodyItemCell_withoutFade"
      v-if="contact.source_name"
    >
      <Tr class="contactsBodyItemMobileText">Источник</Tr>
      <div
        :class="[
          'contactsBodyItemSource',
          {
            contactsBodyItemSource_manual: contact.source_name === 'manual',
            contactsBodyItemSource_union: contact.source_name === 'union',
          },
        ]"
      >
        {{ contactsSource }}
        <div
          class="contactsBodyItemTooltip contactsBodyItemTooltip_mobile"
          v-tooltip="{
            trigger: 'click',
            content: contact.source_name_verbose,
          }"
        />
        <div
          class="contactsBodyItemTooltip contactsBodyItemTooltip_desktop"
          v-tooltip="{
            trigger: 'hover',
            content: contact.source_name_verbose,
          }"
        />
      </div>
    </div>

    <!-- phone -->
    <div class="contactsBodyItemCell" v-if="0">
      <Tr class="contactsBodyItemMobileText">Телефон</Tr>
      <a v-if="phone" :href="`tel:${phone}`" class="contactsBodyItemPhone">
        {{ phone }}
      </a>
      <span v-else class="contactsBodyItemPhone">-</span>
    </div>
    <!-- phone -->

    <!-- socials -->
    <div class="contactsBodyItemSocials">
      <Tr class="contactsBodyItemMobileText">Связь</Tr>
      <a
        v-if="contact.telegram"
        class="contactsBodyItemSocialsLink"
        :href="`https://t.me/${telegram}`"
        target="_blank"
        rel="noopener noreferrer"
        v-tooltip="socialsTooltip('telegram')"
      >
        <base-icon-svg width="20" height="20" viewbox="0 0 20 20">
          <telegram-icon />
        </base-icon-svg>
      </a>
      <a
        v-if="contact.whatsapp"
        class="contactsBodyItemSocialsLink"
        :href="`https://api.whatsapp.com/send?phone=${contact.whatsapp}`"
        target="_blank"
        rel="noopener noreferrer"
        v-tooltip="socialsTooltip('whatsapp')"
      >
        <base-icon-svg width="20" height="20" viewbox="0 0 20 20">
          <whatsapp-icon />
        </base-icon-svg>
      </a>
      <a
        v-if="phone"
        class="contactsBodyItemSocialsLink"
        :href="`tel:${phone}`"
        v-tooltip="phone"
      >
        <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
          <leads-phone-icon />
        </base-icon-svg>
      </a>
      <span v-if="!contact.telegram && !contact.whatsapp && !phone">-</span>
    </div>
    <!-- socials -->

    <!-- progress -->
    <div class="contactsBodyItemProgress">
      <Tr class="contactsBodyItemMobileText">Прогресс</Tr>
      <div class="contactsBodyItemProgressBox">
        <span
          v-for="progressItem in 10"
          :key="progressItem"
          :class="[
            'contactsBodyItemProgressItem',
            {
              contactsBodyItemProgressItem_active:
                progressItem - 1 <= contact.progress,
            },
          ]"
        />
        <div
          class="contactsBodyItemTooltip contactsBodyItemTooltip_mobile"
          v-tooltip="{
            trigger: 'click',
            content: progressTooltip(contact.progress),
          }"
        />
        <div
          class="contactsBodyItemTooltip contactsBodyItemTooltip_desktop"
          v-tooltip="{
            trigger: 'hover',
            content: progressTooltip(contact.progress),
          }"
        />
      </div>
    </div>
    <!-- progress -->

    <!-- heat -->
    <div class="contactsBodyItemCell">
      <Tr class="contactsBodyItemMobileText">Подогретость</Tr>
      <div class="contactsBodyItemHeat">
        <div class="contactsBodyItemHeatBox" v-if="contactHeat">
          <base-icon-svg
            class="contactsBodyItemHeatImg"
            width="120"
            height="20"
            viewbox="0 0 120 20"
          >
            <ContactsHeatCold v-if="contactHeat === 'cold'" />
            <ContactsHeatWarm v-else-if="contactHeat === 'warm'" />
            <ContactsHeatHot v-else-if="contactHeat === 'hot'" />
          </base-icon-svg>
          <div
            class="contactsBodyItemTooltip contactsBodyItemTooltip_mobile"
            v-tooltip="{
              trigger: 'click',
              content: heat,
            }"
          />
          <div
            class="contactsBodyItemTooltip contactsBodyItemTooltip_desktop"
            v-tooltip="{
              trigger: 'hover',
              content: heat,
            }"
          />
        </div>
        <span v-else>-</span>
      </div>
    </div>
    <!-- heat -->

    <!-- top -->
    <div class="contactsBodyItemCell">
      <Tr class="contactsBodyItemMobileText">Топ</Tr>
      <div class="contactsBodyItemTop">
        <Tr
          v-if="contactTop === '100'"
          class="contactsBodyItemTopInner contactsBodyItemTopInner_100"
          >ТОП 100</Tr
        >
        <Tr
          v-else-if="contactTop === '20_and_100'"
          class="contactsBodyItemTopInner contactsBodyItemTopInner_20"
          >ТОП 20</Tr
        >
        <span v-else>-</span>
      </div>
    </div>
    <!-- top -->

    <!-- tags -->
    <div
      class="
        contactsBodyItemCell
        contactsBodyItemCell_tags
        contactsBodyItemCell_withoutFade
      "
    >
      <Tr class="contactsBodyItemMobileText">Теги</Tr>
      <div class="contactsBodyItemTagsWrap" @mousedown.left="onMouseDown">
        <vue-horizontal
          class="horizontal"
          :button="false"
          ref="horizontal"
          snap="none"
          @scroll="onScroll"
        >
          <div class="contactsBodyItemTags">
            <div
              class="contactsBodyItemTagsItem"
              v-tooltip="tag.name"
              v-for="tag in contact.tag_objects"
              :key="tag.id"
              :style="{
                backgroundColor: `#${tag.color}`,
                color: `#${tag.text_color}`,
              }"
            >
              <span>{{ tag.name }}</span>
              <button
                class="contactsBodyItemTagsItemDel"
                type="button"
                :style="{ backgroundColor: `#${tag.color}` }"
                @dblclick="dblclick"
                @click="delTag(tag.id, contact.id)"
              >
                <span
                  :style="{
                    background: `linear-gradient(91deg, #${tag.color}00 0%, #${tag.color} 100%)`,
                  }"
                />
                <base-icon-svg
                  width="8"
                  height="8"
                  viewbox="0 0 8 8"
                  :fill="`#${tag.color}`"
                >
                  <contacts-tags-del
                    :style="{
                      color: tag.text_color,
                    }"
                  />
                </base-icon-svg>
              </button>
            </div>
          </div>
        </vue-horizontal>
      </div>
      <div class="contactsBodyItemAddTags">
        <v-popover placement="right">
          <button
            type="button"
            class="tooltip-target b3 contactsBodyItemAddTagsButton"
          />
          <template slot="popover">
            <contacts-body-item-tags-popup
              :tags="tags"
              :contact="contact"
              v-close-popover
              @addTag="addTag"
            />
          </template>
        </v-popover>
      </div>
    </div>
    <!-- tags -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import contactName from "@/mixins/contactName.js";

import Avatar from "@/components/Avatar.vue";
import BaseIconSvg from "@/components/common/BaseIconSvg.vue";
import TelegramIcon from "@/components/SvgIcons/TelegramIcon.vue";
import WhatsappIcon from "@/components/SvgIcons/WhatsappIcon.vue";
import ContactsHeatCold from "@/components/SvgIcons/contacts/ContactsHeatCold.vue";
import ContactsHeatWarm from "@/components/SvgIcons/contacts/ContactsHeatWarm.vue";
import ContactsHeatHot from "@/components/SvgIcons/contacts/ContactsHeatHot.vue";
import ContactsTagsDel from "@/components/SvgIcons/contacts/ContactsTagsDel.vue";
import VueHorizontal from "vue-horizontal";
import ContactsBodyItemTagsPopup from "@/components/Contacts/ContactsBodyItemTagsPopup.vue";
import LeadsPhoneIcon from "@/components/SvgIcons/LeadsPhoneIcon.vue";

export default {
  name: "ContactsBodyItem",
  components: {
    Avatar,
    BaseIconSvg,
    TelegramIcon,
    WhatsappIcon,
    ContactsHeatCold,
    ContactsHeatWarm,
    ContactsHeatHot,
    ContactsTagsDel,
    VueHorizontal,
    ContactsBodyItemTagsPopup,
    LeadsPhoneIcon,
  },

  props: {
    contact: {
      type: Object,
      required: true,
    },
    progresses: {
      type: Array,
      required: true,
    },
    contactHeatArray: {
      type: Array,
      required: true,
    },
    contactTopArray: {
      type: Array,
      required: true,
    },
    tags: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      left: 0,
      originX: 0,
      originLeft: 0,
    };
  },

  mixins: [contactName],

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),

    link() {
      return `/${this.currentLang}/contacts/${this.contact.id}`;
    },

    phone() {
      return this.contact.phones_objects.length > 0
        ? this.contact.phones_objects[0].value
        : "";
    },

    telegram() {
      const formattedTelegramNickname = this.contact.telegram.replace("@", "");
      return formattedTelegramNickname;
    },

    heat() {
      const heatEl = this.contactHeatArray.find(
        el => el.param === this.contact.heat
      );
      return heatEl ? heatEl.verbose : "";
    },

    contactTop() {
      return this.contact.top;
    },

    contactHeat() {
      return this.contact.heat;
    },
    contactsSource() {
      const name = this.contact.source_name;
      return name.substring(0, 1);
    },
  },

  methods: {
    socialCapitalizeFirstLetter(social) {
      const socialNetwork = social.split("");
      const socialNetworkFirstLetter = socialNetwork[0].toUpperCase();
      return socialNetworkFirstLetter + socialNetwork.splice(1).join("");
    },

    socialsTooltip(social) {
      return ` ${this.$t("Написать в {social}", {
        social: this.socialCapitalizeFirstLetter(social),
      })}  ${this.contact[social]}`;
    },

    progressTooltip(progress) {
      const progressEl = this.progresses.find(el => el.param === progress);
      return progressEl ? progressEl.verbose : "";
    },

    dblclick(event) {
      event.preventDefault();
    },

    addTag(payload) {
      this.$emit('addTag', payload);
    },

    delTag(tagId, contactId) {
      this.$emit('delTag', { tagId, contactId });
    },

    onScroll({ left }) {
      this.left = left;
    },
    onMouseDown(e) {
      this.originX = e.pageX;
      this.originLeft = this.left;

      window.addEventListener("mouseup", this.onMouseUp);
      window.addEventListener("mousemove", this.onMouseMove);
    },
    onMouseUp() {
      window.removeEventListener("mouseup", this.onMouseUp);
      window.removeEventListener("mousemove", this.onMouseMove);
    },
    onMouseMove(e) {
      const offset = e.pageX - this.originX;
      const left = this.originLeft - offset;
      this.$refs.horizontal.scrollToLeft(left, "auto");
    },

    openTagsModal() {
      this.$modal.show("contact-tags-management", { contact: this.contact })
    },

    openContact() {
      const routeData = this.$router.resolve({ path: this.link });
      window.open(routeData.href, "_blank");
    },
  },

  destroyed() {
    this.onMouseUp();
  },
};
</script>

<style lang="stylus" scoped>
.contactsBodyItem {
  position: relative;
  z-index: 1;
  display: grid;
  grid-row-gap: 4px;
  width: 100%;
  max-width: 360px;

  @media (min-width: phone_xl) {
    max-width: 315px;
  }

  @media (min-width: desktop) {
    grid-row-gap: unset;
    margin-bottom: 0;
    border-radius: 10px 0 0 10px;
    max-width: unset;

    &:hover {
      background-color: #f5f5f7;

      .contactsBodyItemCell {
        &::after {
          background-image: linear-gradient(90deg, #f5f5f700 0%, #f5f5f7 100%);
        }
      }

      .contactsBodyItemAddTagsButton {
        display: block;
      }

      .horizontal-wrap {
        &::after {
          background-image: linear-gradient(90deg, #FFFFFF00 0%, grey_text_color 100%);
        }
      }
    }
  }
}

.contactsBodyItemCell {
  position: relative;
  overflow: hidden;
  white-space: nowrap;

  &:not(.contactsBodyItemCell_withoutFade) {
    cell_fade();
  }

  &_tags {
    display: grid;
    grid-auto-flow: column;
    width: fit-content;
    overflow: visible;
    grid-column-gap: 4px;

    @media (min-width: desktop) {
      grid-column-gap: 2px;
    }
  }
}

.contactsBodyItemProgress, .link, .contactsBodyItemHeat {
  display: flex;
  align-items: center;
}

.contactsBodyItemCell, .contactsBodyItemSocials, .contactsBodyItemProgress {
  position: relative;
  grid-column: 2 / 3;
  padding-left: 140px;
  min-height: 20px;
  height: fit-content;
  font-weight: 700;

  @media (min-width: desktop) {
    grid-column: unset;
    padding-left: 0;
    font-weight: 400;
  }
}

.contactsBodyItemPhone {
  position: relative;
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.contactsBodyItemSocials {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;
  width: fit-content;
}

.contactsBodyItemSocialsLink {
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.contactsBodyItemProgressItem {
  display: inline-block;
  width: 8px;
  height: 14px;
  background-color: grey_text_color;

  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &_active {
    &:nth-child(1) {
      background-color: #E91927;
    }

    &:nth-child(2) {
      background-color: #EE4A2E;
    }

    &:nth-child(3) {
      background-color: #F37934;
    }

    &:nth-child(4) {
      background-color: #F8A33A;
    }

    &:nth-child(5) {
      background-color: #FFDB42;
    }

    &:nth-child(6) {
      background-color: #DADC48;
    }

    &:nth-child(7) {
      background-color: #A9D650;
    }

    &:nth-child(8) {
      background-color: #83D255;
    }

    &:nth-child(9) {
      background-color: #4FCB5C;
    }

    &:nth-child(10) {
      background-color: #1BC563;
    }
  }
}

.contactsBodyItemTopInner {
  font(main_text_colot, 10px, 700, 13px);
  letter-spacing: 0.29px;
  vertical-align: top;

  &_100 {
    border-bottom: 4px solid #ffe043;
  }

  &_20 {
    border-bottom: 4px solid #19c564;
  }
}

.contactsBodyItemHeatImg {
  display: inline-block;
  margin-top: 1px;
  margin-left: 1px;
  width: 64px;
  height: 12px;
}

.contactsBodyItemMobileText {
  position: absolute;
  left: 0;
  font-weight: 400;
  opacity: 0.6;

  @media (min-width: desktop) {
    display: none;
  }
}

.contactsBodyItemProgressBox {
  position: relative;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  grid-column-gap: 2px;
}

.contactsBodyItemHeatBox {
  position: relative;
  z-index: 1;
}

.contactsBodyItemTooltip {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &_mobile {
    @media (min-width: desktop) {
      display: none;
    }
  }

  &_desktop {
    display: none;

    @media (min-width: desktop) {
      display: block;
    }
  }
}

.horizontal {
  user-select: none;
}

.horizontal-wrap {
  cell_fade();
}

.contactsBodyItemTagsWrap {
  max-width: 130px;

  @media (min-width: phone_s) {
    max-width: 170px;
  }

  @media (min-width: tablet) {
    max-width: 130px;
  }

  @media (min-width: desktopLarge) {
    max-width: 200px;
  }

  @media (min-width: desktopLeads) {
    max-width: 250px;
  }
}

.contactsBodyItemTags {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 4px;

  @media (min-width: desktop) {
    grid-column-gap: 2px;
  }
}

.contactsBodyItemAddTags {
  max-height: 20px;
}

.contactsBodyItemAddTagsButton {
  position: relative;
  display: block;
  padding: 0;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23e6e7eb' d='M2 20a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2z'/%3E%3C/g%3E%3Cg%3E%3Cg/%3E%3Cg%3E%3Cpath fill='%230f1642' d='M9.333 9.333v-4h1.334v4h4v1.334h-4v4H9.333v-4h-4V9.333z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
  border-radius: 2px;

  @media (min-width: desktop) {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' d='M2 20a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2z'/%3E%3C/g%3E%3Cg%3E%3Cg/%3E%3Cg%3E%3Cpath fill='%230f1642' d='M9.333 9.333v-4h1.334v4h4v1.334h-4v4H9.333v-4h-4V9.333z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    display: none;
  }
}

.contactsBodyItemTagsItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 4px;
  border-radius: 2px;

  span {
    display: inline-block;
    height: 14px;
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    max-width: 60px;
    text-overflow: ellipsis;

    @media (min-width: phone_s) {
      max-width: 80px;
    }

    @media (min-width: tablet) {
      max-width: 60px;
    }

    @media (min-width: desktopLarge) {
      max-width: 90px;
    }
  }

  &:hover {
    .contactsBodyItemTagsItemDel {
      display: block;
    }
  }
}

.contactsBodyItemTagsItemDel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: none;
  margin: auto;
  padding: 0;
  width: 12px;
  height: 20px;
  border: none;
  cursor: pointer;

  span {
    position: absolute;
    top: 0;
    left: -8px;
    width: 8px;
    height: 20px;
  }

  svg {
    position: absolute;
    top: 1px;
    bottom: 0;
    margin: auto;
    right: 2px;
  }

  @media (min-width: desktop) {
    display: none;
  }
}

.contactsBodyItemSource {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  background-color: grey_text_color;
  text-transform: uppercase;

  &_manual {
    background-color: #B5B8C5;
  }

  &_union {
    background-color: rgba(15 22 66 0.7);
    color: #fff;
  }
}

.contactsBodyItemButton {
  cursor: pointer;
}
</style>
