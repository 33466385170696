<template>
  <autowebinar-container />
</template>

<script>
import AutowebinarContainer from "@/containers/Autowebinars/AutowebinarContainer.vue";

export default {
  name: "AutowebinarDescription",
  components: {
    AutowebinarContainer,
  },
};
</script>

<style lang="stylus" scoped></style>