<template>
  <div class="telephonyMinimize" v-show="minimizedMode">
    <div class="telephonyMinimize__box telephonyMinimize__boxName">
      <div class="telephonyMinimize__avatar">
        <img
          class="telephonyMinimize__avatarImg"
          :src="userAvatar"
          v-if="userAvatar"
        />
        <div v-else class="telephonyMinimize__avatarImg empty">
          {{ userName | getInitialsFilter }}
        </div>
      </div>
      <span class="telephonyMinimize__name">{{ userName }}</span>
      <span class="telephonyMinimize__time" v-if="callStatus === 'pending'">{{
        timer | timerFilter
      }}</span>
      <span class="telephonyMinimize__time" v-else>{{
        statuses[callStatus]
      }}</span>
    </div>
    <div class="telephonyMinimize__box telephonyMinimize__boxControl">
      <button
        class="telephonyMinimize__maximize"
        type="button"
        @click="openTelephonyModal"
      >
        <Tr>Развернуть</Tr>
      </button>
      <button class="telephonyMinimize__hangUp" type="button" @click="endCall">
        <base-icon-svg width="24" height="10" viewbox="0 0 24 10">
          <telephony-hang-up />
        </base-icon-svg>
      </button>
    </div>
  </div>
</template>

<script>
import TelephonyHangUp from "@/components/Telephony/TelephonyHangUpIcon.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TelephonyMinimizeContainer",
  components: { TelephonyHangUp },
  props: {},
  data() {
    return {
      statuses: {
        no_call: "-",
        disconnected: this.$t("Соединение разорвано"),
        rejected: this.$t("Отклонено"),
      },
    };
  },
  computed: {
    ...mapGetters({
      userName: "caller/userName",
      userAvatar: "caller/userAvatar",
      minimizedMode: "caller/minimizedMode",
      timer: "caller/timer",
      callStatus: "caller/callStatus",
    }),
  },

  methods: {
    ...mapMutations({
      toggleMinMode: "caller/TOGGLE_MIN_MODE",
    }),
    openTelephonyModal() {
      this.toggleMinMode(false);
      this.$modal.show("telephony-modal");
    },
    endCall() {
      this.$store.dispatch("caller/endCall");
      this.$modal.hide("telephony-modal");
      this.toggleMinMode(false);
    },
  },
  filters: {
    timerFilter(totalSeconds) {
      function pad(val) {
        const valString = val + "";
        if (valString.length < 2) {
          return "0" + valString;
        } else {
          return valString;
        }
      }

      return `${pad(parseInt(totalSeconds / 60))}:${pad(totalSeconds % 60)}`;
    },
  },
};
</script>

<style lang="stylus" scoped>
.telephonyMinimize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid orange;

  @media (min-width: desktop) {
    flex-direction: row;
  }

  &__boxName {
    display: grid;
    grid-gap: 2px 4px;
    margin-bottom: 10px;
    width: fit-content;

    @media (min-width: desktop) {
      margin-bottom: 0;
    }
  }

  &__avatar {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &__name {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    max-height: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font_bold();
  }

  &__time {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    font(main_text_color, 10px, 400, 14px);
  }

  &__avatarImg {
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: yellow;
      font(main_text_color, 10px, 700, 14px);
    }
  }

  &__boxControl {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 8px;
    width: fit-content;
  }

  &__maximize {
    display: block;
    padding: 7px 12px;
    height: 32px;
    border-radius: 4px;
    background-color: #e6e7eb;
    border: none;
    font_regular();

    @media (min-width: desktop) {
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__hangUp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-image: linear-gradient(180deg, #f84d5b 0%, danger_text_color 100%);
    border: none;

    @media (min-width: desktop) {
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
</style>
