<template>
  <div class="funnelBody">
    <updated-funnel-body-head
      :class="['funnelBodyGrid', { funnelBodyGrid_sourceHide: sourceHide }]"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      @hideSource="hideSource"
      @getPipelineFull="getPipelineFull"
      @getPipelineShort="getPipelineShort"
    >
      <template slot="checkbox">
        <div class="funnelBodyHead__cell funnelBodyHead__cellCheckbox">
          <button
            type="button"
            class="
              funnelBodyHead__btn funnelBodyHead__btn_checkbox
              allCheckboxes
            "
            @click="$emit('pick-all-leads')"
          >
            <Tr>Все</Tr>
          </button>
        </div>
      </template>

      <template slot="manager">
        <div class="funnelBodyHead__cell funnelBodyHead__cellManager">
          <button
            type="button"
            class="
              funnelBodyHead__btn
              funnelBodyHead__btn_filter
              funnelBodyHead__cellText
            "
          >
            <Tr>Менеджер</Tr>
          </button>
        </div>
      </template>
    </updated-funnel-body-head>

    <updated-funnel-body-item
      v-for="lead in leads"
      :key="lead.id"
      :class="['funnelBodyGrid', { funnelBodyGrid_sourceHide: sourceHide }]"
      :lead="lead"
      :sourceHide="sourceHide"
      :pipelineIsFull="pipelineIsFull"
      :assignment="true"
      @showSource="showSource"
    >
      <template slot="checkbox">
        <div class="assignmentTableItem__checkbox checkbox">
          <label class="checkbox__label">
            <input
              class="checkbox__input"
              type="checkbox"
              name="leadCheckboxName"
              @change="$emit('pick-lead', lead)"
              :checked="isPickedLeadsChecked(lead)"
            />
            <span
              class="checkbox__fakeCheckBox"
              :class="{
                checkbox__fakeCheckBox_checked: isPickedLeadsChecked(lead),
              }"
            />
          </label>
        </div>
      </template>

      <template slot="manager">
        <div
          class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellManager"
          @click="openModal(lead)"
          v-tooltip="chatbotManagersNamesToStr(lead)"
        >
          <span class="updatedFunnelBodyItem__cell_mobile">
            <Tr>Менеджер</Tr>:
          </span>
          <template v-if="chatbotManagersNamesToStr(lead)">
            <span class="updatedFunnelBodyItem__cellManagerInner">
              {{ chatbotManagersNamesToStr(lead) }}
            </span>
          </template>
          <template v-else>
            <span>-</span>
          </template>
        </div>
      </template>
    </updated-funnel-body-item>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import { mapGetters } from "vuex";

import UpdatedFunnelBodyHead from "@/components/Funnel/UpdatedFunnelBodyHead.vue";
import UpdatedFunnelBodyItem from "@/components/Funnel/UpdatedFunnelBodyItem.vue";

export default {
  name: "UpdatedAssignmentBody",
  components: {
    UpdatedFunnelBodyHead,
    UpdatedFunnelBodyItem,
  },
  props: {
    leads: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: false,
    },
    company: {
      type: String,
      required: true,
    },
    isPickedLeadsChecked: Function,
  },
  data() {
    return {
      sourceHide: false,
      pipelineIsFull: false,
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      managers: "assignmentManagers/managers",
    }),

    webinarWidth() {
      const webinarArr = [];
      for (let i = this.leads.length; i--;) {
        webinarArr.push(
          pathOr([], ["bot", "messages", "groups"], this.leads[i]).length
        );
      }
      webinarArr.sort();
      return webinarArr[webinarArr.length - 1];
    },
  },
  methods: {
    hideSource() {
      this.sourceHide = true;
    },
    showSource() {
      this.sourceHide = false;
    },
    pickAllLeads() {
      this.$emit("pick-all-leads");
    },
    getPipelineFull() {
      this.pipelineIsFull = true;
    },
    getPipelineShort() {
      this.pipelineIsFull = false;
    },

    chatbotManagersNamesToStr(lead) {
      const usersManagers = lead.users.filter(
        user => user.type && user.type === "manager"
      );
      const chatbotManagersArray = [];
      if (usersManagers.length > 0) {
        usersManagers.forEach(user => {
          const chatbotManager = this.managers.find(
            el => el.id == Number(user["user_id"])
          );
          if (chatbotManager !== undefined) {
            chatbotManagersArray.push(chatbotManager);
          }
        });
      }
      const chatbotManagersNames = [];
      if (chatbotManagersArray.length > 0) {
        chatbotManagersArray.forEach(el => {
          chatbotManagersNames.push(el.name);
        });
        if (chatbotManagersNames.length > 0) {
          const arrToStr = chatbotManagersNames.join(", ");
          return arrToStr;
        }
      }
    },

    openModal(lead) {
      this.$modal.show("updated-funnel-lead", { lead: lead });
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnelBodyGrid {
  grid-gap: 8px 4px;
  grid-template-areas: 'checkbox avatar name source' '. manager manager manager' '. actions actions actions' '. tags tags tags';
  // grid-template-columns: 42px 0 161px 140px 170px 400px auto;
  grid-template-columns: 16px 20px calc(100vw - 220px) 155px;

  @media (min-width: phone_s) {
    grid-template-columns: 20px 20px 155px 155px;
  }

  @media (min-width: tablet) {
    grid-template-columns: 20px 20px 100px 155px;
  }

  @media (min-width: desktop) {
    grid-template-columns: 42px 0 141px 110px 170px 300px 83px;
    grid-gap: 23px 12px;
    grid-template-areas: unset;

    &_sourceHide {
      grid-template-columns: 42px 0 141px 110px 12px 300px 83px;
    }
  }

  @media (min-width: desktopLarge) {
    grid-template-columns: 42px 0 161px 110px 170px 400px 83px;

    &_sourceHide {
      grid-template-columns: 42px 0 161px 110px 12px 400px 83px;
    }
  }
}

.funnelBody {
  display: flex;
  flex-wrap: wrap;
  margin-top: 22px;
}

.funnelBodyHead {
  margin-bottom: 13px;

  &__btn {
    padding: 0;
    border: none;
    background-color: transparent;

    &_filter {
      width: fit-content;
      border-bottom: 2px dotted #0F164240;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.allCheckboxes {
  border-bottom: 2px dotted secondary_text_color;
  color: secondary_text_color;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  & span {
    width: max-content;
    display: block;
  }
}

.funnelBodyHead__cell {
  height: 18px;
}

.funnelBodyHead__cellCheckbox, .assignmentTableItem__checkbox {
  grid-area: checkbox;

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.funnelBodyHead__cellManager {
  grid-area: manager;

  @media (min-width: desktop) {
    grid-area: unset;
  }
}

.checkbox {
  position: relative;
  top: 2px;
  left: 2px;
  height: 24px;
}

.checkbox__label {
  position: relative;
  top: -1px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  height: 18px;
  font(main_text_color, 14px, 400, 19px);
  cursor: pointer;

  @media (min-width: desktop) {
    top: 2px;
  }
}

.checkbox__fakeCheckBox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid grey_text_color;
  border-radius: 2px;
}

.checkbox__fakeCheckBox_checked {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Crect width='8' height='8' rx='2 ' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 70%;
  background-position-x: 2px;
  background-position-y: 2px;
  background-repeat: no-repeat;
}

.checkbox__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.updatedFunnelBodyItem__cellManager {
  grid-area: manager;

  @media (min-width: desktop) {
    position: relative;
    grid-area: unset;
    padding-top: 3px;
    cell_fade();
    overflow: hidden;
  }
}

.updatedFunnelBodyItem__cell_mobile {
  display: inline-flex;
  margin-right: 7px;
  color: #0f1642;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  opacity: 0.4;

  @media (min-width: desktop) {
    display: none;
  }
}

.updatedFunnelBodyItem__cellManagerInner {
  &:not(:last-child) {
    position: relative;
    margin-right: 7px;

    &::after {
      position: absolute;
      content: ',';
      right: -3px;
    }
  }
}

@media (min-width: desktop) {
  .funnelBody {
    display: block;
  }
}
</style>
