import axiosConfig from "@/utils/axiosConfigFunnel";
import axios from "axios";
import { find, propEq } from "ramda";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    products: [],
    form: {
      name: "",
      product_id: null,
      video_id: null,
      started_at: "",
    },
    createdUrl: "",
    createdName: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    name(state) {
      return state.form.name;
    },
    linkCreated(state) {
      return state.created;
    },
    products(state) {
      return state.products;
    },
    videos(state) {
      if (state.form.product_id) {
        const product: any = find(propEq("id", Number(state.form.product_id)))(
          state.products
        );
        return product ? product.videos : [];
      }
      return [];
    },
    productId(state) {
      return state.form.product_id;
    },
    videoId(state) {
      return state.form.video_id;
    },
    startedAt(state) {
      return state.form.started_at;
    },
    errors(state) {
      return state.errors;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
    createdName(state) {
      return state.createdName;
    },
  },

  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.form.name = value;
    },
    CHANGE_PRODUCT(state, value) {
      state.form.product_id = value;
      state.form.video_id = null;
      state.form.started_at = "";
    },
    CHANGE_VIDEO(state, value) {
      state.form.video_id = value;
      state.form.started_at = "";
    },
    CHANGE_STARTED_AT(state, value) {
      state.form.started_at = value;
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED(state, data) {
      state.createdName = data.data.name;
      state.createdUrl = data.data.url;
    },
    CLEAR_STORE(state) {
      state.form = {
        name: "",
        product_id: null,
        video_id: null,
        started_at: "",
      };
      state.createdUrl = "";
      state.createdName = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async fetchProducts({ commit, rootGetters, state }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/products?company=${rootGetters[
            "user/userCompanyName"
          ].toLowerCase()}`,
          axiosConfig(tasqToken)
        );
        if (response.status === 200) {
          commit("SET_PRODUCTS", response.data.data);

          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async createLink({ commit, state }, leadId) {
      const data = {
        video_id: state.form.video_id,
        started_at: moment(state.form.started_at).format("DD.MM.YYYY HH:mm:ss"),
      };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/leads/${leadId}/webinar/`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 201) {
          commit("SET_CREATED", response.data);
          commit("LINK_CREATED", true);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
