<template>
  <div>
    <form @submit.prevent class="goodQuest__form">
      <lang-tabs
        :langs="langTabs"
        :value="lang"
        @handle-change-tab="handleChangeLang"
        class="leadershipLangButtons__quest"
      />
      <leadership-input
        :maxLength="NAME_MAX_LENGTH"
        :value="lessonName"
        @handle-name="handleChangeName"
        title="Название урока"
      />
      <div class="errors" v-if="errors && errors.errors">
        <div
          class="errors__item"
          v-if="errors.errors.name_ru && errors.errors.name_ru.length"
        >
          RU:{{ " " }}
          <span :key="item" v-for="item in errors.errors.name_ru">
            {{ item }}
          </span>
        </div>
        <div
          class="errors__item"
          v-if="errors.errors.name_en && errors.errors.name_en.length"
        >
          EN:{{ " " }}
          <span :key="item" v-for="item in errors.errors.name_en">
            {{ item }}
          </span>
        </div>
      </div>
      <text-editor
        :content="lessonContent"
        @change-content="handleChangeContent"
        :heading="'Содержание урока'"
        class="text-editor"
      />
    </form>
  </div>
</template>

<script>
import LeadershipInput from "@/components/Leadership/LeadershipInput.vue";
import LangTabs from "@/components/LangTabs.vue";
import TextEditor from "@/components/TextEditor.vue";

export default {
  name: "LeadershipLesson",
  components: {
    LeadershipInput,
    LangTabs,
    TextEditor,
  },
  data() {
    return {
      NAME_MAX_LENGTH: 150,
      DESCRIPTION_MAX_LENGTH: 10000,
    };
  },
  props: {
    langTabs: {
      type: Array,
      required: true,
    },
    lessonName: {
      type: String,
      required: true,
    },
    lessonContent: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    supportedLangsTitled: {
      type: Array,
    },
    canSkip: {
      type: Boolean,
      required: false,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  computed: {},
  methods: {
    handleChangeName(value) {
      this.$emit("change-name", value);
    },
    handleChangeContent(value) {
      this.$emit("change-content", value);
    },
    handleChangeLang(lang) {
      this.$emit("change-active-lang", lang);
    },
    handleChangeSkip() {
      this.$emit("change-can-skip");
    },
  },
};
</script>

<style lang="stylus">
.infoText {
  margin-top: 5px;
  font-size: 12px;
}

.errors {
  margin-top: -10px;
  margin-bottom: 10px;

  &__item {
    color: danger_text_color;
    font-size: 12px;
  }
}
</style>
