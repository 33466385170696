<template>
  <v-popover
    offset="0"
    trigger="hover"
    placement="top"
    class="updatedFunnelBodyItem__cellNotification"
    v-if="actionItem"
  >
    <!-- This will be the popover target (for the events and position) -->
    <div class="updatedFunnelBodyItem__notificationTrigger tooltip-target b3">
      <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
        <notification-messenger
          :class="[
            'updatedFunnelBodyItem__notificationIcon',
            {
              updatedFunnelBodyItem__notificationIcon_contact: false,
            },
          ]"
        />
      </base-icon-svg>
      <!-- <span v-if="chatbot.length > 1"> +{{ chatbot.length - 1 }} </span> -->
    </div>
    <!-- This will be the content of the popover -->
    <template slot="popover">
      <div class="notificationPopover">
        <span class="tooltip-time">
          {{ dateFormatted(actionItem.seen_at) }}
        </span>
        <p
          class="tooltip-text"
          v-html="actionItem.text"
          v-if="actionItem.text"
        />
        <template v-if="actionItem.attachments">
          <div
            class="tooltip-text"
            v-for="attachment in actionItem.attachments"
            :key="`${attachment.type}_${attachment.url}`"
          >
            <Tr>Прикреплен файл</Tr>({{ attachment.type }})
          </div>
        </template>
      </div>
    </template>
  </v-popover>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import NotificationMessenger from "@/components/SvgIcons/funnel/NotificationMessenger.vue";

export default {
  name: "UpdatedFunnelBodyItemChatbot",

  components: {
    NotificationMessenger,
  },

  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },

  methods: {
    dateFormatted(time) {
      if (time) {
        return moment(time)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.updatedFunnelBodyItem__cellNotification {
  display: flex;
  align-items: center;
  height: 24px;
  background-color: grey_text_color;

  .updatedFunnelBodyItem__notificationTrigger {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 24px;
  }

  span {
    margin: 0 2px 0 3px;
  }
}

.updatedFunnelBodyItem__notificationTrigger {
  padding: 0;
  background: none;
  border: none;
}

.updatedFunnelBodyItem__notificationIcon {
  color: #3489f8;

  &_contact {
    color: main_text_color;
  }
}

.notificationPopover {
  color: main_text_color;
  font_mobile();
  text-align: left;
}

.tooltip-title, .tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  opacity: 0.6;
}

.tooltip-text {
  margin-bottom: 3px;
  word-break: break-word;
  // white-space: nowrap;
  // height: 14px;
  // overflow: hidden;
  // text-overflow: ellipsis;
}
</style>
