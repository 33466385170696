<template>
  <div class="coursesContainer">
    <div class="loader__box" v-if="pending">
      <loader />
    </div>
    <div v-if="!hasCourses">
      <no-content :noContent="noContent" />
      <div class="buttonBox">
        <base-link to="/leadership/courses/new" class="createQuest">
          <Tr>Создать квест</Tr>
        </base-link>
      </div>
    </div>
    <div class="quests__wrap" v-else>
      <leadership-course-list-item
        :key="item.id"
        :quest="item"
        v-for="item in courses"
      />
    </div>
    <button
      class="quests__more"
      type="button"
      @click="fetchMore"
      v-if="theNextPage"
    >
      <Tr>Показать больше</Tr>
    </button>
    <!-- <button class="quests__more" v-if="theNextPage">
      <Tr>Показать ещё</Tr>
    </button> -->
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";
import { mapGetters } from "vuex";
import LeadershipCourseListItem from "@/components/Leadership/LeadershipCourseListItem.vue";
// import LeadershipQuestsList from "@/components/Leadership/LeadershipQuestsList.vue";

export default {
  name: "CoursesContainer",
  components: {
    Loader,
    NoContent,
    LeadershipCourseListItem,
    // LeadershipQuestsList,
  },
  data() {
    return {
      noContent: {
        text: "Пусто. У вас еще нет ни одного бизнес-квеста",
      },
    };
  },
  computed: {
    hasCourses() {
      if (this.courses && this.courses.length) {
        return true;
      }
      return false;
    },
    ...mapGetters({
      courses: "leadershipCourses/courses",
      pending: "leadershipCourses/pending",
      theNextPage: "leadershipCourses/theNextPage",
    }),
  },
  mounted() {
    this.$store.dispatch("leadershipCourses/fetch", { page: 1 });
  },
  methods: {
    fetchMore() {
      this.$store.dispatch("leadershipCourses/fetch", {
        page: this.theNextPage,
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.coursesContainer {
  position: relative;
}

.quests {
  &__wrap {
    display: grid;
    grid-row-gap: 16px;
    padding-top: 1px;

    @media (min-width: desktop) {
      grid-template-columns: repeat(2, 456px);
      grid-column-gap: 16px;
    }

    /* display: none; */
  }

  &__more {
    background: main_text_color;
    color: #fff;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 3px;
    border: none;
    margin-top: 50px;
  }
}

.buttonBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.createQuest {
  align-self: center;
  margin-top: 30px;
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
}

.loader {
  &__box {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
</style>
