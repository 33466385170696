<template>
  <div class="contactsProfileMeetings" v-if="this.hasSubscription">
    <tasks-menu
      class="contactsProfileMeetings__menu"
      :section="pageUrl"
      :tabs="tasksTabs"
    />
    <div class="loaderBox">
      <div class="loaderIcon" v-show="pending">
        <loader />
      </div>
      <no-content v-if="noReminders" :noContent="noContentEmpty" />
      <router-view></router-view>
      <button
        class="contactsProfileMeetings__createButton createButton"
        type="button"
        @click="createMeeting"
      >
        <Tr>Создать встречу</Tr>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TasksMenu from "@/components/Tasks/TasksMenu.vue";
import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";

export default {
  name: "contactsProfileMeetingss",

  components: {
    TasksMenu,
    Loader,
    NoContent,
  },

  computed: {
    ...mapGetters({
      tasksTabs: "leadTasks/tasksTabs",
      meetings: "contactsProfileMeetings/meetings",
      pendingReminders: "contactsProfileMeetings/pending",
      pendingProfile: "contactsProfile/pending",
      progresses: "leadTasks/contactProgresses",
      hasSubscription: "user/hasSubscription",
    }),

    pending() {
      return this.pendingReminders || this.pendingProfile;
    },

    pageUrl() {
      return `contacts/${this.$route.params.profileId}/meetings`;
    },

    noReminders() {
      return !this.pending && this.meetings.length === 0;
    },

    noContentEmpty() {
      return {
        noFunnels: false,
        text: "Встречи не найдены.",
        maxWidth: 0,
      };
    },
  },

  methods: {
    createMeeting() {
      this.$modal.show("create-meeting", {});
    },
  },

  mounted() {
    if (!this.hasSubscription) {
      const base = this.$store.getters["user/mainSiteLink"];
      const href = base + '/contacts/meets';
      window.location = href;
    }
    this.$store.dispatch("contactsProfileMeetings/fetchMeetingTypes");
  },
};
</script>

<style lang="stylus" scoped>
.contactsProfileMeetings {
  &__menu {
    margin-bottom: 24px;
  }
}

.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.contactsProfileMeetings__createButton {
  margin: 40px auto 0;
}

.createButton {
  display: block;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();

  @media (min-width: tablet_large) {
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
