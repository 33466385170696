import moment from "moment";
import qs from "qs";
import { pathOr } from "ramda";
import Vue from "vue";

function filterNonNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

function transformFilters(obj) {
  let transformedObj = obj;
  if (transformedObj.last_action_at_gte) {
    transformedObj = {
      ...transformedObj,
      last_action_at_gte: moment(transformedObj.last_action_at_gte)
        .utc(true)
        .startOf("day")
        .format(),
    };
  }
  if (transformedObj.last_action_at_lte) {
    transformedObj = {
      ...transformedObj,
      last_action_at_lte: moment(transformedObj.last_action_at_lte)
        .utc(true)
        .endOf("day")
        .format(),
    };
  }
  return transformedObj;
}

export default {
  namespaced: true,

  state: () => ({
    data: {},
    filters: {
      name_icontains: "",
      phone_number_contains: "",
      status_in: [],
      last_product_in: [],
      current_product_in: [],
      last_action_at_lte: "",
      last_action_at_gte: "",
    },
    isPickedAllStatuses: false,
    isPickedAllLastProducts: false,
    isPickedAllCurrentProducts: false,
    sort: "",
    filtersActive: false,
    lastProductFilterValue: "",
    currentProductFilterValue: "",
    lastProductFilterCounter: "",
    currentProductFilterCounter: "",
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    leads(state) {
      return pathOr([], ["data", "results"], state);
    },
    total(state) {
      return pathOr(0, ["data", "count"], state);
    },
    hasNextPage(state) {
      return pathOr(undefined, ["data", "next_page_number"], state);
    },
    currentPage(state) {
      return pathOr(undefined, ["data", "current_page_number"], state);
    },
    searchName(state) {
      return state.filters.name_icontains;
    },
    searchPhone(state) {
      return state.filters.phone_number_contains;
    },
    activeFilters(state) {
      return state.filters;
    },
    filters(state) {
      return state.filters;
    },
    filtersActive(state) {
      return state.filtersActive;
    },
    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },
    pickedAllLastProducts(state) {
      return state.isPickedAllLastProducts;
    },
    pickedAllCurrentProducts(state) {
      return state.isPickedAllCurrentProducts;
    },
    lastProductFilterValue(state) {
      return state.lastProductFilterValue;
    },
    currentProductFilterValue(state) {
      return state.currentProductFilterValue;
    },
    lastProductFilterCounter(state) {
      return state.lastProductFilterCounter;
    },
    currentProductFilterCounter(state) {
      return state.currentProductFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data =
        data.current_page_number === 1
          ? data
          : (state.data = {
              ...data,
              results: state.data.results.concat(data.results),
            });
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_SEARCH_NAME(state, value) {
      state.filters.name_icontains = value;
    },
    CHANGE_SEARCH_PHONE(state, value) {
      state.filters.phone_number_contains = value;
    },
    CHANGE_FILTER(state, { value, name }) {
      if (value instanceof Array) {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (name === "last_action_at_lte" || name === "last_action_at_gte") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (state.filters[name]) {
        if (state.filters[name].includes(value)) {
          state.filters = {
            ...state.filters,
            [name]: state.filters[name].filter(el => el !== value),
          };
        } else {
          state.filters = {
            ...state.filters,
            [name]: [...state.filters[name], value],
          };
        }
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },
    SET_PICKED_ALL_STATUSES(state, value) {
      state.isPickedAllStatuses = !state.isPickedAllStatuses;

      state.isPickedAllStatuses
        ? (state.filters.status_in = [...value])
        : (state.filters.status_in = []);
    },
    SET_PICKED_ALL_LAST_PRODUCTS(state, value) {
      state.isPickedAllLastProducts = !state.isPickedAllLastProducts;

      state.isPickedAllLastProducts
        ? (state.filters.last_product_in = [...value])
        : (state.filters.last_product_in = []);
    },
    SET_PICKED_ALL_CURRENT_PRODUCTS(state, value) {
      state.isPickedAllCurrentProducts = !state.isPickedAllCurrentProducts;

      state.isPickedAllCurrentProducts
        ? (state.filters.current_product_in = [...value])
        : (state.filters.current_product_in = []);
    },
    TOGGLE_FILTERS(state) {
      state.filtersActive = !state.filtersActive;
    },
    CLEAR_FILTERS(state) {
      state.filters = {
        ...state.filters,
        status_in: [],
        last_product_in: [],
        current_product_in: [],
        last_action_at_lte: "",
        last_action_at_gte: "",
      };
    },
    SET_LAST_PRODUCT_FILTER_VALUE(state, value) {
      state.lastProductFilterValue = value;
    },
    SET_CURRENT_PRODUCT_FILTER_VALUE(state, value) {
      state.currentProductFilterValue = value;
    },
    SET_LAST_PRODUCT_FILTER_COUNTER(state, value) {
      state.lastProductFilterCounter = value;
    },
    SET_CURRENT_PRODUCT_FILTER_COUNTER(state, value) {
      state.currentProductFilterCounter = value;
    },
    CHANGE_SORT(state, sort) {
      if (state.sort === sort) {
        state.sort = `-${sort}`;
      } else {
        state.sort = sort;
      }
    },
    UPDATE_LEAD(state, lead) {
      state.data.results = state.data.results.map(item =>
        item.id === lead.id ? lead : item
      );
    },
  },

  actions: {
    changeSort({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_SORT", payload);
        resolve();
      });
    },
    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },
    pickAllStatuses({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_STATUSES", payload);
        resolve();
      });
    },
    pickAllLastProducts({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_LAST_PRODUCTS", payload);
        resolve();
      });
    },
    pickAllCurrentProducts({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_CURRENT_PRODUCTS", payload);
        resolve();
      });
    },
    toggleFilter({ commit }) {
      return new Promise((resolve: any) => {
        commit("TOGGLE_FILTERS");
        resolve();
      });
    },
    async changeSearchName({ commit, dispatch }, { search }) {
      commit("CHANGE_SEARCH_NAME", search);
      dispatch("fetch", { page: 1 });
    },
    async changeSearchPhone({ commit, dispatch }, { search }) {
      commit("CHANGE_SEARCH_PHONE", search);
      dispatch("fetch", { page: 1 });
    },

    async changeFilters({ commit, dispatch }, { filters }) {
      commit("CHANGE_FILTERS", filters);
      dispatch("fetch", { page: 1 });
    },

    async fetch({ commit, state }, { page }) {
      try {
        commit("SET_PENDING", true);

        const filters = qs.stringify(
          transformFilters(
            filterNonNull({
              ...state.filters,
              ...(page ? { page } : []),
              ...(state.sort ? { o: state.sort } : []),
            })
          ),
          { arrayFormat: "comma" }
        );

        const response = await Vue.axios.get(`/telephony/leads/?${filters}`);

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async updateLead({ commit }, { leadId }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/telephony/leads/${leadId}/`);

        if (response.status === 200) {
          commit("UPDATE_LEAD", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
