<template>
  <fieldset class="formStatus">
    <legend class="formStatus__legend">
      <Tr>{{ fieldset.legend }}</Tr>
    </legend>
    <div class="formStatus__upsale">
      <div
        class="formStatus__upsaleBox"
        v-for="radiobtn in fieldset.radiobtns"
        :key="radiobtn.id"
      >
        <div
          class="formStatus__label"
          :class="{ active: radiobtn.id === leadStatus }"
          @click="pickRadioBtn(radiobtn.id)"
        >
          {{ radiobtnName(radiobtn.id) }}
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "UpdatedLeadsProfileInfoStatuses",
  data() {
    return {};
  },
  props: {
    fieldset: {
      type: Object,
      tequired: true,
    },
    leadStatus: {
      type: Number,
      required: true,
    },
  },
  methods: {
    pickRadioBtn(val) {
      this.$emit("change", val);
    },
    radiobtnName(id) {
      return this.fieldset.radiobtns.find(el => el.id === id).name;
    },
  },
};
</script>

<style lang="stylus" scoped>
.formStatus {
}

.formStatus__legend {
  margin-bottom: 12px;
  font_bold();
}

.formStatus__upsale {
  position: relative;
  width: fit-content;
  display: flex;
  max-width: calc(100vw - 40px);
  overflow-x: auto;
  white-space: nowrap;

  @media (min-width: tablet) {
    display: block;
    max-width: unset;
    overflow-x: visible;
  }
}

.formStatus__upsaleBox {
  width: max-content;

  &:not(:last-of-type) {
    margin-right: 10px;
  }

  @media (min-width: tablet) {
    width: auto;

    &:not(:last-of-type) {
      margin-right: 0;
    }
  }
}

.formStatus__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.formStatus__input:checked + .formStatus__label {
  color: #fff;
  background-color: orange;
}

.formStatus__label {
  display: block;
  margin-bottom: 6px;
  padding: 10px;
  width: fit-content;
  background-color: grey_text_color;
  border-radius: 2px;
  font(main_text_color, 12px, 400, 15px);
  cursor: pointer;

  & span {
    letter-spacing: -0.1px;
  }

  &.active {
    color: #fff;
    background-color: orange;
  }

  @media (min-width: tablet) {
    padding: 2px 4px 3px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.formStatus__description {
  position: relative;
  padding-left: 12px;
  font(main_text_color, 10px, 400, 14px);
  letter-spacing: -0.1px;
  white-space: pre-line;
}

.formStatus__description:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: #e6e7eb;
}

.formStatus__labelStat {
  margin-left: 6px;
  font(main_text_color, 10px, 400, 13px);
  opacity: 0.6;
}

@media (min-width: phone) {
  .formStatus__upsale {
    grid-auto-flow: column;
    grid-column-gap: 4px;
  }

  .formStatus__description {
    width: 343px;
    min-height: 56px;
  }
}
</style>
