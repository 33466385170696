import i18n from "@/plugins/i18n";
import { runNotice } from "@/utils/notifications";
import Vue from "vue";

const readFile = file => {
  const name = file.name;
  return new Promise(resolve => {
    const fr = new FileReader();
    fr.onload = e => {
      resolve({ original_filename: name, file: e.target!.result });
    };
    fr.onerror = e => {
      console.info(e);
      runNotice("success", i18n.t("Ошибка при чтении файла"));
    };
    fr.readAsText(file);
  });
};

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),
  getters: {
    pending(state) {
      return state.pending;
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async upload({ commit, dispatch }, file) {
      try {
        commit("SET_PENDING", true);
        // const data = await readFile(file);
        const data = new FormData();
        data.append("file", file);

        const response = await Vue.axios.post("/filestorage/api_files/", data);

        if (response.status === 201) {
          // dispatch("leadershipLesson/linkFileToLesson", response.data, {
          //   root: true,
          // });
          dispatch("leadershipLesson/addFileToQueue", response.data, {
            root: true,
          });
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
