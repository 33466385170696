<template>
  <div class="goodQuest__description">
    <form @submit.prevent class="goodQuest__form">
      <!-- <base-checkboxes-group
        :title="'Доступно на языках'"
        :list="supportedLangsTitled"
        :errors="errors ? errors.languages : []"
        :checkedList="course.languages"
        @handle-checkboxes="toggleLang"
      /> -->
      <leadership-course-price
        :cost="course.cost"
        :paymentType="course.payment_type"
        :errors="errors ? errors.cost : []"
        :isProgram="true"
        @change-price="changePrice"
        @change-payment-type="changePaymentType"
      />
      <lang-tabs
        :langs="langTabs"
        :value="lang"
        @handle-change-tab="handleChangeLang"
        class="leadershipLangButtons__quest"
      />
      <leadership-input
        :maxLength="NAME_MAX_LENGTH"
        :value="courseName"
        @handle-name="handleChangeName"
        title="Название программы"
      />
      <!-- <div class="errors" v-if="!courseName && showError">
        <span class="errors__item">
          <Tr>Это поле не должно быть пустым</Tr>
        </span>
      </div> -->
      <div class="errors" v-if="errors && errors.name_ru">
        <span class="errors__item" v-for="error in errors.name_ru" :key="error">
          {{ error }}
        </span>
      </div>
      <leadership-textarea
        :maxLength="DESCRIPTION_MAX_LENGTH"
        :value="courseDescription"
        @handle-description="handleChangeDescription"
        title="Описание"
      />
      <!-- <div class="errors" v-if="!courseDescription && showError">
        <span class="errors__item">
          <Tr>Это поле не должно быть пустым</Tr>
        </span>
      </div> -->
    </form>
    <div class="buttons">
      <form-submit @click="save" buttonName="save" />
      <form-submit
        @click="publish"
        buttonName="publish"
        class="publish"
        :text="'Опубликовать'"
        :key="'publish'"
        v-if="!isNew && !isPublished"
      />
      <span class="published publish" v-else-if="!isNew && isPublished">
        <Tr>Опубликовано</Tr>
      </span>
      <!-- <form-submit
        @click="unpublish"
        class="unpublish"
        :text="'Снять с публикации'"
        :key="'unpublish'"
        v-if="!isNew && isPublished"
      /> -->
    </div>
    <!-- <published-block v-else /> -->
  </div>
</template>

<script>
import LeadershipInput from "@/components/Leadership/LeadershipInput.vue";
import LeadershipTextarea from "@/components/Leadership/LeadershipTextarea.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import LangTabs from "@/components/LangTabs.vue";
import LeadershipCoursePrice from "@/components/Leadership/LeadershipCoursePrice.vue";
// import PublishedBlock from "@/components/PublishedBlock.vue";

export default {
  name: "LeadershipProgram",
  components: {
    LeadershipInput,
    LeadershipTextarea,
    FormSubmit,
    LangTabs,
    LeadershipCoursePrice,
    // PublishedBlock,
  },
  props: {
    course: {
      type: Object,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    supportedLangsTitled: {
      type: Array,
    },
    langTabs: {
      type: Array,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
    courseName: String,
    courseDescription: String,
    isPublished: Boolean,
    isNew: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      NAME_MAX_LENGTH: 64,
      DESCRIPTION_MAX_LENGTH: 10000,
      // showError: false
    };
  },
  methods: {
    toggleLang(lang) {
      this.$emit("change-languages", lang);
    },
    handleChangeName(value) {
      this.$emit("change-name", value);
    },
    handleChangeDescription(value) {
      this.$emit("change-description", value);
    },
    handleChangeLang(value) {
      this.$emit("change-active-lang", value);
    },
    isFormValid() {
      if (!this.courseName) {
        return false;
      }
      if (!this.courseDescription) {
        return false;
      }
      return true;
    },
    save() {
      // this.showError = true;
      // if (!this.isFormValid()) {
      //   return;
      // }
      this.$emit("save-course");
    },
    publish() {
      // this.showError = true;
      // if (!this.isFormValid()) {
      //   return;
      // }
      this.$emit("publish-course");
    },
    changePrice(value) {
      this.$emit("change-price", value);
    },
    unpublish() {
      this.$modal.show("dialog", {
        title: this.$t("Внимание!"),
        text: this.$t("Вы уверены?"),
        buttons: [
          {
            title: this.$t("Отмена"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Снять с публикации"),
            handler: () => {
              this.$emit("unpublish-course");
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    changePaymentType(value) {
      this.$emit("change-payment-type", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadershipLangButtons__quest {
  margin-top: 24px;
}

.publish {
  margin-left: 16px;
  color: #19c564;
}

.unpublish {
  margin-left: 16px;
  color: #e81526;
}

.errors {
  margin-top: -10px;
  margin-bottom: 10px;

  // &:last-of-type {
  // margin-top: 0;
  // }
  &__item {
    color: danger_text_color;
    font-size: 12px;
  }
}
</style>
