<script>
export default {
  name: "TabListRouterFunnel",
  data() {
    return {};
  },
  props: {
    tabs: Array,
  },
};
</script>

<template>
  <ul class="tabs__list">
    <li
      :key="tab.title + index"
      class="tabs__item"
      v-for="(tab, index) in tabs"
    >
      <base-link
        :to="tab.link"
        :exact="tab.exact"
        class="tabs__button activeItem"
        v-if="tab.link"
      >
        <Tr>{{ tab.title }}</Tr>
      </base-link>
      <span v-else class="tabs__button activeItem">
        <Tr>{{ tab.title }}</Tr>
      </span>
    </li>
  </ul>
</template>

<style lang="stylus" scoped>
.tabs__list {
  display: grid;
  // grid-template-columns repeat(6, 1fr)
  grid-auto-flow: column;
  grid-column-gap: 2px;
}

.tabs__button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  background-color: grey_text_color;
  font_small();
  font-weight: 700;
}

.tabs__button_done {
  background-color: white;

  &::after {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #ff9f43;
  }
}

.tabs__button_unfinished {
  background-color: white;

  &::after {
    position: absolute;
    content: '';
    bottom: 0px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: danger_text_color;
  }
}

.tabs__button.router-link-active {
  background-color: orange;
  color: white;
}
</style>
