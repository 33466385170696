<template>
  <div class="textareaMaxlength">
    <label
      :for="`textareaMaxlength_${labelId}`"
      class="textareaMaxlength__label"
    >
      <Tr>{{ label }}</Tr>
    </label>
    <textarea
      :id="`textareaMaxlength_${labelId}`"
      v-model="text"
      :maxlength="maxLength"
      :disabled="disabledProp"
      class="textareaMaxlength__textarea"
      :style="`height: ${height}px;`"
    />
    <div class="textareaMaxlength__textareaLength" v-if="withCounter">
      <span class="textareaMaxlength__textareaLengthSpan">
        <span
          :class="{
            textareaMaxlength__textareaLengthSpan_red:
              valueLength === maxLength,
          }"
          >{{ valueLength }}</span
        >
        /{{ maxLength }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextareaMaxlength",
  props: {
    label: {
      type: String,
      required: true,
    },

    labelId: {
      type: String,
      required: true,
    },

    height: {
      type: Number,
      default: 120,
    },

    textProp: {
      type: String,
    },

    maxLength: {
      type: Number,
      default: 264,
    },

    disabledProp: {
      type: Boolean,
      default: false,
    },
    withCounter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      textareaText: "",
    };
  },
  computed: {
    valueLength() {
      if (!this.text) {
        return 0;
      }
      return this.text.length;
    },

    text: {
      get() {
        if (this.textProp) {
          return this.textProp;
        } else {
          return this.textareaText;
        }
      },

      set(value) {
        this.textareaText = value;
        this.$emit('handleTextarea', value);
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.textareaMaxlength {
  position: relative;
  margin-top: -2px;

  &__label {
    display: block;
    margin-bottom: 4px;
    font(main_text_color, 12px, 400, 16px);
  }

  &__textareaLength {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__textareaLengthSpan {
    font_small();
    opacity: 0.6;

    & span {
      font-weight: 700;
    }

    &_red {
      color: #e81526;
    }
  }

  &__textarea {
    display: block;
    padding: 6px 7px;
    width: 100%;
    border: 1px solid grey_text_color;
    font_regular();
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: grey_text_color #fff;
  }

  &__textarea::-webkit-scrollbar {
    width: 12px;
  }

  &__textarea::-webkit-scrollbar-track {
    background: #fff;
  }

  &__textarea::-webkit-scrollbar-thumb {
    background-color: grey_text_color;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}
</style>
