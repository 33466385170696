import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    meetings: [],
    meeting: {
      type: null,
      topic: "",
      started_at: null,
    },
    errors: {},
    pending: false,
    meetingsTypesPending: false,
    meetingsTypes: [],
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    meetingsTypes(state) {
      return state.meetingsTypes;
    },

    meetingsTypesPending(state) {
      return state.meetingsTypesPending;
    },

    meetings(state) {
      return state.meetings;
    },

    createdMeeting(state) {
      return state.meeting;
    },

    startedAt(state) {
      return state.meeting.started_at;
    },

    errors(state) {
      return state.errors.errors;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.meetings = [...data.results];
    },

    SET_MORE(state, data) {
      state.data = data;
      state.meetings = [...state.meetings, ...data.results];
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_MEETING_TYPES(state, data) {
      state.meetingsTypes = data;
    },

    SET_MEETING_TYPES_PENDING(state, status) {
      state.meetingsTypesPending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data.data;
    },

    SET_MEETING_TYPE(state, value) {
      state.meeting.type = value;
    },

    SET_MEETING_TOPIC(state, value) {
      state.meeting.topic = value;
    },

    SET_MEETING_STARTED_AT(state, value) {
      state.meeting.started_at = value;
    },

    CLEAR_MEETING(state) {
      state.meeting = {
        type: null,
        topic: "",
        started_at: null,
      };
    },
  },

  actions: {
    async fetch({ commit }, { page, contact, filter }) {
      try {
        commit("SET_PENDING", true);

        let response;

        if (filter === "all") {
          response = await Vue.axios.get(`/user_tasks/?o=-created_at`, {
            params: { page, contact, type: "Встреча с контактом" },
          });
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/`, {
            params: { page, contact, type: "Встреча с контактом" },
          });
        }

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchMore({ commit }, { page, contact, filter }) {
      try {
        commit("SET_PENDING", true);

        let response;

        if (filter === "all") {
          response = await Vue.axios.get(`/user_tasks/`, {
            params: { o: "-created_at", page, contact, type: "Встреча с контактом" },
          });
        } else {
          response = await Vue.axios.get(`/user_tasks/${filter}/`, {
            params: { page, contact, type: "Встреча с контактом" },
          });
        }

        if (response.status === 200) {
          commit("SET_MORE", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchMeetingTypes({ commit }) {
      try {
        commit("SET_MEETING_TYPES_PENDING", true);

        const response = await Vue.axios.get(`/contact_meets_types/`);

        if (response.status === 200) {
          commit("SET_MEETING_TYPES", response.data);
          commit("SET_MEETING_TYPES_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_MEETING_TYPES_PENDING", false);
      }
    },

    async createMeetingType({ commit, state }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/contact_meets/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response);
      }
    },

    async createMeeting({ commit, state }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/user_tasks/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response);
      }
    },
  },
};
