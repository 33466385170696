import Cookies from "js-cookie";

export const getToken = (type?: "chatbot"): string | undefined => {
  if (typeof window !== undefined) {
    const authPre = type === "chatbot" ? "Bearer" : "Token";
    return Cookies.get("auth")
      ? `${authPre} ${Cookies.get("auth")}`
      : undefined;
  }
  return "";
};
// typeof window !== undefined
//   ? Cookies.get("auth")
//     ? `Token ${Cookies.get("auth")}`
//     : undefined
//   : "";
