import { render, staticRenderFns } from "./FunnelCreateLink.vue?vue&type=template&id=f93496a0&scoped=true&"
import script from "./FunnelCreateLink.vue?vue&type=script&lang=js&"
export * from "./FunnelCreateLink.vue?vue&type=script&lang=js&"
import style0 from "./FunnelCreateLink.vue?vue&type=style&index=0&id=f93496a0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f93496a0",
  null
  
)

export default component.exports