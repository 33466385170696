<template>
  <div class="funnel__startAndEmpty">
    <no-content :noContent="noContent" :funnelTextWidth="true" />

    <base-link
      class="funnel__startAndEmptyButton funnel__startAndEmptyButton_createLink"
      :to="updatedFunnel ? '/funnel/create-link' : '/funnel/create-link'"
    >
      <Tr>Создать ссылку</Tr>
    </base-link>

    <base-link
      v-if="linksList.length > 0"
      class="funnel__startAndEmptyButton funnel__startAndEmptyButton_linksList"
      to="/funnel/links-list"
    >
      <Tr>Список ссылок</Tr>
    </base-link>

    <base-link
      v-if="!isTasq && !updatedFunnel"
      class="funnel__startAndEmptyButton funnel__startAndEmptyButton_createLink"
      to="/funnel/create-link-without-chatbot"
    >
      <Tr>Ссылка на вебинар</Tr>
    </base-link>
  </div>
</template>

<script>
import NoContent from "@/components/NoContent.vue";

export default {
  name: "FunnelEmpty",

  components: {
    NoContent,
  },

  props: {
    pending: Boolean,
    noContent: Object,
    company: {
      type: String,
      required: true,
    },
    updatedFunnel: Boolean,
    funnelTextWidth: Boolean,
    linksList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    isTasq() {
      return /^(tasq)?$/.test(this.company);
    }
  },
};
</script>

<style lang="stylus" scoped>
.funnel__startAndEmpty {
  display: grid;
  place-items: center;

  &Img {
    display: block;
    margin-bottom: 20px;
    width: 128px;
    height: 128px;
  }

  &Text {
    display: block;
    text-align: center;
    color: #0F1642;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: -0.1px;

    &Wrap {
      width: calc(100% -18px);
      max-width: 460px;
    }
  }

  &Button {
    position: relative;
    display: block;
    margin-top: 20px;
    width: max-content;
    background-color: unset;
    border: none;
    border-radius: 4px;
    color: #0F1642;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &:hover {
      opacity: 0.6;
    }

    &_activate {
      padding: 11px 12px 11px 40px;
      background: transparent linear-gradient(180deg, #FDBA7A 0%, orange 100%);
      color: #fff;
      text-decoration: none;

      &::before {
        position: absolute;
        content: '';
        top: 4px;
        left: 4px;
        width: 32px;
        height: 32px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H32V32H0Z'/%3E%3Cpath class='b' d='M15.333,28.667A13.333,13.333,0,1,1,28.667,15.333,13.333,13.333,0,0,1,15.333,28.667ZM11.187,16.893l.017-.009,1.16,3.827c.149.415.355.489.6.455a.922.922,0,0,0,.547-.325L15.1,19.309l3.4,2.517c.621.343,1.068.165,1.223-.576l2.209-10.429c.244-.971-.183-1.36-.936-1.051L8.017,14.784c-.885.355-.88.851-.16,1.071l3.329,1.04Z' transform='translate(0.667 0.667)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_linksList {
      display: inline-block;
      padding: 7px 8px;
      background-color: grey_text_color;
    }

    &_createLink {
      display: block;
      padding: 7px 8px 5px 28px;
      border: 1px dashed main_text_color;

      &::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

.funnel__startAndEmptyReload {
  margin-top: 20px;
  color: #0F1642;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.1px;
  text-align: center;

  &Button {
    padding: 0;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: secondary_text_color;
  }
}

@media (min-width: tablet_large) {
  .funnel__startAndEmptyTextWrap {
    width: 460px;
  }
}
</style>
