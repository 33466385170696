<template>
  <div class="leadsCallsHistory__item">
    <div class="leadsCallsHistory__itemCell leadsCallsHistory__avatar">
      <avatar
        :avatarName="call.external_lead_name"
        :contacts="true"
        v-if="call.external_lead_name"
      />
    </div>
    <div
      class="leadsCallsHistory__itemCell leadsCallsHistory__name"
      v-tooltip="call.external_lead_name"
    >
      {{ call.external_lead_id }}
      {{ call.external_lead_name }}
    </div>
    <div class="leadsCallsHistory__itemCell">
      {{ call.phone_number }}
    </div>
    <div class="leadsCallsHistory__itemCell leadsCallsHistory__dateTime">
      {{ updatedAtDateFormatted(call.created_at) }}
    </div>
    <div class="leadsCallsHistory__itemCell leadsCallsHistory__duration">
      {{ formatSeconds(call.duration_seconds) }}
    </div>
  </div>
</template>

<script>
import { pathOr } from "ramda";
import moment from "moment";
import { mapGetters } from "vuex";
import Avatar from "@/components/Avatar.vue";

export default {
  name: "LeadsModalCalssHistoryTableItem",

  components: {
    Avatar,
  },

  props: {
    call: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
  },
  methods: {
    hideCallsHistory() {
      this.$modal.hide("calls-history");
      console.log("close or not");
    },
    updatedAtDateFormatted(someDate) {
      if (someDate) {
        return moment(someDate)
          .utcOffset(0)
          .locale(this.currentLang)
          .format("DD.MM.YYYY, HH:mm");
      }
    },
    formatSeconds(seconds) {
      return moment(seconds * 1000).format("m:ss");
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsCallsHistory__item {
  align-items: center;
}

.leadsCallsHistory__itemCell {
  position: relative;
  display: flex;
  align-items: center;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  font(main_text_color, 10px, 400, 14px);

  &::after {
    position: absolute;
    z-index: 3;
    content: '';
    right: 0;
    width: 16px;
    height: 100%;
    background-image: linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 100%);
  }

  @media (min-width: desktopLeads) {
    font-size: 14px;
    line-height: 18px;

    &::after {
      width: 6px;
    }
  }
}

.leadsCallsHistory__name {
  font-weight: 700;

  @media (min-width: desktopLeads) {
    font-weight: 400;
  }
}

.leadsCallsHistory__avatar {
  grid-column-start: 1;
  grid-column-end: 2;

  &::after {
    display: none;
  }

  @media (min-width: desktopLeads) {
    grid-column-start: unset;
    grid-column-end: unset;
  }
}

.leadsCallsHistory__avatarImg {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: 0.1s;

  @media (min-width: desktopLeads) {
    width: 24px;
    height: 24px;
  }

  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: orange;
    font(main_text_color, 10px, 700, 14px);
  }
}
</style>
