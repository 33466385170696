<template>
  <!-- TABLE PARENT -->
  <div class="teamTree__tableBodyItem">
    <div class="teamTree__tableBodyRow">
      <div
        class="
          teamTree__tableBodyRowBox teamTree__tableBodyRowBox_openChildrenAvatar
        "
      >
        <div class="teamTree__tableBodyCell teamTree__tableBodyCellButton">
          <button
            class="team__treeOpen"
            type="button"
            @click="openInner"
            v-if="hasChildren"
          >
            <svg class="funnel__landingIcon">
              <use
                xlink:href="@/assets/svg/sprite_team.svg#treeClosed--minus"
                v-if="treeOpen"
              />
              <use
                xlink:href="@/assets/svg/sprite_team.svg#treeClosed--plus"
                v-else
              />
            </svg>
          </button>
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableBodyCellAvatar">
          <div class="avatar__wrap">
            <div v-if="hasTasqUser">
              <img alt="Avatar" class="avatar" :src="avatar" />
            </div>
            <div
              class="avatarMobile"
              v-if="!hasTasqUser"
              v-tooltip.top-center="{
                content: tooltips.notRegistered,
                trigger: 'click',
              }"
            >
              <svg class="avatar">
                <use xlink:href="@/assets/svg/ic_unregistered.svg#ic" />
              </svg>
            </div>
            <div
              class="avatarDesktop"
              v-if="!hasTasqUser"
              v-tooltip.top-center="tooltips.notRegistered"
            >
              <svg class="avatar">
                <use xlink:href="@/assets/svg/ic_unregistered.svg#ic" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        class="teamTree__tableBodyRowBox mobileS"
        :style="{ width: 162 - level * 6 + 'px' }"
      >
        <div
          class="teamTree__tableBodyCell teamTree__tableCellName"
          :style="{ width: 190 - level * 8 + 'px' }"
        >
          <span class="team__link td" :class="{ team__link_active: treeOpen }">
            {{ name }}
          </span>
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableCellPhone">
          <a
            class="td phone"
            :href="`tel:${phone}`"
            rel="noopener"
            target="_blank"
            >{{ phone }}</a
          >
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableCellMessenger">
          <div class="socials__wrap">
            <a
              v-if="telegram"
              class="socials socials_telegram"
              :href="telegram"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.telegram"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#telegram" />
              </svg>
            </a>
            <a
              v-if="viber"
              class="socials socials_viber"
              :href="viber"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.viber"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#viber" />
              </svg>
            </a>
            <a
              v-if="whatsapp"
              class="socials socials_whatsapp"
              :href="whatsapp"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.whatsapp"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#whatsapp" />
              </svg>
            </a>
          </div>
        </div>
        <div
          v-if="showRank"
          class="teamTree__tableCellRank teamTree__tableBodyCell"
        >
          <span
            class="td"
            v-if="
              teamItem.get_blockchain_rank_display === 'Empty' ||
              teamItem.get_blockchain_rank_display === null
            "
          >
            <Tr>Нет ранга</Tr>
          </span>
          <span class="td" v-else>
            {{ teamItem.get_blockchain_rank_display }}
          </span>
        </div>
        <div
          class="teamTree__tableBodyCell teamTree__tableCellMLMPult"
          :class="{ hidden: !hasTasqUser }"
        >
          <div
            class="accountInfo"
            v-if="!subscription"
            v-tooltip.top-center="{ content: tooltips.free, trigger: 'click' }"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_registered.svg" />
          </div>
          <div
            class="accountInfo"
            v-if="subscription"
            v-tooltip.top-center="{ content: tooltips.payed, trigger: 'click' }"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_payed.svg" />
          </div>
          <div
            class="team__tableText team__accountText_deadline"
            v-if="subscription"
          >
            <span
              :class="{ ending: subscription <= 3 || subscription === '<1' }"
            >
              {{ subscription }} <Tr>{{ declension }}</Tr>
            </span>
          </div>
        </div>
      </div>
      <div
        class="teamTree__tableBodyRowBox mobileM"
        :style="{ width: 213 - level * 6 + 'px' }"
      >
        <div
          class="teamTree__tableBodyCell teamTree__tableCellName"
          :style="{ width: 190 - level * 8 + 'px' }"
        >
          <span class="team__link td" :class="{ team__link_active: treeOpen }">
            {{ name }}
          </span>
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableCellPhone">
          <a
            class="td phone"
            :href="`tel:${phone}`"
            rel="noopener"
            target="_blank"
            >{{ phone }}</a
          >
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableCellMessenger">
          <div class="socials__wrap">
            <a
              v-if="telegram"
              class="socials socials_telegram"
              :href="telegram"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.telegram"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#telegram" />
              </svg>
            </a>
            <a
              v-if="viber"
              class="socials socials_viber"
              :href="viber"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.viber"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#viber" />
              </svg>
            </a>
            <a
              v-if="whatsapp"
              class="socials socials_whatsapp"
              :href="whatsapp"
              rel="noopener"
              target="_blank"
              v-tooltip.top-center="tooltips.whatsapp"
            >
              <svg>
                <use xlink:href="@/assets/svg/sprite_messengers.svg#whatsapp" />
              </svg>
            </a>
          </div>
        </div>
        <div
          v-if="showRank"
          class="teamTree__tableCellRank teamTree__tableBodyCell"
        >
          <span
            class="td"
            v-if="
              teamItem.get_blockchain_rank_display === 'Empty' ||
              teamItem.get_blockchain_rank_display === null
            "
          >
            <Tr>Нет ранга</Tr>
          </span>
          <span class="td" v-else>
            {{ teamItem.get_blockchain_rank_display }}
          </span>
        </div>
        <div
          class="teamTree__tableBodyCell teamTree__tableCellMLMPult"
          :class="{ hidden: !hasTasqUser }"
        >
          <div
            class="accountInfo accountInfo_mobile"
            v-if="!subscription"
            v-tooltip.top-center="{ content: tooltips.free, trigger: 'click' }"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_registered.svg" />
          </div>
          <div
            class="accountInfo accountInfo_desktop"
            v-if="!subscription"
            v-tooltip.top-center="tooltips.free"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_registered.svg" />
          </div>
          <div
            class="accountInfo accountInfo_mobile"
            v-if="subscription"
            v-tooltip.top-center="{ content: tooltips.payed, trigger: 'click' }"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_payed.svg" />
          </div>
          <div
            class="accountInfo accountInfo_desktop"
            v-if="subscription"
            v-tooltip.top-center="tooltips.payed"
          >
            <img class="accountInfoImg" src="@/assets/svg/mlm_payed.svg" />
          </div>
          <div
            class="team__tableText team__accountText_deadline"
            v-if="subscription"
          >
            <span
              :class="{ ending: subscription <= 3 || subscription === '<1' }"
            >
              {{ subscription }} <Tr>{{ declension }}</Tr>
            </span>
          </div>
        </div>
      </div>
      <div
        class="
          teamTree__tableBodyRowBox teamTree__tableBodyRowBox_contactsCommand
        "
      >
        <div class="teamTree__tableBodyCell teamTree__tableCellContacts">
          <div class="teamTree__tableBodyCellMobile"><Tr>Контакты</Tr></div>
          {{ hasTasqUser ? teamItem.contacts_count : "" }}
        </div>
        <div class="teamTree__tableBodyCell teamTree__tableCellTeam">
          <div class="teamTree__tableBodyCellMobile"><Tr>Команда</Tr></div>
          {{ hasTasqUser ? teamItem.get_descendants_count : "" }}
        </div>
        <div
          class="teamTree__tableBodyCell teamTree__tableCellGold"
          v-if="showRank"
        >
          <div class="teamTree__tableBodyCellMobile">
            <img
              class="teamTree__tableBodyCellMobileGold"
              src="@/assets/images/gold@2x.png"
              v-tooltip.top-center="{
                content: tooltips.gold,
                trigger: 'click',
              }"
            />
          </div>
          <span>
            {{ teamItem.gold }}
          </span>
        </div>
        <div
          class="teamTree__tableBodyCell teamTree__tableCellDiamond"
          v-if="showRank"
        >
          <div class="teamTree__tableBodyCellMobile">
            <img
              class="teamTree__tableBodyCellMobileDiamond"
              src="@/assets/images/diamond@2x.png"
              v-tooltip.top-center="{
                content: tooltips.diamond,
                trigger: 'click',
              }"
            />
          </div>
          <span>
            {{ teamItem.brilliant }}
          </span>
        </div>
        <div
          v-if="showRank"
          :class="[
            showRank
              ? 'teamTree__tableBodyCell teamTree__tableCellFakeForRankMobile'
              : '',
          ]"
        />
      </div>
    </div>
    <div class="children" v-if="children && treeOpen">
      <TeamTreeBodyItem
        v-for="item in children"
        :teamItem="item"
        :key="item.id"
        :level="level + 1"
        :showRank="showRank"
      />
    </div>
  </div>
  <!-- END OF TABLE PARENT ROW-->
</template>

<script>
import TeamTreeBodyItem from "@/components/Team/TeamTreeBodyItem.vue";
import declension from "@/utils/declension";
import { pathOr } from "ramda";
import "v-tooltip";

export default {
  name: "TeamTreeBodyItem",
  components: { TeamTreeBodyItem },
  data() {
    return {
      treeOpen: false,
    };
  },
  props: {
    teamItem: Object,
    level: Number,
    showRank: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    declension() {
      // const sub = this.subscription === "<1" ? 2 : this.subscription;
      return declension("день", "дней", "дня", this.subscription);
    },
    tooltips() {
      const contact = this.teamItem.related_contacts[0];

      const telegram = pathOr("", ["telegram"], contact);
      const whatsapp = pathOr("", ["whatsapp"], contact);
      return {
        telegram: `<span>${this.$t(
          "Написать в telegram"
        )} <br/> ${telegram}</span>`,
        whatsapp: `<span>${this.$t(
          "Написать в whatsapp"
        )} <br/> ${whatsapp}</span>`,
        notRegistered: `<span>${this.$t(
          "Не зарегистрирован в"
        )} <br/> TASQ</span>`,
        free: `<span>${this.$t("Бесплатный тариф")} `,
        payed: `<span>${this.$t("Платная подписка")}`,
        gold: this.$t("Золото"),
        diamond: this.$t("Бриллианты"),
      };
    },
    hasTasqUser() {
      if (!this.teamItem.last_login) {
        return false;
      }
      return true;
    },
    avatar() {
      const avatar = this.teamItem.get_avatar_url;

      if (avatar.includes("data:")) {
        return avatar;
      }
      return `${process.env.VUE_APP_MAIN_URL}${avatar}`;
    },
    name() {
      return this.teamItem.name || this.teamItem.email;
    },
    teamCount() {
      return this.teamItem.get_descendants_count;
    },
    hasChildren() {
      return this.teamItem.get_descendants_count > 0;
    },
    children() {
      return this.$store.getters["team/children"](this.teamItem.id);
    },
    subscription() {
      // const { active_subscription } = this.teamItem;
      // const now = new Date();

      // if (active_subscription) {
      //   const subscriptionEnd = new Date(active_subscription.ended_at);
      //   if (subscriptionEnd - now > 0) {
      //     return active_subscription.get_days_left;
      //   }
      // }

      return 0 || this.teamItem.get_total_subscription_days;
    },
    phone() {
      const contact = this.teamItem.related_contacts[0];

      if (contact) {
        if (contact.phones[0]) {
          return contact.phones[0].value;
        }
      }
      return "";
    },
    telegram() {
      const contact = this.teamItem.related_contacts[0];

      if (contact) {
        if (contact.get_telegram_for_link) {
          return `https://t.me/${contact.get_telegram_for_link}`;
        }
      }
      return "";
    },
    viber() {
      const contact = this.teamItem.related_contacts[0];

      if (contact) {
        if (contact.viber) {
          return `https://t.me/${contact.viber}`;
        }
      }
      return "";
    },
    whatsapp() {
      const contact = this.teamItem.related_contacts[0];

      if (contact) {
        if (contact.whatsapp) {
          return `https://api.whatsapp.com/send?phone=${contact.whatsapp}`;
        }
      }
      return "";
    },
  },
  methods: {
    openInner() {
      this.treeOpen = !this.treeOpen;
      if (this.treeOpen && !this.children.length) {
        this.$store.dispatch("team/fetchBy", {
          parent: this.teamItem.id,
          level: this.teamItem.level + 1,
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.mobileM {
  display: none !important;
}

.teamTree__tableBodyItem {
  width: fit-content;
}

.teamTree__tableBody {
  &Row {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 4px;
    margin-bottom: 12px;

    &Box {
      display: grid;
      grid-template-columns: repeat(1, auto);
    }
  }

  &Cell {
    min-height: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    &Button {
      padding: 8px;
      margin: -8px 0px -8px -8px;
      width: 16px;
      height: 20px;
      box-sizing: content-box;
    }

    &Avatar {
      width: 20px;
      height: 20px;
      margin-bottom: 0;
    }
  }
}

.avatarDesktop {
  display: none;
}

.teamTree__tableBodyRowBox_openChildrenAvatar {
  display: grid;
  grid-template-columns: repeat(2, auto);
}

.mobileS, .mobileM {
  overflow: hidden;
}

.teamTree__tableBodyRowBox_contactsCommand {
  height: fit-content;
}

.teamTree__table {
  &Cell {
    &Name {
      overflow: hidden;
      text-overflow: ellipsis;

      & span {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &Phone {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 4px;
      box-sizing: content-box;
    }

    &Messenger {
      width: 88px;
      margin-bottom: 0;
    }

    &MLMPult {
      width: 106px;

      &.hidden {
        visibility: hidden;
      }
    }

    &Contacts {
      width: 85px;
    }

    &Name, &Contacts {
      margin-bottom: 0;
      height: 20px;
    }

    &Team {
      width: 85px;
    }

    &Gold, &Diamond {
      width: 39px;
    }

    &Contacts, &Team, &Gold, &Diamond {
      position: relative;
      margin-right: 0;
      padding-left: 55px;
      font-size: 10px;
      line-height: 13px;
    }
  }
}

.children {
  position: relative;
  left: 6px;
  width: fit-content;

  &::before {
    position: absolute;
    content: '';
    left: -5px;
    top: -8px;
    bottom: 8px;
    width: 2px;
    background-color: grey_text_color;
  }
}

.team__currency {
  position: relative;

  &_gold {
    bottom: 2px;
    height: 20px;
  }

  &_diamond {
    bottom: 4px;
    height: 16px;
  }
}

.noContent {
  display: none;
}

.contactsBody {
  margin-top: 20px;
}

.contactsTable {
  border-collapse: separate;
  border-spacing: 23px 8px;
}

tr {
  text-align: left;
}

.row {
  &__firstLevel td:nth-child(3) {
    padding-right: 33px;
  }

  &__secondLevel td:nth-child(3) {
    padding-right: 33px;
  }
}

.td {
  font(main_text_color, 10px, 400, 13px);
}

.avatar__wrap {
  border-radius: 50%;
  margin-right: -16px;
  overflow: hidden;
}

tr td {
  vertical-align: middle;
}

tr td:nth-child(5) {
  vertical-align: top;
}

.team__treeOpen {
  padding: 0;
  width: 16px;
  height: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.avatar {
  display: block;
  width: 20px;
  height: 20px;
}

.team__link {
  text-decoration: none;

  &_active {
    color: #0F1642;
    font-size: 10px;
    font-weight: 700;
    line-height: 13px;
  }
}

.phone {
  text-decoration: none;
  letter-spacing: -0.17px;
}

.socials__wrap {
  display: inline-grid;
  grid-auto-flow: column;
}

.socials {
  display: inline-block;
  padding: 0 4px 4px;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;

  &:nth-of-type(1) {
    margin-left: -4px;
  }

  & svg {
    width: 100%;
    height: 100%;
  }
}

td {
  height: 24px;
}

.tasks {
  color: #0F1642;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.29px;
  vertical-align: top;
}

.accountInfo {
  padding: 5px;
  margin: -5px -1px -5px -5px;

  &_desktop {
    display: none;
  }
}

.accountInfoImg {
  display: inline-block;
  width: 12px;
  height: 12px;
  vertical-align: unset;
}

.team__tableText {
  font(main_text_color, 10px, 400, 13px);
  vertical-align: super;

  &_deadline {
    font(danger_text_color, 10px, 700, 13px);
  }

  .ending {
    color: #E81526;
    font-weight: bold;
  }
}

.teamTree__tableBodyCellMobile {
  position: absolute;
  left: 0;

  & span {
    opacity: 0.4;
    margin-right: 8px;
  }

  &Gold {
    width: 8px;
    height: 12px;
  }

  &Diamond {
    width: 12px;
    height: 9px;
  }

  &Gold, &Diamond {
    display: block;
    padding: 5px;
    margin: -5px;
    box-sizing: content-box;
  }
}

@media (min-width: 374px) {
  .mobileS {
    display: none !important;
  }

  .mobileM {
    display: grid !important;
  }
}

@media (min-width: 1320px) {
  .avatarDesktop {
    display: block;
  }

  .avatarMobile {
    display: none;
  }

  .mobileM {
    display: grid !important;
    width: auto !important;
  }

  .teamTree__tableBodyItem {
    margin-right: 0;
  }

  .teamTree__tableBodyRow {
    grid-column-gap: unset;
    margin-bottom: 8px;
  }

  .teamTree__tableBodyRowBox {
    &:not(:first-of-type) {
      grid-auto-flow: column;
    }

    &:nth-of-type(2) {
      width: auto !important;
    }
  }

  .team__treeOpen, .teamTree__tableBodyCellButton {
    width: 20px;
    height: 20px;
  }

  .teamTree__tableBodyCellButton {
    margin: 0 8px 0 0;
    padding: 0;
    box-sizing: border-box;
  }

  .teamTree__tableBodyCell {
    margin-right: 8px;
    margin-bottom: 0;
    min-height: 24px;
  }

  .teamTree__tableCellPhone {
    width: 136px;
    padding-bottom: 0;
    box-sizing: border-box;
  }

  .teamTree__tableCellRank {
    width: 132px;
  }

  .td, .team__link_active, .tasks, .teamTree__tableCellContacts, .teamTree__tableCellTeam, .teamTree__tableCellGold, .teamTree__tableCellDiamond {
    font-size: 14px;
    line-height: 18px;
  }

  .teamTree__tableCellContacts, .teamTree__tableCellTeam, .teamTree__tableCellGold, .teamTree__tableCellDiamond {
    margin-right: 8px;
    padding-left: 0;
  }

  .teamTree__tableCellName {
    & span {
      font-weight: 400;
    }
  }

  .teamTree__tableCellName, .teamTree__tableCellContacts {
    height: auto;
    margin-bottom: 0;
  }

  .teamTree__tableCellContacts {
    width: 89px;
  }

  .team__tableText, .team__tableText_deadline {
    font-size: 14px;
    line-height: 20px;
  }

  .teamTree__tableBodyCellAvatar {
    margin-right: 8px;
  }

  .teamTree__tableBodyCellMobile {
    display: none;
  }

  .accountInfo {
    margin: 0;
    margin-right: 8px;
    padding: 0;

    &_desktop {
      display: block;
    }

    &_mobile {
      display: none;
    }
  }

  .accountInfoImg {
    width: 20px;
    height: 20px;
  }

  .teamTree__tableBodyCellAvatar, .avatar {
    width: 24px;
    height: 24px;
  }

  .socials__wrap {
    grid-column-gap: 8px;
  }

  .socials {
    padding: 0;
    box-sizing: border-box;
    width: 20px;
    height: 20px;

    &:nth-of-type(1) {
      margin-left: 0px;
    }
  }

  .teamTree__tableCellFakeForRankMobile {
    display: none;
  }

  .children {
    left: 8px;
  }
}

@media (max-width: 1319px) {
  .teamTree__tableCellName {
    width: 100% !important;
  }
}
</style>
