import axiosConfigOtonVideoUploader from "@/utils/axiosConfigOtonVideoUploader";
import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import router from "@/router";
import { assocPath, pathOr } from "ramda";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
    videoLoader: false,
    otonVideoData: {},
  }),

  getters: {
    uploadLink(state) {
      return state.data.upload_link;
    },
    videoName(state) {
      return pathOr("", ["data", "name"], state);
    },
    videoDescription(state) {
      return pathOr("", ["data", "description"], state);
    },
    videoLoader(state) {
      return pathOr("", ["videoLoader"], state);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_AUTH(state, data) {
      state.otonVideoData = data;
    },
    SET_UPLOAD_DATA(state, data) {
      state.data = data;
    },
    CHANGE_VIDEO_NAME(state, value) {
      state.data = assocPath(["name"], value, state.data);
    },
    CHANGE_VIDEO_DESCRIPTION(state, value) {
      state.data = assocPath(["description"], value, state.data);
    },
    SET_VIDEO_PENDING(state) {
      state.videoLoader = true;
    },
  },
  actions: {
    async authOtonUploader({ state, commit, rootGetters, dispatch }) {
      if (state.pending) {
        return;
      }

      const data = {
        service: "chatbot",
        token: rootGetters["user/chatBotToken"],
      };

      try {
        commit("SET_PENDING", true);

        const response = await axios.post(
          `/auth`,
          data,
          axiosConfigOtonVideoUploader(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_AUTH", response.data.data);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async create({ state, commit, rootGetters, dispatch }) {
      Promise.all([dispatch("authOtonUploader")]).then(resp => {
        if (resp[0].status === 200) {
          const data = {
            name: state.data.name,
            redirect_url: window.location.href,
            description: state.data.description,
          };

          Promise.all([
            dispatch("createVideo", { data, token: resp[0].data.data.token }),
          ]).then(resp => {
            if (resp[0].status === 200) {
              commit("SET_UPLOAD_DATA", resp[0].data.data);
              dispatch("updateVideoInChatBot", {
                vimeo_id: resp[0].data.data.id,
              });
              document.forms["videoUploadForm"].submit();
              // const form: HTMLFormElement = document
              //   .getElementById("videoUploadForm");
              //   .submit();
            }
          });
        }
      });
    },

    async createVideo({ state, commit }, { data, token }) {
      if (state.pending) {
        return;
      }

      try {
        commit("SET_PENDING", true);

        const response = await axios.post(
          `/videos/`,
          data,
          axiosConfigOtonVideoUploader(token)
        );

        if (response.status === 200) {
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async updateVideoInChatBot({ commit, state, rootGetters }, { vimeo_id }) {
      if (state.pending) {
        return;
      }

      try {
        commit("SET_PENDING", true);
        const { webinarId } = router.currentRoute.params;
        const response = await axios.put(
          `/videos/${webinarId}`,
          { vimeo_id },
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
