import axiosConfig from "@/utils/axiosConfigFunnel";
import Cookies from "js-cookie";

import axios from "axios";
import moment from "moment";
import qs from "qs";
import { pathOr } from "ramda";
import Vue from "vue";

function filterNonNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

function transformFilters(obj) {
  let transformedObj = obj;
  if (transformedObj.start_created_at) {
    transformedObj = {
      ...transformedObj,
      start_created_at: moment(transformedObj.start_created_at)
        .utc(true)
        .startOf("day")
        .format(),
    };
  }
  if (transformedObj.finish_created_at) {
    transformedObj = {
      ...transformedObj,
      finish_created_at: moment(transformedObj.finish_created_at)
        .utc(true)
        .endOf("day")
        .format(),
    };
  }
  return transformedObj;
}

function changeResponseString(str, filter) {
  const arr1 = str.split("&");
  let findedEl = arr1.find(el => el.match(`${filter}=`));
  const arr2 = arr1.filter(el => el !== findedEl);
  const result1 = arr2.join("&");

  findedEl = findedEl.replace(`${filter}=`, "");

  const arr3 = findedEl.split("%2C");

  let result2 = arr3.join(`&${filter}[]=`);
  result2 = `&${filter}[]=` + result2;
  const result3 = result1 + result2;

  return result3;
}

export default {
  namespaced: true,

  state: () => ({
    data: {},
    leads: {},
    errors: {},
    pending: false,
    filters: {
      q: "",
    },
    tasqParametres: {},
    isPickedAllFunnels: false,
    isPickedAllTags: false,
    isPickedAllOrders: false,
    isPickedAllStatuses: false,
    funnelsFilterValue: "",
    funnelsFilterCounter: null,
    tagsFilterValue: "",
    tagsFilterCounter: null,
    ordersFilterValue: "",
    ordersFilterCounter: null,
    statusesFilterValue: "",
    statusesFilterCounter: null,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    leads(state) {
      return pathOr([], ["data", "data"], state);
    },
    search(state) {
      return state.filters.q;
    },
    hasNextPage(state) {
      const currentPage = pathOr(0, ["data", "meta", "current_page"], state);
      const lastPage = pathOr(0, ["data", "meta", "last_page"], state);
      return currentPage < lastPage;
    },
    currentPage(state) {
      return pathOr(undefined, ["data", "meta", "current_page"], state);
    },
    filters(state) {
      return state.filters;
    },
    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },
    pickedAllFunnels(state) {
      return state.isPickedAllFunnels;
    },
    pickedAllTags(state) {
      return state.isPickedAllTags;
    },
    pickedAllOrders(state) {
      return state.isPickedAllOrders;
    },
    pickedAllStatuses(state) {
      return state.isPickedAllStatuses;
    },
    funnelsFilterValue(state) {
      return state.funnelsFilterValue;
    },
    funnelsFilterCounter(state) {
      return state.funnelsFilterCounter;
    },
    tagsFilterValue(state) {
      return state.tagsFilterValue;
    },
    tagsFilterCounter(state) {
      return state.tagsFilterCounter;
    },
    ordersFilterValue(state) {
      return state.ordersFilterValue;
    },
    ordersFilterCounter(state) {
      return state.ordersFilterCounter;
    },
    statusesFilterValue(state) {
      return state.statusesFilterValue;
    },
    statusesFilterCounter(state) {
      return state.statusesFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = {
        data:
          data.meta.current_page === 1
            ? data.data
            : state.data.data.concat(data.data),
        meta: data.meta,
      };
    },
    SET_TASQ_PARAMETRES(state, data) {
      state.tasqParametres = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_FILTER(state, { value, name }) {
      if (value instanceof Array) {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (name === "start_created_at" || name === "finish_created_at") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }
      if (name === "webinar_progress_from") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }
      if (name === "webinar_progress_to") {
        if (
          state.filters.webinar_progress_from === undefined ||
          state.filters.webinar_progress_from === ""
        ) {
          state.filters = {
            ...state.filters,
            webinar_progress_from: "0",
            [name]: value,
          };
          return;
        }
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (state.filters[name]) {
        if (state.filters[name].includes(value)) {
          state.filters = {
            ...state.filters,
            [name]: state.filters[name].filter(el => el !== value),
          };
          if (name === "funnel_id") {
            state.isPickedAllFunnels = false;
          } else if (name === "tag_id") {
            state.isPickedAllTags = false;
          } else if (name === "order_status") {
            state.isPickedAllOrders = false;
          } else if (name === "status_id") {
            state.isPickedAllStatuses = false;
          }
        } else {
          if (name === "webinar_viewed") {
            state.filters = {
              ...state.filters,
              [name]: [value],
            };
          } else {
            state.filters = {
              ...state.filters,
              [name]: [...state.filters[name], value],
            };
          }
        }
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },

    CLEAR_FILTERS(state) {
      state.filters = {
        q: "",
      };
      state.isPickedAllFunnels = false;
      state.isPickedAllTags = false;
      state.isPickedAllOrders = false;
      state.isPickedAllStatuses = false;
      state.funnelsFilterValue = "";
      state.funnelsFilterCounter = null;
      state.tagsFilterValue = "";
      state.tagsFilterCounter = null;
      state.ordersFilterValue = "";
      state.ordersFilterCounter = null;
      state.statusesFilterValue = "";
      state.statusesFilterCounter = null;
    },

    CHANGE_SEARCH(state, value) {
      state.filters.q = value;
    },

    SET_FUNNELS_FILTER_VALUE(state, value) {
      state.funnelsFilterValue = value;
    },
    SET_FUNNELS_FILTER_COUNTER(state, value) {
      state.funnelsFilterCounter = Number(value);
    },

    SET_TAGS_FILTER_VALUE(state, value) {
      state.tagsFilterValue = value;
    },
    SET_TAGS_FILTER_COUNTER(state, value) {
      state.tagsFilterCounter = Number(value);
    },

    SET_ORDERS_FILTER_VALUE(state, value) {
      state.ordersFilterValue = value;
    },
    SET_ORDERS_FILTER_COUNTER(state, value) {
      state.ordersFilterCounter = Number(value);
    },

    SET_STATUSES_FILTER_VALUE(state, value) {
      state.statusesFilterValue = value;
    },
    SET_STATUSES_FILTER_COUNTER(state, value) {
      state.statusesFilterCounter = Number(value);
    },

    SET_PICKED_ALL_FUNNELS(state, value) {
      state.isPickedAllFunnels = !state.isPickedAllFunnels;

      if (value.length !== state?.filters?.funnel_id?.length) {
        state.isPickedAllFunnels = true;
      }

      state.isPickedAllFunnels
        ? (state.filters.funnel_id = [...value])
        : (state.filters.funnel_id = []);
    },

    SET_PICKED_ALL_TAGS(state, value) {
      state.isPickedAllTags = !state.isPickedAllTags;

      if (value.length !== state?.filters?.tag_id?.length) {
        state.isPickedAllTags = true;
      }

      state.isPickedAllTags
        ? (state.filters.tag_id = [...value])
        : (state.filters.tag_id = []);
    },

    SET_PICKED_ALL_ORDERS(state, value) {
      state.isPickedAllOrders = !state.isPickedAllOrders;

      if (value.length !== state?.filters?.order_status?.length) {
        state.isPickedAllOrders = true;
      }

      state.isPickedAllOrders
        ? (state.filters.order_status = [...value])
        : (state.filters.order_status = []);
    },

    SET_PICKED_ALL_STATUSES(state, value) {
      state.isPickedAllStatuses = !state.isPickedAllStatuses;

      if (value.length !== state?.filters?.status_id?.length) {
        state.isPickedAllStatuses = true;
      }

      state.isPickedAllStatuses
        ? (state.filters.status_id = [...value])
        : (state.filters.status_id = []);
    },

    UPDATE_LEAD_STATUS(state, { leadId, statusObj }) {
      state.data.data.find(el => el.id === leadId).status = statusObj;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },
  },

  actions: {
    async changeSearch({ commit, dispatch }, { search }) {
      commit("CHANGE_SEARCH", search);
      dispatch("fetchChatbotLeads", { page: 1 });
    },

    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },
    pickAllFunnels({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_FUNNELS", payload);
        resolve();
      });
    },
    pickAllTags({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_TAGS", payload);
        resolve();
      });
    },
    pickAllOrders({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_ORDERS", payload);
        resolve();
      });
    },
    pickAllStatuses({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_STATUSES", payload);
        resolve();
      });
    },

    async changeFilters({ commit, dispatch }, { filters }) {
      commit("CHANGE_FILTERS", filters);
      dispatch("fetch", { page: 1 });
    },

    async fetchSignature({ state, commit }, { page }) {
      try {
        commit("SET_PENDING", true);

        let filters = qs.stringify(
          transformFilters(
            filterNonNull({ ...state.filters, ...(page ? { page } : []) })
          ),
          { arrayFormat: "comma" }
        );

        const addBracketsToFilters = filter => {
          if (state.filters[filter]) {
            if (state.filters[filter].length > 1) {
              filters = changeResponseString(filters, filter);
            } else {
              filters = filters.replace(filter, filter + "[]");
            }
          }
        };

        addBracketsToFilters("funnel_id");
        addBracketsToFilters("tag_id");
        addBracketsToFilters("order_status");
        addBracketsToFilters("status_id");

        filters = filters.replace(
          /(webinar_progress_from|webinar_progress_to)/g,
          "webinar_progress[]"
        );

        const response = await Vue.axios.get(
          `/telephony/leads/v2/signed/?${filters}`
        );

        if (response.status === 200) {
          commit("SET_TASQ_PARAMETRES", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async fetchChatbotLeads({ commit, dispatch, state }, { page }) {
      await dispatch("fetchSignature", { page });

      const tasqParametres = { ...state.tasqParametres };
      const filters = tasqParametres.params;

      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/leads/manager?${filters}`,
          axiosConfig(tasqToken)
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
          return response;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
  },
};
