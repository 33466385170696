<template>
  <course-container />
</template>

<script>
import CourseContainer from "@/containers/Leadership/CourseContainer.vue";

export default {
  name: "Course",
  components: {
    CourseContainer,
  },
};
</script>
