<template>
  <div class="leadsCreateLink">
    <!-- name -->
    <label for="leadsCreateLink__input" class="leadsCreateLink__label">
      <Tr>Название ссылки</Tr>
    </label>
    <input
      id="leadsCreateLink__input"
      class="leadsCreateLink__input"
      type="text"
      :value="name"
      @input="changeName"
      required
    />
    <div class="inputWrap__error error">
      <div :key="error" class="error__item" v-for="error in errors.name">
        {{ error }}
      </div>
    </div>
    <!-- name -->

    <!-- product -->
    <div class="leadsCreateLink__select">
      <label class="leadsCreateLink__label" for="leadsCreateLinkProduct">
        <Tr>Продукт</Tr>
      </label>
      <select
        class="leadsCreateLink__selectItem"
        name="leadsCreateLinkProduct"
        id="leadsCreateLinkProduct"
        @change="changeProduct"
        required
      >
        <option
          v-for="(product, index) in products"
          :value="product.id"
          :key="`${index}-${product.id}`"
        >
          {{ product.name }}
        </option>
      </select>
      <div class="inputWrap__error error">
        <div
          :key="error"
          class="error__item"
          v-for="error in errors.product_id"
        >
          {{ error }}
        </div>
      </div>
    </div>

    <!-- video -->
    <div class="leadsCreateLink__select">
      <label class="leadsCreateLink__label" for="leadsCreateLinkVideo">
        <Tr>Видео</Tr>
      </label>
      <select
        class="leadsCreateLink__selectItem"
        name="leadsCreateLinkFunnel"
        id="leadsCreateLinkVideo"
        @change="changeVideo"
        required
      >
        <template v-if="videoId">
          <option
            v-for="(video, index) in videos"
            :value="video.id"
            :key="`${index}-${video.id}`"
          >
            {{ video.name }}
          </option>
        </template>
        <option v-else>-</option>
      </select>
      <div class="inputWrap__error error">
        <div :key="error" class="error__item" v-for="error in errors.video_id">
          {{ error }}
        </div>
      </div>
    </div>

    <!-- webinar start -->
    <label class="leadsCreateLink__label">
      <Tr>Начало вебинара</Tr>
    </label>
    <div class="datePickerBox">
      <date-picker
        type="datetime"
        format="DD MMM YYYY, HH:mm"
        :lang="['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale"
        v-model="startedAt"
        :default-value="new Date().setHours(10, 0, 0, 0)"
        :disabled-date="notBeforeToday"
      />
    </div>
    <div class="inputWrap__error error">
      <div class="error__item" v-for="error in errors.started_at" :key="error">
        {{ error }}
      </div>
    </div>
    <!-- webinar start -->
    <button class="leadsCreateLink__submit" @click="create">
      <Tr>Создать</Tr>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import { runNotice } from "@/utils/notifications";

export default {
  name: "LeadsCreateLinkWithoutChatbot",
  components: {
    DatePicker,
  },
  data() {
    return {
      leadUsid: "",
    };
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      linkCreated: "funnelCreateLinkWithoutChatbot/linkCreated",
      errors: "funnelCreateLinkWithoutChatbot/errors",
      products: "funnelCreateLinkWithoutChatbot/products",
      videos: "funnelCreateLinkWithoutChatbot/videos",
      videoId: "funnelCreateLinkWithoutChatbot/videoId",
    }),
    name() {
      return `${this.$t('Автоссылка для лида ')}${this.lead.name}`
    },
    startedAt: {
      get() {
        return this.$store.getters["funnelCreateLinkWithoutChatbot/startedAt"];
      },
      set(value) {
        this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_STARTED_AT", value);
      },
    },
  },
  methods: {
    create() {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_NAME", this.name);
      this.leadUsid = (Math.random().toString(36) + Math.random().toString(36)).substring(0, 15);
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_LEAD_USID", this.leadUsid)
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_LEAD_FIRST_NAME", this.lead.name);
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_LEAD_PHONE", this.lead.phone_number);
      this.$store.commit("leadsCreateLink/CHANGE_VIDEO_ID", this.videoId);
      this.$store.dispatch("funnelCreateLinkWithoutChatbot/createLink");
    },
    changeName(e) {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_NAME", e.target.value);
    },
    changeProduct(e) {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_PRODUCT", e.target.value);
    },
    changeVideo(e) {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CHANGE_VIDEO", e.target.value);
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLink {
  padding: 20px 0;
  width: 100%;
  max-width: 692px;
  background-color: #fff;
  border-radius: 8px;

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font_small();
  }

  &__input {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();
  }

  &__selectItem {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();
  }

  &__submit {
    padding: 11px 12px;
    width: fit-content;
    font_regular();
    background-color: grey_text_color;
    border: none;
    border-radius: 4px;
  }
}

@media (min-width: desktop) {
  .leadsCreateLink {
    padding: 0;

    &__input {
      width: 400px;
    }

    &__selectItem {
      width: 400px;
    }
  }
}

.error {
  &__item {
    color: danger_text_color;
    font-size: 14px;
  }
}

.datePickerBox {
  display: block;
  margin-bottom: 12px;
}

.inputWrap__error {
  position: relative;
  top: -5px;
}

.hiddenInput {
  display: block;
}
</style>
