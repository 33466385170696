<template>
  <div
    class="updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellSource"
    @click.prevent="$emit('show-source')"
  >
    <span
      :class="[
        'updatedFunnelBodyItem__sourceDate',
        { hide: sourceHide },
        { show: !sourceHide },
      ]"
      @click="$emit('show-lead')"
    >
      {{ dateFormatted(lead.trigger.created_at) }}
    </span>
    <div class="updatedFunnelBodyItem__sourceBox">
      <template v-if="lead.funnel">
        <span
          :class="[
            'updatedFunnelBodyItem__sourceItem',
            'updatedFunnelBodyItem__sourceItem_mobile',
            { updatedFunnelBodyItem__sourceItem_hide: sourceHide },
          ]"
          v-tooltip="sourceLinkTooltipMobile"
        >
          {{ lead.funnel.name }}
        </span>
        <span
          :class="[
            'updatedFunnelBodyItem__sourceItem',
            'updatedFunnelBodyItem__sourceItem_desktop',
            { updatedFunnelBodyItem__sourceItem_hide: sourceHide },
          ]"
          v-tooltip="sourceLinkTooltipDesktop"
        >
          {{ lead.funnel.name }}
        </span>
      </template>
      <span
        :class="[
          'updatedFunnelBodyItem__sourceItem',
          { updatedFunnelBodyItem__sourceItem_hide: sourceHide },
        ]"
        v-else
      >
        -
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "UpdatedFunnelBodyItemSource",

  props: {
    lead: {
      type: Object,
      required: true,
    },
    sourceHide: {
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
    sourceLinkTooltipMobile() {
      return {
        trigger: "click",
        content: this.lead.funnel.name,
        classes: ["funnel-tooltip"],
      };
    },
    sourceLinkTooltipDesktop() {
      return {
        trigger: "hover",
        content: this.lead.funnel.name,
        classes: ["funnel-tooltip"],
      };
    },
  },

  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.updatedFunnelBodyItem__cellSource {
  display: flex;
  justify-content: space-between;
  height: 20px;

  @media (min-width: desktop) {
    height: 24px;
  }
}

.updatedFunnelBodyItem__sourceBox, .updatedFunnelBodyItem__sourceDate {
  height: 20px;

  @media (min-width: desktop) {
    height: 24px;
  }
}

.updatedFunnelBodyItem__sourceDate {
  align-items: center;
}

.updatedFunnelBodyItem__sourceBox {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 2px;
}

.updatedFunnelBodyItem__sourceItem {
  position: relative;
  display: block;
  padding: 3px 4px;
  width: 64px;
  background-color: grey_text_color;
  overflow: hidden;
  white-space: nowrap;
  cell_fade();

  &_mobile {
    @media (min-width: desktop) {
      display: none;
    }
  }

  &_desktop {
    display: none;

    @media (min-width: desktop) {
      display: block;
    }
  }

  &_hide {
    width: 12px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }
  }

  &::after {
    background-image: linear-gradient(to right, rgba(230, 231, 235, 0) 0%, #e6e7eb 100%);
  }
}

.hide {
  display: none;
}

.show {
  display: flex;
}

@media (min-width: desktop) {
  .updatedFunnelBodyItem__cell {
    margin-bottom: 0;
  }
}
</style>
