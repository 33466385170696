<template>
  <div class="deadline">
    <base-checkbox
      :title="'Дедлайн'"
      :checked="deadlineChecked"
      @change-checkbox="toggleDeadline"
    />
    <div class="deadline__box" v-if="deadlineChecked">
      <input
        class="deadline__input"
        type="text"
        id="deadlineTypeNone"
        value="none"
        v-mask="'###'"
        v-model="deadlineModel"
      />
      <span class="deadline__radioInputFake" />
      <label class="deadline__label" for="deadlineTypeNone">
        <Tr>чч.</Tr>
      </label>
    </div>
    <div class="inputWrap__error error" v-if="deadlineErrors">
      <div :key="error" class="error__item" v-for="error in deadlineErrors">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LessonDeadline",
  data() {
    return {};
  },
  props: {
    deadline: [Object],
    errors: Object,
  },
  computed: {
    deadlineModel: {
      get() {
        return this.deadline.value;
      },
      set(value) {
        this.$emit("change-deadline", value);
      },
    },

    deadlineErrors() {
      return this.errors.time_to_complete;
    },

    deadlineChecked() {
      return this.deadline ? this.deadline.checked : false;
    },
  },
  methods: {
    toggleDeadline() {
      this.$emit("toggle-deadline");
    },
  },
};
</script>

<style lang="stylus">
.deadline {
  margin: 20px 0;

  &__input {
    max-width: 40px;
    height: 26px;
    border: 1px solid #e6e7eb;
    text-align: center;
    font-size: 14px;
  }

  &__label {
    position: relative;
    display: inline-block;
    margin-top: 12px;
    margin-right: 7px;
    padding-left: 5px;
    font_regular();

    @media (min-width: tablet_large) {
      margin-top: 8px;
    }
  }
}
</style>
