<template>
  <modal
    name="updated-funnel-lead"
    :width="640"
    :height="556"
    :adaptive="true"
    :shiftY="0.5"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <updated-lead-profile :tabs="tabs" :activeTab="activeTab" :lead="lead">
      <template slot="closeButton">
        <button
          class="closeButton"
          type="button"
          @click="hideUpdatedFunnelLead"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </template>
    </updated-lead-profile>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";

import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import UpdatedLeadProfile from "@/components/Leads/UpdatedLeadProfile.vue";

export default {
  name: "UpdatedFunnelModalLead",
  components: {
    CloseButton,
    UpdatedLeadProfile,
  },
  data() {
    return {
      lead: {},
      activeTab: "events",
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      statuses: "updatedLeadsStatuses/statuses",
      notes: "updatedLeadNotes/notes",
    }),

    tabs() {
      return [
        {
          title: "События",
          name: "events",
        },
        {
          title: "Инфо",
          name: "info",
        },
      ];
    },
  },
  methods: {
    beforeOpen(event) {
      this.lead = event.params.lead;
    },

    beforeClose() {
      this.activeTab = "events";
    },

    hideUpdatedFunnelLead() {
      this.$modal.hide("updated-funnel-lead");
    },
  },
};
</script>

<style lang="stylus" scoped>
.closeButton {
  position: relative;
  top: 0;
  right: 0;
  display: block;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: unset;
  border: none;

  @media (min-width: tablet) {
    top: 3px;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
