<template>
  <div class="funnel__createLinkContainer">
    <label for="funnel__createLinkInput" class="funnel__createLinkLabel">
      <Tr>Название ссылки</Tr>
    </label>
    <input
      id="funnel__createLinkInput"
      class="funnel__createLinkInput"
      type="text"
      :value="name"
      @input="changeName"
    />
    <div class="inputWrap__error error">
      <div :key="error" class="error__item" v-for="error in nameErrors">
        {{ error }}
      </div>
    </div>
    <!-- ОПИСАНИЕ -->
    <template v-if="!updatedFunnel">
      <label for="funnel__description" class="funnel__createLinkLabel">
        <Tr>Описание</Tr>
      </label>
      <textarea
        id="funnel__description"
        class="funnel__createLinkInput textarea"
        rows="5"
        resize="none"
        :value="description"
        @input="changeDescription"
      />
    </template>
    <!-- ОПИСАНИЕ -->

    <fieldset class="funnel__createLinkFieldset" v-if="!pending">
      <!-- ПРОДУКТ -->
      <div class="funnel__createLinkSelect">
        <label
          class="funnel__createLinkSelectLabel"
          for="funnelCreateLinkProduct"
        >
          <Tr>Продукт</Tr>
        </label>
        <select
          class="funnel__createLinkSelectItem"
          name="funnelCreateLinkProduct"
          id="funnelCreateLinkProduct"
          @change="changeProduct"
        >
          <option v-if="!createLinkPropsObject.productId">
            <Tr>Выберите воронку</Tr>
          </option>
          <template v-if="products">
            <option
              v-for="(product, index) in products"
              :value="product.id"
              :key="`${index}-${product.id}`"
            >
              {{ product.name }}
            </option>
          </template>
        </select>
        <div class="inputWrap__error error">
          <div
            :key="error"
            class="error__item"
            v-for="error in errors.product_id"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <!-- ПРОДУКТ -->

      <!-- ВОРОНКА -->
      <div class="funnel__createLinkSelect">
        <label
          class="funnel__createLinkSelectLabel"
          for="funnelCreateLinkFunnel"
          ><Tr>Воронка</Tr>
        </label>
        <select
          class="funnel__createLinkSelectItem"
          name="funnelCreateLinkFunnel"
          id="funnelCreateLinkFunnel"
          @change="changeFunnel"
        >
          <option v-if="!createLinkPropsObject.funnelId">
            <Tr>Выберите воронку</Tr>
          </option>
          <template v-if="funnels">
            <option
              v-for="(funnel, index) in funnels"
              :value="funnel.id"
              :key="`${index}-${funnel.id}`"
            >
              {{ funnel.name }}
            </option>
          </template>
        </select>
        <div class="inputWrap__error error">
          <div
            :key="error"
            class="error__item"
            v-for="error in errors.funnel_id"
          >
            {{ error }}
          </div>
        </div>
      </div>
      <!-- ВОРОНКА -->

      <!-- СТАРТОВАЯ ТОЧКА -->
      <template v-if="updatedFunnel">
        <div class="funnel__createLinkSelect">
          <label
            class="funnel__createLinkSelectLabel"
            for="funnelCreateLinkStarter"
          >
            <Tr>Стартовая точка</Tr>
          </label>
          <select
            class="funnel__createLinkSelectItem"
            name="funnelCreateLinkStarter"
            id="funnelCreateLinkStarter"
            @change="changeStarter"
          >
            <option v-if="!createLinkPropsObject.starterId">
              <Tr>Выберите стартовую точку</Tr>
            </option>
            <template v-if="starters">
              <option
                v-for="(starter, index) in starters"
                :value="starter.id"
                :key="`${index}-${starter.id}`"
              >
                {{ starter.name }}
              </option>
            </template>
          </select>
          <div class="inputWrap__error error">
            <div
              :key="error"
              class="error__item"
              v-for="error in errors.funnel_starter_id"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </template>
      <!-- СТАРТОВАЯ ТОЧКА -->

      <!-- ЦЕЛЕВАЯ АУДИТОРИЯ -->
      <template v-else>
        <div class="funnel__createLinkSelect">
          <label
            class="funnel__createLinkSelectLabel"
            for="funnelCreateLinkTarget"
            ><Tr>Целевая аудитория</Tr>
          </label>
          <select
            class="funnel__createLinkSelectItem"
            name="funnelCreateLinkTarget"
            id="funnelCreateLinkTarget"
            @change="changeLanding"
          >
            <option v-if="!createLinkPropsObject.landingId">
              <Tr>Выберите целевую аудиторию</Tr>
            </option>
            <template v-if="landings">
              <option
                v-for="(landing, index) in landings"
                :value="landing.id"
                :key="`${index}-${landing.id}`"
              >
                {{ landing.name }}
              </option>
            </template>
          </select>
          <div class="inputWrap__error error">
            <div
              :key="error"
              class="error__item"
              v-for="error in errors.landing_id"
            >
              {{ error }}
            </div>
          </div>
        </div>
      </template>
      <!-- ЦЕЛЕВАЯ АУДИТОРИЯ -->
    </fieldset>
    <loader v-else />
    <button class="funnel__createLinkSubmit" @click="create">
      <Tr>Создать</Tr>
    </button>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "FunnelCreateLink",
  components: {
    Loader,
  },
  props: {
    updatedFunnel: Boolean,
    createLinkPropsObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    linkCreated() {
      return this.createLinkPropsObject.linkCreated;
    },

    name() {
      return this.createLinkPropsObject.name;
    },

    description() {
      return this.createLinkPropsObject.description ? this.createLinkPropsObject.description : '';
    },

    products() {
      return this.createLinkPropsObject.products;
    },

    pending() {
      return this.createLinkPropsObject.pending;
    },

    productsWithoutFirst() {
      const productsArr = [...this.products];
      return productsArr.slice(1);
    },

    funnels() {
      return this.createLinkPropsObject.funnels;
    },

    funnelsWithoutFirst() {
      const funnelsArr = [...this.funnels];
      return funnelsArr.slice(1);
    },

    landings() {
      return this.createLinkPropsObject.landings ? this.createLinkPropsObject.landings : [];
    },

    starters() {
      return this.createLinkPropsObject.starters ? this.createLinkPropsObject.starters : [];
    },

    startersWithoutFirst() {
      const startersArr = [...this.starters];
      return startersArr.slice(1);
    },

    errors() {
      return this.createLinkPropsObject.errors;
    },

    nameErrors() {
      if (this.errors) {
        return this.errors.name ? this.errors.name : this.errors.utm_content;
      }
      return [];
    },
  },
  methods: {
    create() {
      this.$emit("create");
    },
    changeName(e) {
      this.$emit("changeName", e.target.value);
    },
    changeDescription(e) {
      this.$emit("changeDescription", e.target.value);
    },
    changeProduct(e) {
      this.$emit("changeProduct", e.target.value);
    },
    changeFunnel(e) {
      this.$emit("changeFunnel", e.target.value);
    },
    changeLanding(e) {
      this.$emit("changeLanding", e.target.value);
    },
    changeStarter(e) {
      this.$emit("changeStarter", e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnel__createLink {
  &Title {
    margin-bottom: 16px;
  }

  &Container {
    padding: 20px 10px;
    width: 100%;
    max-width: 692px;
    background-color: #fff;
    box-shadow: 0px 3px 10px #0F164214;
    border-radius: 8px;
  }

  &Label, &SelectLabel {
    display: inline-block;
    margin-bottom: 4px;
    font_small();
  }

  &Input, &SelectItem {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    &.textarea {
      resize: none;
    }
  }

  &Fieldset {
    display: block;
    margin-bottom: 20px;
  }

  &Legend {
    margin-bottom: 8px;
    font_small();
  }

  &Select {
  }

  &Submit {
    padding: 11px 12px;
    font_regular();
    background-color: grey_text_color;
    border: none;
    border-radius: 4px;

    &:hover {
      opacity: 0.6;
    }
  }
}

@media (min-width: desktop) {
  .funnel__linkCreatedBody {
    padding: 16px 16px 20px;
  }

  .funnel__createLink {
    &Container {
      padding: 16px 16px 20px;
      width: 692px;
    }

    &Input, &SelectItem {
      width: 400px;

      &_linkCreated {
        width: 400px;
      }
    }
  }

  .funnel__linkCreated {
    &Container {
      width: 692px;
    }
  }

  &Body {
    &Text {
      width: 335px;
    }
  }

  .createLinkButton {
    font-size: 14px;
    line-height: 18px;
  }
}

.error {
  &__item {
    position: relative;
    top: -5px;
    color: danger_text_color;
    font-size: 14px;
  }
}
</style>
