var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['headerInformation', { headerInformation_mobile: _vm.mobile }]},[_c('h3',{class:[
      'headerInformation__title',
      { headerInformation__title_mobile: _vm.mobile } ]},[_c('Tr',[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"headerInformation__wrap"},[_c('span',{class:[
        'headerInformation__txt',
        { headerInformation__txt_mobile: _vm.mobile } ]},[_c('Tr',[_vm._v(_vm._s(_vm.param1))])],1),(_vm.name === 'tasks')?[_c('base-link',{class:[
          'headerInformation__number',
          { headerInformation__number_mobile: _vm.mobile } ],attrs:{"to":"/tasks/actual"}},[_vm._v(" "+_vm._s(_vm.value1)+" ")])]:[_c('span',{class:[
          'headerInformation__number',
          { headerInformation__number_mobile: _vm.mobile } ]},[_vm._v(" "+_vm._s(_vm.value1)+" ")])]],2),_c('div',{staticClass:"headerInformation__wrap"},[_c('span',{class:[
        'headerInformation__txt',
        { headerInformation__txt_mobile: _vm.mobile } ]},[_c('Tr',[_vm._v(_vm._s(_vm.param2))])],1),(_vm.name === 'tasks')?[_c('base-link',{class:[
          'headerInformation__number',
          { headerInformation__numberFUp: _vm.expired },
          { headerInformation__number_mobile: _vm.mobile } ],attrs:{"to":"/tasks/expired"}},[_vm._v(" "+_vm._s(_vm.value2)+" ")])]:[_c('span',{class:[
          'headerInformation__number',
          { headerInformation__numberFUp: _vm.expired },
          { headerInformation__number_mobile: _vm.mobile } ]},[_vm._v(" "+_vm._s(_vm.value2)+" ")])]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }