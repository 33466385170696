<script>
import { sidebarHandler } from "@/components/common/SidebarHandler.js";

export default {
  name: "HeaderMenuButton",
  data() {
    sidebarHandler.$on("aside-menu-handler", this.menuClose);
    return {
      btnMenuOpen: false,
    };
  },
  methods: {
    menuOpen() {
      sidebarHandler.$emit("aside-menu-handler");
    },
    menuClose() {
      this.btnMenuOpen = !this.btnMenuOpen;
    },
  },
};
</script>

<template>
  <button
    class="headerMenuBtn"
    :class="{ headerMenuBtn_open: btnMenuOpen }"
    @click="menuOpen"
  />
</template>

<style lang="stylus" scoped>
.headerMenuBtn {
  display: block;
  padding: 5px 0 5px 10px;
  width: 19px;
  height: 16px;
  box-sizing: content-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='16' viewBox='0 0 19 16'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' d='M10 0v2h9V0zm0 7v2h9V7zM0 4.5l4.596 4.596L6.01 7.682 2.828 4.5 6.01 1.318 4.596-.096zM1 14v2h18v-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center right;
  border: none;
  transition: 0.2s;

  &_open {
    background-image: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='19' height='16' viewBox='0 0 19 16' %3E%3Cg%3E%3Cg%3E%3Cpath fill='%23fff' d='M0 0v2h9V0zm0 7v2h9V7zM14.404-.096L12.99 1.318 16.172 4.5 12.99 7.682l1.414 1.414L19 4.5zM0 14v2h18v-2z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  }

  @media (min-width: desktop) {
    display: none;
  }
}
</style>
