<template>
  <div
    :class="['updatedFunnelLead', { updatedFunnelLead_leadsPage: leadsPage }]"
  >
    <!-- HEADER -->
    <div class="updatedFunnelLead__header">
      <div class="updatedFunnelLead__headerName">{{ lead.id }} {{ name }}</div>
      <slot name="closeButton" />
    </div>
    <!-- HEADER -->

    <div
      :class="[
        'updatedFunnelLead__source',
        { updatedFunnelLead__source_link: sourceLinkUrl },
      ]"
    >
      <div
        :class="[
          'updatedFunnelLead__sourceBox',
          { updatedFunnelLead__sourceBox_link: source.name === 'link' },
          {
            updatedFunnelLead__sourceBox_link:
              sourceLinkUrl && source.name === 'funnel',
          },
        ]"
        v-for="source in sources"
        :key="source.title"
      >
        <span class="updatedFunnelLead__sourceTitle">
          <Tr>{{ source.title }}</Tr>
        </span>
        <div class="updatedFunnelLead__sourceText">
          <template v-if="source.name === 'date'">
            {{ dateFormatted(source.text) }}
          </template>

          <template v-else-if="source.name === 'source'">
            {{ $t(sourcePoint(source.text)) }}
          </template>

          <template v-else-if="source.name === 'link'">
            <span
              class="sourceTooltip sourceTooltip_desktop"
              v-tooltip="{
                content: `${source.text.linkName} ${source.text.linkUrl}`,
                trigger: 'hover',
              }"
            />
            <span
              class="sourceTooltip sourceTooltip_mobile"
              v-tooltip="{
                content: `${source.text.linkName} ${source.text.linkUrl}`,
                trigger: 'click',
              }"
            />
            <span class="funnelName">
              <span class="linkName" v-if="source.text.linkName">
                {{ source.text.linkName }}
              </span>
              <span>
                {{ source.text.linkUrl }}
              </span>
            </span>
          </template>

          <template v-else-if="source.text">
            <span
              class="sourceTooltip sourceTooltip_desktop"
              v-tooltip="{ content: source.text, trigger: 'hover' }"
            />
            <span
              class="sourceTooltip sourceTooltip_mobile"
              v-tooltip="{ content: source.text, trigger: 'click' }"
            />
            <span class="funnelName">
              {{ source.text }}
            </span>
          </template>
        </div>
      </div>
    </div>

    <tab-list
      :tabs="tabs"
      :activeTab="activeTab"
      :mobileBtns="true"
      @handle-main-tabs="handleTabs"
      :class="[
        'updatedFunnelLead__tabList',
        { updatedFunnelLead__tabList_leadsPage: leadsPage },
      ]"
    />

    <!-- BODY -->

    <!-- events -->
    <div class="updatedFunnelLead__table" v-if="activeTab === 'events'">
      <!-- events-head -->
      <div
        v-if="eventsTableIsFull"
        class="updatedFunnelLead__tableHead updatedFunnelLead__tableGrid"
      >
        <template v-for="tableHead in tableHeads">
          <Tr :key="tableHead.title">{{ tableHead.title }}</Tr>
        </template>
      </div>
      <!-- events-head -->

      <!-- events-body -->
      <div class="updatedFunnelLead__tableBody">
        <div
          class="updatedFunnelLead__tableItem"
          v-for="pipelineItem in reversedPipeline"
          :key="pipelineItem.value"
        >
          <component
            :is="actionComponent(pipelineItem.array)"
            :actionItem="findEl(pipelineItem.value, pipelineItem.array)"
            class="
              updatedFunnelLead__tableItem updatedFunnelLead__tableGrid
              tableItem
            "
          />
        </div>
        <updated-funnel-modal-lead-trigger
          class="
            updatedFunnelLead__tableItem updatedFunnelLead__tableGrid
            tableItem
          "
          :trigger="lead.trigger"
        />
        <!-- events-body -->
      </div>
    </div>
    <!-- events -->

    <!-- info -->
    <template v-else-if="activeTab === 'info'">
      <updated-leads-modal-lead-info
        class="updatedFunnelLead__table updatedFunnelLead__table_info"
        :lead="lead"
        :statuses="statuses"
        @change-status="changeStatus"
        v-if="leadsPage"
      />
      <updated-funnel-modal-lead-info
        class="updatedFunnelLead__table updatedFunnelLead__table_info"
        :lead="lead"
        v-else
      />
    </template>
    <!-- info -->

    <!-- notes -->
    <template v-else-if="activeTab === 'notes'">
      <updated-leads-modal-lead-notes
        :notes="notes"
        :lead="lead"
        @create-note="createNote"
        @update-note="updateNote"
        @delete-note="delNote"
        v-if="leadsPage"
      />
    </template>
    <!-- notes -->

    <!-- paymentLink -->
    <template v-else-if="activeTab === 'paymentLink'">
      <updated-leads-create-order-box v-if="leadsPage" :lead="lead" />
    </template>
    <!-- paymentLink -->

    <!-- webinarLink -->
    <template v-else-if="activeTab === 'webinarLink'">
      <updated-leads-create-link-to-webinar-box v-if="leadsPage" :lead="lead" />
    </template>
    <!-- webinarLink -->

    <!-- restartFunnel -->
    <template
      v-else-if="activeTab === 'restartFunnel' && lead.funnel.is_restarted"
    >
      <updated-leads-restart-funnel v-if="leadsPage" :lead="lead" />
    </template>
    <!-- restartFunnel -->

    <!-- callsHistory -->
    <template v-else-if="activeTab === 'callsHistory' && isUserOP">
      <leads-profile-calls v-if="leadsPage" :calls="calls" />
    </template>
    <!-- callsHistory -->

    <!-- BODY -->
  </div>
</template>

<script>
import { pathOr } from "ramda";
import moment from "moment";
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

import TabList from "@/components/TabList.vue";
import UpdatedFunnelModalLeadNotification from "@/components/Funnel/UpdatedFunnelModalLeadNotification.vue";
import UpdatedFunnelModalLeadChatbot from "@/components/Funnel/UpdatedFunnelModalLeadChatbot.vue";
import UpdatedFunnelModalLeadWebinar from "@/components/Funnel/UpdatedFunnelModalLeadWebinar.vue";
import UpdatedFunnelModalLeadTrigger from "@/components/Funnel/UpdatedFunnelModalLeadTrigger.vue";
import UpdatedFunnelModalLeadTriggers from "@/components/Funnel/UpdatedFunnelModalLeadTriggers.vue";
import UpdatedFunnelModalLeadOrder from "@/components/Funnel/UpdatedFunnelModalLeadOrder.vue";
import UpdatedFunnelModalLeadInfo from "@/components/Funnel/UpdatedFunnelModalLeadInfo.vue";
import UpdatedLeadsModalLeadInfo from "@/components/Leads/UpdatedLeadsModalLeadInfo.vue";
import UpdatedLeadsModalLeadNotes from "@/components/Leads/UpdatedLeadsModalLeadNotes.vue";
import UpdatedLeadsCreateLinkToWebinarBox from "@/components/Leads/UpdatedLeadsCreateLinkToWebinarBox.vue";
import UpdatedLeadsCreateOrderBox from "@/components/Leads/UpdatedLeadsCreateOrderBox.vue";
import UpdatedLeadsRestartFunnel from "@/components/Leads/UpdatedLeadsRestartFunnel.vue";
import LeadsProfileCalls from "@/components/Leads/LeadsProfileCalls.vue";

export default {
  name: "UpdatedLeadProfile",
  components: {
    TabList,
    UpdatedFunnelModalLeadNotification,
    UpdatedFunnelModalLeadChatbot,
    UpdatedFunnelModalLeadWebinar,
    UpdatedFunnelModalLeadTriggers,
    UpdatedFunnelModalLeadOrder,
    UpdatedFunnelModalLeadTrigger,
    UpdatedFunnelModalLeadInfo,
    UpdatedLeadsModalLeadInfo,
    UpdatedLeadsModalLeadNotes,
    UpdatedLeadsCreateLinkToWebinarBox,
    UpdatedLeadsCreateOrderBox,
    UpdatedLeadsRestartFunnel,
    LeadsProfileCalls,
  },
  props: {
    leadsPage: Boolean,
    tabs: {
      type: Array,
      requred: true,
    },
    activetab: {
      type: String,
      requred: true,
    },
    lead: {
      type: Object,
      requred: true,
    },

    calls: {
      type: Array,
      requred: true,
    },
  },
  data() {
    return {
      activeTab: "events",
      tableHeads: [
        {
          title: "Дата и время",
        },
        {
          title: "Событие",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      statuses: "updatedLeadsStatuses/statuses",
      changedStatus: "updatedLeadChangeStatus/changedStatus",
      errors: "updatedLeadChangeStatus/errors",
      notes: "updatedLeadNotes/notes",
      isUserOP: "user/showUpdatedLeads",
      isDev: "user/isDev",
    }),

    name() {
      return pathOr("-", ["trigger", "name"], this.lead);
    },
    sources() {
      return [
        {
          name: "date",
          title: "Время и дата",
          text: pathOr("", ["trigger", "created_at"], this.lead),
        },
        this.lead.funnel ?
          {
            name: "funnel",
            title: "Воронка",
            text: pathOr("", ["funnel", "name"], this.lead),
          } : {},
        {
          name: "source",
          title: "Источник",
          text: pathOr("", ["trigger", "source"], this.lead),
        },
        this.sourceLinkUrl ?
          {
            name: "link",
            title: "Ссылка",
            text: {
              linkUrl: this.sourceLinkUrl,
              linkName: this.sourceLinkName,
            },
          } : {},
      ];
    },

    sourceLinkUrl() {
      return pathOr("", ["link", "redirect_url"], this.lead);
    },

    sourceLinkName() {
      return pathOr("", ["link", "name"], this.lead);
    },

    eventsTableIsFull() {
      return Object.keys(this.lead) && Object.keys(this.lead).length > 0;
    },

    reversedPipeline() {
      if (this.lead.pipeline) {
        const arr = [...this.lead.pipeline];
        return arr.reverse();
      }
      return [];
    },
  },

  watch: {
    changedStatus(val) {
      runNotice("success", this.$t("Статус изменен"));
      this.$store.dispatch("updatedLead/updateLeadStatus", val);
      this.$store.commit("updatedLeads/UPDATE_LEAD_STATUS", { leadId: this.lead.id, statusObj: val });
    },

    errors() {
      runNotice("error", this.$t("Ошибка"));
    },

    activeTab(val) {
      if (val === 'events') {
        this.$store.dispatch("updatedLead/fetch", this.$route.params.leadId);
      }
    }
  },

  methods: {
    changeStatus(status) {
      const leadId = this.lead.id;
      this.$store.dispatch("updatedLeadChangeStatus/changeStatus", {
        leadId,
        status,
      });
    },

    createNote(data) {
      this.$store.dispatch("updatedLeadNotes/createNote", {
        ...data,
        leadId: this.lead.id,
      });
    },

    updateNote(data) {
      this.$store.dispatch("updatedLeadNotes/updateNote", {
        ...data,
        leadId: this.lead.id,
      });
    },

    delNote(data) {
      this.$store.dispatch("updatedLeadNotes/deleteNote", data);
    },

    sourcePoint(point) {
      const sourceObj = {
        form: "Лендинг",
        chatbot: "Telegram",
        vk_form: "Форма ВК",
        fb_form: "Форма FB",
      };

      return sourceObj[point];
    },

    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },

    handleTabs(tabName) {
      this.activeTab = tabName;
    },

    withoutRepeat(arr1, arr2, index, param) {
      if (index === 0) {
        return arr2[0][param] !== arr1[param];
      }
      return (
        arr2[index][param] !== arr1[param] &&
        arr2[index][param] !== arr2[index - 1][param]
      );
    },

    actionComponent(arrayName) {
      const obj = {
        orders: "updatedFunnelModalLeadOrder",
        triggers: "updatedFunnelModalLeadTriggers",
        webinars: "updatedFunnelModalLeadWebinar",
        notifications: "updatedFunnelModalLeadNotification",
        chatbot: "updatedFunnelModalLeadChatbot",
      };
      return obj[arrayName];
    },

    findEl(id, array) {
      return this.lead[array].find(el => el.id === id);
    },

    leadPipelineItemArray(name) {
      const nameToArray = name.split("-");
      const arrayToName = nameToArray[0];
      return arrayToName;
    },
  },
};
</script>

<style lang="stylus" scoped>
.updatedFunnelLead {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  height: 100%;

  @media (min-width: tablet) {
    padding: 16px 16px 21px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;

    &_leadsPage {
      width: 100%;
      max-width: calc(100vw - 32px);
    }
  }

  @media (min-width: 930px) {
    &_leadsPage {
      max-width: 900px;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    padding-bottom: 17px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__source {
    display: grid;
    grid-gap: 8px;
    margin-bottom: 17px;
    width: fit-content;

    @media (min-width: tablet) {
      grid-auto-flow: column;
      grid-gap: 0 16px;

      &_link {
        grid-auto-flow: unset;
        grid-template-columns: 120px auto;
        grid-gap: 8px;
      }
    }
  }

  &__sourceBox {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media (min-width: tablet) {
      max-width: 170px;
    }

    &_link {
      max-width: unset;
    }
  }

  &__sourceTitle {
    font_small();
    opacity: 0.6;
  }

  &__sourceText {
    position: relative;
    z-index: 1;
    font_regular();
  }

  &__tabList {
    margin-bottom: 11px;
    min-height: 47px;

    &_leadsPage {
      width: calc(100vw - 36px);

      @media (min-width: 930px) {
        overflow: auto;
      }
    }

    @media (min-width: tablet) {
      min-height: unset;
      margin-right: -16px;
      margin-left: -16px;
      width: auto;
    }
  }

  &__table {
    flex-grow: 1;
    padding-bottom: 12px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #ebe6e7 #fff;

    &_info {
      flex-grow: unset;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e6e7eb;
      border: 2px solid #fff;
      border-radius: 6px;
    }

    @media (min-width: tablet) {
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__tableGrid {
    @media (min-width: tablet) {
      display: grid;
      grid-template-columns: 110px calc(100% - 127px);
      grid-column-gap: 17px;
    }
  }

  &__tableHead {
    display: none;
    margin-bottom: 13px;
    font_bold();

    @media (min-width: tablet) {
      display: grid;
    }
  }

  &__tableItem {
    font_regular();

    &:not(:last-of-type) {
      margin-bottom: 25px;

      @media (min-width: tablet) {
        margin-bottom: 13px;
      }
    }
  }
}

.funnelName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.linkName {
  font-weight: 700;
}

.sourceTooltip {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;

  &_mobile {
    @media (min-width: tablet) {
      display: none;
    }
  }

  &_desktop {
    display: none;

    @media (min-width: tablet) {
      display: block;
    }
  }
}
</style>
