<template>
  <div class="updatedFunnelLead__table tableInfo tableItem">
    <div v-if="lead.trigger && lead.trigger.email" class="tableInfo__item">
      <Tr class="tableInfo__title">Электронная почта</Tr>
      <div class="tableInfo__textBox">
        <span class="tableInfo__textItem">{{ lead.trigger.email }}</span>
        <span
          class="tableInfo__textItem"
          v-for="(leadTriggers, index) in lead.triggers"
          :key="leadTriggers.id"
        >
          <template
            v-if="withoutRepeat(lead.trigger, lead.triggers, index, 'email')"
          >
            {{ leadTriggers.email }}
          </template>
        </span>
      </div>
    </div>
    <div
      v-if="lead.trigger.source === 'chatbot' && lead.trigger.username"
      class="tableInfo__item"
    >
      <Tr class="tableInfo__title">Аккаунт Телеграма</Tr>
      <div class="tableInfo__textBox">
        <span>{{ lead.trigger.username }}</span>
      </div>
    </div>
    <div v-if="lead.trigger && lead.trigger.phone" class="tableInfo__item">
      <Tr class="tableInfo__title">Телефон</Tr>
      <div class="tableInfo__textBox">
        <span class="tableInfo__textItem">{{ lead.trigger.phone }}</span>
        <span
          class="tableInfo__textItem"
          v-for="(leadTriggers, index) in lead.triggers"
          :key="leadTriggers.id"
        >
          <template
            v-if="withoutRepeat(lead.trigger, lead.triggers, index, 'phone')"
          >
            {{ leadTriggers.phone }}
          </template>
        </span>
      </div>
    </div>
    <div v-if="lead.trigger && lead.trigger.name" class="tableInfo__item">
      <Tr class="tableInfo__title">Имя</Tr>
      <div class="tableInfo__textBox">
        <span class="tableInfo__textItem">{{ lead.trigger.name }}</span>
        <span
          class="tableInfo__textItem"
          v-for="(leadTriggers, index) in lead.triggers"
          :key="leadTriggers.id"
        >
          <template
            v-if="withoutRepeat(lead.trigger, lead.triggers, index, 'name')"
          >
            {{ leadTriggers.name }}
          </template>
        </span>
      </div>
    </div>
    <div class="tableInfo__item">
      <Tr class="tableInfo__title">Статус</Tr>
      <updated-leads-profile-info-statuses
        :class="['leadsProfileInfoFieldset']"
        :fieldset="fieldset"
        :leadStatus="lead.status.id"
        @change="changeStatus"
      />
    </div>
    <form-submit
      :orange="true"
      text="К карточке"
      @click="toCart"
      v-if="getContact"
    />
    <Tr
      class="tableInfo__empty"
      v-if="lead.trigger.length > 0 && lead.triggers.length > 0"
      >Нет данных</Tr
    >
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import UpdatedLeadsProfileInfoStatuses from "@/components/Leads/UpdatedLeadsProfileInfoStatuses.vue";
import FormSubmit from "@/components/FormSubmit.vue";

export default {
  name: "UpdatedLeadsModalLeadInfo",

  components: {
    UpdatedLeadsProfileInfoStatuses,
    FormSubmit,
  },

  props: {
    lead: {
      type: Object,
      required: true,
    },
    statuses: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      leadsContacts: "updatedFunnelLeads/leadsContacts",
      currentLang: "currentLang",
    }),
    fieldset() {
      return {
        name: "status",
        radiobtns: this.statuses,
      };
    },

    getContact() {
      return this.leadsContacts.find(el => el.lead_id.includes(this.lead.id)) ? this.leadsContacts.find(el => el.lead_id.includes(this.lead.id)).id : null;
    },
  },

  methods: {
    changeStatus(val) {
      this.$emit("change-status", val);
    },
    withoutRepeat(arr1, arr2, index, param) {
      if (index === 0) {
        return arr2[0][param] !== arr1[param];
      }
      return (
        arr2[index][param] !== arr1[param] &&
        arr2[index][param] !== arr2[index - 1][param]
      );
    },
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
    toCart() {
      const routeUrl = this.$router.resolve({ path: `/${this.currentLang}/contacts/${this.getContact}` });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>
<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__icon {
    margin-right: 7px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
      opacity: 0.6;
    }
  }

  &__text {
    margin-left: 23px;
  }

  &_bold {
    font-weight: 600;
  }
}

.tableInfo {
  &__item {
    margin-bottom: 12px;
  }

  &__title {
    font_small();
    opacity: 0.6;
  }

  &__textItem {
    display: block;
  }

  &__empty {
    white-space: nowrap;
    font-weight: 600;
  }

  &__reasonTitle {
    display: block;
    margin-bottom: 6px;
    font_bold();
  }

  &__reason {
    display: block;
    font(main_text_color, 14px, 400, 20px);
    white-space: pre-line;
    margin-bottom: 5px;
  }
}

.leadsProfileInfoFieldset {
  margin: -10px 0 0;
}
</style>
