import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    lead(state) {
      return pathOr({}, ["data"], state);
    },
    purchasedProducts(state) {
      return pathOr([], ["data", "lead_products"], state);
    },
    nextProducts(state) {
      const nextProduct = pathOr(
        undefined,
        ["data", "next_product_object"],
        state
      );
      return nextProduct ? [nextProduct] : [];
    },
    leadPortrait(state) {
      return {
        status: pathOr("", ["data", "assignment_status_verbose"], state),
        verbose: pathOr("", ["data", "verbose_source"], state),
      };
    },
    status(state) {
      return pathOr(0, ["data", "status"], state);
    },
    lastActionAt(state) {
      return pathOr(0, ["data", "last_action_at"], state);
    },
    createdAt(state) {
      return pathOr(0, ["data", "created_at"], state);
    },
    assignmentAt(state) {
      return pathOr("", ["data", "assignment_at"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_STATUS(state, status) {
      state.data = { ...state.data, status };
    },
  },

  actions: {
    async fetch({ commit }, { id }) {
      try {
        commit("SET_PENDING", true);
        // commit("SET_DATA", []);

        const response = await Vue.axios.get(`/telephony/leads/${id}/`);

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async changeStatus({ commit }, { leadId, status, rejection_reason }) {
      // commit("CHANGE_STATUS", status);
      try {
        commit("SET_PENDING", true);
        // commit("SET_DATA", []);

        const response = await Vue.axios.patch(`/telephony/leads/${leadId}/`, {
          status,
          ...(rejection_reason ? { rejection_reason } : []),
        });

        if (response.status === 200) {
          commit("SET_DATA", {});
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
          return response.status;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
