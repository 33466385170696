import Vue from "vue";

declare type IType = "show" | "error" | "success";

export const runNotice = (type: IType, message: any) =>
  Vue.toasted[type](message, {
    duration: 10000,
    position: "bottom-right",
  });

export const runErrorNotice = message => runNotice("error", message);

const notifications = (type: IType, message: string | string[]) =>
  typeof message === "string"
    ? runNotice(type, message)
    : message.map(mes => runNotice(type, mes));

export const errorMiddlewareNotice = (
  resp: [] | string[] | { [key: string]: string }
) => {
  if (Array.isArray(resp)) {
    resp.forEach((mes: any) => {
      if (typeof mes === "object") {
        if (mes.text) {
          runErrorNotice(mes.text);
        }
      } else {
        runErrorNotice(mes);
      }
    });
  } else if (typeof resp === "object") {
    for (const prop in resp) {
      const elem: any = resp[prop];
      errorMiddlewareNotice(elem);
    }
  }
};

export default notifications;
