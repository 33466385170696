<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="title">
      {{ title }}
    </h1>
    <div
      class="layout__wrap"
      :class="[className, { layout__wrapWebinar: webinarWidth }]"
    >
      <div class="layout__header" v-if="tabs && tabs.length">
        <tab-list-router-funnel :tabs="tabs" v-if="tablistFunnel" />
        <tab-list-router :tabs="tabs" v-else />
      </div>
      <div class="layout__body">
        <slot />
        <layout-loader v-if="pending" />
      </div>
    </div>
  </main>
</template>

<script>
import TabListRouter from "@/components/TabListRouter.vue";
import TabListRouterFunnel from "@/components/TabListRouterFunnel.vue";
import LayoutLoader from "@/components/LayoutLoader.vue";

export default {
  name: "LeadershipLayout",
  props: {
    title: String,
    breadcrumbs: {
      type: Array,
      required: true,
    },
    tabs: {
      type: Array,
      required: true,
    },
    pending: {
      type: Boolean,
      required: false,
    },
    tablistFunnel: Boolean,
    className: {
      type: String,
      required: false,
    },
    webinarWidth: Boolean,
  },
  components: {
    TabListRouter,
    TabListRouterFunnel,
    LayoutLoader,
  },
};
</script>

<style lang="stylus" scoped>
.main {
  position: relative;
}

.layout__wrap {
  width: 100%;
  max-width: 692px;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;

  // overflow: hidden;
  @media (min-width: tablet_large) {
    width: 609px;
    max-width: unset;
  }

  @media (min-width: 990px) {
    width: 692px;
  }

  &Webinar {
    width: 1046px;
  }
}

.layout__body {
  position: relative;
  padding: 8px 6px;
  min-height: 380px;

  @media (min-width: 709px) {
    padding: 16px 16px 20px;
  }
}

.leadershipLangButtons__quest {
  margin-top: 24px;
}
</style>
