var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bonuses"},[(_vm.diamonds || _vm.positionIsHeader)?_c('div',{staticClass:"bonuses__item bonusesItem diamonds"},[_c('span',{class:[
        'bonusesItem__counter',
        'bonusesCounter',
        { bonusesCounter_dark: _vm.darkNums } ]},[_vm._v(" "+_vm._s(_vm.diamonds)+" ")]),_vm._m(0),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.diamondsTootip, trigger: 'click' }),expression:"{ content: diamondsTootip, trigger: 'click' }"}],ref:"bonusesItemTooltipDiamonds",staticClass:"\n        bonusesItem__tooltip\n        bonusesItemTooltip bonusesItemTooltip_mobile\n      ",on:{"click":function($event){$event.stopPropagation();return _vm.console(_vm.diamondsTootip)}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.diamondsTootip, trigger: 'hover' }),expression:"{ content: diamondsTootip, trigger: 'hover' }"}],staticClass:"\n        bonusesItem__tooltip\n        bonusesItemTooltip bonusesItemTooltip_desktop\n      "})]):_vm._e(),(_vm.gold || _vm.positionIsHeader)?_c('div',{staticClass:"bonuses__item bonusesItem gold"},[_c('span',{class:[
        'bonusesItem__counter',
        'bonusesCounter',
        { bonusesCounter_dark: _vm.darkNums } ]},[_vm._v(" "+_vm._s(_vm.gold)+" ")]),_vm._m(1),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.goldTootip, trigger: 'click' }),expression:"{ content: goldTootip, trigger: 'click' }"}],ref:"bonusesItemTooltipGold",staticClass:"\n        bonusesItem__tooltip\n        bonusesItemTooltip bonusesItemTooltip_mobile\n      ",on:{"click":function($event){$event.stopPropagation();return _vm.console(_vm.goldTootip)}}}),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.goldTootip, trigger: 'hover' }),expression:"{ content: goldTootip, trigger: 'hover' }"}],staticClass:"\n        bonusesItem__tooltip\n        bonusesItemTooltip bonusesItemTooltip_desktop\n      "})]):_vm._e(),(false)?_c('div',{staticClass:"bonuses__item bonusesItem bonus"},[_c('span',{class:[
        'bonusesItem__counter',
        'bonusesCounter',
        { bonusesCounter_dark: _vm.darkNums } ]},[_vm._v(" "+_vm._s(_vm.bonuses)+" ")]),_vm._m(2)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bonusesIconBox"},[_c('img',{staticClass:"\n          bonusesItem__icon bonusesItem__icon_diamonds\n          bonusesIcon\n          diamondsIcon\n        ",attrs:{"alt":"бриллианты","src":require("@/assets/images/diamond@2x.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bonusesIconBox"},[_c('img',{staticClass:"bonusesItem__icon bonusesItem__icon_gold bonusesIcon goldIcon",attrs:{"alt":"золото","src":require("@/assets/images/gold@2x.png")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bonusesIconBox"},[_c('img',{staticClass:"\n          bonusesItem__icon bonusesItem__icon_bonus\n          bonusesIcon\n          bonusIcon\n        ",attrs:{"alt":"бонусы","src":require("@/assets/svg/ico_bonuses.svg")}})])}]

export { render, staticRenderFns }