var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.actionItem)?_c('v-popover',{staticClass:"updatedFunnelBodyItem__cellNotification",attrs:{"offset":"0","trigger":"hover","placement":"top"}},[_c('span',{staticClass:"updatedFunnelBodyItem__notificationTrigger tooltip-target b3"},[_c('base-icon-svg',{attrs:{"width":"16","height":"16","viewbox":"0 0 16 16"}},[(_vm.actionItem.channel === 'email')?[(_vm.actionItem.statistics && _vm.actionItem.statistics.open_count > 0)?_c('notification-email-opened',{class:[
            'updatedFunnelBodyItem__notificationIcon',
            {
              updatedFunnelBodyItem__notificationIcon_contact:
                _vm.actionItem.type === 'contact',
            } ]}):_c('notification-email',{class:[
            'updatedFunnelBodyItem__notificationIcon',
            {
              updatedFunnelBodyItem__notificationIcon_contact:
                _vm.actionItem.type === 'contact',
            } ]})]:_vm._e(),(_vm.actionItem.channel === 'sms')?_c('notification-sms',{class:[
          'updatedFunnelBodyItem__notificationIcon',
          {
            updatedFunnelBodyItem__notificationIcon_contact:
              _vm.actionItem.type === 'contact',
          } ]}):_vm._e(),(_vm.actionItem.channel === 'autocall')?_c('notification-call',{class:[
          'updatedFunnelBodyItem__notificationIcon',
          {
            updatedFunnelBodyItem__notificationIcon_contact:
              _vm.actionItem.type === 'contact',
          } ]}):_vm._e()],2)],1),_c('template',{slot:"popover"},[_c('div',{staticClass:"notificationPopover"},[(_vm.actionItem.parameters.subject)?_c('p',{staticClass:"tooltip-title"},[_vm._v(" "+_vm._s(_vm.actionItem.parameters.subject)+" ")]):_vm._e(),_c('span',{staticClass:"tooltip-time"},[_vm._v(" "+_vm._s(_vm.dateFormatted(_vm.actionItem.created_at))+" ")]),(
          _vm.actionItem.statistics &&
          _vm.actionItem.statistics.status &&
          _vm.actionItem.statistics.status === 'completed'
        )?_c('div',{staticClass:"tooltip-text"},[_c('Tr',[_vm._v("Успешный звонок")])],1):(
          _vm.actionItem.statistics &&
          _vm.actionItem.statistics.status &&
          _vm.actionItem.statistics.status !== 'completed'
        )?[(
            _vm.actionItem.statistics &&
            _vm.actionItem.statistics.open_count &&
            _vm.actionItem.statistics.open_count > 0
          )?_c('div',{staticClass:"tooltip-text"},[_vm._v(" Opened ")]):_c('div',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.actionItem.statistics.status)+" ")])]:_vm._e(),(_vm.actionItem.statistics && _vm.actionItem.statistics.duration)?_c('div',{staticClass:"tooltip-text"},[_c('Tr',[_vm._v("Продолжительность:")]),_vm._v(" "+_vm._s(_vm.time)+" ")],1):_vm._e(),(_vm.actionItem.parameters.message)?_c('p',{staticClass:"tooltip-text",domProps:{"innerHTML":_vm._s(_vm.actionItem.parameters.message)}}):_vm._e(),(_vm.actionItem.parameters.number)?_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.actionItem.parameters.number)+" ")]):_vm._e()],2)])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }