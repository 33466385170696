<template>
  <div class="submenu">
    <button class="submenu__buttonArrow" />
    <div class="submenu__counters">
      <HeaderInfo
        :expired="true"
        :param1="'На неделю'"
        :param2="'Просрочено'"
        :title="'Задачи'"
        :value1="stats.navbar_week_tasks"
        :value2="stats.navbar_task_overdue_count"
        :mobile="true"
        name="tasks"
      />
      <HeaderInfo
        v-if="showTeam"
        :expired="false"
        :param1="'В первой линии'"
        :param2="'Всего'"
        :title="'Команда'"
        :value1="headerInfoValue1"
        :value2="headerInfoValue2"
        :mobile="true"
        name="team"
      />
      <header-info-ref
        v-if="userRef && showRef"
        :userRef="userRef"
        :mobile="true"
      />
    </div>
    <div class="submenu__upper">
      <div class="submenu__user">
        <h2 class="submenu__userTitle">{{ name }}</h2>
        <div
          class="submenu__userTarif"
          v-if="subscriptionType && subscriptionStatuses[subscriptionType]"
        >
          <Tr class="submenu__userTarifTitle">Тариф:</Tr>
          <strong>
            <Tr>{{ subscriptionStatuses[subscriptionType] }}</Tr>
          </strong>
        </div>
        <div class="submenu__userTarif" v-if="!hideRank">
          <Tr>Ваш ранг:</Tr>
          <strong>
            <Tr v-if="userStatus === 'Empty' || !userStatus">Нет ранга</Tr>
            <Tr v-else>{{ userStatus }}</Tr>
          </strong>
        </div>
      </div>
      <div class="submenu__profileWrap">
        <a
          class="submenu__button submenu__link"
          :href="outHref + '/profile/edit'"
        >
          <Tr>Профиль</Tr>
        </a>
        <a
          class="submenu__button submenu__link"
          :href="outHref + '/statistics/'"
          v-if="company === 'bitbon'"
        >
          <Tr>Награды</Tr>
        </a>
        <template v-if="false">
          <a class="submenu__button" :href="outHref + '/profile/tariffs/'">
            <Tr>Тарифы</Tr>
          </a>
        </template>
      </div>
      <span class="submenu__button" @click="logout">
        <Tr>Выйти</Tr>
      </span>
    </div>
    <SidebarSubmenuLangs />
  </div>
</template>

<script>
import SidebarSubmenuLangs from "@/components/Sidebar/SidebarSubmenuLangs.vue";
import HeaderInfo from "@/components/Header/HeaderInfo.vue";
import HeaderInfoRef from "@/components/Header/HeaderInfoRef.vue";
import { mapGetters } from "vuex";

export default {
  name: "SubMenu",
  components: {
    SidebarSubmenuLangs,
    HeaderInfo,
    HeaderInfoRef,
  },
  props: {
    company: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      submenuOff: true,
      subscriptionStatuses: {
        // free: "Бесплатный тариф",
        // year: "Годовая подписка",
        // payed: "Годовая подписка",
        free: "User",
        year: "User",
        payed: "User",
      },
    };
  },
  computed: {
    ...mapGetters({
      name: "user/name",
      hasSubscription: "user/hasSubscription",
      subscriptionType: "user/subscription",
      userStatus: "user/userStatus",
      stats: "user/stats",
      showTeam: "user/showTeam",
      userRef: "user/userRefLink",
      showRef: "user/showRef",
    }),
    outHref() {
      return this.$store.getters["user/mainSiteLink"];
    },
    hideRank() {
      return this.company === "tasq" || this.company === "oton";
    },
    headerInfoValue1() {
      return `${this.stats.navbar_team_first_line} / ${this.stats.navbar_team_first_line_paid_count}`;
    },
    headerInfoValue2() {
      return `${this.stats.navbar_team_count} / ${this.stats.navbar_team_paid_count} `;
    },
  },
  methods: {
    logout() {
      const url = `${this.$store.getters["user/mainSiteLink"]}/logout/`;
      location.replace(url);
    },
  },
};
</script>

<style lang="stylus" scoped>
.submenu {
  position: absolute;
  top: 44px;
  right: -8px;
  padding: 12px;
  width: 240px;
  height: calc(100vh - 94px);
  background-color: #fff;
  z-index: 400;

  @media (min-width: desktop) {
    top: 65px;
    right: 20px;
    padding: 12px;
    margin-left: 0;
    width: 220px;
    height: auto;
    border-radius: 4px;
    box-shadow: 0px 0px 24px #0F164214;
  }
}

.submenu__upper {
  margin-bottom: 30px;
}

.submenu__counters {
  @media (min-width: desktop) {
    display: none;
  }
}

.languages {
  margin-bottom: 12px;

  @media (min-width: desktop) {
    position: static;
  }
}

.submenu__buttonArrow {
  display: none;
  position: absolute;
  content: '';
  // top: -37px;
  // left: -3px;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H24V24H0Z'/%3E%3Cpath class='b' d='M7.828,11H20v2H7.828l5.364,5.364-1.414,1.414L4,12l7.778-7.778,1.414,1.414Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  top: -2px;
  right: 4px;
  // left: unset;
  transform: rotate(180deg);
}

.submenu__user {
  padding-bottom: 9px;
  border-bottom: 2px solid #e6e7eb;
}

.submenu__profileWrap {
  padding-bottom: 9px;
  border-bottom: 2px solid #e6e7eb;
}

.submenu__userTitle {
  color: #0f1642;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.submenu__userTarif {
  color: #0f1642;
  // font-size: 10px;
  // line-height: 13px;
  font-weight: 400;
  display: block;
  font-size: 12px;
  line-height: 15px;

  strong {
    font-weight: bold;
  }
}

.submenu__userTarifTitle {
  // margin-right: 5px;
}

.submenu__button {
  display: block;
  margin-top: 9px;
  padding: 0;
  background: unset;
  border: unset;
  color: #0f1642;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
}

.submenu__button:hover {
  text-decoration: underline;
}

.submenu__button + .submenu__button {
  margin-top: 14px;
}

.submenu__button {
  text-decoration: none;
}
</style>
