import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    tags(state) {
      return state.data;
    },
    tagsArray(state) {
      return state.data.data;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/tags`, {
          baseURL: process.env.VUE_APP_FUNNEL_URL,
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
