<template>
  <div>
    <div class="loaderBox">
      <div class="loaderIcon" v-show="pending">
        <loader />
      </div>
      <no-content
        v-if="noTasks || !hasSubscription"
        :noContent="noContentEmpty"
      />

      <template v-if="tasks.length && tasks.length > 0 && hasSubscription">
        <div class="tasksWrap">
          <tasks-item
            v-for="task in tasks"
            :key="task.id"
            :task="task"
            :contacts="contacts"
            :activeTaskTab="activeTaskTab"
          />
        </div>
        <button
          class="showmoreButton"
          type="button"
          @click="fetchMore"
          v-if="hasNextPage"
        >
          <Tr>Показать больше</Tr>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import NoContent from "@/components/NoContent.vue";
import Loader from "@/components/Loader.vue";
import TasksItem from "@/components/Tasks/TasksItem.vue";

export default {
  name: "TasksLayout",
  components: {
    NoContent,
    Loader,
    TasksItem,
  },
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    activeTaskTab: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      contacts: "leadTasks/contacts",
      hasNextPage: "leadTasks/hasNextPage",
      currentPage: "leadTasks/currentPage",
      pending: "leadTasks/pending",
      hasSubscription: "user/hasSubscription",
    }),

    noTasks() {
      return !this.pending && this.tasks.length === 0;
    },

    noContentEmpty() {
      return {
        noFunnels: false,
        text: "Пусто. У вас еще нет ни одной задачи",
        maxWidth: 0,
      };
    },
  },
  methods: {
    fetchMore() {
      this.$store.dispatch("leadTasks/fetchMore", {
        page: this.currentPage + 1,
        filter: this.activeTaskTab ? this.activeTaskTab : "all",
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.tasksWrap {
  display: grid;
  grid-row-gap: 20px;
}

.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();

  @media (min-width: tablet_large) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}
</style>
