<template>
  <tasks-container />
</template>

<script>
import TasksContainer from "@/containers/TasksContainer.vue";

export default {
  name: "Lessons",
  components: {
    TasksContainer,
  },
};
</script>
