<template>
  <updated-lead-container>
    <router-view></router-view>
  </updated-lead-container>
</template>

<script>
import UpdatedLeadContainer from "@/containers/Leads/UpdatedLeadContainer.vue";

export default {
  name: "UpdatedLeadPage",
  components: {
    UpdatedLeadContainer,
  },
};
</script>

<style lang="stylus" scoped></style>
