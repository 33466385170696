import Task from "@/models/task";
import { getLang } from "@/utils/cookies/getLang";
import supportedLangs from "@/utils/supportedLangs";
import { isEmpty, pathOr } from "ramda";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
    activeLang: getLang(),
    isNew: false,
    type: "video",
  }),
  getters: {
    errors(state) {
      return state.errors;
    },
    activeLang(state) {
      return state.activeLang;
    },
    isLangValid: state => lang => {
      if (!state.data.locales) {
        return false;
      }
      return Boolean(state.data.locales[lang].title);
    },
    langTabs(state, getters) {
      return supportedLangs().map(lang => ({
        title: lang,
        isValid: getters.isLangValid(lang),
      }));
    },
    title(state) {
      return pathOr("", ["data", "locales", state.activeLang, "title"], state);
    },
    taskType(state) {
      return pathOr("", ["type"], state);
    },
    rewardGold(state) {
      return pathOr("", ["data", "reward_gold"], state);
    },
    rewardExpire(state) {
      return pathOr("", ["data", "reward_expire_gold"], state);
    },
    rewardBrilliant(state) {
      return pathOr("", ["data", "approve_reward_brilliant"], state);
    },
    rewardBrilliantExpire(state) {
      return pathOr("", ["data", "approve_reward_expire_brilliant"], state);
    },
    isNew(state) {
      return state.isNew;
    },
    taskPosition(state) {
      return pathOr("", ["data", "position"], state);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    CLEAR_ERRORS(state) {
      state.errors = {};
    },
    CHANGE_DESCRIPTION(state, value) {
      state.data.locales[state.activeLang].title = value;
    },
    CHANGE_TASK_TYPE(state, value) {
      state.type = value;
    },
    CHANGE_REWARD(state, { name, value }) {
      state.data[name] = value;
    },
    DROP_ERRORS(state) {
      state.errors = {};
    },
    SET_NEW_TASK(state, value) {
      state.isNew = value;
    },
  },
  actions: {
    setErrors({ commit }, errors) {
      commit("SET_ERRORS", errors);
    },

    dropErrors({ commit }) {
      commit("DROP_ERRORS");
    },

    changeTaskType({ commit }, value) {
      commit("CHANGE_TASK_TYPE", value);
    },

    initTask({ rootState, dispatch, commit }, { taskId, type }) {
      commit("SET_NEW_TASK", false);
      const initTypes = {
        poll: () =>
          dispatch("leadershipTaskPoll/fetch", taskId, { root: true }),
        action: () =>
          dispatch("leadershipTaskAction/fetch", taskId, {
            root: true,
          }),
        upload: () =>
          dispatch("leadershipTaskUpload/fetch", taskId, {
            root: true,
          }),
        video: () =>
          dispatch("leadershipTaskVideo/fetch", taskId, { root: true }),
      };

      const stores = {
        poll: "leadershipTaskPoll",
        action: "leadershipTaskAction",
        upload: "leadershipTaskUpload",
        video: "leadershipTaskVideo",
      };

      Promise.all([initTypes[type]()]).then(() => {
        commit("SET_DATA", rootState[stores[type]].data);
      });
    },

    initNew({ commit, dispatch }) {
      const data = new Task();
      commit("SET_DATA", data);
      commit("SET_NEW_TASK", true);
      dispatch("leadershipTaskPoll/initNew", undefined, { root: true });
      dispatch("leadershipTaskAction/initNew", undefined, { root: true });
      dispatch("leadershipTaskUpload/initNew", undefined, { root: true });
      dispatch("leadershipTaskVideo/initNew", undefined, { root: true });
    },

    init({ commit }, data) {
      const generalData = new Task(data);
      commit("SET_DATA", generalData);
    },

    async save({ state, dispatch }, taskType) {
      const type = taskType ? taskType : state.type;

      if (type === "poll") {
        dispatch("leadershipTaskPoll/saveTest", state.data, { root: true });
      } else if (type === "upload") {
        dispatch("leadershipTaskUpload/saveUpload", state.data, {
          root: true,
        });
      } else if (type === "action") {
        dispatch("leadershipTaskAction/saveAction", state.data, {
          root: true,
        });
      } else if (type === "video") {
        dispatch("leadershipTaskVideo/saveVideo", state.data, {
          root: true,
        });
      }
    },
  },
};
