import secondsToTime from "@/utils/videoEditor/secondsToTime";
import Player from "@vimeo/player";

export default {
  namespaced: true,

  state: () => ({
    player: {},
    errors: {},
    pending: false,
    currentTime: 0,
    duration: 0,
    playing: false,
  }),

  getters: {
    currentTime(state) {
      return state.currentTime;
    },
    duration(state) {
      return state.duration;
    },
    playing(state) {
      return state.playing;
    },
  },

  mutations: {
    INIT(state, { width, id, duration }) {
      state.player = new Player("uploadedVideo", {
        id,
        width,
      });
      state.duration = duration;
      state.player.on(
        "timeupdate",
        ({ seconds }) => (state.currentTime = seconds)
      );
      state.player.on("play", () => (state.playing = true));
      state.player.on("pause", () => (state.playing = false));
    },
    UPDATE_CURRENT_TIME(state, value) {
      state.currentTime = value;
    },
    PLAY_VIDEO(state) {
      state.player.play();
    },
    PAUSE_VIDEO(state) {
      state.player.pause();
    },
  },

  actions: {
    init({ state, commit }, { width, id, duration }) {
      commit("INIT", { width, id, duration });
    },

    updateCurrentTime({ state, commit }, value) {
      commit("UPDATE_CURRENT_TIME", value);

      state.player.setCurrentTime(value).catch(e => console.log(e));
    },
  },
};
