<template>
  <modal
    name="leads-modal-product"
    id="leads-modal-product"
    width="100%"
    height="auto"
    :max-width="640"
    :adaptive="true"
    @before-open="beforeOpen"
    :shiftY="0"
  >
    <div class="leadsModalProduct">
      <!-- HEADER -->
      <div class="leadsModalProduct__header">
        <div class="leadsModalProduct__headerInner">
          <div class="leadsModalProduct__headerName">
            {{ product.name }}
          </div>
          <button
            class="leadsModalProduct__headerButton"
            type="button"
            @click="hideLeadsModalProduct"
          >
            <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
              <close-button />
            </base-icon-svg>
          </button>
        </div>

        <div class="leadsModalProduct__headerLink">
          <Tr class="leadsModalProduct__headerLinkTitle">Ссылка</Tr>
          <a
            class="leadsModalProduct__headerLinkItem"
            :href="product.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ product.link }}
          </a>
        </div>

        <div class="leadsModalProduct__headerInfo">
          <div class="leadsModalProduct__headerInfoBox">
            <Tr class="leadsModalProduct__headerInfoTitle">Продавец</Tr>
            <span class="leadsModalProduct__headerInfoName">
              {{ product.seller }}
            </span>
          </div>
          <div class="leadsModalProduct__headerInfoBox">
            <Tr class="leadsModalProduct__headerInfoTitle">Цена</Tr>
            <span class="leadsModalProduct__headerInfoPrice">
              {{ product.price }} €
            </span>
          </div>
        </div>

        <tab-list
          :tabs="tabs"
          :activeTab="activeTab"
          :mobileBtns="true"
          @handle-main-tabs="handleTabs"
          class="leadsModalProduct__tabs"
        />
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="leadsModalProduct__body">
        {{ activeTabText }}
      </div>
      <!-- BODY -->
    </div>
  </modal>
</template>

<script>
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import TabList from "@/components/TabList.vue";
export default {
  name: "LeadsModalProduct",
  components: {
    CloseButton,
    TabList,
  },
  data() {
    return {
      activeTab: "",
      product: {},
    };
  },
  props: {},
  computed: {
    tabs() {
      if (this.product.texts) {
        return this.product.texts.map((tab) => ({
          ...tab,
          name: tab.tab_name,
          title: tab.tab_name,
        }));
      }
      return [];
    },
    activeTabText() {
      const tab = this.tabs.filter((tab) => tab.name === this.activeTab);
      if (tab && tab[0]) {
        return tab[0].text;
      }
      return "";
    },
  },
  methods: {
    hideLeadsModalProduct() {
      this.$modal.hide("leads-modal-product");
    },
    handleTabs(tabName) {
      this.activeTab = tabName;
    },
    setInitialTab(product) {
      if (product.texts && product.texts.length) {
        this.handleTabs(product.texts[0].tab_name);
      }
    },
    beforeOpen(e) {
      this.product = e.params;
      this.setInitialTab(e.params);
    },
  },
};
</script>

<style lang="stylus" scoped>
.leads-modal-product {
  top: 0 !important;

  @media (min-width: tablet) {
    top: 160px !important;
  }
}

.leadsModalProduct {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  background-color: #fff;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: tablet) {
    padding: 16px 16px 21px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    margin-right: -8px;
    padding-bottom: 7px;
    margin-left: -8px;
    padding-right: 8px;
    padding-left: 8px;

    @media (min-width: tablet) {
      padding-bottom: 16px;
      align-items: unset;
    }
  }

  &__headerInner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    @media (min-width: tablet) {
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    width: 92%;
    font(main_text_color, 16px, 700, 20px);
    letter-spacing: -0.2px;

    @media (min-width: tablet) {
      font_subtitle();
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    left: -3px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 16px;
    font(main_text_color, 14px, 400, 20px);
    white-space: pre-line;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-bottom: 0;
      align-items: unset;
      border-bottom: unset;
    }
  }
}

.leadsModalProduct__headerLink {
  margin-bottom: 8px;
}

.leadsModalProduct__headerLinkTitle {
  display: block;
  font(main_text_color, 12px, 400, 16px);
}

.leadsModalProduct__headerLinkItem {
  display: block;
  font_regular();
  color: secondary_text_color;
  word-break: break-word;
}

.leadsModalProduct__headerInfo {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  margin-bottom: 12px;
  width: fit-content;
}

.leadsModalProduct__headerInfoBox {
}

.leadsModalProduct__headerInfoTitle {
  display: block;
  font(main_text_color, 12px, 400, 14px);
}

.leadsModalProduct__headerInfoName {
  display: block;
  font_regular();
}

.leadsModalProduct__headerInfoPrice {
  display: block;
  font_bold();
}
</style>
