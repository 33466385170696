<template>
  <div class="autowebinar__body">
    <!-- ФОРМА ВЕБИНАРА -->
    <form @submit.prevent class="autowebinar__form">
      <leadership-input
        :maxLength="NAME_MAX_LENGTH"
        :value="webinarName"
        :title="$t('Название автовебинара')"
        class="autowebinar__fieldsetText"
        @handle-name="changeName"
      />

      <autowebinars-langs :value="webinarLang" @change-lang="changeLang" />

      <fieldset>
        <legend class="autowebinar__legend">
          <Tr>Статус</Tr>
          <base-checkbox
            :title="'Опубликован'"
            :checked="!!webinarStatus"
            @change-checkbox="changeStatus"
          />
        </legend>
      </fieldset>
      <div class="autowebinar__buttonsWrap">
        <button class="autowebinar__button" @click="save">
          <Tr>Сохранить</Tr>
        </button>
        <button
          class="autowebinar__button autowebinar__button_del"
          @click="deleteWebinar"
          v-if="!isNew"
        >
          <base-icon-svg class="autowebinar__buttonSvg">
            <editing-delete />
          </base-icon-svg>
          <Tr>Удалить</Tr>
        </button>
      </div>
    </form>
    <!-- КОНЕЦ ФОРМЫ ВЕБИНАРА -->
  </div>
</template>

<script>
import LeadershipInput from "@/components/Leadership/LeadershipInput.vue";
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";
import AutowebinarsLangs from "@/components/Autowebinars/AutowebinarsLangs.vue";

export default {
  name: "AutowebinarsDescriptionForm",
  components: {
    LeadershipInput,
    EditingDelete,
    AutowebinarsLangs,
  },
  props: {
    webinarName: String,
    webinarStatus: Boolean,
    webinarLang: String,
    isNew: Boolean,
  },
  data() {
    return {
      NAME_MAX_LENGTH: 64,
    };
  },
  methods: {
    changeName(value) {
      this.$emit("change-name", value);
    },
    changeLang(value) {
      this.$emit("change-lang", value);
    },
    changeStatus(e) {
      this.$emit("change-status", !this.webinarStatus);
    },
    save(e) {
      this.$emit("save");
    },
    deleteWebinar() {
      this.$emit("deleteWebinar");
    },
  },
};
</script>

<style lang="stylus" scoped>
.titleAutowebinar {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-wrap: wrap;
}

.tabList {
  margin-right: -16px;
  margin-left: -16px;
  border-bottom: 4px solid grey_text_color;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  display: inline-block;
  padding: 4px 16px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);
}

.tabButton_active {
  position: relative;
}

.tabButton_active::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff9f43;
}

.autowebinar__wrap {
  padding: 8px 16px 20px;
  min-width: 692px;
  width: fit-content;
  border-top: 3px solid transparent;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;
}

.autowebinar__body {
  position: relative;
  padding-top: 16px;
}

.autowebinar__legend {
  font(#0F1642, 12px, 400, 15px);
  letter-spacing: -0.1px;
}

.autowebinar__fieldsetText {
  margin-bottom: 20px;
}

.autowebinar__fieldsetRadio {
  margin-bottom: 20px;
}

.autowebinar__label {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 25px;
  font_regular();
  cursor: pointer;
}

.autowebinar__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.autowebinar__radio {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
  border-radius: 50%;
}

.autowebinar__input:checked + .autowebinar__radio:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background-color: main_text_color;
  border-radius: 50%;
}

.autowebinar__checkbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
}

.autowebinar__input:checked + .autowebinar__checkbox:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background-color: main_text_color;
}

.autowebinar__button {
  display: flex;
  margin-top: 18px;
  padding: 11px 12px;
  background-color: #e6e7eb;
  border: none;
  border-radius: 4px;
  font_regular();

  &:hover {
    background-color: #d9dadc;
  }

  &_del {
    & span {
      color: #e81526;
    }
  }

  &sWrap {
    display: flex;
    justify-content: space-between;
    margin-top: 71px;
  }

  &Svg {
    margin-right: 9px;
    fill: #e81526;
  }
}
</style>