<template>
  <div class="leadsCreateLink">
    <div class="leadsCreateLink__box">
      <!-- name -->
      <label for="leadsCreateLink__input" class="leadsCreateLink__label">
        <Tr>Название ссылки</Tr>
      </label>
      <input
        id="leadsCreateLink__input"
        class="leadsCreateLink__input"
        type="text"
        :value="name"
        @input="changeName"
        required
      />
      <!-- name -->

      <!-- product -->
      <div class="leadsCreateLink__select">
        <label class="leadsCreateLink__label" for="leadsCreateLinkProduct">
          <Tr>Продукт</Tr>
        </label>
        <select
          class="leadsCreateLink__selectItem"
          name="leadsCreateLinkProduct"
          id="leadsCreateLinkProduct"
          @change="changeProduct"
          required
        >
          <option v-if="!productId">
            <Tr>Выберите воронку</Tr>
          </option>
          <template v-if="productsWithWebinar">
            <option
              v-for="(product, index) in productsWithWebinar"
              :value="product.id"
              :key="`${index}-${product.id}`"
            >
              {{ product.name }}
            </option>
          </template>
        </select>
        <div class="inputWrap__error error">
          <div
            :key="error"
            class="error__item"
            v-for="error in errors.product_id"
          >
            {{ error }}
          </div>
        </div>
      </div>

      <!-- video -->
      <div class="leadsCreateLink__select">
        <label class="leadsCreateLink__label" for="leadsCreateLinkVideo">
          <Tr>Видео</Tr>
        </label>
        <select
          class="leadsCreateLink__selectItem"
          name="leadsCreateLinkFunnel"
          id="leadsCreateLinkVideo"
          @change="changeVideo"
          required
        >
          <option v-if="!videoId"><Tr>Выберите видео</Tr></option>
          <template v-if="videos">
            <option
              v-for="(video, index) in videos"
              :value="video.id"
              :key="`${index}-${video.id}`"
            >
              {{ video.name }}
            </option>
          </template>
        </select>
        <div class="inputWrap__error error">
          <div
            :key="error"
            class="error__item"
            v-for="error in errors.video_id"
          >
            {{ error }}
          </div>
        </div>
      </div>

      <!-- webinar start -->
      <label class="leadsCreateLink__label">
        <Tr>Начало вебинара</Tr>
      </label>
      <div class="datePickerBox">
        <date-picker
          type="datetime"
          format="DD MMM YYYY, HH:mm"
          :lang="['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale"
          v-model="startedAt"
          :default-value="new Date().setHours(10, 0, 0, 0)"
          :disabled-date="notBeforeToday"
        />
      </div>
      <div class="inputWrap__error error">
        <div
          class="error__item"
          v-for="error in errors.started_at"
          :key="error"
        >
          {{ error }}
        </div>
      </div>
      <!-- webinar start -->
    </div>

    <button class="leadsCreateLink__submit" @click="create">
      <Tr>Создать</Tr>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";

export default {
  name: "UpdatedLeadsCreateLinkToWebinar",
  components: {
    DatePicker,
  },
  data() {
    return {};
  },
  props: {
    lead: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      linkCreated: "updatedLeadsCreateLinkToWebinar/linkCreated",
      errors: "updatedLeadsCreateLinkToWebinar/errors",
      products: "updatedLeadsCreateLinkToWebinar/products",
      videos: "updatedLeadsCreateLinkToWebinar/videos",
      videoId: "updatedLeadsCreateLinkToWebinar/videoId",
      productId: "updatedLeadsCreateLinkToWebinar/productId",
      name: "updatedLeadsCreateLinkToWebinar/name",
    }),

    linkName() {
      return `${this.$t("Автоссылка для лида ")}${this.lead.trigger.name}`;
    },

    startedAt: {
      get() {
        return this.$store.getters["updatedLeadsCreateLinkToWebinar/startedAt"];
      },

      set(value) {
        this.$store.commit(
          "updatedLeadsCreateLinkToWebinar/CHANGE_STARTED_AT",
          value
        );
      },
    },

    productsWithWebinar() {
      return this.products.filter(product => product.videos.length > 0);
    },
  },

  methods: {
    create() {
      this.$store.commit(
        "updatedLeadsCreateLinkToWebinar/CHANGE_NAME",
        this.name
      );

      this.$store.commit("leadsCreateLink/CHANGE_VIDEO_ID", this.videoId);

      this.$store.dispatch(
        "updatedLeadsCreateLinkToWebinar/createLink",
        this.lead.id
      );
    },
    changeName(e) {
      this.$store.commit(
        "updatedLeadsCreateLinkToWebinar/CHANGE_NAME",
        e.target.value
      );
    },

    changeProduct(e) {
      this.$store.commit(
        "updatedLeadsCreateLinkToWebinar/CHANGE_PRODUCT",
        e.target.value
      );
    },

    changeVideo(e) {
      this.$store.commit(
        "updatedLeadsCreateLinkToWebinar/CHANGE_VIDEO",
        e.target.value
      );
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  mounted() {
    this.$store.commit(
      "updatedLeadsCreateLinkToWebinar/CHANGE_NAME",
      this.linkName
    );
    Promise.all([this.$store.dispatch("updatedLeadsCreateLinkToWebinar/fetchProducts")])
      .then(() => {
        this.$store.commit(
          "updatedLeadsCreateLinkToWebinar/CHANGE_PRODUCT",
          this.productsWithWebinar[0].id
        );
      })
      .then(() => {
        this.$store.commit(
          "updatedLeadsCreateLinkToWebinar/CHANGE_VIDEO",
          this.videos[0].id
        );
      })
      .catch(e => {
        console.log(e);
      });
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLink {
  padding: 0 0 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;

  &__box {
    margin: 0 -12px 20px;
    padding: 0 12px;
    border-bottom: 2px solid grey_text_color;

    @media (min-width: desktop) {
      margin: 0 -16px 20px;
      padding: 0 16px;
    }
  }

  &__label {
    display: inline-block;
    margin-bottom: 4px;
    font_small();
  }

  &__input {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();
  }

  &__selectItem {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();
  }

  &__submit {
    padding: 11px 12px;
    width: fit-content;
    font_regular();
    color: #fff;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    border: none;
    border-radius: 4px;

    &:hover {
      opacity: 0.6;
    }
  }
}

@media (min-width: desktop) {
  .leadsCreateLink {
    padding: 0;

    &__input {
      width: 400px;
    }

    &__selectItem {
      width: 400px;
    }
  }
}

.error {
  &__item {
    color: danger_text_color;
    font-size: 14px;
  }
}

.datePickerBox {
  display: block;
  margin-bottom: 12px;
}

.inputWrap__error {
  position: relative;
  top: -5px;
}

.hiddenInput {
  display: block;
}
</style>
