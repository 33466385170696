<template>
  <main class="main">
    <leadership-layout
      :title="webinarName"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
    >
      <autowebinars-description-form
        :webinarName="webinarName"
        :webinarLang="webinarLang"
        :webinarStatus="webinarStatus"
        :isNew="isNew"
        @change-name="changeName"
        @change-lang="changeLang"
        @change-status="changeStatus"
        @save="save"
      />
    </leadership-layout>
  </main>
</template>

<script>
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import AutowebinarsDescriptionForm from "@/components/Autowebinars/AutowebinarsDescriptionForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "AutowebinarContainer",
  components: {
    LeadershipLayout,
    AutowebinarsDescriptionForm,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        { title: "Автовебинары", link: "/leadership/autowebinars" },
      ],
      noContent: { noContacts: true, text: "Загрузите слайды и видео" },
    };
  },
  computed: {
    ...mapGetters({
      pending: "autowebinar/pending",
      webinarName: "autowebinar/name",
      webinarLang: "autowebinar/lang",
      webinarStatus: "autowebinar/isPublish",
      isNew: "autowebinar/isNew",
    }),
    tabs() {
      return [
        {
          title: "Описание",
          link: `/leadership/autowebinars/new`,
          exact: true,
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("autowebinar/initNew");
  },
  methods: {
    changeName(value) {
      this.$store.commit("autowebinar/CHANGE_NAME", value);
    },
    changeLang(value) {
      this.$store.commit("autowebinar/CHANGE_LANG", value);
    },
    changeStatus(value) {
      this.$store.commit("autowebinar/CHANGE_STATUS", value);
    },
    save() {
      this.$store.dispatch("autowebinar/create");
    },
  },
};
</script>
