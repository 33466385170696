import { pathOr } from "ramda";
import Vue from "vue";
import qs from "qs";

function changeResponseString(str, filter) {
  const arr1 = str.split("&");
  let findedEl = arr1.find(el => el.match(`${filter}=`));
  const arr2 = arr1.filter(el => el !== findedEl);
  const result1 = arr2.join("&");

  findedEl = findedEl.replace(`${filter}=`, "");

  const arr3 = findedEl.split("%2C");

  let result2 = arr3.join(`&${filter}=`);
  result2 = `&${filter}=` + result2;
  const result3 = result1 + result2;

  return result3;
}

export default {
  namespaced: true,

  state: () => ({
    data: {},
    contactHeatArray: [],
    contactTopArray: [],
    contacts: [],
    errors: {},
    pendingContacts: false,
    pendingHeat: false,
    pendingTop: false,
    filters: {},
    tagsFilterValue: "",
    tagsFilterCounter: null,
    isPickedAllTags: false,
    deletedContactsCounter: 0,
  }),

  getters: {
    pending(state) {
      return state.pendingHeat || state.pendingContacts || state.pendingTop;
    },

    pendingHeat(state) {
      return state.pendingHeat;
    },

    contacts(state) {
      return state.contacts;
    },

    pickedAllTags(state) {
      return state.isPickedAllTags;
    },
    contactHeatArray(state) {
      return state.contactHeatArray;
    },

    contactTopArray(state) {
      return state.contactTopArray;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },

    amountPages(state) {
      return pathOr(0, ["data", "num_pages"], state);
    },

    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },

    filters(state) {
      return state.filters;
    },

    tagsFilterValue(state) {
      return state.tagsFilterValue;
    },

    tagsFilterCounter(state) {
      return state.tagsFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.contacts = [...state.data.results];
    },

    SET_MORE_DATA(state, data) {
      state.data = data;
      state.contacts = [...state.contacts, ...state.data.results];
    },

    SET_PAGES_DATA(state, data) {
      state.data = data;
      state.contacts = [...state.data.results];
    },

    SET_CONTACT_HEAT(state, data) {
      state.contactHeatArray = data;
    },

    SET_CONTACT_TOP(state, data) {
      state.contactTopArray = data;
    },

    SET_PENDING_CONTACTS(state, status) {
      state.pendingContacts = status;
    },

    SET_PENDING_HEAT(state, status) {
      state.pendingHeat = status;
    },

    SET_PENDING_TOP(state, status) {
      state.pendingTop = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    CHANGE_FILTER(state, { name, value }) {
      if (name === "tags") {
        if (state.filters[name]) {
          if (state.filters[name].includes(Number(value))) {
            state.filters = {
              ...state.filters,
              [name]: state.filters[name].filter(el => el !== Number(value)),
            };
            state.isPickedAllTags = false;
          } else {
            state.filters = {
              ...state.filters,
              [name]: [...state.filters[name], Number(value)],
            };
          }
        } else {
          state.filters = {
            ...state.filters,
            [name]: [Number(value)],
          };
        }
      } else if (name === "progress") {
        state.filters = {
          ...state.filters,
          [name]: [Number(value)],
        };
      } else if (name === "country" || name === "city") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },

    CLEAR_FILTERS(state) {
      state.filters = {};
      state.tagsFilterValue = "";
      state.tagsFilterCounter = null;
      state.isPickedAllTags = false;
    },

    SET_PICKED_ALL_TAGS(state, value) {
      state.isPickedAllTags = !state.isPickedAllTags;

      if (value.length !== state?.filters?.tags?.length) {
        state.isPickedAllTags = true;
      }

      state.isPickedAllTags
        ? (state.filters.tags = [...value])
        : (state.filters.tags = []);
    },

    SET_TAGS_FILTER_VALUE(state, value) {
      state.tagsFilterValue = value;
    },

    SET_TAGS_FILTER_COUNTER(state, value) {
      state.tagsFilterCounter = Number(value);
    },

    SET_DELETED_CONTACTS_COUNTER(state) {
      state.deletedContactsCounter++;
    },

    CLEAR_DELETED_CONTACTS_COUNTER(state) {
      state.deletedContactsCounter = 0;
    },
  },

  actions: {
    async fetch({ state, commit }, page = 1) {
      let filters = qs.stringify(
        { ...state.filters },
        { arrayFormat: "comma" }
      );

      if (state.filters.tags && state.filters.tags.length > 1) {
        filters = changeResponseString(filters, "tags");
      }

      try {
        commit("SET_PENDING_CONTACTS", true);

        const response = await Vue.axios.get(`/contacts/?${filters}`, {
          params: { page },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING_CONTACTS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_CONTACTS", false);
      }
    },

    async fetchMore({ state, commit }, page = 1) {
      let filters = qs.stringify(
        { ...state.filters },
        { arrayFormat: "comma" }
      );

      if (state.filters.tags && state.filters.tags.length > 1) {
        filters = changeResponseString(filters, "tags");
      }

      try {
        commit("SET_PENDING_CONTACTS", true);

        const response = await Vue.axios.get(`/contacts/?${filters}`, {
          params: { page: page },
        });

        if (response.status === 200) {
          commit("SET_MORE_DATA", response.data);
          commit("SET_PENDING_CONTACTS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_CONTACTS", false);
      }
    },

    async fetchPage({ state, commit }, page = 1) {
      let filters = qs.stringify(
        { ...state.filters },
        { arrayFormat: "comma" }
      );

      if (state.filters.tags && state.filters.tags.length > 1) {
        filters = changeResponseString(filters, "tags");
      }

      try {
        commit("SET_PENDING_CONTACTS", true);

        const response = await Vue.axios.get(`/contacts/?${filters}`, {
          params: { page: page },
        });

        if (response.status === 200) {
          commit("SET_PAGES_DATA", response.data);
          commit("SET_PENDING_CONTACTS", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_CONTACTS", false);
      }
    },

    async fetchContactHeat({ commit }) {
      try {
        commit("SET_PENDING_HEAT", true);

        const response = await Vue.axios.get(`/contacts_heat/`);

        if (response.status === 200) {
          commit("SET_CONTACT_HEAT", response.data);
          commit("SET_PENDING_HEAT", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_HEAT", false);
      }
    },

    async fetchContactTop({ commit }) {
      try {
        commit("SET_PENDING_TOP", true);

        const response = await Vue.axios.get(`/contacts_top/`);

        if (response.status === 200) {
          commit("SET_CONTACT_TOP", response.data);
          commit("SET_PENDING_TOP", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_TOP", false);
      }
    },

    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },

    pickAllTags({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_TAGS", payload);
        resolve();
      });
    },
  },
};
