<template>
  <autowebinar-webinar-container />
</template>

<script>
import AutowebinarWebinarContainer from "@/containers/Autowebinars/AutowebinarWebinarContainer.vue";

export default {
  name: "AutowebinarWebinar",
  components: {
    AutowebinarWebinarContainer,
  },
};
</script>
