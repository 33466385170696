<template>
  <div class="noContent">
    <svg class="noContent__img" v-if="noContent.noFunnels">
      <use xlink:href="@/assets/svg/sprite_funnel.svg#funnel" />
    </svg>
    <img
      class="noContent__img"
      src="@/assets/svg/noContacts.svg"
      alt="no content"
      v-else
    />
    <div
      class="noContent__txt"
      :class="[
        { noContent__txt_noFunnel: !noContent.noFunnels },
        { noContent__txt_noFunnel: funnelTextWidth },
      ]"
    >
      <Tr>{{ noContent.text }}</Tr>
      <template v-if="noContent.link">
        <base-link class="noContent__link" :to="noContent.link.href">
          <Tr>{{ noContent.link.text }}</Tr>
        </base-link>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoContent",
  props: {
    noContent: Object,
    fontWeight: Number,
    funnelTextWidth: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
.noContent {
  display: grid;
  place-items: center;
  grid-row-gap: 20px;
  margin: auto;
  margin-top: 50px;
  width: max-content;
}

.noContent__img {
  display: block;
  width: 128px;
  height: 128px;
}

.noContent__txt {
  font(#0F164250, 20px, 400, 24px);
  text-align: center;
  letter-spacing: -0.2px;
  max-width: 260px;

  @media (min-width: tablet_large) {
    max-width: 300px;

    &_noFunnel {
      max-width: unset;
    }
  }
}

.noContent__link {
  display: block;
  margin-top: 10px;
  color: secondary_text_color;
  opacity: 0.8;
  text-decoration: underline;
}
</style>
