<template>
  <div>
    <leadership-layout
      :title="courseTitle"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
    >
      <div v-if="tasksList && tasksList.length && !pending">
        <draggable-list
          :list="tasksList"
          @change-position="changeTaskPosition"
          @change-position-mirror="changeTaskPositionMirror"
        >
          <draggable-list-item
            :id="item.id"
            :link="`/leadership/courses/${urlParams.courseId}/lessons/${urlParams.lessonId}/works/${item.id}?type=${item.target_type}`"
            :name="taskName(item)"
            :key="`task-${item.id}`"
            :elementObject="item"
            @delete-item="deleteTask"
            v-for="item in tasksList"
          />
        </draggable-list>
        <base-link
          :to="`/leadership/courses/${urlParams.courseId}/lessons/${urlParams.lessonId}/works/new`"
          class="lessons__addLesson"
        >
          <Tr>Добавить задание</Tr>
        </base-link>
      </div>

      <div class="lessons__noLessons" v-else>
        <no-content :noContent="noContent" />
        <base-link
          :to="`/leadership/courses/${urlParams.courseId}/lessons/${urlParams.lessonId}/works/new`"
          class="lessons__addLesson noContent"
        >
          <Tr>Добавить задание</Tr>
        </base-link>
      </div>
    </leadership-layout>
    <loader v-if="pending" />
  </div>
</template>

<script>
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import DraggableList from "@/components/DraggableList.vue";
import DraggableListItem from "@/components/DraggableListItem.vue";
import NoContent from "@/components/NoContent.vue";
import Loader from "@/components/Loader.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    LeadershipLayout,
    DraggableList,
    DraggableListItem,
    NoContent,
    Loader,
  },
  data() {
    return {
      noContent: {
        text: "В этом уроке нет ни одного задания",
      },
    };
  },
  computed: {
    urlParams() {
      return this.$route.params;
    },
    breadcrumbs() {
      const { courseId } = this.$route.params;
      return [
        { title: "Настройки лидера", link: "/leadership" },
        ...(this.programId
          ? [
            {
              title: "Программы обучения",
              link: "/leadership/programs/",
            },
            {
              title: this.programName,
              link: `/leadership/programs/${this.programId}/description`,
            },
            {
              title: "Квесты",
              link: `/leadership/programs/${this.programId}/courses`,
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description?programId=${this.programId}`,
            },
          ]
          : [
            {
              title: "Свои бизнес-квесты",
              link: "/leadership/courses/",
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description`,
            },
          ]),
      ];
    },
    tabs() {
      const { courseId, lessonId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/courses/${courseId}/lessons/${lessonId}/description${this.programId ? `?programId=${this.programId}` : ""
            }`,
        },
        {
          title: "Задание",
          link: `/leadership/courses/${courseId}/lessons/${lessonId}/works${this.programId ? `?programId=${this.programId}` : ""
            }`,
        },
      ];
    },
    ...mapGetters({
      courseTitle: "leadershipCourse/title",
      pending: "leadershipLesson/pending",
      tasksList: "leadershipTasks/tasksList",
      programName: "leadershipProgram/name",
      lang: "currentLang",
    }),
  },
  mounted() {
    const {
      params: { courseId, lessonId },
      query: { programId },
    } = this.$route;

    this.$store.dispatch("leadershipCourse/fetch", { courseId });
    this.$store.dispatch("leadershipLesson/fetch", { lessonId });

    if (programId) {
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }
  },
  methods: {
    deleteTask(task) {
      this.$modal.show("dialog", {
        title: this.$t("Вы уверены?"),
        // text: "Lorem ipsum dolor sit amet, ...",
        buttons: [
          {
            title: this.$t("Нет"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Да"),
            handler: () => {
              this.$store.dispatch("leadershipTasks/deleteTask", { task });
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
    changeTaskPosition(data) {
      const { lessonId } = this.$route.params;
      this.$store.dispatch("leadershipTasks/updateTaskPosition", {
        ...data,
        lessonId,
      });
    },
    changeTaskPositionMirror(data) {
      this.$store.dispatch("leadershipTasks/updateTaskPositionMirroring", data);
    },
    taskName(task) {
      const types = {
        video: this.$t("Просмотр видео"),
        upload: this.$t("Загрузка файла"),
        action: this.$t("Прочитать текст"),
        poll: this.$t("Тест"),
      };
      return `${this.$t("Задание")} № ${task.position}. ${types[task.target_type]
        }`;
    },
  },
};
</script>

<style lang="stylus">
.lessons__addLesson {
  display: block;
  margin-top: 20px;
  padding: 6px 7px;
  width: max-content;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  text-decoration: none;

  @media (min-width: tablet_large) {
    margin-left: 48px;
  }

  &.noContent {
    margin: 20px auto 0 auto;
  }
}
</style>
