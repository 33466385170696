<template>
  <div class="underLoader">
    <div class="loaderBox">
      <div class="loaderIcon" v-if="pending">
        <loader />
      </div>
      <no-content v-if="!pending && isNoContent" :noContent="noContent" />

      <template v-if="!pending">
        <div class="mergeContactsTableWrap mergeContactsTableWrap_shadowed">
          <div class="mergeContactsTableBox">
            <div class="mergeContactsTable" v-if="!isNoContent">
              <merge-contacts-table-head
                class="mergeContactsTableGrid"
                :tableHeads="tableHeads"
              />

              <div
                class="mergeContactsTableItemBox"
                v-for="contact in chosenContactsGroup"
                :key="contact.id"
              >
                <button
                  class="mergeContactsTableItemCellButton"
                  type="button"
                  @click="delContactfromMerge(contact)"
                  v-tooltip="
                    $t('Не использовать контакт в текущем объединении')
                  "
                >
                  <base-icon-svg class="autowebinar__buttonSvg">
                    <editing-delete />
                  </base-icon-svg>
                </button>
                <merge-contacts-table-item
                  class="mergeContactsTableGrid"
                  :contact="contact"
                  :createdContact="createdContact"
                  :contactHeatArray="contactHeatArray"
                  :progresses="progresses"
                  :isCheckedField="isCheckedField"
                  :isCheckedObject="isCheckedObject"
                  :isCheckedTagsObject="isCheckedTagsObject"
                  @pickOption="pickOption"
                  @delContactfromMerge="delContactfromMerge(contact)"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="createdContact"
          v-if="Object.keys(createdContactView).length > 0"
        >
          <div class="mergeContactsTableBox">
            <div class="mergeContactsTable">
              <merge-contacts-table-head
                class="mergeContactsTableGrid mergeContactsTableGrid_created"
                :tableHeads="tableHeads"
                :created="true"
              />
              <merge-contacts-table-item-created
                class="mergeContactsTableGrid mergeContactsTableGrid_created"
                :contact="createdContactView"
                :contactHeatArray="contactHeatArray"
                :progresses="progresses"
              />
            </div>
            <form-submit
              :orange="true"
              @click="mergeContacts"
              text="Объединить"
              :disabled="
                !createdContactView.first_name && !createdContactView.last_name
              "
            />
          </div>
        </div>

        <form-submit
          v-if="false"
          @click="openManualMergeModal"
          text="Ручное объединение"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { runNotice } from '@/utils/notifications';

import Loader from "@/components/Loader.vue";
import NoContent from "@/components/NoContent.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import MergeContactsTableHead from "@/components/MergeContacts/MergeContactsTableHead.vue";
import MergeContactsTableItem from "@/components/MergeContacts/MergeContactsTableItem.vue";
import MergeContactsTableItemCreated from "@/components/MergeContacts/MergeContactsTableItemCreated.vue";
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";

export default {
  name: "MergeContactsContainer",

  components: {
    Loader,
    NoContent,
    FormSubmit,
    MergeContactsTableHead,
    MergeContactsTableItem,
    MergeContactsTableItemCreated,
    EditingDelete,
  },

  data() {
    return {
      noContent: {
        text: "Не выбраны контакты для объединения.",
        maxWidth: 0,
        link: {
          text: "Выбрать контакты",
          href: "/contacts-groups",
        },
      },

      tableHeads: [
        { title: "Имя" },
        { title: "Телефон" },
        { title: "Эл. почта" },
        { title: "Соц. сети" },
        { title: "Страна" },
        { title: "Город" },
        { title: "Связь" },
        { title: "Прогресс" },
        { title: "Подогретость" },
        { title: "Топ" },
        { title: "Теги" },
        { title: "" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      createdContactView: "contactsUnion/createdContactView",
      isCheckedField: "contactsUnion/isCheckedField",
      isCheckedObject: "contactsUnion/isCheckedObject",
      isCheckedTagsObject: "contactsUnion/isCheckedTagsObject",
    }),
    ...mapState(
      {
        chosenContactsGroup: state => state.contactsUnion.chosenContactsGroup,
        createdContact: state => state.contactsUnion.createdContact,
        contactHeatArray: state => state.contacts.contactHeatArray,
        pendingHeat: state => state.contacts.pendingHeat,
        pendingMerge: state => state.contactsUnion.pendingMerge,
        progresses: state => state.leadTasks.contactProgresses,
        pendingContactProgresses: state => state.leadTasks.pendingContactProgresses,
        counter: state => state.contactsUnion.counter,
      }
    ),

    isNoContent() {
      return this.chosenContactsGroup.length === 0;
    },

    pending() {
      return this.pendingHeat || this.pendingMerge || this.pendingContactProgresses;
    },
  },

  methods: {
    openManualMergeModal() {
      alert("openManualMergeModal");
    },

    mergeContacts() {
      this.$store.dispatch("contactsUnion/mergeContacts")
        .then(({ responseCreateContact, responseMergeContacts }) => {
          if (responseMergeContacts?.status === 200) {

            if (
              Object.prototype.hasOwnProperty.call(this.createdContactView, "tag_objects") &&
              this.createdContactView.tag_objects.length > 0
            ) {
              Promise.all(
                this.createdContactView.tag_objects.map(el =>
                  this.$store
                    .dispatch("contactsTags/addTag", {
                      tagId: el.id,
                      contactId: responseCreateContact.data.id,
                    })
                )
              )
                .then(() => {
                  this.$router.push({ path: `/${this.currentLang}/contacts` });
                  runNotice("success", this.$t("Контакты объединены"));
                })
                .catch(e => {
                  console.log(e);
                });
            } else {
              this.$router.push({ path: `/${this.currentLang}/contacts` });
              runNotice("success", this.$t("Контакты объединены"));
            }
          } else {
            this.$store.commit("contactsUnion/SET_CLEAR_CREATED_CONTACT");
            runNotice("error", this.$t("Что-то пошло не так"));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    pickOption(key, poleValue, contact) {
      const objectsArray = ['phones_objects', 'emails_objects', 'links_objects']

      if (key === "name") {
        this.$store.commit("contactsUnion/SET_CREATED_CONTACT_FIELD", { contactId: contact.id, nameField: 'first_name', value: contact.first_name });
        this.$store.commit("contactsUnion/SET_CREATED_CONTACT_FIELD", { contactId: contact.id, nameField: 'last_name', value: contact.last_name });
      } else if (objectsArray.includes(key)) {
        this.$store.commit("contactsUnion/TOGGLE_CREATED_CONTACT_OBJECTS", { contactId: contact.id, nameField: key, object: poleValue });
      } else if (key === "tag_objects") {
        this.$store.commit("contactsUnion/TOGGLE_CREATED_CONTACT_TAGS", { contactId: contact.id, nameField: key, array: poleValue });
      } else {
        this.$store.commit("contactsUnion/SET_CREATED_CONTACT_FIELD", { contactId: contact.id, nameField: key, value: poleValue });
      }
    },

    delContactfromMerge(contact) {
      this.$store.commit("contactsUnion/DEL_CHOSEN_CONTACT", contact);
    },
  },

  mounted() {
    this.$store.dispatch("contacts/fetchContactHeat");
    this.$store.dispatch("leadTasks/fetchContactProgresses");
  },

  destroyed() {
    this.$store.commit("contactsUnion/SET_CLEAR_CHOSEN_CONTACTS_GROUP");
    this.$store.commit("contactsUnion/SET_CLEAR_CHOSEN_CONTACTS_GROUP_IDS");
    this.$store.commit("contactsUnion/SET_CLEAR_CREATED_CONTACT");
  },
};
</script>

<style lang="stylus" scoped>
.mergeContactsTableWrap {
  padding-left: 30px;
  position: relative;

  &_shadowed {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      z-index: 0;
      top: 0;
      right: 0;
      width: 8px;
      top: 15px;
      right: unset;
      bottom: 30px;
      left: 20px;
      box-shadow: 0 0 15px rgba(15 22 66 0.3);
      clip-path: inset(0px -15px 0px 0px);
    }
  }

  @media (min-width: desktop) {
    padding-left: 40px;

    &_shadowed {
      &::before {
        top: 50px;
        left: 30px;
      }
    }
  }
}

.mergeContactsTableBox {
  padding: 0 0 10px 10px;
  width: calc(100vw - 60px);
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #e6e7eb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: main_text_color;
    border: 2px solid #fff;
    border-radius: 6px;
  }

  @media (min-width: tablet_large) {
    width: calc(100vw - 80px);
  }

  @media (min-width: desktop) {
    width: calc(100vw - 360px);
  }
}

.mergeContactsTableItemCellButton {
  position: absolute;
  left: 0;
  display: block;
  padding: 0;
  width: 18px;
  height: 18px;
  border: none;
  background-color: unset;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }
}

.mergeContactsTable {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  margin-top: 24px;

  @media (min-width: desktop) {
    display: grid;
    row-gap: unset;
    column-gap: unset;
    grid-row-gap: 8px;
  }
}

.mergeContactsTableGrid {
  grid-template-columns: 180px 140px 190px 190px 120px 120px 170px 130px 135px 70px 160px;
  grid-column-gap: 8px;
  align-items: flex-start;
  font_regular();

  &_created {
    grid-template-columns: 1fr;
  }

  @media (min-width: tablet_large) {
    grid-column-gap: 8px;

    &_created {
      grid-template-columns: 180px 140px 190px 190px 120px 120px 170px 130px 135px 70px 160px;
    }
  }
}

.createdContact {
  margin-top: 20px;
  padding: 16px;
  border: 2px solid #E6E7EB;
  border-radius: 4px;
  max-width: 400px;

  @media (min-width: tablet_large) {
    width: fit-content;
    max-width: unset;
  }

  .mergeContactsTable {
    margin-top: 0;
  }

  .mergeContactsTableBox {
    width: 100%;

    @media (min-width: tablet_large) {
      width: calc(100vw - 80px);
    }

    @media (min-width: desktop) {
      width: calc(100vw - 360px);
    }
  }
}
</style>
