<template>
  <leadership-layout :title="title" :breadcrumbs="breadcrumbs" :tabs="tabs">
    <div class="courses" v-if="courses && courses.length > 0">
      <draggable
        @change="checkMove"
        class="courses__list courses__list-group"
        handle=".lessons__buttonMove"
        tag="ul"
        v-model="courses"
      >
        <leadership-lesson-item
          :delete="deleteLesson"
          :key="course.id"
          :lesson="course"
          :isCourse="true"
          @delete-lesson="deleteLesson"
          v-for="course in courses"
        />
      </draggable>
      <base-link :to="addCourseLink" class="courses__addCourse">
        <Tr>Добавить квест</Tr>
      </base-link>
    </div>
    <div class="courses__noCourses" v-else>
      <no-content :noContent="noContent" />
      <base-link :to="addCourseLink" class="courses__noCoursesAddCourse">
        <Tr>Добавить квест</Tr>
      </base-link>
    </div>
  </leadership-layout>
</template>

<script>
import { mapGetters } from "vuex";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import draggable from "vuedraggable";
import LeadershipLessonItem from "@/components/Leadership/LeadershipLessonItem.vue";
import NoContent from "@/components/NoContent.vue";

import { runErrorNotice } from "@/utils/notifications";

export default {
  name: "ProgramCoursesContainer",
  components: {
    LeadershipLayout,
    LeadershipLessonItem,
    NoContent,
    draggable,
  },
  data() {
    return {
      noContent: {
        text: "В этой программе нет ни одного квеста",
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { title: "Настройки лидера", link: "/leadership" },
        {
          title: "Программы обучения",
          link: "/leadership/programs",
        },
      ];
    },
    tabs() {
      const { programId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/programs/${
            !this.isNew ? programId + "/description" : "new/description"
          }`,
          exact: true,
        },
        ...(!this.isNew
          ? [
              {
                title: "Квесты",
                link: `/leadership/programs/${programId}/courses`,
                exact: true,
              },
            ]
          : []),
      ];
    },
    title() {
      if (this.isNew) {
        return this.$t("Новая программа");
      }
      return this.name;
    },
    addCourseLink() {
      const { programId } = this.$route.params;
      return `/leadership/courses/new?quest_course=${programId}`;
    },
    courses: {
      get() {
        return this.$store.getters["leadershipProgramCourses/courses"];
      },
      set(value) {
        this.$store.dispatch(
          "leadershipProgramCourses/updateLessonPositionMirroring",
          value
        );
      },
    },
    courseLink(courseId) {
      return `/leadership/courses/${courseId}`;
    },
    ...mapGetters({
      program: "leadershipProgram/program",
      pending: "leadershipProgram/pending",
      lang: "leadershipProgram/lang",
      errors: "leadershipProgram/errors",
      supportedLangsTitled: "supportedLangsTitled",
      name: "leadershipProgram/name",
    }),
  },
  methods: {
    checkMove({ moved }) {
      this.$store.dispatch("leadershipProgramCourses/updateLessonPosition", {
        courseId: moved.element.id,
        position: moved.newIndex + 1,
      });
    },
    deleteLesson(id) {
      // if (this.isPublished) {
      //   runErrorNotice(this.$t("Нельзя удалить урок в опубликованном квесте"));
      //   return;
      // }
      this.$modal.show("dialog", {
        title: this.$t("Вы уверены?"),
        // text: "Lorem ipsum dolor sit amet, ...",
        buttons: [
          {
            title: this.$t("Нет"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Да"),
            handler: () => {
              this.$store.dispatch("leadershipProgramCourses/deleteLesson", id);
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
  },
  mounted() {
    const { programId } = this.$route.params;
    this.$store.dispatch("leadershipProgram/fetch", { programId });
    this.$store.dispatch("leadershipProgramCourses/fetch", { programId });
  },
};
</script>

<style lang="stylus">
.courses__noCoursesAddCourse {
  display: block;
  margin: auto;
  margin-top: 30px;
  padding: 6px 7px;
  width: max-content;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  text-decoration: none;
}

.courses__addCourse {
  display: block;
  margin-top: 20px;
  padding: 6px 7px;
  width: max-content;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  text-decoration: none;

  @media (min-width: tablet_large) {
    margin-left: 48px;
  }
}

.courses__list {
  counter-reset: list;
  display: grid;
  grid-row-gap: 4px;
}
</style>
