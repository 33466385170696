<template>
  <main class="main" v-if="showTeam">
    <h1 class="title">{{ $t("Команда") }}</h1>
    <div v-if="team && team.length">
      <TeamMenu :teamTree="teamTree" />
      <TeamTreeBody
        :team="team"
        :pending="pending"
        :showRank="showRank"
        :company="usersCompanySlug"
        v-if="team"
      />
    </div>
    <NoContent :noContent="noContent" class="team__noContent" v-else />
  </main>
</template>

<script>
import TeamMenu from "@/components/Team/TeamMenu.vue";
import TeamTreeBody from "@/components/Team/TeamTreeBody.vue";
import { mapGetters } from "vuex";
// import TeamHead from "~/components/Team/TeamHead.vue";
import NoContent from "@/components/NoContent.vue";

export default {
  name: "TeamTree",
  data() {
    return {
      teamTree: true,
      noContent: {
        text: "У вас нет никого в команде",
      },
    };
  },
  computed: {
    ...mapGetters({
      team: "team/team",
      pending: "team/pending",
      usersCompanySlug: "user/usersCompanySlug",
      sessionid: "user/sessionid",
      showTeam: "user/showTeam",
      currentLang: "currentLang",
    }),
    showRank() {
      return this.usersCompanySlug === "bitbon";
    },
  },
  components: {
    TeamMenu,
    TeamTreeBody,
    NoContent,
    // TeamHead,
  },
  mounted() {
    if (!this.showTeam) {
      this.$router.push({ path: `/${this.currentLang}/contacts` });
    }
    const level = this.$store.getters["user/level"];
    this.$store.dispatch("team/fetch", Number(level) + 1);
  },
};
</script>

<style lang="stylus" scoped></style>
