<template>
  <leadership-layout
    :title="taskTitle"
    :breadcrumbs="breadcrumbs"
    :tabs="tabs"
    :pending="pending"
  >
    <leadership-task-head
      :lang="activeLang"
      :langTabs="langTabs"
      :taskType="taskType"
      :taskDescription="taskDescription"
      :hideTypeChecker="!isNew"
      @change-lang="changeLang"
      @change-description="changeDescription"
      @change-task-type="changeTaskType"
    />

    <!-- <task-mentor-checking v-if="taskType === 'mentor'" /> -->
    <task-test v-if="taskType === 'poll'" />
    <task-video v-if="taskType === 'video'" />

    <template v-if="hasDeadline">
      <leadership-reward-input
        :errors="errors.reward_gold"
        :value="rewardGold"
        @handle-reward="changeRewardGold"
        name="reward"
        title="Награда за выполнение вовремя"
      />
      <leadership-reward-input
        :errors="errors.reward_expire_gold"
        :value="rewardExpire"
        @handle-reward="changeRewardGoldExpire"
        name="reward"
        title="Награда после дедлайна"
      />
    </template>

    <leadership-reward-input
      v-else
      :errors="errors.reward_gold"
      :value="rewardGold"
      @handle-reward="changeRewardGold"
      name="reward"
      title="Награда за выполнение"
    />

    <template v-if="taskType !== 'poll'">
      <leadership-reward-input
        :errors="errors.approve_reward_brilliant"
        :isMentor="true"
        :value="rewardBrilliant"
        @handle-reward="changeRewardBrilliant"
        name="reward"
        title="Награда наставнику"
      />
      <leadership-reward-input
        :errors="errors.approve_reward_expire_brilliant"
        :isMentor="true"
        :value="rewardBrilliantExpire"
        @handle-reward="changeRewardBrilliantExpire"
        name="reward"
        title="Награда наставнику после дедлайна"
        v-if="hasDeadline"
      />
    </template>

    <form-submit
      @click="save"
      buttonName="save"
      :disabled="pendingPoll || pendingAction || pendingUpload || pendingVideo"
    />
    <!-- <published-block v-else /> -->
  </leadership-layout>
</template>

<script>
import { mapGetters } from "vuex";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipTaskHead from "@/components/Leadership/LeadershipTaskHead.vue";
import LeadershipRewardInput from "@/components/Leadership/LeadershipRewardInput.vue";
import TaskTest from "@/containers/Leadership/TaskTest.vue";
import TaskVideo from "@/containers/Leadership/TaskVideo.vue";
import FormSubmit from "@/components/FormSubmit.vue";
// import PublishedBlock from "@/components/PublishedBlock.vue";
// import TaskMentorChecking from "@/containers/Leadership/TaskMentorChecking.vue";

export default {
  name: "TaskContainer",
  components: {
    LeadershipLayout,
    LeadershipTaskHead,
    LeadershipRewardInput,
    // TaskMentorChecking,
    TaskTest,
    // PublishedBlock,
    FormSubmit,
    TaskVideo,
  },
  computed: {
    breadcrumbs() {
      const { courseId, lessonId } = this.$route.params;
      return [
        { title: "Настройки лидера", link: "/leadership" },
        ...(this.programId
          ? [
            {
              title: "Программы обучения",
              link: "/leadership/programs/",
            },
            {
              title: this.programName,
              link: `/leadership/programs/${this.programId}/description`,
            },
            {
              title: "Квесты",
              link: `/leadership/programs/${this.programId}/courses`,
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description?programId=${this.programId}`,
            },
          ]
          : [
            {
              title: "Свои бизнес-квесты",
              link: "/leadership/courses/",
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description`,
            },
            {
              title: this.lessonTitle,
              link: `/leadership/courses/${courseId}/lessons/${lessonId}/description`,
            },
          ]),
      ];
    },

    tabs() {
      return [];
    },
    programId() {
      const {
        query: { programId },
      } = this.$route;
      if (programId) {
        return programId;
      }
      return undefined;
    },
    taskType() {
      const {
        query: { type },
      } = this.$route;
      if (type) {
        return type;
      }
      return this.newTaskType;
    },
    pending() {
      return (
        this.$store.getters["leadershipTaskPoll/pending"] ||
        this.$store.getters["leadershipTaskAction/pending"] ||
        this.$store.getters["leadershipTaskUpload/pending"]
      );
    },
    ...mapGetters({
      lessonTitle: "leadershipLesson/title",
      taskPosition: "leadershipTask/taskPosition",
      courseTitle: "leadershipLesson/courseTitle",
      activeLang: "leadershipTask/activeLang",
      langTabs: "leadershipTask/langTabs",
      newTaskType: "leadershipTask/taskType",
      taskDescription: "leadershipTask/title",
      rewardGold: "leadershipTask/rewardGold",
      rewardExpire: "leadershipTask/rewardExpire",
      rewardBrilliant: "leadershipTask/rewardBrilliant",
      rewardBrilliantExpire: "leadershipTask/rewardBrilliantExpire",
      errors: "leadershipTask/errors",
      isPublished: "leadershipLesson/isPublished",
      deadline: "leadershipLesson/deadline",
      programName: "leadershipProgram/name",
      isNew: "leadershipTask/isNew",

      pendingPoll: "leadershipTaskPoll/pending",
      pendingAction: "leadershipTaskAction/pending",
      pendingUpload: "leadershipTaskUpload/pending",
      pendingVideo: "leadershipTaskVideo/pending",
    }),
    taskTitle() {
      if (this.isNew) {
        return this.$t("Новое задание");
      }
      const types = {
        video: this.$t("Просмотр видео"),
        upload: this.$t("Загрузка файла"),
        action: this.$t("Прочитать текст"),
        poll: this.$t("Тест"),
      };
      return `${this.$t("Задание")} № ${this.taskPosition}. ${types[this.taskType]
        }`;
    },
    hasDeadline() {
      return this.deadline && this.deadline.checked;
    },
  },
  mounted() {
    const {
      params: { lessonId, taskId },
      query: { programId, type },
    } = this.$route;
    if (programId) {
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }

    Promise.all([this.$store.dispatch("leadershipLesson/fetch", { lessonId })])
      .then(() => {

        if (taskId && taskId !== "new") {
          this.$store.dispatch("leadershipTask/initTask", { taskId, type });
        } else {
          this.$store.dispatch("leadershipTask/initNew");
        }
      })
      .catch(e => {
        console.log(e);
      });
  },
  methods: {
    changeLang(lang) {
      this.$store.commit("leadershipTask/TOGGLE_LANG", lang);
    },
    changeDescription(value) {
      this.$store.commit("leadershipTask/CHANGE_DESCRIPTION", value);
    },
    changeTaskType(value) {
      this.$store.dispatch("leadershipTask/changeTaskType", value);
    },
    changeRewardGold(value) {
      this.$store.commit("leadershipTask/CHANGE_REWARD", {
        name: "reward_gold",
        value: value,
      });
    },
    changeRewardGoldExpire(value) {
      this.$store.commit("leadershipTask/CHANGE_REWARD", {
        name: "reward_expire_gold",
        value: value,
      });
    },
    changeRewardBrilliant(value) {
      this.$store.commit("leadershipTask/CHANGE_REWARD", {
        name: "approve_reward_brilliant",
        value: value,
      });
    },
    changeRewardBrilliantExpire(value) {
      this.$store.commit("leadershipTask/CHANGE_REWARD", {
        name: "approve_reward_expire_brilliant",
        value: value,
      });
    },
    save() {
      const {
        query: { type },
      } = this.$route;
      this.$store.dispatch("leadershipTask/save", type);
    },
  },

  destroyed() {
    this.$store.commit("leadershipTask/CLEAR_ERRORS");
  },
};
</script>
