<template>
  <div class="createLinkButtons__wrap">
    <button
      class="createLinkButton createLinkButton_createLink without-img"
      @click="refreshLeads"
    >
      <Tr>Обновить список лидов</Tr>
    </button>
    <BaseLink
      :to="updatedFunnel ? '/funnel/create-link' : '/funnel/create-link'"
      :class="[
        'createLinkButton',
        'createLinkButton_createLink',
        { createLinkButton_createLink_updatedFunnel: updatedFunnel },
      ]"
    >
      <Tr>Создать ссылку</Tr>
    </BaseLink>
    <BaseLink
      v-if="!isTasq && !updatedFunnel"
      to="/funnel/create-link-without-chatbot"
      class="
        createLinkButton
        createLinkButton_createLink
        createLinkButton_createLinkWithoutChatbot
      "
    >
      <Tr>Ссылка на вебинар</Tr>
    </BaseLink>
    <BaseLink
      :to="updatedFunnel ? '/funnel/links-list' : '/funnel/link-list'"
      class="createLinkButton createLinkButton_list"
    >
      <Tr>Список ссылок</Tr>
    </BaseLink>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({

  name: "FunnelMenuAddLinks",
  props: {
    company: {
      type: String,
      required: true,
    },
    updatedFunnel: Boolean,
  },
  computed: {
    isTasq() {
      return /^(tasq)?$/.test(this.company);
    }
  },
  methods: {
    refreshLeads() {
      this.$emit("refreshLeads");
    },
  },
});
</script>

<style lang="stylus" scoped>
.without-img {
  margin-bottom: 10px;
}

.createLink {
  &Buttons__wrap {
    margin-bottom: 20px;
  }

  &Button {
    position: relative;
    background-color: unset;
    border: none;
    border-radius: 4px;
    font_regular();
    text-decoration: none;

    &_createLink {
      display: block;
      margin-right: 10px;
      padding: 7px 8px 7px 28px;
      width: fit-content;
      border: 1px dashed main_text_color;

      &_updatedFunnel {
        margin-bottom: 10px;
      }

      &.without-img {
        padding-left: 8px;

        &:before {
          display: none;
        }
      }

      &::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_createLinkWithoutChatbot {
      margin: 10px 0;
    }

    &_list {
      display: inline-block;
      padding: 7px 8px;
      background-color: grey_text_color;
    }
  }
}

@media (min-width: desktop) {
  .createLink {
    &Buttons__wrap {
      position: absolute;
      right: 0;
      top: 0;
      display: grid;
      grid-auto-flow: column;
      grid-column-gap: 9px;
      margin-bottom: 0;
    }

    &Button {
      &:hover {
        opacity: 0.6;
      }

      &_createLink {
        margin-right: 0;

        &_updatedFunnel {
          margin-bottom: 0;
        }
      }

      &_createLinkWithoutChatbot {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .without-img {
    margin-bottom: 0;
  }
}
</style>
