<template>
  <fieldset class="autowebinar__fieldsetRadio">
    <legend class="autowebinar__legend">
      <Tr v-if="title">{{ title }}</Tr>
      <Tr v-else>Язык автовебинара</Tr>

      <label
        class="autowebinar__label"
        v-for="lang in langs"
        :key="`webinar-lang-${lang.value}`"
      >
        <Tr>{{ lang.title }}</Tr>
        <input
          class="autowebinar__input"
          name="autowebinarLang"
          type="radio"
          :value="lang.value"
          :checked="lang.value === value"
          @change="changeLang"
        />
        <span class="autowebinar__radio" />
      </label>
    </legend>
  </fieldset>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AutowebinarsLangs",
  computed: {
    ...mapGetters({
      langs: "supportedLangsTitled",
    }),
  },
  props: {
    title: String,
    value: String,
  },
  methods: {
    changeLang(e) {
      this.$emit("change-lang", e.target.value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.autowebinar__legend {
  font(#0F1642, 12px, 400, 15px);
  letter-spacing: -0.1px;
}

.autowebinar__fieldsetText {
  margin-bottom: 20px;
}

.autowebinar__fieldsetRadio {
  margin-bottom: 20px;
}

.autowebinar__label {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 25px;
  font_regular();
  cursor: pointer;
}

.autowebinar__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.autowebinar__radio {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
  border-radius: 50%;
}

.autowebinar__input:checked + .autowebinar__radio:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background-color: main_text_color;
  border-radius: 50%;
}

.autowebinar__checkbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid #e6e7eb;
}

.autowebinar__input:checked + .autowebinar__checkbox:after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 8px;
  height: 8px;
  background-color: main_text_color;
}
</style>
