<template>
  <base-icon-svg width="20" height="24" viewbox="0 0 20 24" v-if="actionItem">
    <funnel-landing
      v-if="actionItem.source === 'form'"
      v-tooltip="`${$t('Лендинг')} ${landingUrl}`"
    />
    <funnel-vk v-if="actionItem.source === 'vk_form'" v-tooltip="$t('Вк')" />
    <funnel-telegram
      v-if="actionItem.source === 'chatbot'"
      v-tooltip="'Telegram'"
    />
    <funnel-fb v-if="false" v-tooltip="'Facebook'" />
  </base-icon-svg>
</template>

<script>
import FunnelLanding from "@/components/SvgIcons/funnel/FunnelLanding.vue";
import FunnelVk from "@/components/SvgIcons/funnel/FunnelVk.vue";
import FunnelFb from "@/components/SvgIcons/funnel/FunnelFb.vue";
import FunnelTelegram from "@/components/SvgIcons/funnel/FunnelTelegram.vue";

export default {
  name: "UpdatedFunnelBodyItemtriggers",

  components: {
    FunnelLanding,
    FunnelVk,
    FunnelFb,
    FunnelTelegram,
  },

  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },

  computed: {
    landingUrl() {
      if (this.actionItem.page) {
        let arr = this.actionItem.page.split("/");
        arr = arr.slice(0, 3);
        return arr.join("/");
      }
      return "";
    },
  },
};
</script>

<style lang="stylus" scoped></style>
