<template>
  <leadership-layout
    :title="title"
    :breadcrumbs="breadcrumbs"
    :tabs="tabs"
    :pending="pending"
  >
    <leadership-lesson
      :langTabs="langTabs"
      :lessonName="lessonName"
      :lessonContent="lessonContent"
      :lang="activeLang"
      :canSkip="canSkip"
      :errors="errors"
      @change-name="changeName"
      @change-content="changeContent"
      @change-active-lang="changeActiveLang"
      @change-can-skip="changeCanSkip"
    />

    <lesson-delay
      :lessonDelay="lessonDelay"
      :errors="errors"
      @change-delay-type="changeDelayType"
      @change-delay-day="changeDelayDay"
      @change-delay-time="changeDelayTime"
      @change-delay-date="changeDelayDate"
    />

    <file-uploader :files="linkedFiles" />
    <lesson-deadline
      :deadline="deadline"
      :errors="errors"
      @change-deadline="changeDeadline"
      @toggle-deadline="toggleDeadline"
    />
    <form-submit @click="save" buttonName="save" />
    <!-- <published-block v-else /> -->
    <!-- <form-submit @click="deleteLesson" buttonName="delete" /> -->
  </leadership-layout>
</template>

<script>
import { mapGetters } from "vuex";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipLesson from "@/components/Leadership/LeadershipLesson.vue";
import FormSubmit from "@/components/FormSubmit.vue";
import FileUploader from "@/components/Leadership/FileUploader.vue";
import LessonDelay from "@/components/Leadership/LessonDelay.vue";
import LessonDeadline from "@/components/Leadership/LessonDeadline.vue";
// import PublishedBlock from "@/components/PublishedBlock.vue";

export default {
  name: "LessonContainer",
  components: {
    LeadershipLayout,
    LeadershipLesson,
    FormSubmit,
    FileUploader,
    LessonDelay,
    LessonDeadline,
    // PublishedBlock,
  },
  data() {
    return {};
  },
  computed: {
    breadcrumbs() {
      const { courseId } = this.$route.params;
      return [
        { title: "Настройки лидера", link: "/leadership" },
        ...(this.programId
          ? [
            {
              title: "Программы обучения",
              link: "/leadership/programs/",
            },
            {
              title: this.programName,
              link: `/leadership/programs/${this.programId}/description`,
            },
            {
              title: "Квесты",
              link: `/leadership/programs/${this.programId}/courses`,
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description?programId=${this.programId}`,
            },
          ]
          : [
            {
              title: "Свои бизнес-квесты",
              link: "/leadership/courses/",
            },
            {
              title: this.courseTitle,
              link: `/leadership/courses/${courseId}/description`,
            },
          ]),
      ];
    },

    tabs() {
      const { courseId, lessonId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/courses/${courseId}/lessons/${lessonId}/description${this.programId ? `?programId=${this.programId}` : ""
            }`,
        },
        {
          title: "Задание",
          link: `/leadership/courses/${courseId}/lessons/${lessonId}/works${this.programId ? `?programId=${this.programId}` : ""
            }`,
        },
      ];
    },
    programId() {
      const {
        query: { programId },
      } = this.$route;
      if (programId) {
        return programId;
      }
      return undefined;
    },
    pending() {
      const getters = this.$store.getters;
      return (
        getters["leadershipLesson/pending"] || getters["fileUpload/pending"]
      );
    },

    ...mapGetters({
      courseTitle: "leadershipLesson/courseTitle",
      langTabs: "leadershipLesson/langTabs",
      lessonName: "leadershipLesson/name",
      lessonContent: "leadershipLesson/content",
      activeLang: "leadershipLesson/activeLang",
      videoEnabled: "leadershipLesson/videoEnabled",
      videoUrls: "leadershipLesson/videoUrls",
      title: "leadershipLesson/title",
      reward: "leadershipLesson/reward",
      rewardExpire: "leadershipLesson/rewardExpire",
      // pending: "leadershipLesson/pending",
      errors: "leadershipLesson/errors",
      videoErrors: "leadershipLesson/videoErrors",
      linkedFiles: "leadershipLesson/linkedFiles",
      isPublished: "leadershipLesson/isPublished",
      canSkip: "leadershipLesson/canSkip",
      lessonDelay: "leadershipLesson/lessonDelay",
      deadline: "leadershipLesson/deadline",
      programName: "leadershipProgram/name",
    }),
  },
  mounted() {
    const {
      params: { lessonId },
      query: { programId },
    } = this.$route;
    this.$store.dispatch("leadershipLesson/fetch", { lessonId });

    if (programId) {
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }
  },
  methods: {
    changeDeadline(value) {
      this.$store.commit("leadershipLesson/CHANGE_DEADLINE", value);
    },
    changeVideoRewardGold(value) {
      this.$store.commit("leadershipLesson/CHANGE_REWARD", {
        type: "reward_gold",
        value,
      });
    },
    changeName(value) {
      this.$store.commit("leadershipLesson/CHANGE_NAME", value);
    },
    changeContent(value) {
      this.$store.commit("leadershipLesson/CHANGE_CONTENT", value);
    },
    changeActiveLang(lang) {
      this.$store.commit("leadershipLesson/TOGGLE_LANG", lang);
    },
    toggleVideo() {
      this.$store.commit("leadershipLesson/TOGGLE_VIDEO");
    },
    changeVideoUrl({ value, index }) {
      this.$store.commit("leadershipLesson/CHANGE_VIDEO_URL", { value, index });
    },
    changeVideoReward(value) {
      this.$store.commit("leadershipLesson/CHANGE_REWARD", {
        type: "reward_gold",
        value,
      });
    },
    changeRewardExpire(value) {
      this.$store.commit("leadershipLesson/CHANGE_REWARD", {
        type: "reward_expire_gold",
        value,
      });
    },
    changeCanSkip() {
      this.$store.commit("leadershipLesson/CHANGE_CAN_SKIP");
    },
    save() {
      this.$store.dispatch("leadershipLesson/updateLesson");
    },
    deleteLesson() {
      const { lessonId } = this.$route.params;
      this.$store.dispatch("leadershipLessons/deleteLesson", lessonId);
    },
    changeDelayType(value) {
      this.$store.commit("leadershipLesson/CHANGE_DELAY_TYPE", value);
    },
    changeDelayDay(value) {
      this.$store.commit("leadershipLesson/CHANGE_DELAY_DAY", value);
    },
    changeDelayTime(value) {
      this.$store.commit("leadershipLesson/CHANGE_DELAY_TIME", value);
    },
    changeDelayDate(value) {
      this.$store.commit("leadershipLesson/CHANGE_DELAY_DATE", value);
    },
    toggleDeadline() {
      this.$store.commit("leadershipLesson/CHANGE_DEADLINE_CHECKED");
    },
  },

  destroyed() {
    this.$store.commit("leadershipLesson/CLEAR_ERRORS");
  },
};
</script>
