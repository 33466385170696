<template>
  <div v-if="actionItem">
    <span class="tableItem__time">
      {{ dateFormatted(actionItem.created_at) }}
    </span>
    <div class="tableItem__box">
      <div class="tableItem__title">
        <base-icon-svg
          width="16"
          height="16"
          viewbox="0 0 16 16"
          class="tableItem__icon"
        >
          <template v-if="actionItem.channel === 'email'">
            <notification-email-opened
              v-if="actionItem.statistics && actionItem.statistics.open_count > 0"
              :class="[
                'tableItem__iconInner',
                {
                  tableItem__iconInner_contact: actionItem.type === 'contact',
                },
              ]"
            />
            <notification-email
              v-else
              :class="[
                'tableItem__iconInner',
                {
                  tableItem__iconInner_contact: actionItem.type === 'contact',
                },
              ]"
            />
          </template>
          <notification-sms
            v-if="actionItem.channel === 'sms'"
            :class="[
              'tableItem__iconInner',
              {
                tableItem__iconInner_contact: actionItem.type === 'contact',
              },
            ]"
          />
          <notification-call
            v-if="actionItem.channel === 'autocall'"
            :class="[
              'tableItem__iconInner',
              {
                tableItem__iconInner_contact: actionItem.type === 'contact',
              },
            ]"
          />
        </base-icon-svg>
        <template
          v-if="actionItem.type === 'flow' && actionItem.channel !== 'autocall'"
        >
          <Tr>Автоматическое сообщение</Tr>
        </template>
        <template
          v-if="actionItem.type === 'flow' && actionItem.channel === 'autocall'"
        >
          <Tr>Автозвонок</Tr>
        </template>
        <template v-if="actionItem.type === 'contact'">
          <Tr>Ручное сообщение.</Tr>
        </template>
      </div>
      <div class="tableItem__text">
        <template v-if="actionItem.parameters.subject">
          {{ actionItem.parameters.subject }}
        </template>
        <template v-if="actionItem.parameters.message">
          {{ actionItem.parameters.message }}
        </template>
        <div v-if="actionItem.parameters.number">
          {{ actionItem.parameters.number }}
        </div>
        <template v-if="actionItem.statistics">
          <template v-if="actionItem.statistics.status === 'completed'">
            <Tr>Успешный звонок</Tr>
          </template>
          <template v-if="actionItem.statistics.duration">
            <div>
              <Tr>Продолжительность:</Tr>
              {{ time }}
            </div>
          </template>
        </template>
        <template
          v-if="
            actionItem.channel === 'autocall' &&
            actionItem.statistics &&
            actionItem.statistics.status &&
            actionItem.statistics.status !== 'completed'
          "
        >
          {{ actionItem.statistics.status }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import NotificationEmail from '@/components/SvgIcons/funnel/NotificationEmail.vue';
import NotificationEmailOpened from '@/components/SvgIcons/funnel/NotificationEmailOpened.vue';
import NotificationSms from '@/components/SvgIcons/funnel/NotificationSms.vue';
import NotificationCall from "@/components/SvgIcons/funnel/NotificationCall.vue";

export default {
  name: "UpdatedFunnelModalLeadNotification",
  components: {
    NotificationEmail,
    NotificationEmailOpened,
    NotificationSms,
    NotificationCall,
  },
  props: {
    actionItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
    time() {
      const secToMin = 60;
      if (Number(this.actionItem.statistics.duration) > secToMin) {
        const sec = Number(this.actionItem.statistics.duration) % secToMin;
        const min = (Number(this.actionItem.statistics.duration) - sec) / secToMin;
        return `${min} ${this.$t('мин')} ${sec} ${this.$t('сек')}`
      }
      return `${this.actionItem.statistics.duration} ${this.$t('сек')}`;
    },
  },
  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__time {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: 7px;
  }

  &__iconInner {
    color: #3489f8;

    &_contact {
      color: main_text_color;
    }
  }

  &__title {
    display: flex;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      align-items: center;
    }

    span {
      opacity: 0.6;
    }
  }

  &__text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 23px;
  }

  &__textInner {
    max-height: 18px;
    overflow: hidden;
    transition: 0.4s;

    &_full {
      max-height: unset;
      // overflow: visible;
    }
  }
}
</style>
