<template>
  <div>
    <leadership-layout
      :title="courseTitle"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
    >
      <div class="lessons" v-if="lessons && lessons.length > 0">
        <draggable
          @change="checkMove"
          class="lessons__list lessons__list-group"
          handle=".lessons__buttonMove"
          tag="ul"
          v-model="lessons"
        >
          <leadership-lesson-item
            :delete="deleteLesson"
            :key="lesson.id"
            :lesson="lesson"
            @delete-lesson="deleteLesson"
            v-for="lesson in lessons"
          />
        </draggable>
        <base-link :to="link" class="lessons__addLesson">
          <Tr>Добавить урок</Tr>
        </base-link>
      </div>
      <div class="lessons__noLessons" v-else>
        <no-content :noContent="noContent" />
        <base-link :to="link" class="lessons__noLessonsAddLesson">
          <Tr>Добавить урок</Tr>
        </base-link>
      </div>
    </leadership-layout>
    <!-- <loader v-if="pending" /> -->
  </div>
</template>

<script>
import draggable from "vuedraggable";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipLessonItem from "@/components/Leadership/LeadershipLessonItem.vue";
import NoContent from "@/components/NoContent.vue";

import { mapGetters } from "vuex";
import { runErrorNotice } from "@/utils/notifications";

export default {
  name: "LessonsContainer",
  components: {
    LeadershipLayout,
    LeadershipLessonItem,
    NoContent,
    draggable,
  },
  data() {
    return {
      noContent: {
        text: "В этом квесте нет ни одного урока",
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { title: "Настройки лидера", link: "/leadership" },
        ...(this.programId
          ? [
              {
                title: "Программы обучения",
                link: "/leadership/programs/",
              },
              {
                title: this.programName,
                link: `/leadership/programs/${this.programId}/description`,
              },
              {
                title: "Квесты",
                link: `/leadership/programs/${this.programId}/courses${
                  this.programId ? `?programId=${this.programId}` : ""
                }`,
              },
            ]
          : [
              {
                title: "Свои бизнес-квесты",
                link: "/leadership/courses/",
              },
            ]),
      ];
    },
    link() {
      const { courseId } = this.$route.params;
      return `/leadership/courses/${courseId}/lessons/new${
        this.programId ? `?programId=${this.programId}` : ""
      }`;
    },
    tabs() {
      const { courseId } = this.$route.params;
      return [
        {
          title: "Описание",
          link: `/leadership/courses/${courseId}/description${
            this.programId ? `?programId=${this.programId}` : ""
          }`,
        },
        {
          title: "Уроки",
          link: `/leadership/courses/${courseId}/lessons${
            this.programId ? `?programId=${this.programId}` : ""
          }`,
        },
      ];
    },
    programId() {
      const {
        query: { programId },
      } = this.$route;
      if (programId) {
        return programId;
      }
      return undefined;
    },
    lessons: {
      get() {
        return this.$store.getters["leadershipLessons/lessons"];
      },
      set(value) {
        this.$store.dispatch(
          "leadershipLessons/updateLessonPositionMirroring",
          value
        );
      },
    },
    ...mapGetters({
      courseTitle: "leadershipCourse/title",
      isPublished: "leadershipCourse/isPublished",
      pending: "leadershipLessons/pending",
      programName: "leadershipProgram/name",
    }),
  },
  mounted() {
    this.$store.commit("leadershipLessons/CLEAR_STORE");
    const {
      params: { courseId },
      query: { programId },
    } = this.$route;

    this.$store.dispatch("leadershipCourse/fetch", { courseId });
    this.$store.dispatch("leadershipLessons/fetch", { courseId });

    if (programId) {
      this.$store.dispatch("leadershipProgram/fetch", { programId });
    }
  },
  methods: {
    checkMove({ moved }) {
      this.$store.dispatch("leadershipLessons/updateLessonPosition", {
        lessonId: moved.element.id,
        position: moved.newIndex + 1,
      });
    },
    deleteLesson(id) {
      this.$modal.show("dialog", {
        title: this.$t("Вы уверены?"),
        buttons: [
          {
            title: this.$t("Нет"),
            handler: () => {
              this.$modal.hide("dialog");
            },
          },
          {
            title: this.$t("Да"),
            handler: () => {
              this.$store.dispatch("leadershipLessons/deleteLesson", id);
              this.$modal.hide("dialog");
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.lessons__noLessonsAddLesson {
  display: block;
  margin: auto;
  margin-top: 30px;
  padding: 6px 7px;
  width: max-content;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  text-decoration: none;
}

.lessons__addLesson {
  display: block;
  margin-top: 20px;
  padding: 6px 7px;
  width: max-content;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
  text-decoration: none;

  @media (min-width: tablet_large) {
    margin-left: 48px;
  }
}

.lessons__list {
  counter-reset: list;
  display: grid;
  grid-row-gap: 4px;
}
</style>
