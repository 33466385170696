import Cookies from "js-cookie";
import supportedLangs from "../supportedLangs";

export const getUrlLang = () => {
  if (typeof window === "undefined") {
    return;
  }

  const langs = supportedLangs();
  const path = window.location.pathname.split("/");

  for (const lang of langs) {
    if (path.indexOf(lang) !== -1) {
      return lang;
    }
  }
  return "en";
};

export const getLang = () => {
  const cookiesLang = Cookies.get("lang");
  if (cookiesLang) {
    return cookiesLang;
  }
  return getUrlLang();
};
