import Answer from "@/models/answer";
import Question from "@/models/question";
import Test from "@/models/test";
import router from "@/router";
import { getLang } from "@/utils/cookies/getLang";
import { append, omit, pathOr, remove } from "ramda";
import Vue from "vue";
import { runNotice } from "@/utils/notifications";
import i18n from "@/plugins/i18n";
// import supportedLangs from "@/utils/supportedLangs";
// import { pathOr } from "ramda";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
    activeLang: getLang(),
  }),
  getters: {
    questions(state) {
      return pathOr([], ["data", "questions"], state);
    },
    pending(state) {
      return state.pending;
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
    UPDATE_QUESTIONS_LIST(state, value) {
      state.data.questions = value.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
    },
    UPDATE_ANSWERS_LIST(state, { questionIndex, answersList }) {
      state.data.questions[questionIndex].answers = answersList;
    },

    CHANGE_QUESTION_BODY(state, { value, index, lang }) {
      state.data.questions[index].locales[lang].body = value;
    },
    CHANGE_ANSWER_BODY(state, { answerIndex, lang, questionIndex, value }) {
      state.data.questions[questionIndex].answers[answerIndex].locales[
        lang
      ].body = value;
    },
    CHANGE_ANSWER_EXPLANATION(
      state,
      { answerIndex, lang, questionIndex, value }
    ) {
      state.data.questions[questionIndex].answers[answerIndex].locales[
        lang
      ].explanation = value;
    },
    CHANGE_ANSWER_RIGHT(state, { value, questionIndex, answerIndex }) {
      if (!value) {
        state.data.questions[questionIndex].answers[
          answerIndex
        ].is_right = value;
      } else {
        state.data.questions[questionIndex].answers = state.data.questions[
          questionIndex
        ].answers.map((item, index) => {
          return {
            ...item,
            is_right: answerIndex == index ? value : false,
          };
        });
      }
    },
    DELETE_ANSWER(state, { questionIndex, answerIndex }) {
      const answers = state.data.questions[questionIndex].answers;

      state.data.questions[questionIndex].answers = remove(
        answerIndex,
        1,
        answers
      );
    },
    DELETE_QUESTION(state, index) {
      state.data.questions = remove(index, 1, state.data.questions);
    },
    ADD_QUESTION(state) {
      const newQuestion = {
        ...new Question(),
        position: state.data.questions.length + 1,
      };
      state.data.questions = append(newQuestion, state.data.questions);
    },
    ADD_ANSWER(state, { questionIndex }) {
      const newAnswer = {
        ...new Answer(),
        position: state.data.questions[questionIndex].answers.length + 1,
      };
      state.data.questions[questionIndex].answers = append(
        newAnswer,
        state.data.questions[questionIndex].answers
      );
    },
  },
  actions: {
    initNew({ commit }) {
      const data = new Test();
      commit("SET_DATA", data);
    },

    init({ commit }, testObj) {
      const data = new Test(testObj);
      commit("SET_DATA", data);
    },

    async fetch({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(
          `/leadership_quest_task_polls/${id}/`
        );
        if (response.status === 200) {
          commit("SET_DATA", new Test(response.data));
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
        console.info(e);
      }
    },

    saveTest({ state, dispatch }, data) {
      const { id } = state.data;
      const generalData = omit(
        ["type", "approve_reward_brilliant", "approve_reward_expire_brilliant"],
        data.transformLocales()
      );

      if (id) {
        dispatch("patchTest", generalData);
      } else {
        dispatch("postTest", generalData);
      }
    },

    async postTest({ state, commit, rootState, dispatch }, generalData) {
      try {
        dispatch("leadershipTask/dropErrors", undefined, { root: true });
        commit("SET_PENDING", true);
        let data = {
          ...generalData,
          quest_task: rootState.leadershipLesson.data.id,
          quest_task_poll_questions_objects: state.data.getCreateModel(),
        };

        data = omit(["questions"], data);

        const response = await Vue.axios.post(
          "/leadership_quest_task_polls/",
          data
        );
        if (response.status === 201) {
          commit("SET_PENDING", false);
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
          const { courseId, lessonId, lang } = router.currentRoute.params;
          router.push(
            `/${lang}/leadership/courses/${courseId}/lessons/${lessonId}/works?type=video`
          );
        }
      } catch (e) {
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
        commit("SET_PENDING", false);
        console.info(e);
      }
    },

    async patchTest({ state, dispatch, commit }, generalData) {
      const { id } = state.data;
      const data = {
        ...generalData,
        quest_task_poll_questions_objects: state.data.getCreateModel(),
      };

      try {
        dispatch("leadershipTask/dropErrors", undefined, { root: true });
        commit("SET_PENDING", true);
        const response = await Vue.axios.patch(
          `/leadership_quest_task_polls/${id}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          runNotice("success", i18n.t("Сохранено"));
          dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
        commit("SET_PENDING", false);
        console.error(e);
      }
    },

    async deletePoll({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(
          `/leadership_quest_task_polls/${id}/`
        );
        if (response.status === 204) {
          commit("SET_PENDING", false);
          return true;
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
