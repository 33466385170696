<template>
  <div class="mergeContactsGroup">
    <div class="mergeContactsGroup__titleBox">
      <template v-if="mergeType === 'mail'">
        <Tr>Совпадение эл. почты</Tr>
        <span class="mergeContactsGroup__titleInner">{{ group.email }}</span>
      </template>
      <template v-else>
        <Tr>Частичное совпадение телефона</Tr>
        <span class="mergeContactsGroup__titleInner">{{ group.phone }}</span>
      </template>
    </div>
    <div
      class="mergeContactsGroup__body"
      @click="$emit('chooseGroup', group.contacts)"
    >
      <div class="mergeContactsGroup__nameBox">
        <avatar :avatarName="name" :contacts="true" />
        {{ name }}
      </div>
      <span
        class="mergeContactsGroup__text mergeContactsGroup__text_blur"
        v-if="group.contacts.length > 1"
      >
        {{
          $tc("и еще контактов", group.contacts.length - 1, {
            n: group.contacts.length - 1,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";

export default {
  name: "ContactsGroupsItem",

  components: {
    Avatar,
  },

  props: {
    mergeType: {
      type: String,
      default: "mail",
    },

    group: {
      type: Object,
      required: true,
    },
  },

  computed: {
    name() {
      return `${this.group.contacts[0].first_name} ${this.group.contacts[0].last_name}`
    }
  },
};
</script>

<style lang="stylus" scoped>
.mergeContactsGroup {
  max-width: 507px;
  font_regular();

  &__titleBox {
    margin-bottom: 8px;
  }

  &__titleInner {
    font-weight: 700;
  }

  &__body {
    display: grid;
    grid-column-gap: 10px;
    align-items: center;
    padding: 4px 8px;
    background-color: #fff;
    box-shadow: 0px 3px 10px #0F164214;
    border-radius: 4px;
    transition: 0.1s;

    @media (min-width: tablet) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: desktop) {
      &:hover {
        box-shadow: 5px 8px 15px 5px rgba(15, 22, 66, 0.1);
        transform: translate(0px, -3px);
        cursor: pointer;
      }
    }
  }

  &__nameBox {
    position: relative;
    display: grid;
    grid-template-columns: 20px auto;
    grid-column-gap: 8px;
    align-items: center;
    min-height: 24px;
    overflow: hidden;
    cell_fade();

    @media (min-width: tablet) {
      grid-template-columns: 24px auto;
    }
  }

  &__text_blur {
    padding-left: 28px;
    opacity: 0.6;

    @media (min-width: tablet) {
      padding-left: 0;
    }
  }
}
</style>
