<template>
  <div class="video__loading">
    <div class="title">
      <Tr>Видео загружается</Tr>
    </div>
    <div class="loader__container">
      <loader />
    </div>
    <div class="text">
      <Tr>Загрузка может занять какое-то время. Пожалуйста подождите.</Tr>
      <br />
      <br />
      <Tr>
        После завершения страница автоматически обновится, после чего
        потребуется подождать, пока сторонний сервис обрабатывает загруженное
        видео
      </Tr>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "VideoLoadingModal",
  components: {
    Loader,
  },
};
</script>

<style lang="stylus" scoped>
.video {
  &__loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
}

.loader__container {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
}

.text {
  font-size: 14px;
  opacity: 0.8;
  text-align: center;
  max-width: 400px;
}
</style>
