<template>
  <div class="profileHead">
    <!-- <img
      alt="аватар профиля контакта"
      class="avatar"
      src="@/assets/images/avatar@2x.jpg"
    /> -->
    <div class="wrap">
      <avatar :avatarName="contactName" :fontSize="20" />
      <div class="profileInfo">
        <h1 class="profileTitle">
          {{ contactName }}
        </h1>
        <span class="profilePhone" v-if="contactPhone">{{ contactPhone }}</span>
      </div>
    </div>
    <base-del-button @click="delContact" />
  </div>
</template>

<script>
import contactName from "@/mixins/contactName.js";

import Avatar from "@/components/Avatar.vue";

export default {
  name: "ContactsProfileTitle",
  components: {
    Avatar,
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      contactName: "",
      contactPhone: "",
    };
  },

  mixins: [contactName],
  computed: {
    phone() {
      if (this.contact && this.contact.phones_objects && this.contact.phones_objects.length > 0) {
        return this.contact.phones_objects[0].value;
      }
      return "";
    },
  },

  methods: {
    delContact() {
      this.$emit('delContact');
    },
  },

  watch: {
    contact() {
      this.contactName = this.name;
      this.contactPhone = this.phone;
    }
  },
};
</script>

<style lang="stylus" scoped>
.wrap {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 8px;
  width: fit-content;
  align-items: center;
}

.profileHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.profileTitle {
  font(main_text_color, 20px, 700, 24px);
  word-break: break-word;
}

.profilePhone {
  font(main_text_color, 14px, 400, 18px);
  word-break: break-word;
}

@media (min-width: tablet_large) {
  .wrap {
    grid-column-gap: 12px;
  }

  .profileHead {
    margin-bottom: 12px;
  }

  .avatar {
    width: 64px;
    height: 64px;
  }
}
</style>
