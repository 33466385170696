<template>
  <div class="filterSearch__searchWrap">
    <button
      type="button"
      @click="submitByBtn"
      :class="['searchSubmit', { searchSubmit_active: searchInput.length > 0 }]"
    />
    <input
      class="filterSearch__search"
      :placeholder="$t('Имя, телефон, эл.почта, telegram')"
      ref="inputRef"
      :value="searchInput"
      @input="input"
      @keyup.enter="submit"
      name="quick_search"
    />
    <button
      @click="clear"
      class="filterSearch__searchClear"
      v-if="searchInput.length > 0"
    >
      <svg class="filterSearch__searchClearIcon">
        <use xlink:href="@/assets/svg/sprite_filter_menu.svg#inputCross" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "ContactsMenuFilterSearchInput",
  data() {
    return {
      searchInput: "",
    };
  },

  methods: {
    clear() {
      this.searchInput = "";
      this.$emit("search", {
        name: this.$refs.inputRef.name,
        value: "",
      });
      this.$refs.inputRef.focus();
    },
    input(e) {
      const value = e.target.value;
      if (value.length === 0) {
        this.submitByBtn();
      }
      this.searchInput = value;
    },
    emitFunction(value) {
      let emitValue = value;
      emitValue = emitValue.replace("+", "");
      emitValue = emitValue.trim();
      this.$emit('search', {
        name: this.$refs.inputRef.name,
        value: emitValue,
      });
    },
    submit(e) {
      const value = e.target.value;
      this.emitFunction(value);
    },
    submitByBtn() {
      const value = this.$refs.inputRef.value;
      this.emitFunction(value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterSearch__searchWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.filterSearch__searchIcon {
  position: absolute;
  bottom: 7px;
  left: 0px;
  width: 17px;
  height: 17px;
}

.filterSearch__search {
  position: relative;
  margin-bottom: 0px;
  padding: 0;
  padding-left: 23px;
  width: 200px;
  height: 32px;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  font_regular();
}

@media (min-width: phone_s) {
  .filterSearch__search {
    width: 255px;
  }
}

@media (min-width: 390px) {
  .filterSearch__search {
    width: 290px;
  }
}

@media (min-width: phone_xl) {
  .filterSearch__search {
    width: 360px;
  }
}

.filterSearch__search:focus {
  border-bottom: 4px solid #e6e7eb;
}

.filterSearch__search:focus .filterSearch__searchClear {
  display: block;
}

.filterSearch__searchClear {
  position: absolute;
  top: 0;
  bottom: 4px;
  right: 0px;
  margin: auto;
  padding: 0;
  width: 20px;
  height: 20px;
  background: none;
  border: none;

  &Icon {
    width: 100%;
    height: 100%;
  }
}

.searchSubmit {
  position: absolute;
  z-index: 2;
  content: '';
  bottom: 7px;
  left: 0px;
  padding: 0;
  width: 20px;
  height: 20px;
  border: none;
  background-color: unset;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M14.874,13.739l3.44,3.439-1.136,1.136-3.439-3.44a7.229,7.229,0,1,1,1.136-1.136Zm-1.611-.6a5.619,5.619,0,1,0-.12.12l.12-.12Z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  cursor: auto;
  pointer-events: none;

  &_active {
    cursor: pointer;
    pointer-events: all;
    border-radius: 2px 0 0 2px;
    box-shadow: inset 0 0 2px 1px rgba(15, 22, 66, 0.5), 0 0 3px 2px rgba(15, 22, 66, 0.5);

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
