<template>
  <button @click="filterButtonClicked" class="filterSearch__filter">
    <Tr>Фильтр</Tr>
  </button>
</template>

<script>
export default {
  name: "FunnelMenuFilterSearchButton",
  props: {
    isActive: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    filterButtonClicked() {
      this.$emit("button-click", this.isActive);
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterSearch__filter {
  position: relative;
  padding: 0;
  padding-right: 8px;
  padding-left: 23px;
  height: 32px;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  font_regular();

  @media (min-width: desktop) {
    padding-left: 25px;
  }
}

.filterSearch__filter::before {
  position: absolute;
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M18,4V5.667h-.833L13,11.917V19H8V11.917L3.833,5.667H3V4ZM5.837,5.667l3.83,5.745v5.922h1.667V11.412l3.83-5.745Z' transform='translate(-0.5 -0.667)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 110%;
  bottom: 6px;
  left: 2px;
  width: 16px;
  height: 16px;

  @media (min-width: desktop) {
    bottom: 3px;
    left: 0px;
    width: 20px;
    height: 20px;
  }
}

.filterSearch__filter_active {
  border-bottom: 4px solid grey_text_color;
}

.filterSearch__filter_active::before {
  left: 2px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.058' height='17.904' viewBox='0 0 15.058 17.904'%3E%3Cpath id='Path_2666' data-name='Path 2666' d='M6.274.515,18.058,12.3,16.88,13.478l-3.186-3.185L13,11.336v7.083H8V11.336L3.833,5.086H3V3.419H6.821L5.1,1.693ZM8.487,5.086H5.837l3.83,5.745v5.922h1.667V10.831l1.16-1.739ZM18,3.419V5.086h-.833l-1.6,2.393-1.2-1.2.794-1.191H13.178L11.511,3.419Z' transform='translate(-3 -0.515)' fill='%230f1642'/%3E%3C/svg%3E%0A");
  background-size: contain;
}

@media (min-width: desktop) {
  .filterSearch__filter {
    padding-left: 25px;
    text-align: left;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
