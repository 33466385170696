<template>
  <main class="main">
    <BaseBreadcrumbs
      :breadcrumbs="breadcrumbs"
      class="contactsProfileBreadcrumbs"
    />
    <loader v-if="contactsPending" />
    <template v-else>
      <no-content v-if="!contactExists" :noContent="noContent" />
      <div class="contactsProfile__wrap" v-else>
        <div class="contactsProfile__header">
          <div>
            <contacts-profile-title
              :contact="contact"
              @delContact="delContact"
            />
            <TabList :tabs="tabs" :activeTab="activeTab" />
          </div>
          <!-- <base-button /> -->
        </div>
        <div class="contactsProfile__body">
          <slot />
        </div>
      </div>
    </template>
  </main>
</template>

<script>
import BaseBreadcrumbs from "@/components/common/BaseBreadcrumbs.vue";
import ContactsProfileTitle from "@/components/Contacts/ContactsProfileTitle.vue";
import TabList from "@/components/TabList.vue";
import NoContent from "@/components/NoContent.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "ContatcsProfileLayout",
  components: {
    BaseBreadcrumbs,
    ContactsProfileTitle,
    TabList,
    NoContent,
    Loader,
  },

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: String,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    contactsPending: Boolean,
  },

  data() {
    return {
      breadcrumbs: [{ title: "Контакты", link: "/contacts" }],
      noContent: {
        text: "Такого контакта не существует",
        maxWidth: 0,
      },
    };
  },

  computed: {
    contactExists() {
      return this.contacts.find(contact => contact.id == this.$route.params.profileId)
    },
  },

  methods: {
    delContact() {
      this.$emit('delContact');
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsProfileBreadcrumbs {
  margin-bottom: 15px;
}

.contactsProfile__wrap {
  padding: 12px 6px 20px;
  min-height: 424px;
  box-shadow: 0px 3px 10px shadow_color;
  border-radius: 8px;

  @media (min-width: desktop) {
    min-height: 571px;
  }
}

.contactsProfile__body {
  padding-top: 16px;
}

@media (min-width: desktop) {
  .contactsProfile__wrap {
    padding-right: 16px;
    padding-left: 16px;
    width: 692px;
  }
}
</style>
