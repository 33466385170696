<script>
export default {
  name: "LeadershipAwardInput",
  props: {
    title: String,
    name: String,
    value: [String, Number],
    isMentor: [Boolean],
    errors: Array,
  },
  methods: {
    handleInput(e) {
      this.$emit(`handle-${this.$props.name}`, e.target.value);
    },
  },
};
</script>

<template>
  <fieldset>
    <label>
      {{ $t(title) }}
      <div :class="{ mentor: isMentor }" class="inputWrap">
        <input :value="value" @input="handleInput" type="number" />
      </div>
      <div class="inputWrap__error error">
        <div :key="error" class="error__item" v-for="error in errors">
          {{ error }}
        </div>
      </div>
    </label>
  </fieldset>
</template>

<style lang="stylus" scoped>
fieldset {
  margin-top: 20px;
  margin-bottom: 2px;

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

label {
  display: block;
  font_small();
  letter-spacing: -0.17px;
}

label + label {
  margin-top: 12px;
}

.inputWrap {
  position: relative;
  width: 160px;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 2px;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    background-color: #fff;
    background-image: url('../../assets/images/gold@2x.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.mentor:after {
    right: 6px;
    background-image: url('../../assets/images/diamond@2x.png');
  }
}

input {
  display: block;
  margin-top: 4px;
  padding: 5px 6px;
  width: 160px;
  border: 2px solid #ebe6e7;
  border-radius: 2px;
  font_regular();
}

.error {
  &__item {
    color: danger_text_color;
  }
}
</style>
