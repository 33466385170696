<template>
  <div class="filterBody">
    <fieldset class="fieldset">
      <legend class="legend">{{ $t("Линия") }}</legend>
      <button class="button" @click="checkboxesCheck">{{ $t("Все") }}</button>

      <div class="input" v-for="line in lines" :key="line.name">
        <div class="labelWrap">
          <label class="label">
            <input
              class="inputCheckbox"
              name="filter_therm"
              type="checkbox"
              :checked="checkboxesChecked"
            />
            <span class="checkBox" />
            <span class="lineText">{{ line.name }}</span>
          </label>
          <div class="team__progressLine">
            <div class="team__progressLineInner" :style="line.width" />
          </div>
          <span class="team__progressLineNum">{{ line.num }}</span>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "TeamFilterBody",
  data() {
    return {
      lines: [
        { name: "Первая", width: "width: 20%", num: 12 },
        { name: "2-я", width: "width: 30%", num: 23 },
        { name: "3-я", width: "width: 50%", num: 45 },
        { name: "4-я", width: "width: 75%", num: 65 },
        { name: "5-я", width: "width: 5%", num: 2 },
        { name: "6-я", width: "width: 10%", num: 4 },
        { name: "7-я", width: "width: 5%", num: 1 },
        { name: "8-я", width: "width: 10%", num: 2 },
        { name: "9-я", width: "width: 10%", num: 4 },
        { name: "10-я", width: "width: 5%", num: 2 },
      ],
      checkboxesChecked: false,
    };
  },
  methods: {
    checkboxesCheck() {
      this.checkboxesChecked = !this.checkboxesChecked;
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterBody {
  position: relative;
  padding: 20px;
  margin-top: 7px;
  width: max-content;
  border: 1px solid grey_text_color;
  border-radius: 4px;
}

.fieldset {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.button {
  position: absolute;
  top: 20px;
  right: 20px;
  letter-spacing: 0.4px;
  line-height: 17px;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 1px dotted secondary_text_color;
  color: secondary_text_color;
}

.legend {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.input+.input {
  margin-top: 6px;
}

.label {
  position: relative;
  padding-left: 23px;
  height: 16px;
}

.checkBox {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  margin: auto;
  width: 16px;
  height: 16px;
  border: 2px solid grey_text_color;
  border-radius: 2px;
}

.inputCheckbox:checked + .checkBox {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Crect width='8' height='8' rx='2 ' fill='%230f1642'/%3E%3C/svg%3E");
  background-size: 70%;
  background-position-x: 2px;
  background-position-y: 2px;
  background-repeat: no-repeat;
}

.inputCheckbox {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.labelTxt {
  width: 64px;
  height: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  vertical-align: text-top;
}

.lineText {
  display: inline-block;
  min-width: 55px;
  font(main_text_color, 14px, 400, 19px);
}

.team__progressLine {
  display: inline-block;
  margin: 0 0 0 12px;
  width: 80px;
  height: 4px;
  border-radius: 2px;
  background-color: grey_text_color;
  vertical-align: middle;

  &Inner {
    height: 100%;
    background-color: main_text_color;
    border-radius: 2px;
  }
}

.team__progressLineNum {
  font(main_text_color, 10px, 700, 14px);
}

@media(min-width: tablet) {
  .filterBody {
    margin-bottom: 20px;
    padding: 15px 68px 20px 20px;
  }
  .button {
    top: 15px;
    right: 68px;
  }
}
</style>
