<template>
  <div class="funnel__menu">
    <updated-funnel-menu-filter-search
      :funnels="funnels"
      :tags="tags"
      :orders="orders"
      :filters="filters"
      :isFilterChecked="isFilterChecked"
      :pickedAllFunnels="pickedAllFunnels"
      :pickedAllTags="pickedAllTags"
      :pickedAllOrders="pickedAllOrders"
      :pickedAllStatuses="pickedAllStatuses"
      :funnelsFilterValue="funnelsFilterValue"
      :funnelsFilterCounter="funnelsFilterCounter"
      :tagsFilterValue="tagsFilterValue"
      :tagsFilterCounter="tagsFilterCounter"
      :ordersFilterValue="ordersFilterValue"
      :ordersFilterCounter="ordersFilterCounter"
      :statusesFilterValue="statusesFilterValue"
      :statusesFilterCounter="statusesFilterCounter"
      :statusesFilter="statusesFilter"
      :statuses="statuses"
      :search="search"
      :filtersIsActive="filtersIsActive"
      @changeFilter="changeFilter"
      @pickAllFunnels="pickAllFunnels"
      @pickAllTags="pickAllTags"
      @pickAllOrders="pickAllOrders"
      @pickAllStatuses="pickAllStatuses"
      @clearFilters="clearFilters"
      @changeFunnelsFilterValue="changeFunnelsFilterValue"
      @changeFunnelsFilterCounter="changeFunnelsFilterCounter"
      @changeTagsFilterValue="changeTagsFilterValue"
      @changeTagsFilterCounter="changeTagsFilterCounter"
      @changeOrdersFilterValue="changeOrdersFilterValue"
      @changeOrdersFilterCounter="changeOrdersFilterCounter"
      @changeStatusesFilterValue="changeStatusesFilterValue"
      @changeStatusesFilterCounter="changeStatusesFilterCounter"
      @change-search="changeSearch"
      @handleFilter="handleFilter"
    />
    <FormSubmit
      v-if="isUserOP"
      :text="$t('История звонков')"
      class="callsHistory"
      @click="openCallsHistory"
    />
    <div class="loader__layout" v-if="pending">
      <loader />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import UpdatedFunnelMenuFilterSearch from "@/components/Funnel/UpdatedFunnelMenuFilterSearch.vue";
import Loader from "@/components/Loader.vue";
import FormSubmit from "@/components/FormSubmit.vue";

export default {
  name: "UpdatedLeadsMenu",
  components: {
    UpdatedFunnelMenuFilterSearch,
    Loader,
    FormSubmit,
  },
  props: {
    pending: Boolean,

    funnels: {
      type: Object,
    },
    tags: {
      type: Object,
    },
    orders: {
      type: Object,
    },
    filters: {
      type: Object,
    },
    isFilterChecked: {
      type: Function,
    },
    pickedAllFunnels: {
      type: Boolean,
    },
    pickedAllTags: {
      type: Boolean,
    },
    pickedAllOrders: {
      type: Boolean,
    },
    pickedAllStatuses: {
      type: Boolean,
    },
    funnelsFilterValue: {
      type: [String, Number],
    },
    funnelsFilterCounter: {
      type: Number,
    },
    tagsFilterValue: {
      type: [String, Number],
    },
    tagsFilterCounter: {
      type: Number,
    },
    ordersFilterValue: {
      type: [String, Number],
    },
    ordersFilterCounter: {
      type: Number,
    },
    statusesFilterValue: {
      type: [String, Number],
    },
    statusesFilterCounter: {
      type: Number,
    },
    statusesFilter: Boolean,
    statuses: {
      type: Array,
    },
    search: {
      type: String,
      required: true,
    },
    filtersIsActive: Boolean,
    leads: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      isUserOP: "user/showUpdatedLeads",
      isDev: "user/isDev",
    }),
  },

  methods: {
    changeFilter(payload) {
      this.$emit("changeFilter", payload);
    },
    pickAllFunnels(payload) {
      this.$emit("pickAllFunnels", payload);
    },
    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },
    pickAllOrders(payload) {
      this.$emit("pickAllOrders", payload);
    },
    pickAllManagers(payload) {
      this.$emit("pickAllManagers", payload);
    },
    pickAllStatuses(payload) {
      this.$emit("pickAllStatuses", payload);
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    changeFunnelsFilterValue(value) {
      this.$emit("changeFunnelsFilterValue", value);
    },
    changeFunnelsFilterCounter(value) {
      this.$emit("changeFunnelsFilterCounter", value);
    },
    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },
    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
    changeOrdersFilterValue(value) {
      this.$emit("changeOrdersFilterValue", value);
    },
    changeOrdersFilterCounter(value) {
      this.$emit("changeOrdersFilterCounter", value);
    },
    changeManagersFilterValue(value) {
      this.$emit("changeManagersFilterValue", value);
    },
    changeManagersFilterCounter(value) {
      this.$emit("changeManagersFilterCounter", value);
    },
    changeStatusesFilterValue(value) {
      this.$emit("changeStatusesFilterValue", value);
    },
    changeStatusesFilterCounter(value) {
      this.$emit("changeStatusesFilterCounter", value);
    },
    changeSearch(value) {
      this.$emit("change-search", value);
    },
    handleFilter() {
      this.$emit("handleFilter");
    },

    openCallsHistory() {
      this.$modal.show("calls-history", { leads: this.leads });
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnel__menu {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.funnel__viewButtonsWrap {
  display: flex;
}

.funnel__viewButton {
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 1px solid grey_text_color;
  border-radius: 2px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M8.833,2a.834.834,0,0,1,.833.833V6.167A.834.834,0,0,1,8.833,7H7.167V8.667h4.167V7.833A.834.834,0,0,1,12.167,7h5A.834.834,0,0,1,18,7.833v3.333a.834.834,0,0,1-.833.833h-5a.834.834,0,0,1-.833-.833v-.833H7.167v5h4.167V14.5a.834.834,0,0,1,.833-.833h5A.834.834,0,0,1,18,14.5v3.333a.834.834,0,0,1-.833.833h-5a.834.834,0,0,1-.833-.833V17h-5a.834.834,0,0,1-.833-.833V7H3.833A.834.834,0,0,1,3,6.167V2.833A.834.834,0,0,1,3.833,2Zm7.5,13.333H13V17h3.333Zm0-6.667H13v1.667h3.333ZM8,3.667H4.667V5.333H8Z' transform='translate(-0.5 -0.333)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;

  &:first-child {
    border-radius: 2px 0px 0px 2px;
    border: none;
    background-color: main_text_color;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%23fff;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M7.167,3.917H18V5.583H7.167ZM3,3.5H5.5V6H3ZM3,9.333H5.5v2.5H3Zm0,5.833H5.5v2.5H3ZM7.167,9.75H18v1.667H7.167Zm0,5.833H18V17.25H7.167Z' transform='translate(-0.5 -0.583)'/%3E%3C/svg%3E");
  }
}

.loader {
  &__box {
    position: relative;
  }

  &__layout {
    position: absolute;
    z-index: 5;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
  }
}

@media (min-width: desktop) {
  .funnel__menu {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.assignmentTopButtons {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  margin-bottom: 6px;

  &__pickManager {
    width: fit-content;
    cursor: pointer;

    &[disabled] {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  @media (min-width: tablet) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin-bottom: 0;
  }
}

.callsHistory {
  align-self: baseline;
  margin-top: 0;
  margin-bottom: 20px;
  padding: 7px 8px;
  width: fit-content;

  @media (min-width: desktop) {
    margin-bottom: 0;
  }
}
</style>
