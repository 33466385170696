import Vue from "vue";

const requireComponent = require.context(
  "../components/common",
  false,
  /[A-Z]\w+\.(vue|js)$/
);

const globalComponents = () =>
  requireComponent.keys().forEach((fileName: any) => {
    const componentConfig = requireComponent(fileName);
    const componentName = fileName
      .split("/")
      .pop()
      .replace(/\.\w+$/, "");

    Vue.component(componentName, componentConfig.default || componentConfig);
  });

export default globalComponents;
