import { getUrlLang } from "./cookies/getLang";

const axiosConfigOtonVideoUploader = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      ...(getUrlLang() ? { "accept-language": getUrlLang() } : {}),
    },
    baseURL: process.env.VUE_APP_OTON_VIDEO_URL,
    Accept: "application/json",
  };
};

export default axiosConfigOtonVideoUploader;
