<template>
  <span :key="randomKey">
    {{ phrase }}
  </span>
</template>

<script>
export default {
  name: "Tr",
  props: {
    text: String,
  },
  computed: {
    phrase() {
      return this.$t(this.$slots.default[0].text);
    },

    randomKey() {
      return Math.random()
        .toString(36)
        .substring(7);
    },
  },
};
</script>
