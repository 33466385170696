import Vue from "vue";
import { map, omit, path, clone, mergeDeepWith, concat, equals } from "ramda";

export default {
  namespaced: true,

  state: () => ({
    mailDuplicates: {},
    phoneDuplicates: {},
    chosenContactsGroup: [],
    chosenContactsGroupIds: [],
    createdContact: {},
    errors: {},
    pendingMailDuplicates: false,
    pendingPhoneDuplicates: false,
    pendingMerge: false,
  }),

  getters: {
    createdContactView(state) {
      let obj: any = {};

      Object.keys(state.createdContact).forEach(contact => {
        obj = mergeDeepWith(concat, { ...obj }, state.createdContact[contact]);
      });

      if (
        Object.prototype.hasOwnProperty.call(obj, "phones_objects") &&
        obj.phones_objects.length > 0
      ) {
        const phonesObjects = clone(obj.phones_objects);

        phonesObjects.forEach((phone: any) => {
          delete phone.id;
        });
        obj.phones_objects = phonesObjects;
      }
      if (
        Object.prototype.hasOwnProperty.call(obj, "emails_objects") &&
        obj.emails_objects.length > 0
      ) {
        const emailsObjects = clone(obj.emails_objects);

        emailsObjects.forEach((email: any) => {
          delete email.id;
        });
        obj.emails_objects = emailsObjects;
      }

      if (
        Object.prototype.hasOwnProperty.call(obj, "links_objects") &&
        obj.links_objects.length > 0
      ) {
        const linksObjects = clone(obj.links_objects);

        linksObjects.forEach((link: any) => {
          delete link.id;
          delete link.site;
        });
        obj.links_objects = linksObjects;
      }

      if (
        Object.prototype.hasOwnProperty.call(obj, "tag_objects") &&
        obj.tag_objects.length > 0
      ) {
        const uniqueTags = {};

        obj.tag_objects.forEach(tag => {
          uniqueTags[tag.id] = tag;
        });

        obj.tag_objects = [];
        Object.values(uniqueTags).forEach(tag => obj.tag_objects.push(tag));
      }
      return obj;
    },

    isCheckedField: state => (contactId, nameField, value) => {
      return Object.keys(state.createdContact)
        .map(cId => {
          return (
            state.createdContact[cId][nameField] === value &&
            contactId === Number(cId)
          );
        })
        .find(el => el === true);
    },

    isCheckedObject: state => (contactId, nameField, object) => {
      const created = state.createdContact[contactId];
      const isObjectExist = created && created[nameField];

      if (isObjectExist) {
        const objectsArray = created[nameField];

        return objectsArray.find(({ id }) => id === object.id);
      }
      return false;
    },

    isCheckedTagsObject: state => (contactId, nameField, array) => {
      const innerContact = state.createdContact[contactId];
      const objectExists = innerContact && innerContact[nameField];

      if (objectExists) {
        const objectsArray = innerContact[nameField];

        return equals(objectsArray, array);
      }
      return false;
    },
  },

  mutations: {
    SET_MAIL_DUPLICATES(state, data) {
      state.mailDuplicates = data.results;
    },

    SET_PHONE_DUPLICATES(state, data) {
      state.phoneDuplicates = data.results;
    },

    SET_PENDING_MAIL_DUPLICATES(state, status) {
      state.pendingMailDuplicates = status;
    },

    SET_PENDING_PHONE_DUPLICATES(state, status) {
      state.pendingPhoneDuplicates = status;
    },

    SET_PENDING_MERGE(state, status) {
      state.pendingMerge = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    SET_CHOSEN_CONTACTS_GROUP(state, data) {
      state.chosenContactsGroup = [...data];
    },

    SET_CHOSEN_CONTACTS_GROUP_IDS(state, data) {
      state.chosenContactsGroupIds = [...state.chosenContactsGroupIds, ...data];
    },

    DEL_CHOSEN_CONTACT(state, contact) {
      state.chosenContactsGroupIds = state.chosenContactsGroupIds.filter(
        el => el !== contact.id
      );
      state.chosenContactsGroup = state.chosenContactsGroup.filter(
        el => el !== contact
      );

      const contactId = String(contact.id);

      state.createdContact = omit([contactId], state.createdContact);
    },

    SET_CLEAR_CHOSEN_CONTACTS_GROUP(state) {
      state.chosenContactsGroup = [];
    },

    SET_CLEAR_CHOSEN_CONTACTS_GROUP_IDS(state) {
      state.chosenContactsGroupIds = [];
    },

    SET_CREATED_CONTACT_FIELD(state, { contactId, nameField, value }) {
      const isRemoveAction = !!path(
        [contactId, nameField],
        state.createdContact
      );

      const removeNameField = omit([nameField]);
      state.createdContact = map(removeNameField, state.createdContact);

      if (isRemoveAction) {
        return;
      }

      state.createdContact = {
        ...state.createdContact,
        [contactId]: {
          ...(state.createdContact[contactId] || {}),
          [nameField]: value,
        },
      };
    },

    TOGGLE_CREATED_CONTACT_OBJECTS(state, { contactId, nameField, object }) {
      const newCreatedContact = clone(state.createdContact);

      let isSelected;

      if (
        !newCreatedContact[contactId] ||
        !newCreatedContact[contactId][nameField]
      ) {
        isSelected = false;
      } else {
        isSelected = newCreatedContact[contactId][nameField].find(
          ({ id }) => id === object.id
        );
      }

      if (isSelected) {
        newCreatedContact[contactId][nameField] = newCreatedContact[contactId][
          nameField
        ].filter(({ id }) => id !== object.id);
      } else {
        if (
          !Object.prototype.hasOwnProperty.call(newCreatedContact, contactId)
        ) {
          newCreatedContact[contactId] = {};
        }

        if (
          !Object.prototype.hasOwnProperty.call(
            newCreatedContact[contactId],
            nameField
          )
        ) {
          newCreatedContact[contactId][nameField] = [];
        }

        let nameFieldsLength = 0;
        Object.keys(newCreatedContact).forEach(contact => {
          if (newCreatedContact[contact][nameField]) {
            nameFieldsLength =
              nameFieldsLength + newCreatedContact[contact][nameField].length;
          }
        });

        if (nameFieldsLength < 5) {
          newCreatedContact[contactId][nameField].push(object);
        }
      }

      state.createdContact = newCreatedContact;
    },

    TOGGLE_CREATED_CONTACT_TAGS(state, { contactId, nameField, array }) {
      const newCreatedContact = clone(state.createdContact);
      const tagsIds = array.map(tag => tag.id);

      let isSelected;

      if (
        !newCreatedContact[contactId] ||
        !newCreatedContact[contactId][nameField]
      ) {
        isSelected = false;
      } else {
        const createdTagsArray = newCreatedContact[contactId][nameField];
        const createdTagsIds = createdTagsArray.map(tag => tag.id);

        isSelected = equals(tagsIds, createdTagsIds);
      }

      if (isSelected) {
        tagsIds.forEach(tagId => {
          newCreatedContact[contactId][nameField] = newCreatedContact[
            contactId
          ][nameField].filter(createdtag => createdtag.id !== tagId);
        });
      } else {
        if (
          !Object.prototype.hasOwnProperty.call(newCreatedContact, contactId)
        ) {
          newCreatedContact[contactId] = {};
        }

        if (
          !Object.prototype.hasOwnProperty.call(
            newCreatedContact[contactId],
            nameField
          )
        ) {
          newCreatedContact[contactId][nameField] = [];
        }

        array.forEach(tag => {
          newCreatedContact[contactId][nameField].push(tag);
        });
      }

      state.createdContact = newCreatedContact;
    },

    SET_CLEAR_CREATED_CONTACT(state) {
      state.createdContact = {};
    },
  },

  actions: {
    async fetchMailDuplicates({ commit }) {
      try {
        commit("SET_PENDING_MAIL_DUPLICATES", true);

        const response = await Vue.axios.get(`/contacts/email_duplicates/`);

        if (response.status === 200) {
          commit("SET_MAIL_DUPLICATES", response.data);
          commit("SET_PENDING_MAIL_DUPLICATES", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_MAIL_DUPLICATES", false);
      }
    },

    async fetchPhoneDuplicates({ commit }) {
      try {
        commit("SET_PENDING_PHONE_DUPLICATES", true);

        const response = await Vue.axios.get(`/contacts/phone_duplicates/`);

        if (response.status === 200) {
          commit("SET_PHONE_DUPLICATES", response.data);
          commit("SET_PENDING_PHONE_DUPLICATES", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_PHONE_DUPLICATES", false);
      }
    },

    async mergeContacts({ commit, state, getters }) {
      if (state.pendingMerge) {
        return;
      }
      try {
        commit("SET_PENDING_MERGE", true);

        const contactData = clone(getters.createdContactView);
        contactData.source_name = "union";

        const responseCreateContact = await Vue.axios.post(
          `/contacts/`,
          contactData
        );

        if (responseCreateContact.status === 201) {
          try {
            const responseMergeContacts = await Vue.axios.patch(
              `/contacts/${responseCreateContact.data.id}/union/bulk/`,
              {
                union_from: state.chosenContactsGroupIds,
              }
            );
            if (responseMergeContacts.status === 200) {
              commit("SET_PENDING_MERGE", false);
              return { responseCreateContact, responseMergeContacts };
            }
          } catch (e) {
            commit("SET_PENDING_MERGE", false);
          }
        } else {
          throw responseCreateContact.data.message;
        }
      } catch (e) {
        commit("SET_PENDING_MERGE", false);
      }
    },
  },
};
