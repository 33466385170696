<template>
  <div class="techWorks">
    <div class="base-wrapper">
      <div class="base-container">
        <header class="header">
          <header-logo class="base-logo" />
        </header>
        <div class="base-content">
          <div class="techWorks_1">Technical works. Come back later</div>
          <div class="techWorks_2">Технические работы. Зайдите попозже</div>
          <!-- <div class="techWorks_3">
            Technische Arbeiten. Später wiederkommen
          </div>
          <div class="techWorks_4">Teknik işler. Daha sonra gel</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Header/HeaderLogo.vue";

export default {
  name: "TechWorksPlaceholder",
  components: {
    HeaderLogo,
  },
};
</script>

<style lang="stylus" scoped>
.base-wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $colorSecond;
}

.base-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  z-index: 102;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #0f1642;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 8px 20px 0 #0f164252;
  padding: 0px 8px 0px 11px;
  height: 40px;
  min-height: unset;
  max-height: unset;

  @media (min-width: tablet_large) {
    padding: 5px 20px;
    height: auto;
    min-height: 65px;
    max-height: 65px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.header::before {
  position: absolute;
  content: '';
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: transparent linear-gradient(180deg, #ffffff 0%, #ffffff00 100%) 0%;
  opacity: 0.2;
}

.base-content {
  margin-top: 20px;
  padding-left: 15px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: tablet_large) {
    margin-top: 70px;
    padding-left: 40px;
  }
}

.techWorks {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;

  &_1 {
  }

  &_2 {
    opacity: 0.6;
  }

  &_3 {
    opacity: 0.4;
  }

  &_4 {
    opacity: 0.2;
  }
}
</style>
