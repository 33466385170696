<template>
  <modal
    name="calls-history"
    :width="700"
    :min-height="200"
    height="584px"
    :shiftY="0.2"
    :adaptive="true"
    @before-open="beforeOpen"
  >
    <div class="leadsCallsHistory">
      <!-- HEADER -->
      <div class="leadsCallsHistory__header">
        <div class="leadsCallsHistory__headerName">
          <Tr>История звонков</Tr>
        </div>
        <button
          class="leadsCallsHistory__headerButton"
          type="button"
          @click="hideCallsHistory"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <div class="pagination" v-if="amountPages > 1">
        <button
          v-for="n in amountPages"
          :key="n"
          @click="fetchPage(n)"
          class="pagination__button"
          :class="{ pagination__button_active: n === currentPage }"
        >
          {{ n }}
        </button>
      </div>

      <!-- BODY -->
      <div class="leadsCallsHistory__body">
        <loader v-if="pending" />
        <template v-else>
          <template v-if="!!calls.length">
            <LeadsModalCallsHistoryTableHead
              class="leadsCallsHistory__box"
              @change-sort="changeSort"
            />
            <LeadsModalCallsHistoryTableItem
              class="leadsCallsHistory__box"
              v-for="call in calls"
              :key="call.id"
              :call="call"
            />
          </template>
          <no-content
            v-else
            :noContent="noContent"
            class="leadsCallsHistory__noContent"
          />
        </template>
      </div>
      <!-- BODY -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import LeadsModalCallsHistoryTableHead from "@/components/Leads/LeadsModalCallsHistoryTableHead.vue";
import LeadsModalCallsHistoryTableItem from "@/components/Leads/LeadsModalCallsHistoryTableItem.vue";
import NoContent from "@/components/NoContent.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "LeadsModalCallsHistory",
  components: {
    CloseButton,
    LeadsModalCallsHistoryTableHead,
    LeadsModalCallsHistoryTableItem,
    NoContent,
    Loader,
  },
  data() {
    return {
      noContent: {
        text: "Пусто. Пока нет ни одного звонка",
      },
    };
  },
  computed: {
    ...mapGetters({
      calls: "leadsCalls/calls",
      pending: "leadsCalls/pending",
      currentPage: "leadsCalls/currentPage",
      amountPages: "leadsCalls/amountPages",
    }),
  },
  methods: {
    beforeOpen(event) {
      this.$store.dispatch("leadsCalls/fetch", { pageNum: 1 });
    },
    hideCallsHistory() {
      this.$modal.hide("calls-history");
    },
    changeSort(sortType) {
      Promise.all([this.$store.dispatch("leadsCalls/changeSort", sortType)])
        .then(() => {
          this.$store.dispatch("leadsCalls/fetch", { pageNum: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    fetchPage(n) {
      this.$store.dispatch("leadsCalls/fetch", { pageNum: n });
    },
  },
};
</script>

<style lang="stylus" scoped>
.pagination {
  margin-bottom: 10px;

  .pagination__button {
    padding: 2px 8px;
    font_bold();
    color: main_text_color;
    background-color: unset;
    border: none;
    border-bottom: 2px solid transparent;

    &_active {
      color: orange;
      border-bottom: 2px solid orange;
    }
  }
}

.leadsCallsHistory {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  height: 100%;

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    width: 700px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    margin-bottom: 10px;
    margin-left: -10px;
    padding-right: 10px;
    padding-bottom: 7px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    right: 6px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: grey_text_color #fff;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey_text_color;
      border: 2px solid #fff;
      border-radius: 6px;
    }
  }

  &__box {
    display: grid;
    grid-template-columns: 20px 100px 120px 120px 120px;
    grid-column-gap: 4px;
    margin-bottom: 8px;

    @media (min-width: tablet) {
      grid-template-columns: 24px 120px 141px 160px 160px;
      grid-column-gap: 8px;
    }
  }

  &__noContent {
    margin-bottom: 50px;
  }
}
</style>
