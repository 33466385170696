import Program from "@/models/program";
import i18n from "@/plugins/i18n";
import router from "@/router";
import { getLang } from "@/utils/cookies/getLang";
import { errorMiddlewareNotice, runNotice } from "@/utils/notifications";
import supportedLangs from "@/utils/supportedLangs";
import { append, pathOr } from "ramda";
import Vue from "vue";

const transformResponseData = data => new Program(data);

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
    activeLang: getLang(),
    errors: {},
  }),

  getters: {
    program(state) {
      return state.data;
    },
    pending(state) {
      return state.pending;
    },
    errors(state) {
      return state.errors;
    },
    theNextPage(state) {
      return pathOr(0, ["data", "next_page_number"], state);
    },
    lang(state) {
      return state.activeLang;
    },
    name(state) {
      return pathOr("", ["data", "locales", state.activeLang, "name"], state);
    },
    description(state) {
      return pathOr(
        "",
        ["data", "locales", state.activeLang, "short_description"],
        state
      );
    },
    isLangValid: state => lang => {
      if (!state.data.locales) {
        return false;
      }
      return (
        !!state.data.locales[lang].name &&
        !!state.data.locales[lang].short_description
      );
    },
    langTabs(state, getters) {
      return supportedLangs().map(lang => ({
        title: lang,
        isValid: getters.isLangValid(lang),
      }));
    },
    isPriceValid(state) {
      if (state.data.payment_type === "paid" && !state.data.cost) {
        return false;
      }
      return true;
    },
    cost(state) {
      return pathOr({}, ["data", "cost"], state);
    },
    isPublished(state) {
      if (pathOr(false, ["data", "published_at"], state)) {
        return true;
      }
      return false;
    },
    paymentType(state) {
      return pathOr("free", ["data", "payment_type"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
    TOGGLE_PROGRAM_LANG(state, lang) {
      if (state.data.languages.indexOf(lang) === -1) {
        state.data.languages = append(lang, state.data.languages);
      } else {
        state.data.languages = state.data.languages.filter(
          item => item !== lang
        );
      }
    },
    CHANGE_NAME(state, value) {
      state.data.locales[state.activeLang].name = value;
    },
    CHANGE_DESCRIPTION(state, value) {
      state.data.locales[state.activeLang].short_description = value;
    },
    TOGGLE_COST(state, value) {
      state.data.payment_type = value;
    },
    CHANGE_COST(state, value) {
      state.data.cost = value;
    },
  },
  actions: {
    init({ commit }) {
      commit("SET_DATA", new Program());
    },

    async fetch({ commit, state }, { programId }) {
      if (programId == state.data.id) {
        // return;
      }
      try {
        commit("SET_ERRORS", {});
        commit("SET_PENDING", true);
        commit("CLEAR_DATA");
        const response = await Vue.axios.get(
          `/leadership_quest_courses/${programId}/`
        );
        if (response.status === 200) {
          commit("SET_DATA", transformResponseData(response.data));
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async create({ commit, state, getters }) {
      if (!getters.isPriceValid) {
        runNotice("error", i18n.t("Не заполнена цена"));
        return;
      }
      const data = state.data.getCreateModel();

      try {
        commit("SET_ERRORS", {});
        commit("SET_PENDING", true);
        const lang = i18n.locale;
        const response = await Vue.axios.post(
          `/leadership_quest_courses/`,
          data
        );

        if (response.status === 201) {
          commit("SET_PENDING", false);
          const id = response.data.id;

          router.push(`/${lang}/leadership/programs/${id}/description`);
        } else {
          throw response.data;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async update({ commit, state, rootState, getters }) {
      commit("SET_PENDING", true);
      commit("SET_ERRORS", {});
      try {
        const data = state.data.getUpdateModel();
        const response = await Vue.axios.patch(
          `/leadership_quest_courses/${state.data.id}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("CLEAR_DATA");
          setTimeout(
            () => commit("SET_DATA", transformResponseData(response.data)),
            100
          );
          runNotice("success", i18n.t("Программа успешно обновлена"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async publish({ commit, state }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_ERRORS", {});

        // const data = { published_at: new Date() };
        const response = await Vue.axios.patch(
          `/leadership_quest_courses/${state.data.id}/publish/`
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("PUBLISH_COURSE", response.data.published_at);
          commit("SET_DATA", transformResponseData(response.data.quest_course));
          runNotice("success", i18n.t("Программа успешно опубликована"));
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
        errorMiddlewareNotice(e.response.data);
      }
    },
  },
};
