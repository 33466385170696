<template>
  <main class="main">
    <h1 class="title">
      <Tr>Воронка</Tr>
    </h1>
    <updated-funnel-container />
  </main>
</template>
<script>
import UpdatedFunnelContainer from "@/containers/Funnel/UpdatedFunnelContainer.vue";

export default {
  name: "UpdatedFunnel",
  components: {
    UpdatedFunnelContainer,
  },
  beforeDestroy() {
    this.$modal.hide("updated-funnel-lead");
  },
};
</script>

<style lang="stylus" scoped>
.main {
  width: calc(100vw - 16px);

  @media (min-width: 768px) {
    width: calc(100vw - 31px);
  }

  @media (min-width: tablet_large) {
    width: auto;
  }
}
</style>
