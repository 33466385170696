<script>
export default {
  name: "LeadershipSettings",
  data() {
    return {};
  },
  props: {
    leadershipSettings: Array,
  },
};
</script>

<template>
  <div class="settings__wrap">
    <article
      :key="item.title"
      class="settings__item"
      v-for="item in leadershipSettings"
    >
      <base-link :to="item.link" class="settings__itemLink" />
      <h2 class="settings__itemTitle">
        <Tr>{{ item.title }}</Tr>
      </h2>
      <p class="settings__itemText">
        <span>
          <Tr>{{ item.text }}</Tr>
        </span>
      </p>
    </article>
  </div>
</template>

<style lang="stylus" scoped>
.settings__wrap {
  display: grid;
  grid-row-gap: 16px;
  padding-top: 3px;
  @media (min-width: desktop) {
    grid-template-columns: repeat(2, 456px);
    grid-column-gap: 16px;
  }
}

.settings__item {
  position: relative;
  padding: 10px;
  width: 100%;
  max-width: 456px;
  min-height: 144px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0f164214;
  @media (min-width: phone_xl) {
    padding: 20px;
  }
  @media (min-width: desktop) {
    padding: 12px 16px 18px;
    width: auto;
    max-width: unset;
  }
}

.settings__itemLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.settings__itemTitle {
  padding-bottom: 12px;
  border-bottom: 2px solid #e6e7eb;
  font_subtitle();
  @media (min-width: tablet_large) {
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.settings__itemText {
  padding-top: 10px;
  font_regular();
  line-height: 20px;
  letter-spacing: -0.1px;
  @media (min-width: tablet_large) {
    padding-top: 16px;
  }
}
</style>
