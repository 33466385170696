<template>
  <main class="main" v-if="showUpdatedLeads">
    <h1 class="title">
      <Tr>Лиды</Tr>
    </h1>
    <updated-leads-container />
  </main>
</template>
<script>
import { mapGetters } from "vuex";

import UpdatedLeadsContainer from "@/containers/Leads/UpdatedLeadsContainer.vue";

export default {
  name: "UpdatedLeads",
  components: {
    UpdatedLeadsContainer,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      showLeads: "user/showLeads",
      showUpdatedLeads: "user/showUpdatedLeads",
    }),
  },
  mounted() {
    if (!this.showUpdatedLeads) {
      this.$router.replace({ name: "UpdatedFunnel" });
    }
  },
  beforeDestroy() {
    this.$modal.hide("updated-funnel-lead");
  },
};
</script>

<style lang="stylus" scoped>
.main {
  width: calc(100vw - 16px);

  @media (min-width: 768px) {
    width: auto;
  }
}
</style>
