<template>
  <modal-layout
    :pending="pending"
    :name="modalName"
    @before-close="closeModal"
    modalHeight="584px"
  >
    <template #title>Добавить контакт</template>
    <template #body>
      <div class="contactsProfile__header">
        <div>
          <TabList :tabs="tabs" :activeTab="activeTab" />
        </div>
        <!-- <base-button /> -->
      </div>
      <div class="contactsProfile__body">
        <div class="underLoader">
          <div class="loaderIcon" v-if="pending">
            <loader />
          </div>
          <div class="contactsProfileInfo">
            <div class="contactsProfileInfoInner">
              <!-- NAME AND MESSENGERS -->
              <div class="contactsProfileInfoBox">
                <fieldset
                  class="
                    contactsProfileInfo__fieldset
                    contactsProfileInfo__fieldsetName
                  "
                >
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="Имя"
                    :value="contact.first_name"
                    labelId="first_name"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                  />
                  <div class="error" v-if="errors && errors.first_name">
                    {{ errors.first_name[0] }}
                  </div>
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="Фамилия"
                    :value="contact.last_name"
                    labelId="last_name"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                  />
                  <div class="error" v-if="errors && errors.last_name">
                    {{ errors.last_name[0] }}
                  </div>
                </fieldset>

                <fieldset
                  class="
                    contactsProfileInfo__fieldset
                    contactsProfileInfo__fieldsetSocials
                  "
                >
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="Telegram"
                    :value="contact.telegram"
                    labelId="telegram"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                    :placeholder="placeholderTelegram"
                  />
                  <div class="error" v-if="errors && errors.telegram">
                    {{ errors.telegram[0] }}
                  </div>
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="WhatsApp"
                    :value="contact.whatsapp"
                    labelId="whatsapp"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                    :placeholder="placeholderOnlyDigit"
                    type="text"
                  />
                  <div class="error" v-if="errors && errors.whatsapp">
                    {{ errors.whatsapp[0] }}
                  </div>
                </fieldset>

                <fieldset
                  class="
                    contactsProfileInfo__fieldset
                    contactsProfileInfo__fieldsetName
                  "
                >
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="Страна"
                    :value="contact.country"
                    labelId="country"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                  />
                  <div class="error" v-if="errors && errors.country">
                    {{ errors.country[0] }}
                  </div>
                  <contacts-profile-info-input
                    class="contactsProfileInfo__input"
                    title="Город"
                    :value="contact.city"
                    labelId="city"
                    :inputWidth="240"
                    @handle-input="changeNameMessengers"
                  />
                  <div class="error" v-if="errors && errors.city">
                    {{ errors.city[0] }}
                  </div>
                </fieldset>
              </div>
              <!-- NAME AND MESSENGERS -->

              <!-- CONTACT WAY -->
              <div class="ContactsProfileInfoBox">
                <contacts-profile-info-add-input
                  :contactWayArray="contact.phones_objects"
                  contactWay="phones_objects"
                  @add-contact-way-in-array="addContactWayInArray"
                  @change-contact-way-value="changeContactWayValue"
                  @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
                  @del-contact-way-in-array="delContactWayInArray"
                  class="contactsProfileInfo__addInput"
                  type="text"
                  :placeholder="placeholderOnlyDigit"
                  title="Телефон"
                />
                <div
                  class="error error_addInput"
                  v-if="errors && errors.phones_objects"
                >
                  {{ errors.phones_objects[0].value[0] }}
                </div>

                <contacts-profile-info-add-input
                  :contactWayArray="contact.emails_objects"
                  contactWay="emails_objects"
                  @add-contact-way-in-array="addContactWayInArray"
                  @change-contact-way-value="changeContactWayValue"
                  @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
                  @del-contact-way-in-array="delContactWayInArray"
                  class="contactsProfileInfo__addInput"
                  type="email"
                  title="Эл. почта"
                />
                <div
                  class="error error_addInput"
                  v-if="errors && errors.emails_objects"
                >
                  {{ errors.emails_objects[0].value[0] }}
                </div>

                <contacts-profile-info-add-input
                  :contactWayArray="contact.links_objects"
                  contactWay="links_objects"
                  @add-contact-way-in-array="addContactWayInArray"
                  @change-contact-way-value="changeContactWayValue"
                  @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
                  @del-contact-way-in-array="delContactWayInArray"
                  class="contactsProfileInfo__addInput"
                  title="Соц. сети"
                />
                <div
                  class="error error_addInput"
                  v-if="errors && errors.links_objects"
                >
                  {{ errors.links_objects[0].link[0] }}
                </div>
                <contacts-create-tags
                  :dropdownsGroup="tagsCheckboxGroup"
                  :isFilterChecked="isFilterChecked"
                  :filterValue="tagsFilterValue"
                  :filterCounter="tagsFilterCounter"
                  :contactTags="true"
                  @change="changeFilter"
                  @change-filter-value="changeTagsFilterValue"
                  @change-filter-counter="changeTagsFilterCounter"
                />
              </div>

              <!-- CONTACT WAY -->
            </div>
            <FormSubmit
              text="Добавить"
              :disabled="contact.first_name && contact.first_name.length === 0"
              @click="addContact"
            />
          </div>
        </div>
      </div>
    </template>
  </modal-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { runNotice } from "@/utils/notifications";

import FormSubmit from "@/components/FormSubmit.vue";
import ContactsProfileInfoInput from "@/components/Contacts/ContactsProfileInfoInput.vue";
import ContactsProfileInfoAddInput from "@/components/Contacts/ContactsProfileInfoAddInput.vue";
import Loader from "@/components/Loader.vue";
import TabList from "@/components/TabList.vue";
import ModalLayout from "@/components/ModalLayout.vue";
import ContactsCreateTags from "@/components/Contacts/ContactsCreateTags.vue";

export default {
  name: "ContactsModalCreateContact",

  components: {
    FormSubmit,
    ContactsProfileInfoInput,
    ContactsProfileInfoAddInput,
    Loader,
    TabList,
    ModalLayout,
    ContactsCreateTags,
  },

  data() {
    return {
      modalName: "create-contact",
      tabs: [
        {
          name: "ContactsProfileNew",
          title: "Инфо",
        },
      ],
      activeTab: "ContactsProfileNew",
    };
  },

  computed: {
    ...mapGetters({
      pending: "contactsProfileNew/pending",
      contact: "contactsProfileNew/contact",
      errors: "contactsProfileNew/errors",
      currentPage: "contacts/currentPage",
      isFilterChecked: "contactsProfileNew/isFilterChecked",
      tagsFilterValue: "contactsProfileNew/tagsFilterValue",
      tagsFilterCounter: "contactsProfileNew/tagsFilterCounter",
      contactTags: "contactsProfileNew/contactTags",
    }),
    ...mapState("contactsTags", ["tags"]),

    placeholderTelegram() {
      return this.$t("Укажите логин пользователя");
    },

    placeholderOnlyDigit() {
      return this.$t("Только цифры");
    },

    tagsCheckboxGroup() {
      return {
        title: "Теги",
        name: "tags",
        array: this.tags,
      };
    },
  },

  methods: {
    closeModal() {
      this.$store.commit("contactsProfileNew/SET_CLEAR");
      this.$store.commit("contactsProfileNew/CLEAR_ACTIONS");
      this.$store.commit("contactsProfileNew/CLEAR_ERRORS");
      this.$store.commit("contactsProfileNew/SET_TAGS_CLEAR");
    },

    addContact() {
      if (this.contactTags.length > 0) {
        this.$store
          .dispatch("contactsProfileNew/addContact")
          .then(response => {
            if (response && response.status === 201) {
              Promise.all([
                this.contactTags.map(el =>
                  this.$store
                    .dispatch("contactsTags/addTag", {
                      tagId: el,
                      contactId: response.data.id,
                    })
                )
              ])
                .then(() => {
                  runNotice("success", this.$t("Контакт добавлен"));
                  this.$store.dispatch("contacts/fetch", this.currentPage);
                })
                .catch(e => {
                  runNotice("success", this.$t("Контакт добавлен"));
                  console.log(e);
                  this.$store.dispatch("contacts/fetch", this.currentPage);
                })
                .finally(() => {
                  this.$store.commit("contactsProfileNew/SET_TAGS_CLEAR");
                  this.$store.dispatch("user/fetch");
                  this.$modal.hide(this.modalName);
                });
            } else {
              runNotice("error", this.$t("Что-то пошло не так"));
            }
          })
          .catch(e => {
            runNotice("error", this.$t("Что-то пошло не так"));
            console.log(e);
          });
      } else {
        this.$store
          .dispatch("contactsProfileNew/addContact")
          .then(response => {
            if (response && response.status === 201) {
              runNotice("success", this.$t("Контакт добавлен"));
              this.$store.dispatch("contacts/fetch", this.currentPage);
              this.$store.dispatch("user/fetch");
              this.$modal.hide(this.modalName);
            } else {
              runNotice("error", this.$t("Что-то пошло не так"));
            }
          })
          .catch(e => {
            runNotice("error", this.$t("Что-то пошло не так"));
            console.log(e);
          });
      }
    },

    // change name and messengers
    changeNameMessengers({ param, value }) {
      this.$store.commit(`contactsProfileNew/CHANGE_NAME_MESSENGERS`, {
        param,
        value,
      });
    },

    // add new input if empty
    addNewEmptyContactWayValue(name) {
      this.$store.commit(
        `contactsProfileNew/ADD_NEW_EMPTY_CONTACT_WAY_VALUE`,
        name
      );
    },

    // add new input
    addContactWayInArray(contactWay) {
      this.$store.commit(
        `contactsProfileNew/ADD_CONTACT_WAY_IN_ARRAY`,
        contactWay
      );
    },

    // change input
    changeContactWayValue({ name, id, value }) {
      this.$store.commit(
        `contactsProfileNew/CHANGE_CONTACT_WAY_IN_ARRAY_VALUE`,
        { name, value, id }
      );
    },

    // del input
    delContactWayInArray(payload) {
      this.$store.commit(
        `contactsProfileNew/DEL_CONTACT_WAY_IN_ARRAY`,
        payload
      );
    },

    changeFilter(value) {
      this.$store.commit("contactsProfileNew/SET_TAGS", value);
    },

    changeTagsFilterValue(value) {
      this.$store.commit("contactsProfileNew/SET_TAGS_FILTER_VALUE", value);
    },

    changeTagsFilterCounter(value) {
      this.$store.commit("contactsProfileNew/SET_TAGS_FILTER_COUNTER", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsProfileInfoInner {
  display: grid;
  grid-row-gap: 20px;
}

.contactsProfileInfo__input {
  margin-bottom: 12px;
}

.contactsProfileInfo__fieldset {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.contactsProfileInfo__addInput {
  &:not(:last-child) {
    margin-bottom: 22px;
  }
}

@media (min-width: tablet) {
  .contactsProfileInfoInner {
    grid-auto-flow: column;
    grid-column-gap: 32px;
    grid-row-gap: unset;
    width: fit-content;
  }
}

.underLoader {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.contactsProfileBreadcrumbs {
  margin-bottom: 15px;
}

.contactsProfile__wrap {
  padding: 12px 6px 20px;
  min-height: 424px;
  box-shadow: 0px 3px 10px shadow_color;
  border-radius: 8px;

  @media (min-width: desktop) {
    min-height: 571px;
  }
}

.contactsProfile__body {
  padding-top: 16px;
}

@media (min-width: desktop) {
  .contactsProfile__wrap {
    padding-right: 16px;
    padding-left: 16px;
    width: 692px;
  }
}

.error {
  margin-top: -10px;
  font_small();
  color: danger_text_color;

  &_addInput {
    margin-top: -20px;
  }
}
</style>
