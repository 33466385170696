import { assocPath, clone, dissoc, isEmpty } from "ramda";
import supportedLangs from "./supportedLangs";

class Parser {
  // Основной метод, в качесте аргумента принимает любой объект

  parse(obj) {
    if (!obj) {
      return;
    }
    let data = clone(obj);

    // Чекаем наличие сущестующего свойста 'locales'
    // Форматируем вложенный объект, приводим к виду: locales.${LANG}.${OPTION_NAME}

    if (data && data.locales) {
      let locales = {};
      for (const [key, value] of Object.entries(data.locales)) {
        if (!this.hasExactPostfix(key, "")) {
          for (const [innerKey, innerValue] of Object.entries(
            data.locales[key]
          )) {
            locales = {
              ...locales,
              [innerKey]: {
                ...locales[innerKey],
                [key]: innerValue,
              },
            };
          }
        }
      }
      if (!isEmpty(locales)) {
        data.locales = { ...locales };
      }
    }

    // Парсим сойства на наличие постфикса _${LANG}
    // Если таковые имеются - складываем в locales:
    // locales.${LANG}.${OPTION_NAME}

    for (const [key, value] of Object.entries(data)) {
      if (this.hasLangPostfix(key)) {
        const lang = key.substr(-2);
        const paramName = key.substr(0, key.length - 3);
        // data.locales[lang][paramName] = value;

        data = assocPath(["locales", lang, paramName], value, data);
        data = dissoc(key, data);
      }
    }

    return data;
  }

  // Объектная запись -> Постфиксная запись (дефолтный разделитель: _)

  toPrefix(obj) {
    let data = clone(obj);

    if (data.locales) {
      for (const [lang] of Object.entries(data.locales)) {
        for (const [prop, value] of Object.entries(data.locales[lang])) {
          data = { ...data, [`${prop}_${lang}`]: value };
        }
      }
    }

    delete data.locales;

    return data;
  }

  // Проверка наличия постфикса

  hasLangPostfix = (prop: string) => {
    const langs = supportedLangs();
    let has = false;

    for (const lang in langs) {
      if (prop.endsWith(`_${langs[lang]}`)) {
        has = true;
        break;
      }
    }

    return has;
  };

  // Проверка наличия постфикса с конкретным разделителем

  hasExactPostfix = (prop: string, divider: string) => {
    const langs = supportedLangs();
    let has = false;

    for (const lang in langs) {
      if (prop.endsWith(`${divider}${langs[lang]}`)) {
        has = true;
        break;
      }
    }

    return has;
  };

  deleteFromLocales = (locales: any, propName: string) => {
    const langs = supportedLangs();

    for (const i in langs) {
      delete locales[langs[i]][propName];
    }
    return locales;
  };
}

export default new Parser();
