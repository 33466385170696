import axios from "axios";
import { clone, pathOr } from "ramda";
import axiosConfig from "@/utils/axiosConfigChatBot";
import {
  changeSlidePosition,
  filesToPost,
  transformResponse,
  transformSlidesToUpdate,
} from "@/utils/storesUtils/presentation";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pickedSlide: 0,
    scale: 1,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    slides(state) {
      return pathOr([], ["data", "slides"], state);
    },
    pickedSlide(state) {
      return state.pickedSlide;
    },
    coef(state) {
      return state.trackCoef;
    },
    getCurrentSlide: state => currentTime => {
      if (state.data.slides) {
        return state.data.slides.find(
          slide => slide.start <= currentTime && slide.end >= currentTime
        );
      }
      return undefined;
    },
    scale(state) {
      return state.scale;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    PICK_SLIDE(state, id) {
      state.pickedSlide = id;
    },
    UPDATE_LIST(state, value) {
      state.data.slides = value;
    },
    RESIZE_SLIDE(state, { index, offset, side }) {
      state.data.slides = state.data.slides.map((slide, i) => {
        if (side === "left") {
          if (i === index - 1) {
            return {
              ...slide,
              end: offset,
            };
          }
        }
        if (side === "right") {
          if (i === index + 1) {
            return {
              ...slide,
              start: offset,
            };
          }
        }
        if (index === i) {
          const changes = side === "left" ? { start: offset } : { end: offset };
          return {
            ...slide,
            ...changes,
          };
        } else {
          return slide;
        }
      });
    },
    DRAG_SLIDE(state, { offset, index, fake }) {
      if (fake) {
        const copied = clone(state.data.slides);

        state.data.slides = [];
        setTimeout(() => {
          state.data.slides = copied;
        }, 1);
      } else {
        state.data.slides = changeSlidePosition(
          offset,
          index,
          state.data.slides
        );
      }
    },
    CHANGE_SCALE(state, scale) {
      state.scale = Number(scale);
    },
    FAKE_DRAG(state) {
      const copied = clone(state.data.slides);
      state.data.slides = [];
      state.data.slides = copied;
    },
    ADD_SLIDE_IN_THE_END(state) {
      state.data.slides = [...state.data.slides];
    },
  },

  actions: {
    async fetch({ commit, rootGetters }, { id }) {
      try {
        commit("SET_PENDING", true);
        const response = await axios.get(
          `/presentations/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          const data = transformResponse(
            response.data.response,
            rootGetters["autowebinar/duration"]
          );

          commit("SET_DATA", data);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createPresentation({ commit, rootGetters }, name) {
      const data = {
        name,
      };
      try {
        commit("SET_PENDING", true);

        const response = await axios.post(
          `/presentations`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
          return response.data.response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async updateSlides(
      { commit, rootGetters, state, dispatch },
      newSlidesCount
    ) {
      try {
        commit("SET_PENDING", true);
        const slides = transformSlidesToUpdate(
          state.data.slides,
          newSlidesCount
        );
        const response = await axios.put(
          `/presentations/${state.data.id}/slides`,
          slides,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          // dispatch("fetch", { id: state.data.id });
        }
        // commit("SET_PENDING", true);
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async addSlide({ state, commit, rootGetters, dispatch }, files) {
      const videoDuration = rootGetters["autowebinar/duration"];
      await dispatch("updateSlides", files.length);

      try {
        const data = filesToPost({ files, videoDuration });
        const response = await axios.post(
          `/presentations/${state.data.id}/slides/multi`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );
        if (response.status === 200) {
          commit(
            "SET_DATA",
            transformResponse(
              [...state.data.slides, response.data.response],
              videoDuration
            )
          );
          commit("SET_PENDING", false);
          dispatch("fetch", { id: state.data.id });
          return response.data.response;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
