<template>
  <div
    :class="['avatar', { avatar_contacts: contacts }]"
    :style="[
      { backgroundImage: `${randomColor}` },
      { fontSize: `${fontSize}px` },
    ]"
  >
    {{ avatarName | getInitialsFilter }}
  </div>
</template>

<script>
export default {
  name: "Avatar",

  props: {
    avatarName: {
      type: String,
    },
    fontSize: {
      type: Number,
      default: 10,
    },

    contacts: Boolean,
  },

  computed: {
    randomColor() {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }

      const str = `linear-gradient(#fff -125%, rgb(${getRandomInt(
        150,
        240
      )}, ${getRandomInt(150, 240)}, ${getRandomInt(150, 240)}))`;

      return str;
    },
  },
};
</script>

<style lang="stylus" scoped>
.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font(main_text_color, 10px, 700, 14px);

  @media (min-width: desktop) {
    width: 24px;
    height: 24px;
  }

  &_contacts {
    margin-bottom: -4px;

    @media (min-width: desktop) {
      margin-bottom: 0;
    }
  }
}
</style>
