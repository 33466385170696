import createLocales from "@/utils/createLocales";
import supportedLangs from "@/utils/supportedLangs";
import Universal from ".";

class Answer extends Universal {
  constructor(data?) {
    if (data) {
      super(data);
    } else {
      super({
        position: 0,
        is_right: false,
        locales: {
          ...createLocales(["body", "explanation"], supportedLangs(), ""),
        },
      });
    }
  }
}

export default Answer;
