<template>
  <div
    :class="[
      'mergeContactsTableHead',
      { mergeContactsTableHead_created: created },
    ]"
  >
    <div
      class="mergeContactsTableHeadCell"
      v-for="tableHead in tableHeads"
      :key="tableHead.title"
    >
      <Tr>{{ tableHead.title }}</Tr>
    </div>
  </div>
</template>

<script>
export default {
  name: "MergeContactsTableHead",

  props: {
    tableHeads: {
      type: Array,
      required: true,
    },

    created: Boolean,
  },
};
</script>

<style lang="stylus" scoped>
.mergeContactsTableHead {
  display: grid;

  &_created {
    display: none;
  }

  @media (min-width: desktop) {
    display: grid;

    .mergeContactsTableHeadCell:last-child {
      display: none;
    }
  }
}

.mergeContactsTableHeadCell {
  font-weight: 700;
}
</style>
