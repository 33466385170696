<template>
  <div class="contactsBodyHead">
    <div />
    <div class="contactsBodyHeadCell">
      <Tr>Имя</Tr>
    </div>
    <div />
    <div class="contactsBodyHeadCell" v-if="0">
      <Tr>Телефон</Tr>
    </div>
    <div class="contactsBodyHeadCell">
      <Tr>Связь</Tr>
    </div>
    <div class="contactsBodyHeadCell">
      <Tr>Прогресс</Tr>
    </div>
    <div class="contactsBodyHeadCell">
      <Tr>Подогретость</Tr>
    </div>
    <div class="contactsBodyHeadCell">
      <Tr>Топ</Tr>
    </div>
    <div class="contactsBodyHeadCell">
      <Tr>Теги</Tr>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactsBodyHead",
};
</script>

<style lang="stylus" scoped>
.contactsBodyHead {
  display: none;

  @media (min-width: desktop) {
    display: grid;
  }
}

.contactsBodyHeadCell {
  font-weight: 700;
}
</style>
