import Vue from "vue";

function getNotEmptyObjects(state, data, name) {
  if (name === "links_objects") {
    state.data[name].forEach(el => {
      if (el.link) {
        data[name].push({ link: el.link });
      }
    });
  }
  state.data[name].forEach(el => {
    if (el.value) {
      data[name].push({ value: el.value });
    }
  });
}

export default {
  namespaced: true,

  state: () => ({
    data: {
      first_name: "",
      last_name: "",
      telegram: "",
      whatsapp: "",
      country: "",
      city: "",
      phones_objects: [],
      emails_objects: [],
      links_objects: [],
    },
    actions: {
      isChangedNameMessengers: false,
      create: [],
      change: [],
      del: [],
    },
    errors: {},
    pending: false,
    tags: [],
    tagsFilterValue: "",
    tagsFilterCounter: null,
  }),

  getters: {
    contact(state) {
      return state.data;
    },
    pending(state) {
      return state.pending;
    },
    errors(state) {
      return state.errors;
    },
    isFilterChecked: state => value => {
      if (state.tags.length > 0) {
        return state.tags.includes(value);
      }
      return false;
    },

    tagsFilterValue(state) {
      return state.tagsFilterValue;
    },

    tagsFilterCounter(state) {
      return state.tagsFilterCounter;
    },

    contactTags(state) {
      return state.tags;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    CHANGE_NAME_MESSENGERS(state, { param, value }) {
      state.data[param] = value;
      state.actions.isChangedNameMessengers = true;
    },

    RESET_NAME_MESSENGERS(state) {
      state.actions.isChangedNameMessengers = false;
    },
    RESET_ACTIONS_CREATE(state) {
      state.actions.create = [];
    },
    RESET_ACTIONS_CHANGE(state) {
      state.actions.change = [];
    },
    RESET_ACTIONS_DEL(state) {
      state.actions.del = [];
    },

    CHANGE_RESPONSE(state) {
      state.responses = true;
    },

    ADD_CONTACT_WAY_IN_ARRAY(state, contactWay) {
      if (contactWay === "links_objects") {
        state.data[contactWay].push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id + 1,
          link: "",
        });

        state.actions.create.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          link: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          link: "",
          profileId: state.data.id,
          isCreated: true,
        });
      } else {
        state.data[contactWay].push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id + 1,
          value: "",
        });

        state.actions.create.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          value: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: state.data[contactWay][state.data[contactWay].length - 1].id,
          name: contactWay,
          value: "",
          profileId: state.data.id,
          isCreated: true,
        });
      }
    },

    ADD_NEW_EMPTY_CONTACT_WAY_VALUE(state, name) {
      if (name === "links_objects") {
        state.data[name].push({
          id: 1,
          link: "",
        });

        state.actions.create.push({
          id: 1,
          name,
          link: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: 1,
          name,
          link: "",
          profileId: state.data.id,
          isCreated: true,
        });
      } else {
        state.data[name].push({
          id: 1,
          value: "",
        });

        state.actions.create.push({
          id: 1,
          name,
          value: "",
          profileId: state.data.id,
        });

        state.actions.change.push({
          id: 1,
          name,
          value: "",
          profileId: state.data.id,
          isCreated: true,
        });
      }
    },

    CHANGE_CONTACT_WAY_IN_ARRAY_VALUE(state, { name, value, id }) {
      const findedDataEl = state.data[name].find(el => el.id === Number(id));
      if (findedDataEl) {
        if (Object.prototype.hasOwnProperty.call(findedDataEl, "value")) {
          findedDataEl.value = value;
        } else if (Object.prototype.hasOwnProperty.call(findedDataEl, "link")) {
          findedDataEl.link = value;
        }
      }

      const findedActionsEl = state.actions.change.find(
        el => el.id === Number(id) && el.name === name
      );
      if (findedActionsEl) {
        if (name === "links_objects") {
          findedActionsEl.value = value;
        } else {
          findedActionsEl.link = value;
        }
        return;
      }
      if (name === "links_objects") {
        state.actions.change.push({
          id,
          name,
          link: value,
          profileId: state.data.id,
        });
      } else {
        state.actions.change.push({
          id,
          name,
          value,
          profileId: state.data.id,
        });
      }
    },

    DEL_CONTACT_WAY_IN_ARRAY(state, { id, name }) {
      // del from ui
      const findedEl = state.data[name].find(el => el.id === id);
      const findedElIndex = state.data[name].indexOf(findedEl);
      state.data[name].splice(findedElIndex, 1);

      // del from created
      const createdEl = state.actions.create.find(
        createdEl => createdEl.id === id
      );
      if (createdEl) {
        const createdElIndex = state.actions.create.indexOf(createdEl);
        state.actions.create.splice(createdElIndex, 1);
        return;
      }

      state.actions.del.push({ id: id, name: name });
    },

    CLEAR_ACTIONS(state) {
      state.actions = {
        isChangedNameMessengers: false,
        create: [],
        change: [],
        del: [],
      };
    },

    CLEAR_ERRORS(state) {
      state.errors = {};
    },

    SET_CLEAR(state) {
      state.data = {
        first_name: "",
        last_name: "",
        telegram: "",
        whatsapp: "",
        country: "",
        city: "",
        phones_objects: [],
        emails_objects: [],
        links_objects: [],
      };
    },

    SET_TAGS_CLEAR(state) {
      state.tags = [];
      state.tagsFilterValue = "";
      state.tagsFilterCounter = null;
    },

    SET_TAGS(state, value) {
      if (state.tags.includes(value)) {
        state.tags = state.tags.filter(el => el !== value);
      } else {
        state.tags = [...state.tags, value];
      }
    },

    SET_TAGS_FILTER_VALUE(state, value) {
      state.tagsFilterValue = value;
    },

    SET_TAGS_FILTER_COUNTER(state, value) {
      state.tagsFilterCounter = Number(value);
    },
  },

  actions: {
    async addContact({ commit, state }) {
      try {
        commit("SET_PENDING", true);

        const responseData = {
          ...(state.data.first_name
            ? { first_name: state.data }.first_name
            : {}),
          ...(state.data.last_name ? { last_name: state.data }.last_name : {}),
          ...(state.data.telegram ? { telegram: state.data }.telegram : {}),
          ...(state.data.whatsapp ? { whatsapp: state.data }.whatsapp : {}),
          ...(state.data.city ? { city: state.data }.city : {}),
          ...(state.data.country ? { country: state.data }.country : {}),
          phones_objects: [],
          emails_objects: [],
          links_objects: [],
        };

        getNotEmptyObjects(state, responseData, "phones_objects");
        getNotEmptyObjects(state, responseData, "emails_objects");
        getNotEmptyObjects(state, responseData, "links_objects");

        const response = await Vue.axios.post(`/contacts/`, responseData);

        if (String(response.status).startsWith("20")) {
          commit("SET_PENDING", false);
          commit("SET_CLEAR");
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
