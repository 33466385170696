<template>
  <div class="funnel__linkCreatedContainer">
    <div class="funnel__linkCreatedHeader">
      <div class="funnel__linkCreatedHeaderText">
        <Tr>Ссылка</Tr>
        "{{ createdName }}"
        <Tr>создана</Tr>
      </div>
    </div>
    <div class="funnel__linkCreatedBody">
      <div class="funnel__linkCreatedBodyInputWrap">
        <label
          for="funnel__linkCreatedInput"
          class="funnel__createLinkLabel funnel__createLinkLabel_linkCreated"
        >
          <Tr>Ссылка</Tr>
        </label>
        <input
          id="funnel__linkCreatedInput"
          class="funnel__createLinkInput funnel__createLinkInput_linkCreated"
          type="text"
          :value="createdUrl"
        />
        <button
          class="funnel__linkCreatedBodyCopyLink"
          type="button"
          v-clipboard="copy"
        />
      </div>
      <div class="funnel__linkCreatedBodyText">
        <Tr>Вы можете отправить эту ссылку</Tr>
      </div>
      <div class="createLinkButtons__wrap">
        <button
          :to="updatedFunnel ? '/funnel/create-link' : '/funnel/create-link'"
          class="createLinkButton createLinkButton_createLink"
          @click="newLink"
        >
          <Tr>Создать ссылку</Tr>
        </button>
        <base-link
          :to="updatedFunnel ? '/funnel/links-list' : '/funnel/link-list'"
          class="createLinkButton createLinkButton_list"
        >
          <Tr>Список ссылок</Tr>
        </base-link>
      </div>
    </div>
  </div>
</template>

<script>
import { runNotice } from "@/utils/notifications";

export default {
  name: "FunnelLinkCreateSuccess",
  props: {
    createdUrl: {
      type: String,
      required: true,
    },
    createdName: {
      type: String,
      required: true,
    },
    updatedFunnel: Boolean,
  },
  methods: {
    copy() {
      runNotice("success", this.$t("Ссылка скопирована"));
      return this.createdUrl;
    },
    newLink() {
      this.$emit("newLink");
    },
  },
  beforeDestroy() {
    this.$emit("beforeDestroy");
  },
};
</script>

<style lang="stylus" scoped>
.funnel__createLink {
  &Label {
    display: inline-block;
    margin-bottom: 4px;
    font_small();

    &_linkCreated {
      display: block;
    }
  }

  &Input {
    display: block;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: 240px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    &_linkCreated {
      display: inline-block;
      margin-right: 5px;
      width: calc(100% - 40px);
      max-width: 400px;

      @media (min-width: phone_s) {
        margin-right: 15px;
      }
    }
  }
}

.funnel__linkCreated {
  &Container {
    width: 100%;
    max-width: 692px;
    background-color: #fff;
    box-shadow: 0px 3px 10px #0F164214;
    border-radius: 8px;
    overflow: hidden;
  }

  &Header {
    padding: 7px 16px;
    background-color: #19C564;

    &Text {
      font_bold();
      color: #fff;
    }
  }

  &Body {
    padding: 10px 6px;

    &InputWrap {
      margin-bottom: 16px;
    }

    &CopyLink {
      width: 14px;
      height: 16px;
      background-color: unset;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M4.667 1.6h7.777v9.6H10.89v-8H4.667zm4.666 3.2v9.6H1.556l.002-9.6zM.786 3.2a.791.791 0 0 0-.784.8L0 15.2c0 .213.083.417.23.567.147.15.346.234.553.233h9.323a.791.791 0 0 0 .783-.8v-2.4h2.333A.79.79 0 0 0 14 12V.8a.79.79 0 0 0-.778-.8H3.89a.79.79 0 0 0-.778.8v2.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
    }

    &Text {
      position: relative;
      margin-bottom: 20px;
      padding-left: 12px;
      width: 100%;
      max-width: 335px;
      font(main_text_color, 12px, 400, 14px);

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: grey_text_color;
      }
    }
  }
}

.createLink {
  &Buttons__wrap {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 9px;
  }

  &Button {
    position: relative;
    background-color: unset;
    border: none;
    border-radius: 4px;
    color: #0F1642;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: none;

    &_createLink {
      padding: 7px 8px 7px 28px;
      border: 1px dashed main_text_color;

      &::before {
        position: absolute;
        content: '';
        top: 6px;
        left: 4px;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &_list {
      padding: 7px 8px;
      background-color: grey_text_color;
    }
  }
}

@media (min-width: desktop) {
  .funnel__linkCreatedBody {
    padding: 16px 16px 20px;
  }

  .funnel__createLink {
    &Container {
      width: 692px;
    }

    &Input {
      &_linkCreated {
        width: 400px;
      }
    }
  }

  .funnel__linkCreated {
    &Container {
      width: 692px;
    }
  }

  &Body {
    &Text {
      width: 335px;
    }
  }

  .createLinkButton {
    font-size: 14px;
    line-height: 18px;

    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
