var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'mergeContactsTableItem',
    { mergeContactsTableItem_created: _vm.created } ]},[_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellName\n      mergeContactsTableItemCellFade\n    "},[(_vm.name)?_c('div',{class:[
        'mergeContactsTableItemCellInner',
        {
          mergeContactsTableItemCellInner_created: _vm.created,
          mergeContactsTableItemCellInner_active:
            _vm.isCheckedField(_vm.contact.id, 'first_name', _vm.contact.first_name) &&
            _vm.isCheckedField(_vm.contact.id, 'last_name', _vm.contact.last_name),
        } ]},[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"name"},on:{"change":function($event){return _vm.pickOption($event, _vm.name)}}}),_c('span',{class:[
            'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
            {
              mergeContactsTableItemCellCheckbox_active:
                _vm.isCheckedField(
                  _vm.contact.id,
                  'first_name',
                  _vm.contact.first_name
                ) &&
                _vm.isCheckedField(_vm.contact.id, 'last_name', _vm.contact.last_name),
            } ]})]),_c('base-tooltip',{attrs:{"tooltipContent":_vm.name}},[_vm._v(" "+_vm._s(_vm.name)+" ")])],1):_vm._e()]),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellPhone\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.phones_objects.length > 0)?_vm._l((_vm.contact.phones_objects),function(phone){return _c('div',{key:phone.id,class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedObject(
              _vm.contact.id,
              'phones_objects',
              phone
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"phones_objects"},on:{"change":function($event){return _vm.pickOption($event, phone)}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedObject(
                    _vm.contact.id,
                    'phones_objects',
                    phone
                  ),
                } ]})])]:_vm._e(),_c('base-tooltip',{attrs:{"tooltipContent":phone.value}},[_vm._v(" "+_vm._s(phone.value)+" ")])],2)}):_c('span',{staticClass:"withoutValue"})],2),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellEmail\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.emails_objects.length > 0)?_vm._l((_vm.contact.emails_objects),function(email){return _c('div',{key:email.id,class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedObject(
              _vm.contact.id,
              'emails_objects',
              email
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"emails_objects"},on:{"change":function($event){return _vm.pickOption($event, email)}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedObject(
                    _vm.contact.id,
                    'emails_objects',
                    email
                  ),
                } ]})])]:_vm._e(),_c('base-tooltip',{attrs:{"tooltipContent":email.value}},[_vm._v(" "+_vm._s(email.value)+" ")])],2)}):_c('span',{staticClass:"withoutValue"})],2),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellLink\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.links_objects.length > 0)?_vm._l((_vm.contact.links_objects),function(link){return _c('div',{key:link.id,class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedObject(
              _vm.contact.id,
              'links_objects',
              link
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"links_objects"},on:{"change":function($event){return _vm.pickOption($event, link)}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedObject(
                    _vm.contact.id,
                    'links_objects',
                    link
                  ),
                } ]})])]:_vm._e(),_c('base-tooltip',{attrs:{"tooltipContent":link.link}},[_vm._v(" "+_vm._s(link.link)+" ")])],2)}):_c('span',{staticClass:"withoutValue"})],2),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellCountry\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.country)?_c('div',{class:[
        'mergeContactsTableItemCellInner',
        {
          mergeContactsTableItemCellInner_created: _vm.created,
          mergeContactsTableItemCellInner_active: _vm.isCheckedField(
            _vm.contact.id,
            'country',
            _vm.contact['country']
          ),
        } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"country"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
              'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
              {
                mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                  _vm.contact.id,
                  'country',
                  _vm.contact['country']
                ),
              } ]})])]:_vm._e(),_c('base-tooltip',{attrs:{"tooltipContent":_vm.contact.country}},[_vm._v(" "+_vm._s(_vm.contact.country)+" ")])],2):_c('span',{staticClass:"withoutValue"})]),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellCity\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.city)?_c('div',{class:[
        'mergeContactsTableItemCellInner',
        {
          mergeContactsTableItemCellInner_created: _vm.created,
          mergeContactsTableItemCellInner_active: _vm.isCheckedField(
            _vm.contact.id,
            'city',
            _vm.contact['city']
          ),
        } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"city"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
              'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
              {
                mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                  _vm.contact.id,
                  'city',
                  _vm.contact['city']
                ),
              } ]})])]:_vm._e(),_c('base-tooltip',{attrs:{"tooltipContent":_vm.contact.city}},[_vm._v(" "+_vm._s(_vm.contact.city)+" ")])],2):_c('span',{staticClass:"withoutValue"})]),_c('div',{staticClass:"\n      mergeContactsTableItemCell\n      mergeContactsTableItemCellMessengers\n      mergeContactsTableItemCellFade\n    "},[(_vm.contact.telegram)?_c('div',{class:[
        'mergeContactsTableItemCellInner',
        {
          mergeContactsTableItemCellInner_created: _vm.created,
          mergeContactsTableItemCellInner_active: _vm.isCheckedField(
            _vm.contact.id,
            'telegram',
            _vm.contact['telegram']
          ),
        } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"telegram"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
              'mergeContactsTableItemCellCheckbox',
              {
                mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                  _vm.contact.id,
                  'telegram',
                  _vm.contact['telegram']
                ),
              } ]})])]:_vm._e(),_c('base-icon-svg',{staticClass:"mergeContactsTableItemCellIcon",attrs:{"width":"20","height":"20","viewbox":"0 0 20 20"}},[_c('telegram-icon')],1),_c('base-tooltip',{attrs:{"tooltipContent":_vm.contact.telegram}},[_vm._v(" "+_vm._s(_vm.contact.telegram)+" ")])],2):_vm._e(),(_vm.contact.whatsapp)?_c('div',{class:[
        'mergeContactsTableItemCellInner',
        {
          mergeContactsTableItemCellInner_created: _vm.created,
          mergeContactsTableItemCellInner_active: _vm.isCheckedField(
            _vm.contact.id,
            'whatsapp',
            _vm.contact['whatsapp']
          ),
        } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"whatsapp"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
              'mergeContactsTableItemCellCheckbox',
              {
                mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                  _vm.contact.id,
                  'whatsapp',
                  _vm.contact['whatsapp']
                ),
              } ]})])]:_vm._e(),_c('base-icon-svg',{staticClass:"mergeContactsTableItemCellIcon",attrs:{"width":"20","height":"20","viewbox":"0 0 20 20"}},[_c('whatsapp-icon')],1),_c('base-tooltip',{attrs:{"tooltipContent":_vm.contact.whatsapp}},[_vm._v(" "+_vm._s(_vm.contact.whatsapp)+" ")])],2):_vm._e(),(!_vm.contact.whatsapp && !_vm.contact.telegram)?_c('span',{staticClass:"withoutValue"}):_vm._e()]),_c('div',{staticClass:"mergeContactsTableItemCell mergeContactsTableItemCellProgress"},[(Object.prototype.hasOwnProperty.call(_vm.contact, 'progress'))?[_c('Tr',{staticClass:"contactsBodyItemMobileText"},[_vm._v("Прогресс")]),_c('div',{class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedField(
              _vm.contact.id,
              'progress',
              _vm.contact['progress']
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"progress"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                    _vm.contact.id,
                    'progress',
                    _vm.contact['progress']
                  ),
                } ]})])]:_vm._e(),(_vm.contact.progress >= 0)?_c('base-tooltip',{staticClass:"progressBox",attrs:{"tooltipContent":_vm.progressTooltip(_vm.contact.progress)}},_vm._l((10),function(progressItem){return _c('span',{key:progressItem,class:[
              ' progressItem',
              {
                progressItem_active: progressItem - 1 <= _vm.contact.progress,
              } ]})}),0):_vm._e()],2)]:_vm._e()],2),_c('div',{staticClass:"mergeContactsTableItemCell mergeContactsTableItemCellHeat"},[(_vm.contactHeat)?[_c('Tr',{staticClass:"contactsBodyItemMobileText"},[_vm._v("Подогретость")]),_c('div',{class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedField(
              _vm.contact.id,
              'heat',
              _vm.contact['heat']
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"heat"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                    _vm.contact.id,
                    'heat',
                    _vm.contact['heat']
                  ),
                } ]})])]:_vm._e(),_c('div',{staticClass:"contactsBodyItemHeat"},[_c('base-tooltip',{staticClass:"contactsBodyItemHeatBox",attrs:{"tooltipContent":_vm.heat}},[_c('base-icon-svg',{staticClass:"contactsBodyItemHeatImg",attrs:{"width":"120","height":"20","viewbox":"0 0 120 20"}},[(_vm.contactHeat === 'cold')?_c('ContactsHeatCold'):(_vm.contactHeat === 'warm')?_c('ContactsHeatWarm'):(_vm.contactHeat === 'hot')?_c('ContactsHeatHot'):_vm._e()],1)],1)],1)],2)]:_c('span',{staticClass:"withoutValue"})],2),_c('div',{staticClass:"mergeContactsTableItemCell mergeContactsTableItemCellTop"},[(_vm.contact.top)?[_c('Tr',{staticClass:"contactsBodyItemMobileText"},[_vm._v("Топ")]),_c('div',{class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedField(
              _vm.contact.id,
              'top',
              _vm.contact['top']
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"top"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox mergeContactsTableItemCellCheckbox_radio',
                {
                  mergeContactsTableItemCellCheckbox_active: _vm.isCheckedField(
                    _vm.contact.id,
                    'top',
                    _vm.contact['top']
                  ),
                } ]})])]:_vm._e(),_c('div',{staticClass:"contactsBodyItemTop"},[(_vm.contactTop === '100')?_c('Tr',{staticClass:"contactsBodyItemTopInner contactsBodyItemTopInner_100"},[_vm._v("ТОП 100")]):(_vm.contactTop === '20_and_100')?_c('Tr',{staticClass:"contactsBodyItemTopInner contactsBodyItemTopInner_20"},[_vm._v("ТОП 20")]):_vm._e()],1)],2)]:_c('span',{staticClass:"withoutValue"})],2),_c('div',{staticClass:"mergeContactsTableItemCell mergeContactsTableItemCellTags"},[(
        Object.prototype.hasOwnProperty.call(_vm.contact, 'tag_objects') &&
        _vm.contact.tag_objects.length > 0
      )?[_c('Tr',{staticClass:"contactsBodyItemMobileText"},[_vm._v("Теги")]),_c('div',{class:[
          'mergeContactsTableItemCellInner',
          {
            mergeContactsTableItemCellInner_created: _vm.created,
            mergeContactsTableItemCellInner_active: _vm.isCheckedTagsObject(
              _vm.contact.id,
              'tag_objects',
              _vm.contact['tag_objects']
            ),
          } ]},[(!_vm.created)?[_c('label',[_c('input',{staticClass:"mergeContactsTableItemCellInput",attrs:{"type":"checkbox","name":"tag_objects"},on:{"change":function($event){return _vm.pickOption($event, _vm.contact[$event.target.name])}}}),_c('span',{class:[
                'mergeContactsTableItemCellCheckbox',
                {
                  mergeContactsTableItemCellCheckbox_active:
                    _vm.isCheckedTagsObject(
                      _vm.contact.id,
                      'tag_objects',
                      _vm.contact['tag_objects']
                    ),
                } ]})])]:_vm._e(),(_vm.contact.tag_objects.length > 0)?_c('div',{staticClass:"tagsPopup"},[_c('div',{staticClass:"tagsPopupBox"},_vm._l((_vm.contact.tag_objects),function(tag){return _c('span',{key:tag.id,staticClass:"tagsPopupItem",style:({
                backgroundColor: ("#" + (tag.color)),
                color: ("#" + (tag.text_color)),
              })},[_vm._v(_vm._s(tag.name))])}),0)]):_vm._e()],2)]:_c('span',{staticClass:"withoutValue"})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }