import { pick } from "ramda";

const pickGeneral = data =>
  pick(
    [
      "id",
      "locales",
      "approve_reward_brilliant",
      "approve_reward_expire_brilliant",
      "reward_expire_gold",
      "reward_gold",
    ],
    data
  );

export default pickGeneral;
