<template>
  <div class="leadsCreateLinkSuccess">
    <div class="leadsCreateLinkSuccess__header">
      <div class="leadsCreateLinkSuccess__title">
        <Tr>Ссылка</Tr>
        <Tr>создана</Tr>
      </div>
    </div>
    <div class="leadsCreateLinkSuccess__body">
      <div class="leadsCreateLinkSuccess__inputBox">
        <label
          for="funnel__linkCreatedInput"
          class="leadsCreateLinkSuccess__label"
        >
          <Tr>Ссылка</Tr>
        </label>
        <input
          id="funnel__linkCreatedInput"
          class="leadsCreateLinkSuccess__input"
          type="text"
          :value="createdUrl"
        />
        <button
          class="leadsCreateLinkSuccess__copy"
          type="button"
          v-clipboard="copy"
        />
      </div>
      <div class="createLinkButtons">
        <button class="createLinkButton" @click="newLink">
          <Tr>Создать ещё ссылку</Tr>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

export default {
  name: "UpdatedLeadsCreateLinkToWebinarSuccess",
  computed: {
    ...mapGetters({
      createdUrl: "updatedLeadsCreateLinkToWebinar/createdUrl",
      // name: "updatedLeadsCreateLinkToWebinar/name",
    }),
  },
  methods: {
    copy() {
      runNotice("success", this.$t("Ссылка скопирована"));
      return this.createdUrl;
    },

    newLink() {
      this.$store.commit("updatedLeadsCreateLinkToWebinar/CLEAR_STORE");
    },
  },
  beforeDestroy() {
    this.$store.commit("updatedLeadsCreateLinkToWebinar/CLEAR_STORE");
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLinkSuccess {
  width: 100%;
  background-color: #fff;

  &__header {
    margin -10px -10px 0
    padding: 7px 16px;
    background-color green_text_color
    @media (min-width: desktop) {
      margin -16px -16px 0
    }
  }

  &__title {
    font_bold();
    color: #fff;
  }

  &__body {
    padding: 10px 6px;

    @media (min-width: desktop) {
      padding: 10px 0 0;
    }
  }

  &__inputBox {
    margin-bottom: 16px;
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    font_small();
  }

  &__input {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: calc(100% - 40px);
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    @media (min-width: desktop) {
      width: 400px;
    }
  }

  &__copy {
    width: 14px;
    height: 16px;
    background-color: unset;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M4.667 1.6h7.777v9.6H10.89v-8H4.667zm4.666 3.2v9.6H1.556l.002-9.6zM.786 3.2a.791.791 0 0 0-.784.8L0 15.2c0 .213.083.417.23.567.147.15.346.234.553.233h9.323a.791.791 0 0 0 .783-.8v-2.4h2.333A.79.79 0 0 0 14 12V.8a.79.79 0 0 0-.778-.8H3.89a.79.79 0 0 0-.778.8v2.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
  }
}

.createLinkButtons {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 9px;
}

.createLinkButton {
  position: relative;
  padding: 7px 8px 7px 28px;
  border: 1px dashed main_text_color;
  background-color: unset;
  border-radius: 4px;
  color: #0F1642;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  &::before {
    position: absolute;
    content: '';
    top: 6px;
    left: 4px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
  }
}
</style>
