<template>
  <div class="underLoader">
    <div class="loaderIcon" v-if="pending">
      <loader />
    </div>
    <div class="contactsProfileInfo">
      <div class="contactsProfileInfoInner">
        <!-- NAME AND MESSENGERS -->
        <div class="contactsProfileInfoBox">
          <fieldset
            class="
              contactsProfileInfo__fieldset contactsProfileInfo__fieldsetName
            "
          >
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="Имя"
              :value="contact.first_name"
              labelId="first_name"
              :inputWidth="240"
              @handle-input="changeNameMessengers"
            />
            <div class="error" v-if="errors && errors.first_name">
              {{ errors.first_name[0] }}
            </div>
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="Фамилия"
              :value="contact.last_name"
              labelId="last_name"
              :inputWidth="240"
              @handle-input="changeNameMessengers"
            />
            <div class="error" v-if="errors && errors.last_name">
              {{ errors.last_name[0] }}
            </div>
          </fieldset>

          <fieldset
            class="
              contactsProfileInfo__fieldset contactsProfileInfo__fieldsetSocials
            "
          >
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="Telegram"
              :value="contact.telegram"
              labelId="telegram"
              :inputWidth="240"
              @handle-input="changeNameMessengers"
              :placeholder="placeholderTelegram"
            />
            <div class="error" v-if="errors && errors.telegram">
              {{ errors.telegram[0] }}
            </div>
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="WhatsApp"
              :value="contact.whatsapp"
              labelId="whatsapp"
              :inputWidth="240"
              :placeholder="placeholderOnlyDigit"
              @handle-input="changeNameMessengers"
              type="text"
            />
            <div class="error" v-if="errors && errors.whatsapp">
              {{ errors.whatsapp[0] }}
            </div>
          </fieldset>

          <fieldset
            class="
              contactsProfileInfo__fieldset contactsProfileInfo__fieldsetName
            "
          >
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="Страна"
              :value="contact.country"
              labelId="country"
              :inputWidth="240"
              @handle-input="changeNameMessengers"
            />
            <div class="error" v-if="errors && errors.country">
              {{ errors.country[0] }}
            </div>
            <contacts-profile-info-input
              class="contactsProfileInfo__input"
              title="Город"
              :value="contact.city"
              labelId="city"
              :inputWidth="240"
              @handle-input="changeNameMessengers"
            />
            <div class="error" v-if="errors && errors.city">
              {{ errors.city[0] }}
            </div>
          </fieldset>
        </div>
        <!-- NAME AND MESSENGERS -->

        <!-- CONTACT WAY -->
        <div class="ContactsProfileInfoBox">
          <contacts-profile-info-add-input
            :contactWayArray="contact.phones_objects"
            contactWay="phones_objects"
            @add-contact-way-in-array="addContactWayInArray"
            @change-contact-way-value="changeContactWayValue"
            @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
            @del-contact-way-in-array="delContactWayInArray"
            class="contactsProfileInfo__addInput"
            type="text"
            :placeholder="placeholderOnlyDigit"
            title="Телефон"
          />
          <div class="error error_addInput" v-if="phones_errors">
            {{ phones_errors }}
          </div>

          <contacts-profile-info-add-input
            :contactWayArray="contact.emails_objects"
            contactWay="emails_objects"
            @add-contact-way-in-array="addContactWayInArray"
            @change-contact-way-value="changeContactWayValue"
            @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
            @del-contact-way-in-array="delContactWayInArray"
            class="contactsProfileInfo__addInput"
            type="email"
            title="Эл. почта"
          />
          <div class="error error_addInput" v-if="emails_errors">
            {{ emails_errors }}
          </div>

          <contacts-profile-info-add-input
            :contactWayArray="contact.links_objects"
            contactWay="links_objects"
            @add-contact-way-in-array="addContactWayInArray"
            @change-contact-way-value="changeContactWayValue"
            @add-new-empty-contact-way-value="addNewEmptyContactWayValue"
            @del-contact-way-in-array="delContactWayInArray"
            class="contactsProfileInfo__addInput"
            title="Соц. сети"
          />
          <div class="error error_addInput" v-if="links_errors">
            {{ links_errors }}
          </div>
          <contacts-create-tags
            :dropdownsGroup="tagsCheckboxGroup"
            :isFilterChecked="isFilterChecked"
            :filterValue="tagsFilterValue"
            :filterCounter="tagsFilterCounter"
            :contactTags="true"
            :contact="contact"
            :contactTagsArray="contactTags"
            :allErrors="allErrors"
            @change="changeTags"
            @change-filter-value="changeTagsFilterValue"
            @change-filter-counter="changeTagsFilterCounter"
          />
        </div>
        <!-- CONTACT WAY -->
      </div>

      <FormSubmit
        @click="changeContact"
        :disabled="contact.first_name && contact.first_name.length === 0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { runNotice } from "@/utils/notifications";

import FormSubmit from "@/components/FormSubmit.vue";
import ContactsProfileInfoInput from "@/components/Contacts/ContactsProfileInfoInput.vue";
import ContactsProfileInfoAddInput from "@/components/Contacts/ContactsProfileInfoAddInput.vue";
import Loader from "@/components/Loader.vue";
import ContactsCreateTags from "@/components/Contacts/ContactsCreateTags.vue";

export default {
  name: "ContactsProfileInfo",
  components: {
    FormSubmit,
    ContactsProfileInfoInput,
    ContactsProfileInfoAddInput,
    Loader,
    ContactsCreateTags,
  },

  computed: {
    ...mapGetters({
      pending: "contactsProfile/pending",
      contact: "contactsProfile/contact",
      errors: "contactsProfile/errors",
      phones_errors: "contactsProfile/phones_errors",
      emails_errors: "contactsProfile/emails_errors",
      links_errors: "contactsProfile/links_errors",
      responses: "contactsProfile/responses",
      isFilterChecked: "contactsProfile/isFilterChecked",
      tagsFilterValue: "contactsProfile/tagsFilterValue",
      tagsFilterCounter: "contactsProfile/tagsFilterCounter",
      contactTags: "contactsProfile/contactTags",
    }),
    ...mapState("contactsTags", ["tags"]),

    allErrors() {
      if (
        this.errors && Object.values(this.errors).length > 0 ||
        this.phones_errors && Object.values(this.phones_errors).length > 0 ||
        this.emails_errors && Object.values(this.emails_errors).length > 0 ||
        this.links_errors && Object.values(this.links_errors).length > 0
      ) {
        return true;
      }

      return false;
    },

    placeholderTelegram() {
      return this.$t("Укажите логин пользователя");
    },

    placeholderOnlyDigit() {
      return this.$t("Только цифры");
    },

    phones_errors() {
      return this.errors.phones_objects ? this.errors.phones_objects.find(el => Object.keys(el).length > 0).value[0] : null;
    },
    emails_errors() {
      return this.errors.emails_objects ? this.errors.emails_objects.find(el => Object.keys(el).length > 0).value[0] : null;
    },
    links_errors() {
      return this.errors.links_objects ? this.errors.links_objects.find(el => Object.keys(el).length > 0).link[0] : null
    },

    tagsCheckboxGroup() {
      return {
        title: "Теги",
        name: "tags",
        array: this.tags,
      };
    },
  },

  methods: {
    async changeContact() {
      try {
        await this.$store.dispatch("contactsProfile/changeContact");
        runNotice("success", this.$t("Информация по контакту успешно обновлена"));
      } catch (e) {
        console.log(e);
      }
    },

    // change name and messengers
    changeNameMessengers({ param, value }) {
      this.$store.commit(`contactsProfile/CHANGE_NAME_MESSENGERS`, { param, value });
      this.$store.commit(`contactsProfile/CLEAR_ERRORS`);
    },

    // add new input if empty
    addNewEmptyContactWayValue(name) {
      this.$store.commit(`contactsProfile/ADD_NEW_EMPTY_CONTACT_WAY_VALUE`, name);
    },

    // add new input
    addContactWayInArray(contactWay) {
      this.$store.commit(`contactsProfile/ADD_CONTACT_WAY_IN_ARRAY`, contactWay);
    },

    // change input
    changeContactWayValue({ name, id, value }) {
      this.$store.commit(`contactsProfile/CHANGE_CONTACT_WAY_IN_ARRAY_VALUE`, { name, value, id });
      this.$store.commit(`contactsProfile/CLEAR_ERRORS_${name.toUpperCase()}`);
    },

    // del input
    delContactWayInArray(payload) {
      this.$store.commit(`contactsProfile/DEL_CONTACT_WAY_IN_ARRAY`, payload);
    },

    changeTags(value) {
      this.$store.commit("contactsProfile/SET_TAGS", value);
    },

    changeTagsFilterValue(value) {
      this.$store.commit("contactsProfile/SET_TAGS_FILTER_VALUE", value);
    },

    changeTagsFilterCounter(value) {
      this.$store.commit("contactsProfile/SET_TAGS_FILTER_COUNTER", value);
    },
  },

  mounted() {
    this.$store.commit(`contactsProfile/CLEAR_ERRORS_PHONES_OBJECTS`);
    this.$store.commit(`contactsProfile/CLEAR_ERRORS_EMAILS_OBJECTS`);
    this.$store.commit(`contactsProfile/CLEAR_ERRORS_LINKS_OBJECTS`);
    this.$store.commit(`contactsProfile/CLEAR_ERRORS`);
  }
};
</script>

<style lang="stylus" scoped>
.contactsProfileInfoInner {
  display: grid;
  grid-row-gap: 20px;
}

.contactsProfileInfo__input {
  margin-bottom: 12px;
}

.contactsProfileInfo__fieldset {
  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.contactsProfileInfo__addInput {
  &:not(:last-child) {
    margin-bottom: 22px;
  }
}

@media (min-width: tablet) {
  .contactsProfileInfoInner {
    grid-auto-flow: column;
    grid-column-gap: 32px;
    grid-row-gap: unset;
    width: fit-content;
  }
}

.underLoader {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.error {
  margin-top: -10px;
  font_small();
  color: danger_text_color;

  &_addInput {
    margin-top: -20px;
  }
}
</style>
