<template>
  <fieldset class="goodQuest__fieldsetTextarea goodQuest__fieldsetTask">
    <label class="checking__label">
      <Tr v-if="label">{{ label }}</Tr>
      <select class="checking__select" v-model="selectModel">
        <option
          :key="`${item.value}_${item.label}`"
          :value="item.value"
          v-for="item in options"
        >
          {{ $t(item.label) }}
        </option>
      </select>
    </label>
  </fieldset>
</template>

<script>
export default {
  name: "LeadershipSelectbox",
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    selectModel: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("change-select", value);
      },
    },
  },
  methods: {
    // changeOption(e) {
    //   const { value } = e.target;
    //   this.$emit("change-select", value);
    // },
  },
};
</script>

<style lang="stylus" scoped>
.checking__label {
  display: block;
  margin-top: 10px;
  margin-bottom: 11px;
  font_small();
}

.checking__select {
  display: block;
  margin-top: 3px;
  padding: 5px 2px;
  width: 100%;
  max-width: 400px;
  border: 2px solid grey_text_color;
  border-radius: 2px;
  font_regular();

  @media (min-width: tablet_large) {
    width: 400px;
    max-width: unset;
  }
}
</style>
