<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />
    <h1 class="title">
      <Tr>Объединение</Tr>
    </h1>
    <contacts-groups-container />
  </main>
</template>

<script>
import ContactsGroupsContainer from "@/containers/MergeContacts/ContactsGroupsContainer.vue";

export default {
  name: "ContactsGroups",
  components: {
    ContactsGroupsContainer,
  },

  data() {
    return {
      breadcrumbs: [{ title: "Контакты", link: "/contacts" }],
    };
  },
};
</script>

<style lang="stylus" scoped>
.main {
  @media (min-width: 768px) {
    width: auto;
  }
}

.titleIsHided {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
</style>
