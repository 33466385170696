import createLocales from "@/utils/createLocales";
import getAwards from "@/utils/getAwards";
import supportedLangs from "@/utils/supportedLangs";
import { omit, pick } from "ramda";
import Universal from ".";

class MentorCheck extends Universal {
  constructor(data?) {
    if (data) {
      super(data);
    } else {
      super({
        id: 0,
        locales: {
          ...createLocales(["content"], supportedLangs(), ""),
        },
        ...getAwards(),
      });
    }
  }

  getCreateModel() {
    const model = this.transformLocales();

    return omit(["id"], model);
  }
}

export default MentorCheck;
