<template>
  <div id="app">
    <router-view v-if="hasUser && !techWorks" />
    <!-- <router-view v-if="techWorks" /> -->
    <tech-works-placeholder v-if="techWorks" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Cookies from "js-cookie";

import TechWorksPlaceholder from "@/components/TechWorksPlaceholder.vue";
// import { setToken } from "@/utils/cookies/setToken";

export default {
  name: "App",
  components: {
    TechWorksPlaceholder,
  },
  computed: {
    ...mapGetters({
      hasUser: "user/id",
      techWorks: "user/techWorks",
      userTimezone: "user/userTimezone",
      usersCompanySlug: "user/usersCompanySlug",
    }),
  },

  watch: {
    usersCompanySlug(val) {
      localStorage.setItem('company', val);
    },
  },

  mounted() {
    // setToken("2d0f677c6df37cc89e3fa52e221fca8db3cdbe91");
    // setToken("c55f41fca0711897cffddac4f2c1c0240d8c3846");
    // setToken("297726db674a340ab8c1828e0c513c793cde83e7");
    // setToken("395d9e7953aba351e299fa75d4df75790e70d0bb");
    if (this.$route.query && this.$route.query.token) {
      this.$store.dispatch("user/urlAuth", this.$route.query.token);
      this.$router.replace({ query: null });
    }

    if (!Cookies.get("auth")) {
      const companySlug = localStorage.getItem('company') ?? 'OTON';
      const companyUrl = process.env[`VUE_APP_MAIN_${companySlug.toUpperCase()}_URL`];
      window.location = `${companyUrl}/login`;
    }


    if (this.techWorks) {
      this.$router.replace("/dashboard", { query: null });
    }

    this.$store.dispatch("user/fetch")
      .then(() => {
        if (this.userTimezone.length === 0) {
          this.$store.dispatch("user/setTimezone", moment.tz.guess());
        }
      })
      .catch((e) => { console.log(e) });
  },
};
</script>

<style lang="stylus">
@import './assets/styles/reset.css';
@import './assets/styles/main.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

* {
  color: var(--main_text_color);
}

*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
}

html, body, #__nuxt, #__layout {
  min-height: 100vh;
}

#layout {
  background: #fff;
}

.container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
}

button, textarea, input, select {
  font-family: 'Montserrat', sans-serif;
}

.content {
  position: relative;
  flex-grow: 1;
  padding: 4px 8px;
  min-height: 455px;
  min-height: 495px;
  z-index: 3;

  @media (min-width: desktop) {
    margin-left: 240px;
    padding: 7px 10px 0px 16px;
    min-height: 488px;
    min-height: 528px;
  }
}

.underLoader {
  position: relative;
}

.title {
  font-size: 24px;
  line-height: 29px;
  color: #0f1642;
  font-weight: 700;
  margin-bottom: 13px;
  word-break: break-word;

  @media (min-width: tablet_large) {
    font-size: 44px;
    line-height: 54px;
  }
}

.toasted {
  font-family: 'Montserrat', sans-serif;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 5px;
    padding: 5px 10px 4px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 13px;
    word-break: break-word;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^='right'] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^='left'] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    .popover-inner {
      // background: #f9f9f9;
      color: black;
      padding: 6px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, 0.1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.vm--modal {
  overflow-y: auto !important;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;
}

.vm--modal::-webkit-scrollbar {
  width: 12px;
}

.vm--modal::-webkit-scrollbar-track {
  background: #fff;
}

.vm--modal::-webkit-scrollbar-thumb {
  background-color: #e6e7eb;
  border: 2px solid #fff;
  border-radius: 6px;
}

.vm--container#leads-modal-product {
  @media (min-width: tablet) {
    position: absolute;
    margin-top: 20vh;
  }
}

.datePickerInput {
  padding: 7px 8px;
  width: 90px;
  height: 34px;
  border: 1px solid grey_text_color !important;
  border-radius: 2px;

  &~.mx-icon-calendar {
    display: none;
  }
}

.mx-time-content .mx-time-column .mx-time-list::after {
  height: auto;
}

.tooltip {
  .tooltip-inner {
    background: #fff;
    color: white;
    padding: 4px;
    border-radius: 5px;
    box-shadow: 0 5px 30px rgba(black, 0.1);
    max-width: 250px;
    color: black;
    word-break: break-word;
  }

  .tooltip-arrow {
    border-color: #fff;
  }

  &.leadsCreateTaskTooltip {
    opacity: 0;

    @media (min-width: desktop) {
      opacity: 1;
    }

    @media (min-width: desktopLeads) {
      opacity: 0;
    }
  }
}

.newsItemText {
  &>a {
    font-weight: 400;
    color: #3489f8;
    text-decoration: underline;
  }

  &>b {
    font-weight: 700;
  }
}

.contactsBodyItemAddTags .v-popover {
  display: flex;
}
</style>
