var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tabListBox"},[_c('ul',{staticClass:"tabList",class:{ tabList_mobile: _vm.mobileBtns }},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.title,staticClass:"tabItem",class:{ tabItem_mobile: _vm.mobileBtns }},[(tab.link)?_c('base-link',{staticClass:"tabButton tabLink",class:{ tabButton_active: _vm.activeTab === tab.name },attrs:{"to":tab.link}},[_c('Tr',[_vm._v(_vm._s(tab.title))])],1):_c('button',{staticClass:"tabButton activeItem",class:[
          { tabButton_mobile: _vm.mobileBtns },
          {
            tabButton_createLink:
              tab.name === 'paymentLink' || tab.name === 'webinarLink',
          },
          { tabButton_createLink_payment: tab.name === 'paymentLink' },
          { tabButton_createLink_webinar: tab.name === 'webinarLink' },
          { tabButton_active: _vm.activeTab === tab.name } ],on:{"click":function($event){return _vm.tabClicked(tab.name)}}},[_c('Tr',[_vm._v(_vm._s(tab.title))])],1)],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }