<template>
  <div class="container">
    <div class="container__box">
      <leadership-input
        :value="name"
        :title="$t('Название')"
        :maxLength="128"
        @handle-name="changeName"
      />
      <leadership-textarea
        :value="description"
        :title="$t('Описание')"
        :maxLength="5000"
        @handle-description="changeDescription"
      />
    </div>
    <button class="autowebinar__uploadButton" @click="save">
      <base-icon-svg
        class="autowebinar__uploadSvg"
        width="19"
        height="16"
        viewbox="0 0 19 16"
      >
        <upload-video />
      </base-icon-svg>
      <Tr>Загрузить видео</Tr>
    </button>
  </div>
</template>

<script>
import LeadershipInput from "@/components/Leadership/LeadershipInput.vue";
import LeadershipTextarea from "../Leadership/LeadershipTextarea.vue";
import UploadVideo from "@/components/Leadership/AutowebinarIcons/UploadVideo.vue";
import { runNotice } from "@/utils/notifications";

export default {
  name: "AddVideoModal",
  components: {
    LeadershipInput,
    LeadershipTextarea,
    UploadVideo,
  },
  props: {
    name: String,
    description: String,
  },
  methods: {
    changeName(val) {
      this.$emit("change-name", val);
    },
    changeDescription(val) {
      this.$emit("change-description", val);
    },
    save() {
      if (this.name.trim()) {
        this.$emit("save");
      } else {
        runNotice("error", this.$t("Поле 'Название' обязательно к заполнению"));
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.container {
  padding: 40px 26px;
}

.autowebinar__uploadButton {
  max-width: 200px;
  margin-top: 20px;
}
</style>
