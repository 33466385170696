import { mapObjIndexed, move, slice } from "ramda";
import changeSlidesTimings from "../videoEditor/changeSlidesTimings";
import { slideToPixels } from "../videoEditor/transformSlide";

export const transformResponse = (response, videoDuration) => {
  const slides: any = slice(0, Infinity, response.slides);
  // const slides = response.slides;
  return {
    ...response,
    slides: slides.map((slide, index) =>
      slideToPixels({
        slide,
        videoDuration,
        nextSlide: response.slides[index + 1],
      })
    ),
  };
};

export const changeSlidePosition = (offset, oldIndex, slides) => {
  let newIndex;
  for (let index = 0; index < slides.length; index++) {
    const element = slides[index];

    if (element.start < offset && offset < element.end) {
      newIndex = index;
      break;
    }
  }

  slides = move(oldIndex, newIndex, slides);
  slides = changeSlidesTimings(slides);

  return slides;
};

export const transformSlidesToUpdate = (slides, newSlidesCount) => {
  const MIN_SLIDE_WIDTH = 20;
  const endOffset = MIN_SLIDE_WIDTH * newSlidesCount;
  const requestObject = {};
  const reversedSLides = slides.reverse();
  let changeTiming = false;

  for (let index = 0; index < reversedSLides.length; index++) {
    const slide = reversedSLides[index];
    const prevSlide = reversedSLides[index - 1];

    if (index === 0) {
      const slideOffset = slide.end - slide.start - endOffset;
      if (slideOffset >= MIN_SLIDE_WIDTH) {
        requestObject[slide.id] = {
          seconds: slide.start,
        };
      } else {
        changeTiming = true;
        requestObject[slide.id] = {
          seconds: slide.end - (endOffset + MIN_SLIDE_WIDTH),
        };
        slide.start = slide.end - (endOffset + MIN_SLIDE_WIDTH);
      }
    } else {
      if (prevSlide.start - slide.start <= MIN_SLIDE_WIDTH) {
        if (changeTiming) {
          requestObject[slide.id] = {
            seconds: prevSlide.start - MIN_SLIDE_WIDTH,
          };
          slide.start = prevSlide.start - MIN_SLIDE_WIDTH;
        } else {
          requestObject[slide.id] = {
            seconds: slide.start,
          };
        }
      } else {
        changeTiming = false;
        requestObject[slide.id] = {
          seconds: slide.start,
        };
      }
    }
  }

  return requestObject;
};

export const filesToPost = ({ files, videoDuration }) => {
  const MIN_SLIDE_WIDTH = 20;
  const arr: any = [];
  const fData = new FormData();

  mapObjIndexed((file: any, index) => {
    const obj = {
      file,
      seconds: (
        videoDuration -
        MIN_SLIDE_WIDTH * (Number(index) + 1)
      ).toString(),
      type: "image",
    };

    arr.push(obj);
    return file;
  }, files);

  arr.map((obj, index) => {
    Object.entries(obj).forEach((item: any) => {
      fData.append(`${index}[${item[0]}]`, item[1]);
    });
  });

  return fData;
};
