<template>
  <modal
    :name="name"
    :shiftY="0.2"
    :width="modalWidth"
    :height="modalHeight"
    :min-height="200"
    :adaptive="true"
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="modal">
      <!-- HEADER -->
      <div class="modalHeader">
        <div class="modalTitle">
          <Tr><slot name="title" /></Tr>
        </div>
        <button class="modalCloseButton" type="button" @click="hideModal">
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="loader" v-if="pending">
        <loader />
      </div>
      <div :class="['modalBody', { layout: pending }]">
        <slot name="body" />
      </div>
      <!-- BODY -->
    </div>
  </modal>
</template>

<script>
import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "ModalLayout",

  components: {
    CloseButton,
    Loader,
  },

  props: {
    name: {
      type: String,
      required: true,
    },

    modalWidth: {
      type: Number,
      default: 640,
    },

    pending: Boolean,

    modalHeight: {
      type: String,
      default: "auto",
    },
  },

  methods: {
    beforeOpen(e) {
      this.$emit("before-open", e);
    },

    beforeClose() {
      this.$emit("before-close");
    },

    hideModal() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="stylus" scoped>
.modal {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  height: 100%;

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    // width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }
}

.modalHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-right: -10px;
  padding-bottom: 7px;
  margin-left: -10px;
  padding-right: 10px;
  padding-left: 10px;
  border-bottom: 2px solid grey_text_color;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;

  @media (min-width: tablet) {
    padding-bottom: 8px;
    align-items: unset;
    border-bottom: unset;
  }
}

.modalTitle {
  font(main_text_color, 16px, 700, 20px);
  width: 92%;

  @media (min-width: tablet) {
    margin-bottom: 4px;
    font_subtitle();
  }
}

.modalCloseButton {
  position: relative;
  top: 3px;
  right: 6px;
  display: block;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: unset;
  border: none;
}

.modalBody {
  flex-grow: 1;
  padding-top: 14px;
  padding-bottom: 12px;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: grey_text_color #fff;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: grey_text_color;
    border: 2px solid #fff;
    border-radius: 6px;
  }

  @media (min-width: tablet) {
    padding-top: 0;
    padding-right: 5px;
    align-items: unset;
    border-bottom: unset;
  }
}

.loader {
  position: absolute;
}

.layout {
  opacity: 0.6;
}
</style>
