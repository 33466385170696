<template>
  <a :href="homeLink + '/dashboard'" class="logo__link">
    <img v-if="!!specialLogo" alt class="logo__img" :src="specialLogo" />
    <!-- <img v-else alt class="logo__img" src="@/assets/svg/tasq_logo.svg" /> -->
  </a>
</template>

<script>
export default {
  name: "HeaderLogo",
  components: {},
  computed: {
    homeLink() {
      const usersCompanySlug = this.$store.getters["user/usersCompanySlug"];
      if (usersCompanySlug) {
        const slug = usersCompanySlug.toUpperCase();

        return process.env[`VUE_APP_MAIN_${slug}_URL`];
      }
      return process.env.VUE_APP_MAIN_URL;
    },
    specialLogo() {
      const userslogo = this.$store.getters["user/usersCompanyLogo"];
      const usersCompanySlug = this.$store.getters["user/usersCompanySlug"];

      if (userslogo && usersCompanySlug) {
        const slug = usersCompanySlug.toUpperCase();

        return process.env[`VUE_APP_MAIN_${slug}_URL`] + userslogo;
      }
      return undefined;
    },
  },
};
</script>

<style lang="stylus" scoped>
.logo__link {
  display: block;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.logo__img {
  display: block;
  width: 75px;
  height: auto;
  max-height: 20px;

  @media (min-width: desktop) {
    width: 120px;
    max-height: 32px;
  }
}
</style>
