<template>
  <div class="leadsCreateTask__head">
    <span
      class="leadsCreateTask__headCell"
      v-for="head in headSpans"
      :key="head.name"
    >
      <Tr>{{ head.text }}</Tr>
    </span>
    <!-- <button
      type="button"
      @click="$emit('change-sort', head.sortType)"
      class="leadsCreateTask__headCell leadsCreateTask__headCell_btn"
      v-for="head in headBtns"
      :key="head.name"
    >
      <Tr>{{ head.text }}</Tr>
    </button> -->
  </div>
</template>

<script>
export default {
  name: "LeadsModalCalssHistoryTableHead",
  data() {
    return {
      headSpans: [
        { name: "avatar", text: "" },
        { name: "lead", text: "Лид" },
        { name: "phone", text: "Телефон" },
        {
          name: "dateTime",
          text: "Дата и время",
        },
        {
          name: "duration",
          text: "Продолжительность",
        },
      ],
      headBtns: [
        {
          name: "dateTime",
          text: "Дата и время",
          sortType: "created_at",
        },
        {
          name: "duration",
          text: "Продолжительность",
          sortType: "duration_seconds",
        },
      ],
    };
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateTask__headCell {
  font(main_text_color, 10px, 400, 12px);
  overflow: hidden;

  &_btn {
    display: inline-block;
    padding: 0;
    border: none;
    background-color: unset;
    border-bottom: 2px dotted main_text_color;

    &:hover {
      opacity: 0.6;
    }
  }

  @media (min-width: tablet) {
    font_bold();

    &_btn {
      width: fit-content;
    }
  }
}
</style>
