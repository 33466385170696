<template>
  <fieldset class="dropdownsFieldset">
    <legend class="dropdownsLegend">
      <Tr>{{ title }}</Tr>
    </legend>

    <div class="dropdownBox">
      <div class="datePickerBox">
        <date-picker
          v-if="date"
          v-model="start"
          type="date"
          format="DD MMM"
          :lang="
            ['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale
          "
          input-class="datePickerInput"
        />
        <template v-else>
          <input
            class="datePickerInput"
            type="number"
            v-maxlen="2"
            :value="webinar_progress_from"
            @input="handleWebinarProgressFrom"
            @blur="handleEmptyWebinarProgressFrom"
          />
        </template>
      </div>

      <span class="dropdownBox__separator">...</span>

      <div class="datePickerBox">
        <date-picker
          v-if="date"
          v-model="end"
          type="date"
          format="DD MMM"
          :lang="
            ['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale
          "
          input-class="datePickerInput"
        />
        <template v-else>
          <input
            class="datePickerInput"
            type="number"
            v-maxlen="3"
            :value="webinar_progress_to"
            @input="handleWebinarProgressTo"
          />
        </template>
      </div>
    </div>
  </fieldset>
</template>

<script>
import ClickOutside from "vue-click-outside";
import DatePicker from "vue2-datepicker";

export default {
  name: "UpdatedLeadsFilterDoubleDropdownsGroup",
  components: {
    DatePicker,
  },
  props: {
    title: String,
    start_created_at: [String, Date],
    finish_created_at: [String, Date],
    webinar_progress_from: [String, Number],
    webinar_progress_to: [String, Number],
    date: Boolean,
  },
  data() {
    return {
      requestDelay: 500,
    };
  },
  computed: {
    end: {
      get() {
        return this.finish_created_at;
      },
      set(value) {
        this.$emit("change", { value, name: "finish_created_at" });
      },
    },
    start: {
      get() {
        return this.start_created_at;
      },
      set(value) {
        this.$emit("change", { value, name: "start_created_at" });
      },
    },
  },
  methods: {
    handleWebinarProgressFrom(e) {
      if (e.target.value >= 0 && e.target.value < 100) {
        setTimeout(() => {
          this.$emit("change", {
            value: e.target.value,
            name: "webinar_progress_from",
          });
        }, this.requestDelay);
      } else if (e.target.value > 99) {
        this.$emit("change", { value: 99, name: "webinar_progress_from" });
      }
    },
    handleEmptyWebinarProgressFrom(e) {
      if (e.target.value === "") {
        this.$emit("change", { value: "0", name: "webinar_progress_from" });
      }
    },
    handleWebinarProgressTo(e) {
      if (e.target.value >= 0 && e.target.value < 101) {
        setTimeout(() => {
          this.$emit("change", {
            value: e.target.value,
            name: "webinar_progress_to",
          });
        }, this.requestDelay);
      } else if (e.target.value > 100) {
        this.$emit("change", { value: 100, name: "webinar_progress_to" });
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="stylus" scoped>
.dropdownsFieldset {
  position: relative;
  display: inline-block;
  width: 220px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.dropdownsLegend {
  margin-bottom: 8px;
  font_bold();
}

.dropdownBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__separator {
    margin: 0 5px;
  }
}

.datePickerBox {
  & .mx-datepicker {
    width: 90px;
    height: 34px;
  }
}
</style>
