<template>
  <modal
    name="create-task"
    @before-open="beforeOpen"
    :width="640"
    height="auto"
    :adaptive="true"
  >
    <div class="leadsCreateTask">
      <!-- HEADER -->
      <div class="leadsCreateTask__header">
        <div class="leadsCreateTask__headerName">
          <Tr>Создать задачу</Tr>
        </div>
        <button
          class="leadsCreateTask__headerButton"
          type="button"
          @click="hideCreateTask"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="leadsCreateTask__body">
        <!-- TEXTAREA -->
        <div class="leadsCreateTask__textareaWrap leadsCreateTask__bodyInner">
          <label for="leadsModalTextarea" class="leadsCreateTask__label">
            <Tr>Описание</Tr>
          </label>
          <textarea
            name="leadsModalTextarea"
            id="leadsModalTextarea"
            :value="description"
            :maxlength="maxLength"
            @input="handleChange"
            class="leadsCreateTask__textarea"
          />
          <div class="leadsCreateTask__textareaLength">
            <span class="leadsCreateTask__textareaLengthSpan">
              <span
                :class="{
                  leadsCreateTask__textareaLengthSpan_red:
                    valueLength === maxLength,
                }"
                >{{ valueLength }}</span
              >
              /{{ maxLength }}
            </span>
          </div>
        </div>
        <!-- TEXTAREA -->

        <!-- DATEPICKER -->
        <div class="leadsCreateTask__datePickerWrap">
          <label for="leadsModalTaskType" class="leadsCreateTask__label">
            <Tr>Дата и время</Tr>
          </label>
          <date-picker
            v-model="ended_at"
            type="datetime"
            valueType="timestamp"
            format="DD MMM YYYY, HH:mm"
            :lang="['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale"
            :showSecond="false"
            :showMinute="true"
            :default-value="new Date().setHours(10, 0, 0, 0)"
            :disabled-date="notBeforeToday"
          />
        </div>
        <!-- DATEPICKER -->
      </div>
      <!-- BODY -->

      <!-- FOOTER -->
      <div class="leadsCreateTask__footer">
        <button
          class="leadsCreateTask__submit"
          @click="handleCreate"
          :disabled="createTaskBtnDisabled"
        >
          <Tr>Создать</Tr>
        </button>
      </div>
      <!-- FOOTER -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";
import { path } from "ramda";

import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import DatePicker from "vue2-datepicker";

export default {
  name: "LeadsModalCreateTask",
  components: {
    CloseButton,
    DatePicker,
  },
  data() {
    return {
      description: "",
      ended_at: "",

      selected: {},

      maxLength: 264,

      createTaskBtnDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      errors: "leadsTaskCreate/errors",
      userId: "user/userId",
      isDev: "user/isDev",
    }),
    valueLength() {
      if (!this.description) {
        return 0;
      }
      return this.description.length;
    },
  },
  methods: {
    hideCreateTask() {
      this.$modal.hide("create-task");
    },
    handleChange(e) {
      this.description = e.target.value;
    },
    isFormValid() {
      if (!path(["selected", "id"], this)) {
        runNotice("error", this.$t("Не выбран лид"));
        return false;
      }
      if (!this.description) {
        runNotice("error", this.$t("Не заполнено описание"));
        return false;
      }
      if (!this.ended_at) {
        runNotice("error", this.$t("Не заполнена дата"));
        return false;
      }
      return true;
    },
    handleCreate() {
      if (!this.isFormValid()) {
        return;
      }

      const date = new Date(this.ended_at).toISOString();

      const contentType = () => {
        return this.isDev ? 7 : 6;
      };

      const data = {
        object_id: this.userId,
        lead_service_lead_id: this.selected.id,
        lead_service_lead_name: this.selected.trigger.name,
        content_type: contentType(),
        description: this.description,
        ended_at: date,
        type: "Работа с лидом",
      };

      this.createTaskBtnDisabled = true;

      Promise.all([
        this.$store.dispatch("leadsTaskCreate/createTask", data),
      ]).then(response => {
        if (response) {
          if (response[0] && response[0].status === 201) {
            this.hideCreateTask();
            this.$store.dispatch("user/fetch");
            runNotice("success", this.$t("Задача создана"));
          } else {
            this.createTaskBtnDisabled = false;
            runNotice("error", this.$t("Не удается создать задачу"));
          }
        }
      });
    },
    beforeOpen(event) {
      this.selected = event.params.lead;
      this.description = "";
      this.ended_at = "";
      this.createTaskBtnDisabled = false;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  watch: {
    errors(value) {
      Object.values(value.data.errors).forEach(el => runNotice("error", el));
    },
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateTask {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-right: -10px;
    padding-bottom: 7px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__headerButton {
    position: relative;
    top: 3px;
    right: 6px;
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;
    padding-bottom: 12px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__bodyInner {
    margin-bottom: 12px;
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    font(main_text_color, 12px, 400, 16px);
  }

  &__textareaWrap {
    position: relative;
    margin-top: -2px;
  }

  &__textareaLength {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__textareaLengthSpan {
    font_small();
    opacity: 0.6;

    & span {
      font-weight: 700;
    }

    &_red {
      color: #e81526;
    }
  }

  &__textarea {
    display: block;
    padding: 6px 7px;
    width: 100%;
    height: 120px;
    border: 1px solid grey_text_color;
    font_regular();
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: grey_text_color #fff;

    &.empty {
      border: 1px solid rgba(danger_text_color, 0.5);
    }

    &_answer {
      height: 32px;
      overflow: hidden;
    }
  }

  &__textarea::-webkit-scrollbar {
    width: 12px;
  }

  &__textarea::-webkit-scrollbar-track {
    background: #fff;
  }

  &__textarea::-webkit-scrollbar-thumb {
    background-color: grey_text_color;
    border: 2px solid #fff;
    border-radius: 6px;
  }

  &__footer {
    margin: 0 -16px;
    padding: 20px 16px 0;
    border-top: 2px solid grey_text_color;
  }

  &__submit {
    padding: 11px 12px;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    font(#fff, 14px, 400, 18px);
    border: none;
    border-radius: 4px;

    &:hover {
      opacity: 0.6;
    }
  }

  &__datePickerWrap {
    & .mx-datepicker {
      width: 240px;
    }
  }
}
</style>
