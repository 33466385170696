<template>
  <div class="menu__linkInner">
    <div class="menu__txt">
      <base-icon-svg
        class="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="unset"
      >
        <contacts v-if="link.name === 'contacts'" />
        <courses v-else-if="link.name === 'courses'" />
        <dashboard v-else-if="link.name === 'dashboard'" />
        <funnel-constructor v-else-if="link.name === 'funnel_constructor'" />
        <funnel v-else-if="link.name === 'funnel'" />
        <leadership v-else-if="link.name === 'leadership'" />
        <leads
          v-else-if="link.name === 'leads' || link.name === 'updatedLeads'"
        />
        <assignment
          v-else-if="
            link.name === 'assignment' || link.name === 'updatedAssignment'
          "
        />
        <mentoring v-else-if="link.name === 'mentoring'" />
        <messages v-else-if="link.name === 'messages'" />
        <tasks v-else-if="link.name === 'tasks'" />
        <team v-else-if="link.name === 'team'" />
        <contest v-else-if="link.name === 'contest'" />
      </base-icon-svg>
      <span v-if="link.noTr">{{ link.title }}</span>
      <Tr v-else>{{ link.title }}</Tr>
    </div>
    <div class="menu__countWrap" v-if="link.name !== 'funnel'">
      <span
        class="menu__count menu__count_active"
        :class="{
          menu__count_negative: counts < 0,
          menu__count_positive: counts >= 0,
        }"
      >
        {{ counts }}
      </span>
    </div>
  </div>
</template>

<script>
import Contacts from "@/components/SvgIcons/menu/Contacts.vue";
import Courses from "@/components/SvgIcons/menu/Courses.vue";
import Dashboard from "@/components/SvgIcons/menu/Dashboard.vue";
import FunnelConstructor from "@/components/SvgIcons/menu/FunnelConstructor.vue";
import Funnel from "@/components/SvgIcons/menu/Funnel.vue";
import Leadership from "@/components/SvgIcons/menu/Leadership.vue";
import Leads from "@/components/SvgIcons/menu/Leads.vue";
import Mentoring from "@/components/SvgIcons/menu/Mentoring.vue";
import Messages from "@/components/SvgIcons/menu/Messages.vue";
import Tasks from "@/components/SvgIcons/menu/Tasks.vue";
import Team from "@/components/SvgIcons/menu/Team.vue";
import Contest from "@/components/SvgIcons/menu/Contest.vue";
import Assignment from "@/components/SvgIcons/menu/Assignment.vue";

export default {
  name: "MenuLink",
  props: {
    link: {
      type: Object,
      required: true,
    },
    counts: [Number, String],
  },
  components: {
    Contacts,
    Courses,
    Dashboard,
    FunnelConstructor,
    Funnel,
    Leadership,
    Leads,
    Mentoring,
    Messages,
    Tasks,
    Team,
    Contest,
    Assignment,
  },
};
</script>

<style lang="stylus" scoped>
.icon {
  color: #0f1642;
}

.menu__link {
  position: relative;
  display: flex;
  // padding: 5px 0px 4px 4px;
  // border-top-left-radius: 2px;
  // border-bottom-left-radius: 2px;
  color: #0f1642;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  padding: 4px 8px 4px 7px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &.router-link-active {
    background-color: #0f1642;
  }

  &_active {
    background-color: #0f1642;
  }

  &Inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.menu__link.router-link-active .menu__txt span {
  color: #fff;
}

.menu__link_active .menu__count {
  color: #0f1642;
}

.menu__link.router-link-active .icon {
  color: #fff;
}

.menu__link_active .icon {
  color: #fff;
}

.menu__link:hover {
  background-color: rgba(15, 22, 66, 0.3);
}

.menu__link_active:hover {
  background-color: rgba(15, 22, 66, 0.8);
}

.menu__txt {
  display: flex;
  align-items: center;
}

.menu__link_active {
  background-color: #0f1642;
  color: #fff;
}

.menu__link_active svg {
  fill: #fff;
}

.menu__txt span {
  font-size: 14px;
  line-height: 22px;
  margin-left: 8px;
}

.menu__count {
  font-size: 12px;
  line-height: 22px;
  border-radius: 2px;
}

.menu__count_active {
  padding: 0 3px;
}

.menu__count_positive {
  background-color: #e6e7eb;
}

.menu__count_negative {
  background-color: #ff9f43;
  font-weight: 700;
}
</style>
