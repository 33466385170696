import { value } from "numeral";
import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    tags: [],
    errors: {},
    pending: false,
    createdTag: {
      name: "",
      color: "",
      text_color: "",
    },
    tagsColorObjects: [
      {
        color: "e6e6e6",
        text_color: "000",
      },
      {
        color: "e6f6cf",
        text_color: "000",
      },
      {
        color: "d8f7f3",
        text_color: "000",
      },
      {
        color: "e0f1fb",
        text_color: "000",
      },
      {
        color: "fce5f1",
        text_color: "000",
      },
      {
        color: "ffee9c",
        text_color: "000",
      },
      {
        color: "f7e9c1",
        text_color: "000",
      },
      {
        color: "bababa",
        text_color: "000",
      },
      {
        color: "b7e281",
        text_color: "000",
      },
      {
        color: "92e1d5",
        text_color: "000",
      },
      {
        color: "a6e0fc",
        text_color: "000",
      },
      {
        color: "ffc8ea",
        text_color: "000",
      },
      {
        color: "fed74a",
        text_color: "000",
      },
      {
        color: "e0c378",
        text_color: "000",
      },
      {
        color: "878787",
        text_color: "fff",
      },
      {
        color: "7dbd36",
        text_color: "fff",
      },
      {
        color: "25beb2",
        text_color: "fff",
      },
      {
        color: "42a3df",
        text_color: "fff",
      },
      {
        color: "ff7bc3",
        text_color: "fff",
      },
      {
        color: "ff7123",
        text_color: "fff",
      },
      {
        color: "ce6700",
        text_color: "fff",
      },
      {
        color: "4d4d4d",
        text_color: "fff",
      },
      {
        color: "409600",
        text_color: "fff",
      },
      {
        color: "2e9890",
        text_color: "fff",
      },
      {
        color: "0070e4",
        text_color: "fff",
      },
      {
        color: "dc0083",
        text_color: "fff",
      },
      {
        color: "e30000",
        text_color: "fff",
      },
      {
        color: "8d5100",
        text_color: "fff",
      },
      {
        color: "1a1a1a",
        text_color: "fff",
      },
      {
        color: "256512",
        text_color: "fff",
      },
      {
        color: "00665e",
        text_color: "fff",
      },
      {
        color: "0050a1",
        text_color: "fff",
      },
      {
        color: "900052",
        text_color: "fff",
      },
      {
        color: "8e1600",
        text_color: "fff",
      },
      {
        color: "553000",
        text_color: "fff",
      },
    ],
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    tags(state) {
      return pathOr([], ["tags"], state);
    },

    createdTag(state) {
      return state.createdTag;
    },

    errors(state) {
      return state.errors;
    },

    hasNextPage(state) {
      return (
        pathOr(0, ["data", "current_page_number"], state) <
        pathOr(0, ["data", "num_pages"], state)
      );
    },

    currentPage(state) {
      return pathOr(0, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
      state.tags = [...data.results];
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, data) {
      state.errors = data;
    },

    SET_TAG_NAME(state, value) {
      state.createdTag.name = value;
    },

    SET_TAG_COLOR(state, value) {
      const tagColorObj = state.tagsColorObjects.find(el => el.color === value);
      if (tagColorObj) {
        state.createdTag.color = tagColorObj.color;
        state.createdTag.text_color = tagColorObj.text_color;
      }
    },

    SET_DELETED(state, value) {
      const findedTag = state.tags.find(el => el.id === value.id);
      if (findedTag) {
        findedTag.deleted = true;
      }
    },

    SET_RESTORED(state, value) {
      const findedTag = state.tags.find(el => el.id === value.id);
      if (findedTag) {
        findedTag.deleted = false;
      }
    },

    CLEAR_TAG(state) {
      state.createdTag = {
        name: "",
        color: "",
        text_color: "",
      };
    },
  },

  actions: {
    async fetch({ commit }) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(`/tags/`, {
          params: { page_size: 100 },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async createTag({ state, commit }) {
      try {
        commit("SET_PENDING", true);

        const data = { ...state.createdTag };

        const response = await Vue.axios.post(`/tags/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async editTag({ state, commit }, tag) {
      try {
        commit("SET_PENDING", true);

        const data = { ...state.createdTag };

        const response = await Vue.axios.patch(`/tags/${tag.id}/`, data);

        if (response.status === 200) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async delTagFromTags({ commit }, tag) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(`/tags/${tag.id}/`);

        if (response.status === 204) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },

    async addTag({ commit }, { tagId, contactId }) {
      try {
        commit("SET_PENDING", true);

        const data = {
          tag: tagId,
          contact: contactId,
        };

        const response = await Vue.axios.post(`/contact_tags/`, data);

        if (String(response.status).startsWith("20")) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },

    async fetchIdTag({ commit }, { tagId, contactId }) {
      try {
        commit("SET_PENDING", true);

        const data = {
          tag: tagId,
          contact: contactId,
        };

        const response = await Vue.axios.get(`/contact_tags/`, {
          params: { ...data },
        });

        if (String(response.status).startsWith("20")) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },

    async delTagFromContact({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(`/contact_tags/${id}/`);

        if (String(response.status).startsWith("20")) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
