<template>
  <div>
    <span class="tableItem__time">
      {{ dateFormatted(trigger.created_at) }}
    </span>
    <div class="tableItem__box">
      <div class="tableItem__title">
        <base-icon-svg
          width="20"
          height="24"
          viewbox="0 0 20 24"
          class="tableItem__icon"
        >
          <funnel-landing-modal v-if="trigger.source === 'form'" />
          <funnel-vk-modal v-if="trigger.source === 'vk_form'" />
          <funnel-telegram-modal v-if="trigger.source === 'chatbot'" />
          <funnel-fb-modal v-if="false" />
        </base-icon-svg>

        <template v-if="trigger.source === 'form'">
          <Tr>Заявка с формы лендинга</Tr><span>{{ landingUrl }}</span>
          <!-- <Tr>Заявка с формы лендинга</Tr><span>{{ trigger.page }}</span> -->
        </template>
        <Tr v-if="trigger.source === 'vk_form'">Заявка с формы ВК</Tr>
        <Tr v-if="trigger.source === 'chatbot'">Заявка из чатбота</Tr>
        <Tr v-if="false">Заявка с формы Facebook</Tr>
      </div>

      <div class="tableItem__text">
        <template v-if="trigger.phone">
          <Tr class="tableItem__textGridTitle">Телефон:</Tr>
          <span>{{ trigger.phone }}</span>
        </template>
        <template v-if="trigger.email">
          <Tr class="tableItem__textGridTitle">Email:</Tr>
          <span>{{ trigger.email }}</span>
        </template>
        <template v-if="trigger.name">
          <Tr class="tableItem__textGridTitle">Имя:</Tr>
          <span>{{ trigger.name }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import FunnelLandingModal from '@/components/SvgIcons/funnel/modal/FunnelLandingModal.vue';
import FunnelVkModal from '@/components/SvgIcons/funnel/modal/FunnelVkModal.vue';
import FunnelFbModal from '@/components/SvgIcons/funnel/modal/FunnelFbModal.vue';
import FunnelTelegramModal from '@/components/SvgIcons/funnel/modal/FunnelTelegramModal.vue';

export default {
  name: "UpdatedFunnelModalLeadTrigger",
  components: {
    FunnelLandingModal,
    FunnelVkModal,
    FunnelFbModal,
    FunnelTelegramModal,
  },
  props: {
    trigger: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      messageTextIsFull: false,
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
    }),
    landingUrl() {
      if (this.trigger.page) {
        let arr = this.trigger.page.split("?");
        arr = arr.slice(0, 1);
        return arr.join("");
      }
      return "";
    },
  },
  methods: {
    dateFormatted(date) {
      if (date) {
        return moment(date)
          .utcOffset(6)
          .locale(this.currentLang)
          .format("D MMM, HH:mm");
      }
    },
    showMore() {
      this.messageTextIsFull = !this.messageTextIsFull;
    },
  },
};
</script>

<style lang="stylus" scoped>
.tableItem {
  font_regular();

  &:not(:last-of-type) {
    margin-bottom: 13px;
  }

  &__time {
    display: block;
    font-weight: 600;
    margin-bottom: 5px;

    @media (min-width: tablet) {
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    top: -2px;
    left: -25px;
    margin-right: 1px;
  }

  &__title {
    position: relative;
    top: -4px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 23px;

    @media (min-width: tablet) {
      align-items: center;
    }

    &>span {
      margin-right: 5px;
      opacity: 0.6;
    }
  }

  &__text {
    margin-bottom: 10px;
    margin-left: 23px;

    @media (min-width: tablet_xs) {
      display: grid;
      grid-template-columns: minmax(60px, max-content) auto;
      grid-gap: 2px 5px;
    }
  }

  &__textGridTitle {
    display: block;
    opacity: 0.6;

    &:not(:first-of-type) {
      margin-top: 5px;
    }

    @media (min-width: tablet_xs) {
      display: inline-block;
      opacity: 1;

      &:not(:first-of-type) {
        margin-top: 0;
      }
    }
  }

  &__chatbot {
    display: flex;
    flex-direction: column;
    margin-left: 23px;
  }

  &__chatbotInner {
    max-height: 18px;
    overflow: hidden;
    transition: 0.4s;

    &_full {
      max-height: unset;

      p {
        margin-bottom: 7px;
      }
    }
  }
}

.updatedFunnelBodyItem__notificationIcon {
  color: #3489f8;

  &_contact {
    color: main_text_color;
  }
}

.tooltip-progress {
  font-weight: 600;
}

.tooltip-time {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 7px;
}

.webinarKeypoints {
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }

  &__icon {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: grey_text_color;

    &_visited {
      background-color: green_text_color;
    }
  }

  &__text {
    font_small();
    opacity: 0.6;

    &_visited {
      opacity: 1;
    }
  }
}

.showMoreBtn {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  width: 24px;
  height: 12px;
  background-color: grey_text_color;
  border: 1px solid #bfb9b9;
  border-radius: 5px;
  cursor: pointer;

  span {
    position: relative;
    top: -1px;
    display: block;
    width: 4px;
    height: 4px;
    border-right: 1px solid #4c4b4b;
    border-bottom: 1px solid #4c4b4b;
    transform: rotate(45deg);
    // transition: 0.4s;
  }

  &_opened {
    span {
      top: 1px;
      transform: rotate(225deg);
    }
  }

  &:hover {
    opacity: 0.6;
  }
}
</style>
