const getInitials = (name, amount = 2) => {
  if (name) {
    return name
      .split(" ")
      .map(item => item.substring(0, 1))
      .slice(0, amount)
      .join("")
      .toUpperCase();
  }
  return "";
};

export default getInitials;
