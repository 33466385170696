import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { mapObjIndexed } from "ramda";

const isValid = videos => {
  return !!videos.red && !!videos.blue && !!videos.yellow && !!videos.green;
};
export default {
  namespaced: true,

  state: () => ({
    data: [],
    webinars: [],
    questions: [],
    videos: {
      red: null,
      blue: null,
      yellow: null,
      green: null,
    },
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    getPsychotype: state => color => {
      if (state && state.webinars) {
        return state.webinars.filter(el => el.psychotype === color);
      }
    },
    linkedVideoByColor: state => color => {
      return state.videos[color];
    },
    linkedVideos(state) {
      return state.videos;
    },
    checkValidity(state) {
      if (
        state.videos &&
        state.videos.red &&
        state.videos.blue &&
        state.videos.yellow &&
        state.videos.green
      ) {
        return true;
      }
      return false;
    },
    name(state) {
      return state.data.name;
    },
  },

  mutations: {
    SET_WEBINARS_LIST(state, data) {
      state.webinars = data;
    },
    SET_DATA(state, data) {
      state.data = data;
      state.videos = {
        ...state.videos,
        ...mapObjIndexed((item: any) => item.id, data.videos),
      };
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_VIDEO_ID(state, { type, id }) {
      state.videos[type] = id;
    },
  },

  actions: {
    async fetch({ commit, rootGetters }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_DATA", {});

        const response = await axios.get(
          "/videos",
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_WEBINARS_LIST", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchFunnel({ commit, rootGetters }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await axios.get(
          `/funnels/${id}`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async linkVideos({ commit, rootGetters, state }, { funnelId, id, type }) {
      try {
        // commit("SET_PENDING", true);
        commit("SET_VIDEO_ID", { id, type });

        const data = { ...state.videos, [type]: id };
        // if (!isValid(data)) {
        //   return;
        // }

        const response = await axios.put(
          `/funnels/${funnelId}/videos`,
          data,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          // commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
