<template>
  <div class="contactsBodyItemTooltipBox">
    <slot />
    <div
      class="contactsBodyItemTooltip contactsBodyItemTooltip_mobile"
      v-tooltip="{
        trigger: 'click',
        content: tooltipContent,
      }"
    />
    <div
      class="contactsBodyItemTooltip contactsBodyItemTooltip_desktop"
      v-tooltip="{
        trigger: 'hover',
        content: tooltipContent,
      }"
    />
  </div>
</template>

<script>
export default {
  name: "BaseTooltip",
  props: {
    tooltipContent: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsBodyItemTooltipBox {
  position: relative;
  overflow: hidden;
}

.contactsBodyItemTooltip {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &_mobile {
    @media (min-width: desktop) {
      display: none;
    }
  }

  &_desktop {
    display: none;

    @media (min-width: desktop) {
      display: block;
    }
  }
}
</style>
