import Lesson, {
  transformDocFiles,
  transformLessonDelay,
} from "@/models/lesson";
import supportedLangs from "@/utils/supportedLangs";
import { path, pathOr } from "ramda";
import Vue from "vue";
import i18n from "@/plugins/i18n";
import router from "@/router";
import { getLang } from "@/utils/cookies/getLang";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
    activeLang: getLang(),
    filesQueue: [],
  }),
  getters: {
    title(state, getters, rootState) {
      return pathOr("", ["data", "locales", rootState.siteLang, "name"], state);
    },
    errors(state) {
      return state.errors;
    },
    videoErrors(state) {
      if (
        state.errors &&
        state.errors.quest_task_videos_objects &&
        state.errors.quest_task_videos_objects[0]
      ) {
        return state.errors.quest_task_videos_objects[0];
      }
      return {};
    },
    pending(state) {
      return state.pending;
    },
    courseTitle(state, getters, rootState) {
      return pathOr(
        "",
        ["data", "quest_object", "locales", rootState.siteLang, "name"],
        state
      );
    },
    isVideoValid: state => lang => {
      if (state.data.video && state.data.video.enabled) {
        return (
          path(["data", "video", "locales", lang, "url"], state) &&
          path(["data", "video", "reward_gold"], state) &&
          path(["data", "video", "reward_expire_gold"], state)
        );
      } else {
        return true;
      }
    },
    isLangValid: (state, getters) => lang => {
      if (!state.data.locales && !lang) {
        return false;
      }
      return (
        // path(["data", "locales", lang, "name"], state) &&
        // path(["data", "locales", lang, "content"], state) &&
        // getters.isVideoValid(lang)
        path(["data", "locales", lang, "name"], state) &&
        path(["data", "locales", lang, "content"], state)
      );
    },
    langTabs(state, getters) {
      return supportedLangs().map(lang => ({
        title: lang,
        isValid: getters.isLangValid(lang),
      }));
    },
    name(state) {
      return pathOr("", ["data", "locales", state.activeLang, "name"], state);
    },
    content(state) {
      return pathOr(
        "",
        ["data", "locales", state.activeLang, "content"],
        state
      );
    },
    activeLang(state) {
      return state.activeLang;
    },
    videoEnabled(state) {
      return pathOr(false, ["data", "video", "enabled"], state);
    },
    videoUrls(state) {
      const videoList: any = pathOr(
        [null, null],
        ["data", "video", "items"],
        state
      );
      return videoList.map(item =>
        pathOr("", ["locales", state.activeLang, "url"], item)
      );
    },
    reward(state) {
      return pathOr("", ["data", "video", "items", "0", "reward_gold"], state);
    },
    rewardExpire(state) {
      return pathOr(
        "",
        ["data", "video", "items", "0", "reward_expire_gold"],
        state
      );
    },
    canSkip(state) {
      return pathOr("", ["data", "can_skip"], state);
    },
    linkedFiles(state) {
      const files: any = pathOr([], ["data", "linkedFiles"], state);
      return files.filter(file => file.language === state.activeLang);
    },
    isPublished(state) {
      if (
        state.data &&
        state.data.quest_object &&
        state.data.quest_object.published_at
      ) {
        return true;
      }
      return false;
    },
    lessonDelay(state) {
      return pathOr(transformLessonDelay(), ["data", "lessonDelay"], state);
    },
    deadline(state) {
      return state.data.deadline;
    },
    tasksList(state) {
      return pathOr([], ["data", "sorted_quest_task_targets"], state);
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
    CLEAR_ERRORS(state) {
      state.errors = {};
    },
    CHANGE_NAME(state, value) {
      state.data.locales[state.activeLang].name = value;
    },
    CHANGE_CONTENT(state, value) {
      state.data.locales[state.activeLang].content = value;
    },
    TOGGLE_VIDEO(state) {
      state.data.video.enabled = !state.data.video.enabled;
    },
    CHANGE_VIDEO_URL(state, { value, index }) {
      state.data.video.items[index].locales[state.activeLang].url = value;
    },
    CHANGE_REWARD(state, { type, value }) {
      state.data.video.items = state.data.video.items.map(item => ({
        ...item,
        [type]: value,
      }));
    },
    ADD_FILE_TO_EXISTS(state, file) {
      if (!state.data.linkedFiles) {
        state.data.linkedFiles = [];
      }
      state.data.linkedFiles = state.data.linkedFiles.concat([file]);
    },
    DELETE_FILE(state, id) {
      state.data.linkedFiles = state.data.linkedFiles.filter(
        file => file.id !== id
      );
      state.filesQueue = state.filesQueue.filter(file => file.id !== id);
    },
    ADD_FILE_TO_QUEUE(state, data) {
      state.filesQueue = state.filesQueue.concat([data]);
    },
    CLEAR_FILE_QUEUE(state) {
      state.filesQueue = [];
    },
    CHANGE_CAN_SKIP(state) {
      state.data.can_skip = !state.data.can_skip;
    },
    CHANGE_DELAY_TYPE(state, value) {
      state.data.lessonDelay.delayType = value;
    },
    CHANGE_DELAY_DAY(state, value) {
      state.data.lessonDelay.delay_days = value;
    },
    CHANGE_DELAY_TIME(state, value) {
      state.data.lessonDelay.delay_time = value;
    },
    CHANGE_DELAY_DATE(state, value) {
      state.data.lessonDelay.will_be_available_at = value;
    },
    CHANGE_DEADLINE(state, value) {
      state.data.deadline.value = value;
    },
    CHANGE_DEADLINE_CHECKED(state) {
      state.data.deadline.checked = !state.data.deadline.checked;
      if (state.data.deadline.checked) {
        state.data.deadline.value = "";
      }
    },
  },
  actions: {
    init({ commit }) {
      commit("SET_DATA", new Lesson());
    },

    async fetch({ commit, state, dispatch }, { lessonId }) {
      // if (state.data.id && state.data.id == lessonId) {
      //   return;
      // }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get(
          `/leadership_quest_tasks/${lessonId}/`
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("SET_DATA", new Lesson(response.data));
          dispatch(
            "leadershipTasks/setData",
            response.data.sorted_quest_task_targets,
            { root: true }
          );
        }
      } catch (e) {
        commit("SET_PENDING", false);
        console.error(e);
      }
    },

    async silentFetch({ commit, state }, { lessonId }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get(
          `/leadership_quest_tasks/${lessonId}/`
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          commit("SET_DATA", new Lesson(response.data));
        }
      } catch (e) {
        commit("SET_PENDING", false);
        console.error(e);
      }
    },

    async refresh({ dispatch, state }) {
      Promise.all([
        dispatch("silentFetch", { lessonId: state.data.id }),
      ]).then(() =>
        dispatch("leadershipTask/initTask", undefined, { root: true })
      );
    },

    async createLesson({ commit, state, dispatch }, { programId }) {
      try {
        commit("SET_PENDING", true);
        commit("SET_ERRORS", {});
        const lang = i18n.locale;
        const { courseId } = router.currentRoute.params;

        const data = {
          ...state.data.getCreateModel(),
          quest: courseId,
        };

        const response = await Vue.axios.post(`/leadership_quest_tasks/`, data);
        if (response.status === 201) {
          commit("SET_PENDING", false);
          const id = response.data.id;

          // dispatch("linkFileAfterLessonUpdate", id);
          Promise.all(
            state.filesQueue.map(file =>
              dispatch("linkFileToLesson", { file, id })
            )
          ).then(() => {
            commit("CLEAR_FILE_QUEUE");

            if (programId) {
              router.push(
                `/${lang}/leadership/courses/${courseId}/lessons/${id}/description?programId=${programId}`
              );
            } else {
              router.push(
                `/${lang}/leadership/courses/${courseId}/lessons/${id}/description`
              );
            }
          });
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response.data);
        console.error(e);
      }
    },

    async updateLesson({ commit, state, dispatch }) {
      // dispatch("linkFileToLesson");
      try {
        commit("SET_PENDING", true);
        commit("SET_ERRORS", {});
        const { courseId } = router.currentRoute.params;

        const data = {
          ...state.data.getCreateModel(),
          course: courseId,
        };

        const response = await Vue.axios.patch(
          `/leadership_quest_tasks/${state.data.id}/`,
          data
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          Promise.all(
            state.filesQueue.map(file =>
              dispatch("linkFileToLesson", { file, id: state.data.id })
            )
          ).then(() => commit("CLEAR_FILE_QUEUE"));
          // commit("SET_STATE", FULFILLED);
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response.data);
        console.error(e);
      }
    },

    async addFileToQueue({ state, commit }, file) {
      const transformed = {
        id: file.id,
        url: file.file,
        name: file.original_filename,
        language: state.activeLang,
      };
      commit("ADD_FILE_TO_EXISTS", transformed);
      commit("ADD_FILE_TO_QUEUE", transformed);
    },

    async linkFileToLesson({ state, commit }, payload) {
      const { file, id } = payload;
      try {
        commit("SET_PENDING", true);
        const { lessonId } = router.currentRoute.params;
        const data = {
          file: file.id,
          quest_task: id ? id : lessonId,
          language: file.language,
        };
        const response = await Vue.axios.post(
          "/leadership_quest_task_doc_files/",
          data
        );

        if (response.status === 201) {
          // commit("ADD_FILE_TO_EXISTS", response.data);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        console.info(e);
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response.data);
      }
    },

    async deleteFile({ state, commit }, id) {
      commit("DELETE_FILE", id);
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(
          `/leadership_quest_task_doc_files/${id}/`
        );

        if (response.status === 204) {
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response.data);
        console.info(e);
      }
    },
  },
};
