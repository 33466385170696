<template>
  <button
    @click="$emit(`click`)"
    :class="['form__button', { form__button_orange: orange }]"
    :disabled="disabled"
    type="button"
  >
    <slot />
    <Tr>{{ text }}</Tr>
  </button>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "FormSubmit",
  props: {
    text: {
      type: String,
      default: "Сохранить",
    },
    disabled: {
      type: Boolean,
    },
    orange: Boolean,
  },
});
</script>

<style lang="stylus" scoped>
.form__button {
  margin-top: 18px;
  padding: 11px 12px;
  background-color: #e6e7eb;
  border: none;
  border-radius: 4px;
  font_regular();

  &_orange {
    background-image: linear-gradient(180deg, light_orange 0%, orange 100%);
    background-color: unset;
    color: #fff;
  }

  &:disabled {
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.6;
  }
}
</style>
