<template>
  <div class="container">
    <the-header />
    <the-sidebar :asideOpen="asideOpen" />
    <div class="content">
      <telephony-minimize-container />
      <router-view />
    </div>
    <the-footer />
    <leads-modal-create-task />
    <leads-modal-product />
    <funnel-modal-keypoints />
    <telephony-modal />
    <leads-modal-calls-history />
    <updated-leads-call-to-lead-modal />
    <leads-modal-status-check />
    <!-- <leads-modal-create-link /> -->
    <leads-modal-create-link-without-chatbot />
    <assignment-modal-assign-manager />
    <updated-assignment-modal-assign-manager />
    <updated-assignment-modal-remove-manager />
    <updated-funnel-modal-lead />
    <tasks-item-modal />
    <contacts-profile-modal-create-reminder />
    <contacts-profile-modal-create-meeting />
    <contacts-profile-modal-create-tag />
    <contacts-modal-create-contact />
    <contacts-modal-tags />
    <div class="modals">
      <v-dialog :styles="styles" />
    </div>
  </div>
</template>

<script>
import { sidebarHandler } from "@/components/common/SidebarHandler.js";
import { mapGetters } from "vuex";

import TheHeader from "@/components/TheHeader.vue";
import TheSidebar from "@/components/TheSidebar.vue";
import TheFooter from "@/components/TheFooter.vue";
import LeadsModalCreateTask from "@/components/Leads/LeadsModalCreateTask.vue";
import LeadsModalStatusCheck from "@/components/Leads/LeadsModalStatusCheck.vue";
import LeadsModalCallsHistory from "@/components/Leads/LeadsModalCallsHistory.vue";
import LeadsModalProduct from "@/components/Leads/LeadsModalProduct.vue";
import FunnelModalKeypoints from "@/components/Funnel/FunnelModalKeypoints.vue";
import TelephonyModal from "@/components/Telephony/TelephonyModal.vue";
import TelephonyMinimizeContainer from "@/containers/Telephony/TelephonyMinimizeContainer.vue";
// import LeadsModalCreateLink from "@/components/Leads/LeadsModalCreateLink.vue";
import LeadsModalCreateLinkWithoutChatbot from "@/components/Leads/LeadsModalCreateLinkWithoutChatbot.vue";
import AssignmentModalAssignManager from "@/components/Assignment/AssignmentModalAssignManager.vue";
import UpdatedAssignmentModalAssignManager from "@/components/Assignment/UpdatedAssignmentModalAssignManager.vue";
import UpdatedAssignmentModalRemoveManager from "@/components/Assignment/UpdatedAssignmentModalRemoveManager.vue";
import UpdatedFunnelModalLead from "@/components/Funnel/UpdatedFunnelModalLead.vue";
import TasksItemModal from "@/components/Tasks/TasksItemModal.vue";
import ContactsProfileModalCreateReminder from "@/components/Contacts/ContactsProfileModalCreateReminder.vue";
import ContactsProfileModalCreateMeeting from "@/components/Contacts/ContactsProfileModalCreateMeeting.vue";
import ContactsProfileModalCreateTag from "@/components/Contacts/ContactsProfileModalCreateTag.vue";
import ContactsModalCreateContact from "@/components/Contacts/ContactsModalCreateContact.vue";
import ContactsModalTags from "@/components/Contacts/ContactsModalTags.vue";
import UpdatedLeadsCallToLeadModal from "@/components/Leads/UpdatedLeadsCallToLeadModal.vue";

export default {
  name: "DefaultLayout",
  components: {
    TheHeader,
    TheSidebar,
    TheFooter,
    LeadsModalCreateTask,
    LeadsModalCallsHistory,
    LeadsModalProduct,
    FunnelModalKeypoints,
    TelephonyModal,
    TelephonyMinimizeContainer,
    LeadsModalStatusCheck,
    // LeadsModalCreateLink,
    LeadsModalCreateLinkWithoutChatbot,
    AssignmentModalAssignManager,
    UpdatedAssignmentModalAssignManager,
    UpdatedAssignmentModalRemoveManager,
    UpdatedFunnelModalLead,
    TasksItemModal,
    ContactsProfileModalCreateReminder,
    ContactsProfileModalCreateMeeting,
    ContactsProfileModalCreateTag,
    ContactsModalCreateContact,
    ContactsModalTags,
    UpdatedLeadsCallToLeadModal,
  },
  data() {
    sidebarHandler.$on("aside-menu-handler", this.menuOpen);
    return {
      styles: {
        "vue-dialog-button": {
          background: "red",
        },
      },
      asideOpen: false,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    menuOpen() {
      this.asideOpen = !this.asideOpen;
    },
  },
};
</script>

<style lang="stylus"></style>
