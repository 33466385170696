var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellSource",on:{"click":function($event){$event.preventDefault();return _vm.$emit('show-source')}}},[_c('span',{class:[
      'updatedFunnelBodyItem__sourceDate',
      { hide: _vm.sourceHide },
      { show: !_vm.sourceHide } ],on:{"click":function($event){return _vm.$emit('show-lead')}}},[_vm._v(" "+_vm._s(_vm.dateFormatted(_vm.lead.trigger.created_at))+" ")]),_c('div',{staticClass:"updatedFunnelBodyItem__sourceBox"},[(_vm.lead.funnel)?[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.sourceLinkTooltipMobile),expression:"sourceLinkTooltipMobile"}],class:[
          'updatedFunnelBodyItem__sourceItem',
          'updatedFunnelBodyItem__sourceItem_mobile',
          { updatedFunnelBodyItem__sourceItem_hide: _vm.sourceHide } ]},[_vm._v(" "+_vm._s(_vm.lead.funnel.name)+" ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.sourceLinkTooltipDesktop),expression:"sourceLinkTooltipDesktop"}],class:[
          'updatedFunnelBodyItem__sourceItem',
          'updatedFunnelBodyItem__sourceItem_desktop',
          { updatedFunnelBodyItem__sourceItem_hide: _vm.sourceHide } ]},[_vm._v(" "+_vm._s(_vm.lead.funnel.name)+" ")])]:_c('span',{class:[
        'updatedFunnelBodyItem__sourceItem',
        { updatedFunnelBodyItem__sourceItem_hide: _vm.sourceHide } ]},[_vm._v(" - ")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }