<script>
export default {
  name: "TabListRouter",
  data() {
    return {};
  },
  props: {
    tabs: Array,
  },
};
</script>

<template>
  <ul class="tabList">
    <li :key="tab.title + index" class="tabItem" v-for="(tab, index) in tabs">
      <base-link :to="tab.link" :exact="tab.exact" class="tabButton activeItem">
        <Tr>{{ tab.title }}</Tr>
      </base-link>
    </li>
  </ul>
</template>

<style lang="stylus" scoped>
.tabList {
  // margin-right: -16px;
  // margin-left: -16px;
  border-bottom: 4px solid #e6e7eb;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  position: relative;
  padding: 12px 16px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);
  display: inline-block;

  &.router-link-active {
    &::after {
      position: absolute;
      content: '';
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #ff9f43;
    }
  }
}
</style>
