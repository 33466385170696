<template>
  <div class="contactsfilter">
    <div class="contactsfilter__box">
      <contacts-filter-body-progress
        :isFilterChecked="isFilterChecked"
        @change="changeFilter"
      />
    </div>

    <contacts-filter-body-warm-tops
      :isFilterChecked="isFilterChecked"
      @change="changeFilter"
      class="contactsfilter__box"
    />

    <div class="conatctFiltersGeoWrap">
      <updated-funnel-filter-dropdowns-group
        :dropdownsGroup="tagsCheckboxGroup"
        :isFilterChecked="isFilterChecked"
        :pickedAll="pickedAllTags"
        :filterValue="tagsFilterValue"
        :filterCounter="tagsFilterCounter"
        :contactTags="true"
        @change="changeFilter"
        @changeAll="pickAllTags"
        @change-filter-value="changeTagsFilterValue"
        @change-filter-counter="changeTagsFilterCounter"
      />

      <contacts-profile-info-input
        class="conatctFiltersGeoItem"
        title="Страна"
        labelId="country"
        :value="filters.country"
        :inputWidth="240"
        :labelBold="true"
        @handle-input="changeFilterGeo"
      />

      <contacts-profile-info-input
        class="conatctFiltersGeoItem"
        title="Город"
        labelId="city"
        :value="filters.city"
        :inputWidth="240"
        :labelBold="true"
        @handle-input="changeFilterGeo"
      />
    </div>

    <clear-filters class="clearFilters" @click="clearFilters" />
  </div>
</template>

<script>
import ContactsFilterBodyProgress from "@/components/Contacts/ContactsFilterBodyProgress";
import ContactsFilterBodyWarmTops from "@/components/Contacts/ContactsFilterBodyWarmTops";
import UpdatedFunnelFilterDropdownsGroup from "@/components/Funnel/UpdatedFunnelFilterDropdownsGroup";
import ContactsProfileInfoInput from "@/components/Contacts/ContactsProfileInfoInput.vue";
import ClearFilters from "@/components/ClearFilters.vue";

export default {
  name: "ContactsFilterBody",
  components: {
    ContactsFilterBodyProgress,
    ContactsFilterBodyWarmTops,
    UpdatedFunnelFilterDropdownsGroup,
    ContactsProfileInfoInput,
    ClearFilters,
  },
  props: {
    isFilterChecked: {
      type: Function,
    },

    tags: {
      type: Array,
      required: true,
    },

    filters: {
      type: Object,
    },

    tagsFilterValue: {
      type: [String, Number],
    },

    tagsFilterCounter: {
      type: [String, Number],
    },

    pickedAllTags: {
      type: Boolean,
    },
  },

  computed: {
    tagsCheckboxGroup() {
      return {
        title: "Теги",
        name: "tags",
        array: this.tags,
      };
    },
  },

  methods: {
    changeFilter(payload) {
      this.$emit('changeFilter', payload);
    },

    changeFilterGeo(payload) {
      this.$emit("changeFilter", {
        name: payload.param,
        value: payload.value,
      });
    },

    clearFilters() {
      this.$emit('clearFilters');
    },

    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },

    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },

    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.contactsfilter {
  position: relative;
  display: grid;
  grid-row-gap: 26px;
  margin-top: 7px;
  padding: 10px;
  width: 100%;
  max-width: 420px;
  border: 1px solid #e6e7eb;
  border-radius: 4px;
}

@media (min-width: 420px) {
  .contactsfilter {
    padding: 20px;
    max-width: 440px;
  }
}

@media (min-width: tablet_large) {
  .contactsfilter {
    max-width: 500px;
  }
}

@media (min-width: desktop) {
  .contactsfilter {
    grid-auto-flow: column;
    grid-column-gap: 32px;
    grid-row-gap: unset;
    padding: 15px 68px 20px 20px;
    width: fit-content;
    max-width: unset;
  }
}

.clearFilters {
  margin-bottom: 15px;

  @media (min-width: desktop) {
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin-bottom: 0;
  }
}

.conatctFiltersGeoWrap {
  @media (min-width: desktop) {
    display: grid;
    grid-row-gap: 20px;
    height: fit-content;
    margin-bottom: 40px;
  }
}

.conatctFiltersGeoItem {
  margin-bottom: 20px;

  @media (min-width: desktop) {
    margin-bottom: 0;
  }
}
</style>
