<template>
  <div class="contactsProgress">
    <span
      class="contactsProgressItem contactsProgressItem_active"
      v-for="(n, index) in num"
      :key="`${n}_${index}`"
    />
    <span
      class="contactsProgressItem"
      v-for="(n, index) in 10 - num"
      :key="`${n}__${index}`"
    />
  </div>
</template>

<script>
export default {
  name: "ContactsProgress",
  data() {
    return {};
  },
  props: {
    param: {
      default: 0,
      type: Number,
    },
  },
  computed: {
    num() {
      return this.param + 1;
    }
  }
};
</script>

<style lang="stylus" scoped>
.contactsProgress {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90px;
}

.contactsProgressItem {
  display: block;
  width: 8px;
  height: 14px;
  background-color: grey_text_color;

  &:not(:last-child) {
    margin-right: 1px;
  }

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &_active {
    &:nth-child(1) {
      background-color: #E91927;
    }

    &:nth-child(2) {
      background-color: #EE4A2E;
    }

    &:nth-child(3) {
      background-color: #F37934;
    }

    &:nth-child(4) {
      background-color: #F8A33A;
    }

    &:nth-child(5) {
      background-color: #FFDB42;
    }

    &:nth-child(6) {
      background-color: #DADC48;
    }

    &:nth-child(7) {
      background-color: #A9D650;
    }

    &:nth-child(8) {
      background-color: #83D255;
    }

    &:nth-child(9) {
      background-color: #4FCB5C;
    }

    &:nth-child(10) {
      background-color: #1BC563;
    }
  }
}
</style>
