<template>
  <TasksLayout :tasks="allTasks" />
</template>

<script>
import { mapGetters } from "vuex";

import TasksLayout from "@/components/Tasks/TasksLayout.vue";

export default {
  name: "TasksAll",
  components: {
    TasksLayout,
  },
  computed: {
    ...mapGetters({
      allTasks: "leadTasks/tasks",
    }),
  },
  mounted() {
    this.$store.dispatch("leadTasks/fetchOnlyAllTasks", { page: 1 });
  },
};
</script>
