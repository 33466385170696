<template>
  <div class="delay">
    <h2 class="addonTitle">
      <Tr>Варианты отображения урока</Tr>
    </h2>
    <div class="delay__box">
      <input
        class="delay__radioInput"
        type="radio"
        id="delayTypeNone"
        value="none"
        v-model="delayType"
      />
      <span class="delay__radioInputFake" />
      <label class="delay__label" for="delayTypeNone">
        <Tr>Сразу после предыдущего</Tr>
      </label>
    </div>

    <div class="delay__box">
      <input
        class="delay__radioInput"
        type="radio"
        id="delayTypeDelay"
        value="delay"
        v-model="delayType"
      />
      <span class="delay__radioInputFake" />
      <label class="delay__label" for="delayTypeDelay">
        <Tr>Через</Tr>
      </label>
      <span>
        <input
          class="delay__text"
          v-mask="'###'"
          min="1"
          max=""
          v-model="delayDay"
          :disabled="delayType !== 'delay'"
        />
        <Tr>дн.</Tr>
      </span>
      <Tr>в</Tr>
      <span>
        <input
          class="delay__time"
          type="time"
          min="1"
          max=""
          v-model="delayTime"
          :disabled="delayType !== 'delay'"
        />
        <Tr>чч.мм</Tr>
      </span>
    </div>

    <div class="delay__box">
      <input
        class="delay__radioInput"
        type="radio"
        id="delayTypeByDate"
        value="by_date"
        v-model="delayType"
      />
      <span class="delay__radioInputFake" />
      <label class="delay__label" for="delayTypeByDate">
        <Tr>Точная дата</Tr>
      </label>
      <date-picker
        v-model="delayDate"
        type="datetime"
        valueType="format"
        format="YYYY-MM-DD HH:mm"
        :lang="['tr', 'de', 'es'].includes($i18n.locale) ? 'en' : $i18n.locale"
        :showSecond="false"
        :showMinute="true"
        :disabled="delayType !== 'by_date'"
      />
    </div>
    <div class="inputWrap__error error" v-if="delayErrors">
      <div :key="error" class="error__item" v-for="error in delayErrors">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

export default {
  name: "LessonDelay",
  components: {
    DatePicker,
  },
  props: {
    lessonDelay: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      time: null,
    };
  },
  computed: {
    delayErrors() {
      const type = this.lessonDelay.delayType;

      if (this.errors) {
        if (type === "by_date") {
          return this.errors.will_be_available_at || [];
        } else if (type === "delay") {
          return [
            ...(this.errors.delay_days ? this.errors.delay_days : []),
            ...(this.errors.delay_time ? this.errors.delay_time : []),
          ];
        }
        return [];
      }

      return [];
    },
    delayType: {
      get() {
        return this.lessonDelay.delayType;
      },
      set(value) {
        this.$emit("change-delay-type", value);
      },
    },
    delayDay: {
      get() {
        return this.lessonDelay.delay_days;
      },
      set(value) {
        this.$emit("change-delay-day", value);
      },
    },
    delayTime: {
      get() {
        return this.lessonDelay.delay_time;
      },
      set(value) {
        this.$emit("change-delay-time", value);
      },
    },
    delayDate: {
      get() {
        return this.lessonDelay.will_be_available_at;
      },
      set(value) {
        this.$emit("change-delay-date", value);
      },
    },
  },
  mounted() {
    // // const stamp = 1622343600;
    // const testDate = "2021-05-30 06:00";
    // // const inUtc = moment.unix(stamp).utc();
    // const inUtc = moment(testDate).utc();
    // console.log(inUtc.format());
    // console.log(inUtc.utcOffset(180).format());
    // console.log(
    //   moment("2021-05-30T06:00:00+03:00").format("YYYY-MM-DD HH:mm:ss")
    // );
  },
};
</script>

<style lang="stylus">
.delay {
  margin: 20px 0;

  & .mx-datepicker {
    margin-top: 5px;
    width: 172px;
  }

  &__radioInput {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  &__radioInputFake {
    position: absolute;
    content: '';
    top: 12px;
    left: 0;
    margin: auto;
    width: 16px;
    height: 16px;
    border: 2px solid #e6e7eb;
    border-radius: 50%;

    @media (min-width: tablet_large) {
      top: 10px;
    }
  }

  &__radioInput:checked + &__radioInputFake {
    background-color: #0F1642;
    box-shadow: inset 0px 0px 0px 3px #fff;
  }

  &__label {
    position: relative;
    display: inline-block;
    margin-top: 12px;
    margin-right: 7px;
    padding-left: 25px;
    font_regular();

    @media (min-width: tablet_large) {
      margin-top: 8px;
    }
  }

  &__text {
    max-width: 40px;
    height: 26px;
    border: 1px solid #e6e7eb;
    text-align: center;
    font-size: 14px;
  }

  &__time {
    position: relative;
    top: -5px;
    border: 1px solid #e6e7eb;
    height: 26px;

    @media (min-width: tablet_large) {
      position: static;
    }
  }

  &__box {
    position: relative;
    margin: 4px 0;
    font-size: 14px;

    & input {
      padding: 5px;
    }
  }

  .mx-input {
    border: 1px solid #e6e7eb;
    box-shadow: none;
    border-radius: 0;
    height: 26px;
  }
}

.addonTitle {
  color: #0f1642;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.error {
  &__item {
    color: danger_text_color;
    font-size: 14px;
  }
}
</style>
