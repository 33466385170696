<template>
  <div>
    <lang-tabs
      :langs="langTabs"
      :value="lang"
      @handle-change-tab="handleChangeLang"
      class="leadershipLangButtons__quest"
    />
    <leadership-textarea
      :maxLength="DESCRIPTION_MAX_LENGTH"
      :value="taskDescription"
      @handle-description="handleChangeContent"
      title="Задание"
    />
    <leadership-selectbox
      :value="taskType"
      :options="types"
      label="Тип задания"
      v-if="!hideTypeChecker"
      @change-select="changeTaskType"
    />
  </div>
</template>

<script>
import LangTabs from "@/components/LangTabs.vue";
import LeadershipTextarea from "@/components/Leadership/LeadershipTextarea.vue";
import LeadershipSelectbox from "@/components/Leadership/LeadershipSelectbox.vue";

export default {
  name: "LeadershipTaskHead",
  components: {
    LangTabs,
    LeadershipTextarea,
    LeadershipSelectbox,
  },
  data() {
    return {
      DESCRIPTION_MAX_LENGTH: 10000,
      types: [
        {
          label: "Просмотр видео",
          value: "video",
        },
        {
          label: "Загрузка файла",
          value: "upload",
        },
        {
          label: "Прочитать текст",
          value: "action",
        },
        {
          label: "Тест",
          value: "poll",
        },
      ],
    };
  },
  props: {
    lang: { type: String, required: true },
    langTabs: {
      type: Array,
      required: true,
    },
    taskDescription: {
      type: String,
      required: true,
    },
    taskType: {
      type: [String, Number],
    },
    hideTypeChecker: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    handleChangeLang(lang) {
      this.$emit("change-lang", lang);
    },
    handleChangeContent(value) {
      this.$emit("change-description", value);
    },
    changeTaskType(value) {
      this.$emit("change-task-type", value);
    },
  },
};
</script>

<style lang="stylus" scoped></style>
