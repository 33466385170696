<template>
  <article :key="`${quest.id}_${quest.title}`" class="quests__item">
    <base-link :to="link" class="quests__itemLink" />
    <div class="quests__itemHeader">
      <span class="quest__itemType">{{ $t("Бизнес-квест") }}</span>
      <h2 class="quests__itemTitle">{{ quest.name }}</h2>
    </div>
    <div class="quests__itemBody">
      <span class="quests__itemBody_text">{{ $t("Количество уроков") }}</span>
      <span class="quests__itemBody_text">{{ $t("Статус") }}</span>
      <span class="quests__itemBody_text_bold">{{ quest.tasks_count }}</span>
      <span :class="statusClass" class="quests__itemBody_text_bold">
        <Tr v-if="isPublished">Опубликован</Tr>
        <Tr v-else>Черновик</Tr>
      </span>
    </div>
    <!-- <button class="tempDelete" @click="deleteCourse">delete</button> -->
  </article>
</template>

<script>
export default {
  name: "LeadershipQuestsListItem",
  props: {
    quest: { type: Object, required: true },
  },
  computed: {
    link() {
      return `/leadership/courses/${this.quest.id}/description`;
    },
    isPublished() {
      return !!this.quest.published_at;
    },
    statusClass() {
      return this.quest.published_at
        ? "quests__itemBody_text_bold_green"
        : "quests__itemBody_text_bold_red";
    },
  },
  methods: {
    deleteCourse() {
      this.$store.dispatch(
        "leadershipCourses/deleteCourse",
        this.$props.quest.id
      );
    },
  },
};
</script>

<style lang="stylus" scoped>
.tempDelete {
  position: absolute;
  z-index: 2000;
}

.quests__item {
  position: relative;
  padding: 10px;
  width: 100%;
  max-width: 456px;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0f164214;

  @media (min-width: phone_xl) {
    padding: 20px;
  }

  @media (min-width: desktop) {
    padding: 13px 16px 19px;
    width: auto;
    max-width: unset;
  }
}

.quests__itemLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
}

.quests__itemHeader {
  padding-bottom: 12px;
  border-bottom: 2px solid #e6e7eb;

  @media (min-width: tablet_large) {
    margin-right: -16px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}

.quest__itemType {
  font_small();
}

.quests__itemTitle {
  margin-top: 5px;
  font_subtitle();
}

.quests__itemBody {
  padding-top: 12px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 2px;
}

.quests__itemBody_text {
  font_small();
}

.quests__itemBody_text_bold {
  font_bold();
}

.quests__itemBody_text_bold_green {
  color: #19c564;
}

.quests__itemBody_text_bold_red {
  color: #e81526;
}
</style>
