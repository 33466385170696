<template>
  <div class="tabListBox">
    <ul class="tabList" :class="{ tabList_mobile: mobileBtns }">
      <li
        :key="tab.title"
        class="tabItem"
        :class="{ tabItem_mobile: mobileBtns }"
        v-for="tab in tabs"
      >
        <base-link
          v-if="tab.link"
          :to="tab.link"
          :class="{ tabButton_active: activeTab === tab.name }"
          class="tabButton tabLink"
        >
          <Tr>{{ tab.title }}</Tr>
        </base-link>
        <button
          :class="[
            { tabButton_mobile: mobileBtns },
            {
              tabButton_createLink:
                tab.name === 'paymentLink' || tab.name === 'webinarLink',
            },
            { tabButton_createLink_payment: tab.name === 'paymentLink' },
            { tabButton_createLink_webinar: tab.name === 'webinarLink' },
            { tabButton_active: activeTab === tab.name },
          ]"
          @click="tabClicked(tab.name)"
          class="tabButton activeItem"
          v-else
        >
          <Tr>{{ tab.title }}</Tr>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabList",
  props: {
    tabs: Array,
    activeTab: String,
    mobileBtns: Boolean,
  },
  methods: {
    tabClicked(tabName) {
      this.$emit("handle-main-tabs", tabName);
    },
  },
};
</script>

<style lang="stylus" scoped>
.tabListBox {
  padding-bottom: 5px;
  overflow-x: auto;
  max-width: calc(100vw - 28px);
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  @media (min-width: tablet_large) {
    overflow-x: visible;
    max-width: unset;
  }
}

.tabListBox::-webkit-scrollbar {
  height: 10px;
}

.tabListBox::-webkit-scrollbar-track {
  background: #fff;
}

.tabListBox::-webkit-scrollbar-thumb {
  background-color: #e6e7eb;
  border: 2px solid #fff;
  border-radius: 6px;
}

.tabList {
  min-width: max-content;
  width: 100%;
  border-bottom: 4px solid #e6e7eb;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  display: block;
  padding: 4px 20px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);

  &_createLink {
    position: relative;
    padding-left: 36px;

    &::before {
      position: absolute;
      content: '';
      top: 4px;
      left: 12px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &_createLink_payment {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='unset' %3E%3Cpath fill='%230f1642' d='M8.063 2.996l.013-.009.218.06.4.086-.014.018 1.071.29 2.721.657-3.778 2.169-1.24-.354.01-.015-1.168-.316-2.37-.506 3.528-2.212zM3.344 6.664l5.09 1.161.088 4.672-5.178-1.333v-4.5zm6.26 7.94l5.001-3.235-.22-7.697-7.531-2.01-5.086 3.233-.106 7.607 7.941 2.102h.001z' %3E%3C/path%3E%3C/svg%3E");
    }
  }

  &_createLink_webinar {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M15.665,13.309l-1.178-1.18,1.178-1.178A4.167,4.167,0,1,0,9.773,5.058L8.594,6.237,7.415,5.059,8.6,3.88a5.834,5.834,0,0,1,8.25,8.25l-1.179,1.178Zm-2.357,2.357-1.179,1.178a5.834,5.834,0,0,1-8.25-8.25L5.058,7.415,6.237,8.6,5.058,9.774a4.167,4.167,0,1,0,5.893,5.893l1.178-1.178,1.179,1.178Zm-.59-8.839L13.9,8.005,8.005,13.9,6.826,12.719l5.892-5.892Z' transform='translate(-0.362 -0.362)'/%3E%3C/svg%3E");
    }
  }

  @media (min-width: tablet) {
    padding: 4px 16px 6px;

    &_createLink {
      padding-left: 36px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
}

.tabButton_active, .router-link-active {
  position: relative;
}

.tabButton_active::after, .router-link-active::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff9f43;
}
</style>
