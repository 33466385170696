import postfixParser from "@/utils/postfixParser";

class Universal {
  constructor(data: object) {
    Object.assign(this, this.transformApiModel(data));
  }

  // Метод трансформирует объект и его сойства первого уровня с префиксной нотацией _${lang} в объектную модель

  private transformApiModel(obj: object) {
    const parsed = postfixParser.parse(obj);

    return parsed;
  }

  // Метод преобразует объектную модель в префиксную нотацию. Если объект не передан, преобразует свойства this

  transformLocales(props?: object) {
    if (props) {
      return postfixParser.toPrefix(props);
    }

    return postfixParser.toPrefix(this);
  }
}

export default Universal;
