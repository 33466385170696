<template>
  <article
    class="task"
    @click="openTaskModal"
    :style="`borderColor: ${typeColor}`"
  >
    <div class="task__body" :class="{ task__body_bordered: Boolean(name) }">
      <bonuses
        v-if="taskBonuses"
        :gold="task.reward_gold"
        :diamonds="task.reward_brilliant"
        :darkNums="true"
        class="task__bonuses"
      />
      <h2 :class="['task__title', { widthWithBonus: taskBonuses }]">
        {{ task.dynamic_title }}
      </h2>
      <p class="task__text" v-if="task.is_meet">
        {{ task.meet_object.type_verbose }}
      </p>
      <p
        :class="['questDescription', { widthWithBonus: taskBonuses }]"
        v-if="task.quest_user_task_description"
      >
        {{ task.quest_user_task_description }}
      </p>
      <p class="task__text">
        {{ task.description }}
      </p>
      <div class="task__deadline">
        <template v-if="task.is_meet">
          <Tr>Начало встречи:</Tr>
          <span class="task__deadlineDate">
            {{
              formatDateTz(
                task.meet_object.started_at,
                currentLang,
                userTimezone
              )
            }}
          </span>
        </template>
        <template v-else>
          <Tr>Крайний срок выполнения:</Tr>
          <span class="task__deadlineDate">
            {{ formatDateTz(task.ended_at, currentLang, userTimezone) }}
          </span>
        </template>
      </div>
      <div class="task__deadline" v-if="task.done_at">
        <Tr>Выполнена:</Tr>
        <span class="task__deadlineDate">
          {{ formatDateTz(task.done_at, currentLang, userTimezone) }}
        </span>
      </div>
    </div>
    <div class="task__leadBox" v-if="Boolean(name)">
      <!-- AVATAR -->
      <div class="task__leadAvatar" :style="randomColor">
        {{ name | getInitialsFilter }}
      </div>
      <!-- AVATAR -->

      <!-- NAME -->
      <span class="task__leadName">
        <template v-if="task.lead_service_lead_id">{{
          task.lead_service_lead_id
        }}</template>
        {{ name }}
      </span>
      <!-- NAME -->
    </div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDateTz } from "@/utils/formatDateTz.ts";

import Bonuses from "@/components/Bonuses.vue";

export default {
  name: "TasksItem",

  components: {
    Bonuses,
  },

  props: {
    task: {
      type: Object,
      required: true,
    },

    contacts: {
      type: Array,
    },

    contactFromContacts: {
      type: Object,
    },

    activeTaskTab: {
      type: String,
    },
  },

  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      baseUrl: "user/mainSiteLink",
      userTimezone: "user/userTimezone",
    }),

    taskBonuses() {
      return Boolean(this.task.reward_gold) || Boolean(this.task.reward_brilliant);
    },

    randomColor() {
      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      }
      const str = `background: linear-gradient(#fff -125%, rgb(${getRandomInt(
        150,
        240
      )}, ${getRandomInt(150, 240)}, ${getRandomInt(150, 240)}))`;
      return str;
    },

    contact() {
      if (this.contactFromContacts) {
        return this.contactFromContacts;
      }
      const contact = this.contacts.find(el => el.id === this.task.contact);
      return contact ? contact : {};
    },

    name() {
      if (this.task.lead_service_lead_name) {
        return this.task.lead_service_lead_name;
      } else if (this.contact && (this.contact.first_name || this.contact.last_name)) {
        return `${this.contact.first_name} ${this.contact.last_name}`;
      } else {
        return '';
      }
    },

    meetType() {
      return this.task.type === 'Встреча с контактом'
    },

    reminderType() {
      return this.task.type === 'Напоминание про контакт'
    },

    taskTitle() {
      return this.task.type ? this.task.type : this.task.dynamic_title;
    },

    typeColor() {
      const typeColors = {
        green: "#28c76f",
        green2: "#0ac724",
        red: "#fa678e",
        red2: "#fa3953",
        orange: "#ff9f43",
        orange2: "#ff7100",
        yellow: "#fff700",
        yellow2: "#fff686",
        purple: "#7467f0",
        purple2: "#7803f0",
        blue: "#394df0",
        blue2: "#2f8cf0",
      };
      return typeColors[this.task.type_color];
    },
  },

  methods: {
    openTaskModal() {
      if (this.task.has_modal) {
        this.$modal.show("tasks-item-modal", { task: this.task, name: this.name, contact: this.contact, avatarColor: this.randomColor, activeTaskTab: this.activeTaskTab });
      } else {
        const href = `${this.baseUrl}${this.task.absolute_url}`;
        window.open(href, "_blank");
      }
    },

    formatDateTz,
  },
};
</script>

<style lang="stylus" scoped>
.task {
  position: relative;
  z-index: 1;
  padding: 6px 12px 8px;
  max-width: 300px;
  box-shadow: 0 3px 10px 0 rgba(15, 22, 66, 0.1);
  border-left: 8px solid #e6e7eb;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.1s;

  @media (min-width: phone_s) {
    max-width: 350px;
  }

  @media (min-width: tablet) {
    max-width: 520px;
  }

  @media (min-width: desktop) {
    &:hover {
      box-shadow: 5px 8px 15px 5px rgba(15, 22, 66, 0.1);
      transform: translate(0px, -5px);
    }
  }

  &__body {
    position: relative;
    z-index: 1;
    margin: 0 -12px;
    padding: 0 12px 6px;

    &_bordered {
      border-bottom: 2px solid grey_text_color;
    }
  }

  &__bonuses {
    position: absolute;
    z-index: 2;
    top: 3px;
    right: 12px;
  }

  &__title {
    margin-bottom: 8px;
    font(main_text_color, 16px, 700, 20px);
    max-height: 20px;
  }

  &__title, &__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__text {
    margin-bottom: 8px;
    max-height: 18px;
    font_regular();
  }

  &__deadline {
    font_small();
    opacity: 0.4;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }

  &__deadlineDate {
    display: block;

    @media (min-width: tablet) {
      display: inline-block;
    }
  }

  &__leadBox {
    display: grid;
    grid-template-columns: 20px auto;
    grid-column-gap: 12px;
    align-items: center;
    padding-top: 8px;

    @media (min-width: desktop) {
      grid-template-columns: 24px auto;
    }
  }

  &__leadAvatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    font(main_text_color, 10px, 700, 12px);
    border-radius: 50%;
    overflow: hidden;

    @media (min-width: desktop) {
      width: 24px;
      height: 24px;
    }
  }

  &__leadName {
    position: relative;
    display: flex;
    font_regular();

    & span {
      overflow: hidden;
      white-space: nowrap;

      & span {
        display: inline;
      }
    }
  }
}

.questDescription {
  margin-bottom: 8px;
  font_regular();
  white-space: pre-line;
}

.widthWithBonus {
  max-width: calc(100% - 45px);
}
</style>
