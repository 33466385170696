<template>
  <div class="languages">
    <SidebarSubmenuLangsButton :key="lang" :lang="lang" v-for="lang in langs" />
  </div>
</template>

<script>
import SidebarSubmenuLangsButton from "@/components/Sidebar/SidebarSubmenuLangsButton.vue";

export default {
  name: "SidebarSubmenuLangs",
  components: {
    SidebarSubmenuLangsButton,
  },
  computed: {
    langs() {
      const arr = this.$store.getters["supportedLangs"];
      return arr;
    },
  },
};
</script>

<style lang="stylus" scoped>
.languages {
  margin-left: -5px;

  @media (min-width: desktop) {
    margin-left: 0px;
  }
}
</style>
