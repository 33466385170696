<template>
  <modal-layout
    :modalWidth="480"
    :pending="false"
    :name="modalName"
    @before-close="beforeClose"
    @before-open="beforeOpen"
  >
    <template #title>Управление тегами</template>
    <template #body>
      <div class="tagsPopupInnerWrap">
        <div class="tagsPopupInner" v-if="contactTags.length > 0">
          <!-- <input class="tagsPopupInput" type="text" @input="searchTag" /> -->
          <contacts-modal-tags-item
            v-for="tag in contactTags"
            :key="tag.id"
            :tag="tag"
            @setContactTags="setContactTags"
            @editTag="editTag"
          />
        </div>
      </div>

      <div
        :class="[
          'tagsPopupFooter',
          { tagsPopupFooter_bordered: contactTags.length > 0 },
        ]"
      >
        <button
          class="tagsPopupButton"
          type="button"
          @dblclick="dblclick"
          @click="createTag"
        >
          <Tr>Создать тег</Tr>
        </button>
      </div>
    </template>
  </modal-layout>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { runNotice } from "@/utils/notifications";

import ModalLayout from "@/components/ModalLayout.vue";
import ContactsModalTagsItem from "@/components/Contacts/ContactsModalTagsItem.vue";

export default {
  name: "ContactsModalTags",

  components: {
    ModalLayout,
    ContactsModalTagsItem,
  },

  data() {
    return {
      modalName: "contact-tags-management",
      maxLength: 32,
      contactTags: [],
    };
  },

  computed: {
    ...mapGetters({
      createdTag: "contactsTags/createdTag",
      currentPage: "contacts/currentPage",
    }),
    ...mapState(
      'contactsTags', ['tagsColorObjects', 'tags']
    ),

    name() {
      return this.createdTag.name ? this.createdTag.name : "";
    },

    valueLength() {
      if (this.name.length === 0) {
        return 0;
      }
      return this.name.length;
    },

    createBtnDisabled() {
      if (this.createdTag.name.length === 0) return true;
      else if (this.createdTag.color.length === 0) return true;
      return false;
    },
  },

  methods: {
    setContactTags() {
      this.contactTags = [...this.tags];
    },

    beforeClose() {
      this.$store.commit("contactsTags/CLEAR_TAG");
    },

    beforeOpen() {
      this.setContactTags();
    },

    createTag() {
      this.$modal.hide(this.modalName);
      this.$modal.show('create-contact-tag', {});
    },


    editTag(tag) {
      this.$modal.hide(this.modalName);
      this.$modal.show('create-contact-tag', { tag: tag });
    },

    dblclick(event) {
      event.preventDefault();
    },

    searchTag() {
      console.info('searchTag');
    },
  },
};
</script>

<style lang="stylus" scoped>
.tagsPopupInnerWrap {
  max-height: 160px;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e7eb;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}

.tagsPopupInner {
  display: grid;
  grid-row-gap: 4px;
  padding: 3px 0 4px;

  @media (min-width: desktop) {
    grid-row-gap: 3px;
  }
}

.tagsPopupInput {
  padding: 2px;
  width: 100%;
  border: 1px solid grey_text_color;
  border-radius: 2px;
  font-size: 12px;
  line-height: 14px;
}

.tagsPopupFooter {
  position: relative;
  display: flex;
  margin-bottom: -10px;
  padding: 10px 0 0;

  &_bordered {
    &::before {
      position: absolute;
      content: '';
      top: -4px;
      right: 0;
      width: 100%;
      height: 4px;
      background-image: linear-gradient(180deg, #FFFFFF00 0%, #fff 100%);
    }
  }
}

.tagsPopupButton {
  padding: 0;
  border: none;
  background-color: unset;
  font-size: 12px;
  line-height: 14px;
  color: secondary_text_color;
  border-bottom: 2px dotted secondary_text_color;
}
</style>
