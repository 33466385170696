<template>
  <div class="leadsCreateLinkSuccess">
    <div class="leadsCreateLinkSuccess__header">
      <div class="leadsCreateLinkSuccess__title">
        <Tr>Ссылка</Tr>
        "{{ createdName }}"
        <Tr>создана</Tr>
      </div>
    </div>
    <div class="leadsCreateLinkSuccess__body">
      <div class="leadsCreateLinkSuccess__inputBox">
        <label
          for="funnel__linkCreatedInput"
          class="leadsCreateLinkSuccess__label"
        >
          <Tr>Ссылка</Tr>
        </label>
        <input
          id="funnel__linkCreatedInput"
          class="leadsCreateLinkSuccess__input"
          type="text"
          :value="createdUrl"
        />
        <button
          class="leadsCreateLinkSuccess__copy"
          type="button"
          v-clipboard="copy"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

export default {
  name: "LeadsCreateLinkSuccessWithoutChatbot",
  computed: {
    ...mapGetters({
      createdUrl: "funnelCreateLinkWithoutChatbot/createdUrl",
      createdName: "funnelCreateLinkWithoutChatbot/createdName",
    }),
  },
  methods: {
    copy() {
      runNotice("success", this.$t("Ссылка скопирована"));
      return this.createdUrl;
    },
    newLink() {
      this.$store.commit("funnelCreateLinkWithoutChatbot/CLEAR_STORE");
    },
  },
  beforeDestroy() {
    this.$store.commit("funnelCreateLinkWithoutChatbot/CLEAR_STORE");
  },
};
</script>

<style lang="stylus" scoped>
.leadsCreateLinkSuccess {
  width: 100%;
  max-width: 692px;
  background-color: #fff;
  overflow: hidden;

  @media (min-width: desktop) {
  }

  &__header {
    padding: 7px 0;
  }

  &__title {
    font_bold();
    color: main_text_color;
  }

  &__body {
    padding: 10px 6px;

    @media (min-width: desktop) {
      padding: 10px 0 0;
    }
  }

  &__inputBox {
    margin-bottom: 16px;
  }

  &__label {
    display: block;
    margin-bottom: 4px;
    font_small();
  }

  &__input {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 12px;
    padding: 7px 8px;
    width: calc(100% - 40px);
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    @media (min-width: desktop) {
      width: 400px;
    }
  }

  &__copy {
    width: 14px;
    height: 16px;
    background-color: unset;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='16' viewBox='0 0 14 16'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M4.667 1.6h7.777v9.6H10.89v-8H4.667zm4.666 3.2v9.6H1.556l.002-9.6zM.786 3.2a.791.791 0 0 0-.784.8L0 15.2c0 .213.083.417.23.567.147.15.346.234.553.233h9.323a.791.791 0 0 0 .783-.8v-2.4h2.333A.79.79 0 0 0 14 12V.8a.79.79 0 0 0-.778-.8H3.89a.79.79 0 0 0-.778.8v2.4z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
  }
}
</style>
