<template>
  <div class="underLoader">
    <div class="loader__box">
      <LeadsTelephonyAttention
        v-if="!hasCookie && telephonyAttention && !isChrome"
        @set-cookie="setCookie"
      />
      <no-content
        v-if="!pending && leads.length !== undefined && isNoContent"
        :noContent="noContentEmpty"
      />

      <updated-leads-menu
        v-if="leads.length > 0 || filtersFilled || filtersIsActive"
        :pending="pending"
        :funnels="funnels"
        :tags="tags"
        :orders="orders"
        :statusesFilter="true"
        :statuses="statuses"
        :filters="filters"
        :isFilterChecked="isFilterChecked"
        :pickedAllFunnels="pickedAllFunnels"
        :pickedAllTags="pickedAllTags"
        :pickedAllOrders="pickedAllOrders"
        :pickedAllStatuses="pickedAllStatuses"
        :funnelsFilterValue="funnelsFilterValue"
        :funnelsFilterCounter="funnelsFilterCounter"
        :tagsFilterValue="tagsFilterValue"
        :tagsFilterCounter="tagsFilterCounter"
        :ordersFilterValue="ordersFilterValue"
        :ordersFilterCounter="ordersFilterCounter"
        :statusesFilterValue="statusesFilterValue"
        :statusesFilterCounter="statusesFilterCounter"
        :search="search"
        :filtersIsActive="filtersIsActive"
        :leads="leads"
        @changeFilter="changeFilter"
        @pickAllFunnels="pickAllFunnels"
        @pickAllTags="pickAllTags"
        @pickAllOrders="pickAllOrders"
        @pickAllStatuses="pickAllStatuses"
        @clearFilters="clearFilters"
        @changeFunnelsFilterValue="changeFunnelsFilterValue"
        @changeFunnelsFilterCounter="changeFunnelsFilterCounter"
        @changeTagsFilterValue="changeTagsFilterValue"
        @changeTagsFilterCounter="changeTagsFilterCounter"
        @changeOrdersFilterValue="changeOrdersFilterValue"
        @changeOrdersFilterCounter="changeOrdersFilterCounter"
        @changeStatusesFilterValue="changeStatusesFilterValue"
        @changeStatusesFilterCounter="changeStatusesFilterCounter"
        @change-search="changeSearch"
        @handleFilter="handleFilter"
      />

      <updated-leads-body
        v-if="leads.length > 0 || filtersFilled || filtersIsActive"
        :leads="leads"
        @callToLead="callToLead"
      />

      <button
        class="showmoreButton"
        type="button"
        @click="fetchMore"
        v-if="hasNextPage"
      >
        <Tr>Показать больше</Tr>
      </button>
    </div>
    <div class="loader__layout" v-if="pending">
      <loader />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { runNotice } from "@/utils/notifications";
import Cookies from "js-cookie";
import { isEmpty, pathEq } from "ramda";

import NoContent from "@/components/NoContent.vue";
import UpdatedLeadsMenu from "@/components/Leads/UpdatedLeadsMenu.vue";
import UpdatedLeadsBody from "@/components/Leads/UpdatedLeadsBody.vue";
import Loader from "@/components/Loader.vue";
import LeadsTelephonyAttention from "@/components/Leads/LeadsTelephonyAttention.vue";

export default {
  name: "UpdatedLeadsContainer",
  components: {
    NoContent,
    UpdatedLeadsMenu,
    UpdatedLeadsBody,
    Loader,
    LeadsTelephonyAttention,
  },
  data() {
    return {
      filtersIsActive: false,
      telephonyAttention: true,
    };
  },

  computed: {
    ...mapGetters({
      filters: "updatedLeads/filters",
      leads: "updatedLeads/leads",
      statuses: "updatedLeadsStatuses/statuses",
      pendingLeads: "updatedLeads/pending",
      pendingUser: "user/pending",
      hasNextPage: "updatedLeads/hasNextPage",
      currentPage: "updatedLeads/currentPage",

      funnels: "updatedFilterFunnels/funnels",
      tags: "updatedTags/tags",
      orders: "updatedOrderStatuses/orders",
      isFilterChecked: "updatedLeads/isFilterChecked",
      pickedAllFunnels: "updatedLeads/pickedAllFunnels",
      pickedAllTags: "updatedLeads/pickedAllTags",
      pickedAllOrders: "updatedLeads/pickedAllOrders",
      pickedAllStatuses: "updatedLeads/pickedAllStatuses",
      funnelsFilterValue: "updatedLeads/funnelsFilterValue",
      funnelsFilterCounter: "updatedLeads/funnelsFilterCounter",
      tagsFilterValue: "updatedLeads/tagsFilterValue",
      tagsFilterCounter: "updatedLeads/tagsFilterCounter",
      ordersFilterValue: "updatedLeads/ordersFilterValue",
      ordersFilterCounter: "updatedLeads/ordersFilterCounter",
      statusesFilterValue: "updatedLeads/statusesFilterValue",
      statusesFilterCounter: "updatedLeads/statusesFilterCounter",
      search: "updatedLeads/search",
      userBrowser: "userBrowser",
    }),

    ...mapState("caller", ["user"]),

    pending() {
      return this.pendingLeads || this.pendingUser;
    },
    noContentEmpty() {
      return {
        noFunnels: false,
        text: "Пусто. У вас еще нет ни одного лида",
        maxWidth: 0,
      };
    },
    filtersFilled() {
      return (
        (Object.keys(this.filters).length > 1 && this.leads.length === 0) ||
        (this.filters.q.length > 0 && this.leads.length === 0)
      );
    },
    isNoContent() {
      return (
        Object.keys(this.filters).length === 1 &&
        this.filters.q.length === 0 &&
        this.leads.length === 0
      );
    },
    hasCookie() {
      return Cookies.get("telephony-attention");
    },
    isChrome() {
      if (
        pathEq(["name"], "chrome")(this.userBrowser) ||
        pathEq(["name"], "crios")(this.userBrowser)
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    fetchMore() {
      this.$store.dispatch("updatedLeads/fetchChatbotLeads", {
        page: this.currentPage + 1,
      });
    },

    changeFilter(payload) {
      this.$store
        .dispatch("updatedLeads/changeFilter", payload)
        .then(() => {
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllFunnels(payload) {
      this.$store
        .dispatch("updatedLeads/pickAllFunnels", payload)
        .then(() => {
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllTags(payload) {
      this.$store
        .dispatch("updatedLeads/pickAllTags", payload)
        .then(() => {
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllOrders(payload) {
      this.$store
        .dispatch("updatedLeads/pickAllOrders", payload)
        .then(() => {
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    pickAllStatuses(payload) {
      this.$store.dispatch("updatedLeads/pickAllStatuses", payload)
        .then(() => {
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
        })
        .catch(e => {
          console.log(e);
        });
    },
    clearFilters() {
      this.$store.commit("updatedLeads/CLEAR_FILTERS");
      this.$store.dispatch("updatedLeads/fetchChatbotLeads", { page: 1 });
    },

    changeFunnelsFilterValue(value) {
      this.$store.commit("updatedLeads/SET_FUNNELS_FILTER_VALUE", value);
    },
    changeFunnelsFilterCounter(value) {
      this.$store.commit("updatedLeads/SET_FUNNELS_FILTER_COUNTER", value);
    },

    changeTagsFilterValue(value) {
      this.$store.commit("updatedLeads/SET_TAGS_FILTER_VALUE", value);
    },
    changeTagsFilterCounter(value) {
      this.$store.commit("updatedLeads/SET_TAGS_FILTER_COUNTER", value);
    },

    changeOrdersFilterValue(value) {
      this.$store.commit("updatedLeads/SET_ORDERS_FILTER_VALUE", value);
    },
    changeOrdersFilterCounter(value) {
      this.$store.commit("updatedLeads/SET_ORDERS_FILTER_COUNTER", value);
    },

    changeStatusesFilterValue(value) {
      this.$store.commit("updatedLeads/SET_STATUSES_FILTER_VALUE", value);
    },
    changeStatusesFilterCounter(value) {
      this.$store.commit("updatedLeads/SET_STATUSES_FILTER_COUNTER", value);
    },

    changeSearch(value) {
      this.$store.dispatch("updatedLeads/changeSearch", { search: value });
    },

    handleFilter() {
      this.filtersIsActive = !this.filtersIsActive;
    },

    callToLead(data) {
      const startCall = () => {
        Promise.all([this.$store.dispatch("caller/initAudioDevices")])
          .then(() => {
            this.$store.dispatch("caller/startCall", data);
            this.$modal.show("telephony-modal");
          })
          .catch(e => {
            console.log(e);
          });
      };
      const noAccess = () =>
        runNotice(
          "error",
          this.$t("Включите доступ к микрофону в настройках браузера")
        );

      if (this.isFirefox) {
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(function () {
            startCall();
          })
          .catch(function (err) {
            console.log("No mic for you!");
          });
        return;
      }

      if (navigator.permissions) {
        navigator.permissions
          .query({ name: "microphone" })
          .then(result => {
            const permission = result.state;

            if (permission === "granted") {
              startCall();
            } else {
              navigator.mediaDevices
                .getUserMedia({ audio: true })
                .then(function () {
                  startCall();
                })
                .catch(function (err) {
                  console.log("No mic access");
                  noAccess();
                });
            }
          })
          .catch(error => {
            console.log("Got error :", error);
            runNotice(
              "error",
              this.$t("Включите доступ к микрофону в настройках браузера")
            );
          });
      } else {
        startCall();
      }

      return;
    },

    setCookie() {
      Cookies.set("telephony-attention", "telephony-attention-value", {
        expires: 180,
      });
      this.telephonyAttention = false;
    },
  },

  mounted() {
    this.$store.dispatch("user/fetch")
      .then(response => {
        if (response && response.status === 200) {
          this.$store.dispatch("updatedLeadsStatuses/fetch");
          this.$store.dispatch("updatedLeads/fetchChatbotLeads", {
            page: 1
          })
            .then(response => {
              if (response && response.status === 200) {
                this.$store.dispatch("updatedFilterFunnels/fetch");
                this.$store.dispatch("updatedTags/fetch");
                this.$store.dispatch("updatedOrderStatuses/fetch");
                this.$store.dispatch("assignmentManagers/fetch");
              }
            })
            .catch(e => {
              console.log(e);
            });
        }
      })
      .catch(e => {
        console.log(e);
      });
    if (isEmpty(this.user)) {
      this.$store.dispatch("caller/initDevice");
    }
  },
};
</script>

<style lang="stylus" scoped>
.showmoreButton {
  display: block;
  margin-top: 20px;
  padding: 7px 8px;
  background-color: unset;
  border: 1px dashed main_text_color;
  border-radius: 4px;
  font_regular();
}

.loader {
  &__box {
    position: relative;
  }

  &__layout {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fff;
    opacity: 0.5;
  }
}
</style>
