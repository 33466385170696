<template>
  <fieldset class="formStatus__fieldset">
    <legend class="formStatus__legend">
      <Tr>{{ fieldset.legend }}</Tr>
    </legend>
    <div
      :class="[
        'formStatus__inputWrap',
        { formStatus__inputWrap_progress: fieldset.name === 'progress' },
      ]"
    >
      <label
        class="formStatus__label"
        v-for="radiobtn in fieldset.radiobtns"
        :key="radiobtn.param"
      >
        <!-- heat images -->
        <template v-if="fieldset.name === 'heat'">
          <svg class="formStatus__labelImg" v-if="radiobtn.param === 'cold'">
            <use xlink:href="@/assets/svg/sprite_contacts.svg#cold" />
          </svg>
          <svg
            class="formStatus__labelImg"
            v-else-if="radiobtn.param === 'warm'"
          >
            <use xlink:href="@/assets/svg/sprite_contacts.svg#warm" />
          </svg>
          <svg
            class="formStatus__labelImg"
            v-else-if="radiobtn.param === 'hot'"
          >
            <use xlink:href="@/assets/svg/sprite_contacts.svg#hot" />
          </svg>
          <div class="formStatus__labelImg" v-else />
        </template>

        <!-- top images -->
        <template v-else-if="fieldset.name === 'top'">
          <div
            class="formStatus__labelImg"
            :class="{
              formStatus__labelImg_yellow: radiobtn.param === '100',
              formStatus__labelImg_green: radiobtn.param === '20_and_100',
            }"
          />
        </template>

        <!-- progress images -->
        <template v-else-if="fieldset.name === 'progress'">
          <div
            :class="[
              'progressImg',
              { progressImg_active: radiobtn.param <= contact.progress },
            ]"
            v-tooltip="radiobtn.verbose"
          />
        </template>

        <input
          :name="fieldset.name"
          :checked="isChecked(radiobtn.param)"
          :value="radiobtn.param"
          class="formStatus__input"
          type="radio"
          @change="changeStatus(radiobtn.param)"
        />
        <span class="formStatus__labelTitle" v-if="fieldset.name === 'heat'">
          {{ radiobtn.verbose }}
        </span>
        <span
          class="formStatus__labelTitle"
          v-else-if="fieldset.name === 'top'"
        >
          <template v-if="radiobtn.param === '20_and_100'">
            <Tr>Топ 20</Tr>
          </template>
          <template v-else-if="radiobtn.param === '100'">
            <Tr>Топ 100</Tr>
          </template>
          <template v-else>
            {{ radiobtn.verbose }}
          </template>
        </span>
      </label>
    </div>
    <span class="formStatus__labelTitle" v-if="fieldset.name === 'progress'">
      {{ progressVerbose }}
    </span>
  </fieldset>
</template>

<script>
export default {
  name: "ContactsProfileStatusFieldsets",
  props: {
    fieldset: {
      type: Object,
      required: true,
    },

    contact: {
      type: Object,
      required: true,
    },
  },
  computed: {
    progressVerbose() {
      if (this.fieldset.name === "progress") {
        const findedEl = this.fieldset.radiobtns.find(
          el => this.contact.progress === el.param
        );
        return findedEl ? findedEl.verbose : "";
      }
      return "";
    },
  },
  methods: {
    isChecked(param) {
      if (param === "unmarked") {
        if (this.contact[this.fieldset.name] === "") return true;
      }
      return this.contact[this.fieldset.name] === param;
    },

    changeStatus(param) {
      if (param === "unmarked") {
        this.$emit("change-status", { name: this.fieldset.name, param: "" });
      } else {
        this.$emit("change-status", { name: this.fieldset.name, param });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
imgWidthMobile = 70px;
imgWidthMobileXS = 80px;
imgWidth = 120px;
progressImgWidthMobile = 27px;
progressImgWidth = 50px;

.formStatus__legend {
  font_bold();
}

.formStatus__inputWrap {
  display: grid;
  grid-template-columns: repeat(4, imgWidthMobile);
  grid-gap: 2px;
  margin-top: 12px;

  @media (min-width: 374px) {
    grid-template-columns: repeat(4, imgWidthMobileXS);
  }

  &_progress {
    grid-template-columns: repeat(10, progressImgWidthMobile);
  }
}

.formStatus__label {
  position: relative;
  padding-top: 12px;
  font-weight: 400;
  cursor: pointer;

  &:nth-of-type(1) {
    .progressImg_active {
      background-color: #E91927;
    }
  }

  &:nth-of-type(2) {
    .progressImg_active {
      background-color: #EE4A2E;
    }
  }

  &:nth-of-type(3) {
    .progressImg_active {
      background-color: #F37934;
    }
  }

  &:nth-of-type(4) {
    .progressImg_active {
      background-color: #F8A33A;
    }
  }

  &:nth-of-type(5) {
    .progressImg_active {
      background-color: #FFDB42;
    }
  }

  &:nth-of-type(6) {
    .progressImg_active {
      background-color: #DADC48;
    }
  }

  &:nth-of-type(7) {
    .progressImg_active {
      background-color: #A9D650;
    }
  }

  &:nth-of-type(8) {
    .progressImg_active {
      background-color: #83D255;
    }
  }

  &:nth-of-type(9) {
    .progressImg_active {
      background-color: #4FCB5C;
    }
  }

  &:nth-of-type(10) {
    .progressImg_active {
      background-color: #1BC563;
    }
  }

  @media (min-width: phone) {
    padding-top: 18px;
  }
}

.formStatus__labelImg {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: imgWidthMobile;
  height: 12px;
  background-color: #e6e7eb;

  @media (min-width: 374px) {
    width: 80px;
    height: 14px;
  }

  @media (min-width: phone) {
    width: imgWidth;
    height: 20px;
  }
}

.formStatus__input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.formStatus__input:checked + .formStatus__labelTitle::after {
  background-color: #ff9f43;
}

.formStatus__labelTitle {
  position: relative;
  font(main_text_color, 10px, 400, 12px);

  @media (min-width: phone) {
    font(main_text_color, 12px, 400, 15px);
  }
}

.formStatus__labelTitle::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
}

.formStatus__labelImg_yellow {
  background-color: #ffe043;
}

.formStatus__labelImg_green {
  background-color: #19c564;
}

@media (min-width: phone) {
  .formStatus__inputWrap {
    grid-auto-flow: column;
    grid-template-columns: repeat(4, imgWidth);

    &_progress {
      grid-template-columns: repeat(10, progressImgWidth);
    }
  }

  .formStatus__label {
    padding-top: 23px;

    &:hover {
      opacity: 0.6;
    }
  }
}

.progressImg {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: progressImgWidthMobile;
  height: 12px;
  background-color: #e6e7eb;

  @media (min-width: phone) {
    width: progressImgWidth;
    height: 20px;
  }
}
</style>
