import MentorFileCheck from "@/models/mentorFileCheck";
import router from "@/router";
import pickGeneral from "@/utils/pickGeneral";
import postfixParser from "@/utils/postfixParser";
import { omit } from "ramda";
import Vue from "vue";
import { runNotice } from "@/utils/notifications";
import i18n from "@/plugins/i18n";

export default {
  namespaced: true,
  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CLEAR_DATA(state) {
      state.data = {};
    },
  },

  actions: {
    initNew({ commit }) {
      commit("SET_DATA", new MentorFileCheck());
    },
    init({ commit }, data) {
      commit("SET_DATA", new MentorFileCheck(data));
    },

    async fetch({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.get(
          `/leadership_quest_task_uploads/${id}/`
        );
        if (response.status === 200) {
          commit("SET_DATA", new MentorFileCheck(response.data));
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
        console.info(e);
      }
    },

    saveUpload({ dispatch, state }, data) {
      const { id } = state.data;
      const generalData = pickGeneral(data);

      if (id) {
        dispatch("patchUpload", generalData);
      } else {
        dispatch("postUpload", generalData);
      }
    },

    async postUpload({ commit, dispatch, rootState }, data) {
      try {
        commit("SET_PENDING", true);
        const parsedData = {
          ...postfixParser.toPrefix(omit(["type"], data)),
          quest_task: rootState.leadershipLesson.data.id,
        };

        const response = await Vue.axios.post(
          "/leadership_quest_task_uploads/",
          parsedData
        );
        if (response.status === 201) {
          commit("SET_PENDING", false);
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
          const { courseId, lessonId, lang } = router.currentRoute.params;
          router.push(
            `/${lang}/leadership/courses/${courseId}/lessons/${lessonId}/works?type=video`
          );
        }
      } catch (e) {
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
        commit("SET_PENDING", false);
      }
    },

    async patchUpload({ state, commit, dispatch, rootState }, data) {
      try {
        commit("SET_PENDING", true);
        const parsedData = {
          ...postfixParser.toPrefix(omit(["type"], data)),
          // quest_task: rootState.leadershipLesson.data.id,
        };

        const response = await Vue.axios.patch(
          `/leadership_quest_task_uploads/${state.data.id}/`,
          parsedData
        );
        if (response.status === 200) {
          commit("SET_PENDING", false);
          runNotice("success", i18n.t("Сохранено"));
          dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        dispatch("leadershipTask/setErrors", e.response.data, { root: true });
        commit("SET_PENDING", false);
      }
    },

    async deleteUpload({ commit }, id) {
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.delete(
          `/leadership_quest_task_uploads/${id}/`
        );
        if (response.status === 204) {
          commit("SET_PENDING", false);
          return true;
          // dispatch("leadershipLesson/refresh", undefined, { root: true });
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
