<template>
  <div>
    <leadership-layout
      :title="title"
      :breadcrumbs="breadcrumbs"
      :tabs="tabs"
      :pending="pending"
    >
      <leadership-course
        :course="course"
        :errors="errors"
        :lang="lang"
        :supportedLangsTitled="supportedLangsTitled"
        :courseName="name"
        :courseDescription="description"
        :langTabs="langTabs"
        :isPublished="false"
        :isNew="true"
        :cost="cost"
        :programs="programs"
        :choosedProgram="choosedProgram"
        @change-active-lang="handleChangeLang"
        @change-languages="handleChangeLanguages"
        @change-name="handleChangeName"
        @change-description="handleChangeDescription"
        @save-course="saveCourse"
        @change-price="changePrice"
        @change-payment-type="changePaymentType"
        @change-program="changeProgram"
      />
    </leadership-layout>
    <!-- <loader v-if="pending" /> -->
  </div>
</template>

<script>
// import Loader from "@/components/Loader.vue";
import LeadershipLayout from "@/components/Leadership/LeadershipLayout.vue";
import LeadershipCourse from "@/components/Leadership/LeadershipCourse.vue";
import { mapGetters } from "vuex";

export default {
  name: "NewCourseContainer",
  components: {
    // Loader,
    LeadershipLayout,
    LeadershipCourse,
  },
  data() {
    return {
      title: this.$t("Новый квест"),
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        {
          title: "Свои бизнес-квесты",
          link: "/leadership/courses/",
        },
      ],
      activeTab: "description",
      tabs: [
        { title: "Описание", link: "/leadership/courses/new", exact: true },
        // { title: "Уроки", link: "/leadership/courses/lessons", exact: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      course: "leadershipNewCourse/course",
      pending: "leadershipNewCourse/pending",
      lang: "leadershipNewCourse/lang",
      errors: "leadershipNewCourse/errors",
      supportedLangsTitled: "supportedLangsTitled",
      name: "leadershipNewCourse/name",
      description: "leadershipNewCourse/description",
      langTabs: "leadershipNewCourse/langTabs",
      cost: "leadershipNewCourse/cost",
      programs: "leadershipPrograms/programs",
      choosedProgram: "leadershipNewCourse/choosedProgram",
    }),
  },
  mounted() {
    this.$store.dispatch("leadershipNewCourse/init");

    if (this.$route.query) {
      Promise.all([this.$store.dispatch("leadershipPrograms/fetch")]).then(
        () => {
          const { quest_course } = this.$route.query;
          this.$store.commit(
            "leadershipNewCourse/CHANGE_PROGRAM",
            Number(quest_course)
          );
        }
      );
    } else {
      this.$store.dispatch("leadershipPrograms/fetch");
    }
  },
  methods: {
    handleChangeLang(lang) {
      this.$store.commit("leadershipNewCourse/TOGGLE_LANG", lang);
    },
    handleChangeLanguages(lang) {
      this.$store.commit("leadershipNewCourse/TOGGLE_COURSE_LANG", lang);
    },
    handleChangeName(value) {
      this.$store.commit("leadershipNewCourse/CHANGE_NAME", value);
    },
    handleChangeDescription(value) {
      this.$store.commit("leadershipNewCourse/CHANGE_DESCRIPTION", value);
    },
    saveCourse() {
      this.$store.dispatch("leadershipNewCourse/createCourse");
    },
    changePrice(value) {
      this.$store.commit("leadershipNewCourse/CHANGE_COST", value);
    },
    changePaymentType(value) {
      this.$store.commit("leadershipNewCourse/TOGGLE_COST", value);
    },
    changeProgram(value) {
      this.$store.commit("leadershipNewCourse/CHANGE_PROGRAM", value);
    },
  },
};
</script>
