const getPhone = (value: string) => {
  const arr = [...value.split("")];
  let newArr: string[] = [];
  if (arr[0] === "+") {
    newArr = [
      arr[0],
      arr[1],
      "(",
      arr[2],
      arr[3],
      arr[4],
      ")",
      arr[5],
      arr[6],
      arr[7],
      "-",
      arr[8],
      arr[9],
      "-",
    ];
  } else {
    newArr = [
      "+",
      arr[0],
      "(",
      arr[1],
      arr[2],
      arr[3],
      ")",
      arr[4],
      arr[5],
      arr[6],
      "-",
      arr[7],
      arr[8],
      "-",
      arr[9],
    ];
  }
  for (let i = 10; i < arr.length; i++) {
    newArr.push(arr[i]);
  }
  return newArr.join("");
};

export default getPhone;
