<template>
  <div class="filterSearch__searchWrap">
    <svg class="filterSearch__searchIcon">
      <use xlink:href="@/assets/svg/sprite_filter_menu.svg#search" />
    </svg>
    <input
      class="filterSearch__search"
      placeholder="Имя или телефон"
      ref="input"
      v-model="searchInput"
    />
    <button
      @click="clear"
      class="filterSearch__searchClear"
      v-if="searchInput.length > 0"
    />
  </div>
</template>

<script>
export default {
  name: "TeamMenuFilterSearchInput",
  data() {
    return {
      searchInput: "",
    };
  },
  methods: {
    clear() {
      this.searchInput = "";
      this.$refs.input.focus();
    },
  },
};
</script>

<style lang="stylus" scoped>
.filterSearch__searchWrap {
  position: relative;
  display: flex;
  align-items: center;
}

.filterSearch__searchIcon {
  position: absolute;
  bottom: 4px;
  left: 0px;
  width: 17px;
  height: 17px;
}

.filterSearch__search {
  position: relative;
  margin-bottom: 0px;
  padding: 0;
  padding-left: 20px;
  width: 100%;
  height: 24px;
  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  color: #0F1642;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
}

.filterSearch__search:focus {
  border-bottom: 4px solid grey_text_color;
}

.filterSearch__search:focus .filterSearch__searchClear {
  display: block;
}

.filterSearch__searchClear {
  position: absolute;
  top: 0;
  bottom: 4px;
  right: 0px;
  /* display: none; */
  margin: auto;
  width: 20px;
  height: 20px;
  background: none;
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:none;%7D.b%7Bfill:%230f1642;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M0,0H20V20H0Z'/%3E%3Cpath class='b' d='M10.939,9.761l4.125-4.125,1.178,1.178-4.125,4.125,4.125,4.125-1.178,1.178-4.125-4.125L6.814,16.243,5.636,15.064l4.125-4.125L5.636,6.814,6.814,5.636Z' transform='translate(-0.939 -0.939)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
}
@media(min-width: tablet) {
  .filterSearch__search {
    padding-left: 23px;
    width: 320px;
    height: 32px;
    font-size: 14px;
    line-height: 18px;
  }

  .filterSearch__searchIcon {
    bottom: 9px;
  }
}
</style>
