import createLocales from "@/utils/createLocales";
import supportedLangs from "@/utils/supportedLangs";
import Universal from ".";
import Course from "./course";

class ListLesson extends Universal {
  constructor(data?) {
    if (data) {
      super({ ...data, quest_object: new Course(data.quest_object) });
    } else {
      super({
        id: 0,
        quest_object: new Course(),
        course: 0,
        locales: {
          ...createLocales(["name", "content"], supportedLangs(), ""),
        },
        position: 0,
      });
    }
  }
}

export default ListLesson;
