<template>
  <task-container />
</template>

<script>
import TaskContainer from "@/containers/Leadership/TaskContainer.vue";

export default {
  name: "LessonTask",
  components: {
    TaskContainer,
  },
};
</script>
