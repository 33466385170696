















































import TeamMenuFilterSearch from "@/components/Team/TeamMenuFilterSearch.vue";
import TeamFilterBody from "@/components/Team/TeamFilterBody.vue";

export default {
  name: "TeamMenu",
  data() {
    return {
      filterBody: false,
    };
  },
  props: {
    teamList: Boolean,
    teamTree: Boolean,
  },
  components: {
    TeamMenuFilterSearch,
    TeamFilterBody,
  },
};
