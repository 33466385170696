import Vue from "vue";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    children: {},
    filters: [],
    errors: {},
    pending: false,
  }),

  getters: {
    team(state) {
      return state.data;
    },
    children: state => id => {
      return state.children[id] || {};
    },
    pending(state) {
      return state.pending;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_CHILDREN(state, data) {
      state.children = { ...state.children, ...data };
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetchBy({ commit }, { parent, level }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get("/users/team/", {
          params: { parent, level },
        });

        if (response.status === 200) {
          commit("SET_CHILDREN", { [parent]: response.data });
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetch({ commit }, userLevel) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get("/users/team/", {
          params: { level: userLevel },
        });

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchNewTeam({ commit }, sessionidValue) {
      try {
        // commit("SET_PENDING", true);

        const data: any = { sessionid: sessionidValue };
        console.info(data);
        const response = await Vue.axios.get("/users/v2/team/", data);

        if (response.status === 200) {
          // commit("SET_DATA", response.data);
          // commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        // commit("SET_PENDING", false);
      }
    },
  },
};
