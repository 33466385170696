<template>
  <div class="callToLead__text">
    <Tr class="callToLead__textGridTitle">Телефон:</Tr>
    <button class="callToLead__btn" type="button" @click="openTelephonyModal">
      <span>{{ trigger.phone }}</span>
    </button>
    <button
      class="callToLead__btn callToLead__btn_icon"
      type="button"
      @click="openTelephonyModal"
    >
      <base-icon-svg width="16" height="16" viewbox="0 0 16 16">
        <leads-phone-icon />
      </base-icon-svg>
    </button>
  </div>
</template>

<script>
import LeadsPhoneIcon from "@/components/SvgIcons/LeadsPhoneIcon.vue";

export default {
  name: "UpdatedLeadsCallToLeadModalPhone",

  components: {
    LeadsPhoneIcon,
  },

  props: {
    trigger: {
      type: Object,
      required: true,
    },
  },

  methods: {
    openTelephonyModal() {
      this.$emit("openTelephonyModal");
    },
  },
};
</script>

<style lang="stylus" scoped>
.callToLead__text {
  display: grid;
  grid-template-columns: minmax(110px, max-content) 20px;
  grid-gap: 4px 10px;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 10px;
  margin-left: 23px;

  @media (min-width: tablet_xs) {
    grid-template-columns: minmax(60px, max-content) minmax(110px, max-content) 20px;
    grid-gap: 2px 5px;
  }
}

.callToLead__textGridTitle {
  display: block;
  opacity: 0.6;
  grid-column-start: 1;
  grid-column-end: 3;

  &:not(:first-of-type) {
    margin-top: 5px;
  }

  @media (min-width: tablet_xs) {
    display: inline-block;
    opacity: 1;
    grid-column-start: unset;
    grid-column-end: unset;

    &:not(:first-of-type) {
      margin-top: 0;
    }
  }
}

.callToLead__btn {
  padding: 0;
  background-color: unset;
  border: none;

  &_icon {
    width: 16px;
    height: 16px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}
</style>
