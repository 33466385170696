<template>
  <article class="mentor__wrap" v-if="mentor">
    <img alt="Your mentor" class="mentor__avatar" :src="avatar" />
    <div class="mentor__title">
      <span class="mentor__desription">
        <Tr>Наставник</Tr>
      </span>
      <span v-if="mentor.name" v-tooltip="mentor.name">{{ mentor.name }}</span>
      <Tr v-else>Без имени</Tr>
    </div>
    <div class="mentor__registration" v-if="!mentorIsRegistered">
      <Tr>Попросите вашего спонсора</Tr>
    </div>
    <div class="mentor__info">
      <a
        v-if="mentor.phone"
        class="mentor__link"
        :href="`tel:${mentor.phone}`"
        v-tooltip="mentor.phone"
      >
        <span class="mentorLinkBox">
          <base-icon-svg
            class="mentorLinkIcon"
            width="16"
            height="16"
            viewbox="0 0 16 16"
          >
            <mentor-icon-phone />
          </base-icon-svg>
          <span>{{ mentor.phone }}</span>
        </span>
      </a>
      <a
        v-if="mentor.email"
        class="mentor__link"
        :href="`mailto:${mentor.email}`"
        v-tooltip="mentor.email"
      >
        <span class="mentorLinkBox">
          <base-icon-svg
            class="mentorLinkIcon"
            width="16"
            height="16"
            viewbox="0 0 16 16"
          >
            <mentor-icon-mail />
          </base-icon-svg>
          <span>{{ mentor.email }}</span>
        </span>
      </a>
      <template v-if="mentor.links_objects">
        <a
          v-for="socialLink in mentor.links_objects"
          :key="socialLink.id"
          :href="socialLink.link"
          class="mentor__link"
          v-tooltip="socialLink.link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="mentorLinkBox">
            <!-- INSTAGRAM -->
            <template v-if="socialLink.get_host_name.includes('instagram')">
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-instagram />
              </base-icon-svg>
              <span>Instagram</span>
            </template>
            <!-- INSTAGRAM -->

            <!-- FACEBOOK -->
            <template v-else-if="socialLink.get_host_name.includes('facebook')">
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-facebook />
              </base-icon-svg>
              <span>Facebook</span>
            </template>
            <!-- FACEBOOK -->

            <!-- YOUTUBE -->
            <template v-else-if="socialLink.get_host_name.includes('youtube')">
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-youtube />
              </base-icon-svg>
              <span>YouTube</span>
            </template>
            <!-- YOUTUBE -->

            <!-- VK -->
            <template v-else-if="socialLink.get_host_name.includes('vk')">
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-vk />
              </base-icon-svg>
              <span>VK</span>
            </template>
            <!-- VK -->

            <!-- TWITTER -->
            <template v-else-if="socialLink.get_host_name.includes('twitter')">
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-twitter />
              </base-icon-svg>
              <span>Twitter</span>
            </template>
            <!-- TWITTER -->

            <!-- WHATSAPP -->
            <template
              v-else-if="
                socialLink.get_host_name.includes('whatsapp') ||
                socialLink.get_host_name.includes('wa.me')
              "
            >
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-whatsapp />
              </base-icon-svg>
              <span>Whatsapp</span>
            </template>
            <!-- WHATSAPP -->

            <!-- TELEGRAM -->
            <template
              v-else-if="
                socialLink.get_host_name.includes('t.me') ||
                socialLink.get_host_name.includes('telegram.me')
              "
            >
              <base-icon-svg
                class="mentorLinkIcon"
                width="16"
                height="16"
                viewbox="0 0 16 16"
              >
                <mentor-icon-telegram />
              </base-icon-svg>
              <span>Telegram</span>
            </template>
            <!-- TELEGRAM -->

            <!-- LINK -->
            <template v-else>
              <base-icon-svg
                width="20"
                height="20"
                viewbox="0 0 20 20"
                class="mentorLinkIcon"
              >
                <mentor-icon-link />
              </base-icon-svg>
              <span>{{ socialLink.get_host_name }}</span>
            </template>
            <!-- LINK -->
          </span>
        </a>
      </template>
    </div>
  </article>
</template>

<script>
import { mapGetters } from "vuex";
import MentorIconInstagram from "@/components/SvgIcons/mentor-contacts/MentorIconInstagram.vue";
import MentorIconFacebook from "@/components/SvgIcons/mentor-contacts/MentorIconFacebook.vue";
import MentorIconYoutube from "@/components/SvgIcons/mentor-contacts/MentorIconYoutube.vue";
import MentorIconVk from "@/components/SvgIcons/mentor-contacts/MentorIconVk.vue";
import MentorIconTwitter from "@/components/SvgIcons/mentor-contacts/MentorIconTwitter.vue";
import MentorIconWhatsapp from "@/components/SvgIcons/mentor-contacts/MentorIconWhatsapp.vue";
import MentorIconTelegram from "@/components/SvgIcons/mentor-contacts/MentorIconTelegram.vue";
import MentorIconLink from "@/components/SvgIcons/mentor-contacts/MentorIconLink.vue";
import MentorIconMail from "@/components/SvgIcons/mentor-contacts/MentorIconMail.vue";
import MentorIconPhone from "@/components/SvgIcons/mentor-contacts/MentorIconPhone.vue";

export default {
  name: "SidebarMenuMentor",
  components: {
    MentorIconInstagram,
    MentorIconFacebook,
    MentorIconYoutube,
    MentorIconVk,
    MentorIconTwitter,
    MentorIconLink,
    MentorIconWhatsapp,
    MentorIconTelegram,
    MentorIconMail,
    MentorIconPhone,
  },

  computed: {
    ...mapGetters({
      mentor: "user/mentor",
      avatar: "user/mentorAvatar",
      mentorIsRegistered: "user/mentorIsRegistered",
    }),
  },
};
</script>

<style lang="stylus" scoped>
.mentor__wrap {
  display: grid;
  grid-template-columns: 32px auto;
  grid-column-gap: 8px;
  margin-right: 8px;
  margin-left: 8px;
  padding: 10px 3px 14px 7px;
  border: 1px solid #e6e7eb;

  @media (min-width: tablet_large) {
    grid-template-columns: 40px auto;
    margin-right: 24px;
    margin-left: 4px;
    padding-top: 6px;
  }
}

.mentor__avatar {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  @media (min-width: tablet_large) {
    width: 40px;
    height: 40px;
  }
}

.mentor__desription {
  display: block;
  color: #0f1642;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  margin-bottom: 1px;
}

.mentor__title {
  color: #0f1642;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentor__info {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background-image: linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 100%);
  }
}

.mentor__info, .mentor__registration {
  grid-column: 2 / 3;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: tablet_large) {
    grid-column: 1 / 3;
  }
}

.mentor__phone {
  display: block;
  margin-top: 8px;
  color: #0f1642;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-decoration: none;

  @media (min-width: tablet_large) {
    margin-top: 14px;
  }
}

.mentor__phone:hover {
  text-decoration: underline;
}

.mentorLinkBox {
  display: grid;
  grid-template-columns: 16px auto;
  grid-column-gap: 4px;
  align-items: center;
  margin-left: -16px;
  width: fit-content;
}

.mentor__link {
  position: relative;
  display: block;
  margin-top: 8px;
  padding-left: 16px;
  width: fit-content;
  color: #3489f8;
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mentor__link:hover {
  text-decoration: none;
}

.mentor__link:focus {
  outline: none;
}

.mentor__link::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 3px;
  left: -15px;
  margin: auto;
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;

  @media (min-width: tablet_large) {
    bottom: 2px;
    left: -18px;
    width: 10px;
    height: 10px;
  }
}

.mentor__link_telegram::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M3.6 5.527l-1.124-.351c-.243-.074-.244-.242.055-.362l4.38-1.692c.254-.103.398.028.316.356L6.48 6.997c-.052.25-.203.31-.412.195l-1.148-.85-.535.517c-.055.053-.1.098-.184.11-.084.011-.154-.014-.205-.154l-.391-1.292-.006.004zM5 9.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-.9a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.mentor__link_whatsapp::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cg%3E%3Cg%3E%3Cpath fill='%230f1642' d='M3.376 2.889a1.524 1.524 0 0 1 .254.005c.072.008.15.052.177.112.134.304.265.61.39.918.029.068.012.156-.041.242a1.97 1.97 0 0 1-.119.167c-.05.065-.16.185-.16.185s-.044.053-.027.12a.398.398 0 0 0 .046.092l.026.042c.115.192.27.387.46.57.053.053.106.107.163.157.21.185.449.337.706.45h.002l.114.05a.647.647 0 0 0 .086.03.158.158 0 0 0 .165-.059c.326-.394.355-.42.358-.42a.217.217 0 0 1 .17-.056.233.233 0 0 1 .08.018c.239.109.63.28.63.28l.262.117c.044.021.084.071.085.12a1.253 1.253 0 0 1-.09.498.52.52 0 0 1-.095.135 1.07 1.07 0 0 1-.205.17 2.262 2.262 0 0 1-.172.099.895.895 0 0 1-.375.103c-.083.005-.166.011-.25.007L5.76 7a4.252 4.252 0 0 1-1.728-.92c-.101-.09-.195-.186-.292-.282a4.28 4.28 0 0 1-.886-1.234 1.562 1.562 0 0 1-.149-.636c-.002-.273.087-.54.254-.756.033-.042.064-.086.117-.137a.573.573 0 0 1 .133-.103.432.432 0 0 1 .166-.045zM.502 9.5l.608-2.236A4.5 4.5 0 1 1 2.736 8.89zm2.362-1.578l.326.19A3.6 3.6 0 1 0 1.888 6.81l.19.327-.294 1.08z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.mentor__registration {
  margin-top: 10px;
  fontMainColor(12px, 700, 14px);
}

.mentorLinkIcon {
  display: block;
  width: 16px;
  height: 16px;
}
</style>
