<template>
  <div class="funnelBodyHead">
    <slot name="checkbox" />
    <div class="funnelBodyHead__cell" />
    <div class="funnelBodyHead__cell">
      <Tr class="funnelBodyHead__cellText">Имя</Tr>
    </div>
    <slot name="manager" />
    <slot name="status" />
    <div class="funnelBodyHead__cell funnelBodyHead__cellSource">
      <Tr
        :class="[
          'funnelBodyHead__cellText',
          { hide: sourceHide },
          { show: !sourceHide },
        ]"
        >Дата</Tr
      >
      <button
        :class="[
          'funnelBodyHead__cellButton',
          { hide: sourceHide },
          { show: !sourceHide },
        ]"
        type="button"
        @click="hideSource"
      >
        <Tr>Свернуть</Tr>
      </button>
    </div>
    <slot name="managerIcon" />
    <div class="funnelBodyHead__cell funnelBodyHead__cellActions">
      <button
        :class="[
          'funnelBodyHead__cellButton',
          { funnelBodyHead__cellButton_active: pipelineIsFull },
        ]"
        type="button"
        @click="getPipelineFull"
      >
        <Tr>Подробно</Tr>
      </button>
      <button
        :class="[
          'funnelBodyHead__cellButton',
          { funnelBodyHead__cellButton_active: !pipelineIsFull },
        ]"
        type="button"
        @click="getPipelineShort"
      >
        <Tr>Кратко</Tr>
      </button>
    </div>
    <div class="funnelBodyHead__cell funnelBodyHead__cellTags">
      <Tr class="funnelBodyHead__cellText">Теги</Tr>
    </div>
    <slot name="calls" />
    <slot name="createLinks" />
  </div>
</template>

<script>
export default {
  name: "UpdatedFunnelBodyHead",
  props: {
    sourceHide: Boolean,
    pipelineIsFull: Boolean,
  },
  methods: {
    hideSource() {
      this.$emit('hideSource');
    },
    getPipelineFull() {
      this.$emit("getPipelineFull");
    },
    getPipelineShort() {
      this.$emit("getPipelineShort");
    },
  },
};
</script>

<style lang="stylus" scoped>
.funnelBodyHead {
  display: none;
  margin-bottom: 13px;
}

.funnelBodyHead__cell {
  height: 18px;
}

.funnelBodyHead__cellSource {
  display: flex;
  justify-content: space-between;
}

.funnelBodyHead__cellActions {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 12px;
  align-items: baseline;
  width: fit-content;
}

.funnelBodyHead__cellText {
  font(main_text_color, 14px, 700, 18px);
}

.funnelBodyHead__cellButton {
  font_regular();
  letter-spacing: 0.4px;
  padding: 0;
  background: none;
  border: none;
  border-bottom: 2px dotted secondary_text_color;
  color: secondary_text_color;
  cursor: pointer;

  &_active {
    border-bottom: 2px solid orange;
    color: main_text_color;
  }

  &:hover {
    opacity: 0.6;
  }
}

.hide {
  display: none;
}

.show {
  display: block;
}

@media (min-width: desktop) {
  .funnelBodyHead {
    display: grid;
  }
}
</style>
