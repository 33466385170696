<template>
  <div class="menu">
    <ul class="menu__list">
      <SidebarMenuLink
        :exact="index === 0 ? true : false"
        :key="index"
        :menulink="par"
        v-for="(par, index) in pars"
      />
    </ul>
    <!-- <SidebarMenuMentor /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SidebarMenuLink from "@/components/Sidebar/SidebarMenuLink.vue";

export default {
  name: "SidebarMenu",
  components: {
    SidebarMenuLink,
    // SidebarMenuMentor,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      showBnLink: "user/showBnLink",
      showDashboard: "user/showDashboard",
      showChat: "user/showChat",
      hasFunnel: "user/hasFunnel",
      showTeam: "user/showTeam",
      showLeadership: "user/showLeadership",
      contestPart: "user/contestPart",
      showMentoring: "user/showMentoring",
      showLeads: "user/showLeads",
      showAssignment: "user/showAssignment",
      showUpdatedLeads: "user/showUpdatedLeads",
      hasSubscription: "user/hasSubscription",
      bnName: "user/bnName",
    }),
    pars() {
      return [
        ...(this.showDashboard
          ? [
            {
              title: "Рабочий стол",
              href: "/dashboard",
              name: "dashboard",
            },
          ]
          : []),
        ...(this.showBnLink
          ? [
            {
              title: this.bnName,
              href: "/quests",
              name: "courses",
              noTr: true,
            },
          ]
          : []),
        ...(this.hasSubscription
          ? [
            {
              title: "Задачи",
              href: "/tasks",
              name: "tasks",
              inner: true,
            },
          ]
          : [{
            title: "Задачи",
            href: "/front/tasks",
            name: "tasks",
          }]),
        ...(this.showUpdatedLeads
          ? [
            {
              title: "Лиды",
              href: "/leads",
              name: "updatedLeads",
              inner: true,
            },
          ]
          : []),
        ...(this.showAssignment
          ? [
            {
              title: "Распределение",
              href: "/assignment/",
              name: "updatedAssignment",
              inner: true,
            },
          ]
          : []),
        {
          title: "Контакты",
          href: "/contacts",
          name: "contacts",
          inner: true,
        },
        ...(this.showMentoring
          ? [
            {
              title: "Наставничество",
              href: "/mentoring",
              name: "mentoring",
            },
          ]
          : []),
        ...(this.showTeam
          ? [
            {
              title: "Команда",
              href: "/team-tree",
              name: "team",
              inner: true,
            },
            ...(this.showChat
              ? [
                {
                  title: "Сообщения",
                  href: "/chat",
                  name: "messages",
                },
              ]
              : []),
          ]
          : []),
        ...(this.contestPart
          ? [
            {
              title: "Конкурс",
              href: "/contest",
              name: "contest",
            },
          ]
          : []),
        ...(this.showLeadership
          ? [
            {
              title: "Лидерство",
              href: "/leadership",
              name: "leadership",
              inner: true,
            },
          ]
          : []),
        // {
        //   title: "Конструктор воронок",
        //   href: "/funnels-constructor/funnels",
        //   name: "funnel_constructor",
        //   inner: true,
        // },
        // {
        //   title: "Автовебинары",
        //   href: "/leadership/autowebinars/",
        //   name: "funnel_constructor",
        //   inner: true,
        // },
        // ...(this.hasFunnel
        //   ? [
        //       {
        //         title: "Воронка",
        //         href: "/funnel",
        //         name: "funnel",
        //         inner: true,
        //       },
        //     ]
        //   : []),
        ...(this.hasFunnel
          ? [
            {
              title: "Воронка",
              href: "/funnel",
              name: "funnel",
              inner: true,
            },
          ]
          : []),
      ];
    },
  },
};
</script>

<style lang="stylus" scoped>
.menu {
  margin-bottom: 13px;
  margin-left: 8px;

  @media (min-width: tablet_large) {
    margin-bottom: 33px;
    margin-left: 0;
  }
}

.menu__list {
  display: grid;
  grid-row-gap: 3px;

  @media (min-width: tablet_large) {
    grid-row-gap: 8px;
  }
}
</style>
