<script>
import Vue from "vue";

export default Vue.extend({
  name: "SidebarSubmenuLangsButton",
  props: { lang: String },
});
</script>

<template>
  <base-locale-switcher class="languages__button" :lang="lang">
    {{ lang }}
  </base-locale-switcher>
</template>

<style lang="stylus" scoped>
.languages__button {
  padding: 10px 10px 7px 8px;
  background: unset;
  border: unset;
  border-bottom: 2px solid #ff9f43;
  color: #0f1642;
  font-size: 10px;
  font-weight: 700;
  line-height: 13px;
  text-transform: uppercase;

  &.active {
    background-color: #ff9f43;
  }
}

.languages__button:hover {
  background-color: #ff9f43;
}
</style>
