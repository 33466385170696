<template>
  <modal
    name="tasks-item-modal"
    @before-open="beforeOpen"
    @before-close="beforeClose"
    :width="640"
    height="auto"
    :adaptive="true"
    :shiftY="0.3"
  >
    <div class="tasksItemModal">
      <!-- HEADER -->
      <div class="tasksItemModal__header">
        <div class="tasksItemModal__headerName">
          {{ task.dynamic_title }}
        </div>
        <button
          class="tasksItemModal__headerButton"
          type="button"
          @click="hideCreateTask"
        >
          <base-icon-svg width="14" height="14" viewbox="0 0 14 14">
            <close-button />
          </base-icon-svg>
        </button>
      </div>
      <!-- HEADER -->

      <!-- BODY -->
      <div class="tasksItemModal__body">
        <div class="avatarName">
          <div class="avatar" :style="avatarColor">
            {{ name | getInitialsFilter }}
          </div>
          <p class="tasksItemModal__name">
            <template v-if="task.lead_service_lead_id">{{
              task.lead_service_lead_id
            }}</template>
            {{ name }}
          </p>
        </div>
        <tab-list
          v-if="meetType || reminderType"
          :tabs="tabs"
          :activeTab="activeTab"
          :mobileBtns="true"
          @handle-main-tabs="handleTabs"
          class="tasksItemModalTabList tasksItemModalTabList_leadsPage"
        />

        <!-- INFO -->
        <div class="tasksItemModalBody" v-show="activeTab === 'info'">
          <div class="tasksItemModalBodyInner">
            <div
              class="tasksItemModal__description"
              v-if="contact.phones_objects && contact.phones_objects.length > 0"
            >
              <div class="tasksItemModalContactTitle"><Tr>Телефон</Tr></div>
              <a
                class="tasksItemModalLink"
                :href="`tel:${phone.value}`"
                v-for="phone in Object.values(contact.phones_objects)"
                :key="phone.id"
              >
                {{ phone.value }}
              </a>
            </div>
            <div
              class="tasksItemModal__description"
              v-if="contact.emails_objects && contact.emails_objects.length > 0"
            >
              <div class="tasksItemModalContactTitle">
                <Tr>E-mail</Tr>
              </div>
              <a
                class="tasksItemModalLink"
                :href="`mailto:${email.value}`"
                v-for="email in Object.values(contact.emails_objects)"
                :key="email.id"
              >
                {{ email.value }}
              </a>
            </div>
            <div
              class="tasksItemModal__description"
              v-if="contact.links_objects && contact.links_objects.length > 0"
            >
              <div class="tasksItemModalContactTitle">
                <Tr>Соц. сети</Tr>
              </div>
              <a
                class="tasksItemModalLink"
                :href="link.link"
                v-for="link in Object.values(contact.links_objects)"
                :key="link.id"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ link.link }}
              </a>
            </div>
          </div>
          <button
            type="button"
            class="tasksItemModal__submit tasksItemModal__contactLink"
            @click.prevent="openContact"
          >
            <Tr>К карточке</Tr>
          </button>
        </div>

        <!-- RESULT -->
        <div v-show="activeTab === 'result'">
          <div class="tasksItemModal__descriptionWrap">
            <p class="tasksItemModal__description" v-if="task.description">
              {{ task.description }}
            </p>
            <template v-if="meetType || reminderType">
              <!-- TEXTAREA -->
              <TextareaMaxlength
                class="tasksItemModal__textarea"
                :label="$t('Результат')"
                labelId="result"
                :height="100"
                :disabledProp="Boolean(task.done_at)"
                :textProp="task.result"
                @handleTextarea="handleTextarea"
                :withCounter="true"
                required
              />
              <!-- TEXTAREA -->

              <!-- SELECT -->
              <div class="tasksSelect" v-if="!Boolean(task.done_at)">
                <label class="tasksSelect__label" for="tasksContactProgress">
                  <Tr>Прогресс</Tr>
                </label>
                <select
                  class="tasksSelect__selectItem"
                  id="tasksContactProgress"
                  @change="changeProgress"
                  :disabled="Boolean(task.done_at)"
                >
                  <option
                    v-for="progress in contactProgresses"
                    :value="progress.param"
                    :key="progress.param"
                    :selected="contact.progress === progress.param"
                  >
                    {{ progress.verbose }}
                  </option>
                </select>
              </div>

              <div class="tasksSelect" v-if="task.contact_progress">
                <label class="tasksSelect__label" for="tasksContactProgress">
                  <Tr>Прогресс</Tr>
                </label>
                <select
                  class="tasksSelect__selectItem"
                  id="tasksContactProgress"
                  @change="changeProgress"
                  :disabled="true"
                >
                  <option
                    v-for="progress in contactProgresses"
                    :value="progress.param"
                    :key="progress.param"
                    :selected="task.contact_progress === progress.param"
                  >
                    {{ progress.verbose }}
                  </option>
                </select>
              </div>
              <!-- SELECT -->
            </template>
          </div>
        </div>
      </div>
      <!-- BODY -->

      <!-- FOOTER -->
      <div
        class="tasksItemModal__footer"
        v-if="taskIsActual && activeTab === 'result'"
      >
        <button
          class="tasksItemModal__submit"
          @click="handleTaskDone"
          :disabled="taskDoneBtnDisabled || taskDoneBtnDisabledResult"
        >
          <Tr>Задача выполнена</Tr>
        </button>
      </div>
      <!-- FOOTER -->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from "vuex";
import { runNotice } from "@/utils/notifications";

import CloseButton from "@/components/SvgIcons/CloseButton.vue";
import TabList from "@/components/TabList.vue";
import TextareaMaxlength from "@/components/TextareaMaxlength.vue";
// import BaseLink from '../common/BaseLink.vue';

export default {
  name: "TasksItemModal",
  components: {
    CloseButton,
    TabList,
    TextareaMaxlength,
    // BaseLink,
  },
  data() {
    return {
      task: {},
      activeTaskTab: "",
      name: "",
      contact: {},
      result: "",
      avatarColor: "",
      taskDoneBtnDisabled: false,
      progressIsChanged: false,
      activeTab: "result",
      tabs: [
        {
          title: "Результат",
          name: "result",
        },
        {
          title: "Инфо",
          name: "info",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentLang: "currentLang",
      errors: "leadsTaskCreate/errors",
      contactProgresses: "leadTasks/contactProgresses",
      baseUrl: "user/mainSiteLink",
    }),
    taskIsActual() {
      return this.task.done_at === null;
    },
    meetType() {
      return this.task.type === 'Встреча с контактом'
    },
    reminderType() {
      return this.task.type === 'Напоминание про контакт'
    },
    taskDoneBtnDisabledResult() {
      if (this.meetType || this.reminderType) {
        return this.result.length === 0;
      }
      return false;
    },
  },
  methods: {
    hideCreateTask() {
      this.$modal.hide("tasks-item-modal");
    },

    beforeOpen(event) {
      this.task = event.params.task;
      this.name = event.params.name;
      this.contact = event.params.contact;
      this.avatarColor = event.params.avatarColor;
      this.activeTaskTab = event.params.activeTaskTab;
    },

    handleTabs(tabName) {
      this.activeTab = tabName;
    },

    handleTextarea(value) {
      this.result = value;
    },

    changeProgress(e) {
      this.progressIsChanged = true;
      this.$store.commit("leadTask/SET_CONTACT_PROGRESS", Number(e.target.value));
    },

    openContact() {
      const routeUrl = this.$router.resolve({ path: `/${this.currentLang}/contacts/${this.task.contact}/` });
      window.location = routeUrl.href;
    },

    handleTaskDone() {
      this.taskDoneBtnDisabled = true;

      this.$store.dispatch("leadTask/getTaskDone", { id: this.task.id, result: this.result })
        .then(response => {
          if (response) {
            if (response && response.status === 200) {

              // TASKS
              if (this.$route.name.includes("Tasks")) {
                this.$store.dispatch("leadTasks/fetchAll", { page: 1 });

                this.$store.dispatch("leadTasks/fetch", { page: 1, filter: this.activeTaskTab });

                this.$store.dispatch("user/fetch");
              }

              // REMINDERS
              else if (this.$route.name.includes("ContactsProfileReminders")) {
                this.$store.dispatch("contactsProfileReminders/fetch", {
                  page: 1,
                  contact: this.$route.params.profileId,
                  filter: this.activeTaskTab,
                });
              }

              // MEETINGS
              else if (this.$route.name.includes("ContactsProfileMeetings")) {
                this.$store.dispatch("contactsProfileMeetings/fetch", {
                  page: 1,
                  contact: this.$route.params.profileId,
                  filter: this.activeTaskTab,
                });
              }

              this.$store.dispatch("contactsProfileEvents/postEvent", { contact: this.contact.id, topic: this.result });

              runNotice("success", this.$t("Задача выполнена"));
              this.$modal.hide("tasks-item-modal");
            } else {
              this.taskDoneBtnDisabled = false;
              runNotice("error", this.$t("Не удается отметить задачу выполненной"));
            }
          }
        })
        .catch(e => {
          console.log(e);
        });

      if (this.progressIsChanged) {
        this.$store.dispatch("leadTask/changeContactProgress", { id: this.contact.id })
          .then(response => {
            if (response && response.status === 200 && this.$route.params.profileId) {
              this.$store.dispatch("contactsProfile/fetch", this.$route.params.profileId);
            }
          })
          .catch(e => { console.log(e) });
      }
    },

    beforeClose() {
      this.result = "";
      this.progressIsChanged = false;
      this.taskDoneBtnDisabled = false;
      this.activeTab = "result";
    },
  },
};
</script>

<style lang="stylus" scoped>
.tasksItemModal {
  display: flex;
  flex-direction: column;
  padding: 16px 10px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 10px #0F164214;
  font-size: 10px;
  line-height: 14px;
  font_regular();

  @media (min-width: tablet) {
    padding: 16px 12px 21px 16px;
    width: 640px;
    overflow: hidden;
    border-radius: 8px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -10px;
    padding-bottom: 7px;
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
    border-bottom: 2px solid grey_text_color;
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;

    @media (min-width: tablet) {
      padding-bottom: 8px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__headerName {
    font(main_text_color, 16px, 700, 20px);
    width: 92%;
    word-break: break-word;

    @media (min-width: tablet) {
      margin-bottom: 4px;
      font_subtitle();
    }
  }

  &__headerButton {
    display: block;
    padding: 0;
    width: 20px;
    height: 20px;
    background-color: unset;
    border: none;

    @media (min-width: tablet) {
      position: relative;
      top: 3px;
      right: 6px;
    }
  }

  &__body {
    flex-grow: 1;
    padding-top: 14px;

    @media (min-width: tablet) {
      padding-top: 0;
      padding-right: 5px;
      align-items: unset;
      border-bottom: unset;
    }
  }

  &__descriptionWrap {
    margin-bottom: 20px;
  }

  &__name {
    font-weight: 700;
  }

  &__description {
    max-height: 140px;
    white-space: pre-line;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: grey_text_color #fff;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: grey_text_color;
      border: 2px solid #fff;
      border-radius: 6px;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__textarea {
    margin-bottom: 20px;
  }

  &__footer {
    margin: 0 -16px;
    padding: 20px 16px 0;
    border-top: 2px solid grey_text_color;
  }

  &__submit {
    padding: 11px 12px;
    background-image: linear-gradient(180deg, #FDBA7A 0%, #FF9F43 100%);
    font(#fff, 14px, 400, 18px);
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      opacity: 0.6;
    }

    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
  }

  &__contactLink {
    display: inline-block;
    background-image: none;
    background-color: grey_text_color;
    color: main_text_color;
  }
}

.avatarName {
  display: grid;
  grid-template-columns: 20px auto;
  grid-column-gap: 12px;
  align-items: center;
  margin-bottom: 10px;

  @media (min-width: desktop) {
    grid-template-columns: 24px auto;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font(main_text_color, 10px, 700, 12px);
  border-radius: 50%;
  overflow: hidden;

  @media (min-width: desktop) {
    width: 24px;
    height: 24px;
  }
}

.tasksSelect {
  &__label {
    display: block;
    margin-bottom: 4px;
    font(main_text_color, 12px, 400, 16px);
  }

  &__selectItem {
    display: block;
    padding: 7px 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid grey_text_color;
    border-radius: 2px;
    font_regular();

    &:disabled {
      background-color: #FAFAFA;
    }
  }
}

.tasksItemModalTabList {
  margin-bottom: 11px;
  min-height: 47px;

  &_leadsPage {
    width: calc(100vw - 36px);
  }

  @media (min-width: tablet) {
    min-height: unset;
    margin-right: -16px;
    margin-left: -16px;
    width: auto;
  }
}

.tasksItemModalContactTitle {
  margin-bottom: 4px;
  font-weight: 700;
}

.tasksItemModalLink {
  display: block;
  margin-bottom: 4px;
  width: fit-content;
  color: secondary_text_color;

  @media (min-width: tablet) {
    &:hover {
      opacity: 0.6;
    }
  }
}

.tasksItemModalBodyInner {
  margin-bottom: 10px;
  max-height: 350px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #ebe6e7 #fff;

  &::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e6e7eb;
    border: 2px solid #fff;
    border-radius: 6px;
  }
}
</style>
