<template>
  <div class="loaderBox">
    <div class="loaderIcon" v-show="commonPending">
      <loader />
    </div>
    <form action class="contactStatus formStatus" method="post">
      <div class="formStatus__fieldsetWrap">
        <contacts-profile-status-fieldsets
          v-for="fieldset in fieldsets"
          :key="fieldset.legend"
          :fieldset="fieldset"
          :contact="contact"
          @change-status="changeStatus"
        />
      </div>
      <FormSubmit @click="submitChangedStatus" />
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { runNotice } from '@/utils/notifications';

import FormSubmit from "@/components/FormSubmit.vue";
import ContactsProfileStatusFieldsets from "@/components/Contacts/ContactsProfileStatusFieldsets.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "ContactsProfileStatus",

  components: {
    ContactsProfileStatusFieldsets,
    FormSubmit,
    Loader,
  },

  computed: {
    ...mapGetters({
      pending: "contactsProfile/pending",
      contact: "contactsProfile/contact",
      errors: "contactsProfile/errors",
      progresses: "leadTasks/contactProgresses",
      contactHeatArray: "contacts/contactHeatArray",
      contactTopArray: "contacts/contactTopArray",
      statusPending: "contacts/pending",
    }),
    fieldsets() {
      return [
        {
          legend: "Прогресс",
          name: "progress",
          radiobtns: [...this.progresses]
        },
        {
          legend: "Подогретость",
          name: "heat",
          radiobtns: [...this.contactHeatArray]
        },
        {
          legend: "Топ",
          name: "top",
          radiobtns: [...this.contactTopArray]
        },
      ];
    },
    commonPending() {
      return this.pending || this.statusPending;
    },
  },

  methods: {
    changeStatus(payload) {
      this.$store.commit("contactsProfile/CHANGE_STATUS", payload);
    },

    submitChangedStatus() {
      Promise.all([this.$store.dispatch("contactsProfile/changeStatus")])
        .then(response => {
          if (response) {
            if (response[0] && String(response[0].status).startsWith("20")) {
              runNotice("success", this.$t("Информация по контакту успешно обновлена"))
              this.$store.dispatch("contactsProfile/fetch", this.$route.params.profileId);
            } else {
              runNotice("error", this.$t("Что-то пошло не так"));
            }
          }
        }).catch(
          e => { console.log(e) }
        );
    },
  },

  mounted() {
    this.$store.dispatch("contacts/fetchContactHeat");
    this.$store.dispatch("contacts/fetchContactTop");
  }
};
</script>

<style lang="stylus" scoped>
.loaderBox {
  position: relative;
}

.loaderIcon {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
}

.formStatus__fieldsetWrap {
  display: grid;
  grid-row-gap: 24px;
}

.contactStatus__progress {
  margin-bottom: 24px;
}
</style>
