<template>
  <program-courses-container />
</template>

<script>
import ProgramCoursesContainer from "@/containers/Leadership/ProgramCoursesContainer.vue";

export default {
  name: "ProgramCourses",
  components: {
    ProgramCoursesContainer,
  },
};
</script>
