<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <h1 class="title titleAutowebinar">
      <Tr>Продаем ещё более лучше</Tr>
    </h1>

    <div class="autowebinar__wrap">
      <div class="autowebinar__header">
        <ul class="tabList">
          <li class="tabItem">
            <button class="tabButton_active tabButton">Описание</button>
          </li>
          <li class="tabItem">
            <button class="tabButton">Вебинар</button>
          </li>
        </ul>
      </div>

      <div class="autowebinar__body">
        <!-- БЛОК ЗАГРУЗКИ КОНТЕНТА -->
        <div class="autowebinar__uploadWrap">
          <div class="autowebinar__uploadBox">
            <div class="autowebinar__uploadButtonWrap">
              <button class="autowebinar__uploadButton">
                <base-icon-svg
                  class="autowebinar__uploadSvg"
                  width="18"
                  height="17"
                  viewbox="0 0 18 17"
                >
                  <upload-slide />
                </base-icon-svg>
                <Tr>Добавить слайд</Tr>
              </button>
              <div class="autowebinar__descriptionText">
                <Tr>
                  Каждый слайд должен быть отдельной картинкой формата JPG или
                  PNG. Максимальное количество слайдов — 100, сейчас —
                </Tr>
                <span class="autowebinar__uploadText_bold">8</span>
              </div>
            </div>
            <div
              class="autowebinar__uploadContent autowebinar__uploadContentSlide"
            >
              <img
                src="@/assets/images/autowebinar-slide.jpg"
                alt="слайд автовебинара"
              />
            </div>
          </div>
          <div class="autowebinar__uploadBox">
            <div class="autowebinar__uploadButtonWrap">
              <button class="autowebinar__uploadButton">
                <base-icon-svg
                  class="autowebinar__uploadSvg"
                  width="19"
                  height="16"
                  viewbox="0 0 19 16"
                >
                  <upload-video />
                </base-icon-svg>
                <Tr>Загрузить другое видео</Tr>
              </button>
              <div class="autowebinar__descriptionText">
                <Tr>
                  Видео с комментариями спикера. Продолжительность должна быть
                  от 5 минут для 1 часа.
                </Tr>
              </div>
            </div>
            <div
              class="autowebinar__uploadContent autowebinar__uploadContentVideo"
            >
              <iframe
                src="https://player.vimeo.com/video/456530701"
                frameborder="0"
              ></iframe>
            </div>
          </div>
        </div>
        <!-- КОНЕЦ БЛОКА ЗАГРУЗКИ КОНТЕНТА -->

        <!-- БЛОК УПРАВЛЕНИЯ СОЗДАНИЕМ -->
        <div class="autowebinar__controlsWrap">
          <!-- КНОПКА PLAY -->
          <button class="autowebinar__controlsButtonPlay" type="button">
            <span class="autowebinar__controlsButtonPlayIcon"></span>
          </button>
          <!-- КОНЕЦ КНОПКИ PLAY -->

          <!-- ОБЕРТКА ПРОГРЕССБАРА -->
          <div class="autowebinar__progressbarWrap">
            <div class="autowebinar__progressbar"></div>

            <!-- ПОЛЗУНОК -->
            <div class="autowebinar__progressbarButtonMoveWrap">
              <div class="autowebinar__progressbarButtonMove">
                <base-icon-svg
                  class="autowebinar__progressbarButtonMoveSvg"
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                >
                  <button-move />
                </base-icon-svg>
              </div>
              <span class="autowebinar__progressbarButtonMoveTime">02:04</span>
            </div>
            <!-- КОНЕЦ ПОЛЗУНКА -->

            <span
              class="autowebinar__progressbarTime autowebinar__progressbarTime_beginning"
              >00:00</span
            >
            <span
              class="autowebinar__progressbarTime autowebinar__progressbarTime_end"
              >13:47</span
            >
          </div>
          <!-- КОНЕЦ ОБЕРТКИ ПРОГРЕССБАРА -->

          <!-- БЛОК СЛАЙДОВ -->
          <div class="autowebinar__slidesContainer">
            <h2 class="autowebinar__subtitle">
              <Tr>Слайды</Tr>
            </h2>
            <div class="autowebinar__slidesWrap">
              <!-- НАЧАЛЬНЫЙ СЛАЙД -->
              <div class="autowebinar__slide autowebinar__slide_end">
                <span class="autowebinar__slideText autowebinar__slideText_end"
                  >Приветствие</span
                >
              </div>
              <!-- КОНЕЦ НАЧАЛЬНОГО СЛАЙДА -->

              <!-- ОБЫЧНЫЙ СЛАЙД -->
              <div class="autowebinar__slide">
                <span class="autowebinar__slideText">slide_01.jpg</span>
                <span class="autowebinar__slideTitle">Автоматизация</span>
              </div>
              <!-- КОНЕЦ ОБЫЧНОГО СЛАЙДА -->

              <!-- ВЫБРАННЫЙ СЛАЙД -->
              <div class="autowebinar__slide autowebinar__slide_pointed">
                <!-- время начало -->
                <span
                  class="autowebinar__slide_pointedTime autowebinar__slide_pointedTime_start"
                  >05:41</span
                >
                <!-- расягиватель начала -->
                <div
                  class="autowebinar__slide_pointedStretcher autowebinar__slide_pointedStretcher_start"
                ></div>
                <!-- название -->
                <span
                  class="autowebinar__slideText autowebinar__slideText_pointed"
                  >slide_02.jpg</span
                >
                <span class="autowebinar__slideTitle">Автоматизация</span>
                <!-- иконка кнопки -->
                <div class="autowebinar__slide_pointedPointer">
                  <base-icon-svg
                    fill="white"
                    width="18"
                    height="14"
                    viewBox="0 0 18 14"
                  >
                    <button-move />
                  </base-icon-svg>
                </div>
                <!-- расягиватель конца -->
                <div
                  class="autowebinar__slide_pointedStretcher autowebinar__slide_pointedStretcher_end"
                ></div>
                <!-- время конец -->
                <span
                  class="autowebinar__slide_pointedTime autowebinar__slide_pointedTime_end"
                  >06:38</span
                >
                <!-- слайд -->
                <div class="autowebinar__slide_pointedPopup">
                  <button
                    class="autowebinar__slide_pointedPopupButton"
                    type="button"
                  >
                    <base-icon-svg>
                      <editing-delete />
                    </base-icon-svg>
                  </button>
                  <img
                    src="@/assets/images/autowebinar-slide.jpg"
                    alt="слайд"
                    class="autowebinar__slide_pointedPopupImg"
                  />
                  <div class="autowebinar__slide_pointedPopupName">
                    <span class="autowebinar__slide_pointedPopupNameText"
                      >Слайд про обучение партнеровас</span
                    >
                    <div class="autowebinar__slide_pointedPopupNameButtonWrap">
                      <span
                        class="autowebinar__slide_pointedPopupNameButtonSpan"
                      ></span>
                      <button
                        class="autowebinar__slide_pointedPopupNameButton"
                        type="button"
                      >
                        <base-link
                          to="/leadership/autowebinars/1/webinar/create-title"
                        >
                          <base-icon-svg>
                            <editing-edit width="20" height="20" />
                          </base-icon-svg>
                        </base-link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- КОНЕЦ ВЫБРАННОГО СЛАЙДА -->

              <!-- ОБЫЧНЫЙ СЛАЙД -->
              <div class="autowebinar__slide">
                <span class="autowebinar__slideText">slide_03.jpg</span>
                <span class="autowebinar__slideTitle">Автоматизация</span>
              </div>
              <!-- КОНЕЦ ОБЫЧНОГО СЛАЙДА -->

              <!-- ЗАВЕРШАЮЩИЙ СЛАЙД -->
              <div class="autowebinar__slide autowebinar__slide_end">
                <span class="autowebinar__slideText autowebinar__slideText_end"
                  >Регистрация</span
                >
              </div>
              <!-- КОНЕЦ ЗАВЕРШАЮЩЕГО СЛАЙДА -->
            </div>
          </div>
          <!-- КОНЕЦ БЛОКА СЛАЙДОВ -->

          <!-- БЛОК СООБЩЕНИЙ -->
          <div class="autowebinar__messagesContainer">
            <h2 class="autowebinar__subtitle">
              <Tr>Сообщения в чате</Tr>
            </h2>
            <div class="autowebinar__descriptionText">
              <Tr>Перед созданием сообщений настройте слайды.</Tr>
            </div>
            <!-- кнопки блока сообщений -->
            <div class="autowebinar__messagesButtonsWrap">
              <div class="autowebinar__messagesButtonsBox">
                <button
                  type="button"
                  class="autowebinar__messagesButton autowebinar__messagesButton_active"
                >
                  <base-link
                    class="autowebinar__messagesButtonLink"
                    to="/leadership/autowebinars/1/webinar/points"
                  >
                    <base-icon-svg width="16" height="19" viewbox="0 0 16 19">
                      <messages-time />
                    </base-icon-svg>
                  </base-link>
                </button>
                <button type="button" class="autowebinar__messagesButton">
                  <base-link
                    class="autowebinar__messagesButtonLink"
                    to="/leadership/autowebinars/1/webinar/list"
                  >
                    <base-icon-svg width="16" height="14" viewbox="0 0 16 14">
                      <messages-list />
                    </base-icon-svg>
                  </base-link>
                </button>
              </div>
              <button type="button" class="autowebinar__messagesAudience">
                <base-link
                  to="/leadership/autowebinars/1/webinar/audience-list"
                >
                  <Tr>Список зрителей</Tr>
                </base-link>
              </button>
            </div>
            <!-- точки сообщений -->
            <div class="autowebinar__messagesDotsWrap">
              <div class="autowebinar__messagesDotsRow">
                <button type="button" class="autowebinar__messagesDotsButton">
                  <base-icon-svg width="20" height="20" viewbox="0 0 20 20">
                    <add-message-button />
                  </base-icon-svg>
                </button>
              </div>
              <div class="autowebinar__messagesDotsRow">
                <span
                  class="autowebinar__messagesDot autowebinar__messagesDot_active"
                ></span>
              </div>
              <div class="autowebinar__messagesDotsRow">
                <span class="autowebinar__messagesDot"></span>
              </div>
            </div>
          </div>
          <!-- КОНЕЦ БЛОКА СООБЩЕНИЙ -->
          <button class="autowebinar__button">
            <Tr>Сохранить</Tr>
          </button>
        </div>
        <!-- КОНЕЦ БЛОКА УПРАВЛЕНИЯ СОЗДАНИЕМ -->
      </div>
    </div>
  </main>
</template>

<script>
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue";
import UploadSlide from "@/components/Leadership/AutowebinarIcons/UploadSlide.vue";
import UploadVideo from "@/components/Leadership/AutowebinarIcons/UploadVideo.vue";
import ButtonMove from "@/components/Leadership/AutowebinarIcons/ButtonMove.vue";
import MessagesTime from "@/components/Leadership/AutowebinarIcons/MessagesTime.vue";
import MessagesList from "@/components/Leadership/AutowebinarIcons/MessagesList.vue";
import EditingEdit from "@/components/SvgIcons/EditingEdit.vue";
import AddMessageButton from "@/components/Leadership/AutowebinarIcons/AddMessageButton.vue";

export default {
  name: "Autowebinars",
  components: {
    EditingDelete,
    UploadSlide,
    UploadVideo,
    ButtonMove,
    MessagesTime,
    MessagesList,
    EditingEdit,
    AddMessageButton,
  },
  data() {
    return {
      breadcrumbs: [
        { title: "Настройки лидера", link: "/leadership" },
        { title: "Автовебинары", link: "/leadership/autowebinars" },
      ],
      NAME_MAX_LENGTH: 64,
      noContent: { noContacts: true, text: "Загрузите слайды и видео" },
    };
  },
};
</script>

<style lang="stylus" scoped>
.titleAutowebinar {
  letter-spacing: -0.1px;
}

.title__wrap {
  display: flex;
  flex-wrap: wrap;
}

.tabList {
  margin-right: -16px;
  margin-left: -16px;
  border-bottom: 4px solid grey_text_color;
}

.tabItem {
  position: relative;
  display: inline-block;
}

.tabButton {
  padding: 4px 16px 6px;
  background: none;
  border: none;
  font(main_text_color, 14px, 700, 18px);
}

.tabButton_active {
  position: relative;
}

.tabButton_active::after {
  position: absolute;
  content: '';
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #ff9f43;
}

.autowebinar__wrap {
  padding: 8px 16px 20px;
  min-width: 692px;
  width: fit-content;
  border-top: 3px solid transparent;
  box-shadow: 0px 3px 10px #0f164214;
  border-radius: 8px;
}

.autowebinar__body {
  position: relative;
  padding-top: 16px;
}

.autowebinar__upload {
  &Wrap {
    display: flex;
    max-width: 864px;
  }

  &Box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      margin-right: 16px;

      & .autowebinar__descriptionText {
        margin-right: 39px;
        width: 268px;

        &_bold {
          font-weight: 700;
        }
      }
    }

    &:last-child {
      & .autowebinar__descriptionText {
        width: 293px;
      }
    }
  }

  &Button {
    display: flex;
    margin-bottom: 11px;
    padding: 7px 7px 7px 4px;
    border: 1px dashed #0f1642;
    border-radius: 4px;
    background: none;
    font_regular();

    &:hover {
      opacity: 0.8;
    }
  }

  &Svg {
    margin-right: 5px;
  }

  &Content {
    margin-top: 19px;
    height: 224px;

    &Slide {
    }

    &Video {
      width: 400px;
    }

    & iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.autowebinar__description {
  &Text {
    position: relative;
    display: block;
    margin-left: 12px;
    padding: 0px;
    width: fit-content;
    background-color: white;
    font(main_text_color, 10px, 400, 14px);
    letter-spacing: -0.1px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -12px;
      width: 4px;
      height: 100%;
      background-color: grey_text_color;
    }
  }
}

.autowebinar__controls {
  &Wrap {
    margin-top: 20px;
  }

  &ButtonPlay {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 2px;
    background-color: grey_text_color;

    &:hover {
      background-color: #d9dadc;
    }

    &Icon {
      position: relative;
      left: 2px;
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      border-left: 14px solid main_text_color;
    }
  }
}

.autowebinar__progressbar {
  position: absolute;
  top: 8px;
  left: 0px;
  width: 100%;
  height: 8px;
  background-color: grey_text_color;
  border-radius: 2px;

  &Wrap {
    position: relative;
    margin-bottom: 19px;
    width: 1014px;
    height: 50px;
  }

  &ButtonMove {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 24px;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px #0f164214;
    border: none;
    border-radius: 4px;

    &::after {
      position: absolute;
      content: '';
      bottom: -277px;
      width: 2px;
      background-color: main_text_color;
      opacity: 0.1;
      height: 294px;
    }

    &Svg {
      opacity: 0.3;
    }

    &:hover {
      cursor: pointer;
    }

    &Wrap {
      position: absolute;
      top: 0;
      left: 20%;
      border-radius: 4px;
      text-align: center;
    }

    &Time {
      font(main_text_color, 12px, 400, 16px);
      opacity: 0.6;
    }
  }

  &Time {
    position: absolute;
    bottom: 0;
    font(main_text_color, 12px, 400, 16px);
    opacity: 0.6;

    &_beginning {
      left: 0;
    }

    &_end {
      right: 0;
    }
  }
}

.autowebinar__subtitle {
  position: relative;
  margin-bottom: 12px;
  width: fit-content;
  background-color: white;
  font_bold();
}

.autowebinar__slide {
  position: relative;
  padding: 1px 2px;
  height: 100%;
  background-color: grey_text_color;
  border-radius: 2px;

  &sWrap {
    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 2px;
    margin-bottom: 20px;
    padding-top: 8px;
    height: 72px;
  }

  &_end {
    background-color: main_text_color;
    box-shadow: 0 3px 10px rgba(15, 22, 66, 0.08);

    & .autowebinar__slideText {
      color: white;
    }
  }

  &_pointed {
    padding: 2px 10px;
    background-color: orange;

    & .autowebinar__slideText, & .autowebinar__slideTitle {
      color: white;
    }

    &Time {
      position: absolute;
      top: -19px;
      font(main_text_color, 12px, 400, 16px);
      opacity: 0.6;

      &_start {
        left: -34px;
      }

      &_end {
        right: -34px;
      }
    }

    &Stretcher {
      position: absolute;
      top: 0;
      width: 8px;
      height: 100%;
      background-color: white;
      box-shadow: 0 3px 10px rgba(15, 22, 66, 0.16);
      cursor: pointer;

      &::after {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 5px;
        height: 5px;
        border-right: 2px solid main_text_color;
        border-bottom: 2px solid main_text_color;
      }

      &_start {
        left: 0;

        &::after {
          left: 2px;
          border-radius: 2px 0 0 2px;
          transform: rotate(135deg);
        }
      }

      &_end {
        right: 0px;

        &::after {
          border-radius: 0 2px 2px 0;
          transform: rotate(-45deg);
        }
      }
    }

    &Pointer {
      position: absolute;
      right: 0;
      bottom: 12px;
      left: 0;
      margin: 0 auto;
      width: min-content;
      cursor: pointer;
    }

    &Popup {
      position: absolute;
      z-index: 5;
      bottom: -138px;
      left: 50%;
      transform: translateX(-50%);
      padding: 4px;
      width: 216px;
      box-shadow: 0 3px 10px rgba(15, 22, 66, 0.16);
      border-radius: 2px;
      background-color: white;

      &::before {
        position: absolute;
        content: '';
        top: -6px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid white;
      }

      &Button {
        position: absolute;
        top: 6px;
        right: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 24px;
        height: 24px;
        background-color: white;
        border: none;
        border-radius: 50%;

        &:hover {
          opacity: 0.8;
        }
      }

      &Img {
        display: block;
        width: 208px;
      }

      &Name {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 4px;
        height: 20px;

        &Text {
          display: inline-block;
          margin-right: 3px;
          width: 208px;
          height: 14px;
          overflow: hidden;
          font-size: 10px;
          font-weight: 700;
          line-height: 14px;
        }

        &Button {
          padding: 0;
          border: none;
          background-color: white;

          &Wrap {
            position: absolute;
            bottom: -4px;
            right: 0px;
            display: flex;
          }

          &Span {
            display: inline-block;
            width: 12px;
            height: 20px;
            background: transparent linear-gradient(90deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
          }
        }
      }
    }
  }

  &Text, &Title {
    display: block;
    width: 100%;
    overflow: hidden;
    font(main_text_color, 10px, 400, 14px);
  }

  &Title {
    font-weight: 700;
  }
}

.autowebinar__messages {
  &Container {
    margin-bottom: 16px;

    & .autowebinar__descriptionText {
      margin-bottom: 9px;
    }
  }

  &Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: unset;
    width: 32px;
    height: 32px;
    background-color: white;
    border: 1px solid grey_text_color;

    &:first-child {
      border-right: none;
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-left: none;
      border-radius: 0 2px 2px 0;
    }

    &_active {
      background-color: main_text_color;

      & svg {
        fill: white;
      }
    }

    &sWrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    &sBox {
      display: flex;
    }

    &Link {
      display: inline-flex;
    }
  }

  &Audience {
    padding: 8px 7px 7px;
    border: none;
    border-radius: 4px;
    background-color: grey_text_color;
    font(main_text_color, 14px, 400, 20px);

    &:hover {
      background-color: #d9dadc;
    }
  }

  &Dots {
    margin-bottom: 22px;
  }
}

.autowebinar__button {
  display: flex;
  margin-top: 18px;
  padding: 11px 12px;
  background-color: #e6e7eb;
  border: none;
  border-radius: 4px;
  font_regular();

  &:hover {
    background-color: #d9dadc;
  }
}

.autowebinar__messagesDot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: grey_text_color;
  border: 1px solid white;
  border-radius: 50%;

  &_active {
    width: 16px;
    height: 16px;
    background-color: main_text_color;
  }

  &sWrap {
  }

  &sRow {
    display: flex;
    align-items: center;
    height: 20px;
  }

  &sButton {
    display: flex;
    padding: 0px;
    background-color: unset;
    border: none;
  }
}
</style>
