<template>
  <div>
    <div class="autowebinar__uploadWrap">
      <!-- <div class="autowebinar__uploadBox">
        <div class="autowebinar__uploadButtonWrap">
          <button class="autowebinar__uploadButton addFile">
            <base-icon-svg
              class="autowebinar__uploadSvg"
              width="18"
              height="17"
              viewbox="0 0 18 17"
            >
              <upload-slide />
            </base-icon-svg>
            <span>
              <Tr>Загрузить слайды</Tr>
            </span>
            <input
              class="addFile__input"
              @change="handleFileUpload"
              ref="newSlide"
              type="file"
              multiple
            />
          </button>
          <div class="autowebinar__descriptionText">
            <Tr>
              Каждый слайд должен быть отдельной картинкой формата JPG или PNG.
              Максимальное количество слайдов — 100, сейчас -
            </Tr>
            <strong> {{ slidesCount || 0 }}</strong>
          </div>
        </div>
        <div class="slideBox">
          <img :src="slideImg" alt="" class="slideBox__img" />
        </div>
      </div> -->

      <div class="autowebinar__uploadBox">
        <autowebinars-upload-video :videoUrl="videoUrl" />
      </div>
    </div>
  </div>
</template>

<script>
// import UploadSlide from "@/components/Leadership/AutowebinarIcons/UploadSlide.vue";
import AutowebinarsUploadVideo from "@/components/Autowebinars/AutowebinarsUploadVideo.vue";

import { mapGetters } from "vuex";

export default {
  name: "AutowebinarsUploadBlock",
  components: {
    // UploadSlide,
    AutowebinarsUploadVideo,
  },
  props: {
    slidesCount: {
      type: Number,
      required: false,
    },
    videoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({}),
    currentTime: {
      get() {
        return this.$store.getters["videoEditor/currentTime"];
      },
      set(value) {
        this.$store.dispatch("videoEditor/updateCurrentTime", value);
      },
    },

    slideImg() {
      const slide = this.$store.getters["presentation/getCurrentSlide"](
        this.currentTime
      );
      if (slide && slide.data) {
        if (typeof Number(slide.data) === Number) {
          return "";
        }
        return slide.data;
      }

      return "";
    },
  },
  methods: {
    handleFileUpload() {
      const files = this.$refs.newSlide.files;

      this.$store.dispatch("presentation/addSlide", files);
      // this.$refs.newSlide.value = null;
    },
  },
};
</script>

<style lang="stylus">
.autowebinar__upload {
  &Wrap {
    display: flex;
    max-width: 864px;
  }

  &Box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:first-child {
      margin-right: 16px;

      & .autowebinar__descriptionText {
        margin-right: 34px;
        width: 268px;

        &_bold {
          font-weight: 700;
        }
      }
    }

    &:last-child {
      & .autowebinar__descriptionText {
        width: 293px;
      }
    }
  }

  &Button {
    position: relative;
    display: flex;
    margin-bottom: 11px;
    padding: 7px 7px 7px 4px;
    border: 1px dashed #0f1642;
    border-radius: 4px;
    background: none;
    font_regular();

    &:hover {
      opacity: 0.8;
    }
  }

  &Svg {
    margin-right: 5px;
  }
}

.autowebinar__description {
  &Text {
    position: relative;
    display: block;
    margin-left: 12px;
    padding: 0px;
    width: fit-content;
    background-color: white;
    font(main_text_color, 10px, 400, 14px);
    letter-spacing: -0.1px;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -12px;
      width: 4px;
      height: 100%;
      background-color: grey_text_color;
    }
  }
}

.playButton {
  width: 32px;
  height: 32px;
  background: #E6E7EB;
  border: none;
}

.slideBox {
  max-width: 448px;
  min-height: 224px;

  &__img {
    max-width: 448px;
    min-height: 224px;
  }
}

.addFile {
  position: relative;

  &__input {
    display: block;
    position: absolute;
    // visibility: hidden;
    opacity: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    cursor: pointer;
  }
}
</style>