import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import { groupWith } from "ramda";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    errors: {},
    pending: false,
  }),

  getters: {
    messagesGroupedByTime(state) {
      return groupWith((a: any, b: any) => a.seconds === b.seconds, state.data);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ state, commit, rootGetters }, { id }) {
      try {
        const response = await axios.get(
          `/chats/${id}/messages`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async fetchChatMembers({ state, commit, rootGetters }, { id }) {
      try {
        const response = await axios.get(
          `/videos/${id}/members`,
          axiosConfig(rootGetters["user/chatBotToken"])
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data.response);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
