<template>
  <button class="baseDelBtn" type="button" @click="click">
    <base-icon-svg
      class="baseDelBtnSvg"
      width="20"
      height="20"
      viewbox="0 0 20 20"
    >
      <editing-delete />
    </base-icon-svg>
    <Tr>Удалить</Tr>
  </button>
</template>

<script>
import EditingDelete from "@/components/SvgIcons/EditingDelete.vue"

export default {
  name: "BaseDelButton",
  components: {
    EditingDelete,
  },
  methods: {
    click() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="stylus" scoped>
.baseDelBtn {
  display: flex;
  align-items: center;
  column-gap: 4px;
  padding: 10px;
  border: none;
  background-color: #fff;
  font_regular();
  color: danger_text_color;
}

.baseDelBtnSvg {
  color: inherit;
}
</style>
