// import Universal from "@/models";
import Course from "@/models/course";
import i18n from "@/plugins/i18n";
import router from "@/router";
import { getLang } from "@/utils/cookies/getLang";
import { runNotice } from "@/utils/notifications";
import supportedLangs from "@/utils/supportedLangs";
import { append, pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: new Course(),
    errors: {},
    pending: false,
    activeLang: getLang(),
  }),

  getters: {
    course(state) {
      return state.data;
    },
    pending(state) {
      return state.pending;
    },
    lang(state) {
      return state.activeLang;
    },
    errors(state) {
      return state.errors;
    },
    name(state) {
      return pathOr("", ["data", "locales", state.activeLang, "name"], state);
    },
    description(state) {
      return pathOr(
        "",
        ["data", "locales", state.activeLang, "short_description"],
        state
      );
    },
    isLangValid: state => lang => {
      if (!state.data.locales) {
        return false;
      }
      return (
        !!state.data.locales[lang].name &&
        !!state.data.locales[lang].short_description
      );
    },
    langTabs(state, getters) {
      return supportedLangs().map(lang => ({
        title: lang,
        isValid: getters.isLangValid(lang),
      }));
    },
    isPriceValid(state) {
      if (state.data.payment_type === "paid" && !state.data.cost) {
        return false;
      }
      return true;
    },
    cost(state) {
      return pathOr({}, ["data", "cost"], state);
    },
    choosedProgram(state) {
      return pathOr(null, ["data", "quest_course"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    TOGGLE_LANG(state, lang) {
      state.activeLang = lang;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    TOGGLE_COURSE_LANG(state, lang) {
      if (state.data.languages.indexOf(lang) === -1) {
        state.data.languages = append(lang, state.data.languages);
      } else {
        state.data.languages = state.data.languages.filter(
          item => item !== lang
        );
      }
    },
    CHANGE_NAME(state, value) {
      state.data.locales[state.activeLang].name = value;
    },
    CHANGE_DESCRIPTION(state, value) {
      state.data.locales[state.activeLang].short_description = value;
    },
    TOGGLE_COST(state, value) {
      state.data.payment_type = value;
    },
    CHANGE_COST(state, value) {
      state.data.cost = value;
    },
    CHANGE_PROGRAM(state, value) {
      state.data.quest_course = value;
    },
  },

  actions: {
    init({ commit }) {
      commit("SET_DATA", new Course());
    },

    async createCourse({ commit, state, getters }) {
      if (!getters.isPriceValid) {
        runNotice("error", i18n.t("Не заполнена цена"));
        return;
      }
      const data = state.data.getCreateModel();

      try {
        commit("SET_ERRORS", {});
        commit("SET_PENDING", true);
        const lang = i18n.locale;
        const response = await Vue.axios.post(`/leadership_quests/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);

          const { quest_course, id } = response.data;
          if (quest_course) {
            router.push(
              `/${lang}/leadership/courses/${id}/description?programId=${quest_course}`
            );
          } else {
            router.push(`/${lang}/leadership/courses/${id}/description`);
          }
        } else {
          throw response.data;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
