import getAwards from "@/utils/getAwards";
import { omit } from "ramda";
import Universal from ".";

class MentorFileCheck extends Universal {
  constructor(data?) {
    if (data) {
      super(data);
    } else {
      super({
        id: 0,
        ...getAwards(),
      });
    }
  }

  getCreateModel() {
    const model = this.transformLocales();

    return omit(["id"], model);
  }
}

export default MentorFileCheck;
