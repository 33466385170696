import axiosConfig from "@/utils/axiosConfigChatBot";
import axios from "axios";
import moment from "moment";
import Cookies from "js-cookie";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    form: {
      lead_id: "",
      video_id: "",
      started_at: "",
      name: "",
    },
    createdUrl: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    name(state) {
      return state.form.name;
    },
    linkCreated(state) {
      return state.created;
    },
    startedAt(state) {
      return state.form.started_at;
    },
    errors(state) {
      return state.errors;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.form.name = value;
    },
    CHANGE_STARTED_AT(state, value) {
      state.form.started_at = value;
    },
    CHANGE_LEAD_ID(state, value) {
      state.form.lead_id = value;
    },
    CHANGE_VIDEO_ID(state, value) {
      state.form.video_id = value;
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED(state, data) {
      state.createdUrl = data.url;
    },
    CLEAR_STORE(state) {
      state.form = {
        lead_id: "",
        video_id: "",
        started_at: "",
        name: "",
      };
      state.createdUrl = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async createLink({ commit, state }) {
      const data = {
        ...state.form,
        started_at: moment(state.form.started_at).format("DD.MM.YYYY HH:mm:ss"),
      };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/leads/${state.form.lead_id}/vebinar`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 200) {
          commit("SET_CREATED", response.data.response);
          commit("LINK_CREATED", true);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
