var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leadsTable"},[_c('updated-funnel-body-head',{class:[
      'leadsTableBox',
      'leadsTableBoxHead',
      { leadsTableBox_sourceHide: _vm.sourceHide } ],attrs:{"sourceHide":_vm.sourceHide,"pipelineIsFull":_vm.pipelineIsFull},on:{"hideSource":_vm.hideSource,"getPipelineFull":_vm.getPipelineFull,"getPipelineShort":_vm.getPipelineShort}},[_c('template',{slot:"status"},[_c('div',{staticClass:"leadsTableHead__cell leadsTableHead__cellStatus"},[_c('Tr',{staticClass:"leadsTableHead__cellText"},[_vm._v("Статус")])],1)]),_c('template',{slot:"managerIcon"},[_c('div',{staticClass:"leadsTableHead__cell leadsTableHead__cellManager"})]),_c('template',{slot:"createLinks"},[_c('div')])],2),_vm._l((_vm.leads),function(lead){return _c('updated-funnel-body-item',{key:lead.id,class:[
      'leadsTableBox',
      'leadsTableBox_item',
      { leadsTableBox_sourceHide: _vm.sourceHide } ],attrs:{"lead":lead,"sourceHide":_vm.sourceHide,"pipelineIsFull":_vm.pipelineIsFull},on:{"showSource":_vm.showSource,"click":function($event){return _vm.openModal(lead)}}},[_c('template',{slot:"status"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.status(lead)),expression:"status(lead)"}],staticClass:"leadsTableItem__cell leadsTableItem__cellStatus"},[_c('span',{staticClass:"updatedFunnelBodyItem__cell_mobile"},[_c('Tr',[_vm._v("Статус")]),_vm._v(": ")],1),_c('span',[_vm._v(_vm._s(_vm.status(lead)))])])]),_c('template',{slot:"managerIcon"},[_c('div',{staticClass:"leadsTableItem__cell leadsTableItem__cellManager"},[(_vm.hasManager(lead))?_c('v-popover',{attrs:{"offset":"0","trigger":"hover","placement":"top"}},[_c('span',{class:[
              'managerIcon',
              'managerIcon_desktop',
              { managerIcon_active: _vm.hasManager(lead) } ]},[_c('base-icon-svg',{attrs:{"width":"24","height":"24","viewbox":"0 0 24 24"}},[_c('manager-icon')],1)],1),(_vm.hasManager(lead))?_c('template',{slot:"popover"},[_c('div',{staticClass:"notificationPopover"},[_c('div',{staticClass:"tooltip-title"},[_c('Tr',[_vm._v("Менеджер")])],1),_c('span',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.managerName(lead))+" ")])])]):_vm._e()],2):_c('span',{class:[
            'managerIcon',
            'managerIcon_desktop',
            { managerIcon_active: _vm.hasManager(lead) } ]},[_c('base-icon-svg',{attrs:{"width":"24","height":"24","viewbox":"0 0 24 24"}},[_c('manager-icon')],1)],1),(_vm.hasManager(lead))?_c('v-popover',{attrs:{"offset":"0","trigger":"click","placement":"top"}},[_c('span',{class:[
              'managerIcon',
              'managerIcon_mobile',
              { managerIcon_active: _vm.hasManager(lead) } ]},[_c('base-icon-svg',{attrs:{"width":"24","height":"24","viewbox":"0 0 24 24"}},[_c('manager-icon')],1)],1),_c('template',{slot:"popover"},[_c('div',{staticClass:"notificationPopover"},[_c('div',{staticClass:"tooltip-title"},[_c('Tr',[_vm._v("Менеджер")])],1),_c('span',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.managerName(lead))+" ")])])])],2):_c('span',{class:[
            'managerIcon',
            'managerIcon_mobile',
            { managerIcon_active: _vm.hasManager(lead) } ]},[_c('base-icon-svg',{attrs:{"width":"24","height":"24","viewbox":"0 0 24 24"}},[_c('manager-icon')],1)],1)],1)]),_c('template',{slot:"calls"},[_c('div',[(_vm.isUserOP && _vm.phoneStartsWithPlus(lead))?_c('button',{staticClass:"\n            updatedFunnelBodyItem__cell updatedFunnelBodyItem__callButton\n          ",on:{"click":function($event){return _vm.openCallToLeadModal(lead)}}},[_c('base-icon-svg',{attrs:{"width":"16","height":"16","viewbox":"0 0 16 16"}},[_c('leads-phone-icon')],1)],1):_vm._e()])]),_c('template',{slot:"createLinks"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.$t('Создать задачу'),
          classes: 'leadsCreateTaskTooltip',
        }),expression:"{\n          content: $t('Создать задачу'),\n          classes: 'leadsCreateTaskTooltip',\n        }"}],staticClass:"\n          updatedFunnelBodyItem__cell updatedFunnelBodyItem__cellActionsButton\n        ",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.openCreateTaskModalByLead(lead)}}},[_c('base-icon-svg',{attrs:{"width":"16","height":"16","viewbox":"0 0 16 16"}},[_c('leads-create-task-icon')],1),_c('div',{staticClass:"updatedFunnelBodyItem__cellActionsText"},[_c('Tr',[_vm._v("Создать задачу")])],1)],1)])],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }