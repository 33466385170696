<template>
  <div :class="['headerInformation', { headerInformation_mobile: mobile }]">
    <h3
      :class="[
        'headerInformation__title',
        { headerInformation__title_mobile: mobile },
      ]"
    >
      <Tr>{{ title }}</Tr>
    </h3>
    <div class="headerInformation__wrap">
      <span
        :class="[
          'headerInformation__txt',
          { headerInformation__txt_mobile: mobile },
        ]"
      >
        <Tr>{{ param1 }}</Tr>
      </span>
      <template v-if="name === 'tasks'">
        <base-link
          to="/tasks/actual"
          :class="[
            'headerInformation__number',
            { headerInformation__number_mobile: mobile },
          ]"
        >
          {{ value1 }}
        </base-link>
      </template>
      <template v-else>
        <span
          :class="[
            'headerInformation__number',
            { headerInformation__number_mobile: mobile },
          ]"
        >
          {{ value1 }}
        </span>
      </template>
    </div>

    <div class="headerInformation__wrap">
      <span
        :class="[
          'headerInformation__txt',
          { headerInformation__txt_mobile: mobile },
        ]"
      >
        <Tr>{{ param2 }}</Tr>
      </span>
      <template v-if="name === 'tasks'">
        <base-link
          to="/tasks/expired"
          :class="[
            'headerInformation__number',
            { headerInformation__numberFUp: expired },
            { headerInformation__number_mobile: mobile },
          ]"
        >
          {{ value2 }}
        </base-link>
      </template>

      <template v-else>
        <span
          :class="[
            'headerInformation__number',
            { headerInformation__numberFUp: expired },
            { headerInformation__number_mobile: mobile },
          ]"
        >
          {{ value2 }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderInfo",
  props: {
    title: String,
    param1: String,
    param2: String,
    value1: [Number, String],
    value2: [Number, String],
    expired: Boolean,
    mobile: Boolean,
    name: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
.headerInformation {
  &_mobile {
    margin-bottom: 12px;
  }
}

.headerInformation__title {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
}

.headerInformation__wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.headerInformation__txt {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  opacity: 0.6;
  margin-right: 28px;
}

.headerInformation__number {
  color: #ffffff;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
}

a.headerInformation__number {
  &:hover {
    opacity: 0.6;
  }
}

.headerInformation__title, .headerInformation__txt, .headerInformation__number {
  &_mobile {
    color: main_text_color;
  }
}

.headerInformation__numberFUp {
  color: #e81526;
}
</style>
