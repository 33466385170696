import axiosConfig from "@/utils/axiosConfigFunnel";
import Cookies from "js-cookie";
import axios from "axios";
import Vue from "vue";
import { pathOr } from "ramda";
import qs from "qs";
import moment from "moment";

function filterNonNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

function transformFilters(obj) {
  let transformedObj = obj;
  if (transformedObj.start_created_at) {
    transformedObj = {
      ...transformedObj,
      start_created_at: moment(transformedObj.start_created_at)
        .utc(true)
        .startOf("day")
        .format(),
    };
  }
  if (transformedObj.finish_created_at) {
    transformedObj = {
      ...transformedObj,
      finish_created_at: moment(transformedObj.finish_created_at)
        .utc(true)
        .endOf("day")
        .format(),
    };
  }
  return transformedObj;
}

function changeResponseString(str, filter) {
  const arr1 = str.split("&");
  let findedEl = arr1.find(el => el.match(`${filter}=`));
  const arr2 = arr1.filter(el => el !== findedEl);
  const result1 = arr2.join("&");

  findedEl = findedEl.replace(`${filter}=`, "");

  const arr3 = findedEl.split("%2C");

  let result2 = arr3.join(`&${filter}[]=`);
  result2 = `&${filter}[]=` + result2;
  const result3 = result1 + result2;

  return result3;
}

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
    pickedLeads: [],
    manager: null,
    filters: {
      q: "",
    },
    isPickedAllFunnels: false,
    isPickedAllTags: false,
    isPickedAllOrders: false,
    isPickedAllManagers: false,
    funnelsFilterValue: "",
    funnelsFilterCounter: null,
    tagsFilterValue: "",
    tagsFilterCounter: null,
    ordersFilterValue: "",
    ordersFilterCounter: null,
    managersFilterValue: "",
    managersFilterCounter: null,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },

    pendingLeads(state) {
      return state.pendingLeads;
    },

    search(state) {
      return state.filters.q;
    },

    leadsList(state) {
      return pathOr([], ["data", "data"], state);
    },

    isPickedLeadsChecked: state => value => {
      return Boolean(state.pickedLeads.filter(i => i.id === value.id).length);
    },

    pickedLeads(state) {
      return state.pickedLeads;
    },

    pickedLeadsWithManagers(state) {
      const pickedLeadsWithManagersArray: object[] = [];

      if (state.pickedLeads.length > 0) {
        for (let i = state.pickedLeads.length; i--; ) {
          if (state.pickedLeads[i].users.length > 1) {
            pickedLeadsWithManagersArray.push(state.pickedLeads[i]);
          }
        }
        return pickedLeadsWithManagersArray;
      }
      return pickedLeadsWithManagersArray;
    },

    pickedLeadsDoesntHaveManagers(state) {
      if (state.pickedLeads.length > 0) {
        for (let i = state.pickedLeads.length; i--; ) {
          if (
            state.pickedLeads[i].users
              .map(user => {
                return (
                  Object.prototype.hasOwnProperty.call(user, "type") &&
                  user.type === "manager"
                );
              })
              .filter(leadUser => leadUser === true).length === 0
          ) {
            return true;
          }
        }
      }
    },

    manager(state) {
      return state.manager;
    },

    chatbotManagers(state) {
      let usersArray: string[] = [];
      for (let i = 0; i < state.pickedLeads.length; i++) {
        usersArray = [...usersArray, ...state.pickedLeads[i].users];
      }
      const tasqUsers = usersArray.filter(
        user => user["type"] && user["type"] === "manager"
      );
      const tasqUsersIds: string[] = [];
      tasqUsers.forEach(tasqUser => tasqUsersIds.push(tasqUser["user_id"]));
      if (state.pickedLeads.length > 1) {
        const numberedTasqUsers = tasqUsersIds.map(el => el);
        const sortedTasqUsers = numberedTasqUsers.sort();
        const obj = {};

        for (let i = 0; i < sortedTasqUsers.length; i++) {
          const currentEl = sortedTasqUsers[i];
          if (currentEl in obj) {
            obj[currentEl] = 1;
          } else {
            obj[currentEl] += 1;
          }
        }
        const unUniqueValues: string[] = [];
        const uniqueValues: string[] = [];
        let values: string[] = [];
        Object.keys(obj).forEach(key => {
          if (obj[key] === 1) {
            unUniqueValues.push(key);
          }
          if (obj[key] !== 1) {
            uniqueValues.push(key);
          }
          values = [...uniqueValues, ...unUniqueValues];
        });
        return values;
      }
      return tasqUsersIds;
    },

    currentPage(state) {
      return pathOr(0, ["data", "meta", "current_page"], state);
    },

    hasNextPage(state) {
      const currentPage = pathOr(0, ["data", "meta", "current_page"], state);
      const lastPage = pathOr(0, ["data", "meta", "last_page"], state);
      return currentPage < lastPage;
    },

    total(state) {
      return pathOr(0, ["data", "meta", "last_page"], state);
    },

    filters(state) {
      return state.filters;
    },
    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },
    pickedAllFunnels(state) {
      return state.isPickedAllFunnels;
    },
    pickedAllTags(state) {
      return state.isPickedAllTags;
    },
    pickedAllOrders(state) {
      return state.isPickedAllOrders;
    },
    pickedAllManagers(state) {
      return state.isPickedAllManagers;
    },
    funnelsFilterValue(state) {
      return state.funnelsFilterValue;
    },
    funnelsFilterCounter(state) {
      return state.funnelsFilterCounter;
    },
    tagsFilterValue(state) {
      return state.tagsFilterValue;
    },
    tagsFilterCounter(state) {
      return state.tagsFilterCounter;
    },
    ordersFilterValue(state) {
      return state.ordersFilterValue;
    },
    ordersFilterCounter(state) {
      return state.ordersFilterCounter;
    },
    managersFilterValue(state) {
      return state.managersFilterValue;
    },
    managersFilterCounter(state) {
      return state.managersFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = {
        data:
          data.meta.current_page === 1
            ? data.data
            : state.data.data.concat(data.data),
        meta: data.meta,
      };
    },

    SET_PENDING(state, status) {
      state.pending = status;
    },

    SET_ERRORS(state, errors) {
      state.errors = errors;
    },

    SET_PICKED_LEADS(state, value) {
      const isLeadPicked = Boolean(
        state.pickedLeads.find(lead => lead.id === value.id)
      );
      if (isLeadPicked) {
        state.pickedLeads = state.pickedLeads.filter(el => el.id !== value.id);
      } else {
        state.pickedLeads = [...state.pickedLeads, value];
      }
    },

    SET_PICKED_ALL_LEADS(state, value) {
      state.isPickedAllLeads = !state.isPickedAllLeads;

      if (value.length !== state?.pickedLeads?.length) {
        state.isPickedAllLeads = true;
      }

      state.isPickedAllLeads
        ? (state.pickedLeads = [...value])
        : (state.pickedLeads = []);
    },
    CHANGE_MANAGER(state, value) {
      state.manager = value;
    },
    CLEAR_STORE(state) {
      state.pickedLeads = [];
      state.manager = null;
    },

    CHANGE_SEARCH(state, value) {
      state.filters.q = value;
    },

    SET_PICKED_ALL_FUNNELS(state, value) {
      state.isPickedAllFunnels = !state.isPickedAllFunnels;

      if (value.length !== state?.filters?.funnel_id?.length) {
        state.isPickedAllFunnels = true;
      }

      state.isPickedAllFunnels
        ? (state.filters.funnel_id = [...value])
        : (state.filters.funnel_id = []);
    },

    SET_PICKED_ALL_TAGS(state, value) {
      state.isPickedAllTags = !state.isPickedAllTags;

      if (value.length !== state?.filters?.tag_id?.length) {
        state.isPickedAllTags = true;
      }

      state.isPickedAllTags
        ? (state.filters.tag_id = [...value])
        : (state.filters.tag_id = []);
    },

    SET_PICKED_ALL_ORDERS(state, value) {
      state.isPickedAllOrders = !state.isPickedAllOrders;

      if (value.length !== state?.filters?.order_status?.length) {
        state.isPickedAllOrders = true;
      }

      state.isPickedAllOrders
        ? (state.filters.order_status = [...value])
        : (state.filters.order_status = []);
    },

    SET_PICKED_ALL_MANAGERS(state, value) {
      state.isPickedAllManagers = !state.isPickedAllManagers;

      if (value.length !== state?.filters?.manager_id?.length) {
        state.isPickedAllManagers = true;
      }

      state.isPickedAllManagers
        ? (state.filters.manager_id = [...value])
        : (state.filters.manager_id = []);
    },

    CHANGE_FILTER(state, { value, name }) {
      if (value instanceof Array) {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (name === "start_created_at" || name === "finish_created_at") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }
      if (name === "webinar_progress_from") {
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }
      if (name === "webinar_progress_to") {
        if (
          state.filters.webinar_progress_from === undefined ||
          state.filters.webinar_progress_from === ""
        ) {
          state.filters = {
            ...state.filters,
            webinar_progress_from: "0",
            [name]: value,
          };
          return;
        }
        state.filters = {
          ...state.filters,
          [name]: value,
        };
        return;
      }

      if (state.filters[name]) {
        if (state.filters[name].includes(value)) {
          state.filters = {
            ...state.filters,
            [name]: state.filters[name].filter(el => el !== value),
          };
          if (name === "funnel_id") {
            state.isPickedAllFunnels = false;
          } else if (name === "tag_id") {
            state.isPickedAllTags = false;
          } else if (name === "order_status") {
            state.isPickedAllOrders = false;
          } else if (name === "manager_id") {
            state.isPickedAllManagers = false;
          }
        } else {
          if (name === "webinar_viewed") {
            state.filters = {
              ...state.filters,
              [name]: [value],
            };
          } else {
            state.filters = {
              ...state.filters,
              [name]: [...state.filters[name], value],
            };
          }
        }
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },
    CLEAR_FILTERS(state) {
      state.filters = {
        q: "",
      };
      state.isPickedAllFunnels = false;
      state.isPickedAllTags = false;
      state.isPickedAllOrders = false;
      state.isPickedAllManagers = false;

      state.funnelsFilterValue = "";
      state.funnelsFilterCounter = null;
      state.tagsFilterValue = "";
      state.tagsFilterCounter = null;
      state.ordersFilterValue = "";
      state.ordersFilterCounter = null;

      state.managersFilterValue = "";
      state.managersFilterCounter = null;
    },
    SET_FUNNELS_FILTER_VALUE(state, value) {
      state.funnelsFilterValue = value;
    },
    SET_FUNNELS_FILTER_COUNTER(state, value) {
      state.funnelsFilterCounter = Number(value);
    },
    SET_TAGS_FILTER_VALUE(state, value) {
      state.tagsFilterValue = value;
    },
    SET_TAGS_FILTER_COUNTER(state, value) {
      state.tagsFilterCounter = Number(value);
    },
    SET_ORDERS_FILTER_VALUE(state, value) {
      state.ordersFilterValue = value;
    },
    SET_ORDERS_FILTER_COUNTER(state, value) {
      state.ordersFilterCounter = Number(value);
    },
    SET_MANAGERS_FILTER_VALUE(state, value) {
      state.managersFilterValue = value;
    },
    SET_MANAGERS_FILTER_COUNTER(state, value) {
      state.managersFilterCounter = Number(value);
    },
    SET_MANAGER(state, data) {
      for (let i = data.leads_ids.length; i--; ) {
        state.data.data.map(lead => {
          if (lead.id === data.leads_ids[i]) {
            lead.users.push({ service: "tasq", user_id: String(data.user_id) });
          }
        });
      }
    },
    REMOVE_MANAGER(state, data) {
      for (let i = data.leads_ids.length; i--; ) {
        state.data.data.map(lead => {
          if (lead.id === data.leads_ids[i]) {
            lead.users = lead.users.filter(
              user => user.user_id !== String(data.user_id)
            );
          }
        });
      }
    },
  },

  actions: {
    async changeSearch({ commit, dispatch }, { search }) {
      commit("CHANGE_SEARCH", search);
      dispatch("fetch", { page: 1 });
    },

    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },
    pickAllFunnels({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_FUNNELS", payload);
        resolve();
      });
    },
    pickAllTags({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_TAGS", payload);
        resolve();
      });
    },
    pickAllOrders({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_ORDERS", payload);
        resolve();
      });
    },
    pickAllManagers({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_MANAGERS", payload);
        resolve();
      });
    },

    // ACTION FETCH
    async fetch({ commit, rootGetters, state }, { page }) {
      let filters = qs.stringify(
        transformFilters(
          filterNonNull({ ...state.filters, ...(page ? { page } : []) })
        ),
        { arrayFormat: "comma" }
      );

      if (state.filters.funnel_id && state.filters.funnel_id.length > 1) {
        filters = changeResponseString(filters, "funnel_id");
      } else {
        filters = filters.replace(/(funnel_id)/g, "$1[]");
      }
      if (state.filters.tag_id && state.filters.tag_id.length > 1) {
        filters = changeResponseString(filters, "tag_id");
      } else {
        filters = filters.replace(/(tag_id)/g, "$1[]");
      }
      if (state.filters.order_status && state.filters.order_status.length > 1) {
        filters = changeResponseString(filters, "order_status");
      } else {
        filters = filters.replace(/(order_status)/g, "$1[]");
      }
      if (state.filters.manager_id && state.filters.manager_id.length > 1) {
        filters = changeResponseString(filters, "manager_id");
      } else {
        filters = filters.replace(/(manager_id)/g, "$1[]");
      }
      filters = filters.replace(
        /(webinar_progress_from|webinar_progress_to)/g,
        "webinar_progress[]"
      );

      if (state.pending) {
        return;
      }

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/leads/rop/?${filters}`,
          axiosConfig(tasqToken)
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
          return response;
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
    // ACTION FETCH

    // ACTION PICK_MANAGER
    async pickManager({ commit, state }) {
      const leadsIds = () => {
        const arr: number[] = [];
        state.pickedLeads.forEach(item => {
          arr.push(item.id);
        });
        return arr;
      };

      const data = {
        user_id: state.manager,
        leads_ids: leadsIds(),
      };

      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(
          `/telephony/assignment/v2/leads/managers/update/bulk/`,
          data
        );
        if (response.status === 200) {
          commit("SET_MANAGER", data);
          commit("CLEAR_STORE");
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },
    // ACTION PICK_MANAGER

    // ACTION REMOVE_MANAGER
    async removeManager({ commit, state }) {
      const leadsIds = () => {
        const arr: number[] = [];
        state.pickedLeads.forEach(item => {
          arr.push(item.id);
        });
        return arr;
      };

      const data = {
        user_id: state.manager,
        leads_ids: leadsIds(),
      };

      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(
          `/telephony/assignment/v2/leads/managers/remove/bulk/`,
          data
        );
        if (response.status === 200) {
          commit("REMOVE_MANAGER", data);
          commit("CLEAR_STORE");
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
    // ACTION REMOVE_MANAGER
  },
};
