import router from "@/router";
import { runNotice } from "@/utils/notifications";
import { pathOr } from "ramda";
import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: [],
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    notes(state) {
      return pathOr([], ["data"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data = data.results;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
  },

  actions: {
    async fetch({ commit }, { id }) {
      try {
        commit("SET_PENDING", true);
        // commit("SET_DATA", []);

        const response = await Vue.axios.get(
          `/telephony/lead_notes/?lead=${id}&page_size=200`
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async createNote({ commit, state, dispatch }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/telephony/lead_notes/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          dispatch("fetch", { id: data.lead });
          return true;
        } else {
          commit("SET_PENDING", false);
          throw response.data.message;
        }
      } catch (e) {
        e.response.data.text.forEach(text => runNotice("error", text));
        commit("SET_PENDING", false);
      }
    },

    async updateNote({ commit, state, dispatch }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.patch(
          `/telephony/lead_notes/${data.id}/`,
          data
        );

        if (response.status === 200) {
          commit("SET_PENDING", false);
          dispatch("fetch", { id: data.lead });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async deleteNote({ commit, state, dispatch }, id) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(`/telephony/lead_notes/${id}/`);

        if (response.status === 204) {
          commit("SET_PENDING", false);
          const { leadId } = router.currentRoute.params;
          dispatch("fetch", { id: leadId });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
    async updatedLeadsDeleteNote({ commit, state, dispatch }, { id, leadId }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(`/telephony/lead_notes/${id}/`);

        if (response.status === 204) {
          commit("SET_PENDING", false);
          dispatch("fetch", { id: leadId });
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },
  },
};
