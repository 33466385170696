<template>
  <new-course-container />
</template>

<script>
import NewCourseContainer from "@/containers/Leadership/NewCourseContainer.vue";

export default {
  name: "NewCourse",
  components: {
    NewCourseContainer,
  },
};
</script>
