import Course from "@/models/course";
import { pathOr } from "ramda";
import Vue from "vue";

const transformResponseData = ({ results, ...rest }) => ({
  ...rest,
  results: results.map(course => new Course(course)),
});

export default {
  namespaced: true,

  state: () => ({
    data: {},
    pending: false,
  }),

  getters: {
    courses(state) {
      return state.data && state.data.results ? state.data.results : [];
    },
    pending(state) {
      return state.pending;
    },
    theNextPage(state) {
      return pathOr(0, ["data", "next_page_number"], state);
    },
    currentPage(state) {
      return pathOr(1, ["data", "current_page_number"], state);
    },
  },

  mutations: {
    SET_DATA(state, data) {
      if (data.current_page_number !== 1) {
        state.data = {
          ...data,
          results: state.data.results.concat(data.results),
        };
      } else {
        state.data = data;
      }
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    DELETE_COURSE(state, courseId) {
      state.data.results = state.data.results.filter(
        ({ id }) => id !== courseId
      );
    },
  },

  actions: {
    async fetch({ commit }, { page }) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.get(
          `/leadership_quests/${page ? `?page=${page}` : ""}`
        );

        if (response.status === 200) {
          commit("SET_DATA", transformResponseData(response.data));
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async deleteCourse({ commit }, id) {
      try {
        commit("SET_PENDING", true);
        const response = await Vue.axios.delete(`/leadership_quests/${id}/`);
        if (response.status === 204) {
          commit("DELETE_COURSE", id);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data);
        commit("SET_PENDING", false);
      }
    },
  },
};
