import axiosConfig from "@/utils/axiosConfigFunnel";
import Cookies from "js-cookie";

import axios from "axios";
import { find, propEq } from "ramda";

export default {
  namespaced: true,

  state: () => ({
    products: [],
    form: {
      name: "",
      funnel_id: null,
      product_id: null,
      funnel_starter_id: null,
    },
    createdUrl: "",
    createdName: "",
    created: false,
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    name(state) {
      return state.form.name;
    },
    linkCreated(state) {
      return state.created;
    },
    products(state) {
      return state.products;
    },
    funnels(state) {
      if (state.form.product_id) {
        const product: any = find(propEq("id", Number(state.form.product_id)))(
          state.products
        );
        if (product) {
          const funnels = product.funnels.filter(el => el.starters.length > 0);
          return funnels;
        }
      }
      return [];
    },
    starters(state) {
      if (state.form.funnel_id) {
        const funnel: any = find(propEq("id", Number(state.form.funnel_id)))(
          state.products.find(
            product => product.id === Number(state.form.product_id)
          ).funnels
        );
        return funnel ? funnel.starters : [];
      }
      return [];
    },
    productId(state) {
      return state.form.product_id;
    },
    funnelId(state) {
      return state.form.funnel_id;
    },
    starterId(state) {
      return state.form.funnel_starter_id;
    },
    errors(state) {
      return state.errors;
    },
    createdUrl(state) {
      return state.createdUrl;
    },
    createdName(state) {
      return state.createdName;
    },
  },

  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_NAME(state, value) {
      state.form.name = value;
    },
    CHANGE_PRODUCT(state, value) {
      state.form.product_id = Number(value);
      state.form.funnel_id = null;
      state.form.funnel_starter_id = null;
    },
    CHANGE_FUNNEL(state, value) {
      state.form.funnel_id = Number(value);
      state.form.funnel_starter_id = null;
    },
    CHANGE_STARTER(state, value) {
      state.form.funnel_starter_id = Number(value);
    },
    LINK_CREATED(state, value) {
      state.created = value;
    },
    SET_CREATED(state, data) {
      state.createdName = data.data.name;
      state.createdUrl = data.data.redirect_url;
    },
    CLEAR_STORE(state) {
      state.form = {
        name: "",
        funnel_id: null,
        product_id: null,
        funnel_starter_id: null,
      };
      state.createdUrl = "";
      state.createdName = "";
      state.created = false;
      state.errors = {};
    },
  },

  actions: {
    async fetch({ commit, rootGetters, state }) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.get(
          `/products?company=${rootGetters[
            "user/userCompanyName"
          ].toLowerCase()}`,
          axiosConfig(tasqToken)
        );
        if (response.status === 200) {
          commit("SET_PRODUCTS", response.data.data);

          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response);
        commit("SET_PENDING", false);
      }
    },

    async createLink({ commit, state }) {
      const data = {
        name: state.form.name,
        funnel_starter_id: state.form.funnel_starter_id,
      };

      try {
        commit("SET_PENDING", true);

        const tasqToken = Cookies.get("auth");

        const response = await axios.post(
          `/funnel-links`,
          data,
          axiosConfig(tasqToken)
        );
        if (response.status === 201) {
          commit("SET_CREATED", response.data);
          commit("LINK_CREATED", true);
          commit("SET_PENDING", false);
        }
      } catch (e) {
        commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
