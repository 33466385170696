import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    data: {},
    errors: {},
    pending: false,
  }),

  getters: {
    pending(state) {
      return state.pending;
    },
    errors(state) {
      return state.errors;
    },
  },
  mutations: {
    SET_PENDING(state, isPending) {
      state.pending = isPending;
    },
    SET_ERRORS(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async createTask({ commit, state }, data) {
      if (state.pending) {
        return;
      }
      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.post(`/user_tasks/`, data);

        if (response.status === 201) {
          commit("SET_PENDING", false);
          return response;
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
        commit("SET_ERRORS", e.response);
      }
    },
  },
};
