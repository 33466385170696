<template>
  <div class="addContacts__wrap">
    <!-- ADD BUTTON -->
    <button
      type="button"
      @click="openContactModal"
      class="addContacts__button addContacts__buttonAdd"
    >
      <svg>
        <use xlink:href="@/assets/svg/sprite_contacts.svg#addContact" />
      </svg>
      {{ $t("Добавить") }}
    </button>
    <!-- ADD BUTTON -->

    <!-- MERGE BUTTON -->
    <base-link
      to="/contacts-groups"
      @click="openTagModal"
      class="addContacts__button addContacts__buttonGoogleAdd"
    >
      {{ $t("Объединение") }}
    </base-link>
    <!-- MERGE BUTTON -->

    <!-- MANAGE TAGS BUTTON -->
    <button
      @click="openTagModal"
      class="addContacts__button addContacts__buttonGoogleAdd"
    >
      {{ $t("Управление тегами") }}
    </button>
    <!-- MANAGE TAGS BUTTON -->
  </div>
</template>

<script>
export default {
  name: "ContactsMenuAddContacts",

  methods: {
    openContactModal() {
      this.$modal.show("create-contact", {});
    },

    openTagModal() {
      this.$modal.show("contact-tags-management", {});
    },
  },
};
</script>

<style lang="stylus" scoped>
.addContacts__wrap {
  display: grid;
  grid-row-gap: 10px;
  margin-top: 10px;
}

.addContacts__button {
  position: relative;
  padding: 6px 8px 6px 8px;
  background: none;
  border: none;
  border-radius: 4px;
  font_regular();
  width: fit-content;

  & svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
  }

  &:hover {
    opacity: 0.6;
  }
}

.addContacts__buttonAdd {
  border: 1px dashed #0f1642;
}

.addContacts__buttonGoogleAdd {
  background-color: #e6e7eb;
  border: 1px dashed transparent;
}

@media (min-width: desktop) {
  .addContacts__wrap {
    position: absolute;
    right: 0;
    top: -1px;
    grid-auto-flow: column;
    grid-column-gap: 9px;
    grid-row-gap: unset;
    margin-top: 0;
  }
}
</style>
