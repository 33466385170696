<template>
  <div class="contacts__menu">
    <ContactsMenuFilterSearch
      v-if="isMenuShown"
      v-bind="{ ...$props }"
      :tags="tags"
      :filters="filters"
      :tagsFilterValue="tagsFilterValue"
      :tagsFilterCounter="tagsFilterCounter"
      :pickedAllTags="pickedAllTags"
      @change-filter="changeFilter"
      @clearFilters="clearFilters"
      @pickAllTags="pickAllTags"
      @handleFilter="handleFilter"
      @changeTagsFilterValue="changeTagsFilterValue"
      @changeTagsFilterCounter="changeTagsFilterCounter"
    />
    <ContactsMenuAddContacts />
  </div>
</template>

<script>
import ContactsMenuAddContacts from "@/components/Contacts/ContactsMenuAddContacts.vue";
import ContactsMenuFilterSearch from "@/components/Contacts/ContactsMenuFilterSearch.vue";

export default {
  name: "ContactsMenu",

  components: {
    ContactsMenuAddContacts,
    ContactsMenuFilterSearch,
  },

  props: {
    isFilterChecked: {
      type: Function,
    },
    filtersIsActive: Boolean,
    isMenuShown: Boolean,

    tags: {
      type: Array,
      required: true,
    },

    filters: {
      type: Object,
    },

    tagsFilterValue: {
      type: [String, Number],
    },

    tagsFilterCounter: {
      type: [String, Number],
    },

    pickedAllTags: {
      type: Boolean,
    },
  },

  methods: {
    changeFilter(payload) {
      this.$emit('change-filter', payload)
    },

    clearFilters() {
      this.$emit('clearFilters')
    },

    handleFilter() {
      this.$emit("handleFilter");
    },

    pickAllTags(payload) {
      this.$emit("pickAllTags", payload);
    },

    changeTagsFilterValue(value) {
      this.$emit("changeTagsFilterValue", value);
    },

    changeTagsFilterCounter(value) {
      this.$emit("changeTagsFilterCounter", value);
    },
  },
};
</script>

<style lang="stylus" scoped>
.contacts__menu {
  position: relative;
  margin-bottom: 20px;
  min-height: 35px;
}
</style>
