import Leadership from "../views/Leadership.vue";
import Courses from "../views/Leadership/Courses.vue";
import Lessons from "../views/Leadership/Lessons.vue";
import Course from "../views/Leadership/Course.vue";
import Programs from "../views/Leadership/Programs.vue";
import LessonDescription from "../views/Leadership/LessonDescription.vue";
import NewCourse from "../views/Leadership/NewCourse.vue";
import NewProgram from "../views/Leadership/NewProgram.vue";
import Program from "../views/Leadership/Program.vue";
import ProgramCourses from "../views/Leadership/ProgramCourses.vue";
import NewLesson from "@/views/Leadership/NewLesson.vue";
import LessonTask from "@/views/Leadership/LessonTask.vue";
import LessonTasks from "@/views/Leadership/LessonTasks.vue";
import Autowebinars from "@/views/Autowebinars/Autowebinars.vue";
import Autowebinar from "@/views/Autowebinars/AutowebinarDescription.vue";
import NewAutowebinar from "@/views/Autowebinars/NewAutowebinarDescription.vue";
import AutowebinarWebinar from "@/views/Autowebinars/AutowebinarWebinar.vue";
import AutowebinarPoints from "@/views/Autowebinars/Autowebinars_points.vue";
import AutowebinarList from "@/views/Autowebinars/Autowebinars_list.vue";
import AutowebinarCreateTitle from "@/views/Autowebinars/Autowebinars_createTitle.vue";
import AutowebinarAudienceList from "@/views/Autowebinars/Autowebinars_audienceList.vue";
import AutowebinarEditMessage from "@/views/Autowebinars/Autowebinars_editMessage.vue";
import AutowebinarCreateMessage from "@/views/Autowebinars/Autowebinars_createMessage.vue";
import AutowebinarEditAudience from "@/views/Autowebinars/Autowebinars_editAudience.vue";
import AutowebinarCreateAudience from "@/views/Autowebinars/Autowebinars_createAudience.vue";
import TeamTree from "@/views/Team/TeamTree.vue";
import UpdatedFunnel from "@/views/Funnel/UpdatedFunnel.vue";
import UpdatedAssignment from "@/views/Assignment/UpdatedAssignment.vue";
import UpdatedLeads from "@/views/Leads/UpdatedLeads.vue";
import TechWorksPlaceholder from "@/components/TechWorksPlaceholder.vue";
import UpdatedFunnelCreateLink from "@/views/Funnel/UpdatedFunnelCreateLink.vue";
import UpdatedFunnelLinksList from "@/views/Funnel/UpdatedFunnelLinksList.vue";
import UpdatedLeadPage from "@/views/Leads/UpdatedLeadPage.vue";
import Tasks from "@/views/Tasks/Tasks.vue";
import TasksActual from "@/views/Tasks/TasksActual.vue";
import TasksExpired from "@/views/Tasks/TasksExpired.vue";
import TasksDone from "@/views/Tasks/TasksDone.vue";
import TasksAll from "@/views/Tasks/TasksAll.vue";
import Contacts from "@/views/Contacts/Contacts.vue";
import ContactsProfile from "@/views/Contacts/ContactsProfile.vue";
import ContactsProfileInfo from "@/views/Contacts/ContactsProfileInfo.vue";
import ContactsProfileStatus from "@/views/Contacts/ContactsProfileStatus.vue";
import ContactsProfileNotes from "@/views/Contacts/ContactsProfileNotes.vue";
import ContactsProfileEvents from "@/views/Contacts/ContactsProfileEvents.vue";
import ContactsProfileReminders from "@/views/Contacts/ContactsProfileReminders/ContactsProfileReminders.vue";
import ContactsProfileMeetings from "@/views/Contacts/ContactsProfileMeetings/ContactsProfileMeetings.vue";
import ContactsProfileNotesItem from "@/views/Contacts/ContactsProfileNotesItem.vue";
import ContactsProfileRemindersActual from "@/views/Contacts/ContactsProfileReminders/ContactsProfileRemindersActual.vue";
import ContactsProfileRemindersExpired from "@/views/Contacts/ContactsProfileReminders/ContactsProfileRemindersExpired.vue";
import ContactsProfileRemindersDone from "@/views/Contacts/ContactsProfileReminders/ContactsProfileRemindersDone.vue";
import ContactsProfileRemindersAll from "@/views/Contacts/ContactsProfileReminders/ContactsProfileRemindersAll.vue";
import ContactsProfileMeetingsActual from "@/views/Contacts/ContactsProfileMeetings/ContactsProfileMeetingsActual.vue";
import ContactsProfileMeetingsExpired from "@/views/Contacts/ContactsProfileMeetings/ContactsProfileMeetingsExpired.vue";
import ContactsProfileMeetingsDone from "@/views/Contacts/ContactsProfileMeetings/ContactsProfileMeetingsDone.vue";
import ContactsProfileMeetingsAll from "@/views/Contacts/ContactsProfileMeetings/ContactsProfileMeetingsAll.vue";
import ContactsGroups from "@/views/MergeContacts/ContactsGroups.vue";
import MergeContacts from "@/views/MergeContacts/MergeContacts.vue";

export default [
  {
    path: "leadership",
    name: "Leadership",
    component: Leadership,
  },

  // Ledership Courses

  {
    path: "leadership/courses",
    name: "Courses",
    component: Courses,
  },
  {
    path: "leadership/courses/:courseId/description",
    name: "Course",
    component: Course,
  },
  {
    path: "leadership/courses/:courseId/lessons",
    name: "Lessons",
    component: Lessons,
  },
  {
    path: "leadership/courses/new",
    name: "NewCourse",
    component: NewCourse,
  },
  {
    path: "leadership/courses/:courseId/lessons/:lessonId/description",
    name: "LessonDescription",
    component: LessonDescription,
  },

  //
  // Leadership Tasks
  //
  {
    path: "leadership/courses/:courseId/lessons/:lessonId/works",
    name: "LessonTasks",
    component: LessonTasks,
  },
  {
    path: "leadership/courses/:courseId/lessons/:lessonId/works/:taskId",
    name: "LessonTask",
    component: LessonTask,
  },
  {
    path: "leadership/courses/:courseId/lessons/new",
    name: "NewLesson",
    component: NewLesson,
  },

  //

  //
  // Leadership Programs
  //
  {
    path: "leadership/programs",
    name: "Programs",
    component: Programs,
  },
  {
    path: "leadership/programs/new/description",
    name: "NewProgram",
    component: NewProgram,
  },
  {
    path: "leadership/programs/:programId/description",
    name: "Program",
    component: Program,
  },
  {
    path: "leadership/programs/:programId/courses",
    name: "ProgramCourses",
    component: ProgramCourses,
  },

  {
    path: "leadership/autowebinars",
    name: "Autowebinars",
    component: Autowebinars,
  },
  {
    path: "leadership/autowebinars/:webinarId/description",
    name: "Autowebinar",
    component: Autowebinar,
  },
  {
    path: "leadership/autowebinars/new",
    name: "NewAutowebinar",
    component: NewAutowebinar,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar",
    name: "AutowebinarWebinar",
    component: AutowebinarWebinar,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/points",
    name: "AutowebinarPoints",
    component: AutowebinarPoints,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/list",
    name: "AutowebinarList",
    component: AutowebinarList,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/create-title",
    name: "AutowebinarCreateTitle",
    component: AutowebinarCreateTitle,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/audience-list",
    name: "AutowebinarAudienceList",
    component: AutowebinarAudienceList,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/edit-message",
    name: "AutowebinarEditMessage",
    component: AutowebinarEditMessage,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/create-message",
    name: "AutowebinarCreateMessage",
    component: AutowebinarCreateMessage,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/edit-audience",
    name: "AutowebinarEditAudience",
    component: AutowebinarEditAudience,
  },
  {
    path: "leadership/autowebinars/:webinarId/webinar/create-audience",
    name: "AutowebinarCreateAudience",
    component: AutowebinarCreateAudience,
  },
  {
    path: "team/",
    name: "Team",
    component: TeamTree,
    redirect: "team-tree",
  },
  {
    path: "team-tree/",
    name: "TeamTree",
    component: TeamTree,
  },
  {
    path: "funnel",
    name: "UpdatedFunnel",
    component: UpdatedFunnel,
  },
  {
    path: "assignment",
    name: "UpdatedAssignment",
    component: UpdatedAssignment,
  },
  {
    path: "leads",
    name: "UpdatedLeads",
    component: UpdatedLeads,
  },
  {
    path: "tech-works",
    name: "TechWorksPlaceholder",
    component: TechWorksPlaceholder,
  },
  {
    path: "funnel/create-link",
    name: "UpdatedFunnelCreateLink",
    component: UpdatedFunnelCreateLink,
  },
  {
    path: "funnel/links-list",
    name: "UpdatedFunnelLinksList",
    component: UpdatedFunnelLinksList,
  },
  {
    path: "leads/:leadId",
    name: "UpdatedLeadPage",
    component: UpdatedLeadPage,
  },

  // TASKS
  {
    path: "tasks",
    name: "Tasks",
    component: Tasks,
    redirect: "tasks/actual",
    children: [
      { path: "actual", name: "TasksActual", component: TasksActual },
      { path: "expired", name: "TasksExpired", component: TasksExpired },
      { path: "done", name: "TasksDone", component: TasksDone },
      { path: "all", name: "TasksAll", component: TasksAll },
    ],
  },

  // CONTACTS
  {
    path: "contacts",
    name: "Сontacts",
    component: Contacts,
  },
  {
    path: "contacts/:profileId",
    name: "ContactsProfile",
    component: ContactsProfile,
    redirect: "contacts/:profileId/info",
    children: [
      {
        path: "info",
        name: "ContactsProfileInfo",
        component: ContactsProfileInfo,
      },
      {
        path: "status",
        name: "ContactsProfileStatus",
        component: ContactsProfileStatus,
      },
      {
        path: "notes",
        name: "ContactsProfileNotes",
        component: ContactsProfileNotes,
      },
      {
        path: "notes/:note",
        name: "ContactsProfileNotesItem",
        component: ContactsProfileNotesItem,
      },
      {
        path: "events",
        name: "ContactsProfileEvents",
        component: ContactsProfileEvents,
      },
      {
        path: "reminders",
        name: "ContactsProfileReminders",
        component: ContactsProfileReminders,
        redirect: "reminders/actual",
        children: [
          {
            path: "actual",
            name: "ContactsProfileRemindersActual",
            component: ContactsProfileRemindersActual,
          },
          {
            path: "expired",
            name: "ContactsProfileRemindersExpired",
            component: ContactsProfileRemindersExpired,
          },
          {
            path: "done",
            name: "ContactsProfileRemindersDone",
            component: ContactsProfileRemindersDone,
          },
          {
            path: "all",
            name: "ContactsProfileRemindersAll",
            component: ContactsProfileRemindersAll,
          },
        ],
      },
      {
        path: "meetings",
        name: "ContactsProfileMeetings",
        component: ContactsProfileMeetings,
        redirect: "meetings/actual",
        children: [
          {
            path: "actual",
            name: "ContactsProfileMeetingsActual",
            component: ContactsProfileMeetingsActual,
          },
          {
            path: "expired",
            name: "ContactsProfileMeetingsExpired",
            component: ContactsProfileMeetingsExpired,
          },
          {
            path: "done",
            name: "ContactsProfileMeetingsDone",
            component: ContactsProfileMeetingsDone,
          },
          {
            path: "all",
            name: "ContactsProfileMeetingsAll",
            component: ContactsProfileMeetingsAll,
          },
        ],
      },
    ],
  },
  {
    path: "contacts-groups",
    name: "ContactsGroups",
    component: ContactsGroups,
  },
  {
    path: "merge-contacts",
    name: "MergeContacts",
    component: MergeContacts,
  },
];
