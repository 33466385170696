<script>
import AutowebinarsContainer from "@/containers/Leadership/AutowebinarsContainer.vue";

export default {
  name: "Autowebinars",
  components: {
    AutowebinarsContainer,
  },
  data() {
    return {
      breadcrumbs: [{ title: "Настройки лидера", link: "/leadership" }],
    };
  },
};
</script>
<template>
  <main class="main">
    <base-breadcrumbs :breadcrumbs="breadcrumbs" />

    <div>
      <div class="title__wrap">
        <h1 class="title">
          <Tr>Автовебинары</Tr>
        </h1>
        <base-link to="/leadership/autowebinars/new" class="createAutowebinar">
          <Tr>Создать автовебинар</Tr>
        </base-link>
      </div>
      <div class="underLoader">
        <autowebinars-container />
      </div>
    </div>
  </main>
</template>

<style lang="stylus" scoped>
.title__wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.createAutowebinar {
  align-self: center;
  margin-bottom: 5px;
  margin-left: 50px;
  padding: 6px 7px;
  border: 1px dashed #0f1642;
  border-radius: 4px;
  background: none;
  font_regular();
}
</style>
