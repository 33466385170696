import moment from "moment";
import qs from "qs";
import { pathOr } from "ramda";
import Vue from "vue";

function filterNonNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([k, v]) => v));
}

function arrFromObj(obj) {
  const arr: string[] = [];
  Object.keys(obj).forEach(i => {
    obj[i].forEach(k => {
      arr.push(`&${i}=${k}`);
    });
  });
  const str = arr.join("");
  return str;
}

export default {
  namespaced: true,

  state: () => ({
    data: {},
    name_icontains: "",
    filters: {
      assignment_status: [],
      user: [],
      source_name: [],
    },
    pickedLeads: [],
    manager: null,
    sort: "",
    filtersActive: false,
    isPickedAllLeads: false,
    isPickedAllStatuses: false,
    isPickedAllManagers: false,
    isPickedAllSources: false,
    managerFilterValue: "",
    sourceFilterValue: "",
    managerFilterCounter: "",
    sourceFilterCounter: "",
    // errors: {},
    pending: false,
  }),

  getters: {
    // errors(state) {
    //   return state.errors;
    // },
    pending(state) {
      return state.pending;
    },
    leads(state) {
      return pathOr([], ["data", "results"], state);
    },
    manager(state) {
      return state.manager;
    },
    total(state) {
      return pathOr(0, ["data", "count"], state);
    },
    hasNextPage(state) {
      return pathOr(undefined, ["data", "next_page_number"], state);
    },
    currentPage(state) {
      return pathOr(undefined, ["data", "current_page_number"], state);
    },
    searchValue(state) {
      return state.name_icontains;
    },
    activeFilters(state) {
      return state.filters;
    },
    filters(state) {
      return state.filters;
    },
    filtersActive(state) {
      return state.filtersActive;
    },
    isFilterChecked: state => (name, value) => {
      if (state.filters[name]) {
        return state.filters[name].includes(value);
      }
      return false;
    },
    isPickedLeadsChecked: state => value => {
      // return state.pickedLeads.includes(value);

      return !!state.pickedLeads.filter(i => {
        return i.id === value.id;
      }).length;
    },
    pickedLeads(state) {
      return state.pickedLeads;
    },
    pickedAllManagers(state) {
      return state.isPickedAllManagers;
    },
    pickedAllSources(state) {
      return state.isPickedAllSources;
    },
    managerFilterValue(state) {
      return state.managerFilterValue;
    },
    sourceFilterValue(state) {
      return state.sourceFilterValue;
    },
    managerFilterCounter(state) {
      return state.managerFilterCounter;
    },
    sourceFilterCounter(state) {
      return state.sourceFilterCounter;
    },
  },

  mutations: {
    SET_DATA(state, data) {
      state.data =
        data.current_page_number === 1
          ? data
          : (state.data = {
              ...data,
              results: state.data.results.concat(data.results),
            });
    },
    SET_PENDING(state, status) {
      state.pending = status;
    },
    CHANGE_SEARCH(state, value) {
      state.name_icontains = value;
    },
    CHANGE_FILTER(state, { value, name }) {
      if (value instanceof Array) {
        state.filters = {
          ...state.filters,
          [name]: value,
        };

        return;
      }

      if (state.filters[name]) {
        if (state.filters[name].includes(value)) {
          state.filters = {
            ...state.filters,
            [name]: state.filters[name].filter(el => el !== value),
          };
        } else {
          state.filters = {
            ...state.filters,
            [name]: [...state.filters[name], value],
            // [name]: [value],
          };
        }
      } else {
        state.filters = {
          ...state.filters,
          [name]: [value],
        };
      }
    },
    TOGGLE_FILTERS(state) {
      state.filtersActive = !state.filtersActive;
    },
    CLEAR_FILTERS(state) {
      state.filters = {
        ...state.filters,
        assignment_status: [],
        user: [],
        source_name: [],
      };
    },
    SET_MANAGER_FILTER_VALUE(state, value) {
      state.managerFilterValue = value;
    },
    SET_SOURCE_FILTER_VALUE(state, value) {
      state.sourceFilterValue = value;
    },
    SET_MANAGER_FILTER_COUNTER(state, value) {
      state.managerFilterCounter = value;
    },
    SET_SOURCE_FILTER_COUNTER(state, value) {
      state.sourceFilterCounter = value;
    },
    CHANGE_SORT(state, sort) {
      if (state.sort === sort) {
        state.sort = `-${sort}`;
      } else {
        state.sort = sort;
      }
    },
    SET_PICKED_LEADS(state, value) {
      const isLeadPicked = () => {
        return !!state.pickedLeads.filter(i => {
          return i.id === value.id;
        }).length;
      };
      if (isLeadPicked()) {
        state.pickedLeads = state.pickedLeads.filter(el => el.id !== value.id);
      } else {
        state.pickedLeads = [...state.pickedLeads, value];
      }
    },
    SET_PICKED_ALL_LEADS(state, value) {
      state.isPickedAllLeads = !state.isPickedAllLeads;

      state.isPickedAllLeads
        ? (state.pickedLeads = [...value])
        : (state.pickedLeads = []);
    },
    SET_PICKED_ALL_STATUSES(state, value) {
      state.isPickedAllStatuses = !state.isPickedAllStatuses;

      state.isPickedAllStatuses
        ? (state.filters.assignment_status = [...value])
        : (state.filters.assignment_status = []);
    },
    SET_PICKED_ALL_USERS(state, value) {
      state.isPickedAllManagers = !state.isPickedAllManagers;

      state.isPickedAllManagers
        ? (state.filters.user = [...value])
        : (state.filters.user = []);
    },
    SET_PICKED_ALL_SOURCES(state, value) {
      state.isPickedAllSources = !state.isPickedAllSources;

      state.isPickedAllSources
        ? (state.filters.source_name = [...value])
        : (state.filters.source_name = []);
    },
    CHANGE_MANGER(state, value) {
      state.manager = +value;
    },
    // SET_ERRORS(state, data) {
    //   state.errors = data;
    // },
    CLEAR_STORE(state) {
      state.pickedLeads = [];
      state.manager = null;
    },
  },

  actions: {
    changeSort({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_SORT", payload);
        resolve();
      });
    },
    changeFilter({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("CHANGE_FILTER", payload);
        resolve();
      });
    },
    pickAllStatuses({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_STATUSES", payload);
        resolve();
      });
    },
    pickAllUsers({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_USERS", payload);
        resolve();
      });
    },
    pickAllSources({ commit }, payload) {
      return new Promise((resolve: any) => {
        commit("SET_PICKED_ALL_SOURCES", payload);
        resolve();
      });
    },
    toggleFilter({ commit }) {
      return new Promise((resolve: any) => {
        commit("TOGGLE_FILTERS");
        resolve();
      });
    },
    async changeSearch({ commit, dispatch }, { search }) {
      commit("CHANGE_SEARCH", search);
      dispatch("fetch", { page: 1 });
    },

    async fetch({ commit, state }, { page }) {
      try {
        commit("SET_PENDING", true);

        let filters = qs.stringify(
          filterNonNull({
            name_icontains: state.name_icontains,
            ...(page ? { page } : []),
            ...(state.sort ? { o: state.sort } : []),
          }),
          { arrayFormat: "comma" }
        );

        const newFilters = arrFromObj(state.filters);
        filters += newFilters;

        const response = await Vue.axios.get(
          `/telephony/assignment_telephony_leads/?${filters}`
        );

        if (response.status === 200) {
          commit("SET_DATA", response.data);
          commit("SET_PENDING", false);
        } else {
          throw response.data.message;
        }
      } catch (e) {
        commit("SET_PENDING", false);
      }
    },

    async pickManager({ commit, state, dispatch }) {
      const leadsIds = () => {
        const arr: number[] = [];
        state.pickedLeads.forEach(item => {
          arr.push(item.id);
        });
        return arr;
      };

      const data = {
        user_id: state.manager,
        leads_ids: leadsIds(),
      };

      try {
        commit("SET_PENDING", true);

        const response = await Vue.axios.patch(
          `/telephony/assignment_telephony_leads/manager_to_leads/`,
          data
        );
        if (response.status === 200) {
          commit("CLEAR_STORE");
          dispatch("fetch", { page: 1 });

          commit("SET_PENDING", false);
        }
      } catch (e) {
        // commit("SET_ERRORS", e.response.data.errors);
        commit("SET_PENDING", false);
      }
    },
  },
};
